export const getColumnWidth = (params: {
  size: 'big' | 'medium' | 'small'
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}): string => {
  const { size, isMobile, isTablet, isDesktop } = params
  if (size === 'big' && isDesktop) {
    return '300px'
  }
  if (size === 'big' && isTablet) {
    return '250px'
  }
  if (size === 'big' && isMobile) {
    return '200px'
  }

  if (size === 'medium' && isDesktop) {
    return '200px'
  }
  if (size === 'medium' && isTablet) {
    return '150px'
  }
  if (size === 'medium' && isMobile) {
    return '100px'
  }

  if (size === 'small' && isDesktop) {
    return '100px'
  }
  if (size === 'small' && isTablet) {
    return '100px'
  }
  if (size === 'small' && isMobile) {
    return '100px'
  }

  return ''
}
