import {UseBaseApiParams} from "../../../core/UseBaseApiParams";
import React, {useState} from "react";
import {useAppSelector} from "../../../hooks";
import {selectChecklistItem} from "../../../data/start-guide/slice";
import {CHECKLIST_CODES} from "../../../data/start-guide/constants";
import {generatePath} from "react-router-dom";
import {sumBy} from "lodash";
import {Box} from "@chakra-ui/react";
import {COLORS} from "../../../mini-lib/theme/colors";
import {StepContent, StepHeader} from "./SGIndexPage";
import { ROUTES } from "../../../appRoutes";
import { ReleaseSquare } from "../../../mini-lib/flags/Release";
import { UseIsInTrialV2 } from "../../trial-v1/utils";

export const PricingSteps = (props: { isDefaultOpen: boolean }) => {
  const { isDefaultOpen } = props
  const { salonId } = UseBaseApiParams()
  const [expanded, setExpanded] = useState(isDefaultOpen)
  const addedLinesItem = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.addLines))
  const addedPricingAuto = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.autoPricing))
  const addedPricingManual = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.addPricing))
  const learnPOS = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.learnPOS))
  const releaseSquare = ReleaseSquare()
  const isTrial = UseIsInTrialV2()
  const steps = [
    {
      label: 'Add Your Color Lines',
      complete: addedLinesItem.completed,
      link: generatePath(ROUTES.sgAddLinesIntro, { salonId }),
    },
    {
      label: 'Determine Your Pricing',
      complete: addedPricingAuto.completed || addedPricingManual.completed,
      link: generatePath(ROUTES.sgAddPricingIntro, { salonId }),
    }
  ]
  if (releaseSquare) {
    const squareStep = {
      code: CHECKLIST_CODES.learnPOS,
      label: 'Connect to your booking system',
      complete: learnPOS.completed,
      link: generatePath(ROUTES.sgPOSIntro, { salonId }),
      variant: isTrial ? 'default': 'skippable'
    }
    steps.push(squareStep)
  }

  const totalSteps = steps.length
  const stepsComplete = sumBy(steps, 'complete')

  return (
    <Box border={`1px solid ${COLORS.shades_neutral_300}`} borderRadius="15px">
      <StepHeader
        iconName="settings"
        label="Set Up SalonScale"
        setExpanded={setExpanded}
        expanded={expanded}
        stepsComplete={stepsComplete}
        totalSteps={totalSteps}
      />
      {expanded && <StepContent steps={steps} />}
    </Box>
  )
}
