import React, { ReactNode } from 'react'
import { Box, Drawer, DrawerBody, DrawerContent, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { useDispatch } from 'react-redux'
import {
  reduceHelpDrawerState,
  selectHelpDrawerState,
} from '../../../data/start-guide/slice'
import { useAppSelector } from '../../../hooks'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'
import { UseViewSize } from '../../../core/UseViewSize'
import { Gap } from '../../../mini-lib/gap/Gap'
import { MaterialIcon } from "../../../mini-lib/icons/MaterialIcon";
import { Video } from "../../../mini-lib/video/Video";
import { VideoData } from "../../../mini-lib/video/constants";

export const HELP_DRAWER_STATES: any = {
  drawer: 'drawer',
  button: 'button',
  hidden: 'hidden',
}

export type HelpDrawerState = 'hidden' | 'drawer' | 'button' | string

export const HelpDrawer = ( props: {
  title?: string
  content?: string | ReactNode
  video?: VideoData
} ) => {
  const dispatch = useDispatch()
  const { title='', content = '', video } = props
  const drawerState = useAppSelector(selectHelpDrawerState)
  const { isMobile }: any = UseViewSize()
  const toggleDrawerState = () => {
    if (drawerState === HELP_DRAWER_STATES.button) {
      dispatch(reduceHelpDrawerState(HELP_DRAWER_STATES.drawer))
    } else {
      dispatch(reduceHelpDrawerState(HELP_DRAWER_STATES.button))
    }
  }
  return (
    <>
        <Drawer
          variant="alwaysOpen"
          isOpen={drawerState === HELP_DRAWER_STATES.drawer || drawerState === HELP_DRAWER_STATES.button}
          placement="bottom"
          onClose={toggleDrawerState}
          blockScrollOnMount={false}
          trapFocus={false}
        >
          <DrawerContent>
            <DisableAutofocus/>

            {drawerState === HELP_DRAWER_STATES.button && (
              <DrawerBody borderTop={`2px solid ${COLORS.lavender_500}`} p='12px'>
                  <Flex justify='end' align='center' gridGap='8px'>
                    Show page help
                    <MaterialIcon cursor='pointer' size='28px' name='keyboard_arrow_up' theme='midnight' onClick={() => dispatch(reduceHelpDrawerState(HELP_DRAWER_STATES.drawer))}/>
                  </Flex>
              </DrawerBody>
            )}
            {drawerState === HELP_DRAWER_STATES.drawer && (
              <DrawerBody borderTop={`2px solid ${COLORS.lavender_500}`} >
                <MaterialIcon cursor='pointer' size='28px' position='fixed' top='24px' right='24px' name='keyboard_arrow_down' theme='midnight' onClick={() => dispatch(reduceHelpDrawerState(HELP_DRAWER_STATES.hidden))}/>
                <Flex gridGap='24px' p='12px' direction={isMobile ? 'column' : 'row'} align={isMobile ? 'center' : 'start'}>
                  {video && (
                    <Video
                      video={video}
                      variant="miniplayer"
                      height={isMobile ? '200px' : '170px'}
                      width={isMobile ? '300px' : '300px'}
                    />
                  )}
                  <Flex direction="column">
                    <Text variant={isMobile ? 'title1mobile' : 'default/regular/title3'} fontWeight="700">
                      {title}
                    </Text>
                    <Gap s="12px"/>
                    <Text variant="footnote" color={COLORS.text_secondary} whiteSpace='pre-wrap'>{content}</Text>
                    <Box h="24px"/>
                  </Flex>
                </Flex>
                {isMobile && <Gap s='30px'/>}
              </DrawerBody>
            )}
          </DrawerContent>
        </Drawer>
    </>
  )
}
