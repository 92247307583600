import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'

import { selectSupplyList } from '../../data/supplies/slice'
import { dispatchListSupplies } from '../../data/supplies/api'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { useAppSelector } from '../../hooks'
import { ColorForMode } from '../../theme'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { SupplyTable } from './SupplyTable'
import { COLORS } from '../../mini-lib/theme/colors'
import { HelpStepper, Step } from '../start-guide/common-components/HelpStepper'
import { HelpStepCompleteDrawer } from '../start-guide/common-components/HelpStepCompleteDrawer'
import { dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import {
  reduceSetCurrentStepCode,
  reduceSetCurrentSubStepIndex,
  reduceSetStepCompleteDrawerState,
  selectStepCompleteDrawerState,
  selectStepsCompleted,
} from '../../data/start-guide/slice'
import { HelpStepperV2 } from '../start-guide-v2/HelpStepperV2'
import { ReleaseLaraSupplies, ReleasePageHelp, ReleaseStartGuideV2 } from '../../mini-lib/flags/Release'
import { UseViewSize } from '../../core/UseViewSize'
import { ROUTES } from "../../appRoutes";
import { HelpDrawer } from "../start-guide/common-components/HelpDrawer";
import { VIDEO_MAP } from "../../mini-lib/video/constants";

export const SuppliesPage = (props: {showBreadCrumbs?: boolean}) => {
  const {showBreadCrumbs = true} = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const { isMobile } = UseViewSize()

  const breadcrumbs = showBreadCrumbs ? [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Add-Ons' }] : []
  const helpSteps: Step[] = [
    {
      title: 'Select Your Add-Ons',
      state: 'complete',
      link: generatePath(ROUTES.supplyOnboard, { salonId }) + `?guide=${CHECKLIST_CODES.addAddons}`,
    },
    {
      title: 'Set Prices',
      state: 'current',
      link: generatePath(ROUTES.supplies, { salonId }) + `?guide=${CHECKLIST_CODES.addAddons}`,
    },
  ]
  const dispatch = useDispatch()
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const models = useAppSelector(selectSupplyList)
  const queryParams: any = UseQueryParams()
  const showEmpty = queryParams.get('showEmpty') // force the empty state
  const showTable = queryParams.get('showTable') // force the table to show even if no data

  const releaseLaraSupplies = ReleaseLaraSupplies()

  useEffect(() => {
    dispatch(dispatchListSupplies({token, salonId, releaseLaraSupplies}))
  }, [dispatch, token, salonId, releaseLaraSupplies])

  const show = showTable || (!showEmpty && models && models.length > 0)
  const releaseStartGuideV2 = ReleaseStartGuideV2()
  const releasePageHelp = ReleasePageHelp()

  const stepCodes = [CHECKLIST_CODES.addAddons, CHECKLIST_CODES.addExtensions]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  const stepsComplete = stepsCompleted || 0
  const previousStepLink = generatePath(ROUTES.sgIndex, { salonId })
  const nextStepLink = generatePath(ROUTES.sgAddExtensionsIntro, { salonId })
  return (
    <>
    {releasePageHelp && (
      <HelpDrawer
        video={VIDEO_MAP.addAddons}
        title={`Let’s take a look inside Addons`}
        content={`Add-Ons are exactly what they sound like- other costs of goods sold at your backbar or in your salon. The most common examples are:
• Foils
• Gloves
• Shampoo
• Treatments
• Specialty drinks or coffee`}
      />
    )}
    <PageLayout
      variant="full"
      top={
        <>
          {releaseStartGuideV2 ? (
            <HelpStepperV2
              title={isMobile ? 'Add Add-Ons' : 'Add Add-Ons to Your Back Bar'}
              titleIconName="clients"
              currentStepNumber={2}
              stepsComplete={stepsComplete}
              totalSteps={stepCodes.length}
              previousStepLink={previousStepLink}
              nextStepLink={nextStepLink}
              guideType={CHECKLIST_CODES.addAddons}
            />
          ) : (
            <HelpStepper
              guideType={CHECKLIST_CODES.addAddons}
              title="Add Add-Ons to Your Back Bar"
              steps={helpSteps}
              bgHex={COLORS.linen_100}
              borderHex={COLORS.linen_500}
            />
          )}
        </>
      }
      header={<PageHeader showHelp={true} title="Add-Ons" breadcrumbs={breadcrumbs} />}
      content={
        <>
          <HelpStepCompleteDrawer
            guideType={CHECKLIST_CODES.addAddons}
            title="Now you know how to input Add-Ons into your Back Bar!"
            subtitle="Feel free to set up more prices while we are here. When you are ready, click below to go to the next step"
            actions={
              <Flex gridGap="12px">
                {stepCompleteDrawerState !== 'button' && (
                  <Button
                    variant="round-outline"
                    colorScheme="brand.midnight"
                    onClick={() => dispatch(reduceSetStepCompleteDrawerState('button'))}
                  >
                    Continue Setting Add-On Prices
                  </Button>
                )}
                <Link
                  to={
                    releaseStartGuideV2
                      ? generatePath(ROUTES.sgAddExtensionsIntro, { salonId })
                      : generatePath(ROUTES.startGuide, { salonId })
                  }
                >
                  <Button
                    variant="round"
                    colorScheme="brand.midnight"
                    onClick={() => {
                      dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: CHECKLIST_CODES.addAddons }))
                      dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.addExtensions))
                      dispatch(reduceSetStepCompleteDrawerState('hidden'))
                      dispatch(reduceSetCurrentSubStepIndex(0))
                    }}
                  >
                    Next Step
                  </Button>
                </Link>
              </Flex>
            }
          />
          {models && show && <SupplyTable supplies={models} />}
          {models && !show && (
            <>
              <Empty />
            </>
          )}
          {!models && (
            <Flex h="500px" align="center" justify="center">
              <Spinner />
            </Flex>
          )}
        </>
      }
    />
    </>
  )
}

const Empty = (props: {}) => {
  const st = ColorForMode('secondary-text')
  const history = useHistory()
  const params: any = useParams()
  const { salonId } = params
  const goToOnboard = () => {
    history.push(generatePath(ROUTES.supplyOnboard, { salonId }))
  }
  const suppliesEmpty = 'https://storage.googleapis.com/salonscale-prod-webapp-assets/addons-empty-state.png'
  return (
    <Flex direction="column" justify="center" align="center">
      <img alt="logo" style={{ height: '380px', maxWidth: '100%', padding: '4px' }} src={suppliesEmpty} />
      <Text variant="title1" fontWeight="bold">
        NO ADD-ONS ADDED
      </Text>
      <Box h="24px" />
      <Text textAlign="center" color={st} maxW="100%" w="640px">
        Add your add-ons to start using this feature in the SalonScale App!
      </Text>
      <Box h="24px" />
      <Button w="200px" variant="round" bg="brand.midnight.500" onClick={goToOnboard}>
        LET'S GO!
      </Button>
    </Flex>
  )
}
