import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export type SettingSheetType = 'closed' | 'photo' | 'name' | 'email' | 'salon-name' | 'salon-timezone'
// Define a type for the slice state
interface SettingsState {
  sheetType: SettingSheetType
}

// Define the initial state using that type
const initialState: SettingsState = {
  sheetType: 'closed',
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingsSheetType: (state, action: PayloadAction<SettingSheetType>) => {
      state.sheetType = action.payload
    },
  },
})

export const { setSettingsSheetType } = settingsSlice.actions

export const selectSettingsSheetType = (state: RootState) => state.settings.sheetType

export default settingsSlice.reducer
