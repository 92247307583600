// util that will keep the different types of table cells the same size
// the ui requires spacers and so if we can just add empty cells it makes life easy
import { UseViewSize } from '../../core/UseViewSize'
import { Box } from '@chakra-ui/react'
import React from 'react'

// a standard sized table cell that you can build a set of rows/columns around
export const TableCell = (props: { children?: any; width?: string; height?: string }) => {
  const { children, width, height } = props
  const { isMobile } = UseViewSize()
  

  const h = height ? height : '40px'
  const w = width ? width : isMobile ? '150px' : '200px'

  const borderRadius = '8px'
  const margin = '0 0 8px 0'

  return (
    <Box borderRadius={borderRadius} h={h} w={w} m={margin} overflow="hidden">
      {children}
    </Box>
  )
}

