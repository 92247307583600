import React from 'react'
import { UseViewSize } from '../../../core/UseViewSize'
import { InventoryCount } from '../../../data/inventory/interfaces'
import { Box, Divider, Flex, Progress, Text } from '@chakra-ui/react'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { generatePath, Link } from 'react-router-dom'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { ROUTES } from "../../../appRoutes";

export const InventoryAuditList = (props: { audits: InventoryCount[] }) => {
  const { audits } = props
  const { isMobile } = UseViewSize()
  return (
    <>
      {!isMobile && <TitleRow />}
      {audits.map((audit) => {
        return <Row key={audit.id} inventoryCount={audit} />
      })}
    </>
  )
}

export const TitleRow = () => {
  return (
    <>
      <Flex justify="space-between" p="24px 0">
        <Box w="150px" pl="12px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Name
          </Text>
        </Box>
        <Box w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Staff
          </Text>
        </Box>
        <Flex justify="center" w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Progress
          </Text>
        </Flex>
        <Flex justify="center" w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Items
          </Text>
        </Flex>
        <Flex justify="center" w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Stock Cost
          </Text>
        </Flex>
        <Box w="24px" />
      </Flex>
      <Divider />
    </>
  )
}
export const Row = (props: { inventoryCount: InventoryCount }) => {
  const { inventoryCount } = props
  const { salonId } = UseBaseApiParams()
  const statusHex = !!inventoryCount.completedAt ? COLORS.shades_neutral_500 : COLORS.success_500
  const statusLabel = !!inventoryCount.completedAt ? 'Complete' : 'In Progress'
  const { isMobile } = UseViewSize()
  const url = generatePath(ROUTES.inventoryCount, { salonId, countId: inventoryCount.id })
  const progressNumber =
    inventoryCount.progressItemCount > 0 && inventoryCount.totalItemCount > 0
      ? (inventoryCount.progressItemCount / inventoryCount.totalItemCount) * 100
      : 0
  return (
    <Link to={url}>
      <Box p="12px 0">
        <Flex justify="space-between" p="12px 0">
          <Box w={!isMobile ? '150px' : ''}>
            <Flex gridGap="12px" align="center">
              <Box backgroundColor={statusHex} h="50px" w="4px" minW="4px" borderRadius="50px" />
              <Flex flexDirection="column">
                <Text color={statusHex} variant="caption2" fontWeight="bold">
                  {statusLabel}
                </Text>
                <Text variant="body">{inventoryCount.title}</Text>
                <Text variant="small-subtitle" fontWeight="normal">
                  {buildDateYYYYMMDD(inventoryCount.createdAt)}
                </Text>
              </Flex>
            </Flex>
          </Box>
          {!isMobile && (
            <>
              <Flex align="center" w="150px">
                {inventoryCount.assignedUser.firstName}
              </Flex>
              <Flex justify="center" w="150px" align="center" gridGap="12px">
                <Progress colorScheme="brand.lavender" borderRadius="15px" size="sm" w="100px" value={progressNumber} />{' '}
                <Text textAlign="right" w="70px">
                  {inventoryCount.progressPercentage}
                </Text>
              </Flex>
              <Flex justify="center" w="150px" align="center">
                {inventoryCount.progressItemCount}/{inventoryCount.totalItemCount}
              </Flex>
              <Flex justify="center" w="150px" align="center">
                ${inventoryCount.stockCost}
              </Flex>
              <Flex align="center">
                <MaterialIcon name="chevron_right" />
              </Flex>
            </>
          )}
          {isMobile && (
            <Flex align="center">
              <Box w="100px">
                <Text>${inventoryCount.stockCost}</Text>
                <Text variant="small-subtitle">{inventoryCount.totalItemCount} Items</Text>
              </Box>
              <MaterialIcon name="chevron_right" />
            </Flex>
          )}
        </Flex>
        <Divider />
      </Box>
    </Link>
  )
}
