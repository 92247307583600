import { useState } from 'react'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { Box, Button, Flex, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { UseViewSize } from '../../core/UseViewSize'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '../../hooks'
import { CSVLink } from 'react-csv'

import {
  reduceSelectedDateRange,
  selectCSVData,
  selectCSVTitle,
  selectReportsDateRangeType,
  selectUnusedCSV,
  selectUsedOrUnusedCSVType,
} from '../../data/report/slice'
import { StylistPage } from './StylistPage'
import { ExtensionLinesReportPage } from './ExtensionLinesReportPage'
import { CustomDateRangeModal } from '../../mini-lib/dates-and-times/CustomDateRangeModal'
import { DATE_RANGE_OPTION_MAP_WITH_CUSTOM, DateRangeType } from '../../mini-lib/dates-and-times/constants'
import { values } from 'lodash'
import { DropdownFilter } from '../../mini-lib/filters/DropdownFilter'
import { ClientReportPage } from './ClientPage'
import { ExportReportsCSVModal } from '../../mini-lib/modals/ExportReportsCSVModal'
import { SalonTypes } from '../../data/salon/interfaces'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { tabs } from  '../reports-v2/utils'

export const pages = {
  clientList: 'CustomerReportTable',
  clientSessionList: 'CustomerReportSessionTable',
  clientSessionDetail: 'CustomertReportSessionDetail',
  clientDetail: 'ClientDetailTable',
  stylistList: 'StylistReportTable',
  stylistDetail: 'StylistReportDetail',
  productList: `ExtensionReportTable`,
  productDetail: `ExtensionReportDetail`,
}

export const ExtensionReportsPage = (props: { selectedTab: number}) => {
  const breadcrumbs = [
    { label: 'Home', url: 'home' },
    { label: 'Reports', url: 'reports' },
  ]
  const dispatch = useDispatch()
  const { isMobile, isTablet } = UseViewSize()
  const { user } = UseBaseApiParams()
  const [productPage, setProductPage] = useState(pages.productList)
  const [stylistPage, setStylistPage] = useState(pages.stylistList)
  const [clientPage, setClientPage] = useState(pages.clientList)
  const csvType = useAppSelector(selectUsedOrUnusedCSVType) // returns a string if data is unused or used csv type
  const unusedCSV = useAppSelector(selectUnusedCSV) //getting unusedcsv data
  const csv = useAppSelector(selectCSVData) // getting used csv data + all other csv data
  const csvdata: any = csvType === 'unused' ? unusedCSV : csv
  const csvTitle = useAppSelector(selectCSVTitle)
  const selectedDateRange = useAppSelector(selectReportsDateRangeType)

  const [isOpen, setIsOpen] = useState(false)
  const [isExportModalIsOpen, setExportModalIsOpen] = useState(false)

  const onDateRangeSelect = (option: DateRangeType) => {
    if (option === 'custom') {
      setIsOpen(true)
    } else {
      dispatch(reduceSelectedDateRange(option))
      setIsOpen(false)
    }
  }

  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Reports" breadcrumbs={breadcrumbs} />}
      content={
        <Box width="100%">
          <ExportReportsCSVModal
            buttonText='Export Selected CSV files'
            modalHeader='Select Files for Export'
            csvDataList={csvdata}
            csvTitle={csvTitle}
            keyToDisplay='stylistName'
            showTotalFor={['cost', 'mark up', 'price']}
            setIsModalOpen={setExportModalIsOpen}
            isModalOpen={isExportModalIsOpen}
          />
          <Tabs index={props.selectedTab} colorScheme="brand.lavender">
            <Flex w="100%" direction={isMobile || isTablet ? 'column-reverse' : 'row'} borderBottom="1px solid #BDBDBD">
              <Flex align={'center'} justify={'flex-end'} padding="4px" w="100%">
                {!isMobile && <Text>Filter By:</Text>}
                <Box pl={'10px'}>
                  <DropdownFilter
                    theme='lavender'
                    w="150px"
                    placeholder="Date Range"
                    value={selectedDateRange || null}
                    options={values(DATE_RANGE_OPTION_MAP_WITH_CUSTOM)}
                    onChange={(option: any) => onDateRangeSelect(option.value)}
                  />
                </Box>
                {isOpen && <CustomDateRangeModal setIsOpen={setIsOpen} isOpen={isOpen} />}
                <Box pl={'10px'} mr="24px">
                  {props.selectedTab === tabs.client && user?.currentSalonContext?.salonType === SalonTypes.salon ? (
                    <Button disabled={csvdata?.length <= 1} onClick={() => setExportModalIsOpen(true)} variant="round" bg="brand.midnight.500">
                      EXPORT CSV
                    </Button>
                  ) : (
                    <Button variant="round" bg="brand.midnight.500">
                      <CSVLink filename={csvTitle} data={csvdata ? csvdata : []}>
                        EXPORT CSV
                      </CSVLink>
                    </Button>
                  )}
                </Box>
              </Flex>
            </Flex>

            <TabPanels>
              <TabPanel maxW="1200px" p="12px 0">
                {props.selectedTab === tabs.extension && <ExtensionLinesReportPage page={productPage} setPage={setProductPage} />}
              </TabPanel>
              <TabPanel maxW="1200px" p="12px 0">
                {props.selectedTab === tabs.stylist && <StylistPage page={stylistPage} setPage={setStylistPage} />}
              </TabPanel>
              <TabPanel maxW="1200px" p="12px 0">
                {props.selectedTab === tabs.client && <ClientReportPage page={clientPage} setPage={setClientPage} />}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      }
    />
  )
}
