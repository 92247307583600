import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Gap } from "../../mini-lib/gap/Gap";
import { COLORS } from "../../mini-lib/theme/colors";
import { buildContextlessEmailUrl } from "../../data/settings/utils";

export const RefundPolicyPage = () => {
  return (
    <Flex justify='center'>
      <Box maxW='600px' w='100%'>
        <Gap/>
        <Text fontWeight='bold' variant='title2'>Refund Policy</Text>
        <Gap/>
        <Text fontWeight='bold'>Refund of Scale</Text>
        <Gap/>
        <Text fontStyle='italic'>No Refunds</Text>
        <Gap/>
        We do not accept returns or exchanges unless the scale you purchased is defective. If you receive a defective
        scale, please contact us at <a style={{ color: COLORS.lavender_500 }} target="_blank"
                                       href={buildContextlessEmailUrl()} rel="noreferrer">info@salonscale.com</a> with
        details of the scale and the defect. We will then contact
        you with information on how to proceed and if you are entitled to a credit or a replacement as a result of the
        defect. Scales included in bundled subscriptions will receive a replacement scale only as they are free with
        bundle purchase. Scales sold on sale will be credited for the sale price paid. If you are entitled to a
        replacement or credit, we will replace the product or credit the purchase price towards your subscription.
        Credits do not include any shipping and/or handling charges shown on the packaging slip or invoice. Any amounts
        credited will be for the scale only.
        <Gap/>
        Scales purchased on third party sites will abide by that site’s policies.
        <Gap/>
        <Gap/>
        <Text fontWeight='bold'>Scale Refund Process/Procedure:</Text>
        <Gap/>
        <Text fontWeight='bold'>What is this?</Text>
        <Gap/>
        If a customer receives a damaged/broken scale or their scale is defective (does not work properly) we will offer
        them either:
        <Gap/>

        <Box pl='24px'>

          A. A new scale to be sent out by us.

          <Gap/>
          <Text pl='24px'>OR</Text>
          <Gap/>

          B. A credit applied to their account for the price of the scale to be used towards their next subscription
          payment.
          <Gap/>
        </Box>
        <Gap/>
        <Text fontWeight='bold'>Who is this for?</Text>
        <Gap/>
        Any customer with a broken or defective scale that is requesting a refund (new scale or credit only).
        <Gap/>
        <Text fontWeight='bold'>When does this refund process happen?</Text>
        <Gap/>
        When a customer contacts us (via any method-email, phone, dm…) with a broken or defective scale.
        <Gap/>
        You may request photos of the scale if broken if needed to verify.
        <Gap/>
        If the customer says the scale is defective troubleshoot with them to determine if defective or user error is
        causing issues. Accuracy is not deemed as defective.
        <Gap/>
        <Text fontWeight='bold'>How does the credit/new scale shipment process happen?</Text>
        <Gap/>

        If you determine that the scale is broken/defective:
        <Gap/>
        <Box pl='24px'>

          Step 1: Offer to send them a new scale and proceed with shipping a scale to them.
          <Gap/>
          <Box pl='24px'>OR</Box>
          <Gap/>
          Step 2: Credit their account the price they paid for the scale to be used towards their next subscription
          payment.
          <Gap/>
        </Box>
        If the scale was included in a bundle then only a new scale will be sent (no credit) as it is considered free.
        <Gap/>
        <Text fontWeight='bold'>Refund of Subscription</Text>
        <Gap/>
        <Text fontStyle='italic'>No Refunds</Text>
        <Gap/>
        Paid Subscription fees are non-refundable, except when required by law. SalonScale will make every effort to
        assist you in the use of our application. If you need assistance with using the app or are experiencing
        technical difficulties, please contact us at <a style={{ color: COLORS.lavender_500 }} target="_blank"
                                                        href={buildContextlessEmailUrl()}
                                                        rel="noreferrer">info@salonscale.com</a> and our team will be
        happy to assist you. We
        offer a variety of educational resources to ensure that you are able to successfully use our service.
        Unfortunately, not using our service for personal reasons is not a valid reason for refund.
        <Gap/>
        Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance
        on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis,
        depending on the type of subscription plan you select when purchasing a Subscription. By subscribing to our
        service, you are authorizing SalonScale to automatically charge your credit card on file on it’s Billing Cycle
        renewal date. At the end of each Billing Cycle, your Subscription will automatically renew under the exact same
        conditions unless you cancel it or SalonScale cancels it on your behalf. If you wish to cancel your service
        before your next billing cycle, as the subscriber, it is your responsibility to do so and no refund will be made
        after this date. You may cancel your Subscription renewal either through your online account management page or
        by contacting SalonScale customer support team at <a style={{ color: COLORS.lavender_500 }} target="_blank"
                                                             href={buildContextlessEmailUrl()}
                                                             rel="noreferrer">info@salonscale.com</a>.

        <Gap/>
        <Gap/>
        <Text fontWeight='bold'>Refund of Subscription Process/Procedure:</Text>
        <Gap/>
        <Text fontWeight='bold'>What is this?</Text>
        <Gap/>
        When a customer reaches out and wants a refund of their subscription during or after their auto renewal date.
        <Gap/>
        <Text fontWeight='bold'>Who is this for?</Text>
        <Gap/>
        Any customer that reaches out and asks for a refund during or after their auto renewal.
        <Gap/>
        <Text fontWeight='bold'>When does this refund/cancellation process happen?</Text>
        <Gap/>
        When a customer contacts us (via any method-email, phone, dm…) and wants to cancel their subscription or says
        they did not authorize the auto renewal payment.
        <Gap/>

        <Text fontWeight='bold'>How does the refund of subscription process happen?</Text>
        <Gap/>
        If this occurs before their renewal date:
        <Gap/>
        <Box pl='24px'>
          1. Why do they want to cancel? Determine the reason and try to educate them on the success they can have by
          using
          the app and do not cancel subscription.
          <Gap/>
          <Box pl='24px'>OR</Box>
          <Gap/>
          2. If they do not wish to proceed, cancel their subscription on their behalf- no refund will be issued for any
          subscription time remaining.
        </Box>
        <Gap/>
        In good faith, within 24 hours of the subscription transaction we will reverse the charges on their behalf.
        <Gap/>
        If this occurs after their renewal date we will not refund them any remaining funds on their subscription as per
        our policy.
        <Gap/>
        <Box pl='24px'>
          1. Advise them that as per our policy, we do not offer refunds of their subscription payment and when they
          signed
          up for our service they accepted our terms of use acknowledging they read/understood this.
          <Gap/>
          2. Offer to assist them in using the app with educational resources from our Knowledge Hub including videos
          and
          reading material.
          <Gap/>
          3. Offer them assistance with using the app, a demo of the app or videos to share with their team.
          <Gap/>
          4. If the customer objects or asks to speak with a manager, fill out the <a
          style={{ color: COLORS.lavender_500 }} href="https://forms.gle/fdgthRh9BXpiWMB68" target="_blank"
          rel="noreferrer">Escalation Form</a> and once reviewed you will
          receive directions on how to proceed. Review and response will happen as soon as possible.
        </Box>
        <Gap/>
      </Box>
    </Flex>
  )
}
