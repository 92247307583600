import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { Box, Text } from '@chakra-ui/react'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { useAppSelector } from '../../hooks'
import { selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { Gap } from "../../mini-lib/gap/Gap";
import { buildSupportEmailUrl } from "../../data/settings/utils";
import { COLORS } from "../../mini-lib/theme/colors";
import { SalonTypes } from "../../data/salon/interfaces";
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGClientsIntroPage = () => {
  const {
    user,
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && user.userId) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, user.token, user.userId, checklistItemsLoaded])

  const canAddTeam = user?.currentSalonContext?.salonType === SalonTypes.salon
  const stepCodes = canAddTeam ? [CHECKLIST_CODES.addStylist, CHECKLIST_CODES.addClient] : [CHECKLIST_CODES.addClient]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="clients"
          title="ADD YOUR CLIENTS"
          description={
            <Box>
              Clients can be created ahead of time in your business center (where you are now!) or on-the-fly at the
              beginning of an appointment using the mobile app. Simply add the client’s first and last name and voila,
              you have created a client!
              <Gap/>
              <a target="_blank" href={buildSupportEmailUrl(user, salonId)} rel="noreferrer">
                <Text as='span' color={COLORS.lavender_500}>Email our Support Team</Text>
              </a> if you have an exported .csv file and we can upload your clients for you.

            </Box>
          }
          video={VIDEO_MAP.addingClients}
          actions={
            <SGActionButtons
              action={{
                label: 'Add Your Clients',
                url: generatePath(ROUTES.sgAddClients, {salonId}) + `?guide=${CHECKLIST_CODES.addClient}`,
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={stepCodes.length} stepsComplete={stepsCompleted || 0}/>}
          imgPath={require('../../assets/start-guide/add-clients.png').default}
        />
      </SGLayout>
    </>
  )
}
