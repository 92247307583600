import { divide } from 'lodash'
import { ColorBrandReport } from './interfaces'

export const buildColorBrandListReportCsv = (models: ColorBrandReport[]): ColorBrandReport[] | any => {
  return models
}

export const totalWastePercentageCalculator = (totalWasteProductGrams: number, totalProductGrams: number) => {
  if (totalWasteProductGrams && totalProductGrams) {
    return divide(totalWasteProductGrams, totalProductGrams) * 100
  }
  return 0
}

export const pluralizeNameIfRequired = (name: string, number: number) => {
  if(name !== 'unit') {
    return name
  }
  return number > 1 ? `${name}s` : name
}