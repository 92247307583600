export const ADMIN_ANALYTICS_LOADING = 'ADMIN_ANALYTICS_LOADING'

export const ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS = {
  date_range: 'date_range',
  total_active_subscriptions: 'total_active_subscriptions',
  total_solo_monthly_subscriptions: 'total_solo_monthly_subscriptions',
  total_solo_yearly_subscriptions: 'total_solo_yearly_subscriptions',
  total_team_monthly_subscriptions: 'total_team_monthly_subscriptions',
  total_team_yearly_subscriptions: 'total_team_yearly_subscriptions',
  total_new_subscriptions: 'total_new_subscriptions',
  total_trial_conversions: 'total_trial_conversions',
  total_subscription_churn: 'total_subscription_churn',
  total_trial_churn: 'total_trial_churn',
  total_salons_with_min_sessions: 'total_salons_with_min_sessions',
  total_sessions: 'total_sessions',
  new_solo_monthly_trials: 'new_solo_monthly_trials',
  new_solo_yearly_subscriptions: 'new_solo_yearly_subscriptions',
  new_solo_monthly_trial_conversions: 'new_solo_monthly_trial_conversions',
  new_solo_monthly_trial_churn: 'new_solo_monthly_trial_churn',
  total_existing_solo_monthly_churn: 'total_existing_solo_monthly_churn',
  total_existing_solo_yearly_churn: 'total_existing_solo_yearly_churn',
  new_team_monthly_trials: 'new_team_monthly_trials',
  new_team_yearly_subscriptions: 'new_team_yearly_subscriptions',
  new_team_monthly_trial_conversions: 'new_team_monthly_trial_conversions',
  new_team_monthly_trial_churn: 'new_team_monthly_trial_churn',
  total_existing_team_monthly_churn: 'total_existing_team_monthly_churn',
  total_existing_team_yearly_churn: 'total_existing_team_yearly_churn',
}


export const ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_DATA: { key: string, header: string, description: string }[] = [
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.date_range,
    header: 'date range',
    description: 'date range the data was queried for'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_active_subscriptions,
    header: 'total active subscriptions',
    description: 'all subscriptions that were active in the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_new_subscriptions,
    header: 'all new subscriptions',
    description: 'all subscriptions of any type created over the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_trial_conversions,
    header: 'all trial conversions',
    description: 'all subscriptions created from (time period start - 1 month to time period end - 1 month) that are now paying'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_subscription_churn,
    header: 'total churn',
    description: 'total number of accounts, including trials, that cancelled in the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_trial_churn,
    header: 'total trial churn',
    description: 'total number of trials that churned'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_salons_with_min_sessions,
    header: 'salons with sessions',
    description: 'number of salons that had 1 or more session over the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_sessions,
    header: 'total sessions',
    description: 'total number of sessions made over the time period'
  },

  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_solo_monthly_subscriptions,
    header: 'solo monthly: total subscriptions',
    description: 'all paying solo monthly subscriptions that were active in the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_solo_yearly_subscriptions,
    header: 'solo yearly: total subscriptions',
    description: 'all paying solo yearly subscriptions that were active in the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_team_monthly_subscriptions,
    header: 'team monthly: total subscriptions',
    description: 'all paying team monthly subscriptions that were active in the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_team_yearly_subscriptions,
    header: 'team yearly: total subscriptions',
    description: 'all paying team yearly subscriptions that were active in the time period'
  },

  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.new_solo_monthly_trials,
    header: 'solo monthly: new trials',
    description: 'number of new solo monthly trials created in the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.new_solo_yearly_subscriptions,
    header: 'solo yearly: new subscriptions',
    description: 'number of new solo yearly subscriptions created in the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.new_solo_monthly_trial_conversions,
    header: 'solo monthly: last months trial conversions',
    description: 'solo monthly subscriptions created from (time period start - 1 month to time period end - 1 month) that are now paying'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.new_solo_monthly_trial_churn,
    header: 'solo monthly: last months trial churn',
    description: 'number of solo monthly subscriptions that went from in trial to churned in the time period (includes subs that chargebee doesnt consider trials)'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_existing_solo_monthly_churn,
    header: 'solo monthly: paying sub churn',
    description: 'number of solo monthly subscriptions that went from paying to churned in the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_existing_solo_yearly_churn,
    header: 'solo yearly: paying sub churn',
    description: 'number of solo yearly subscriptions that went from paying to churned in the time period'
  },

  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.new_team_monthly_trials,
    header: 'team monthly: new trials',
    description: 'number of new team monthly trials created in the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.new_team_yearly_subscriptions,
    header: 'team yearly: new subsubscriptions',
    description: 'number of new team yearly subscriptions created in the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.new_team_monthly_trial_conversions,
    header: 'team monthly: last months trial conversions',
    description: 'team monthly subscriptions created from (time period start - 1 month to time period end - 1 month) that are now paying'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.new_team_monthly_trial_churn,
    header: 'team monthly: last months trial churn',
    description: 'number of team monthly subscriptions that went from in trial to churned in the time period (includes subs that chargebee doesnt consider trials)'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_existing_team_monthly_churn,
    header: 'team monthly: paying sub churn',
    description: 'number of team monthly subscriptions that went from paying to churned in the time period'
  },
  {
    key: ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_KEYS.total_existing_team_yearly_churn,
    header: 'team yearly : paying sub churn',
    description: 'number of team yearly subscriptions that went from paying to churned in the time period'
  },
]


export const ADMIN_USER_ANALYTICS_COLUMN_KEYS = {
  created_at: 'created_at',
  user_id: 'user_id',
  salon_id: 'salon_id',
  first_name: 'first_name',
  last_name: 'last_name',
  email: 'email',
  status: 'status',
  plan_id: 'plan_id',
  plan_amount: 'plan_amount',
  salon_name: 'salon_name',
}
export const ADMIN_USER_ANALYTICS_COLUMN_DATA: { key: string, header: string, description: string }[] = [
  {
    key: 'created_at',
    header: 'created date',
    description: 'date the subscription was created',
  },
  {
    key: 'user_id',
    header: 'user id',
    description: 'user_id',
  },
  {
    key: 'salon_id',
    header: 'salon id',
    description: 'salon_id',
  },
  {
    key: 'first_name',
    header: 'first name',
    description: 'first_name',
  },
  {
    key: 'last_name',
    header: 'last name',
    description: 'last_name',
  },
  {
    key: 'phone',
    header: 'phone',
    description: 'phone',
  },
  {
    key: 'email',
    header: 'email',
    description: 'email',
  },
  {
    key: 'status',
    header: 'status',
    description: 'status',
  },
  {
    key: 'plan_id',
    header: 'chargebee plan',
    description: 'plan_id',
  },
  {
    key: 'salon_name',
    header: 'salon name',
    description: 'salon_name',
  },
  {
    key: 'chargebee_customer_link',
    header: 'chargebee customer link',
    description: 'click to go to chargebee customer',
  },
  {
    key: 'chargebee_subscription_link',
    header: 'chargebee sub link',
    description: 'click to go to chargebee subscription',
  },
  {
    key: 'admin_salon_link',
    header: 'salon link',
    description: 'click to go to the admin salon dashboard',
  },
  {
    key: 'admin_user_link',
    header: 'user link',
    description: 'click to go to the admin user dashboard',
  },

]
