import { SessionMetaLara } from '../../../data/sessions/interfaces'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import {
  reduceIsLaborSheetVisible,
  selectActiveSessionLabors,
  selectIsLaborSheetVisible,
} from '../../../data/sessions/slice'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { SessionLaborSheet } from './SessionLaborSheet'
import { SESSION_STATUS_OPTIONS } from '../../../data/sessions/constants'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'

import { formatCentsToDollars } from "../../../mini-lib/units/money";
import {getSessionLaborLaraTotalCost} from "../../../data/sessions/utils";

export const SessionLabor = (props: { sessionMeta: SessionMetaLara}) => {
  const dispatch = useDispatch()
  const { sessionMeta } = props
  const [show, setShow] = useState(true)
  const isLaborSheetVisible = useAppSelector(selectIsLaborSheetVisible)

  // lara session apis
  //
  //
  const sessionLabor = useAppSelector(selectActiveSessionLabors)

  //
  //
  //////
  return (
    <>
      {isLaborSheetVisible && <SessionLaborSheet />}
      <Flex
        minH="60px"
        borderRadius="8px"
        bg={COLORS.skylight_50}
        align="center"
        justify="space-between"
        p="24px"
        onClick={() => setShow(!show)}
        cursor="pointer"
      >
        <Text variant="title3">Services</Text>
        <Flex align="center" gridGap="24px">
          <Text>${sessionLabor ? formatCentsToDollars(getSessionLaborLaraTotalCost(sessionLabor)) : 0}</Text>
          <MaterialIcon pr="4px" name={show ? 'arrow_drop_down' : 'arrow_drop_up'} />
        </Flex>
      </Flex>
      {show && (
        <>
          {sessionLabor?.map((item) => {
            const durationOrQuantity = item.type === 'hourly' ? item.durationSeconds / 60 / 60 : item.durationSeconds
            const total = item.pricePerHour * (durationOrQuantity)
            return (
              <Box
                key={item.id}
                onClick={() => {
                  if (sessionMeta.status === SESSION_STATUS_OPTIONS.open) {
                    dispatch(reduceIsLaborSheetVisible(true))
                  }
                }}
                cursor={sessionMeta.status === SESSION_STATUS_OPTIONS.open ? 'pointer' : ''}
              >
                <Flex p="24px 12px" justify="space-between" pr="24px">
                  <Text>{item.name}</Text>
                  <Text>${formatCentsToDollars(total)}</Text>
                </Flex>
                <Divider />
              </Box>
            )
          })}
          {sessionLabor?.length === 0 && <EmptyBox h="200px" />}
          {sessionMeta.status === SESSION_STATUS_OPTIONS.open && (
            <Flex
              align="center"
              p="12px"
              onClick={() => {
                dispatch(reduceIsLaborSheetVisible(true))
              }}
              cursor="pointer"
            >
              <MaterialIcon colorhex={COLORS.skylight_500} name="add_circle" style={{ marginRight: '4px' }} />{' '}
              <Text color={COLORS.skylight_500}>add services</Text>
            </Flex>
          )}
        </>
      )}
    </>
  )
}
