import { Notification } from './interfaces'
import { parseISO, isBefore } from 'date-fns'

export const separateNotificationsByDate = (notifications: { [key: number]: Notification } | null) => {
    const now = new Date();
    const pendingNotifications: { [key: number]: Notification } = {};
    const sentNotifications: { [key: number]: Notification } = {};
  
    notifications && 
    Object.values(notifications).forEach((notification: Notification) => {
      if (notification && notification.visibleDate) {
        const parsedDate = parseISO(String(notification.visibleDate));
        if (isBefore(parsedDate, now)) {
          sentNotifications[notification.id] = notification
        } else {
          pendingNotifications[notification.id] = notification
        }
      }
    });
    return { pendingNotifications, sentNotifications };
  };