// @flow
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Input,
  InputGroup,
  Text,
} from '@chakra-ui/react'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { FileUpload } from '../../mini-lib/file-upload/FileUpload'
import { toast } from 'react-toastify'
import { isImageType } from '../../core/validators'
import { COLORS } from '../../mini-lib/theme/colors'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { SalonScaleIcon } from '../../mini-lib/icons/SalonScaleIcon'
import { APILaraPatchSalon, Salon } from '../../data/salon/interfaces'
import { dispatchLaraPatchSalon } from '../../data/salon/api'
import { TimezoneDropdown } from '../../mini-lib/time/TimezoneDropdown'
import { dispatchUploadFile } from '../../core/file-upload/api'
import { useAppSelector } from '../../hooks'
import { selectFileUrlUploadByName } from '../../core/file-upload/slice'
import { selectActiveSalonContext } from '../../data/salon/slice'
import { FormikError } from "../../mini-lib/formik-utils/FormikError";

export const SalonSettingsSideSheet = (props: { salon: Salon; show: boolean; setShow: (show: boolean) => void }) => {
  const dispatch = useDispatch()
  const { show, setShow } = props
  const { user, salonId } = UseBaseApiParams()
  const uploadName = `salon-photo-${salonId}`
  const uploadedPhotoUrl = useAppSelector((state) => selectFileUrlUploadByName(state, uploadName))
  const salon = useAppSelector(selectActiveSalonContext)

  const initialName = salon?.name || ''
  const [name, setName] = useState(initialName)

  const initialTimezone = salon?.timezone || null
  const [localTimezone, setLocalTimezone] = useState<string | null>(initialTimezone)

  const initialPhotoUrl = salon?.photoUrl || null
  const [photoUrl, setPhotoUrl] = useState<string | null>(initialPhotoUrl)

  // if photo is uploaded successfully update the photoUrl
  useEffect(() => {
    if (uploadedPhotoUrl) {
      setPhotoUrl(uploadedPhotoUrl)
    }
  }, [uploadedPhotoUrl])

  const onSubmit = () => {
    if (name) {
      const request: APILaraPatchSalon = {
        name: name || '',
        photo_url: photoUrl,
        timezone: localTimezone,
      }
      dispatch(
        dispatchLaraPatchSalon({
          token: user.token,
          userId: user.userId,
          salonId,
          currentSalonContext: user.currentSalonContext,
          request,
          syncToPipedrive: false
        }),
      )
    }

    if (photoUrl) {
      // todo: upload salon image here and add that to the request (similar to empty bowl flow)
    }
    setShow(false)
  }

  const onFileUpload = (file: File | null) => {
    if (file && isImageType(file.type)) {
      dispatch(dispatchUploadFile({ token: user.token, uploadName, file }))
    } else {
      toast.error('Please enter a valid image')
    }
  }
  const nameError = name.length === 0 ? 'Please enter a name' : ''
  return (
    <Drawer placement="right" onClose={() => setShow(false)} isOpen={show}>
      <DrawerOverlay />
      <DrawerContent>
        <>
          <DrawerHeader>
            <Text variant="title2">Update Salon</Text>
          </DrawerHeader>
          <DrawerBody>
            <Flex justify="center">
              <Circle size="100px" position="relative" bg={COLORS.lavender_100} p="4px">
                <Circle overflow="hidden">
                  {photoUrl && <Image h="90px" w="90px" src={photoUrl} />}
                  {!photoUrl && <SalonScaleIcon size="90px" name="home" />}
                  <Circle
                    cursor="pointer"
                    borderRadius="100px"
                    size="32px"
                    p="4px"
                    bg="white"
                    border={`1px solid ${COLORS.lavender_500}`}
                    position="absolute"
                    bottom={0}
                    right={0}
                  >
                    <FileUpload
                      override={<MaterialIcon colorhex={COLORS.lavender_500} name="photo_camera" />}
                      onFileUpload={(file) => onFileUpload(file)}
                    />
                  </Circle>
                </Circle>
              </Circle>
            </Flex>

            <Box h="32px" />

            <InputGroup
              borderColor={nameError ? COLORS.danger : COLORS.shades_neutral_200}
              borderRadius="100px"
              width="100%"
              alignItems="center"
            >
              <Input borderRadius="100px" value={name} onChange={(evt) => setName(evt.target.value)} />
            </InputGroup>
            <FormikError showError={!!nameError} errorText={nameError} />

            <Box h="24px" />

            <TimezoneDropdown value={localTimezone} onChange={(tz) => setLocalTimezone(tz.value)} />

            <Box h="24px" />
          </DrawerBody>
          <DrawerFooter>
            <Button minW="100px" variant="round-outline" mr={3} onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button minW="100px" variant="round" colorScheme="brand.lavender" onClick={() => onSubmit()}>
              Save
            </Button>
          </DrawerFooter>
        </>
      </DrawerContent>
    </Drawer>
  )
}
