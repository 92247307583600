import {
  reduceSetProductFilters,
  selectAllCategories,
  selectLineNamesFilteredByVendorNames,
  selectProductFilters,
  selectVendorNames,
} from '../../../data/products/slice'

import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { UseViewSize } from '../../../core/UseViewSize'
import { STOCK_LEVEL_OPTIONS } from '../../../data/inventory/constants'
import { getNumFiltersActive } from '../../../data/products/utils'
import { EMPTY_PRODUCT_FILTERS } from '../../../data/products/constants'
import { SearchFilter } from '../../../mini-lib/filters/SearchFilter'
import { FilterBar } from '../../../mini-lib/filters/FilterBar'
import {
  ProductOnHandFilterOptions,
  ProductPricingFilterOptions,
  ProductTargetFilterOptions
} from "../../../data/sessions/interfaces";
import { SelectInput } from "../../../mini-lib";

export const ProductFilters = (props: {
  showLineFilter?: boolean
  showCategoryFilter?: boolean
  showVendorFilter?: boolean
  showStockLevelFilter?: boolean
  showPricingFilter?: boolean
  showOnHandFilter?: boolean
  showTargetFilter?: boolean
}) => {
  const dispatch = useDispatch()

  const filters = useAppSelector(selectProductFilters)
  const vendorNames = useAppSelector(selectVendorNames)
  const lineNames = useAppSelector(selectLineNamesFilteredByVendorNames)
  const allCategories = useAppSelector(selectAllCategories)
  const { isMobile } = UseViewSize()
  const {
    showLineFilter = true,
    showCategoryFilter = true,
    showVendorFilter = true,
    showTargetFilter = false,
    showOnHandFilter = false,
    showPricingFilter = false,
    showStockLevelFilter = false,
  } = props

  return (
    <>
      <FilterBar
        search={
          <SearchFilter
            placeholder="Search by name"
            value={filters.searchText || ''}
            onChange={(searchText) => dispatch(reduceSetProductFilters({ ...filters, ...{ searchText } }))}
            onClear={() => dispatch(reduceSetProductFilters({ ...filters, ...{ searchText: '' } }))}
          />
        }
        filters={
          <>
            {showPricingFilter && (
              <SelectInput
                w={isMobile ? '49%' : '150px'}
                label={isMobile ? 'Pricing' : 'All Pricing'}
                value={filters.pricingFilter}
                options={ProductPricingFilterOptions}
                onChange={(value) =>
                  dispatch(reduceSetProductFilters({ ...filters, ...{ pricingFilter: value } }))
                }
              />
            )}
            {showOnHandFilter && (
              <SelectInput
                variant='material'
                border='rounded'
                allowFocus={true}
                w={isMobile ? '49%' : '150px'}
                label={isMobile ? 'On-hand' : 'All on-hand'}
                value={filters.onHandFilter}
                options={ProductOnHandFilterOptions}
                onChange={(value) =>
                  dispatch(reduceSetProductFilters({ ...filters, ...{ onHandFilter: value } }))
                }
              />
            )}
            {showTargetFilter && (
              <SelectInput
                variant='material'
                border='rounded'
                allowFocus={true}
                w={isMobile ? '49%' : '150px'}
                label={isMobile ? 'Target' : 'All Targets'}
                value={filters.targetFilter}
                options={ProductTargetFilterOptions}
                onChange={(value) =>
                  dispatch(reduceSetProductFilters({ ...filters, ...{ targetFilter: value } }))
                }
              />
            )}
            {showCategoryFilter && (
              <SelectInput
                variant='material'
                border='rounded'
                w={isMobile ? '49%' : '150px'}
                label={isMobile ? 'Categories' : 'All Categories'}
                value={filters.categoryName}
                options={allCategories.map((name) => ({ label: name, value: name }))}
                onChange={(value) =>
                  dispatch(reduceSetProductFilters({ ...filters, ...{ categoryName: value } }))
                }
                allowFocus={true}
              />
            )}
            {showVendorFilter && (
              <SelectInput
                variant='material'
                border='rounded'
                allowFocus={true}
                w={isMobile ? '49%' : '150px'}
                label={isMobile ? 'Brands' : 'All Brands'}
                value={filters.vendorName}
                options={vendorNames.map((name) => ({ label: name, value: name }))}
                onChange={(value) =>
                  dispatch(reduceSetProductFilters({ ...filters, ...{ vendorName: value } }))
                }
              />
            )}
            {showLineFilter && (
              <SelectInput
                variant='material'
                border='rounded'
                allowFocus={true}
                w={isMobile ? '49%' : '150px'}
                label={isMobile ? 'Lines' : 'All Lines'}
                value={filters.lineName}
                options={lineNames.map((name) => ({ label: name, value: name }))}
                onChange={(value) => dispatch(reduceSetProductFilters({ ...filters, ...{ lineName: value } }))}
              />
            )}
            {showStockLevelFilter && (
              <SelectInput
                variant='material'
                border='rounded'
                allowFocus={true}
                w={isMobile ? '49%' : '150px'}
                label={isMobile ? 'Stock Level' : 'All Stocks'}
                value={filters.stockLevel}
                options={STOCK_LEVEL_OPTIONS}
                onChange={(value) =>
                  dispatch(reduceSetProductFilters({ ...filters, ...{ stockLevel: value } }))
                }
              />
            )}
          </>
        }
        onClear={() => dispatch(reduceSetProductFilters(EMPTY_PRODUCT_FILTERS))}
        numFiltersActive={getNumFiltersActive(filters)}
      />
    </>
  )
}

