import { Box, Flex, Spinner } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseViewSize } from '../../core/UseViewSize'
import { dispatchListPermissionSettings } from '../../data/settings/api'
import { selectPermissionSettings } from '../../data/settings/slice'
import { useAppSelector } from '../../hooks'
import { TableCell } from '../../mini-lib/table/Table'
import { BRAND_THEMES } from '../../theme'
import { PermissionCheckBoxCell, PermissionTitleCell, RoleTitleCell } from './PermissionTableCells'
import { getPermissionCellWidth } from '../../data/settings/utils'
import { ReleaseLabour } from "../../mini-lib/flags/Release";

export const PermissionSettings = () => {
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()

  const permissionSettings = useAppSelector(selectPermissionSettings)
  const permissionSettingsLoaded = !!permissionSettings

  useEffect(() => {
    if (!permissionSettingsLoaded) {
      dispatch(dispatchListPermissionSettings(token, salonId))
    }
  }, [dispatch, token, salonId, permissionSettingsLoaded])
  const releaseLabour = ReleaseLabour();
  const permissionNames = [
    'Home',
    'Color Lines',
    'Addons',
    'Extensions',
    ...(releaseLabour ? ['Labor'] : []),
    'Inventory Snapshot',
    'Stock Alerts',
    'Inventory Count',
    'Add Stock',
    'Stylists',
    'Reports',
    'Orders',
    'Settings',
  ]

  const { isMobile } = UseViewSize()
  return (
    <>
      {!permissionSettingsLoaded && (
        <Flex h="100vh" mt="33vh" justify="center">
          <Spinner />
        </Flex>
      )}
      {permissionSettingsLoaded && (
        <Flex>
          <PermissionTitleCol permissionNames={permissionNames} />
          <Flex ml={isMobile ? '12px' : ''} overflowX="scroll">
            {permissionSettings?.map((teamMember, key) => {
              return (
                <RoleCol key={key} permissionNames={permissionNames} roleName={teamMember.role} id={teamMember.id} />
              )
            })}
          </Flex>
        </Flex>
      )}
    </>
  )
}

export const PermissionTitleCol = (props: { permissionNames: string[] }) => {
  const { permissionNames } = props
  const { isMobile } = UseViewSize()
  return (
    <Box m={isMobile ? '' : '12px'}>
      <TableCell width={getPermissionCellWidth(isMobile)} />
      <>
        {permissionNames.map((name, key) => {
          return (
            <TableCell width={getPermissionCellWidth(isMobile)} key={key}>
              <PermissionTitleCell permissionName={name} theme={BRAND_THEMES.midnight} />
            </TableCell>
          )
        })}
      </>
    </Box>
  )
}

export const RoleCol = (props: { permissionNames: string[]; roleName: string; id: number }) => {
  const { permissionNames, roleName, id } = props
  const { isMobile } = UseViewSize()

  return (
    <Box m={isMobile ? '0 12px 0 0' : '12px'}>
      <TableCell width={getPermissionCellWidth(isMobile)}>
        <RoleTitleCell roleName={roleName} />
      </TableCell>

      <>
        {permissionNames.map((permission, key) => {
          return (
            <Flex key={key}>
              <TableCell width={getPermissionCellWidth(isMobile)}>
                <PermissionCheckBoxCell role={roleName} id={id} permission={permission} />
              </TableCell>
            </Flex>
          )
        })}
      </>
    </Box>
  )
}
