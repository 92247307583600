import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Flex } from '@chakra-ui/react'
import { generatePath, Redirect } from 'react-router-dom'

import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { InventoryTabs, LINES_TABS } from '../InventoryTabs'
import {
  reduceInventoryAddStockModalVisibility,
  reduceInventoryAuditModalVisibility,
  selectInventoryCounts,
  selectInventoryCountsPagination,
  selectRedirectInventoryCountId,
} from '../../../data/inventory/slice'
import { useAppSelector } from '../../../hooks'
import { dispatchListInventoryCounts } from '../../../data/inventory/api'
import { InventoryAuditList } from './InventoryAuditList'
import { InventoryCountFilters } from '../filters/InventoryCountFilters'
import { EMPTY_INVENTORY_COUNT_FILTERS, INVENTORY_COUNT_TYPES } from '../../../data/inventory/constants'
import { assign } from 'lodash'
import { filterInventoryCounts } from '../../../data/inventory/utils'
import { InventoryAuditModal } from './InventoryAuditModal'
import { Loading } from '../../../mini-lib/loading/Loading'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { EmptySearch } from '../../../mini-lib/empty/EmptySearch'
import { UseViewSize } from '../../../core/UseViewSize'
import { HelpStepper, Step } from '../../start-guide/common-components/HelpStepper'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { COLORS } from '../../../mini-lib/theme/colors'
import { HelpModal } from '../../start-guide/common-components/HelpModal'
import { HelpPopover } from '../../start-guide/common-components/HelpPopover'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { ROUTES } from "../../../appRoutes";
import { HelpDrawer } from "../../start-guide/common-components/HelpDrawer";
import { ReleasePageHelp } from "../../../mini-lib/flags/Release";
import { VIDEO_MAP } from "../../../mini-lib/video/constants";

export const InventoryAuditsPage = () => {
  const dispatch = useDispatch()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Inventory', url: generatePath(ROUTES.inventorySnapshot, { salonId }) },
    { label: 'Inventory Count' },
  ]
  const { isMobile } = UseViewSize()
  const queryParams: any = UseQueryParams()
  const inventoryCounts = useAppSelector(selectInventoryCounts)
  const inventoryCountsPagination = useAppSelector(selectInventoryCountsPagination)
  const inventoryCountsLoaded = !!inventoryCounts
  const initialFilters = assign({}, EMPTY_INVENTORY_COUNT_FILTERS, { countTypes: [INVENTORY_COUNT_TYPES.inventory] })
  const [filters, setFilters] = useState(initialFilters)
  const filteredCounts = inventoryCounts ? filterInventoryCounts(inventoryCounts, filters) : null
  const allAudits = inventoryCounts ? filterInventoryCounts(inventoryCounts, initialFilters) : null

  const helpSteps: Step[] = [
    {
      title: 'Create a Count',
      state: 'current',
      link: generatePath(ROUTES.inventoryAudits, { salonId }) + `?guide=${CHECKLIST_CODES.inventoryCompleteCount}`,
    },
    {
      title: 'Set Quantities',
      state: 'incomplete',
      link: generatePath(ROUTES.inventoryAudits, { salonId }) + `?guide=${CHECKLIST_CODES.inventoryCompleteCount}`,
    },
  ]
  useEffect(() => {
    dispatch(reduceInventoryAddStockModalVisibility(false))
  }, [dispatch])
  useEffect(() => {
    if (!inventoryCountsLoaded) {
      dispatch(dispatchListInventoryCounts({ token, salonId }))
    }
  }, [dispatch, token, salonId, inventoryCountsLoaded])

  const releasePageHelp = ReleasePageHelp()
  // when an inventory count gets created it sets the redirect state
  const redirectInventoryCountId = useAppSelector(selectRedirectInventoryCountId)
  if (redirectInventoryCountId) {
    const guideParam = queryParams.get('guide')
    const guideQp =
      guideParam === CHECKLIST_CODES.inventoryCompleteCount ? `?guide=${CHECKLIST_CODES.inventoryCompleteCount}` : ''
    const url = generatePath(ROUTES.inventoryCount, { salonId, countId: redirectInventoryCountId }) + guideQp
    // redirect to count page
    return <Redirect to={url} />
  }
  return (
    <>
      {releasePageHelp && (
          <HelpDrawer
            video={VIDEO_MAP.inventoryCount}
            title={`Let’s take a look inside Inventory Count`}
            content={`Once you do your main inventory count thru the start guide you can decide if you want to do annual quarterly or monthly.  You dont have to do full inventory counts either, you can break them into permanents, demis etc.  This allows you to keep tabs on your inventory over the year and make a large job into a multiple smaller ones.  Once set up you can create orders based on what you use and then replenish your stock with add stock. `}
          />
        )}
      <HelpModal guideType={CHECKLIST_CODES.inventoryCompleteCount} title="Let’s make your first count!" />
      <PageLayout
        variant="full"
        top={
          <HelpStepper
            guideType={CHECKLIST_CODES.inventoryCompleteCount}
            title="Complete your first count"
            steps={helpSteps}
            bgHex={COLORS.lavender_100}
            borderHex={COLORS.lavender_500}
          />
        }
        header={
          <PageHeader
            showHelp={true}
            title="Inventory"
            breadcrumbs={breadcrumbs}
            actions={
              <HelpPopover
                guideTypes={[CHECKLIST_CODES.inventoryCompleteCount]}
                children={
                  <Button
                    colorScheme="brand.midnight"
                    variant="round"
                    onClick={() => {
                      dispatch(reduceInventoryAuditModalVisibility(true))
                    }}
                  >
                    {isMobile ? 'New Count' : 'Start New Count'}
                  </Button>
                }
                description="Click start new count to create a new count"
                stepIndex={0}
                placement="left"
              />
            }
          />
        }
      >
        <InventoryTabs selectedTab={LINES_TABS.inventoryCount} />
        <Box h="24px" />
        <InventoryCountFilters
          variant="audits"
          filters={filters}
          setFilters={setFilters}
          showDateFilters={true}
          showCountTypeFilter={false}
          showProgressFilter={true}
        />
        <Box h="24px" />
        {filteredCounts && filteredCounts.length > 0 && <InventoryAuditList audits={filteredCounts} />}
        {inventoryCountsPagination && inventoryCountsPagination.currentPage !== inventoryCountsPagination.lastPage && (
          <Flex justify="center" p="12px">
            <Button
              colorScheme="brand.lavender"
              variant="round-outline"
              onClick={() => {
                dispatch(
                  dispatchListInventoryCounts({
                    token: token,
                    salonId,
                    pageNumber: inventoryCountsPagination.currentPage + 1,
                  }),
                )
              }}
            >
              View More
            </Button>
          </Flex>
        )}
        {allAudits && allAudits.length === 0 && (
          <EmptyBox
            h="200px"
            title="You dont have any inventory counts yet"
            content="Click Start New Count to get started!"
          />
        )}
        {allAudits && allAudits.length > 0 && filteredCounts?.length === 0 && (
          <EmptySearch variant="clear">No counts matched your filters</EmptySearch>
        )}
        {!filteredCounts && <Loading />}
      </PageLayout>
      <InventoryAuditModal />
    </>
  )
}
