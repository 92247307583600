import axios from 'axios'
import { UTM_KEYS, UtmParams } from '../utm'
import { GetServerBaseUrl } from "../../env";
import { getLocalStorageItem } from "../../core/localStorage";
import { getEnvConfig } from "../../config";

// active campaign dev settings (contains key/base url/doc links- https://salonscale.activehosted.com/app/settings/developer
// contact schema - https://developers.activecampaign.com/reference/create-a-new-contact
// create contact postman api - https://www.postman.com/acdevrel/workspace/activecampaign-developer-relations/request/19351179-063b5857-333e-4b5a-ae29-e2d036da9f25?tab=body

// note: at the time of writing this the field ids are not available in the active campaign ui for some reason
// also the custom field/value stuff does not take the field name, it needs the field's id
//
//
// hopefully they improve it in the future but this is how i got the ids
//
// 1. go to an active campaign contact
// 2. add data to the existing field there that you want to find the id for
// 3. call a get request on that contact
// 4. search for the data you entered (not the field name, the data you entered in the field)
// 5. find the id associated with your data and add it below associated with the field name
//
export const ACTIVE_CAMPAIGN_FIELD_IDS = {
  allow_sms: 8,
  utm_campaign: 35,
  utm_content: 36,
  utm_medium: 37,
  utm_source: 38,
  utm_term: 39

}
export const createUpdateActiveCampaignContact = ( params: {
  email: string
  firstName?: string
  lastName?: string
  phone?: string | null
  allowSms?: boolean
  utmParams?: UtmParams
} ) => {


  const { email, firstName, lastName, phone, allowSms  , utmParams} = params
  const fieldValues: any[] = [
    {
      field: ACTIVE_CAMPAIGN_FIELD_IDS.allow_sms,
      value: allowSms ? 'yes' : 'no'
    }
  ]

  const url = `${GetServerBaseUrl('v3', 'lara')}/active-campaign/contacts`

  if (!!utmParams?.term) {
    fieldValues.push({
      field: ACTIVE_CAMPAIGN_FIELD_IDS[UTM_KEYS.term],
      value: utmParams.term,
    })
  }

  if (!!utmParams?.content) {
    fieldValues.push({
      field: ACTIVE_CAMPAIGN_FIELD_IDS[UTM_KEYS.content],
      value: utmParams.content,
    })
  }
  if (!!utmParams?.medium) {
    fieldValues.push({
      field: ACTIVE_CAMPAIGN_FIELD_IDS[UTM_KEYS.medium],
      value: utmParams.medium,
    })
  }
  if (!!utmParams?.campaign) {
    fieldValues.push({
      field: ACTIVE_CAMPAIGN_FIELD_IDS[UTM_KEYS.campaign],
      value: utmParams.campaign,
    })
  }
  if (!!utmParams?.source) {
    fieldValues.push({
      field: ACTIVE_CAMPAIGN_FIELD_IDS[UTM_KEYS.source],
      value: utmParams.source,
    })
  }
  const body = {
    "contact": {
      "email": email,
      "firstName": firstName,
      "lastName": lastName,
      "phone": phone,
      "fieldValues": fieldValues
    }
  }
  const localStorageEnv = getLocalStorageItem('env')
  const config = getEnvConfig()
  const isProduction = config.isProd
  if (localStorageEnv === 'prod' || isProduction) {
    return axios
      .post(url, body)
      .then(( response: any ) => {
        return response
      })
      .catch(( error ) => {
        throw error
      })
  } else {
    console.warn('hubspot requests will only be sent on production environment')
    return Promise.resolve('no request sent')
  }
}
