import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { useAppSelector } from '../../hooks'
import { selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";
import { SalonTypes } from "../../data/salon/interfaces";

export const SGLaborIntroPage = () => {
  const {
    user,
    salonId,
  } = UseBaseApiParams()

  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems

  useEffect(() => {
    if (!checklistItemsLoaded && user.userId) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, user.token, user.userId, checklistItemsLoaded])
  const stepCodes = [CHECKLIST_CODES.addLabor]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  const isSalon = user?.currentSalonContext?.salonType === SalonTypes.salon

  // todo: direct these routes to solo or team start guide once the pages exist
  const url = isSalon
    ? generatePath(ROUTES.laborTeamOnboard, { salonId }) + `?guide=${CHECKLIST_CODES.addLaborTeam}`
    : generatePath(ROUTES.laborSoloOnboard, { salonId }) + `?guide=${CHECKLIST_CODES.addLaborSolo}`
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="labor"
          title="ADD YOUR SERVICE MENUS"
          description={descriptionText}
          video={VIDEO_MAP.addAddons}
          actions={
            <SGActionButtons
              action={{
                label: 'Start Set-up',
                url: url,
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={stepCodes.length} stepsComplete={stepsCompleted || 0} />}
          imgPath={require('../../assets/start-guide/labor.png').default}
        />
      </SGLayout>
    </>
  )
}

// todo: add text here before launch
const descriptionText = `
`
