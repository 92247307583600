import { Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { GetServerBaseUrl } from '../../env'
import { REPORTS_LOADING_CONSTANT } from './constants'
import {
  AddonReportAddon,
  APIAddonReportAddon,
  APIClientDetailReportAddon,
  APIClientDetailReportLara,
  APIClientReportAddon,
  APIClientReportLara,
  APIExtensionOrProductReportDetailsLara,
  APIProductReport,
  APIStylistDetailReportAddon,
  APIStylistDetailReportLara,
  APIStylistReport,
  APIStylistReportAddon,
  ClientDetailReportAddon,
  ClientDetailReportLara,
  ClientReportAddon,
  ClientReportLara,
  ExtensionOrProductReportDetailsLara,
  ProductReport,
  StylistDetailReportAddon,
  StylistDetailReportLara,
  StylistReport,
  StylistReportAddon,
} from './interfaces'
import {
  mapAPIAddonClientsDetailToAddonClientsDetail,
  mapAPIAddonClientsToAddonClients,
  mapAPIAddonsToAddon,
  mapAPIAddonStylistsDetailToAddonStylistsDetail,
  mapAPIAddonStylistsToAddonStylists,
  mapAPIClientsDetailToClientsDetail,
  mapAPIClientsToClients,
  mapAPIProductReportsDetailToUnusedProductReportsDetail,
  mapAPIProductReportsDetailToUsedProductReportsDetail,
  mapAPIReportsToReports,
  mapAPIStylistsDetailExtensionToStylistsDetailExtension,
  mapAPIStylistsToStylists,
  mapStylistDetailToCSVForExtensions
} from './mappers'
import {
  reduceListAddonsReport,
  reduceListClientDetailReport,
  reduceListClientDetailReportAddon,
  reduceListClientReportAddon,
  reduceListClientStylistReport,
  reduceListExtensionDetailReportByBrand,
  reduceListProductExtensionDetailReportByBrand,
  reduceListProductReport,
  reduceListStylistDetailReportAddon,
  reduceListStylistDetailReportById,
  reduceListStylistReport,
  reduceListStylistReportAddon,
} from './slice'

export const apiGetAllReportsByKind = (props: {
  token: string
  salonId: number
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<ProductReport[]> => {
  const { token, salonId, dateStart, dateEnd, kind } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/products/?token=${token}&filter[kind]=${kind}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIProductReport[] } }) => {
      return mapAPIReportsToReports(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetReportsExtensionsbyBrand = (props: {
  token: string
  salonId: number
  brand: string
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<{
  usedProducts: ExtensionOrProductReportDetailsLara[]
  unusedProducts: ExtensionOrProductReportDetailsLara[]
}> => {
  const { token, salonId, brand, dateStart, dateEnd, kind } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/products/detail/?token=${token}&filter[brand]=${encodeURIComponent(brand)}&filter[kind]=${kind}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIExtensionOrProductReportDetailsLara[] } }) => {
      return {
        usedProducts: mapAPIProductReportsDetailToUsedProductReportsDetail(response.data.data),
        unusedProducts: mapAPIProductReportsDetailToUnusedProductReportsDetail(response.data.data),
      }
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetProductReportsDetailsForExtensions = (props: {
  token: string
  salonId: number
  brand: string
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<ExtensionOrProductReportDetailsLara[]> => {
  const { token, brand, dateStart, salonId, dateEnd, kind } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/products/detail/?token=${token}&filter[brand]=${encodeURIComponent(brand)}&filter[kind]=${kind}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIExtensionOrProductReportDetailsLara[] } }) => {
      return mapAPIProductReportsDetailToUsedProductReportsDetail(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetAllReportsStylist = (props: {
  token: string
  salonId: number
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<StylistReport[]> => {
  const { token, dateStart, salonId, dateEnd, kind } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/stylists?token=${token}&filter[kind]=${kind}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIStylistReport[] } }) => {
      return mapAPIStylistsToStylists(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetReportsStylistbyId = (props: {
  token: string
  salonId: number
  stylistId: number
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<{ products: StylistDetailReportLara[] }> => {
  const { token, salonId, stylistId, dateStart, dateEnd, kind } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/stylists/${stylistId}?token=${token}&filter[kind]=${kind}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIStylistDetailReportLara[] } }) => {
      return {
        products: mapAPIStylistsDetailExtensionToStylistsDetailExtension(response.data.data),
      }
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetAllClientDetailReport = (props: {
  token: string
  salonId: number
  clientId: number
  stylistId: number
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<ClientDetailReportLara[]> => {
  const { token, salonId, clientId, stylistId, dateStart, dateEnd, kind } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/clients/${clientId}/?token=${token}&filter[kind]=${kind}&filter[stylist_id]=${stylistId}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIClientDetailReportLara[] } }) => {
      return mapAPIClientsDetailToClientsDetail(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetAllClientReport = (props: {
  token: string
  salonId: number
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<ClientReportLara[]> => {
  const { token, salonId, dateStart, dateEnd, kind } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/clients/?token=${token}&filter[kind]=${kind}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIClientReportLara[] } }) => {
      return mapAPIClientsToClients(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetAllAddonReportsForAddons = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<AddonReportAddon[]> => {
  const { token, salonId, dateStart, dateEnd } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/reports/addons/?token=${token}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIAddonReportAddon[] } }) => {
      return mapAPIAddonsToAddon(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetAllAddonReportsForStylist = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<StylistReportAddon[]> => {
  const { token, salonId, dateStart, dateEnd } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/reports/addons/stylists/?token=${token}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIStylistReportAddon[] } }) => {
      return mapAPIAddonStylistsToAddonStylists(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetStylistDetailReportForAddons = (props: {
  token: string
  salonId: number
  stylistId: number
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<StylistDetailReportAddon[]> => {
  const { token, salonId, stylistId, dateStart, dateEnd } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''

  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/addons/stylists/${stylistId}/?token=${token}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIStylistDetailReportAddon[] } }) => {
      return mapAPIAddonStylistsDetailToAddonStylistsDetail(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetAllClientDetailReportForAddons = (props: {
  token: string
  salonId: number
  clientId: number
  stylistId: number
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<ClientDetailReportAddon[]> => {
  const { token, salonId, clientId, stylistId, dateStart, dateEnd } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/addons/clients/${clientId}/?token=${token}&filter[stylist_id]=${stylistId}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIClientDetailReportAddon[] } }) => {
      return mapAPIAddonClientsDetailToAddonClientsDetail(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetAllClientReportForAddons = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<ClientReportAddon[]> => {
  const { token, salonId, dateStart, dateEnd } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/reports/addons/clients/?token=${token}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIClientReportAddon[] } }) => {
      return mapAPIAddonClientsToAddonClients(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchGetAllClientReport = (props: {
  token: string
  salonId: number
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetAllClientReport(props).then((resp) => {
      dispatch(reduceListClientStylistReport(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetAllClientDetailReport = (props: {
  token: string
  salonId: number
  kind: string
  clientId: number
  stylistId: number
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetAllClientDetailReport(props).then((resp) => {
      dispatch(reduceListClientDetailReport(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetAllReportsStylists = (props: {
  token: string
  salonId: number
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetAllReportsStylist(props).then((resp) => {
      dispatch(reduceListStylistReport(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetReportsStylistsById = (props: {
  token: string
  salonId: number
  kind: string
  stylistId: number
  dateStart?: string | null
  dateEnd?: string | null
  csvMapper: any
  stylistName: string
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetReportsStylistbyId(props).then((resp) => {
      const { csvMapper = mapStylistDetailToCSVForExtensions, stylistName } = props
      dispatch(reduceListStylistDetailReportById({ ...resp, stylistName, csvMapper }))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetAllReportsByKind = (props: {
  token: string
  salonId: number
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetAllReportsByKind(props).then((resp) => {
      dispatch(reduceListProductReport(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetAllAddonReportsForAddons = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetAllAddonReportsForAddons(props).then((resp) => {
      dispatch(reduceListAddonsReport(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetReportsExtensionsbyBrand = (props: {
  token: string
  salonId: number
  brand: string
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetReportsExtensionsbyBrand(props).then((resp) => {
      dispatch(reduceListExtensionDetailReportByBrand(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetProductReportsDetailsForExtensions = (props: {
  token: string
  salonId: number
  brand: string
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetProductReportsDetailsForExtensions(props).then((resp) => {
      dispatch(reduceListProductExtensionDetailReportByBrand(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetStylistsReportsForAddons = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetAllAddonReportsForStylist(props).then((resp) => {
      dispatch(reduceListStylistReportAddon(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetStylistDetailReportsForAddons = (props: {
  token: string
  salonId: number
  stylistId: number
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch, getState) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetStylistDetailReportForAddons(props).then((resp) => {
      const { reports: { stylistReportAddon } } = getState();
      const stylistName = stylistReportAddon.filter(e => e.stylistId === props.stylistId)[0].stylist
      dispatch(reduceListStylistDetailReportAddon({ addon: resp, stylistName }))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetAllClientReportForAddons = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetAllClientReportForAddons(props).then((resp) => {
      dispatch(reduceListClientReportAddon(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetAllClientDetailReportForAddons = (props: {
  token: string
  salonId: number
  clientId: number
  stylistId: number
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetAllClientDetailReportForAddons(props).then((resp) => {
      dispatch(reduceListClientDetailReportAddon(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}
