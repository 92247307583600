import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { selectProductsByVendor } from '../../../data/products/slice'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { useDispatch } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { useAppSelector } from '../../../hooks'
import { dispatchListProducts } from '../../../data/products/api'
import { ROUNDED_ALL_STYLES } from '../../../styles'
import { Loading } from '../../../mini-lib/loading/Loading'
import { Product, Vendor } from '../../../data/products/interfaces'
import { ColorForMode } from '../../../theme'
import { EmptySearch } from '../../../mini-lib/empty/EmptySearch'
import { setNavVisible } from '../../../mini-lib/sidenav/slice'
import { keys, reduce, uniq } from 'lodash'
import { maybePluralize } from '../../../core/text'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { ReleaseLaraColors } from "../../../mini-lib/flags/Release";
import { ROUTES } from "../../../appRoutes";

export const VendorsPage = (props: {}) => {
  const dispatch = useDispatch()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const productsByVendor = useAppSelector(selectProductsByVendor)
  const cardBg = ColorForMode('card-bg')

  const releaseLaraColors = ReleaseLaraColors()
  useEffect(() => {
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, releaseLaraColors])

  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Brands' }]
  const [searchText] = useState('')
  const sortedVendorNames = keys(productsByVendor).sort()
  const sortedFilteredVendorNames = sortedVendorNames.filter((vendorName) =>
    vendorName.toLowerCase().includes(searchText.toLowerCase()),
  )
  return (
    <PageLayout
      variant="full"
      header={
        <PageHeader
          title="Brands"
          breadcrumbs={breadcrumbs}
          actions={
            <Link to={generatePath(ROUTES.addLinesV2, { salonId })}>
              <Button variant="round" colorScheme="brand.lavender">
                Add Lines
              </Button>
            </Link>
          }
        />
      }
      content={
        <>
          {/*<StickySearch placeholder="Search Brands" searchText={searchText} setSearchText={setSearchText}>*/}
          <Flex justify="flex-end">
            <Link onClick={() => dispatch(setNavVisible(false))} to={generatePath(ROUTES.lines, { salonId })}>
              <Button variant="ghost" colorScheme="brand.lavender">
                Go To All Color Lines <i className="material-icons-round">chevron_right</i>
              </Button>
            </Link>
          </Flex>
          {/*</StickySearch>*/}

          {productsByVendor ? (
            <>
              <Box bg="brand.lavender.100" p="12px" borderRadius="8px" m="12px 0">
                <Text variant="caption1">My Brands</Text>
              </Box>
              {keys(productsByVendor).length > 0 ? (
                <Box bg={cardBg} p="0">
                  <VendorList
                    sortedFilteredVendorNames={sortedFilteredVendorNames}
                    productsByVendor={productsByVendor}
                    salonId={salonId}
                    dispatch={dispatch}
                  />
                </Box>
              ) : (
                <EmptySearch>No Lines Found</EmptySearch>
              )}
            </>
          ) : (
            <Loading />
          )}
        </>
      }
    >
    </PageLayout>
  )
}

export const VendorList = (props: {
  productsByVendor: { [key: string]: Product[] }
  sortedFilteredVendorNames: string[]
  dispatch: any
  salonId: number
}) => {
  const { sortedFilteredVendorNames, productsByVendor, dispatch, salonId } = props
  return (
    <>
      {sortedFilteredVendorNames.map((vendorName, index) => {
        const products: Product[] = productsByVendor[vendorName]
        const numProductsWithPrices = reduce(
          products,
          (sum, product) => {
            if (product.pricing.price) {
              return sum + 1
            }
            return sum
          },
          0,
        )
        const uniqLineNames = uniq(products.map((product) => product.line.name))
        const secondaryBg = ColorForMode('secondary-bg')
        const brandLight = ColorForMode('brand-light')
        return (
          <Link
            key={products[0].vendor.id}
            onClick={() => dispatch(setNavVisible(false))}
            to={generatePath(ROUTES.lines, { salonId }) + `?searchText=${products[0].vendor.name}`}
          >
            <Flex
              justify="space-between"
              p="16px"
              _hover={{ background: brandLight }}
              bg={index % 2 === 0 ? secondaryBg : ''}
            >
              <Text fontWeight="bold">{products[0].vendor.name}</Text>
              <div>
                {uniqLineNames.length} {maybePluralize('Line', uniqLineNames.length)}, {numProductsWithPrices} active{' '}
                {maybePluralize('Product', numProductsWithPrices)}
              </div>
            </Flex>
          </Link>
        )
      })}
    </>
  )
}

export const FeaturedVendorList = (props: { vendors: Vendor[]; dispatch: any; salonId: number }) => {
  const { vendors, dispatch, salonId } = props
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={6} m="24px 12px">
      {vendors.map((vendor, index) => {
        return (
          <Link
            key={vendor.name}
            onClick={() => dispatch(setNavVisible(false))}
            to={generatePath(ROUTES.lines, { salonId }) + `?searchText=${vendor.name}`}
          >
            <GridItem w="100%" h="60px" p="8px" bg={ColorForMode('secondary-bg')} style={ROUNDED_ALL_STYLES}>
              <Flex justify="center" align="center" h="100%" w="100%">
                {vendor.logoUrl ? (
                  <img
                    alt="client"
                    key={vendor.id}
                    style={{
                      cursor: 'pointer',
                      display: 'inline-block',
                      objectFit: 'cover',
                      // height: '200px',
                      // width: '200px',
                      borderRadius: '12px',
                    }}
                    src={vendor.logoUrl ? vendor.logoUrl : ''}
                  />
                ) : (
                  <div>{vendor.name}</div>
                )}
              </Flex>
            </GridItem>
          </Link>
        )
      })}
    </Grid>
  )
}
