import React, { useState } from 'react'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { Box, Divider, Flex, Img, Text } from '@chakra-ui/react'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { generatePath } from 'react-router-dom'
import { UseSignOut } from '../../core/hooks/UseSignOut'
import { COLORS } from '../../mini-lib/theme/colors'
import { UserSettingsSideSheet } from './UserSettingsSideSheet'
import { ConfirmModal } from '../../mini-lib/confirm-modal/ConfirmModal'
import { buildFeatureRequestEmailUrl, buildSupportEmailUrl } from '../../data/settings/utils'
import { SalonScaleIcon } from '../../mini-lib/icons/SalonScaleIcon'
import { UseViewSize } from '../../core/UseViewSize'
import { ROUTES } from "../../appRoutes";

export const UserSettingsPage = () => {
  const { salonId } = UseBaseApiParams()
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Account' }]
  const [showUserSettings, setShowUserSettings] = React.useState(false)
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Account" breadcrumbs={breadcrumbs} />}
      content={
        <Box maxW="500px">
          <UserSettingsSideSheet show={showUserSettings} setShow={setShowUserSettings} />
          <UserSettings setShowUserSettings={setShowUserSettings} />
        </Box>
      }
    />
  )
}

export const UserSettings = (props: { setShowUserSettings: (show: boolean) => void }) => {
  const { user, salonId } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  const { setShowUserSettings } = props
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  return (
    <Box>
      <ConfirmModal
        showModal={showLogoutModal}
        title="Sign out"
        subtitle="Are you sure you want to sign out?"
        confirmText="Yes, Sign Out"
        onConfirm={() => {
          setShowLogoutModal(false)
          UseSignOut()
        }}
        onCancel={() => setShowLogoutModal(false)}
      />
      {/* profile section */}
      {/**/}
      <Box h="24px" />
      <Text color={COLORS.text_secondary}>Profile</Text>
      <Box h="12px" />
      <Flex justify="space-between" align="center" cursor="pointer" onClick={() => setShowUserSettings(true)}>
        <Flex gridGap="12px" align="center">
          {user?.photoUrl && <Img h="64px" w="64px" src={user?.photoUrl} borderRadius="50%" bg={COLORS.lavender_50} />}
          {!user?.photoUrl && <SalonScaleIcon size="64px" h="64px" w="64px" name="profile" />}
          <Box>
            <Text fontWeight="bold">
              {user.firstName} {user.lastName}
            </Text>
            <Text color={COLORS.text_secondary} isTruncated={true} maxW={isMobile ? '240px' : ''}>
              {user.email}
            </Text>
          </Box>
        </Flex>
        <MaterialIcon size="32px" colorhex={COLORS.text_secondary} name="chevron_right" />
      </Flex>

      <Box h="24px" />
      <Divider />
      <Box h="24px" />
      {/* support info section */}
      {/**/}
      <Text color={COLORS.text_secondary}>Support</Text>
      <Box h="12px" />

      <a target="_blank" href={buildSupportEmailUrl(user, salonId)} rel="noreferrer">
        <UserSettingTile iconName="mail" label="Email Support Team" />
      </a>
      <Box h="12px" />

      <a target="_blank" href={buildFeatureRequestEmailUrl(user, salonId)} rel="noreferrer">
        <UserSettingTile iconName="question_answer" label="Request a Feature" />
      </a>
      <Box h="12px" />

      <a target="_blank" href="https://helpcenter.salonscale.com/en/" rel="noreferrer">
        <UserSettingTile iconName="help" label="Help" />
      </a>
      <Box h="12px" />

      <Box h="24px" />
      <Divider />
      <Box h="24px" />

      {/* purchase scales section */}
      {/**/}
      <Text color={COLORS.text_secondary}>Accessories</Text>
      <Box h="12px" />
      <a target="_blank" href="https://salonscale.com/bluetooth-scale/" rel="noreferrer">
        <UserSettingTile iconName="monitor_weight" label="Purchase SalonScale Scale" />
      </a>

      <Box h="12px" />

      <a
        target="_blank"
        href="https://eightouncecoffee.com/collections/acaia-scales/products/acaia-pearl-coffee-scale-black?rfsn=7136873.0ee803&utm_source=refersion&utm_medium=affiliate&utm_campaign=7136873.0ee803"
        rel="noreferrer"
      >
        <UserSettingTile iconName="monitor_weight" label="Purchase Acaia Scale" />
      </a>

      <Box h="24px" />
      <Divider />
      <Box h="24px" />

      <Box onClick={() => setShowLogoutModal(true)}>
        <UserSettingTile iconName="logout" label="Sign Out" />
      </Box>
    </Box>
  )
}

export const UserSettingTile = (props: { iconName: string; label: string }) => {
  const { iconName, label } = props
  return (
    <Flex justify="space-between" align="center" w="100%" cursor="pointer">
      <Flex gridGap="8px">
        <MaterialIcon color={COLORS.lavender_500} name={iconName} />
        <Text>{label}</Text>
      </Flex>
      <MaterialIcon cursor="pointer" size="32px" colorhex={COLORS.text_secondary} name="chevron_right" />
    </Flex>
  )
}
