import { Dispatch } from '@reduxjs/toolkit'
import { APIUpdateProduct, LineOption, Product } from '../products/interfaces'
import { toast } from 'react-toastify'
import { buildLoadingName } from '../../core/loading/utils'
import { delay } from 'lodash'
import { dispatchSetLoadingType } from '../../core/loading/api'
import {
  apiAddLines,
  apiCreateProduct,
  apiDeleteLine,
  apiDeleteProduct,
  apiDeleteProducts,
  apiListAllLines,
  apiListProduct,
  apiUpdateProducts,
} from '../products/api'
import {
  reduceCreateExtension,
  reduceDeleteExtension,
  reduceDeleteExtensions,
  reduceListAllExtensionLines,
  reduceListExtension,
  reduceUpdateExtensions,
} from './slice'
import { LineKindOptions } from '../products/constants'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { buildLaraConfig, buildLaraPageParams } from "../../mini-lib/lara/lara-utils";
import { GetServerBaseUrl } from "../../env";
import axios from "axios";
import { APIUpdateExtension, Extension } from "./interfaces";
import { LOADING_EXTENSIONS } from "./constants";
import { APIPagedResponse } from "../../core/pagination";
import { mapAPIExtensionLarasToToExtensions, mapAPIUpdateProductToAPIExtensionPatchLara } from "./mappers";

export const dispatchListExtensions = (
  params: {
    token: string,
    salonId: number,

    trashedFilter?: 'only' | string
    hasPriceFilter?: boolean
    idFilter?: number
    pageNumber?: number
    pageSize?: number
    nameFilter?: string
    levelFilter?: number
    volumeFilter?: number
    search?: string

    releaseLaraColors?: boolean
  } ) => {
  const { releaseLaraColors = false } = params
  return ( dispatch: Dispatch ) => {
    dispatch(reduceSetLoadingState({ name: LOADING_EXTENSIONS, state: true }))

    if (releaseLaraColors) {
      return apiListExtensionsLara(params).then(( resp ) => {
        dispatch(reduceListExtension(resp))
        dispatch(reduceSetLoadingState({ name: LOADING_EXTENSIONS, state: false }))
      })
    } else {
      return apiListProduct({ ...params, lineKind: LineKindOptions.extension }).then(( resp ) => {
        dispatch(reduceListExtension(resp))
        dispatch(reduceSetLoadingState({ name: LOADING_EXTENSIONS, state: false }))
      })
    }
  }
}

export const dispatchUpdateExtensions = (params: {
  token: string
  user_id: number
  salon_id: number
  models: APIUpdateProduct[]
  loadingName: string
  releaseLaraColors: boolean
}) => {
  const { loadingName, releaseLaraColors } = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: loadingName, state: true }))
    if (releaseLaraColors) {
      return apiPatchExtensions(params)
      .then(( resp ) => {
        dispatch(reduceUpdateExtensions(resp))
        dispatch(reduceSetLoadingState({ name: loadingName, state: false }))
      })
    } else {
      return apiUpdateProducts(params)
        .then((resp) => {
          dispatch(reduceUpdateExtensions(resp))
          dispatch(reduceSetLoadingState({ name: loadingName, state: false }))
        })
        .catch((error) => {
          throw error
        })
    }
  }
}
export const dispatchCreateExtension = (params: {
  token: string
  user_id: number
  salon_id: number
  model: Product
}) => {
  return (dispatch: Dispatch) => {
    return apiCreateProduct(params)
      .then((resp) => {
        dispatch(reduceCreateExtension(resp))
        toast.success('Created Extension')
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDeleteExtension = (params: { token: string; salonId: number; model: Product }) => {
  return (dispatch: Dispatch) => {
    return apiDeleteProduct(params)
      .then((resp) => {
        dispatch(reduceDeleteExtension(resp))
        toast.success('Deleted Extension')
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDeleteExtensionLine = (params: { token: string; salonId: number; lineName: string }) => {
  return (dispatch: Dispatch) => {
    return apiDeleteLine(params)
      .then((resp) => {
        dispatch(reduceDeleteExtensions(resp))
        toast.success('Deleted Line')
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchListAllExtensionLines = (token: string, salonId: number) => {
  return (dispatch: Dispatch) => {
    return apiListAllLines(token, LineKindOptions.extension, salonId).then((resp) => {
      dispatch(reduceListAllExtensionLines(resp))
    })
  }
}

export const dispatchAddExtensionLines = (token: string, salonId: number, lines: LineOption[]) => {
  return (dispatch: any) => {
    return apiAddLines(token, salonId, lines).then((resp) => {
      dispatch(dispatchListExtensions({ token, salonId }))
      lines.forEach((line) => {
        const loadingName = buildLoadingName(line.lineName)
        delay(() => {
          dispatch(dispatchSetLoadingType({ name: loadingName, state: false }))
        }, 3000)
      })
    })
  }
}

export const dispatchDeleteExtensions = (params: {
  token: string
  salonId: number
  models: Product[]
  releaseLaraColors: boolean
  loadingName?: string
}) => {
  const { releaseLaraColors, loadingName = 'deleting-products' } = params
  return (dispatch: Dispatch) => {
    if (releaseLaraColors) {
      return apiDeleteExtensionsLara(params).then(( resp ) => {
        dispatch(reduceDeleteExtensions(resp))
        dispatch(reduceSetLoadingState({ name: loadingName, state: false }))
      })
    } else {

    dispatch(reduceSetLoadingState({ name: loadingName, state: true }))
    return apiDeleteProducts(params)
      .then((resp) => {
        dispatch(reduceDeleteExtensions(resp))
        dispatch(reduceSetLoadingState({ name: loadingName, state: false }))
      })
      .catch((error) => {
        throw error
      })
    }
  }
}

export const apiDeleteExtensionsLara = ( params: {
  token: string
  salonId: number
  models: Product[]
} ): Promise<Product[]> => {
  const { token, models, salonId } = params
  const config = buildLaraConfig({ token })
  const modelIds = models.map((model) => model.id).join(',')
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/extensions?ids=${modelIds}`
  return axios
    .delete(url, config)
    .then(( response: any ) => {
      return models
    })
    .catch(( error ) => {
      throw error
    })
}

export const apiPatchExtensions = ( params: {
  token: string
  user_id: number
  salon_id: number
  models: APIUpdateExtension[]

} ): Promise<Product[]> => {
  const { token, salon_id, models } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salon_id}/extensions`

  const body = mapAPIUpdateProductToAPIExtensionPatchLara(models)
  return axios
    .patch(url, body, config)
    .then(( response: any ) => {
      return mapAPIExtensionLarasToToExtensions(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}

export const apiListExtensionsLara = ( params: {
  token: string
  salonId: number

  trashedFilter?: 'only' | string
  hasPriceFilter?: boolean
  idFilter?: number
  pageNumber?: number
  pageSize?: number
  nameFilter?: string
  levelFilter?: number
  volumeFilter?: number
} ): Promise<Extension[]> => {
  const {
    token,
    salonId,
    trashedFilter,
    hasPriceFilter,
    nameFilter,
    idFilter,
    levelFilter,
    volumeFilter,
    pageNumber = 1,
    pageSize = 10000,
  } = params

  const nameParam = nameFilter ? `&filter[name]=${nameFilter}` : ''
  const trashedParam = trashedFilter ? `&filter[trashed]=${trashedFilter}` : ''
  const hasPriceParam = hasPriceFilter ? `&filter[hasPrice]=${hasPriceFilter}` : ''
  const idParam = idFilter ? `&filter[id]=${idFilter}` : ''
  const levelParam = levelFilter ? `&filter[level]=${levelFilter}` : ''
  const volumeParam = volumeFilter ? `&filter[volume]=${volumeFilter}` : ''
  const { pageSizeParam, pageNumberParam } = buildLaraPageParams({ pageSize, pageNumber })

  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/extensions?${pageSizeParam}&${pageNumberParam}${nameParam}${trashedParam}${hasPriceParam}${idParam}${levelParam}${volumeParam}`
  return axios
    .get(url, config)
    .then(( response: { data: APIPagedResponse } ) => {
      return mapAPIExtensionLarasToToExtensions(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}
