import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  Text,
  Divider,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import {
  APILaborServiceOverrideBulkUpsert,
  APILaborServiceUpdate,
  APILaborTierCreate,
  APILaborTierServiceCreate,
  APILaborTierServiceUpdate,
  LABOR_SERVICE_TYPES,
  LaborItem,
  LaborService, LaborServiceOverride,
  LaborTier,
} from '../../data/labor/interfaces'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectLoggedInUser } from '../../data/user/slice'
import {
  dispatchBulkDeleteLaborServiceOverrides, dispatchBulkUpsertLaborServiceOverrides,
  dispatchCreateLaborItems,
  dispatchCreateLaborServices,
  dispatchCreateLaborTiers,
  dispatchDeleteLaborServices,
  dispatchDeleteLaborTiers,
  dispatchDeleteLaborItems,
  dispatchDuplicateLaborService,
  dispatchDuplicateLaborTier,
  dispatchUpdateLaborItems,
  dispatchUpdateLaborServices,
} from '../../data/labor/api'
import { buildLoadingIdentifier, buildTierServiceKey, buildUserServiceKey } from '../../data/labor/utils'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { SavedStateIcon } from '../../mini-lib/icons/SaveStateIcon'
import { dispatchSetLoadingType } from '../../core/loading/api'
import { LaborTierUsers } from './LaborTierUsers'
import {
  DEFAULT_SERVICE_NAME,
  DEFAULT_TIER_NAME,
  soloLaborOnboardSteps,
  teamLaborOnboardSteps
} from '../../data/labor/constants'
import { centsToDollars, dollarsToCents } from '../../core/money/utils'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { HelpPopover } from "../start-guide/common-components/HelpPopover";
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { selectSalonUsers } from "../../data/salon-user/slice";
import { COLORS } from "../../mini-lib/theme/colors";
import { SalonUser } from "../../data/salon-user/interfaces";
import {selectLaborItemsForService, selectLaborItemsForTier} from "../../data/labor/slice";

export const LaborServiceCell = (props: { service: LaborService; theme: string, serviceIndex: number, categoryIndex: number }) => {
  const dispatch = useDispatch()
  const {
    user,
    salonId,
  } = UseBaseApiParams()
  const salonType = user.currentSalonContext?.salonType
  const { service, theme, serviceIndex, categoryIndex } = props
  const laborTierServicesForService = useAppSelector((state) => selectLaborItemsForService(state, service))

  const deleteService = (service: LaborService) => {
    dispatch(
      dispatchDeleteLaborServices({
        token: user.token,
        userId: user.userId,
        salonId: salonId,
        models: [service],
      }),
    )
  }
  const updateService = (service: LaborService, updatedName: string) => {
    const updatedService: APILaborServiceUpdate = {
      id: service.id,
      name: updatedName,
      type: service.type,
      category: service.category,
    }
    if (updatedName !== service.name) {
      dispatch(
        dispatchUpdateLaborServices({
          token: user.token,
          userId: user.userId,
          salonId: salonId,
          models: [updatedService],
        }),
      )
    }
  }
  const duplicateService = (service: LaborService) => {
    if (laborTierServicesForService) {
      dispatch(
        dispatchDuplicateLaborService({
          token: user.token,
          userId: user.userId,
          salonId: salonId,
          service: service,
          tierServicesForService: laborTierServicesForService,
        }),
      )
    }
  }
  const helpIndex = serviceIndex === 0 && categoryIndex === 0
    ? salonType === 'salon'
      ? teamLaborOnboardSteps.tierType
      : soloLaborOnboardSteps.tierType
    : -1
  return (
    <Flex
      justify="space-between"
      align="center"
      h="100%"
      w="100%"
      bg={`brand.${theme}.100`}
      color="black"
      borderLeftColor={`brand.${theme}.500`}
      borderLeftWidth="9px"
      pl="12px"
    >
      <Tooltip label={service.name}>
        <Editable
          defaultValue={service.name}
          onSubmit={(updatedName) => updateService(service, updatedName)}
          isTruncated={true}
          w="100%"
        >
          <EditablePreview cursor="pointer" w="100%" />
          <EditableInput w="100%" />
        </Editable>
      </Tooltip>
      <HelpPopover description={'Click the three dots to change the charge between hourly rate and flat rate, duplicate the service item, or delete the service item.'} stepIndex={helpIndex} guideTypes={[CHECKLIST_CODES.addLaborSolo, CHECKLIST_CODES.addLaborTeam]}>
        <Box>
          <ServiceMenu service={service} deleteService={deleteService} duplicateService={duplicateService} />
        </Box>
      </HelpPopover>
    </Flex>
  )
}

export const LaborServiceAddCell = (props: { index: number, categoryName: string; serviceType: string; theme: string }) => {
  const dispatch = useDispatch()
  const {user, salonId} = UseBaseApiParams()
  const salonType = user.currentSalonContext?.salonType

  const { index, categoryName, serviceType, theme } = props
  const createService = (type: string) => {
    if (user && type) {
      dispatch(
        dispatchCreateLaborServices({
          token: user.token,
          userId: user.userId,
          salonId: salonId,
          models: [{ name: DEFAULT_SERVICE_NAME, type: type, category: categoryName }],
        }),
      )
    }
  }
  const addMenuHelpIndex = index === 0
    ? salonType === 'salon' ? teamLaborOnboardSteps.addService: soloLaborOnboardSteps.addService
    : -1
  return (
    <HelpPopover description={'Add an item to your service menu by clicking here'} stepIndex={addMenuHelpIndex} guideTypes={[CHECKLIST_CODES.addLaborSolo, CHECKLIST_CODES.addLaborTeam]}>
      <Flex
        onClick={() => createService(serviceType)}
        cursor="pointer"
        align="center"
        h="100%"
        borderColor={`brand.${theme}.500`}
        borderWidth="1px"
        borderRadius="8px"
        borderLeftColor={`brand.${theme}.500`}
        borderLeftWidth="9px"
        p="12px"
      >
        + Add a Service
      </Flex>
    </HelpPopover>
  )
}
export const LaborTierServiceCell = (props: {
  tier: LaborTier
  service: LaborService
  laborItemsByTierService: { [key: string]: LaborItem }
  type: string
  tierIndex: number
  serviceIndex: number
  categoryIndex: number
  laborServiceOverride?: LaborServiceOverride
}) => {
  const { tierIndex, serviceIndex, categoryIndex, tier, service, laborItemsByTierService, type, laborServiceOverride } = props
  const dispatch = useDispatch()
  const {user, salonId} = UseBaseApiParams()
  const salonType = user.currentSalonContext?.salonType
  const loadingIdentifier = buildLoadingIdentifier('item', tier.id, service.id)
  const key = buildTierServiceKey(tier.id, service.id)
  const itemForTierService = laborItemsByTierService[key]
  const inputValue: any = laborServiceOverride
    ? centsToDollars(laborServiceOverride.priceCents)
    : itemForTierService
      ? `${itemForTierService?.priceDollars}`
      : ''

  const [error, setError] = useState('')

  // only show the help message on the first add price cell not all of them
  const helpMessageIndex = tierIndex === 0 && serviceIndex === 0 && categoryIndex === 0
    ? salonType === 'salon'
      ? teamLaborOnboardSteps.addPrice
      : soloLaborOnboardSteps.addPrice
    : -1
  const createOrUpdateItem = (updatedPrice: any) => {
    if (updatedPrice === '') {
      deleteTierService()
    } else if (isNaN(updatedPrice) || updatedPrice < 0) {
      setError('invalid number')
    } else {
      setError('')
      if (itemForTierService && user && updatedPrice !== itemForTierService.priceDollars.toString()) {
        dispatch(dispatchSetLoadingType({ name: loadingIdentifier, state: true }))
        const updatedItem: APILaborTierServiceUpdate = {
          id: itemForTierService.id,
          tier_id: tier.id,
          service_id: service.id,
          price: dollarsToCents(updatedPrice),
        }
        dispatch(
          dispatchUpdateLaborItems({
            token: user.token,
            userId: user.userId,
            salonId: salonId,
            loadingId: loadingIdentifier,
            models: [updatedItem],
          }),
        )
      } else if (!itemForTierService && user) {
        dispatch(dispatchSetLoadingType({ name: loadingIdentifier, state: true }))
        const createdItem: APILaborTierServiceCreate = {
          tier_id: tier.id,
          service_id: service.id,
          price: dollarsToCents(updatedPrice),
        }
        dispatch(
          dispatchCreateLaborItems({
            token: user.token,
            userId: user.userId,
            salonId: salonId,
            loadingId: loadingIdentifier,
            models: [createdItem],
          }),
        )
      }
    }
  }
  const deleteTierService = () => {
    if (user && itemForTierService) {
      dispatch(dispatchSetLoadingType({ name: loadingIdentifier, state: true }))
      dispatch(
        dispatchDeleteLaborItems({
          token: user.token,
          userId: user.userId,
          salonId: salonId,
          loadingId: loadingIdentifier,
          models: [itemForTierService],
        }),
      )
    }
  }

  return (
    <Flex
      p={inputValue === '' ? '' : '0 12px'}
      justify="space-between"
      align="center"
      h="100%"
      borderColor="shades.neutral.200"
      borderWidth="1px"
      borderRadius="8px"
    >
      <SavedStateIcon loadingName={loadingIdentifier} />
      <Flex align="center">
        <HelpPopover description={'Click into the cell to add or edit the price'} stepIndex={helpMessageIndex} guideTypes={[CHECKLIST_CODES.addLaborSolo, CHECKLIST_CODES.addLaborTeam]}>
          <Editable
            key={inputValue}
            textAlign="end"
            defaultValue={inputValue}
            placeholder={inputValue === '' ? 'add price' : ''}
            onSubmit={(updatedPrice) => createOrUpdateItem(updatedPrice)}
            w="100%"
          >
            <EditablePreview cursor="pointer" color={inputValue ? 'inherit' : 'shades.neutral.500'} w="100%" />
            <EditableInput w="100%" />
          </Editable>
        </HelpPopover>
        {!error &&
          itemForTierService &&
          itemForTierService.priceDollars >= 0 &&
          type === LABOR_SERVICE_TYPES.hourly && <Box pl="4px">/hr</Box>}
        {error && (
          <Popover>
            <PopoverTrigger>
              <IconButton
                ml="4px"
                color="danger"
                aria-label="error"
                variant="ghost"
                size="xs"
                icon={<MaterialIcon size="16px" name="error_outline" />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>Please enter a number</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        {!error && inputValue === '' && (
          <Popover>
            <PopoverTrigger>
              <IconButton
                m="0 4px"
                color="danger"
                aria-label="error"
                variant="ghost"
                size="xs"
                icon={<MaterialIcon size="16px" color="shades.neutral.400" name="visibility_off" />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>This will not appear in the app</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Flex>
    </Flex>
  )
}

export const LaborTierAddCell = (props: {}) => {
  const dispatch = useDispatch()
  const params: any = useParams()
  const { salonId } = params
  const user = useAppSelector(selectLoggedInUser)

  const addTier = () => {
    const tier: APILaborTierCreate = { name: DEFAULT_TIER_NAME }
    if (user) {
      dispatch(
        dispatchCreateLaborTiers({
          token: user.token,
          userId: user.userId,
          salonId: salonId,
          models: [tier],
        }),
      )
    }
  }
  return (
    <Flex pl="12px" gridGap="8px" align="center" h="100%" color="brand.lavender.500" onClick={addTier} cursor="pointer">
      <MaterialIcon name="add_circle" /> Add Tier
    </Flex>
  )
}

export const LaborTierCell = (props: { tier: LaborTier, tierIndex: number, categoryIndex: number }) => {
  const dispatch = useDispatch()
  const params: any = useParams()
  const { tier, tierIndex, categoryIndex } = props
  const { salonId } = params
  const user = useAppSelector(selectLoggedInUser)
  const laborTierServicesForTier = useAppSelector((state) => selectLaborItemsForTier(state, tier))
  const salonUsers = useAppSelector(selectSalonUsers)

  const deleteTier = (tier: LaborTier) => {
    if (user) {
      dispatch(
        dispatchDeleteLaborTiers({
          token: user.token,
          userId: user.userId,
          salonId: salonId,
          models: [tier],
        }),
      )
    }
  }
  // const updateTier = (tier: LaborTier, updatedName: string) => {
  //   const updatedTier: APILaborTierUpdate = { id: tier.id, name: updatedName }
  //   if (user && updatedName !== tier.name) {
  //     dispatch(
  //       dispatchUpdateLaborTiers({
  //         token: user.token,
  //         userId: user.userId,
  //         salonId,
  //         models: [updatedTier],
  //       }),
  //     )
  //   }
  // }
  const duplicateTier = (tier: LaborTier) => {
    if (user && laborTierServicesForTier) {
      dispatch(
        dispatchDuplicateLaborTier({
          token: user.token,
          userId: user.userId,
          salonId: salonId,
          tier: tier,
          tierServicesForTier: laborTierServicesForTier,
        }),
      )
    }
  }
  return (
    <Flex pl="12px" justify="space-between" align="center" h="100%" bg="brand.midnight.50" color="black" width="100%">
      <Tooltip label={tier.name}>
        <Text>{tier.name}</Text>
      </Tooltip>
      <Flex>
        {salonUsers && <LaborTierUsers tier={tier} tierIndex={tierIndex} categoryIndex={categoryIndex}/>}
        <TierMenu tier={tier} deleteTier={deleteTier} duplicateTier={duplicateTier} />
      </Flex>
    </Flex>
  )
}

export const ServiceMenu = (props: {
  service: LaborService
  deleteService: (LaborService) => void
  duplicateService: (LaborService) => void
}) => {
  const { service, deleteService, duplicateService } = props
  const { user, salonId } = UseBaseApiParams()
  const dispatch = useDispatch()
  const setServiceType = (serviceType: string) => {
    const updatedService: APILaborServiceUpdate = {
      id: service.id,
      name: service.name,
      type: serviceType,
      category: service.category,
    }
    dispatch(
      dispatchUpdateLaborServices({
        token: user.token,
        userId: user.userId,
        salonId,
        models: [updatedService],
      }),
    )
  }
  return (
    <Menu>
      <MenuButton as={IconButton} aria-label="Options" icon={<MaterialIcon name="more_vert" colorhex={COLORS.skylight_500}/>} variant="ghost" />
      <MenuList>
        <MenuItem
          onClick={() => {
            setServiceType('hourly')
          }}
        >
          <Flex justify='space-between' w='100%'>
            <Text>Hourly Priced Service</Text>
            {service.type === 'hourly' && <MaterialIcon name='check'/>}
          </Flex>

        </MenuItem>

        <MenuItem
          onClick={() => {
            setServiceType('service')
          }}
        >
          <Flex justify='space-between' w='100%'>
            <Text>Flat Rate Service</Text>
            {service.type === 'service' && <MaterialIcon name='check'/>}
          </Flex>
        </MenuItem>

        <Divider />
        <MenuItem
          onClick={() => {
            duplicateService(service)
          }}
          icon={<MaterialIcon name="content_copy" />}
        >
          Duplicate Service
        </MenuItem>
        <MenuItem
          color="danger"
          onClick={() => {
            deleteService(service)
          }}
          icon={<MaterialIcon name="delete" />}
        >
          Delete Service
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export const TierMenu = (props: {
  tier: LaborTier
  deleteTier: (LaborTier) => void
  duplicateTier: (LaborTier) => void
}) => {
  const { tier, deleteTier, duplicateTier } = props
  return (
    <Menu>
      <MenuButton>
        <MaterialIcon name="more_vert" />
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() => {
            duplicateTier(tier)
          }}
          icon={<MaterialIcon name="content_copy" />}
        >
          Duplicate Tier
        </MenuItem>
        <MenuItem
          color="danger"
          onClick={() => {
            deleteTier(tier)
          }}
          icon={<MaterialIcon name="delete" />}
        >
          Delete Tier
        </MenuItem>
      </MenuList>
    </Menu>
  )
}



export const LaborServiceOverrideCell = (props: {
  salonUser: SalonUser
  tier: LaborTier
  service: LaborService
  laborItemsByTierService: { [key: string]: LaborItem }
  laborServiceOverridesByUserServiceId: { [key: string]: LaborServiceOverride }
  type: string
  tierIndex: number
  serviceIndex: number
  categoryIndex: number
  laborServiceOverride?: LaborServiceOverride
}) => {
  const { salonUser, laborServiceOverridesByUserServiceId, tierIndex, serviceIndex, categoryIndex, tier, service, laborItemsByTierService, type, laborServiceOverride } = props
  const dispatch = useDispatch()
  const {user, salonId} = UseBaseApiParams()
  const salonType = user.currentSalonContext?.salonType
  const loadingIdentifier = buildLoadingIdentifier('item', tier.id, service.id)
  const key = buildTierServiceKey(tier.id, service.id)
  const itemForTierService = laborItemsByTierService[key]
  const userServiceKey = buildUserServiceKey(salonUser.id, service.id)
  const overrideForServiceUser = laborServiceOverridesByUserServiceId[userServiceKey]
  const inputValue: any = laborServiceOverride
    ? centsToDollars(laborServiceOverride.priceCents)
    : ''

  const [error, setError] = useState('')

  // only show the help message on the first add price cell not all of them
  const helpMessageIndex = tierIndex === 0 && serviceIndex === 0 && categoryIndex === 0 && salonType === 'salon'
    ? teamLaborOnboardSteps.override
    : -1

  const upsertOverride = (updatedPrice: any) => {
    if (updatedPrice === '') {
      deleteServiceOverride()
    } else if (isNaN(updatedPrice) || updatedPrice < 0) {
      setError('invalid number')
    } else {
      setError('')
      if (!overrideForServiceUser || updatedPrice !== overrideForServiceUser?.priceCents.toString()) {
        dispatch(dispatchSetLoadingType({ name: loadingIdentifier, state: true }))
        const updatedItem: APILaborServiceOverrideBulkUpsert = {
          user_id: salonUser.userId,
          service_id: service.id,
          price_cents: dollarsToCents(updatedPrice),
        }
        if (overrideForServiceUser) {
          updatedItem['id'] = overrideForServiceUser.id
        }
        dispatch(
          dispatchBulkUpsertLaborServiceOverrides({
            token: user.token,
            salonId: salonId,
            loadingId: loadingIdentifier,
            models: [ updatedItem ],
          }),
        )
      }
    }
  }
  const deleteServiceOverride = () => {
    if (user && overrideForServiceUser) {
      dispatch(dispatchSetLoadingType({ name: loadingIdentifier, state: true }))
      dispatch(
        dispatchBulkDeleteLaborServiceOverrides({
          token: user.token,
          userId: user.userId,
          salonId: salonId,
          loadingId: loadingIdentifier,
          models: [overrideForServiceUser],
        }),
      )
    }
  }
  return (
    <Flex
      p={inputValue === '' ? '' : '0 12px'}
      justify="space-between"
      align="center"
      h="100%"
      borderColor="shades.neutral.200"
      borderWidth="1px"
      borderRadius="8px"
    >
      <SavedStateIcon loadingName={loadingIdentifier} />
      <Flex align="center">
        <HelpPopover description={'Stylists will automatically inherit the base price, but service prices can be adjusted by individual stylist here'} stepIndex={helpMessageIndex} guideTypes={[CHECKLIST_CODES.addLaborTeam]}>
          <Editable
            key={inputValue}
            textAlign="end"
            defaultValue={inputValue}
            placeholder={inputValue === '' ? itemForTierService ? `${itemForTierService?.priceDollars} ` : 'add price' : ''}
            onSubmit={(updatedPrice) => upsertOverride(updatedPrice)}
            w="100%"
          >
            <EditablePreview
              pr={!overrideForServiceUser && type === LABOR_SERVICE_TYPES.service ? '12px' : '0'}
              cursor="pointer"
              color={inputValue ? 'inherit' : 'shades.neutral.400'} w="100%"
            />
            <EditableInput w="100%" />
          </Editable>
        </HelpPopover>
        {!error &&
          (itemForTierService?.priceDollars >= 0 || overrideForServiceUser?.priceCents >= 0) &&
          type === LABOR_SERVICE_TYPES.hourly && <Box color={!overrideForServiceUser ? "shades.neutral.400" : ''} pl="4px" pr={!(overrideForServiceUser?.priceCents > 0) && itemForTierService?.priceDollars > 0 ? '12px' : 0}>/hr</Box>}
        {error && (
          <Popover>
            <PopoverTrigger>
              <IconButton
                ml="4px"
                color="danger"
                aria-label="error"
                variant="ghost"
                size="xs"
                icon={<MaterialIcon size="16px" name="error_outline" />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>Please enter a number</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        {!error && inputValue === '' && !(itemForTierService?.priceDollars > 0) && (
          <Popover>
            <PopoverTrigger>
              <IconButton
                m="0 4px"
                color="danger"
                aria-label="error"
                variant="ghost"
                size="xs"
                icon={<MaterialIcon size="16px" color="shades.neutral.400" name="visibility_off" />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>This will not appear in the app</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Flex>
    </Flex>
  )
}
