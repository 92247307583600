import { UseQueryParams } from '../../mini-lib/utils/basic'
import { useDispatch } from 'react-redux'
import React, { useEffect } from 'react'
import { LoginForm } from '../login/LoginPage'
import { Loading } from '../../mini-lib/loading/Loading'
import { UseCurrentUser } from '../../core/hooks/UseCurrentUser'
import { dispatchUpdateSalonMemberRole } from '../../data/user/api'
import { generatePath } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { ROUTES } from "../../appRoutes";
import { getRoleForSalonFromSalonContexts } from "../../data/user/utils";

// if they have a user and permissions send them to the session page
// if they have a user but no permissions create permissions for them and send them to the session page
// if they don't have a user get them to log in
export const ExistingStylistSalonJoinRedirect = () => {
  const dispatch = useDispatch()
  const queryParams = UseQueryParams()
  const salonId = queryParams.get('salon-id') || ''

  const { user, loadingUser } = UseCurrentUser(salonId)
  const userRoleForSalon = user ? getRoleForSalonFromSalonContexts(parseInt(salonId), user.salonContexts) : null
  const token = user?.token || ''
  const userId = user?.userId || null
  const hasPermissionsForSalon = !!userRoleForSalon

  // note: it's possible, especially during qa, to click this link with a logged in user that is not the intended recipient of the email
  // this will not update the role for the incorrect user in that case (if they already have a role in the salon (likely the owner role)
  useEffect(() => {
    if (userId && token && !hasPermissionsForSalon) {
      dispatch(dispatchUpdateSalonMemberRole({ token, salonId, userId, userToUpdateId: userId, roleName: 'stylist', roleAccess: true }))
    }
  }, [dispatch, token, salonId, userId, hasPermissionsForSalon])

  if (user && hasPermissionsForSalon) {
    const url = generatePath(ROUTES.sessions, { salonId })
    window.location.href = url
  }
  return (
    <>
      {!user && !loadingUser && <LoginForm salonIdOverride={salonId} stylistInviteRedirect={true}/>}
      {(user || loadingUser) && (
        <>
          <Box h="48px" />
          <Loading />
        </>
      )}
    </>
  )
}
