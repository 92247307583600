import { map } from 'lodash'
import { APILaborLaborReports, LaborLaborReports, LaborLaborReportsCSV } from './interfaces'
import { formatTime } from './utils'

export const mapAPILaborLaborReportToLaborLaborReport = (api: APILaborLaborReports): LaborLaborReports => {
  return {
    type: api.type,
    time: formatTime(api.time),
    system: api.system,
    quantity: api.quantity,
    retail: api.retail,
  }
}
export const mapAPILaborLaborReportsToLaborLaborReports = (apiModels: APILaborLaborReports[]): LaborLaborReports[] => {
  return map(apiModels, mapAPILaborLaborReportToLaborLaborReport)
}

export const mapLaborLaborReportToLaborLaborReportCSV = (api: LaborLaborReports): LaborLaborReportsCSV => {
  return {
    type: api.type,
    time: api.time,
    system: api.system,
    quantity: api.quantity,
    retail: api.retail.toFixed(2),
  }
}
export const mapLaborLaborReportsToLaborLaborReportsCSV = (apiModels: LaborLaborReports[]): LaborLaborReportsCSV[] => {
  const csv = map(apiModels, mapLaborLaborReportToLaborLaborReportCSV)
  const sumRetail = apiModels ? apiModels.map((apiModels) => apiModels.retail).reduce((a, b) => a + b, 0) : 0
  const obj = {
    type: 'Total',
    retail: sumRetail.toFixed(2),
  }
  csv.push(obj)
  return csv
}
