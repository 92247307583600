import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const UseGeneratePathWithQPS = (params: { pathname: string, newParams?: {[key: string]: string} }): { pathname: string; search: any } => {
  const { pathname, newParams } = params
  const location = useLocation()
  const currentParams = queryString.parse(location.search)
  const mergedParams = { ...currentParams, ...newParams }

  return { pathname, search: queryString.stringify(mergedParams) }
}
