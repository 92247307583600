import { Text, Divider, Menu, MenuButton, MenuItem, MenuList, Box } from '@chakra-ui/react'
import { MaterialIcon } from "../icons/MaterialIcon";
import React from "react";
import { ResetPricingButton } from "../../pages/products/lines/ResetPricing";
import { COLORS } from "../theme/colors";

export const SuperUserColorsMenu = () => {
  return (
    <Menu>
      <MenuButton>
        <MaterialIcon name="more_vert" />
      </MenuButton>
      <MenuList bg='white' zIndex='10'>
        <Box m='12px'>
          <Text variant='button'>Super User Options</Text>
          <Text color={COLORS.danger} variant='headline'>only admins can see this menu</Text>
        </Box>
        <Divider/>
        <MenuItem>
          <ResetPricingButton/>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}