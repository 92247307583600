import { Box, Circle, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { SalonScaleIcon } from '../../../mini-lib/icons/SalonScaleIcon'
import { UseViewSize } from '../../../core/UseViewSize'
import { COLORS } from '../../../mini-lib/theme/colors'
import { Link } from 'react-router-dom'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { UseQueryParams } from '../../../mini-lib/utils/basic'

export interface Step {
  title: string
  link: string
  state: 'complete' | 'current' | 'incomplete'
}

export const HelpStepper = (props: {
  title: string
  steps: Step[]
  guideType: string
  bgHex?: string
  borderHex?: string
}) => {
  const { isMobile } = UseViewSize()
  const { title, steps, bgHex = COLORS.lavender_100, borderHex = COLORS.lavender_500, guideType } = props
  const queryParams: any = UseQueryParams()
  const guideParam = queryParams.get('guide')
  const isGuide = guideParam === guideType
  return (
    <>
      {isGuide && (
        <Flex w="100%" border={`2px solid ${borderHex}`} borderRadius="15px" mb="24px" wrap="wrap">
          {/* header */}
          {/*
           */}
          <Flex
            w={isMobile ? '100%' : '50%'}
            justify={isMobile ? 'center' : ''}
            align="center"
            p={isMobile ? '12px' : '24px'}
            gridGap="12px"
            bg={bgHex}
            borderRadius={isMobile ? '15px 15px 0 0' : '15px 0 0 15px'}
          >
            <SalonScaleIcon name="startGuide" />
            <Text variant={isMobile ? 'title2mobile' : 'title2'}>{title}</Text>
          </Flex>
          {/* steps */}
          {/*
           */}
          <Box w={isMobile ? '100%' : '50%'}>
            <StepNumbers steps={steps} stepWidthPixels={'150px'} />
            <StepLabels steps={steps} stepWidthPixels={'150px'} />
          </Box>
        </Flex>
      )}
    </>
  )
}

export const StepNumbers = ({ steps, stepWidthPixels }) => {
  const { isMobile } = UseViewSize()
  return (
    <Flex align="center" justify="center" p={isMobile ? '6px 12px 0' : '12px 24px'}>
      {steps.map((step, index) => {
        return (
          <Flex key={index} align="center">
            {index !== 0 && (
              <Box
                h="2px"
                w="125px"
                bg={
                  step.state === 'complete' || step.state === 'current' ? COLORS.success_500 : COLORS.shades_neutral_300
                }
              />
            )}
            <Link key={index} to={step.link}>
              <Flex direction="column" justify="center" align="center" gridGap="6px">
                <HelpStepNumberIcon state={step.state} stepNumber={index + 1} />
              </Flex>
            </Link>
          </Flex>
        )
      })}
    </Flex>
  )
}

export const StepLabels = ({ steps, stepWidthPixels }) => {
  const { isMobile } = UseViewSize()
  return (
    <Flex align="center" justify="center" p={isMobile ? '6px 12px' : '0 24px 12px'}>
      {steps.map((step, index) => {
        return (
          <Flex w={stepWidthPixels} justify="center" key={index}>
            <Link key={index} to={step.link}>
              <Text variant="caption2">{step.title}</Text>
            </Link>
          </Flex>
        )
      })}
    </Flex>
  )
}

export const HelpStepNumberIcon = (props: { stepNumber: number; state: 'complete' | 'current' | 'incomplete' }) => {
  const { isMobile } = UseViewSize()
  const { stepNumber, state } = props

  // if step is complete show complete
  //
  if (state === 'complete') {
    return <MaterialIcon name="check_circle" colorhex={COLORS.success_500} size={isMobile ? '24px' : '26px'} />
  }
  // if step is incomplete and the currently selected step show step number
  //
  if (state === 'current') {
    return (
      <Circle size={isMobile ? '20px' : '24px'} border={`2px solid ${COLORS.success_500}`} color={COLORS.success_500}>
        {stepNumber}
      </Circle>
    )
  }
  // by default return the basic step number
  //
  return (
    <Circle
      size={isMobile ? '20px' : '24px'}
      border={`1px solid ${COLORS.shades_neutral_400}`}
      color={COLORS.shades_neutral_400}
    >
      {stepNumber}
    </Circle>
  )
}
