import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Flex } from '@chakra-ui/react'
import { generatePath, Redirect } from 'react-router-dom'

import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { InventoryTabs, LINES_TABS } from '../InventoryTabs'
import {
  reduceInventoryAddStockModalVisibility,
  selectInventoryCounts,
  selectInventoryCountsPagination,
  selectRedirectInventoryCountId,
} from '../../../data/inventory/slice'
import { InventoryAddStockModal } from './InventoryAddStockModal'
import { useAppSelector } from '../../../hooks'
import { dispatchListInventoryCounts } from '../../../data/inventory/api'
import { InventoryAddStockList } from './InventoryAddStockList'
import { InventoryCountFilters } from '../filters/InventoryCountFilters'
import { EMPTY_INVENTORY_COUNT_FILTERS, INVENTORY_COUNT_TYPES } from '../../../data/inventory/constants'
import { filterInventoryCounts } from '../../../data/inventory/utils'
import { assign } from 'lodash'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { EmptySearch } from '../../../mini-lib/empty/EmptySearch'
import { Loading } from '../../../mini-lib/loading/Loading'
import { ROUTES } from "../../../appRoutes";
import { HelpDrawer } from "../../start-guide/common-components/HelpDrawer";
import { ReleasePageHelp } from "../../../mini-lib/flags/Release";
import { VIDEO_MAP } from "../../../mini-lib/video/constants";

export const InventoryAddStockPage = () => {
  const dispatch = useDispatch()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Inventory', url: generatePath(ROUTES.inventorySnapshot, { salonId }) },
    { label: 'Add Stock' },
  ]

  const releasePageHelp = ReleasePageHelp()
  const inventoryCounts = useAppSelector(selectInventoryCounts)
  const inventoryCountsPagination = useAppSelector(selectInventoryCountsPagination)
  const inventoryCountsLoaded = !!inventoryCounts
  const initialFilters = assign({}, EMPTY_INVENTORY_COUNT_FILTERS, {
    countTypes: [INVENTORY_COUNT_TYPES.order, INVENTORY_COUNT_TYPES.manual],
  })
  const [filters, setFilters] = useState(initialFilters)

  useEffect(() => {
    if (!inventoryCountsLoaded) {
      dispatch(dispatchListInventoryCounts({ token, salonId }))
    }
  }, [dispatch, token, salonId, inventoryCountsLoaded])

  useEffect(() => {
    dispatch(reduceInventoryAddStockModalVisibility(false))
  }, [dispatch])

  const filteredCounts = inventoryCounts ? filterInventoryCounts(inventoryCounts, filters) : null

  // when an inventory count gets created it sets the redirect state
  const redirectInventoryCountId = useAppSelector(selectRedirectInventoryCountId)
  if (redirectInventoryCountId) {
    const url = generatePath(ROUTES.inventoryCount, { salonId, countId: redirectInventoryCountId })
    // redirect to count page
    return <Redirect to={url} />
  }

  return (
    <>
      {releasePageHelp && (
        <HelpDrawer
          video={VIDEO_MAP.inventoryAddingStock}
          title={`Let’s take a look inside Add Stock`}
          content={`When an order comes in, simply use Add Stock to replenish your inventory. If you used our order tool, just use the add stock from order and verify it all was delivered and it instantly replenishes. If you didnt use our order feature you can simply copy your delivery list and add each item and qty in.`}
        />
      )}
      <PageLayout
        variant="full"
        header={
          <PageHeader
            showHelp={true}
            title="Inventory"
            breadcrumbs={breadcrumbs}
            actions={
              <Button
                colorScheme="brand.midnight"
                variant="round"
                onClick={() => {
                  dispatch(reduceInventoryAddStockModalVisibility(true))
                }}
              >
                Add Stock
              </Button>
            }
          />
        }
      >
        <InventoryTabs selectedTab={LINES_TABS.addStock} />
        <Box h="24px" />
        <InventoryCountFilters
          variant="orders and manual"
          filters={filters}
          setFilters={setFilters}
          showDateFilters={false}
          showCountTypeFilter={true}
          showProgressFilter={true}
        />
        <Box h="24px" />
        {filteredCounts && filteredCounts.length > 0 && <InventoryAddStockList inventoryCounts={filteredCounts} />}
        {inventoryCountsPagination && inventoryCountsPagination.currentPage !== inventoryCountsPagination.lastPage && (
          <Flex justify="center" p="12px">
            <Button
              colorScheme="brand.lavender"
              variant="round-outline"
              onClick={() => {
                dispatch(
                  dispatchListInventoryCounts({
                    token: token,
                    salonId,
                    pageNumber: inventoryCountsPagination.currentPage + 1,
                  }),
                )
              }}
            >
              View More
            </Button>
          </Flex>
        )}
        {inventoryCounts && inventoryCounts.length === 0 && (
          <EmptyBox h="200px" title="You dont have any counts yet" content="Click Add Stock to get started" />
        )}
        {inventoryCounts && inventoryCounts.length > 0 && filteredCounts?.length === 0 && (
          <EmptySearch variant="clear">You have no counts or no counts matched your filters</EmptySearch>
        )}
        {!filteredCounts && <Loading />}
      </PageLayout>
      <InventoryAddStockModal />
    </>
  )
}
