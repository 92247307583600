export const LOADING_USER = 'LOADING_USER'
export const USER_ROLES = {
  owner: 'owner',
  stylist: 'stylist',
  manager: 'manager',
  frontdesk: 'frontdesk',
  superuser: 'superuser',
}

export const USER_PERMISSIONS = {
  home: 'home',
  colorLines: 'color lines',
  addons: 'addons',
  labor: 'labor',
  extensions: 'extensions',
  stylists: 'stylists',
  reports: 'reports',
  orders: 'orders',
  settings: 'settings',
  inventoryAudit: 'inventory count',
  inventorySnapshot: 'inventory snapshot',
  inventoryStockAlerts: 'stock alerts',
  inventoryAddStock: 'add stock',
  salonGuide: 'salon guide',
  // note: everyone gets access to sessions and clients
}
