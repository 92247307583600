import React from 'react'
import { PAGE_CONTENT_WIDE_MAX_WIDTH } from '../../data/constants'
import { Box, Flex, Img, Text } from '@chakra-ui/react'
import { UseViewSize } from '../../core/UseViewSize'
import { UserNav } from '../../mini-lib/user-nav/UserNav'
import { SalonScaleIcon } from '../../mini-lib/icons/SalonScaleIcon'
import { Video } from '../../mini-lib/video/Video'
import { VideoData } from '../../mini-lib/video/constants'
import { Gap } from "../../mini-lib/gap/Gap";

export const SGLayout = (props: { content?: JSX.Element; children?: any }) => {
  const { content, children } = props
  const { isMobile, isTablet } = UseViewSize()
  return (
    <Flex direction="column" justify="center" align="center">
      <Box p={isMobile ? '' : '24px'} w='100%'><UserNav /></Box>
      <Box
        p={isMobile || isTablet ? '4px' : '0 12px'}
        maxW={PAGE_CONTENT_WIDE_MAX_WIDTH}
        w="100%"
        minH="100px"
        borderRadius={15}
      >
        {content}
        {children}
      </Box>
    </Flex>
  )
}

export const SGTwoColumnContent = (props: {
  iconName: string
  title: string
  description: string | JSX.Element
  descriptionActions?: JSX.Element
  video: VideoData
  actions: JSX.Element
  taskBar: JSX.Element
  imgPath: string
}) => {
  const { iconName, title, description, descriptionActions, video, actions, taskBar, imgPath } = props
  const { isMobile } = UseViewSize()
  return (
    <Box p="12px">
      <Box h={isMobile ? '24px' : '64px'} />
      <Flex justify="center" wrap={isMobile ? 'wrap-reverse' : 'inherit'}>
        <Box w={isMobile ? '100%' : '55%'} p="24px">
          <Flex align="center" gridGap="12px">
            <SalonScaleIcon size="32px" name={iconName} />
            <Text variant={isMobile ? 'largetitlemobile' : 'largetitle'} textTransform="uppercase">
              {title}
            </Text>
          </Flex>

          <Box h={isMobile ? '24px' : "48px"} />

          <Box whiteSpace="pre-wrap">{description}</Box>

          <Box h={isMobile ? '24px' : "48px"} />
          {descriptionActions}
          <Box h={isMobile ? '24px' : "48px"} />

          <Text variant="TODO" fontWeight="bold">
            Tutorial Video
          </Text>
          <Box h="12px" />

          <Video
            video={video}
            variant="miniplayer"
            width={isMobile ? '100%' : '275px'}
            height={isMobile ? '200px' : '150px'}
          />

          <Box h="24px" />

          {actions}
        </Box>
        <Box w={isMobile ? '100%' : '45%'} p={isMobile ? '0 24px' : "24px"}>
          <Flex justify={isMobile ? '' : "center"}>{taskBar}</Flex>
          {!isMobile && (
            <>
              <Gap s='48px' />
              <Img src={imgPath} />
            </>
          )}
        </Box>
      </Flex>
    </Box>
  )
}
