import { Button, DrawerBody, DrawerFooter, Flex } from '@chakra-ui/react'
import { InputControl } from 'formik-chakra-ui'
import { reduceSetSelectedClientId, selectSelectedClient } from '../../data/clients/slice'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { dispatchCreateClient, dispatchUpdateClient } from '../../data/clients/api'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import {
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectStepCompleteDrawerState
} from '../../data/start-guide/slice'
import { HELP_DRAWER_STATES } from '../start-guide/common-components/HelpStepCompleteDrawer'
import { dispatchUpdateUserChecklistItem } from "../../data/start-guide/api";
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { UseQueryParams } from "../../mini-lib/utils/basic";

export const ClientForm = (props: {}) => {
  const dispatch = useDispatch()
  const client = useAppSelector(selectSelectedClient)
  const {user, salonId} = UseBaseApiParams()
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const queryParams: any = UseQueryParams()
  const isGuide = !!queryParams.get('guide')
  const addClientStep = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.addClient))

  const formInitialValues = {
    id: client ? client.id : -1,
    firstName: client ? client.firstName : '',
    lastName: client ? client.lastName : '',
  }
  const formValidationSchema = Yup.object({
    id: Yup.string(),
    firstName: Yup.string().required('first name is required'),
    lastName: Yup.string().required('last name is required'),
  })

  const onSubmit = (values: any) => {
    // update
    if (values.id >= 0 && user && client) {
      const params = {
        token: user.token,
        client_id: client.id,
        salon_id: salonId,
        model: values,
      }
      dispatch(dispatchUpdateClient(params))
      dispatch(reduceSetSelectedClientId(null))
    }
    // create
    if (values.id < 0 && user) {
      const params = {
        token: user.token,
        salonId,
        model: values,
      }
      dispatch(dispatchCreateClient(params))
      dispatch(reduceSetSelectedClientId(null))
      if (isGuide && !addClientStep.completed) {
        dispatch(dispatchUpdateUserChecklistItem({ token: user.token, checklistItemCode: CHECKLIST_CODES.addClient }))
      }
    }

    if (HELP_DRAWER_STATES.button !== stepCompleteDrawerState) {
      dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
    }
  }
  return (
    <Formik onSubmit={onSubmit} initialValues={formInitialValues} validationSchema={formValidationSchema}>
      {({ handleSubmit, values, errors }) => (
        <>
          <DrawerBody>
            <Flex gridGap="12px" direction="column" onSubmit={handleSubmit as any}>
              <InputControl inputProps={{ borderRadius: '50px' }} name="firstName" label="First Name" />
              <InputControl inputProps={{ borderRadius: '50px' }} name="lastName" label="Last Name" />
            </Flex>
          </DrawerBody>
          <DrawerFooter m="24px 0 0 0">
            <Button
              className="cy-cancel-button"
              variant="round-outline"
              mr={3}
              onClick={() => dispatch(reduceSetSelectedClientId(null))}
            >
              Cancel
            </Button>
            <Button variant="round" colorScheme="brand.lavender" onClick={() => handleSubmit()}>
              Save
            </Button>
          </DrawerFooter>
        </>
      )}
    </Formik>
  )
}
