import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { buildDateYYYYMMDD } from '../../core/dates'
import { dispatchGetAllReportsByKind, dispatchGetReportsExtensionsbyBrand } from '../../data/report/api'
import { selectLoadingState } from '../../core/loading/slice'
import { REPORTS_LOADING_CONSTANT } from '../../data/report/constants'
import { ProductReport, ExtensionOrProductReportDetailsLara } from '../../data/report/interfaces'
import {
  reduceUsedOrUnusedCSV,
  selectCustomEndDate,
  selectCustomStartDate,
  selectProductReportList,
  selectReportsDateRangeType,
  selectUnusedProductsForBrand,
  selectUsedProductsForBrand,
} from '../../data/report/slice'
import { useAppSelector } from '../../hooks'
import { convertDateRangeTypeToDates } from '../../mini-lib/dates-and-times/utils'
import { pages } from './ReportsPage'
import { Loading } from '../../mini-lib/loading/Loading'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { VARIANTS } from '../../mini-lib/theme/variants'
import { UseViewSize } from '../../core/UseViewSize'
import { sortBy } from 'lodash'
import { TableHead, TableContent, TableTotal } from './Table'

export const ColorLineReportPage = (props: { page: string; setPage: (page: string) => void }) => {
  const [brandName, setbrandName] = useState('')
  const { page, setPage } = props

  return (
    <>
      {page === pages.productList && <ColorExtensionReportsTable setPage={setPage} setbrandName={setbrandName} />}
      {page === pages.productDetail && <ColorExtensionReportDetail brand={brandName} setPage={setPage} />}
    </>
  )
}

export const ColorExtensionReportsTable = (props: { setPage: (string) => void; setbrandName: (string) => void }) => {
  const { setPage, setbrandName } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const loadingReport = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const models = useAppSelector(selectProductReportList)
  const sumProfit = models ? models.map((model) => model.profit).reduce((a, b) => a + b, 0) : null
  const sumRevenue = models ? models.map((model) => model.retail).reduce((a, b) => a + b, 0) : null
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0

  // future todo: add paging to extensions table
  // const [numRowsToShow, setNumRowsToShow] = useState(30)
  // const modelsToShow = models ? models.slice(0, numRowsToShow) : null

  useEffect(() => {
    dispatch(dispatchGetAllReportsByKind({ token, salonId, dateStart: sDate, dateEnd: eDate, kind: 'color' }))
  }, [dispatch, token, salonId, sDate, eDate])

  return (
    <Flex flexDirection="column" width="100%">
      <Flex width="100%" align="center" p="20px" borderRadius="15px" bg="brand.lavender.100" maxH="50px">
        <Text variant={VARIANTS.text.caption1}>COLOR LINES</Text>
      </Flex>
      <Box h={'25px'} />
      {!loadingReport && models && models.length > 0 && (
        <ColorExtensionReportTableContainer
          modelsToShow={models}
          sumProfit={sumProfit}
          sumRevenue={sumRevenue}
          sumWholesale={sumWholesale}
          setPage={setPage}
          setbrandName={setbrandName}
        />
      )}
      {!loadingReport && models && models.length === 0 && <EmptyBox />}
      {loadingReport && <Loading />}
    </Flex>
  )
}

export const ColorExtensionReportTableContainer = (props: {
  modelsToShow: ProductReport[]
  sumProfit: number | null
  sumRevenue: number | null
  sumWholesale: number | null
  setPage: (string) => void
  setbrandName: (number) => void
}) => {
  const { modelsToShow, sumProfit, sumRevenue, sumWholesale, setPage, setbrandName } = props
  const list = [
    { label: 'Title' },
    { label: 'Cost', value: sumWholesale, additionalProps: { isNumeric: true, width: 120 } },
    { label: 'Price', value: sumRevenue, additionalProps: { isNumeric: true } },
    { label: 'Mark up', value: sumProfit, additionalProps: { isNumeric: true } },
  ]
  return (
    <>
      <TableHead list={list} />
      {sortBy(modelsToShow, 'brand').map((product, index) => (
        <Fragment key={index}>
          <ColorExtensionReportTableRow product={product} setPage={setPage} setbrandName={setbrandName} />
        </Fragment>
      ))}
      <TableTotal list={list} />
    </>
  )
}

export const ColorExtensionReportTableRow = (props: {
  product: ProductReport
  setPage: (string) => void
  setbrandName: (string) => void
}) => {
  const { product, setPage, setbrandName } = props
  const dispatch = useDispatch()
  const wholesale = product.retail - product.profit
  const list = [
    { label: 'Title', value: product.brand },
    { label: 'Cost', value: wholesale, additionalProps: { isNumeric: true, width: 120 } },
    { label: 'Price', value: product.retail, additionalProps: { isNumeric: true } },
    { label: 'Mark up', value: product.profit, additionalProps: { isNumeric: true } },
  ]
  return (
    <TableContent
      list={list}
      action={
        <Button
          minWidth={'70px'}
          height={'20px'}
          variant="round-ghost-lower"
          onClick={() => {
            setbrandName(product.brand)
            setPage(pages.productDetail)
            dispatch(reduceUsedOrUnusedCSV('used'))
          }}
        >
          view all
        </Button>
      }
    />
  )
}

// ---------Extension Report Detail--------------

export const ColorExtensionReportDetail = (props: { brand: string; setPage: (string) => void }) => {
  const { brand, setPage } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  //using this brand_name we will make a request to Product Detail Report
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const brandUsedProducts = useAppSelector(selectUsedProductsForBrand)
  const brandUnusedProducts = useAppSelector(selectUnusedProductsForBrand)
  const loadingReport = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const sumProfit = brandUsedProducts ? brandUsedProducts.map((model) => model.profit).reduce((a, b) => a + b, 0) : null
  const sumRevenue = brandUsedProducts
    ? brandUsedProducts.map((model) => model.retail).reduce((a, b) => a + b, 0)
    : null
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0

  // todo: add client side paging to this in a future iteration
  // const [numRowsToShow, setNumRowsToShow] = useState(50)
  // const usedProducts = brandUsedProducts ? brandUsedProducts.slice(0, numRowsToShow) : null

  useEffect(() => {
    dispatch(
      dispatchGetReportsExtensionsbyBrand({ token, salonId, brand, dateStart: sDate, dateEnd: eDate, kind: 'color' }),
    )
  }, [dispatch, token, brand, salonId, sDate, eDate])
  return (
    <div>
      <Tabs colorScheme="brand.lavender">
        <TabList borderBottom='unset' overflowX="auto" padding="4px">
          <Tab minW={isMobile ? '100px' : '180px'} onClick={() => dispatch(reduceUsedOrUnusedCSV('used'))}>
            Used Product
          </Tab>
          <Tab minW={isMobile ? '100px' : '180px'} onClick={() => dispatch(reduceUsedOrUnusedCSV('unused'))}>
            Unused Product
          </Tab>
        </TabList>
        <TabPanels>
          {/* Used Products  */}
          <TabPanel maxW="1200px">
            <Flex
              width="100%"
              justify="space-between"
              align="center"
              p="20px"
              borderRadius="15px"
              bg="brand.lavender.100"
            >
              <Text variant={VARIANTS.text.caption1}>{brand}</Text>
              <Box cursor="pointer" onClick={() => setPage(pages.productList)}>
                <Text variant={VARIANTS.text.caption1}>Back</Text>
              </Box>
            </Flex>
            <Box h={'25px'} />
            {!loadingReport && brandUsedProducts && brandUsedProducts.length > 0 && (
              <ColorExtensionReportDetailTableContainer
                usedProducts={brandUsedProducts}
                sumProfit={sumProfit}
                sumRevenue={sumRevenue}
                sumWholesale={sumWholesale}
              />
            )}
            {!loadingReport && brandUsedProducts && brandUsedProducts.length === 0 && <EmptyBox />}
            {loadingReport && <Loading />}
          </TabPanel>

          {/* Unused Product */}
          <TabPanel>
            <Flex
              width="100%"
              justify="space-between"
              align="center"
              p="20px"
              borderRadius="15px"
              bg="brand.lavender.100"
            >
              <Text variant={VARIANTS.text.caption1}>{brand}</Text>
              <Box cursor="pointer" onClick={() => setPage(pages.productList)}>
                <Text variant={VARIANTS.text.caption1}>Back</Text>
              </Box>
            </Flex>
            <Box h={'25px'} />
            {!loadingReport && brandUnusedProducts && brandUnusedProducts.length > 0 && (
              <ColorExtensionReportDetailUnusedTableContainer unusedProducts={brandUnusedProducts} />
            )}
            {!loadingReport && brandUnusedProducts && brandUnusedProducts.length === 0 && <EmptyBox />}
            {loadingReport && <Loading />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  )
}

// UsedProduct

export const ColorExtensionReportDetailTableContainer = (props: {
  usedProducts: ExtensionOrProductReportDetailsLara[]
  sumProfit: number | null
  sumRevenue: number | null
  sumWholesale: number | null
}) => {
  const { usedProducts, sumProfit, sumRevenue, sumWholesale } = props
  const { isMobile, isTablet } = UseViewSize()
  const minWidth = '110px'
  const list = [
    { label: 'Line' },
    { label: 'Color', additionalProps: { noTotal: true, width: isMobile || isTablet ? 105 : 180 } },
    { label: 'Type', additionalProps: { noTotal: true, width: isMobile || isTablet ? 105 : 180 } },
    { label: 'Qty', additionalProps: { noTotal: true, width: isMobile || isTablet ? 105 : 120 } },
    {
      label: 'Cost',
      value: sumWholesale,
      additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 },
    },
    {
      label: 'Price',
      value: sumRevenue,
      additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 },
    },
    {
      label: 'Mark up',
      value: sumProfit,
      additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 },
    },
  ]
  return (
    <>
      <TableHead list={list} minWidth={minWidth} noAction />
      {usedProducts.map((product, index) => {
        return (
          <Fragment key={index}>
            <ColorExtensionReportDetailTableRow product={product} />
          </Fragment>
        )
      })}
      <TableTotal list={list} minWidth={minWidth} noAction />
    </>
  )
}

export const ColorExtensionReportDetailTableRow = (props: { product: ExtensionOrProductReportDetailsLara }) => {
  const { product } = props
  const wholesale = product.retail - product.profit
  const { isMobile, isTablet } = UseViewSize()
  const minWidth = '110px'
  const list = [
    { label: 'Line', value: product.line },
    { label: 'Color', value: product.color, additionalProps: { width: isMobile || isTablet ? 105 : 180 } },
    { label: 'Type', value: product.category, additionalProps: { width: isMobile || isTablet ? 105 : 180 } },
    {
      label: 'Qty',
      value: product.quantity,
      additionalProps: { noTotal: true, width: isMobile || isTablet ? 105 : 120 },
    },
    { label: 'Cost', value: wholesale, additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 } },
    {
      label: 'Price',
      value: product.retail,
      additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 },
    },
    {
      label: 'Mark up',
      value: product.profit,
      additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 },
    },
  ]
  return <TableContent list={list} minWidth={minWidth} />
}

// UnusedProduct

export const ColorExtensionReportDetailUnusedTableContainer = (props: {
  unusedProducts: ExtensionOrProductReportDetailsLara[]
}) => {
  const { unusedProducts } = props
  const list = [
    { label: 'Line' },
    { label: 'Color', additionalProps: { isNumeric: true, noTotal: true, width: 200 } },
    { label: 'Type', additionalProps: { isNumeric: true, noTotal: true, width: 200 } }
  ]
  const minWidth = '200px'

  return (
    <>
      <TableHead list={list} minWidth={minWidth} />
      {unusedProducts.map((product, index) => (
        <Fragment key={index}>
          <ColorExtensionReportDetailUnusedTableRow unusedProduct={product} />
        </Fragment>
      ))}
    </>
  )
}

export const ColorExtensionReportDetailUnusedTableRow = (props: { unusedProduct: ExtensionOrProductReportDetailsLara }) => {
  const { unusedProduct } = props

  const list = [
    { label: 'Line', value: unusedProduct.line },
    { label: 'Color', value: unusedProduct.color, additionalProps: { noTotal: true, width: 200 } },
    { label: 'Type', value: unusedProduct.category, additionalProps: { noTotal: true, width: 200 } },
  ]

  return <TableContent list={list} minWidth={'200px'} action={<></>} />
}
