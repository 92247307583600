import { createSlice } from '@reduxjs/toolkit'

interface HomeState {}

// Define the initial state using that type
const initialState: HomeState = {
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
})

export default homeSlice.reducer
