/////////////// mappers.ts
//
//

import { has, keys, map, round, uniq } from 'lodash'
import {
  APIColorLara,
  APIColorPatchLara,
  APILineLara,
  APILineOption,
  APIProduct,
  APIUpdateProduct,
  APIVendorLara,
  ColorLara,
  LineLara,
  LineNamesByVendor,
  LineOption,
  LinesById,
  LinesByVendor,
  Product,
  ProductFilters,
  ProductsByCategoryThenByLineSize,
  Vendor,
  VendorLara,
  VendorsById,
} from './interfaces'
import { GROUPED_CATEGORIES } from './constants'
import { filterProduct } from './utils'
import { InventoryCountItem } from '../inventory/interfaces'
import { getQuantityOnHand, getStockLevel } from '../inventory/utils'
import { convertDisplayUnits } from "../sessions/utils";
import { centsToDollars } from "../../core/money/utils";

export const mapAPIProductToProduct = ( api: APIProduct ): Product => {
  return {
    id: api.id,
    masterProductId: api.master_product_id,
    category: api.category,
    // lineName: api.brand_name, // this is confusing
    // vendorName: api.parent_brand_name, // also super confusing
    // vendorLogoUrl: api.parent_brand_logo_url,
    type: api.type,
    typeAlias: api.user_defined_type,
    inventory: {
      minStockLevel: api.inventory.min_stock_level,
      maxStockLevel: api.inventory.max_stock_level,
      amountGramsInStock: api.inventory.amount_grams_in_stock,
      quantityOnHand: round(api.inventory.quantity_on_hand, 1),
      cost: api.inventory.cost,
      costPerG: api.inventory.cost_per_g,
      costPerOz: api.inventory.cost_per_oz,
      stockLevel: getStockLevel(api.inventory.quantity_on_hand, api.inventory.max_stock_level),
    },
    pricing: {
      price: api.pricing.price,
      pricePerG: api.pricing.price_per_g,
      pricePerOz: api.pricing.price_per_oz,
    },
    vendor: {
      id: api.vendor.id,
      name: api.vendor.name,
      logoUrl: api.vendor.logo_url,
    },
    line: {
      id: api.line.id,
      name: api.line.name,
    },
    isDefaultPrice: api.is_default_price,
    size: api.size,
    lastUpdated: api.last_updated,
    units: api?.units ?? null
  }
}
export const mapAPIProductToProducts = ( apiModels: APIProduct[] ): Product[] => {
  return map(apiModels, mapAPIProductToProduct)
}

export const mapProductsToProductsByLine = ( products: Product[] ): { [key: string]: Product[] } => {
  const lines: { [key: string]: Product[] } = {}
  products.forEach(( product ) => {
    has(lines, product.line.name)
      ? ( lines[product.line.name] = [...lines[product.line.name], product] )
      : ( lines[product.line.name] = [product] )
  })
  return lines
}

export const mapProductsToProductsByVendor = ( products: Product[] ): { [key: string]: Product[] } => {
  const vendors: { [key: string]: Product[] } = {}
  products.forEach(( product ) => {
    has(vendors, product.vendor.name)
      ? ( vendors[product.vendor.name] = [...vendors[product.vendor.name], product] )
      : ( vendors[product.vendor.name] = [product] )
  })
  return vendors
}

export const mapProductsToProductsByCategory = ( products: Product[] ): { [key: string]: Product[] } => {
  const map: { [key: string]: Product[] } = {}
  products.forEach(( product ) => {
    has(map, product.category)
      ? ( map[product.category] = [...map[product.category], product] )
      : ( map[product.category] = [product] )
  })
  return map
}

export const mapProductsToProductsByLineCategory = ( products: Product[] ): { [key: string]: Product[] } => {
  const byCategory: { [key: string]: Product[] } = {}
  products.forEach(( product ) => {
    const categoryLine = `${product.line.name}-${product.category}`
    has(byCategory, categoryLine)
      ? ( byCategory[categoryLine] = [...byCategory[categoryLine], product] )
      : ( byCategory[categoryLine] = [product] )
  })
  return byCategory
}

export const mapProductsToProductsByCategoryThenLine = ( products: Product[] ): { [key: string]: Product[] } => {
  const byCategory: { [key: string]: Product[] } = {}
  products.forEach(( product ) => {
    const categoryLine = `${product.line.name}-${product.category}`
    has(byCategory, categoryLine)
      ? ( byCategory[categoryLine] = [...byCategory[categoryLine], product] )
      : ( byCategory[categoryLine] = [product] )
  })
  return byCategory
}

export const mapProductsToProductsByCategoryLineSize = ( products: Product[] ): { [key: string]: Product[] } => {
  const map: { [key: string]: Product[] } = {}
  products.forEach(( product ) => {
    const lineCategorySize = `${product.category}-${product.line.name}-${product.size}`
    has(map, lineCategorySize)
      ? ( map[lineCategorySize] = [...map[lineCategorySize], product] )
      : ( map[lineCategorySize] = [product] )
  })
  return map
}

export const mapProductsToProductsByLineSize = ( products: Product[] ): { [key: string]: Product[] } => {
  const map: { [key: string]: Product[] } = {}
  products.forEach(( product ) => {
    const lineSize = `${product.line.name}-${product.size}`
    has(map, lineSize) ? ( map[lineSize] = [...map[lineSize], product] ) : ( map[lineSize] = [product] )
  })
  return map
}

export const mapProductToLine = ( product: Product ): LineOption => {
  return {
    lineName: product.line.name,
    lineId: product.line.id,
    vendorName: product.vendor.name,
    vendorId: product.vendor.id,
    vendorLogoUrl: product.vendor.logoUrl,
  }
}

export const mapProductsToLineNamesByVendor = ( products: Product[] ): LineNamesByVendor => {
  const vendors: { [key: string]: string[] } = {}
  products.forEach(( product ) => {
    has(vendors, product.vendor.name)
      ? ( vendors[product.vendor.name] = [...vendors[product.vendor.name], product.line.name] )
      : ( vendors[product.vendor.name] = [product.line.name] )
  })
  const sortedVendors: { [key: string]: string[] } = {}
  keys(vendors).forEach(( key ) => {
    const linesForKey = vendors[key]
    sortedVendors[key] = uniq(linesForKey).sort()
  })
  return sortedVendors
}

export const mapProductsToLinesByVendor = ( products: Product[] ): LinesByVendor => {
  const vendors: { [key: string]: LineOption[] } = {}
  products.forEach(( product ) => {
    const line: LineOption = mapProductToLine(product)
    has(vendors, product.vendor.name)
      ? ( vendors[product.vendor.name] = [...vendors[product.vendor.name], line] )
      : ( vendors[product.vendor.name] = [line] )
  })
  const sortedVendors: { [key: string]: LineOption[] } = {}
  keys(vendors).forEach(( key ) => {
    const linesForKey = vendors[key]
    sortedVendors[key] = uniq(linesForKey).sort()
  })
  return sortedVendors
}

export const mapLinesByIdToLinesByVendor = ( linesById: LinesById ): LinesByVendor => {
  const vendors: LinesByVendor = {}
  keys(linesById).forEach(( key ) => {
    const line = linesById[key]
    has(vendors, line.vendorName)
      ? ( vendors[line.vendorName] = [...vendors[line.vendorName], line] )
      : ( vendors[line.vendorName] = [line] )
  })
  const sortedVendors: LinesByVendor = {}
  keys(vendors).forEach(( key ) => {
    const linesForKey = vendors[key]
    sortedVendors[key] = uniq(linesForKey).sort()
  })
  return sortedVendors
}

export const mapLinesByIdToVendorsById = ( linesById: LinesById ): VendorsById => {
  const vendorsById: VendorsById = {}
  keys(linesById).forEach(( key ) => {
    const line = linesById[key]
    const vendor: Vendor = {
      id: line.vendorId,
      name: line.vendorName,
      logoUrl: line.vendorLogoUrl,
    }
    vendorsById[vendor.id] = vendor
  })
  return vendorsById
}

export const mapAPILineToLine = ( line: APILineOption ): LineOption => {
  return {
    lineName: line.name,
    lineId: line.brand_id,
    vendorName: line.parent_brand,
    vendorId: line.parent_brand_id,
    vendorLogoUrl: line.parent_brand_logo_url,
  }
}
export const mapAPILinesToLines = ( lines: APILineOption[] ): LineOption[] => {
  const mapped = map(lines, mapAPILineToLine)
  return mapped
}

export const mapLineToAPILine = ( line: LineOption ): APILineOption => {
  return {
    name: line.lineName,
    brand_id: line.lineId,
    parent_brand: line.vendorName,
    parent_brand_id: line.vendorId,
    parent_brand_logo_url: line.vendorLogoUrl,
  }
}
export const mapLinesToAPILines = ( lines: LineOption[] ): APILineOption[] => {
  const mapped = map(lines, mapLineToAPILine)
  return mapped
}

export const mapProductsByCategoryThenByLineSize = (
  products: Product[],
  filters: ProductFilters,
): ProductsByCategoryThenByLineSize => {
  const m: { [key: string]: { [key: string]: Product[] } } = {}
  products.forEach(( product ) => {
    const category = product.category.toLowerCase()
    const lineOrLineSizeBasedOnCategory = GROUPED_CATEGORIES.includes(category)
      ? `${product.line.name}`
      : `${product.line.name}-${product.size}`
    if (filterProduct(product, filters)) {
      if (m[category]) {
        if (m[category][lineOrLineSizeBasedOnCategory]) {
          m[category][lineOrLineSizeBasedOnCategory] = [...m[category][lineOrLineSizeBasedOnCategory], product]
        } else {
          m[category][lineOrLineSizeBasedOnCategory] = [product]
        }
      } else {
        m[category] = {}
        m[category][lineOrLineSizeBasedOnCategory] = [product]
      }
    }
  })
  return m
}

export const mapCountItemsToCountItemsByCategory = (
  items: InventoryCountItem[],
): { [key: string]: InventoryCountItem[] } => {
  const map: { [key: string]: InventoryCountItem[] } = {}
  items.forEach(( item ) => {
    has(map, item.product.category)
      ? ( map[item.product.category] = [...map[item.product.category], item] )
      : ( map[item.product.category] = [item] )
  })
  return map
}


// lara mappers
//
//
export const mapAPIColorLaraToColorLara = ( api: APIColorLara ): ColorLara => {
  return {
    id: api.id,
    masterProductIsDeleted: api.master_product_is_deleted,
    category: api.category,
    brand: api.brand,
    type: api.type,
    typeIsDeleted: api.type_is_deleted,
    size: api.size,
    volume: api.volume,
    level: api.level,
    nickname: api.nickname,
    line: mapAPILineLaraToLineLara(api.line),
    vendor: mapAPIVendorLaraToVendorLara(api.vendor),
    salonPurchasePriceCents: api.salon_purchase_price,
    salonPurchaseCentsPerGram: api.salon_purchase_price_per_gram,
    clientPurchasePriceCents: api.client_purchase_price,
    clientPurchaseCentsPerGram: api.client_purchase_price_per_gram,
    target: api.target,
    amountGramsInStock: api.amount_grams_in_stock,
    isDefaultPrice: api.is_default_price,
    masterProductId: api.master_product_id,
    markUp: api.mark_up,
    isDeleted: api.is_deleted,
    quantityOnHand: api.quantity_on_hand,
    stockLevel: getStockLevel(api.quantity_on_hand, api.target),
  }
}
export const mapAPIColorsLaraToColorsLara = ( api: APIColorLara[] ): ColorLara[] => {
  return map(api, mapAPIColorLaraToColorLara)
}

export const mapAPIColorToProduct = ( api: APIColorLara ): Product => {
  const quantityOnHand = getQuantityOnHand(api.amount_grams_in_stock, api.size) // todo: should be using `api.quantity_on_hand` but it's returning 0,
  return {
    id: api.id,
    units: api?.units ?? null,
    masterProductId: api.master_product_id,
    category: api.category,
    // lineName: api.brand_name, // this is confusing
    // vendorName: api.parent_brand_name, // also super confusing
    // vendorLogoUrl: api.parent_brand_logo_url,
    type: api.type,
    typeAlias: api.nickname,
    inventory: {
      minStockLevel: null,
      maxStockLevel: api.target,
      amountGramsInStock: api.amount_grams_in_stock,
      quantityOnHand: round(quantityOnHand, 2),
      cost: centsToDollars(api.salon_purchase_price),
      costPerG: centsToDollars(api.salon_purchase_price_per_gram),
      costPerOz: centsToDollars(convertDisplayUnits(api.salon_purchase_price_per_gram, 'g', 'oz')),
      stockLevel: getStockLevel(quantityOnHand, api.target),
    },
    pricing: {
      price: centsToDollars(api.client_purchase_price),
      pricePerG: centsToDollars(api.client_purchase_price_per_gram),
      pricePerOz: centsToDollars(convertDisplayUnits(api.client_purchase_price_per_gram, 'g', 'oz')),
    },
    vendor: {
      id: api.vendor.id,
      name: api.vendor.name,
      logoUrl: api.vendor.logo_url,
    },
    line: {
      id: api.line.id,
      name: api.line.name,
    },
    isDefaultPrice: api.is_default_price,
    size: api.size,
    lastUpdated: '',
  }
}
export const mapAPIColorsToProducts = ( api: APIColorLara[] ): Product[] => {
  return map(api, mapAPIColorToProduct)
}

export const mapAPILineLaraToLineLara = ( apiLineLara: APILineLara ): LineLara => ( {
  id: apiLineLara.id,
  isDeleted: apiLineLara.is_deleted,
  name: apiLineLara.name,
  kind: apiLineLara.kind,
} )

export const mapAPIVendorLaraToVendorLara = ( apiVendorLara: APIVendorLara ): VendorLara => ( {
  id: apiVendorLara.id,
  isDeleted: apiVendorLara.is_deleted,
  name: apiVendorLara.name,
  logoUrl: apiVendorLara.logo_url,
} )

export const mapAPILineLaraListToLineLaraList = ( apiLineLaraList: APILineLara[] ): LineLara[] => (
  map(apiLineLaraList, mapAPILineLaraToLineLara)
)

export const mapAPIVendorLaraListToVendorLaraList = ( apiVendorLaraList: APIVendorLara[] ): VendorLara[] => (
  map(apiVendorLaraList, mapAPIVendorLaraToVendorLara)
)

export const mapAPIUpdateProductToAPIPatchLara = ( apis: APIUpdateProduct[] ): { colors: APIColorPatchLara[] } => {
  const apiColors = apis.map(api => {
    const apiColor: APIColorPatchLara = { id: api.product_id }
    if (api.nickname !== undefined && api.nickname !== null) apiColor.nickname = api.nickname;
    if (api.mark_up !== undefined && api.mark_up !== null) apiColor.mark_up = api.mark_up;
    if (api.wholesale_price !== undefined && api.wholesale_price !== null) apiColor.salon_purchase_price_cents = api.wholesale_price ? Math.round(api.wholesale_price * 100) : 0;
    if (api.is_default_price !== undefined && api.is_default_price !== null) apiColor.is_default_price = api.is_default_price;
    if (api.max_stock_level !== undefined && api.max_stock_level !== null) apiColor.target = api.max_stock_level;
    if (api.amount_grams_in_stock !== undefined && api.amount_grams_in_stock !== null) apiColor.amount_grams_in_stock = api.amount_grams_in_stock;
    return apiColor
  })
  return { colors: apiColors }
}
