import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Input, Text, Textarea, } from '@chakra-ui/react'

import {
  dispatchCreateNotificationForAdmin,
  dispatchListNotificationForAdmin,
  dispatchUpdateNotificationForAdmin
} from '../../data/notification-admin/api'
import { selectSelectedNotification } from '../../data/notification-user/slice'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import Select from 'react-select'
import { selectPermissionSettings } from '../../data/settings/slice'
import { useAppSelector } from '../../hooks'
import { NotificationCreate } from '../../data/notification-user/interfaces'
import { format, formatISO, parse } from 'date-fns';
import { COLORS } from '../../mini-lib/theme/colors'
import { SALONSCALE_LOGO_URL } from '../../data/constants'
import { dropdownGhostVariant2Styles } from '../../theme'
import { TriangleDownIcon } from '@chakra-ui/icons'
import { NotificationRow as NotificationPreview } from '../../mini-lib/notification'
import './styles.scss'
import { Loading } from '../../mini-lib/loading/Loading'
import { selectLoadingState } from '../../core/loading/slice'
import { NOTIFICATIONS_CREATE_LOADING_CONSTANT } from '../../data/notification-user/constants'
import { FormikError } from "../../mini-lib/formik-utils/FormikError";

type TextState = {
  target: {
    value: string;
  };
};

export const NotificationsForm = ({ toggleToList, roles }: { toggleToList: Function, roles: any }) => {
  const { user: { token }, salonId } = UseBaseApiParams()
  const selectedNotification = useAppSelector(selectSelectedNotification)
  const dispatch = useDispatch()
  const [title, setTitle] = useState(selectedNotification?.title ?? '');
  const [description, setDescription] = useState(selectedNotification?.description ?? '')
  const [visibleTo, setVisibleTo] = useState(selectedNotification?.visibleTo ?? '');
  const [visibleDate, setVisibleDate] = useState(selectedNotification ? format(new Date(selectedNotification.visibleDate), 'yyyy-MM-dd') : '');
  const [visibleTime, setVisibleTime] = useState(selectedNotification ? format(new Date(selectedNotification.visibleDate), 'HH:mm') : '');
  const [url, setUrl] = useState(selectedNotification?.url ?? '');
  const [avatarUrl] = useState(selectedNotification?.avatarUrl ?? SALONSCALE_LOGO_URL);
  const [isChecked, setCheckbox] = useState(Boolean(selectedNotification?.url));
  const [showError, setShowError] = useState(false)

  const permissionSettings = useAppSelector(selectPermissionSettings)
  const permissionSettingsLoaded = !!permissionSettings
  const creatingNotifications = useAppSelector((state) => selectLoadingState(state, NOTIFICATIONS_CREATE_LOADING_CONSTANT))
  useEffect(() => {
    if (!permissionSettingsLoaded) {
      dispatch(dispatchListNotificationForAdmin(token))
    }
  }, [dispatch, token, salonId, permissionSettingsLoaded])

  const dropdownOptions: { label: string, value: string }[] = roles?.map((role) => ({ label: role.name, value: role.name })) ?? []

  // set first option for dropdown
  dropdownOptions.splice(0, 0, { label: 'All', value: '' })

  const handleTitleChange = ({ target: { value } }) => { setTitle(value) };

  const handleDescriptionChange = ({ target: { value } }) => { setDescription(value) };

  const handleVisibleDateChange = ({ target: { value } }) => { setVisibleDate(value) };

  const handleVisibleTimeChange = ({ target: { value } }) => { setVisibleTime(value) };

  const handleUrlChange = ({ target: { value } }) => { setUrl(value) }

  const handleVisibleToChange = ({ value }) => { setVisibleTo(value) }

  const handleCheckboxChange = () => {
    setCheckbox(!isChecked);
    setUrl('')
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setShowError(true)
    const hasError = !Boolean(title && visibleDate && visibleTime)

    if (!hasError && !creatingNotifications) {
      const parsedDate = parse(visibleDate, 'yyyy-MM-dd', new Date());
      const parsedTime = parse(visibleTime, 'HH:mm', new Date());
      const combinedDateTime = formatISO(parsedDate.setHours(parsedTime.getHours(), parsedTime.getMinutes(), parsedTime.getSeconds()));
     // converting date to UTC
      const utcDate = new Date(parsedDate.getTime() + new Date(combinedDateTime).getTimezoneOffset() * 60000);
      const date = new Date(utcDate);
      const formattedUtcDate: any = formatISO(date);

      const model: NotificationCreate = {
        title,
        description,
        visible_to: visibleTo,
        visible_date: formattedUtcDate,
        url,
        avatar_url: avatarUrl
      }

      // dispatch update if notification is not null
      selectedNotification ?
        await dispatch(dispatchUpdateNotificationForAdmin({ token, model: { ...model, id: selectedNotification.id } })) : await dispatch(dispatchCreateNotificationForAdmin({ token, model }))
      // set State to show list
      toggleToList()
    }
  };

  const selectedVisibleTo = dropdownOptions.filter(({ value }) => value === visibleTo)[0]

  return (
    <Box className='notification-form'>
      <form onSubmit={handleSubmit}>
        <CustomSelect options={dropdownOptions} onChange={handleVisibleToChange} selectedValue={selectedVisibleTo} />
        <CustomInput type='text' value={title} wordsLimit={70} onChange={handleTitleChange} placeholder="" label='Title' showError={Boolean(showError && !title)} errorText='Required!' />
        <FormControl mt='3rem'>
          <Checkbox size='lg' isChecked={isChecked} colorScheme="brand.lavender" onChange={handleCheckboxChange}>
            <Text fontSize='16px' variant="body">This notification has a link</Text>
          </Checkbox>
        </FormControl>
        {isChecked && (<CustomInput type='text' wordsLimit={255} value={url} onChange={handleUrlChange} placeholder="" label='Link URL' />)}
        <CustomInput type='textarea' value={description} onChange={handleDescriptionChange} placeholder="" label='Description' wordsLimit={255}/>
        <Text mt='1.5rem' fontSize='16px' variant="body">Preview Notification</Text>
        <Box mt='8px' padding='1.5rem' display='flex' justifyContent='center' className='text' border={`0.5px solid ${COLORS.shades_neutral_300}`} borderRadius={'8px'}>
          <NotificationPreview
            id={0}
            title={title}
            description={description}
            url={url}
            avatarUrl={avatarUrl}
            read={false}
            additionalStyles={{ borderRadius: '8px' }}
            openOnClick={false}
          />
        </Box>
        <Text mt='1.5rem' fontSize='16px' variant="body">Schedule Notification:</Text>
        <Flex alignItems='flex-start'>
          <CustomInput
            type='date'
            value={visibleDate}
            onChange={handleVisibleDateChange}
            placeholder="MM/DD/YYYY"
            label='Choose Date'
            showError={Boolean(showError && !visibleDate)}
            errorText='Required!'
            additionalStyles={{ marginRight: '10px' }}
          />
          <CustomInput
            type='time'
            value={visibleTime}
            onChange={handleVisibleTimeChange}
            placeholder=""
            label='Choose Time'
            showError={Boolean(showError && !visibleTime)}
            errorText='Required!'
            additionalStyles={{ marginLeft: '10px' }}
          />
        </Flex>
        <Button height='56px' fontSize='18px' mt='18px' width='100%' type="submit" colorScheme="brand.lavender">
          {creatingNotifications ? <Loading variant='clear' /> : 
          <> {`${selectedNotification?.id ? 'Update' : 'Schedule'} Notification`} </>
          }
         
        </Button>
      </form>
    </Box>
  )
}

const CustomInput = ({
  type = 'text',
  value,
  onChange,
  placeholder,
  label,
  additionalStyles = {},
  showError = false,
  errorText = '',
  wordsLimit = null
} : {
  type: 'time' | 'text' | 'date' | 'textarea',
  value: string,
  onChange: any,
  placeholder: string,
  label: string,
  additionalStyles?: any,
  showError?: boolean,
  errorText?: string,
  wordsLimit?: number | null,
}) => {
  const [text, setText] = useState<TextState>({ target: { value } })
  const [ownError, setOwnError] = useState<null | string>(null)

  const textCount = value.length

  useEffect(() =>  {text && onChange(text) }, [text, onChange])

  const handleChange = (e) => {
    if (wordsLimit && e.target.value.length >= wordsLimit) {
      e.target.value.length === wordsLimit && setText(e)
      setOwnError(`Max limit ${wordsLimit} characters`);
    } else {
      setOwnError(null)
      setText(e)
    }
  }


  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text/plain');
    const newLength = text.target.value.length + pastedText.length;
    if (wordsLimit && newLength <= wordsLimit - 1) {
      setText(event);
    } else {
      wordsLimit ? setOwnError(`Max limit ${wordsLimit} characters`) : setText(event);
      wordsLimit && event.preventDefault();
    }
  };


  return (
    <Flex direction='column' width="100%">
      <FormControl mt='18px' padding='10px 20px 3px 20px' background={COLORS.form_control} borderRadius='6px 6px 0px 0px' borderBottom={`1px solid ${COLORS.border}`} {...additionalStyles}>
        <FormLabel mb='unset'>
          <Text variant='small-subtitle'>  {label}</Text>
        </FormLabel>
        {type === 'textarea' ?
          <Textarea padding='unset' height='20px' border='unset' onChange={handleChange} onPaste={handlePaste} value={text.target.value} /> :
          <Input padding='unset' height='20px' border='unset' fontSize='16px' type={type} value={text.target.value} onChange={handleChange} onPaste={handlePaste} placeholder={placeholder} />}
        <FormikError showError={Boolean(ownError) ?? showError} errorText={ownError ?? errorText} />
      </FormControl>
      {wordsLimit && <Text variant='small-subtitle' mt='1rem' textAlign='end'>{textCount}/{wordsLimit}</Text>}
    </Flex>
  )
}

const CustomSelect = ({
  options = [],
  selectedValue = null,
  onChange
}: { options: { label: string, value: string }[], selectedValue?: { label: string, value: string } | null, onChange: any }) => {
  const styles = dropdownGhostVariant2Styles('lavender')
  return (
    <FormControl mt='18px' padding='10px 20px 7px 20px' background={COLORS.form_control} borderRadius='6px 6px 0px 0px' borderBottom={`1px solid ${COLORS.border}`}>
      <FormLabel mb='unset'>
        <Text variant='small-subtitle'>Send Notification to</Text>
      </FormLabel>
      <Select
        value={selectedValue}
        styles={styles}
        components={{
          DropdownIndicator: () => <TriangleDownIcon height='0.7rem' width='0.7rem' />,
          IndicatorSeparator: () => <></>
        }}

        placeholder=""
        options={options}
        onChange={onChange}
        isClearable={false}
      />
    </FormControl>
  )
}
