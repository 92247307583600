import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { generatePath } from 'react-router-dom'
import React, { useEffect } from 'react'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { ColorReportsTabs } from './ColorReportsTabs'

import { Box, Table, TableContainer } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { ReportTableContent, ReportTableFooters, ReportTableHeaders } from '../ReportTable'
import {
  selectColorStylistWasteReports,
  selectCustomEndDate,
  selectCustomStartDate,
  selectReportsDateRangeType,
  selectedUnit,
  selectColorStylistReports
} from '../../../data/reports-v2/slice'
import { dispatchGetColorStylistReports } from '../../../data/reports-v2/api'
import { ReportSectionHeader } from '../../reports-v2/ReportSectionHeader'
import { COLORS } from '../../../mini-lib/theme/colors'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { REPORTS_LOADING_CONSTANT } from '../../../data/reports-v2/constants'
import { selectLoadingState } from '../../../core/loading/slice'
import { convertDateRangeTypeToDates } from '../../../mini-lib/dates-and-times/utils'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { buildCsv } from '../../../mini-lib/csv/buildCsv'
import { ColorStylistReport } from '../../../data/reports-v2/interfaces'
import { UseViewSize } from '../../../core/UseViewSize'
import { COLOR_STYLIST_PRODUCTS_DETAILS_REPORTS_TABS, ColorStylistReportsTabs } from './ColorStylistReportsTabs'
import { totalWastePercentageCalculator, pluralizeNameIfRequired } from '../../../data/reports-v2/utils'
import { convertDisplayUnits } from '../../../data/sessions/utils'
import { sumBy } from 'lodash'
import { formatDollars } from "../../../mini-lib/units/money";
import { Units } from '../../../data/reports-v2/interfaces'
import { ROUTES } from "../../../appRoutes";

export const ColorStylistsWasteReportsPage = (props: { selectedTab: number }) => {
  const { selectedTab } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = [
    {
      label: 'Reports',
      url: generatePath(ROUTES.colorStylistReports, { salonId }),
    },
    { label: 'Stylist Waste Reports' },
  ]
  const dispatch = useDispatch()
  const { isMobile, isTablet } = UseViewSize()
  const models = useAppSelector(selectColorStylistWasteReports)
  const totalProducts = useAppSelector(selectColorStylistReports)
  const currentSelectedUnit: Units = useAppSelector(selectedUnit)
  const currentlyLoading = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null

  const sumWasteGrams = models ? models.map((model) => model.wasteGrams).reduce((a, b) => Number(a) + Number(b), 0) : 0
  const sumWaste = convertDisplayUnits(sumWasteGrams, 'g', currentSelectedUnit)
  const totalProductGrams =  sumBy(totalProducts, ({totalGrams})=> totalGrams);
  const totalWasteProductGrams =  sumBy(models, ({wasteGrams})=> wasteGrams);
  const totalWastePercentage = totalWastePercentageCalculator(totalWasteProductGrams, totalProductGrams)

  const sumWasteCost = models ? models.map((model) => model.wasteCost).reduce((a, b) => a + b, 0) : 0

  useEffect(() => {
    dispatch(dispatchGetColorStylistReports({ token, salonId, dateStart: sDate, dateEnd: eDate, kind: 'color' }))
  }, [dispatch, token, salonId, sDate, eDate])

  const tableHeaders = [{ label: 'Stylist' }, { label: 'Waste', variant: 'weight' }, { label: 'Waste %' }, { label: 'Waste $' }]
  const tableProperties = [
    { name: 'stylist', variant: 'text', mainText: true, size: isMobile || isTablet ? 'l' : 'xl' },
    currentSelectedUnit === 'unit' ? 
    { name: 'wasteQuantity', variant: 'weight'} : 
    { name: 'wasteGrams', variant: 'weight' },
    { name: 'wastePercentage', variant: 'percentage' },
    { name: 'wasteCost', variant: 'money' },
  ]
  const tableActions = [
    {
      label: 'view all',
      buildUrl: (stylistId: string): string =>
        generatePath(ROUTES.colorStylistWasteProductsReports, {
          salonId,
          stylistId,
        }),
      variant: 'button',
    },
  ]
  const tableFooters = [
    'Total',
    currentSelectedUnit === 'unit' ? "" : sumWaste.toFixed(2) + currentSelectedUnit,
    formatDollars(totalWastePercentage) + '%',
    '$' + formatDollars(sumWasteCost),
  ]
  const onCsvExport = () => {
    const totals: ColorStylistReport | any = {
      stylist: 'total',
      wasteGrams: currentSelectedUnit === 'unit' ? "" : sumWaste.toFixed(2) + currentSelectedUnit,
      wastePercentage: formatDollars(totalWastePercentage) + '%',
      wasteCost: '$' + formatDollars(sumWasteCost),
    }
    const formattedModels: ColorStylistReport[] | any[] = models
      ? models.map((model) => {
        const waste = convertDisplayUnits(model.wasteGrams, 'g', currentSelectedUnit)
        return({
          stylist: model.stylist,
          wasteGrams: `${currentSelectedUnit === 'unit' ? model.wasteQuantity : waste}${' '}${pluralizeNameIfRequired(currentSelectedUnit, model.wasteQuantity)}`,
          wastePercentage: formatDollars(model.wastePercentage) + '%',
          wasteCost: '$' + formatDollars(model.wasteCost),
        })})
      : []
    const rows: any = formattedModels ? [...formattedModels, totals] : []
    const tableHeadersForCSV = ['Stylist', `Waste (${currentSelectedUnit})`, 'Waste %', 'Waste $']
    buildCsv('color stylist waste list report', tableHeadersForCSV, rows)
  }

  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Color Reports" breadcrumbs={breadcrumbs} />}
      content={
        <>
          <ColorReportsTabs selectedTab={selectedTab} onCsvExport={onCsvExport} />
          <ColorStylistReportsTabs selectedTab={COLOR_STYLIST_PRODUCTS_DETAILS_REPORTS_TABS.wasteProducts} />
          <Box h="24px" />
          <ReportSectionHeader label="COLORS" bgHex={COLORS.lavender_100} />
          <Box h="24px" />
          {!currentlyLoading && !!models && models.length > 0 && (
            <TableContainer>
              <Table>
                <ReportTableHeaders headers={tableHeaders} actions={tableActions} />
                <ReportTableContent properties={tableProperties} models={models} actions={tableActions} urlProperty={'stylistId'} />
                <ReportTableFooters footers={tableFooters} actions={tableActions} />
              </Table>
            </TableContainer>
          )}
          {!currentlyLoading && !!models && models.length === 0 && (
            <EmptyBox content="No colors were wasted in this time period" />
          )}
          <Box h="24px" />
          {(!models || currentlyLoading) && <Loading />}
        </>
      }
    />
  )
}
