/////////////// slice.ts
//
//

// todos
// 1: add analytic to RootState located at src/store.ts
// 2: ensure model has 'id', change it to unique property if necessary

// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {assign} from 'lodash'
import {DateInterval, DateRangeType} from '../../mini-lib/dates-and-times/constants'

import {RootState} from '../../store'
import {Analytics} from './interfaces'
import {convertDateRangeTypeToDates} from '../../mini-lib/dates-and-times/utils'
import {buildDateYYYYMMDD} from '../../core/dates'


// state
//
interface AnalyticState {
  dateRangeType: DateRangeType
  homeCustomStartDate: Date | null
  homeCustomEndDate: Date | null
  analyticsByStatType: { [key: string]: Analytics } | null
}

const initialState: AnalyticState = {
  dateRangeType: 'month',
  homeCustomStartDate: null,
  homeCustomEndDate: null,
  analyticsByStatType: null,
}

// reducer
//
export const AnalyticSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    reduceGetOwnerAnalytics: (
      state,
      action: PayloadAction<{ dateRangeType: string; statType: string; analytics: any }>,
    ) => {
      const key = `${action.payload.dateRangeType}-${action.payload.statType}`
      state.analyticsByStatType = assign({}, state.analyticsByStatType, {
        [key]: action.payload.analytics,
      })
    },
    reduceSetDateRangeType: (state, action: PayloadAction<DateRangeType>) => {
      state.dateRangeType = action.payload
    },
    reduceHomeCustomStartDate: (state, action: PayloadAction<Date>) => {
      state.homeCustomStartDate = action.payload
    },

    reduceHomeCustomEndDate: (state, action: PayloadAction<Date>) => {
      state.homeCustomEndDate = action.payload
    },

  },
})

// actions
//
export const { reduceGetOwnerAnalytics, reduceSetDateRangeType, reduceHomeCustomEndDate, reduceHomeCustomStartDate } = AnalyticSlice.actions

// selectors
//
export const selectAnalyticsForStatType = (
  state: RootState,
  dateRangeType: DateRangeType,
  statType: string,
): Analytics | null => {
  const key = `${dateRangeType}-${statType}`
  const analytics: any | null = state.analytics.analyticsByStatType && state.analytics.analyticsByStatType[key]
  return analytics ? analytics : null
}
export const selectDateRangeData = (
  state: RootState,
): {
  dateRangeType: DateRangeType
  start: Date
  end: Date
  startString: string
  endString: string
  dataInterval: DateInterval
} => {
  const { start, end, dataInterval } = convertDateRangeTypeToDates(state.analytics.dateRangeType)
  const dateRangeType = state.analytics.dateRangeType
  const homeCustomStartDate = state.analytics.homeCustomStartDate
  const homeCustomEndDate = state.analytics.homeCustomEndDate
  if (dateRangeType === 'custom' && homeCustomStartDate && homeCustomEndDate) {
    const customDateRangeData: any = {
      dateRangeType: dateRangeType,
      start: homeCustomStartDate,
      end: homeCustomEndDate,
      dataInterval: 'date',
      startString: buildDateYYYYMMDD(homeCustomStartDate),
      endString: buildDateYYYYMMDD(homeCustomEndDate),
    }
    return customDateRangeData
  }
  return {
    dateRangeType: dateRangeType,
    start,
    end,
    dataInterval,
    startString: buildDateYYYYMMDD(start),
    endString: buildDateYYYYMMDD(end),
  }
}

// export
//
export default AnalyticSlice.reducer
