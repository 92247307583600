import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { OwnerMetricOptions } from '../../../data/analytics/interfaces'
import { selectAnalyticsForStatType, selectDateRangeData } from '../../../data/analytics/slice'
import { useAppSelector } from '../../../hooks'
import { ChartData } from '../../../mini-lib/charts/interfaces'
import { getLineColor } from '../../../mini-lib/charts/utils'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { addNumberSuffix } from '../../../mini-lib/utils/basic'
import { UseViewSize } from '../../../core/UseViewSize'

export const HomeRevenueGraph = () => {
  const { isMobile, isTablet } = UseViewSize()
  const { dateRangeType } = useAppSelector(selectDateRangeData)
  const revenueAnalytics = useAppSelector((state) =>
    selectAnalyticsForStatType(state, dateRangeType, OwnerMetricOptions.totalRevenueProfit),
  )
  const chartData: ChartData[] | null = revenueAnalytics?.totalRevenueProfit ?? null
  const dataKeys = ['revenue', 'cost']

  return (
    <>
      <Text variant="title1">Color Revenue</Text>
      <Box h="12px" />
      <Box h="250px" w={isMobile || isTablet ? '100%' : '500px'} maxW="100%">
        {chartData && chartData.length > 0 && (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={chartData}>
              <XAxis dataKey="date" />
              {dateRangeType === 'all' ? (
                <YAxis
                  tickFormatter={(x) => {
                    const formattedX = addNumberSuffix(x)
                    return formattedX ? formattedX.toLocaleString() : '0'
                  }}
                />
              ) : (
                <YAxis tickFormatter={(x) => `$${x.toLocaleString()}`} />
              )}
              <Tooltip />
              {dataKeys.map((key, index) => {
                return <Area key={key} type="monotone" dataKey={key} stroke={getLineColor(1)} fill={getLineColor(1)} />
              })}
            </AreaChart>
          </ResponsiveContainer>
        )}
        {chartData && chartData.length === 0 && (
          <EmptyBox
            title="You have no revenue for this time period yet!"
            content="Create some sessions to see metrics"
          />
        )}
        {!chartData && <Loading />}
      </Box>
    </>
  )
}
