import { listTimeZones } from 'timezone-support'

import React from 'react'
import Select from 'react-select'
import { selectTheme } from '../../theme'
import { Box } from '@chakra-ui/react'
import { find } from 'lodash'

export interface DropdownOption {
  label: string
  value: string
}

// this wraps the react select lib in a way that we commonly use
export const TimezoneDropdown = (props: {
  version?: 1
  theme?: 'lavender' | 'peach' | 'linen' | 'midnight' | 'skylight'
  value: string | null // this will be the option.value
  onChange: (option: DropdownOption) => void
  w?: string
  isClearable?: boolean
  isDisabled?: boolean
}) => {
  const { version = 1, theme, value, onChange, w = '100%', isClearable = true, isDisabled = false } = props

  const componentOverrides = value
    ? {
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }
    : { IndicatorSeparator: () => null }
  if (![1].includes(version)) {
    console.warn('the TimezoneDropdown component version you have selected does not exist')
  }
  const timezones = listTimeZones()
  const options: DropdownOption[] = timezones.map((timezone) => ({ value: timezone, label: timezone }))
  const selectedOption = value ? find(options, (option) => option.value === value) : null
  return (
    <>
      {version === 1 && (
        <Box w={w} maxW="100%">
          <Select
            components={componentOverrides}
            isDisabled={isDisabled}
            value={selectedOption}
            styles={selectTheme({theme})}
            placeholder="select a timezone"
            options={options}
            onChange={(option: any) => {
              if (option) {
                onChange(option)
              } else {
                onChange({ label: '', value: '' })
              }
            }}
            isClearable={isClearable}
          />
        </Box>
      )}
    </>
  )
}
