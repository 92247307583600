import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Circle,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { generatePath, Link } from 'react-router-dom'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import React, { ReactNode, useEffect, useState } from 'react'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useAppSelector } from '../../../hooks'
import { useDispatch } from 'react-redux'
import { dispatchAddMasterProducts, dispatchListMasterProducts } from '../../../data/master-products/api'
import { reduceSearchText, selectMasterProductsForLine, selectSearchText, } from '../../../data/master-products/slice'
import { Loading } from '../../../mini-lib/loading/Loading'
import { selectLoadingState } from '../../../core/loading/slice'
import { LOADING_MASTER_PRODUCTS } from '../../../data/master-products/constants'
import { SearchFilter } from '../../../mini-lib/filters/SearchFilter'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { HelpStepperV2 } from '../../start-guide-v2/HelpStepperV2'
import { HelpModal } from '../../start-guide/common-components/HelpModal'
import { HelpPopover } from '../../start-guide/common-components/HelpPopover'
import { HELP_DRAWER_STATES, HelpStepCompleteDrawer } from '../../start-guide/common-components/HelpStepCompleteDrawer'
import {
  reduceSetCurrentStepCode,
  reduceSetCurrentSubStepIndex,
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectStepCompleteDrawerState,
  selectStepsCompleted,
} from '../../../data/start-guide/slice'
import { UseQueryParams } from "../../../mini-lib/utils/basic";
import { UseResetStartGuideState } from "../../start-guide/common-components/UseResetStartGuideState";
import {
  selectAllExtensionLines,
  selectAllExtensionLinesByVendor,
  selectExtensionLineNamesByVendor,
  selectExtensionListForLine,
  selectMasterProductsExtensionLineNamesAndExtensionVendorNamesFilteredBySearchText
} from "../../../data/extensions/slice";
import {
  dispatchDeleteExtensions,
  dispatchListAllExtensionLines,
  dispatchListExtensions
} from "../../../data/extensions/api";
import { MasterProduct } from "../../../data/master-products/interfaces";
import { LineNamesByVendor, LineOption, LinesByVendor } from "../../../data/products/interfaces";
import { intersection, keys, orderBy } from "lodash";
import { UseViewSize } from "../../../core/UseViewSize";
import { COLORS } from "../../../mini-lib/theme/colors";
import { ReleaseLaraColors, ReleaseUnitsSupport } from "../../../mini-lib/flags/Release";
import { buildMasterProductsLoadingName, getProductForMasterProduct } from "../../../data/master-products/utils";
import { dispatchUpdateUserChecklistItem } from "../../../data/start-guide/api";
import { MaterialIcon } from "../../../mini-lib/icons/MaterialIcon";
import { buttonStyles } from "../../../theme";
import { ConfirmPopover } from "../../../mini-lib/confirm-popover/ConfirmPopover";
import { LOADING_EXTENSIONS } from "../../../data/extensions/constants";
import { Extension } from '../../../data/extensions/interfaces'
import { ROUTES } from "../../../appRoutes";

export const AddExtensionsV2Page = ( props: { showBreadCrumbs?: boolean } ) => {
  const { showBreadCrumbs = true } = props
  const dispatch = useDispatch()
  UseResetStartGuideState()
  const releaseLaraColors = ReleaseLaraColors()
  const queryParams: any = UseQueryParams()
  const guideParam = queryParams.get('guide')
  const isGuide = !!guideParam
  const {
    salonId,
    user: { token },
  } = UseBaseApiParams()
  const breadcrumbs = showBreadCrumbs ? [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Extensions', url: generatePath(ROUTES.extensions, { salonId }) },
    { label: 'Add Extensions' },
  ] : []

  const allExtensionLines = useAppSelector(selectAllExtensionLines)
  const allExtensionLinesByVendor = useAppSelector(selectAllExtensionLinesByVendor)
  const addedExtensionLinesByVendors = useAppSelector(selectExtensionLineNamesByVendor)
  const searchText = useAppSelector(selectSearchText)

  const allExtensionLinesLoaded = !!allExtensionLines
  const loadingSearchText = useAppSelector(( state ) => selectLoadingState(state, searchText))

  const drawerState = useAppSelector(selectStepCompleteDrawerState)
  const minSearchTextLength = 2
  useEffect(() => {
    if (!allExtensionLinesLoaded && token) {
      dispatch(dispatchListAllExtensionLines(token, salonId))
    }
  }, [dispatch, token, allExtensionLinesLoaded, salonId])

  // initial dispatch for first row of master products
  useEffect(() => {
    if (token) {
      dispatch(
        dispatchListMasterProducts({
          token,
          search: 'Adored',
          loadingName: LOADING_MASTER_PRODUCTS,
        }),
      )
    }
  }, [dispatch, token])

  useEffect(() => {
    if (token) {
      dispatch(dispatchListExtensions({ token, salonId, releaseLaraColors }))
    }
  }, [dispatch, token, salonId, releaseLaraColors])

  useEffect(() => {
    if (searchText.length >= minSearchTextLength) {
      dispatch(dispatchListMasterProducts({ token, search: searchText, loadingName: searchText }))
    }
  }, [dispatch, token, searchText])

  const { filteredMasterProducts, filteredLineNames, filteredVendorNames } = useAppSelector(
    selectMasterProductsExtensionLineNamesAndExtensionVendorNamesFilteredBySearchText,
  )
  const previousStepLink = generatePath(ROUTES.sgAddExtensionsIntro, { salonId })
  const nextStepLink = generatePath(ROUTES.sgExtensions, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`

  const stepCodes = [CHECKLIST_CODES.addExtensions, CHECKLIST_CODES.addExtensionsPricing]
  const stepsCompleted = useAppSelector(( state ) => selectStepsCompleted(state, stepCodes)) || 0
  return (
    <>
      <HelpModal title="Let’s Set Up Your Extensions!" guideType={CHECKLIST_CODES.addExtensions}/>
      <HelpStepCompleteDrawer
        title="You have added your first Extension."
        subtitle="Make sure you add all of your salon’s extensions before you continue. After this, we will customize your extension pricing."
        actions={
          <Flex gridGap="12px" borderRadius="15px" bg="white" p="0 12px">
            {drawerState === HELP_DRAWER_STATES.drawer && (
              <Button
                colorScheme="brand.midnight"
                variant="round-ghost-upper"
                onClick={() => dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.button))}
              >
                Continue adding extensions
              </Button>
            )}
            <Link to={generatePath(ROUTES.sgExtensions, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`}>
              <Button
                variant="round"
                colorScheme="brand.midnight"
                onClick={() => {
                  dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.addExtensions))
                  dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.hidden))
                  dispatch(reduceSetCurrentSubStepIndex(0))
                }}
              >
                Next Step
              </Button>
            </Link>
          </Flex>
        }
        guideType={CHECKLIST_CODES.addExtensions}
      />
      <PageLayout
        variant="full"
        top={
          <HelpStepperV2
            title="Add Your Extensions"
            titleIconName="products"
            currentStepNumber={1}
            stepsComplete={stepsCompleted}
            totalSteps={stepCodes.length}
            previousStepLink={previousStepLink}
            nextStepLink={nextStepLink}
            guideType={CHECKLIST_CODES.addExtensions}
          />
        }
        header={
          <PageHeader
            title="Add Extensions"
            breadcrumbs={breadcrumbs}
            actions={
              <>
                {!isGuide && (
                  <Button variant="round" colorScheme="brand.midnight">
                    <Link to={generatePath(ROUTES.extensions, { salonId })}>View My Extensions</Link>
                  </Button>
                )}
              </>
            }
          />
        }
      >
        <Box h="24px"/>
        <HelpPopover
          description="Use the search bar to search for a brand, line, or extension."
          stepIndex={0}
          guideTypes={[CHECKLIST_CODES.addExtensions]}
        >
          <Box>
            <SearchFilter
              width="100%"
              theme="midnight"
              buttonClickToSearch={true}
              minSearchLength={minSearchTextLength}
              placeholder="Search"
              onChange={( v ) => {
                dispatch(reduceSearchText(v))
              }}
              value={searchText}
              onClear={() => dispatch(reduceSearchText(''))}
            />
          </Box>
        </HelpPopover>
        {!loadingSearchText && allExtensionLinesByVendor && addedExtensionLinesByVendors && (
          <>
            <Box h="24px"/>
            <VendorAccordion
              filteredVendorNames={filteredVendorNames}
              filteredLineNames={filteredLineNames}
              filteredMasterProducts={filteredMasterProducts}
              linesByVendor={allExtensionLinesByVendor}
              addedExtensionLinesByVendors={addedExtensionLinesByVendors}
            />
          </>
        )}
        {( !allExtensionLinesByVendor || !addedExtensionLinesByVendors || loadingSearchText ) && (
          <>
            <Box h="48px"/>
            <Loading/>
          </>
        )}
      </PageLayout>
    </>
  )
}

export const VendorAccordion = ( props: {
  filteredVendorNames: string[] | null
  filteredLineNames: string[] | null
  filteredMasterProducts: MasterProduct[] | null
  linesByVendor: LinesByVendor
  addedExtensionLinesByVendors: LineNamesByVendor
} ) => {
  const { filteredVendorNames, filteredLineNames, filteredMasterProducts, linesByVendor, addedExtensionLinesByVendors } = props
  const dispatch = useDispatch()
  const {
    user: { token },
  } = UseBaseApiParams()

  const searchText = useAppSelector(selectSearchText)
  const loadingMps = useAppSelector(( state ) => selectLoadingState(state, LOADING_MASTER_PRODUCTS))
  const loadingExtensions = useAppSelector(( state ) => selectLoadingState(state, LOADING_EXTENSIONS))

  const sortedVendorNames = keys(linesByVendor).sort()
  const sortedFilteredVendorNames = searchText
    ? intersection(sortedVendorNames, filteredVendorNames)
    : sortedVendorNames
  const [numRowsToShow, setNumRowsToShow] = useState(10)
  const hasMoreRowsToShow = numRowsToShow < sortedFilteredVendorNames.length
  return (
    <>
      <Accordion allowToggle={true} defaultIndex={0}>
        {sortedFilteredVendorNames.slice(0, numRowsToShow).map(( vendorName, index ) => {
          const vendorAddedLines = addedExtensionLinesByVendors[vendorName]
          const vendorLines = linesByVendor[vendorName]
          return (
            <AccordionItem key={vendorName} borderTop={index === 0 ? '0px solid black' : ''}>
              <AccordionButton
                p={0}
                onClick={() =>
                  dispatch(
                    dispatchListMasterProducts({
                      token,
                      search: vendorName,
                      loadingName: LOADING_MASTER_PRODUCTS,
                    }),
                  )
                }
                _focus={{ boxShadow: 'none' }}
              >
                <Flex w="100%" align="center" justify="space-between">
                  <GenericTile
                    title={vendorName}
                    subtitle={
                      <VendorSubtitle
                        addedLinesLength={vendorAddedLines?.length || 0}
                        vendorLinesLength={vendorLines.length}
                        loading={!loadingExtensions}
                      />
                    }
                  />
                  <AccordionIcon/>
                </Flex>
              </AccordionButton>
              <AccordionPanel p={0}>
                {loadingMps && (
                  <AccordionItem p={'24px'}>
                    <Loading/>
                  </AccordionItem>
                )}
                {!loadingMps && (
                  <LineAccordion
                    filteredLineNames={filteredLineNames}
                    filteredMasterProducts={filteredMasterProducts}
                    lines={vendorLines}
                  />
                )}
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
      <Box h="24px"/>
      {hasMoreRowsToShow && (
        <Flex justify="center">
          <Button variant="round-ghost-lower" onClick={() => setNumRowsToShow(numRowsToShow + 10)}>
            View More
          </Button>
        </Flex>
      )}
    </>
  )
}

const VendorSubtitle = ( props: { loading: boolean; vendorLinesLength: number; addedLinesLength: number } ) => {
  const { loading, vendorLinesLength, addedLinesLength } = props
  return (
    <>
      {vendorLinesLength} Lines | {loading ? addedLinesLength : <Spinner h="12px" w="12px"/>} Used
    </>
  )
}
export const LineAccordion = ( props: {
  filteredLineNames: string[] | null
  filteredMasterProducts: MasterProduct[] | null
  lines: LineOption[]
} ) => {
  const { lines, filteredLineNames, filteredMasterProducts } = props
  const sortedLines = orderBy(lines, ['lineName'], ['asc'])
  const filteredSortedLineNames = sortedLines.filter(( line ) => filteredLineNames?.includes(line.lineName))
  return (
    <Accordion allowToggle={true}>
      {filteredSortedLineNames.map(( line ) => {
        return <LineAccordionItem key={line.lineId} line={line} filteredMasterProducts={filteredMasterProducts}/>
      })}
    </Accordion>
  )
}

export const LineAccordionItem = ( props: { line: LineOption; filteredMasterProducts: MasterProduct[] | null } ) => {
  const { isMobile } = UseViewSize()
  const { line, filteredMasterProducts } = props

  const mpsInLine: MasterProduct[] | null = useAppSelector(( state ) => selectMasterProductsForLine(state, line.lineName, line.vendorName))
  const filteredMpsInLine =
    mpsInLine && filteredMasterProducts ? intersection(mpsInLine, filteredMasterProducts) : mpsInLine
  const mpsInLineLoaded = !!mpsInLine
  const extensionsInLine: Extension[] | null = useAppSelector(( state ) => selectExtensionListForLine(state, line.lineName))

  const subtitle = mpsInLine ? (
    <>
      {filteredMpsInLine?.length} Items |{' '}
      <span style={{ color: extensionsInLine && extensionsInLine.length > 0 ? COLORS.peach_201 : '' }}>
        {extensionsInLine?.length || 0} Added
      </span>
    </>
  ) : (
    ''
  )

  const hasAllExtensions = extensionsInLine && filteredMpsInLine && extensionsInLine.length === filteredMpsInLine?.length
  const hasSomeExtensions = extensionsInLine && filteredMpsInLine && extensionsInLine.length > 0
  const releaseUnitsSupport = ReleaseUnitsSupport();

  return (
    <AccordionItem key={line.lineId}>
      <AccordionButton p={isMobile ? '0 0 0 12px' : '0 0 0 24px'} _focus={{ boxShadow: 'none' }}>
        <Flex w="100%" align="center" justify="space-between">
          <GenericTile
            title={line.lineName}
            subtitle={subtitle}
            actions={
              <HelpPopover
                description="Add an entire line or expand the line to add individual products from that line."
                stepIndex={1}
                guideTypes={[CHECKLIST_CODES.addExtensions]}
              >
                <Flex wrap="wrap" justify="end" maxW="50%" gridGap={isMobile ? '4px' : ''}>
                  {mpsInLineLoaded && !hasAllExtensions && (
                    <MasterProductsButton
                      variant={hasSomeExtensions ? 'update-line' : 'add-line'}
                      masterProducts={filteredMpsInLine || []}
                      extensions={extensionsInLine}
                    />
                  )}
                  {mpsInLineLoaded && ( hasSomeExtensions || hasAllExtensions ) && (
                    <MasterProductsButton
                      variant={'remove-line'}
                      masterProducts={filteredMpsInLine || []}
                      extensions={extensionsInLine}
                    />
                  )}
                </Flex>
              </HelpPopover>
            }
          />
          <AccordionIcon/>
        </Flex>
      </AccordionButton>
      <AccordionPanel p={0}>
        {mpsInLineLoaded &&
          filteredMpsInLine?.map(( mp ) => {
            const product: Extension | null = extensionsInLine ? getProductForMasterProduct(mp, extensionsInLine) : null
            const staticUnit = 'g or ml'
            const units = releaseUnitsSupport ? mp?.units ?? staticUnit : staticUnit
            return (
              <Box key={mp.id} p={isMobile ? '0 0 0 24px' : '0 0 0 48px'} _hover={{ background: COLORS.hover }}>
                <GenericTile
                  title={mp.type}
                  subtitle={`${mp.size}${units}`}
                  actions={
                    <MasterProductsButton
                      variant={product ? 'remove-extension' : 'add-extension'}
                      masterProducts={[mp]}
                      extensions={product ? [product] : null}
                    />
                  }
                />
              </Box>
            )
          })}
        {!mpsInLineLoaded && <Loading/>}
      </AccordionPanel>
    </AccordionItem>
  )
}

export const GenericTile = ( props: { title: string; subtitle: string | ReactNode; actions?: ReactNode } ) => {
  const { title, subtitle, actions } = props

  return (
    <Flex align="center" justify="space-between" p="12px 0" w="100%">
      <Box>
        <Text textAlign="left" fontWeight="bold">
          {title}
        </Text>
        <Box textAlign="left" color={COLORS.text_secondary}>
          {subtitle}
        </Box>
      </Box>
      {actions}
    </Flex>
  )
}

// note: the compiler complains when a button is on a button so the add line button is a flex box with button styles
export const MasterProductsButton = ( props: {
  variant: 'add-extension' | 'add-line' | 'remove-extension' | 'remove-line' | 'update-line' | string
  masterProducts: MasterProduct[]
  extensions: Extension[] | null
} ) => {
  const dispatch = useDispatch()
  const { masterProducts, extensions, variant } = props
  const { salonId, user } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  const releaseLaraColors = ReleaseLaraColors()
  const loadingName = buildMasterProductsLoadingName(masterProducts)
  const isLoading = useAppSelector(( state ) => selectLoadingState(state, loadingName))
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const queryParams: any = UseQueryParams()
  const isGuide = !!queryParams.get('guide')
  const addExtensionsStep = useAppSelector(( state ) => selectChecklistItem(state, CHECKLIST_CODES.addExtensions))
  const createClicked = ( evt ) => {
    evt.stopPropagation()
    if (stepCompleteDrawerState !== HELP_DRAWER_STATES.button) {
      dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
    }
    if (!addExtensionsStep.completed && isGuide) {
      dispatch(dispatchUpdateUserChecklistItem({ token: user.token, checklistItemCode: CHECKLIST_CODES.addExtensions }))
    }
    dispatch(
      dispatchAddMasterProducts({
        token: user.token,
        salonId,
        masterProductIds: masterProducts.map(( mp ) => mp.id),
        loadingName,
        type: 'extension'
      }),
    )
  }
  const onRemoveClicked = ( evt?: any ) => {
    evt?.stopPropagation()
    dispatch(dispatchDeleteExtensions({ token: user.token, salonId, models: extensions ?? [], loadingName, releaseLaraColors}))
  }
  return (
    <>
      {isLoading && <Loading mr="12px" color={COLORS.peach_201}/>}
      {!isLoading && variant === 'add-extension' && (
        <Circle
          onClick={( evt ) => createClicked(evt)}
          size="32px"
          _hover={{ border: `1px solid ${COLORS.peach_201}` }}
          bg={COLORS.shades_neutral_100}
          cursor="pointer"
        >
          <MaterialIcon name="add" colorhex={COLORS.peach_201}/>
        </Circle>
      )}
      {!isLoading && variant === 'add-line' && (
        <>
          {isMobile ? (
            <Circle
              onClick={( evt ) => createClicked(evt)}
              color={COLORS.peach_201}
              bg={COLORS.shades_neutral_100}
              p="4px"
            >
              <MaterialIcon name="add"/>
            </Circle>
          ) : (
            <Flex
              onClick={( evt ) => createClicked(evt)}
              mr="12px"
              gridGap="6px"
              style={buttonStyles({colorHex: COLORS.peach_201})}
              _hover={{ border: `1px solid ${COLORS.peach_201}` }}
            >
              <MaterialIcon name="add"/> {!isMobile && 'Add Line'}
            </Flex>
          )}
        </>
      )}
      {!isLoading && variant === 'remove-line' && (
        <>
          {isMobile ? (
            <ConfirmPopover
              title="Remove Extension Line?"
              subtitle="Removing this extension line means it will no longer appear in your salon."
              onConfirm={() => onRemoveClicked()}
            >
              <Circle
                onClick={( evt ) => evt.stopPropagation()}
                bg={COLORS.shades_neutral_100}
                p="4px"
                color={COLORS.peach_201}
              >
                <MaterialIcon name="delete_outline"/>
              </Circle>
            </ConfirmPopover>
          ) : (
            <ConfirmPopover
              title="Remove Extension Line?"
              subtitle="Removing this extension line means it will no longer appear in your salon."
              onConfirm={() => onRemoveClicked()}
            >
              <Flex
                onClick={( evt ) => evt.stopPropagation()}
                mr="12px"
                style={buttonStyles({colorHex: COLORS.peach_201})}
                _hover={{ border: `1px solid ${COLORS.peach_201}` }}
              >
                <MaterialIcon name="delete_outline"/> Remove Line
              </Flex>
            </ConfirmPopover>
          )}
        </>
      )}
      {!isLoading && variant === 'update-line' && (
        <>
          {isMobile ? (
            <Circle
              onClick={( evt ) => createClicked(evt)}
              color={COLORS.peach_201}
              bg={COLORS.shades_neutral_100}
              p="4px"
            >
              <MaterialIcon name="sync"/>
            </Circle>
          ) : (
            <Flex
              onClick={( evt ) => createClicked(evt)}
              mr="12px"
              style={buttonStyles({colorHex: COLORS.peach_201})}
              _hover={{ border: `1px solid ${COLORS.peach_201}` }}
            >
              <MaterialIcon name="sync"/> Update Line
            </Flex>
          )}
        </>
      )}
      {!isLoading && variant === 'remove-extension' && (
        <ConfirmPopover
          title="Remove Extension?"
          subtitle="Removing this extension means it will no longer appear in your salon."
          onConfirm={() => onRemoveClicked()}
        >
          <Flex align="center">
            <MaterialIcon name="check" size="16px" colorhex={COLORS.text_secondary} mr="6px"/>
            Added
            <Circle
              ml="12px"
              size="32px"
              cursor="pointer"
              _hover={{ border: `1px solid ${COLORS.peach_201}` }}
              bg={COLORS.shades_neutral_100}
            >
              <MaterialIcon name="delete_outline" colorhex={COLORS.peach_201}/>
            </Circle>
          </Flex>
        </ConfirmPopover>
      )}
    </>
  )
}
