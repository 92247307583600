import { Box, Button, Flex, Text } from '@chakra-ui/react'
import pageNotFoundLottie from '../../assets/404-page-not-found-lottie.json'
import accessedDeniedLottie from '../../assets/403-access-denied-lottie.json'
import Lottie from 'react-lottie-player'
import { UserNav } from '../../mini-lib/user-nav/UserNav'
import { ReleaseMultiLocation } from '../../mini-lib/flags/Release'
import React from "react";
import { generatePath, Link } from "react-router-dom";
import { UseBaseApiParams } from "../UseBaseApiParams";
import { ROUTES } from "../../appRoutes";

export const Page402 = () => {
  const releaseMultiSalon = ReleaseMultiLocation()
  const { salonId } = UseBaseApiParams()
  return (
    <Box p='24px'>
      {releaseMultiSalon && <UserNav/>}
      <Flex justify="center" align="center" direction="column" p="40px">
        <Text variant="title1" fontWeight="bold">
          Your subscription is inactive, please purchase a subscription to continue
        </Text>
        <Box h="24px"/>
        <Link to={generatePath(releaseMultiSalon ? ROUTES.salonSettings : ROUTES.settings, { salonId }) + '?tab=subscription'}>
          <Button colorScheme='brand.midnight' variant='round'>
            Manage Subscription
          </Button>
        </Link>
        <Lottie loop animationData={accessedDeniedLottie} play style={{ maxWidth: '100%' }}/>
      </Flex>
    </Box>
  )
}

export const Page403 = () => {
  const releaseMultiSalon = ReleaseMultiLocation()
  return (
    <Box p='24px'>
      {releaseMultiSalon && <UserNav/>}
      <Flex justify="center" align="center" direction="column" p="40px">
        <Text variant="title1" fontWeight="bold">
          you do not have access to this page
        </Text>
        <Box h="24px"/>
        <Text>please contact your salon owner or support</Text>
        <Lottie loop animationData={accessedDeniedLottie} play style={{ maxWidth: '100%' }}/>
      </Flex>
    </Box>
  )
}

export const Page404 = () => {
  const releaseMultiSalon = ReleaseMultiLocation()
  return (
    <>
      {releaseMultiSalon && <UserNav/>}
      <Flex justify="center" align="center" direction="column" p="40px">
        <Text variant="title1" fontWeight="bold">
          the page you were looking for was not found
        </Text>
        <Box h="24px"/>
        <Text>please contact your salon owner or support</Text>
        <Lottie loop animationData={pageNotFoundLottie} play style={{ maxWidth: '100%' }}/>
      </Flex>
    </>
  )
}
