import { Analytics, IntervalOptions } from '../../data/analytics/interfaces'
import { ChartData } from './interfaces'
import { camelCase, keys } from 'lodash'

export const getLineColor = (index: number): string => {
  const options = [
    '#9C9EEF',
    '#B8BAF5',
    '#9FA2F5',
    '#888BE2',
    '#6F72C9',
    '#d9534f',
    '#29b6f6',
    '#fbc02d',
    '#388e3c',

    '#e57373',
    '#0d47a1',
    '#c4b000',
    '#13bd6a',

    '#d32f2f',
    '#236ab9',
    '#b19f00',
    '#66cc91',

    '#f44336',
    '#1976d2',
    '#766a00',
    '#5cb85c',

    '#D4D5F900',
  ]
  return options[index % options.length]
}

export const mapAnalyticsToChartData = (analytics: Analytics, interval: string, statName?: string): ChartData[] => {
  let data: any[] = []
  if (statName) {
    const camelCaseStatName = camelCase(statName) // needs to be camel case to access the mapped data
    data = analytics[camelCaseStatName]
  } else {
    data = analytics
  }
  const chartData: ChartData[] = []
  const unwantedKeys = ['year', 'month', 'date', 'id']
  data?.forEach((d) => {
    const chartItem = { xAxisLabel: buildKeyForDateRangeAndInterval(d, interval) }
    const keysToAdd = keys(d).filter((key) => !unwantedKeys.includes(key))

    keysToAdd.forEach((key) => {
      const normalizedData = typeof d[key] === 'number' ? Math.round(d[key] * 100) / 100 : d[key]
      chartItem[key] = normalizedData
    })
    chartData.push(chartItem)
  })
  return chartData
}

export const buildKeyForDateRangeAndInterval = (datum: any, interval: string): string => {
  if (interval === IntervalOptions.year) {
    return `${datum.year}`
  }
  if (interval === IntervalOptions.month) {
    return `${datum.year}-${datum.month}`
  }
  if (interval === IntervalOptions.date) {
    return `${datum.date}`
  }
  return ''
}
