import { Flex, Text } from '@chakra-ui/react'
import { VARIANTS } from '../../mini-lib/theme/variants'
import { Link } from "react-router-dom";
import React from "react";

export const ReportSectionHeader = ( props: {
  label: string
  bgHex: string
  backButtonUrl?: string
  actions?: any
} ) => {
  const { label, actions, backButtonUrl, bgHex } = props
  return (
    <Flex width="100%" align="center" p="20px" borderRadius="15px" bg={bgHex} maxH="50px" justify="space-between">
      <Text variant={VARIANTS.text.caption1}>{label}</Text>
      {actions}
      {backButtonUrl &&
          <Link to={backButtonUrl}>
              <Text cursor='pointer' variant={VARIANTS.text.caption1}>Back</Text>
          </Link>
      }
    </Flex>
  )
}
