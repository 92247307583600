// to find new icon names check out the material icon docs https://fonts.google.com/icons?selected=Material+Icons
import React from 'react'
import { Box } from '@chakra-ui/react'
import Lottie from 'react-lottie-player'
import syncedCheckmarkLottie from '../../assets/syncedCheckmarkLottie.json'
import { useAppSelector } from '../../hooks'
import { selectLoadingState } from '../../core/loading/slice'

export const SavedStateIcon = (props: { loadingName: string }) => {
  const { loadingName } = props
  const loadingState = useAppSelector((state) => selectLoadingState(state, loadingName))
  return (
    <Box h="24px">
      {loadingState && (
        <Lottie style={{ height: '24px', width: '24px' }} loop animationData={syncedCheckmarkLottie} play />
      )}
    </Box>
  )
}
