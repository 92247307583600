import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGTeamIntroPage = () => {
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])
  const stepCodes = [CHECKLIST_CODES.addStylist, CHECKLIST_CODES.addClient]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="stylists"
          title="INVITE YOUR TEAM"
          description={descriptionText}
          video={VIDEO_MAP.addStylist}
          actions={
            <SGActionButtons
              action={{
                label: 'Invite Your team',
                url: generatePath(ROUTES.sgAddTeam, { salonId }) + `?guide=${CHECKLIST_CODES.addStylist}`,
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={stepCodes.length} stepsComplete={stepsCompleted || 0} />}
          imgPath={require('../../assets/start-guide/add-team.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `It is easy to add your team members to SalonScale. Simply enter their email address or phone number, and your team will be prompted to create their account and download the app from the App Store or GooglePlay store.`
