import { ConfirmModal } from '../../../mini-lib/confirm-modal/ConfirmModal'
import { Box, Button, Flex, Radio, RadioGroup, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { dispatchListProducts, dispatchResetPricing } from '../../../data/products/api'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useAppSelector } from '../../../hooks'
import { selectProductList } from '../../../data/products/slice'
import { RESETTING_PRICING } from '../../../data/master-products/constants'
import { selectLoadingState } from '../../../core/loading/slice'
import { Loading } from '../../../mini-lib/loading/Loading'
import { ReleaseLaraColors } from '../../../mini-lib/flags/Release'
import { Gap } from '../../../mini-lib/gap/Gap'

export const ResetPricingButton = () => {
  const [showModal, setShowModal] = useState(false)
  const resettingPricing = useAppSelector((state) => selectLoadingState(state, RESETTING_PRICING))
  return (
    <>
      <Box onClick={() => setShowModal(true)}>
        {resettingPricing ? <Loading/> : 'Manage Colors' }
      </Box>
      {showModal && <ResetPricingModal showModal={showModal} setShowModal={setShowModal}/>}
    </>
  )
}

export const ResetPricingModal = ( props: { showModal: boolean; setShowModal: ( updated: boolean ) => void } ) => {
  const { showModal, setShowModal } = props
  const [onlyResetAutoPricedProducts, setOnlyResetAutoPricedProducts] = useState(false)
  const [shouldArchiveProducts, setShouldArchiveProducts] = useState(true)
  const dispatch = useDispatch()
  const products = useAppSelector(selectProductList)
  const productsLoaded = !!products

  const {
    salonId,
    user: { token },
  } = UseBaseApiParams()

  const releaseLaraColors = ReleaseLaraColors()

  useEffect(() => {
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, releaseLaraColors])

  const onConfirm = () => {
    dispatch(dispatchResetPricing({token, salonId, onlyAutoPriced: onlyResetAutoPricedProducts, shouldArchive: shouldArchiveProducts}))
    setShowModal(false)
  }
  const confirmText = buildConfirmText(shouldArchiveProducts, onlyResetAutoPricedProducts)
  return (
    <ConfirmModal
      showModal={showModal}
      title={
        <Flex gridGap="12px" align="center">
          <Text variant="title1">Reset Colors</Text>
        </Flex>
      }
      subtitle={
        <Flex direction='column' align='start' fontSize='14px'>

          <Gap/>
          <Text fontWeight='bold' >Select all colors or just the colors with quick start pricing?</Text>
          <RadioGroup
            value={onlyResetAutoPricedProducts ? 'yes' : 'no'}
            onChange={(selectedValue: 'yes' | 'no') => {
              setOnlyResetAutoPricedProducts(selectedValue === 'yes')
            }}
          >
          <Flex direction='column' align='start'>
            <Radio colorScheme="brand.midnight" value={'yes'} >
              <Text fontSize='14px'>select only quick start colors</Text>
            </Radio>
            <Radio colorScheme="brand.midnight" value={'no'}>
              <Text fontSize='14px'>select all colors</Text>
            </Radio>
          </Flex>
          </RadioGroup>

          <Gap/>

          <Text fontWeight='bold' fontSize='14px'>Delete or reset selected colors?</Text>

          <RadioGroup
            value={shouldArchiveProducts ? 'yes' : 'no'}
            onChange={(selectedValue: 'yes' | 'no') => {
              setShouldArchiveProducts(selectedValue === 'yes')
            }}
          >
            <Flex direction='column' align='start'>
              <Radio colorScheme="brand.midnight" value={'yes'}>
                <Text fontSize='14px'>delete selected colors</Text>
              </Radio>
              <Radio colorScheme="brand.midnight" value={'no'}>
                <Text fontSize='14px'>reset pricing for selected colors</Text>
              </Radio>
            </Flex>
          </RadioGroup>

        </Flex>
      }
      onConfirm={onConfirm}
      confirmOverride={
        <Button
          variant="round"
          colorScheme="brand.midnight"
          onClick={() => {
            onConfirm()
          }}
        >
          {!productsLoaded ? (
            <Flex gridGap="8px" align="center">
              <Loading size="20px" variant="clear" colorhex="white" /> loading colors
            </Flex>
          ) : (
            <Text>{confirmText}</Text>
          )}
        </Button>
      }
      onCancel={() => setShowModal(false)}
      theme="midnight"
    />
  )
}

const buildConfirmText = (shouldArchiveProducts: boolean, onlyResetAutoPricedProducts: boolean): string => {
  if (shouldArchiveProducts && onlyResetAutoPricedProducts) {
    return 'Delete My Quick Start Colors'
  }
  if (shouldArchiveProducts && !onlyResetAutoPricedProducts) {
    return 'Delete All My Colors'
  }
  if (!shouldArchiveProducts && onlyResetAutoPricedProducts) {
    return 'Reset My Quick Start Colors'
  }
  if (!shouldArchiveProducts && !onlyResetAutoPricedProducts) {
    return 'Reset All My Color Prices'
  }
  return 'Reset Colors'
}