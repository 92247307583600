import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, has, keyBy, orderBy, values } from 'lodash'
import { ModelPagination } from '../../core/pagination'
import { RootState } from '../../store'
import { mapUsedBrandToUsedProducts } from './mappers'
import { APIOrderCreateItem, OrderLara, OrderProduct } from './interfaces'
import { LaraPagination } from "../../mini-lib/lara/lara-utils";

//State

interface OrderState {
  pagination: ModelPagination | null
  searchPagination: ModelPagination | null
  usedProductsByBrandName: { [key: string]: OrderProduct[] } | null
  selectedBrandNames: string[] | null
  selectedProducts: OrderProduct[] | null
  orderBrands: OrderProduct[] | null
  orderQuantitiesByProductId: { [key: string]: APIOrderCreateItem }
  ordersLaraById: { [key: string]: OrderLara } | null
  ordersLaraPagination: LaraPagination | null
}

const initialState: OrderState = {
  pagination: null,
  searchPagination: null,
  usedProductsByBrandName: null,
  selectedBrandNames: null,
  selectedProducts: null,
  orderBrands: null,
  orderQuantitiesByProductId: {},
  ordersLaraById: null,
  ordersLaraPagination: null
}

// reducer
//

export const OrderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {

    reduceListOrderBrands: (state, action: PayloadAction<OrderProduct[]>) => {
      state.orderBrands = action.payload
      state.usedProductsByBrandName = mapUsedBrandToUsedProducts(action.payload)
    },

    reduceSelectedBrands: (state, action: PayloadAction<string[]>) => {
      state.selectedBrandNames = action.payload
    },

    reduceSetProductsOnOrder: (state, action: PayloadAction<OrderProduct[]>) => {
      state.selectedProducts = action.payload
    },
    reduceAddProductsToOrder: (state, action: PayloadAction<OrderProduct[]>) => {
      if (state.selectedProducts) {
        const stateById = keyBy(state.selectedProducts, 'id')
        const payloadById = keyBy(action.payload, 'id')
        const merged = assign({}, stateById, payloadById)
        // merged ordered product list with adjusted onHand value
        // if onHand value is in negative it should be converted to zero
        state.selectedProducts = values(merged).map((product) => ({ ...product, onHand: product.onHand < 0 ? 0 : product.onHand }))
      } else {
        state.selectedProducts = action.payload.map((product) => ({ ...product, onHand: product.onHand < 0 ? 0 : product.onHand }))
      }
    },
    reduceRemoveProductFromOrder: (state, action: PayloadAction<OrderProduct>) => {
      if (state.selectedProducts) {
        state.selectedProducts = state.selectedProducts.filter((product) => product.id !== action.payload.id)
      }
    },
    reduceSetSelectedOrderQuantitiesByProductId: (
      state,
      action: PayloadAction<{ [key: string]: APIOrderCreateItem }>,
    ) => {
      state.orderQuantitiesByProductId = action.payload
    },
    reduceAddSelectedOrderQuantitiesByProductId: (
      state,
      action: PayloadAction<{productId: number, quantityToAdd: number, unitCost: number}[]>,
    ) => {
      const updatedQuantities = assign({}, state.orderQuantitiesByProductId)
      action.payload.forEach(toAdd => {
        if (has(updatedQuantities, toAdd.productId)) {
          const existingOrderItem = updatedQuantities[toAdd.productId]
          const updatedQuantity = existingOrderItem.order_item_quantity + toAdd.quantityToAdd
          updatedQuantities[toAdd.productId] = assign({}, existingOrderItem, {order_item_quantity: updatedQuantity})
        } else {
          updatedQuantities[toAdd.productId] = { estimated_unit_cost:  toAdd.unitCost, order_item_quantity: toAdd.quantityToAdd, product_id: toAdd.productId}
        }
      })
      state.orderQuantitiesByProductId = assign({}, state.orderQuantitiesByProductId, updatedQuantities)
    },
    reduceSetSingleOrderQuantityByProductId: (state, action: PayloadAction<APIOrderCreateItem>) => {
      state.orderQuantitiesByProductId[action.payload.product_id] = action.payload
    },
    reduceListOrdersLara: (state, action: PayloadAction<OrderLara[]>) => {
      state.ordersLaraById = assign({}, state.ordersLaraById, keyBy(action.payload, 'id'))
    },
    reduceOrderLara: (state, action: PayloadAction<OrderLara>) => {
      state.ordersLaraById = assign({}, state.ordersLaraById, { [action.payload.id]: action.payload })
    },
    reduceListOrdersPagination: (state, action: PayloadAction<LaraPagination>) => {
      state.ordersLaraPagination = action.payload
    },
  },
})

// actions
//

export const {
  reduceListOrderBrands,
  reduceSetProductsOnOrder,
  reduceAddProductsToOrder,
  reduceSetSelectedOrderQuantitiesByProductId,
  reduceSetSingleOrderQuantityByProductId,
  reduceRemoveProductFromOrder,
  reduceListOrdersLara,
  reduceListOrdersPagination,
  reduceOrderLara,
  reduceAddSelectedOrderQuantitiesByProductId,
} = OrderSlice.actions

// selector
//

export const selectOrdersLara = (state: RootState): OrderLara[] | null => {
  const property1Sorter = (order) => order?.id
  const property2Sorter = (order) => order?.orderNumber?.toLowerCase()
  return state.orders.ordersLaraById
    ? orderBy(values(state.orders.ordersLaraById), [property1Sorter, property2Sorter], ['desc', 'asc'])
    : null
}

export const selectOrderLaraForId = (state: RootState, orderId: number): OrderLara | null => {
  return state.orders.ordersLaraById
    ? state.orders.ordersLaraById[orderId]
    : null
}

export const selectOrderListByBrand = (state: RootState): { [key: string]: OrderProduct[] } | null => {
  return state.orders.usedProductsByBrandName ? state.orders.usedProductsByBrandName : null
}

export const selectSelectedProducts = (state: RootState): OrderProduct[] | null => {
  return state.orders.selectedProducts ? state.orders.selectedProducts : null
}

export const selectSelectedOrderQuantitiesByProductId = (state: RootState): { [key: string]: APIOrderCreateItem } =>
  state.orders.orderQuantitiesByProductId

export default OrderSlice.reducer
