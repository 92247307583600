import { APISalonUser, SalonUser } from "./interfaces";

export const mapAPISalonUserToSalonUser = (api: APISalonUser): SalonUser => {
  return {
    id: api.id,
    userId: api.user_id,
    fullName: api.full_name,
    firstName: api.first_name,
    lastName: api.last_name,
    email: api.email,
    phone: api.phone,
    userPhotoUrl: api.user_photo_url,
    roleId: api.role_id,
    roleName: api.role_name,
    tierId: api.tier_id,
    tierName: api.tier_name,
    userTierId: api.user_tier_id,
    legacyStylistId: api.legacy_stylist_id
  }
}

export const mapApiSalonUsersToSalonUsers = ( api: APISalonUser[] ): SalonUser[] => {
  return api.map(( user ) => mapAPISalonUserToSalonUser(user))
}
