import { Text } from '@chakra-ui/react'
import { COLORS } from '../theme/colors'
import React, { ReactNode } from 'react'
import { BASE_INPUT_STYLES } from "../../styles";

export const MaterialLabel = (props: {
  label: string | ReactNode
  elementId: string
  isDisabled: boolean
  hasContent: boolean
  themeColor: string
  isFocused: boolean
  allowFocus?: boolean
  leftPadding?: string
}) => {
  const { label, allowFocus = false, isFocused, elementId, isDisabled, hasContent, themeColor, leftPadding = '12px' } = props
  // note: we are setting focussed to true due to some limitations of autocomplete
  // the browser autocomplete does not update the virutal dom state which makes the unfocuessed state overlap with the input values
  const focusOverride = allowFocus ? isFocused : true
  return (
    <Text
      pointerEvents="none"
      as="label" // this makes the text behave as a <label> html component (this allows it to enter the input border
      htmlFor={elementId} // this sets focus on the input element
      textAlign="left"
      pb="6px"
      color={isDisabled ? 'gray.400' : focusOverride ? themeColor : COLORS.placeholder}
      position="absolute"
      left={leftPadding}
      top={focusOverride || hasContent ? '-6px' : '7px'}
      background={focusOverride || hasContent ? 'white' : 'transparent'}
      padding={'0 4px'}
      zIndex={2}
      transition={'top 0.2s, font-size 0.2s, background-color 0.2s'}

      fontSize={focusOverride || hasContent ? BASE_INPUT_STYLES.materialLabelInputHasTextFontSize : BASE_INPUT_STYLES.fontSize}
    >
      {label}
    </Text>
  )
}
