import { buildLaraConfig } from "../../mini-lib/lara/lara-utils";
import { GetServerBaseUrl } from "../../env";
import axios from "axios";
import { Dispatch } from "@reduxjs/toolkit";

export const apiUpdateChargebeeCustomer = ( params: { token: string, userId: number, email: string } ) => {
  const { token, userId, email } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/users/${userId}/chargebee/update-customer`
  const body = { email }

  return axios
    .post(url, body, config)
    .then(() => {
      return
    })
    .catch(( error ) => {
      throw error
    })
}

export const dispatchUpdateChargebeeCustomer = ( params: { token: string, userId: number, email: string } ) => {
  return ( dispatch: Dispatch ) => {
    return apiUpdateChargebeeCustomer(params).then(() => {
    })
  }
}
