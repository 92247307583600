import { Button, Tooltip } from '@chakra-ui/react'
import React, { useState } from 'react'
import { UseViewSize } from '../../core/UseViewSize'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { OrderAddProductsSideSheet } from './OrderAddProductsSheet'
import { OrderAddProductsByInventorySheet } from './OrderAddProductsByInventorySheet'
import { ReleaseInventoryOrders } from '../../mini-lib/flags/Release'

export const OrderAddProductsButton = ({ openModal, isInventoryGuideCompleted }: { openModal: () => void, isInventoryGuideCompleted: boolean }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isMobile } = UseViewSize()
  const params = UseQueryParams()
  const pageType = params.get('pageType')
  const isInventoryOrderPage = pageType === 'inventory'
  const releaseInventoryOrders = ReleaseInventoryOrders()
  const onClick = () => {
    isInventoryOrderPage && !isInventoryGuideCompleted && releaseInventoryOrders ? openModal() : setIsOpen(true)
  }

  const SideSheet = isInventoryOrderPage && releaseInventoryOrders ?
    <OrderAddProductsByInventorySheet isOpen={isOpen} setIsOpen={setIsOpen} /> :
    <OrderAddProductsSideSheet isOpen={isOpen} setIsOpen={setIsOpen} />

  return (
    <>
      {isOpen && SideSheet}
      <Tooltip
        label={
          isInventoryOrderPage && releaseInventoryOrders
            ? 'Create an order that tops up your inventory to meet target set'
            : ''
        }
      >
        <Button colorScheme="brand.midnight" variant="round-outline" onClick={onClick}>
          {isInventoryOrderPage
            ? isMobile
              ? '+ By Stock'
              : 'Add Products by Usage'
            : isMobile
            ? '+ By Usage'
            : 'Add Products by Usage'}
        </Button>
      </Tooltip>
    </>
  )
}
