import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Link,
  Stack,
  Text
} from '@chakra-ui/react'
import { generatePath } from 'react-router-dom'
import { assign, groupBy, keyBy, keys, without } from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { maybePluralize } from '../../core/text'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { dispatchListProducts } from '../../data/products/api'
import { APIOrderCreateItem } from '../../data/orders/interfaces'
import { Product } from '../../data/products/interfaces'
import {
  reduceAddProductsToOrder,
  reduceSetSelectedOrderQuantitiesByProductId,
  selectSelectedProducts
} from '../../data/orders/slice'
import { mapProductsToOrderProducts } from '../../data/orders/mappers'
import { selectProductSnapshotList } from '../../data/products/slice'
import { getProductsForSelectedLinesIds, getProductsWithMaxStockLevel } from '../../data/orders/utils'
import { useAppSelector } from '../../hooks'
import { Loading } from '../../mini-lib/loading/Loading'
import { UseViewSize } from '../../core/UseViewSize'
import { COLORS } from '../../mini-lib/theme/colors'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { ReleaseLaraColors } from "../../mini-lib/flags/Release";
import { ROUTES } from "../../appRoutes";

export const OrderAddProductsByInventorySheet = (props: { isOpen: boolean; setIsOpen: (isOpen: boolean) => void }) => {
  const { isOpen, setIsOpen } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()

  const { isMobile } = UseViewSize()
  const [selectedLineIds, setSelectedLineIds] = useState<string[]>([])
  const productsList: Product[] | null = useAppSelector(selectProductSnapshotList)
  const productsLoaded = !!productsList
  const productsInOrder = useAppSelector(selectSelectedProducts)
  // Add/Remove checked item from list
  const handleSelect = (lineId: string) => {
    if (selectedLineIds.includes(lineId)) {
      const lineIds = selectedLineIds.filter((item) => item !== lineId)
      setSelectedLineIds(lineIds)
    } else {
      const lineIds = [...selectedLineIds, lineId]
      setSelectedLineIds(lineIds)
    }
  }

  const productsWithMaxStockLevel = getProductsWithMaxStockLevel(productsList ?? []);

  const usedProductsByBrandId = groupBy(
    productsWithMaxStockLevel?.filter((e) => e.inventory.quantityOnHand < (e?.inventory?.maxStockLevel ?? 0)),
    'line.id',
  )
  const releaseLaraColors = ReleaseLaraColors()
  useEffect(() => {
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, releaseLaraColors])

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={() => setIsOpen(false)} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <Box h="24px" />
        <Flex flexDirection={isMobile ? "column" : "row"} justify="space-between" align="start" p="24px">
          <Flex flexDirection="column">
            <Text variant="title1" fontWeight="bold">
              Select Brands
            </Text>
            <Text>Select brands to be in order</Text>
          </Flex>
          <Flex width={isMobile ? '100%' : 'center'} marginTop={isMobile ? '1rem' : '0px'} justify="end" gridGap="12px">
            <Button onClick={() => setIsOpen(false)} variant="round-ghost-upper">
              Cancel
            </Button>
            <Button
              disabled={selectedLineIds.length === 0}
              variant="round"
              colorScheme="brand.midnight"
              onClick={() => {
                const products: Product[] = getProductsForSelectedLinesIds(
                  without(selectedLineIds, ''),
                  usedProductsByBrandId,
                )
                // convert the list to order-product type and filter out the products with null or zero target
                let orderProducts = mapProductsToOrderProducts(products, 'automatic').filter((e) => e.target && e.target > 0)
                if (productsInOrder) orderProducts = [...productsInOrder, ...orderProducts];
                dispatch(reduceAddProductsToOrder(orderProducts))
                const orderQuantities: APIOrderCreateItem[] = orderProducts.map((p) => ({
                  product_id: Number(p.id),
                  estimated_unit_cost: p.estWholesalePrice,
                  order_item_quantity: p.totalContainersUsed,
                }))
                const orderQuantitiesByProductId: { [key: string]: APIOrderCreateItem } = assign(
                  {},
                  keyBy(orderQuantities, 'product_id'),
                )

                dispatch(reduceSetSelectedOrderQuantitiesByProductId(orderQuantitiesByProductId))
                setIsOpen(false)
              }}
            >
              {selectedLineIds.length > 0
                ? `Add ${selectedLineIds.length} ${maybePluralize('brand', selectedLineIds.length)}`
                : 'Add brands'}
            </Button>
          </Flex>
        </Flex>
        <Flex p="24px" paddingTop='unset'>
          <Alert status="warning" bg={COLORS.lavender_50} borderRadius="6px" border={`1px solid ${COLORS.lavender_500}`}>
            <Box w='100%'>
              <Flex align="center" justify="space-between" w="100%">
                <Flex align="center" gridGap="8px">
                  <AlertIcon color={COLORS.lavender_500} />
                  <Text fontSize='14px'>Don't see your colors? Only colors with targets will appear here!</Text>
                </Flex>
                <Box color={COLORS.lavender_500}>
                  <Link href={generatePath(ROUTES.lines, { salonId }) + `?guide=${CHECKLIST_CODES.inventorySetTargets}`}>
                    <Button variant='round' bg={COLORS.lavender_500}>Set Targets</Button>
                  </Link>
                </Box>
              </Flex>
            </Box>
          </Alert>
        </Flex>
        <DrawerBody>
          <Box h="20px" />
          {!productsLoaded ? <>
            <Loading />
          </> : <>
            <Flex>
              <Stack direction="column" width="100%">
                {usedProductsByBrandId &&
                  keys(usedProductsByBrandId).map((lineId, index) => {
                    const brandName = usedProductsByBrandId[lineId][0].line.name
                    const notTheLastItem = keys(usedProductsByBrandId).length - 1 !== index
                    return (
                      <Fragment key={lineId}>
                        <Box p="4px" mt="16px !important" mb="16px !important" key={lineId}>
                          <Checkbox
                            colorScheme="brand.lavender"
                            isChecked={selectedLineIds.includes(lineId)}
                            onChange={(e) => {
                              handleSelect(lineId)
                            }}
                          >
                            {brandName}
                          </Checkbox>
                        </Box>
                        {notTheLastItem && <Divider />}
                      </Fragment>
                    )
                  })}
              </Stack>
            </Flex>
          </>}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
