import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { generatePath, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'

import { Box, Table, TableContainer } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { ReportTableContent, ReportTableFooters, ReportTableHeaders } from '../ReportTable'
import {
  reduceSelectedBrand,
  selectColorBrandUnusedProducts,
  selectColorBrandUsedProducts,
  selectColorBrandWasteProducts,
  selectCustomEndDate,
  selectCustomStartDate,
  selectedUnit,
  selectReportsDateRangeType,
  selectSelectedBrand
} from '../../../data/reports-v2/slice'
import {
  dispatchGetColorBrandDetailsReport,
  dispatchGetColorBrandUnusedDetailsReport
} from '../../../data/reports-v2/api'
import { ReportSectionHeader } from '../ReportSectionHeader'
import { COLORS } from '../../../mini-lib/theme/colors'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { REPORTS_LOADING_CONSTANT } from '../../../data/reports-v2/constants'
import { selectLoadingState } from '../../../core/loading/slice'
import { convertDateRangeTypeToDates } from '../../../mini-lib/dates-and-times/utils'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { buildCsv } from '../../../mini-lib/csv/buildCsv'
import { ColorBrandReport } from '../../../data/reports-v2/interfaces'
import { COLOR_BRAND_DETAILS_REPORTS_TABS, ColorLineDetailsReportsTabs } from './ColorLineDetailsReportsTabs'
import { UseViewSize } from '../../../core/UseViewSize'
import { convertDisplayUnits } from '../../../data/sessions/utils'
import { totalWastePercentageCalculator, pluralizeNameIfRequired } from '../../../data/reports-v2/utils'
import { merge, sumBy } from 'lodash'
import { formatDollars } from "../../../mini-lib/units/money";
import { Units } from '../../../data/reports-v2/interfaces'
import { ROUTES } from "../../../appRoutes";

export const AllColorLineWasteProductsReportPage = () => {
  const dispatch = useDispatch()
  const { isMobile, isTablet } = UseViewSize()
  const params: any = useParams()
  const { brand } = params

  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = [
    { label: 'Reports', url: generatePath(ROUTES.colorBrandReports, { salonId }) },
    { label: isMobile ? 'Lines' : 'Color Line Reports', url: generatePath(ROUTES.colorBrandReports, { salonId }) },
    { label: isMobile ? 'Line' : 'Color Line Report' },
  ]
  const models = useAppSelector(selectColorBrandWasteProducts)
  const used = useAppSelector(selectColorBrandUsedProducts)
  const unused = useAppSelector(selectColorBrandUnusedProducts)
  const totalProducts = merge(used, unused)

  const currentlyLoading = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const currentBrand = useAppSelector(selectSelectedBrand)
  const currentSelectedUnit: Units = useAppSelector(selectedUnit)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const sumWasteGrams = models ? models.map((model) => model.wasteGrams).reduce((a, b) => a + b, 0) : 0
  const sumWaste = convertDisplayUnits(sumWasteGrams, 'g', currentSelectedUnit)
  const totalProductGrams =  sumBy(totalProducts, ({totalGrams})=> totalGrams);
  const totalWasteProductGrams =  sumBy(models, ({wasteGrams})=> wasteGrams);
  const totalWastePercentage = totalWastePercentageCalculator(totalWasteProductGrams, totalProductGrams)
  const sumWasteCost = models ? models.map((model) => model.wasteCost).reduce((a, b) => a + b, 0) : 0

  useEffect(() => {
    if (brand !== currentBrand) {
      dispatch(reduceSelectedBrand(brand))
    }
  }, [dispatch, brand, currentBrand])

  useEffect(() => {
    dispatch(dispatchGetColorBrandDetailsReport({ token, salonId, brand: null, dateStart: sDate, dateEnd: eDate }))
    dispatch(dispatchGetColorBrandUnusedDetailsReport({ token, salonId, brand: null, dateStart: sDate, dateEnd: eDate }))
  }, [dispatch, token, brand, salonId, sDate, eDate, currentBrand])

  const tableHeaders = [
    { label: 'Line' },
    { label: 'Color' },
    { label: 'Type' },
    { label: 'Waste', variant: 'weight' },
    { label: 'Waste %' },
    { label: 'Waste $' },
  ]

  const tableProperties = [
    { name: 'line', variant: 'text', size: 's' },
    { name: 'type', variant: 'text', mainText: true, size: isMobile || isTablet ? 's' : 'm' },
    { name: 'category', variant: 'text', size: 'sm' },
    currentSelectedUnit === 'unit' ? 
    { name: 'wasteQuantity', variant: 'weight', size: isMobile || isTablet ? 'xs' : 'sm' } : 
    { name: 'wasteGrams', variant: 'weight', size: isMobile || isTablet ? 'xs' : 'sm' },
    { name: 'wastePercentage', variant: 'percentage' },
    { name: 'wasteCost', variant: 'money' },
  ]
  const tableFooters = [
    'Total',
    '',
    '',
    currentSelectedUnit === 'unit' ? "" : sumWaste.toFixed(2) + currentSelectedUnit,
    totalWastePercentage.toFixed(2) + '%',
    '$' + formatDollars(sumWasteCost),
  ]
  const onCsvExport = () => {
    const totals: ColorBrandReport | any = {
      line: 'total',
      type: '',
      category: '',
      wasteGrams: currentSelectedUnit === 'unit' ? "" : sumWaste.toFixed(2) + currentSelectedUnit,
      wastePercentage: totalWastePercentage.toFixed(2) + '%',
      wasteCost: '$' + formatDollars(sumWasteCost),
    }
    const formattedModels: ColorBrandReport[] | any[] = models
      ? models.map((model) => {
        const waste = convertDisplayUnits(model.wasteGrams, 'g', currentSelectedUnit)
        return {
          line: model.line,
          type: model.type,
          category: model.category,
          wasteGrams: `${currentSelectedUnit === 'unit' ? model.wasteQuantity : waste}${' '}${pluralizeNameIfRequired(currentSelectedUnit, model.wasteQuantity )}`,
          wastePercentage: model.wastePercentage + '%',
          wasteCost: '$' + formatDollars(model.wasteCost),
        }
      })
      : []
    const rows: any = formattedModels ? [...formattedModels, totals] : []

    const tableHeadersForCSV = ['Line', 'Color', 'Type', `Waste (${currentSelectedUnit})`, 'Waste %', 'Waste $']
    buildCsv('color brand waste products report', tableHeadersForCSV, rows)
  }
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Color Reports" breadcrumbs={breadcrumbs} />}
      content={
        <>
          {!isMobile && <Box h="24px" />}
          <ColorLineDetailsReportsTabs
            selectedTab={COLOR_BRAND_DETAILS_REPORTS_TABS.wasteColors}
            onCsvExport={onCsvExport}
          />
          <Box h="24px" />
          <ReportSectionHeader
            label={'Color Waste'}
            bgHex={COLORS.lavender_100}
          />

          {!currentlyLoading && !!models && models.length > 0 && (
            <TableContainer>
              <Table>
                <ReportTableHeaders headers={tableHeaders} />
                <ReportTableContent properties={tableProperties} models={models ?? []} />
                <ReportTableFooters footers={tableFooters} />
              </Table>
            </TableContainer>
          )}
          {!currentlyLoading && !!models && models.length === 0 && (
            <EmptyBox content="No colors were wasted in this time period" />
          )}
          <Box h="24px" />
          {(!models || currentlyLoading) && <Loading />}
        </>
      }
    />
  )
}
