import React, { useState, useEffect } from 'react'
import { NotificationsForm } from './AdminNotificationsForm'
import { NotificationsList } from './AdminNotificationsList'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { dispatchListNotificationForAdmin } from '../../data/notification-admin/api'
import { selectSelectedNotification, reduceSelectNotification } from '../../data/notification-user/slice'
import { dispatchListGlobalRoles } from '../../data/global-roles/api'
import { selectPermissionSettings } from '../../data/settings/slice'
import { selectGolbalRolesList } from '../../data/global-roles/slice'
import { useAppSelector } from '../../hooks'
import { Text } from '@chakra-ui/react'
import { COLORS } from '../../mini-lib/theme/colors'

export const Notifications = () => {
  const {
    user: { token },
  } = UseBaseApiParams()

  const dispatch = useDispatch()
  const permissionSettings = useAppSelector(selectPermissionSettings)
  const selectedNotification = useAppSelector(selectSelectedNotification)
  const roles = useAppSelector(selectGolbalRolesList)
  const [toggle, setToggle] = useState<'FORM' | 'LIST'>('FORM')
  const permissionSettingsLoaded = !!permissionSettings

  useEffect(() => {
    dispatch(dispatchListGlobalRoles(token))
  }, [token, dispatch])

  useEffect(() => {
    if (!permissionSettingsLoaded) {
      dispatch(dispatchListNotificationForAdmin(token))
    }
  }, [dispatch, token, permissionSettingsLoaded])

  useEffect(() => {
    selectedNotification && setToggle('FORM')
  }, [selectedNotification])

  const handleToggle = () => {
    dispatch(reduceSelectNotification(null));
    toggle === 'FORM' ? setToggle('LIST') : setToggle('FORM')
  }

  const renderButtonText = () => {
    if (toggle === 'FORM') {
      return selectedNotification ? 'Back to history' : 'View Notification history'
    } else {
      return 'Back'
    }
  }
  return (
    <>
      <button onClick={handleToggle}>
        <Text color={COLORS.lavender_500}>
          {renderButtonText()}
        </Text>
      </button>
      {toggle === "FORM" ? <NotificationsForm roles={roles} toggleToList={() => setToggle("LIST")} /> : <NotificationsList />}
    </>
  );
};

export default Notifications;
