import { Box, Button, Flex, Text, Tooltip, } from '@chakra-ui/react'
import { sortBy } from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { buildDateYYYYMMDD } from '../../core/dates'
import { selectLoadingState } from '../../core/loading/slice'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseViewSize } from '../../core/UseViewSize'
import { dispatchGetAllClientReportForAddons } from '../../data/report/api'
import { REPORTS_LOADING_CONSTANT } from '../../data/report/constants'
import { ClientReportLara, SessionDetails } from '../../data/report/interfaces'
import {
  reduceListClientSessionDetailReport,
  reduceListClientSessionReport,
  reduceSetCSVData,
  selectClientReportByStylist,
  selectClientSessionDetailReport,
  selectClientSessionReport,
  selectCustomEndDate,
  selectCustomStartDate,
  selectReportsDateRangeType
} from '../../data/report/slice'
import {
  mapClientSessionDetailToCSVForAddon,
  mapClientSessionToCSV,
  mapClientSessionToCSVForAddon
} from '../../data/report/mappers'
import { useAppSelector } from '../../hooks'
import { convertDateRangeTypeToDates } from '../../mini-lib/dates-and-times/utils'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { Loading } from '../../mini-lib/loading/Loading'
import { VARIANTS } from '../../mini-lib/theme/variants'
import { pages } from './AddonReportsPage'
import { TableContent, TableHead, TableTotal } from './Table'

export const ClientReportPage = (props: { page: string; setPage: (page: string) => void }) => {

  const [clientName, setClientName] = useState('')
  const [stylistName, setStylistName] = useState('')
  const [clientId, setClientId] = useState(0)
  const [stylistId, setStylistId] = useState(0)
  const { page, setPage } = props
  useEffect(() => () => setPage(pages.clientList), [setPage])
  return (
    <>
      {page === pages.clientList && (
        <ReportStylistClientTable
          setPage={setPage}
          setClientName={setClientName}
          setStylistName={setStylistName}
          setClientId={setClientId}
          setStylistId={setStylistId}
        />
      )}

      {page === pages.clientSessionList && (
        <ReportStylistClientSessionListTable
          setPage={setPage}
          clientId={clientId}
          clientName={clientName}
          stylistName={stylistName}
          stylistId={stylistId}
        />
      )}

      {page === pages.clientSessionDetail && (
        <ReportClientSessionDetail
          stylistName={stylistName}
          customerName={clientName}
          stylistId={stylistId}
          setPage={setPage}
        />
      )}
    </>
  )
}


const ReportStylistClientTable = (props: {
  setPage: (string) => void
  setClientName: (string) => void
  setStylistName: (string) => void
  setClientId: (number) => void
  setStylistId: (number) => void
}) => {
  const { setPage, setClientName, setStylistName, setClientId, setStylistId } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const listByStylist = useAppSelector(selectClientReportByStylist) ?? {}
  const loadingReports = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const loadingReport = loadingReports ? loadingReports : null

  useEffect(() => {
    dispatch(dispatchGetAllClientReportForAddons({ token, salonId, dateStart: sDate, dateEnd: eDate }))
  }, [dispatch, token, salonId, sDate, eDate])

  return (
    <>
      {!loadingReport &&
        listByStylist &&
        Object.keys(listByStylist).map((stylistId, index) => {
          const clientRow = listByStylist[stylistId][Object.keys(listByStylist[stylistId])[0]]
          const clientid = Object.keys(clientRow)[0]
          const stylistName = clientRow[clientid][0].stylistName
          return (
            <Box key={index}>
              <Flex width="100%" align="center" justify="space-between" p="20px" borderRadius="15px" bg="brand.linen.50">
                <Text variant={VARIANTS.text.caption1}>{stylistName}</Text>
              </Flex>


              <Box h={'25px'} />

              <ReportStylistClientTableContainer
                clientRows={listByStylist[stylistId]}
                setPage={setPage}
                setClientName={setClientName}
                setStylistName={setStylistName}
                setClientId={setClientId}
                setStylistId={setStylistId}
              />
            </Box>
          )
        })}
      {!loadingReport && listByStylist && Object.keys(listByStylist).length === 0 && <EmptyBox />}
      {loadingReport && <Loading />}
    </>
  )
}

export const ReportStylistClientTableContainer = (props: {
  clientRows: { [key: number]: { [key: number]: ClientReportLara[] } }
  setPage: (string) => void
  setClientName: (string) => void
  setStylistName: (string) => void
  setClientId: (string) => void
  setStylistId: (string) => void
}) => {
  const { clientRows, setPage, setClientName, setStylistName, setClientId, setStylistId } = props
  const [numRowsToShow, setNumRowsToShow] = useState(10)
  const sortedClientList = sortBy(clientRows, () => {
    const clientDetails = clientRows[Object.keys(clientRows)[0]]
    const clientName = clientDetails[Object.keys(clientDetails)[0]][0].clientName
    return clientName
  })
  const pagedModels = sortedClientList.slice(0, numRowsToShow)
  const sumRetail = sortedClientList ? sortedClientList.map((model) => Object.keys(model).map((e) => model[e].map((a) => a.retail).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0) : null;
  const hasMore = numRowsToShow < Object.keys(clientRows).length
  const list = [
    { label: 'Client' },
    { label: 'Price', value: sumRetail, additionalProps: { isNumeric: true } }
  ]
  return (
    <>
      <TableHead list={list} />
      {pagedModels.map((clients, index) => (
        <Fragment key={index}>
          <ReportStylistClientTableRow
            clients={clients}
            setPage={setPage}
            setStylistName={setStylistName}
            setClientName={setClientName}
            setStylistId={setStylistId}
            setClientId={setClientId}
          />
        </Fragment>
      ))}
      {hasMore && (
        <Flex justifyContent={'center'}>
          <Button variant="round-ghost-lower" onClick={() => setNumRowsToShow(pagedModels.length + 15)}>
            View More
          </Button>
        </Flex>
      )}
      <TableTotal list={list} />
    </>
  )
}

export const ReportStylistClientTableRow = (props: {
  clients: { [key: number]: ClientReportLara[] }
  setPage: (string) => void
  setClientName: (string) => void
  setStylistName: (string) => void
  setClientId: (string) => void
  setStylistId: (string) => void
}) => {
  const { clients, setPage, setClientName, setStylistName, setStylistId, setClientId } = props
  const dispatch = useDispatch()
  const listByStylist = useAppSelector(selectClientReportByStylist)
  const clientTotal = {
    retail: Object.keys(clients)?.map((p) => clients[p].map((x) => x.retail).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)
  };
  const sessionItems = clients[Object.keys(clients)[0]];
  const sessionItem = sessionItems[0]
  const list = [
    { label: 'Client', value: sessionItems[0].clientName },
    { label: 'Price', value: clientTotal.retail, additionalProps: { isNumeric: true } }
  ]
  return (
    <TableContent
      list={list}
      action={
        <Button
          minWidth={'70px'}
          height={'20px'}
          variant="round-ghost-lower"
          onClick={() => {
            setClientName(sessionItem.clientName)
            setStylistName(sessionItem.stylistName)
            setClientId(sessionItem.clientId)
            setStylistId(sessionItem.stylistId)
            setPage(pages.clientSessionList)
            dispatch(reduceListClientSessionReport({ list: listByStylist?.[sessionItem.stylistId][sessionItem.clientId], csvMapper: mapClientSessionToCSVForAddon }))
          }}
        >
          view all
        </Button>
      }
    />
  )
}

const ReportStylistClientSessionListTable = (props: {
  setPage: (string) => void
  clientId: number
  clientName: string
  stylistName: string
  stylistId: number
}) => {
  const { setPage, clientId, stylistName, stylistId } = props
  const listBySession = useAppSelector(selectClientSessionReport) ?? {}
  const loadingReports = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const loadingReport = loadingReports ? loadingReports : null
  const { isMobile } = UseViewSize()


  const onBackClick = () => {
    setPage(pages.clientList);
  }

  return (
    <>
      {isMobile ? (
        <Flex width="100%" justify="space-between" align="center" p="20px" borderRadius="15px" bg="brand.linen.50">
          <Flex direction="column" width="80%">
            <Tooltip label={stylistName}>
              <Text variant={VARIANTS.text.caption1}>{stylistName}</Text>
            </Tooltip>
          </Flex>

          <Box cursor="pointer" onClick={onBackClick}>
            <Text variant={VARIANTS.text.caption1}>Back</Text>
          </Box>
        </Flex>
      ) : (
        <Flex width="100%" justify="space-between" align="center" p="20px" borderRadius="15px" bg="brand.linen.50">
          <Text variant={VARIANTS.text.caption1}>{stylistName}</Text>
          <Box cursor="pointer" onClick={onBackClick}>
            <Text variant={VARIANTS.text.caption1}>Back</Text>
          </Box>
        </Flex>
      )}

      <Box h={'25px'} />

      {!loadingReport && <ReportStylistClientSessionListTableContainer
        clientRows={listBySession}
        setPage={setPage}
        clientId={clientId}
        stylistId={stylistId}
      />}
      {!loadingReport && listBySession && Object.keys(listBySession).length === 0 && <EmptyBox />}
      {loadingReport && <Loading />}
    </>
  )
}


const ReportStylistClientSessionListTableContainer = (props: {
  clientRows: SessionDetails[]
  setPage: (string) => void
  clientId: number
  stylistId: number
}) => {
  const { clientRows, setPage, clientId, stylistId } = props
  const totalPrice = clientRows?.reduce((a, b) => a + b.retail, 0)
  const list = [
    { label: 'Customer' },
    { label: 'Date', additionalProps: { noTotal: true } },
    { label: 'Price', value: totalPrice, additionalProps: { isNumeric: true } },
  ]
  return (
    <>
      <TableHead list={list} />
      {clientRows.map((row, index) => (
        <Fragment key={index}>
          <ReportStylistClientSessionListTableRow
            sessionDetails={row}
            id={index}
            setPage={setPage}
            clientId={clientId}
            stylistId={stylistId}
          />
        </Fragment>
      ))}
      <TableTotal list={list} />
    </>
  )
}

const ReportStylistClientSessionListTableRow = (props: {
  sessionDetails: SessionDetails
  setPage: (string) => void
  clientId: number
  stylistId: number
  id: number
}) => {
  const dispatch = useDispatch()
  const { sessionDetails: { clientName, createdAt, retail, sessionId }, setPage, clientId, stylistId, id } = props
  const list = [
    { label: 'Customer', value: clientName },
    { label: 'Date', value: buildDateYYYYMMDD(createdAt) },
    { label: 'Price', value: retail, additionalProps: { isNumeric: true } },
  ]
  const ListByStylist = useAppSelector(selectClientReportByStylist) ?? {}
  return (
    <TableContent
      list={list}
      id={id}
      action={
        <Button
          minWidth={'70px'}
          height={'20px'}
          variant="round-ghost-lower"
          onClick={() => {
            setPage(pages.clientSessionDetail)
            dispatch(reduceListClientSessionDetailReport({ list: ListByStylist[stylistId][clientId][sessionId], csvMapper: mapClientSessionDetailToCSVForAddon }))
          }}
        >
          view all
        </Button>
      }
    />
  )
}

//=-----------=

export const ReportClientSessionDetail = (props: {
  stylistName: string
  customerName: string
  stylistId: number
  setPage: (string) => void
}) => {
  const dispatch = useDispatch()
  const { stylistName, customerName, setPage } = props //using this stylid and client id we will make a request to Client Detail Report
  const data = useAppSelector(selectClientSessionDetailReport) ?? []
  const loadingReports = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const { isMobile } = UseViewSize()
  const sumRevenue = data.map((model) => model.retail).reduce((a, b) => a + b, 0)
  const list = [
    { label: 'Title' },
    { label: 'Qty', additionalProps: { noTotal: true, tooltip: 'number of containers' } },
    { label: 'Price', value: sumRevenue, additionalProps: { isNumeric: true } }
  ]
  const listBySession = useAppSelector(selectClientSessionReport) ?? {}
  const onBackClick = () => {
    setPage(pages.clientSessionList);
    dispatch(reduceSetCSVData(mapClientSessionToCSV(listBySession)))
  }
  return (
    <div>
      {isMobile ? (
        <Flex width="100%" justify="space-between" align="center" p="20px" borderRadius="15px" bg="brand.linen.50">
          <Flex direction="column" width="80%">
            <Text variant={VARIANTS.text.caption1}> {customerName}</Text>
            <Tooltip label={stylistName}>
              <Text overflowX="scroll" variant={VARIANTS.text.caption1}>
                Stylist: {stylistName}
              </Text>
            </Tooltip>
          </Flex>

          <Box cursor="pointer" onClick={onBackClick}>
            <Text variant={VARIANTS.text.caption1}>Back</Text>
          </Box>
        </Flex>
      ) : (
        <Flex width="100%" justify="space-between" align="center" p="20px" borderRadius="15px" bg="brand.linen.50">
          <Text variant={VARIANTS.text.caption1}>
            {' '}
            {customerName} - Stylist: {stylistName} - {buildDateYYYYMMDD(data[0].createdAt)}
          </Text>
          <Box cursor="pointer" onClick={onBackClick}>
            <Text variant={VARIANTS.text.caption1}>Back</Text>
          </Box>
        </Flex>
      )}

      <Box h={'25px'}></Box>
      {!loadingReports && data.length > 0 && (
        <>
          <TableHead list={list} />
          {data.map((product, index) => {
            const list = [
              { label: 'Title', value: product.title },
              { label: 'Qty', value: product.quantity, additionalProps: { noTotal: true } },
              { label: 'Price', value: product.retail, additionalProps: { isNumeric: true } }
            ]
            return (<Fragment key={index}>
              <TableContent list={list} action={<></>} />
            </Fragment>)
          })}
          <TableTotal list={list} />
        </>
      )}
      {!loadingReports && data.length === 0 && <EmptyBox />}
      {loadingReports && <Loading />}
    </div>
  )
}
