import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { sortBy } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { buildDateYYYYMMDD } from '../../core/dates'
import { selectLoadingState } from '../../core/loading/slice'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { dispatchGetStylistDetailReportsForAddons, dispatchGetStylistsReportsForAddons } from '../../data/report/api'
import { REPORTS_LOADING_CONSTANT } from '../../data/report/constants'
import { StylistDetailReportAddon, StylistReportAddon } from '../../data/report/interfaces'
import {
  reduceSetCurrentStylist,
  selectCustomEndDate,
  selectCustomStartDate,
  selectReportsDateRangeType,
  selectStylistDetailReportForAddon,
  selectStylistReportForAddon,
} from '../../data/report/slice'

import { useAppSelector } from '../../hooks'
import { convertDateRangeTypeToDates } from '../../mini-lib/dates-and-times/utils'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { Loading } from '../../mini-lib/loading/Loading'
import { VARIANTS } from '../../mini-lib/theme/variants'
import { pages } from './AddonReportsPage'
import { TableHead, TableContent, TableTotal } from './Table'

export const StylistAddonReportPage = (props: { page: string; setPage: (page: string) => void }) => {
  const [stylistName, setstylistName] = useState('')
  const [stylistId, setstylistId] = useState(0)
  const { page, setPage } = props
  if (page === pages.stylistList) {
    return <StylistAddonTable setPage={setPage} setstylistName={setstylistName} setstylistId={setstylistId} />
  }
  if (page === pages.stylistDetail) {
    return <StylistAddonDetail stylistName={stylistName} stylistId={stylistId} setPage={setPage} />
  }

  return <></>
}

export const StylistAddonTable = (props: {
  setPage: (string) => void
  setstylistName: (string) => void
  setstylistId: (number) => void
}) => {
  const { setPage, setstylistName, setstylistId } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const dispatch = useDispatch()

  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const loadingReports = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const stylistsAddonProducts = useAppSelector(selectStylistReportForAddon)

  const sumRevenue = stylistsAddonProducts
    ? stylistsAddonProducts.map((model) => model.retail).reduce((a, b) => a + b, 0)
    : null
  const [numRowsToShow, setNumRowsToShow] = useState(30)

  useEffect(() => {
    dispatch(dispatchGetStylistsReportsForAddons({ token, salonId, dateStart: sDate, dateEnd: eDate }))
  }, [dispatch, token, salonId, sDate, eDate]) // add dateFilter.start and enddate

  const pagedStylistReportProducts = stylistsAddonProducts ? stylistsAddonProducts.slice(0, numRowsToShow) : null
  const loadingReport = loadingReports ? loadingReports : null
  return (
    <>
      <Flex width="100%" align="center" p="20px" borderRadius="15px" bg="brand.linen.50">
        <Text variant={VARIANTS.text.caption1}>STYLISTS</Text>
      </Flex>
      <Box h="25px" />
      {!loadingReport &&
        stylistsAddonProducts &&
        pagedStylistReportProducts &&
        pagedStylistReportProducts.length > 0 &&
        sumRevenue !== null && (
          <StylistAddonTableContainer
            hasMore={numRowsToShow < stylistsAddonProducts.length}
            setNumRowsToShow={setNumRowsToShow}
            pagedStylistReportProducts={pagedStylistReportProducts}
            sumRevenue={sumRevenue}
            setPage={setPage}
            setstylistName={setstylistName}
            setstylistId={setstylistId}
          />
        )}
      {!loadingReport && pagedStylistReportProducts && pagedStylistReportProducts.length === 0 && <EmptyBox />}
      {loadingReport && <Loading />}
    </>
  )
}

export const StylistAddonTableContainer = (props: {
  pagedStylistReportProducts: StylistReportAddon[]
  hasMore: boolean
  setNumRowsToShow: (number) => void
  sumRevenue: number
  setPage: (string) => void
  setstylistName: (string) => void
  setstylistId: (number) => void
}) => {
  const { hasMore, pagedStylistReportProducts, setNumRowsToShow, sumRevenue, setPage, setstylistName, setstylistId } =
    props

  const list = [
    { label: 'Stylist' },
    { label: 'Price', value: sumRevenue, additionalProps: { isNumeric: true } }
  ]
  return (
    <>
      <TableHead list={list} />
      {sortBy(pagedStylistReportProducts, 'stylistName').map((product, index) => (
        <Fragment key={index}>
          <StylistAddonTableRow
            product={product}
            id={index}
            setPage={setPage}
            setstylistName={setstylistName}
            setstylistId={setstylistId}
          />
        </Fragment>
      ))}
      {hasMore && (
        <Flex justifyContent={'center'}>
          <Button
            variant="round-ghost-lower"
            onClick={() => setNumRowsToShow(pagedStylistReportProducts.length + 30)}
          >
            View More
          </Button>
        </Flex>
      )}
      <TableTotal list={list} />
    </>
  )
}

export const StylistAddonTableRow = (props: {
  product: StylistReportAddon
  setPage: (string) => void
  setstylistName: (string) => void
  setstylistId: (number) => void
  id: number
}) => {
  const { product, setPage, setstylistName, setstylistId } = props

  const list = [
    { label: 'Stylist', value: product.stylist },
    { label: 'Price', value: product.retail, additionalProps: { isNumeric: true } }
  ]
  return (
    <TableContent
      list={list}
      action={
        <Button
          minWidth={'70px'}
          height={'20px'}
          variant="round-ghost-lower"
          onClick={() => {
            setstylistName(product.stylist)
            setstylistId(product.stylistId)
            setPage(pages.stylistDetail)
          }}
        >
          view all
        </Button>
      }
    />
  )
}

// ------------STYLIST REPORT DETAIL---------------------

export const StylistAddonDetailTableContainer = (props: {
  hasMore: boolean
  setNumRowsToShow: (number) => void
  stylistAddonProducts: StylistDetailReportAddon[]
  sumRevenue: number | any
}) => {
  const { stylistAddonProducts, hasMore, setNumRowsToShow, sumRevenue } = props


  const list = [
    { label: 'Title' },
    { label: 'Price', value: sumRevenue, additionalProps: { isNumeric: true } }
  ]
  return (
    <>
      <TableHead list={list} />
      {stylistAddonProducts.map((product, index) => (
        <Fragment key={index}>
          <StylistReportDetailTableRow product={product} id={index} />
        </Fragment>
      ))}
      {hasMore && (
        <Flex justifyContent={'center'}>
          <Button
            variant="round-ghost-lower"
            onClick={() => setNumRowsToShow(stylistAddonProducts.length + 30)}
          >
            View More
          </Button>
        </Flex>
      )}
      <TableTotal list={list} />
    </>
  )
}

export const StylistReportDetailTableRow = (props: { product: StylistDetailReportAddon, id: number }) => {
  const { product } = props


  const list = [
    { label: 'Title', value: product.title },
    { label: 'Price', value: product.retail, additionalProps: { isNumeric: true } }
  ]
  return <TableContent list={list} action={<div></div>} />
}

export const StylistAddonDetail = (props: { stylistName: string; stylistId: number; setPage: (string) => void }) => {
  const { stylistName, stylistId, setPage } = props //using this stylistId we will make a request to Stylist Detail Report

  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const dispatch = useDispatch()

  const stylistAddonProducts = useAppSelector(selectStylistDetailReportForAddon)
  const loadingReports = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const [numRowsToShow, setNumRowsToShow] = useState(30)
  const sumRevenue = stylistAddonProducts?.map((model) => model.retail).reduce((a, b) => a + b, 0)

  useEffect(() => {
    dispatch(dispatchGetStylistDetailReportsForAddons({ token, salonId, stylistId, dateStart: sDate, dateEnd: eDate }))
    dispatch(reduceSetCurrentStylist(stylistId))
  }, [dispatch, token, stylistId, salonId, sDate, eDate])

  const pagedStylistReportProducts = stylistAddonProducts ? stylistAddonProducts.slice(0, numRowsToShow) : null

  return (
    <>
      <Flex width="100%" justify="space-between" align="center" p="20px" borderRadius="15px" bg="brand.linen.50">
        <Text variant={VARIANTS.text.caption1}>{stylistName}</Text>
        <Box cursor="pointer" onClick={() => setPage(pages.stylistList)}>
          <Text variant={VARIANTS.text.caption1}>Back</Text>
        </Box>
      </Flex>
      <Box h="25px" />
      {!loadingReports &&
        pagedStylistReportProducts &&
        stylistAddonProducts &&
        sumRevenue !== null &&
        pagedStylistReportProducts.length > 0 && (
          <StylistAddonDetailTableContainer
            hasMore={numRowsToShow < stylistAddonProducts.length}
            setNumRowsToShow={setNumRowsToShow}
            stylistAddonProducts={pagedStylistReportProducts}
            sumRevenue={sumRevenue}
          />
        )}
      {!loadingReports && pagedStylistReportProducts && pagedStylistReportProducts.length === 0 && <EmptyBox />}
      {loadingReports && <Loading />}
    </>
  )
}
