import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { generatePath } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { TaskBar } from './HelpStepperV2'
import { useAppSelector } from '../../hooks'
import { selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { useDispatch } from 'react-redux'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGAddLinesIntroPage = () => {
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()

  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])

  const stepCodes = [CHECKLIST_CODES.addLines, CHECKLIST_CODES.addPricing, CHECKLIST_CODES.autoPricing]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  // normalize to 2 in case they have somehow done auto pricing and also add pricing
  const stepsComplete = stepsCompleted ? (stepsCompleted > 2 ? 2 : stepsCompleted) : 0
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName='products'
          title='ADD YOUR COLOR LINES'
          description='This is where the magic begins! Choose from over 250+ color lines to customize your digital backbar to
                match the products that you use everyday in your salon.'
          video={VIDEO_MAP.addLines}
          actions={
            <SGActionButtons
              action={{
                label: 'Add color lines',
                url: generatePath(ROUTES.sgAddLines, { salonId }) + `?guide=${CHECKLIST_CODES.addLines}`
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} stepsComplete={stepsComplete} totalSteps={2} />}
          imgPath={require('../../assets/start-guide/color-lines.png').default}
        />
        {/*<Box p="12px">*/}
        {/*  <Box h={isMobile ? '48px' : '92px'} />*/}
        {/*  <Flex justify="center" wrap="wrap">*/}
        {/*    <Box w={isMobile ? '100%' : '55%'}>*/}
        {/*      <Flex align="center" gridGap="24px">*/}
        {/*        <SalonScaleIcon size="32px" name="products" />*/}

        {/*        <Text variant={isMobile ? 'largetitlemobile' : 'largetitle'}>ADD YOUR COLOR LINES</Text>*/}
        {/*      </Flex>*/}

        {/*      <Box h="48px" />*/}

        {/*      <Text>*/}
        {/*        This is where the magic begins! Choose from over 250+ color lines to customize your digital backbar to*/}
        {/*        match the products that you use everyday in your salon.*/}
        {/*      </Text>*/}

        {/*      <Box h="48px" />*/}

        {/*      <Text variant="TODO" fontWeight="bold">*/}
        {/*        Tutorial Video*/}
        {/*      </Text>*/}
        {/*      <Box h="12px" />*/}

        {/*      <Video*/}
        {/*        video={VIDEO_MAP.addLines}*/}
        {/*        variant="miniplayer"*/}
        {/*        width={isMobile ? '100%' : '275px'}*/}
        {/*        height={isMobile ? '200px' : '150px'}*/}
        {/*      />*/}

        {/*      <Box h="24px" />*/}

        {/*      <Flex gridGap="12px" mb="48px">*/}
        {/*        <Link to={generatePath(ROUTES.sgIntro, { salonId })}>*/}
        {/*          <Button variant="round-outline" colorScheme="brand.midnight">*/}
        {/*            Back*/}
        {/*          </Button>*/}
        {/*        </Link>*/}

        {/*        <Link to={generatePath(ROUTES.sgAddLines, { salonId }) + `?guide=${CHECKLIST_CODES.addLines}`}>*/}
        {/*          <Button variant="round" colorScheme="brand.midnight">*/}
        {/*            Add color lines*/}
        {/*          </Button>*/}
        {/*        </Link>*/}
        {/*      </Flex>*/}
        {/*    </Box>*/}
        {/*    <Box w={isMobile ? '100%' : '45%'}>*/}
        {/*      <Flex justify="center">*/}
        {/*        <TaskBar showLabel={false} stepsComplete={stepsComplete} totalSteps={2} />*/}
        {/*      </Flex>*/}
        {/*      <Img src={require('../../assets/start-guide/color-lines.png').default} />*/}
        {/*    </Box>*/}
        {/*  </Flex>*/}
        {/*</Box>*/}
      </SGLayout>
    </>
  )
}
