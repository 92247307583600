export const getLocalStorageItem = (key: string): any => {
  try {
    return JSON.parse(localStorage?.getItem(key) as string)
  } catch (e) {
    console.info('Please enable local storage to prevent having to log in every time you come to the website.')
  }
}

export const setLocalStorageItem = (key: string, value: Object): void => {
  try {
    localStorage?.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.info('Please enable local storage to prevent having to log in every time you come to the website.')
  }
}

export const clearLocalStorageItem = (key: string) => localStorage?.removeItem(key)

export const clearAllLocalStorage = () => {
  const allKeys = ['user-id', 'auth-token', 'theme', 'env', 'square-auth-state']
  try {
    allKeys.forEach(key => {
      localStorage?.removeItem(key)
    })
  } catch (e) {
    console.info('Please enable local storage to prevent having to log in every time you come to the website.')
  }
}
