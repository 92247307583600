import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { Box, Flex, Grid, GridItem, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { UseViewSize } from '../../core/UseViewSize'
import { Video } from '../../mini-lib/video/Video'
import {
  INVENTORY_VIDEOS,
  MOBILE_APP_VIDEOS,
  SETUP_VIDEOS,
  TRANSPARENT_BACKBAR,
  WEB_DASHBOARD_VIDEOS,
} from '../../mini-lib/video/constants'
import { generatePath } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { ReleaseTutorialsV2 } from "../../mini-lib/flags/Release";
import { COLORS } from "../../mini-lib/theme/colors";
import { Gap } from "../../mini-lib/gap/Gap";
import linenBg from '../../assets/tutorial/tutorial-tile-bg-linen.svg'
import peachBg from '../../assets/tutorial/tutorial-tile-bg-peach.svg'
import lavenderBg from '../../assets/tutorial/tutorial-tile-bg-lavender.svg'
import midnightBg from '../../assets/tutorial/tutorial-tile-bg-midnight.svg'
import skylightBg from '../../assets/tutorial/tutorial-tile-bg-skylight.svg'
import { ROUTES } from "../../appRoutes";

export const TutorialsPage = ( props: {} ) => {
  const { salonId } = UseBaseApiParams()
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Tutorials' }]
  const releaseTutorialsV2 = ReleaseTutorialsV2()
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="tutorials" breadcrumbs={breadcrumbs}/>}
      content={
        <>
          {releaseTutorialsV2
            ? <TutorialsV2/>
            : <Tabs colorScheme="brand.lavender">
              <TabList overflowX="auto">
                <Tab minW={'150px'}>Set Up</Tab>
                <Tab minW={'150px'}>Mobile App</Tab>
                <Tab minW={'150px'}>Business Center</Tab>
                <Tab minW={'150px'}>Inventory</Tab>
                <Tab minW={'150px'}>Transparent Backbar</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Setup/>
                </TabPanel>
                <TabPanel>
                  <MobileApp/>
                </TabPanel>
                <TabPanel>
                  <BusinessCenter/>
                </TabPanel>
                <TabPanel>
                  <Inventory/>
                </TabPanel>
                <TabPanel>
                  <TransparentBackbar/>
                </TabPanel>
              </TabPanels>
            </Tabs>
          }
        </>
      }
    />
  )
}

export const Setup = ( props: {} ) => {
  const { isMobile, isTablet } = UseViewSize()
  const templateColumns = isMobile ? 'repeat(1, 1fr)' : isTablet ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'
  return (
    <Flex maxW={'100%'}>
      <Grid templateColumns={templateColumns} width="700px" maxWidth="100%" gridGap="15px">
        {SETUP_VIDEOS.map(( video, index ) => {
          return (
            <GridItem key={index}>
              <Flex direction="column" align="center" justify="center" w="100%">
                <Video video={video} height="225px" width="300px"/>
                <Box h="12px"/>
                <Text> {video.title} </Text>
                <Box h="12px"/>
              </Flex>
            </GridItem>
          )
        })}
      </Grid>
    </Flex>
  )
}
export const MobileApp = ( props: {} ) => {
  const { isMobile, isTablet } = UseViewSize()
  const templateColumns = isMobile ? 'repeat(1, 1fr)' : isTablet ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'
  return (
    <Box maxW={'100%'} w={'700px'}>
      <Grid templateColumns={templateColumns} width="700px" maxWidth="100%" gridGap="15px">
        {MOBILE_APP_VIDEOS.map(( video, index ) => {
          return (
            <GridItem key={index}>
              <Flex direction="column" align="center" justify="center" w="100%">
                <Video video={video} height="225px" width="300px"/>
                <Box h="12px"/>
                <Text> {video.title} </Text>
                <Box h="12px"/>
              </Flex>
            </GridItem>
          )
        })}
      </Grid>
    </Box>
  )
}
export const BusinessCenter = ( props: {} ) => {
  const { isMobile, isTablet } = UseViewSize()
  const templateColumns = isMobile ? 'repeat(1, 1fr)' : isTablet ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'
  return (
    <Box maxW={'100%'} w={'700px'}>
      <Grid templateColumns={templateColumns} width="700px" maxWidth="100%" gridGap="15px">
        {WEB_DASHBOARD_VIDEOS.map(( video, index ) => {
          return (
            <GridItem key={index}>
              <Flex direction="column" align="center" justify="center" w="100%">
                <Video video={video} height="225px" width="300px"/>
                <Box h="12px"/>
                <Text> {video.title} </Text>
                <Box h="12px"/>
              </Flex>
            </GridItem>
          )
        })}
      </Grid>
    </Box>
  )
}

export const Inventory = ( props: {} ) => {
  const { isMobile, isTablet } = UseViewSize()
  const templateColumns = isMobile ? 'repeat(1, 1fr)' : isTablet ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'
  return (
    <Box maxW={'100%'} w={'700px'}>
      <Grid templateColumns={templateColumns} width="700px" maxWidth="100%" gridGap="15px">
        {INVENTORY_VIDEOS.map(( video, index ) => {
          return (
            <GridItem key={index}>
              <Flex direction="column" align="center" justify="center" w="100%">
                <Video video={video} height="225px" width="300px"/>
                <Box h="12px"/>
                <Text> {video.title} </Text>
                <Box h="12px"/>
              </Flex>
            </GridItem>
          )
        })}
      </Grid>
    </Box>
  )
}

export const TransparentBackbar = ( props: {} ) => {
  const { isMobile, isTablet } = UseViewSize()
  const templateColumns = isMobile ? 'repeat(1, 1fr)' : isTablet ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'
  return (
    <Box maxW={'100%'} w={'700px'}>
      <Grid templateColumns={templateColumns} width="700px" maxWidth="100%" gridGap="15px">
        {TRANSPARENT_BACKBAR.map(( video, index ) => {
          return (
            <GridItem key={index}>
              <Flex direction="column" align="center" justify="center" w="100%">
                <Video video={video} height="225px" width="300px"/>
                <Box h="12px"/>
                <Text> {video.title} </Text>
                <Box h="12px"/>
              </Flex>
            </GridItem>
          )
        })}
      </Grid>
    </Box>
  )
}

export const TutorialsV2 = () => {

  return (
    <>
      <Gap/>

      <Flex gridGap='24px' wrap='wrap'>
        <TutorialTile
          header={{
            backgroundImg: linenBg,
            text1: 'Set Up',
            text2: 'SalonScale',
            text1Color: COLORS.linen_500,
            text2Color: COLORS.midnight_500
          }}
          linkUrl='https://helpcenter.salonscale.com/en/collections/3622532-get-started'
          title='Set Up SalonScale'
          description='Explore guides for your mobile app, business centre, and bluetooth scale.'
        />
        <TutorialTile
          header={{
            backgroundImg: skylightBg,
            text1: 'Mobile',
            text2: 'App',
            text1Color: COLORS.skylight_500,
            text2Color: COLORS.midnight_500
          }}
          linkUrl='https://helpcenter.salonscale.com/en/collections/3622536-mobile-app'
          title='Mobile App'
          description='Everything you need to know to get the most out of the SalonScale App.'
        />
        <TutorialTile
          header={{
            backgroundImg: lavenderBg,
            text1: 'Business',
            text2: 'Center',
            text1Color: COLORS.lavender_500,
            text2Color: COLORS.midnight_500
          }}
          linkUrl='https://helpcenter.salonscale.com/en/collections/3622535-business-center'
          title='Business Center'
          description='Learn everything you need to know about SalonScales Business Center.'
        />
        <TutorialTile
          header={{
            backgroundImg: midnightBg,
            text1: 'Inventory',
            text2: '',
            text1Color: COLORS.midnight_500,
            text2Color: COLORS.midnight_500
          }}
          linkUrl='https://helpcenter.salonscale.com/en/collections/3953762-salonscale-inventory-management'
          title='Inventory'
          description='Inventory overview: Setup, usage, and optimization tips.'
        />
        <TutorialTile
          header={{
            backgroundImg: peachBg,
            text1: 'Transparent',
            text2: 'Backbar Course',
            text1Color: COLORS.peach_500,
            text2Color: COLORS.midnight_500
          }}
          linkUrl='https://helpcenter.salonscale.com/en/collections/5473320-transparent-backbar'
          title='Transparent Backbar Course'
          description='Learn how transparency in your backbar can boost more than profits.'
        />
      </Flex>
    </>
  )
}

export const TutorialTile = ( props: {
  header: { backgroundImg: any, text1: string, text1Color: string, text2: string, text2Color: string },
  linkUrl: string,
  title: string,
  description: string
} ) => {
  const { header, linkUrl, title, description } = props
  const {isMobile} = UseViewSize()
  return (
    <a href={linkUrl} target='_blank' rel="noreferrer">
      <Box w={isMobile ? '100%' : '300px'} bg={COLORS.shades_neutral_50} borderRadius='12px'>
        <Flex
          direction='column'
          w='100%'
          h='150px'
          align='center'
          justify='center'
          borderRadius='12px'
          backgroundImage={`url(${header.backgroundImg})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
        >
          <Text variant='title1' fontWeight='bold' color={header.text1Color}>
            {header.text1}
          </Text>
          {header.text2 &&
              <Text as='span' variant='title1' fontWeight='bold' color={header.text2Color}>{header.text2}</Text>}
        </Flex>
        <Box p='12px' height='100px'>
          <Text fontWeight='bold'>{title}</Text>
          <Gap s='4px'/>
          <Text color={COLORS.text_secondary}>{description}</Text>
        </Box>
      </Box>
    </a>
  )
}
