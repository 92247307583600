import TagManager from 'react-gtm-module'
import { useAppSelector } from "../hooks";
import { selectLoggedInUser } from "../data/user/slice";
import { useEffect } from "react";


declare var window
// Google Tag Manager
// - initialize gtag - https://stackoverflow.com/a/66302798/10795885
// - install gtm - https://tagmanager.google.com/#/admin/accounts/6003056601/containers/39319394/install?containerDraftId=80
// - react lib docs - https://github.com/alinemorelli/react-gtm
//
// - gtag link https://tagmanager.google.com/#/container/accounts/6003056601/containers/39319394/workspaces/101/tags
// - intercom/gtag integration guide https://www.intercom.com/help/en/articles/2631808-install-intercom-with-google-tag-manager
// - intercom docs https://developers.intercom.com/installing-intercom/docs/intercom-javascript
// - for login credentials contact John Mason
//
// - link to the actual property names the data layer wants https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#data-attributes
//
export const InitializeGtag = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-59KG5V2'
  }
  TagManager.initialize(tagManagerArgs)
}

export const InitializeIntercomUser = () => {
  const user = useAppSelector(selectLoggedInUser)
  const userEmail = user?.email
  const userName = user ? `${user.firstName} ${user.lastName}` : null

  useEffect(() => {
    if (userEmail && userName) {
      const userParams = {
        email: userEmail,
        name: userName,
      }
      if (window && window?.Intercom) {
        window?.Intercom('boot', userParams)
      }
    }
  }, [userEmail, userName])
}
