// to find new icon names check out the material icon docs https://fonts.google.com/icons?selected=Material+Icons
import React from 'react'
import { Flex } from '@chakra-ui/react'
import { ThemeType } from "../../theme";
import { COLORS } from "../theme/colors";

export const MaterialIcon = ( props: {
  name: string;
  size: string;
  iconsize?: string;
  colorhex?: string;
  variant?: 'icon' | 'button'
  theme?: ThemeType
} | any ) => {
  const { name, size, iconsize = size, colorhex = '', variant = 'icon', theme = 'lavender' } = props
  return (
    <>
      {variant === 'icon' &&
          <Flex h={size ? size : '24px'} w={size ? size : '24px'} {...props} align="center">
              <i style={{ height: size, width: size, fontSize: size, color: colorhex }}
                 className="material-icons-round">
                {name}
              </i>
          </Flex>
      }
      {variant === 'button' &&
          <Flex
              borderRadius='50px'
              bg={COLORS[`${theme}_50`]}
              border={`1px solid ${COLORS[`${theme}_500`]}`}
              color={COLORS[`${theme}_500`]}
              h={size ? size : '24px'}
              w={size ? size : '24px'}
              align="center"
              cursor='pointer'
              justify='center'
              {...props}
          >
              <i style={{ height: iconsize, width: iconsize, fontSize: iconsize, color: colorhex }}
                 className="material-icons-round">
                {name}
              </i>
          </Flex>
      }
    </>
  )
}

