/////////////// slice.ts
//
//

// todos
// 1: add labor to RootState located at src/store.ts
// 2: ensure model has 'id', change it to unique property if necessary

// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { assign, keyBy, keys, map, omit, orderBy, uniq, values } from 'lodash'

import { RootState } from '../../store'
import {LaborService, LaborTier, LaborServiceOverride, LaborItem} from './interfaces'
import { ALL_LABOR_CATEGORIES, LABOR_PAGE_TYPES, LaborPageType } from "./constants";
import { buildUserServiceKey, groupLaborServicesByCategory } from "./utils";

// state
//
interface LaborState {
  laborTierServicesByid: { [key: string]: LaborItem } | null
  laborTiersById: { [key: string]: LaborTier } | null
  laborServicesById: { [key: string]: LaborService } | null
  laborServicesOverridesByUserServiceId: { [key: string]: LaborServiceOverride } | null
  selectedLaborCategory: string
  editableLaborCategory: string
  laborPageType: LaborPageType
  modalIdVisible: string
}

const initialState: LaborState = {
  laborTierServicesByid: null,
  laborTiersById: null,
  laborServicesById: null,
  laborServicesOverridesByUserServiceId: null,
  selectedLaborCategory: ALL_LABOR_CATEGORIES,
  editableLaborCategory: '',
  laborPageType: LABOR_PAGE_TYPES.list,
  modalIdVisible: '',

}

// reducer
//
export const LaborSlice = createSlice({
  name: 'labors',
  initialState,
  reducers: {
    // labor item state
    //
    reduceListLaborItems: (state, action: PayloadAction<LaborItem[]>) => {
      state.laborTierServicesByid = assign({}, state.laborTierServicesByid, keyBy(action.payload, 'id'))
    },
    reduceCreateLaborItems: (state, action: PayloadAction<LaborItem[]>) => {
      state.laborTierServicesByid = assign({}, state.laborTierServicesByid, keyBy(action.payload, 'id'))
    },
    reduceResetLaborItems: (state, action: PayloadAction<LaborItem[]>) => {
      state.laborTierServicesByid = {}
    },
    reduceUpdateLaborItems: (state, action: PayloadAction<LaborItem[]>) => {
      state.laborTierServicesByid = assign({}, state.laborTierServicesByid, keyBy(action.payload, 'id'))
    },
    reduceDeleteLaborItems: (state, action: PayloadAction<LaborItem[]>) => {
      const idsToRemove = action.payload.map((item) => item.id)
      state.laborTierServicesByid = omit(state.laborTierServicesByid, idsToRemove)
    },

    // labor tier state
    //
    reduceListLaborTiers: (state, action: PayloadAction<LaborTier[]>) => {
      state.laborTiersById = assign({}, state.laborTiersById, keyBy(action.payload, 'id'))
    },
    reduceCreateLaborTiers: (state, action: PayloadAction<LaborTier[]>) => {
      state.laborTiersById = assign({}, state.laborTiersById, keyBy(action.payload, 'id'))
    },
    reduceUpdateLaborTiers: (state, action: PayloadAction<LaborTier[]>) => {
      state.laborTiersById = assign({}, state.laborTiersById, keyBy(action.payload, 'id'))
    },
    reduceDeleteLaborTiers: (state, action: PayloadAction<LaborTier[]>) => {
      const idsToRemove = action.payload.map((item) => item.id)
      state.laborTiersById = omit(state.laborTiersById, idsToRemove)
    },

    // labor service state
    //
    reduceListLaborServices: (state, action: PayloadAction<LaborService[]>) => {
      state.laborServicesById = assign({}, state.laborServicesById, keyBy(action.payload, 'id'))
    },
    reduceCreateLaborServices: (state, action: PayloadAction<LaborService[]>) => {
      state.laborServicesById = assign({}, state.laborServicesById, keyBy(action.payload, 'id'))
    },
    reduceUpdateLaborServices: (state, action: PayloadAction<LaborService[]>) => {
      state.laborServicesById = assign({}, state.laborServicesById, keyBy(action.payload, 'id'))
    },
    reduceDeleteLaborServices: (state, action: PayloadAction<LaborService[]>) => {
      const idsToRemove = action.payload.map((item) => item.id)
      state.laborServicesById = omit(state.laborServicesById, idsToRemove)
    },

    // category state
    //
    reduceSelectedLaborCategory: (state, action: PayloadAction<string>) => {
      state.selectedLaborCategory = action.payload
    },
    reduceEditableLaborCategory: (state, action: PayloadAction<string>) => {
      state.editableLaborCategory = action.payload
    },

    // override state
    reduceListLaborServiceOverrides: (state, action: PayloadAction<LaborServiceOverride[]>) => {
      state.laborServicesOverridesByUserServiceId = assign(
        {},
        state.laborServicesOverridesByUserServiceId,
        keyBy(action.payload, item => buildUserServiceKey(item.userId, item.laborService.id))
      )
    },
    reduceBulkUpsertLaborServiceOverrides: (state, action: PayloadAction<LaborServiceOverride[]>) => {
      state.laborServicesOverridesByUserServiceId = assign(
        {},
        state.laborServicesOverridesByUserServiceId,
        keyBy(action.payload, item => buildUserServiceKey(item.userId, item.laborService.id))
      )
    },
    reduceBulkDeleteLaborServiceOverrides: (state, action: PayloadAction<LaborServiceOverride[]>) => {
      const idsToRemove = action.payload.map((override) => buildUserServiceKey(override.userId, override.laborService.id))
      state.laborServicesOverridesByUserServiceId = omit(state.laborServicesOverridesByUserServiceId, idsToRemove)
    },

    reduceLaborPageType: (state, action: PayloadAction<LaborPageType>) => {
      state.laborPageType = action.payload
    },

    reduceModalIdVisible: (state, action: PayloadAction<string>) => {
      state.modalIdVisible = action.payload
    },
  },
})

// actions
//
export const {
  reduceListLaborItems,
  reduceCreateLaborItems,
  reduceUpdateLaborItems,
  reduceDeleteLaborItems,
  reduceListLaborTiers,
  reduceResetLaborItems,
  reduceUpdateLaborTiers,
  reduceCreateLaborTiers,
  reduceDeleteLaborTiers,
  reduceListLaborServices,
  reduceCreateLaborServices,
  reduceUpdateLaborServices,
  reduceDeleteLaborServices,
  reduceSelectedLaborCategory,
  reduceEditableLaborCategory,
  reduceListLaborServiceOverrides,
  reduceBulkUpsertLaborServiceOverrides,
  reduceBulkDeleteLaborServiceOverrides,
  reduceLaborPageType,
  reduceModalIdVisible,
} = LaborSlice.actions

// selectors
//

export const selectLaborItemsById = (state: RootState) => state.labors.laborTierServicesByid;

export const selectLaborItemList = (state: RootState): LaborItem[] | null => {
  // if they have no labor tier services return empty list (not null)
  if (state.labors.laborTierServicesByid && keys(state.labors.laborTierServicesByid).length === 0) {
    return []
  }
  const items = values(state.labors.laborTierServicesByid) || []

  // only return items if all three objects exist
  if (items.length > 0) {
    const property1Sorter = (labor) => labor?.tier?.name.toLowerCase()
    const property2Sorter = (labor) => labor?.service?.name.toLowerCase()
    return orderBy(items, [property1Sorter, property2Sorter], ['asc', 'asc'])
  }
  return null
}

export const selectLaborServiceList = (state: RootState): LaborService[] | null => {
  const property1Sorter = (service) => service?.name?.toLowerCase()
  const property2Sorter = (service) => service?.type?.toLowerCase()
  return state.labors.laborServicesById
    ? orderBy(values(state.labors.laborServicesById), [property1Sorter, property2Sorter], ['asc', 'asc'])
    : null
}

export const selectLaborServiceOverridesByUserServiceId = (state: RootState): {[key: string]: LaborServiceOverride} | null => {
  return state.labors.laborServicesOverridesByUserServiceId
}

export const selectLaborItemsForTier = (state: RootState, tier: LaborTier): LaborItem[] | null => {
  const allItems = values(state.labors.laborTierServicesByid) || []
  const itemsForTier = allItems.filter((item) => item.tier.id === tier.id)
  return itemsForTier
}

export const selectLaborItemsForService = (
  state: RootState,
  service: LaborService,
): LaborItem[] | null => {
  const allItems = values(state.labors.laborTierServicesByid) || []
  const itemsForService = allItems.filter((item) => item.service.id === service.id)
  return itemsForService
}

export const selectLaborTierList = (state: RootState): LaborTier[] | null => {
  const property1Sorter = (tier) => tier?.name?.toLowerCase()
  return state.labors.laborTiersById ? orderBy(values(state.labors.laborTiersById), [property1Sorter], ['asc']) : null
}

export const selectSelectedLaborCategory = (state: RootState): string => {
  return state.labors.selectedLaborCategory
}
export const selectEditableLaborCategory = (state: RootState): string => {
  return state.labors.editableLaborCategory
}

export const selectLaborServicesByCategory = (state: RootState): { [category: string]: LaborService[] } | null => {
  return state.labors.laborServicesById
    ? groupLaborServicesByCategory(values(state.labors.laborServicesById))
    : null
}

export const selectAllLaborCategories = (state: RootState): string[] => {
  const services = values(state.labors.laborServicesById)
  const all = map(services, (s) => s.category)
  return uniq(all)
}

export const selectLaborPageType = (state: RootState): LaborPageType => {
  return state.labors.laborPageType
}

export const selectModalIdVisible = (state: RootState): string => {
  return state.labors.modalIdVisible
}
// export
//
export default LaborSlice.reducer
