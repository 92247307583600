import { Box, Flex, Text } from '@chakra-ui/react'
import { SalonScaleIcon } from '../../../mini-lib/icons/SalonScaleIcon'
import { addNumberSuffix } from '../../../mini-lib/utils/basic'
import React, { useEffect } from 'react'
import { dispatchGetOwnerAnalytics } from '../../../data/analytics/api'
import { OwnerMetricOptions } from '../../../data/analytics/interfaces'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useAppSelector } from '../../../hooks'
import { selectAnalyticsForStatType, selectDateRangeData } from '../../../data/analytics/slice'
import { useDispatch } from 'react-redux'
import { UseViewSize } from '../../../core/UseViewSize'
import { Loading } from '../../../mini-lib/loading/Loading'
import { buildDateYYYYMMDD } from '../../../core/dates'

export const HomeTotals = (props: {}) => {
  const dispatch = useDispatch()
  const { isMobile, isTablet } = UseViewSize()
  const { user, salonId } = UseBaseApiParams()
  const { dateRangeType, start, end, dataInterval } = useAppSelector(selectDateRangeData)
  const sessionAnalytics = useAppSelector((state) =>
    selectAnalyticsForStatType(state, dateRangeType, OwnerMetricOptions.totalSessions),
  )
  const revenueAnalytics = useAppSelector((state) =>
    selectAnalyticsForStatType(state, dateRangeType, OwnerMetricOptions.totalRevenueProfit),
  )


  const startDateString = buildDateYYYYMMDD(start)
  const endDateString =  buildDateYYYYMMDD(end)

  const totalSessions = sessionAnalytics?.totalSessions ?? 0
  const totalRevenue = revenueAnalytics?.totalRevenueProfit.reduce((a, b) => (a + b.revenue), 0) ?? 0
  useEffect(() => {
    if (dataInterval && startDateString && endDateString) {
      dispatch(
        dispatchGetOwnerAnalytics({
          token: user.token,
          salonId,
          dateRangeType,
          dateStart: startDateString,
          dateEnd: endDateString,
          interval: dataInterval,
          statName: OwnerMetricOptions.totalRevenueProfit,
        }),
      )
    }
  }, [dispatch, dataInterval, dateRangeType, user.token, salonId, startDateString, endDateString])

  useEffect(() => {
    if (dataInterval && startDateString && endDateString) {
      dispatch(
        dispatchGetOwnerAnalytics({
          token: user.token,
          salonId,
          dateRangeType,
          dateStart: startDateString,
          dateEnd: endDateString,
          interval: dataInterval,
          statName: OwnerMetricOptions.totalSessions,
        }),
      )
    }
  }, [dispatch, dataInterval, dateRangeType, user.token, salonId, startDateString, endDateString])

  const averagePerSession = totalRevenue && totalSessions ? totalRevenue / totalSessions : 0
  return (
    <Flex align="center" gridGap="12px" justify={isMobile || isTablet ? 'space-between' : ''}>
      <Flex align="center">
        <SalonScaleIcon size={isMobile ? '24px' : '40px'} mr="4px" name="sessions" />
        <Box>
          {sessionAnalytics ? <Text variant="title3">{addNumberSuffix(totalSessions)}</Text> : <Loading size="12px" />}
          <Text textTransform="uppercase" fontSize={isMobile ? '10px' : '12px'}>
            {isMobile ? 'sessions' : 'total sessions'}
          </Text>
        </Box>
      </Flex>
      <Flex align="center">
        <SalonScaleIcon size={isMobile ? '24px' : '40px'} mr="4px" name="products" />
        <Box>
          {revenueAnalytics ? <Text variant="title3">{addNumberSuffix(totalRevenue)}</Text> : <Loading size="12px" />}
          <Text textTransform="uppercase" fontSize={isMobile ? '10px' : '12px'}>
            {isMobile ? 'revenue' : 'color revenue'}
          </Text>
        </Box>
      </Flex>
      <Flex align="center">
        <SalonScaleIcon size={isMobile ? '24px' : '40px'} mr="4px" name="average" />
        <Box>
          {sessionAnalytics && revenueAnalytics ? (
            <Text variant="title3">{addNumberSuffix(averagePerSession)}</Text>
          ) : (
            <Loading size="12px" />
          )}
          <Text textTransform="uppercase" fontSize={isMobile ? '10px' : '12px'}>
            average/session
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}
