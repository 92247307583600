import React from 'react'
import { UseViewSize } from '../../../core/UseViewSize'
import {
  EMPTY_INVENTORY_ITEM_FILTERS,
  INVENTORY_ADD_OR_ORDER_COUNT_REASONS,
  INVENTORY_AUDIT_REASONS,
} from '../../../data/inventory/constants'
import { capitalize } from 'lodash'
import { useAppSelector } from '../../../hooks'
import { reduceSetInventoryItemFilters, selectItemFilters } from '../../../data/inventory/slice'
import { useDispatch } from 'react-redux'
import { InventoryCountedState, InventoryCountReason } from '../../../data/inventory/interfaces'
import { FilterBar } from '../../../mini-lib/filters/FilterBar'
import { SearchFilter } from '../../../mini-lib/filters/SearchFilter'
import { DropdownFilter } from '../../../mini-lib/filters/DropdownFilter'
import { getNumFiltersActive } from '../../../data/products/utils'

export interface ItemFilters {
  searchText: string
  vendor: string
  line: string
  category: string
  reasonType: InventoryCountReason
  countedState: InventoryCountedState
}

export const InventoryItemFilters = (props: {
  showVendorFilter: boolean
  showLineFilter: boolean
  showCategoryFilter: boolean
  showCountedTypeFilter: boolean
  showAddOrOrderReasonTypeFilter: boolean
  showAuditReasonTypeFilter: boolean
  allCategories?: string[]
  allLines?: string[]
  allVendors?: string[]
}) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const {
    showVendorFilter,
    showLineFilter,
    showCategoryFilter,
    showCountedTypeFilter,
    showAuditReasonTypeFilter,
    showAddOrOrderReasonTypeFilter,
    allCategories = [],
    allLines = [],
    allVendors = []
  } = props

  const filters = useAppSelector(selectItemFilters)
  const filterWidth = isMobile ? '48%' : '130px'
  return (
    <>
      <FilterBar
        search={
          <SearchFilter
            placeholder="Search by name"
            value={filters.searchText}
            onChange={(searchText) => dispatch(reduceSetInventoryItemFilters({ ...filters, ...{ searchText } }))}
            onClear={() => dispatch(reduceSetInventoryItemFilters({ ...filters, ...{ searchText: '' } }))}
          />
        }
        filters={
          <>
            {showVendorFilter && (
              <DropdownFilter
                theme='lavender'
                w={filterWidth}
                placeholder={isMobile ? 'Brands' : 'All Brands'}
                value={filters.vendor}
                options={allVendors.map((name) => ({ label: name, value: name }))}
                onChange={(option) =>
                  dispatch(reduceSetInventoryItemFilters({ ...filters, ...{ vendor: option.value, line: '' } }))
                }
              />
            )}
            {showLineFilter && (
              <DropdownFilter
                theme='lavender'
                w={filterWidth}
                placeholder={isMobile ? 'Lines' : filters.vendor ? `${filters.vendor} Lines` : 'All Lines'}
                value={filters.line}
                options={allLines.map((name) => ({ label: name, value: name }))}
                onChange={(option) =>
                  dispatch(reduceSetInventoryItemFilters({ ...filters, ...{ line: option.value } }))
                }
              />
            )}
            {showCategoryFilter && (
              <DropdownFilter
                theme='lavender'
                w={filterWidth}
                placeholder={isMobile ? 'Categories' : 'All Categories'}
                value={filters.category}
                options={allCategories.map((name) => ({ label: name, value: name }))}
                onChange={(option) =>
                  dispatch(reduceSetInventoryItemFilters({ ...filters, ...{ category: option.value } }))
                }
              />
            )}
            {showCountedTypeFilter && (
              <DropdownFilter
                theme='lavender'
                w={filterWidth}
                placeholder="Counted Status"
                value={filters.countedState}
                options={['counted', 'not counted'].map((name) => ({ label: capitalize(name), value: name }))}
                onChange={(option) =>
                  dispatch(reduceSetInventoryItemFilters({ ...filters, ...{ countedState: option.value } }))
                }
              />
            )}
            {showAddOrOrderReasonTypeFilter && (
              <DropdownFilter
                theme='lavender'
                w={filterWidth}
                placeholder="All Reasons"
                value={filters.reasonType}
                options={INVENTORY_ADD_OR_ORDER_COUNT_REASONS}
                onChange={(option) =>
                  dispatch(reduceSetInventoryItemFilters({ ...filters, ...{ reasonType: option.value } }))
                }
              />
            )}
            {showAuditReasonTypeFilter && (
              <DropdownFilter
                theme='lavender'
                w={filterWidth}
                placeholder="All Reasons"
                value={filters.reasonType}
                options={INVENTORY_AUDIT_REASONS}
                onChange={(option) =>
                  dispatch(reduceSetInventoryItemFilters({ ...filters, ...{ reasonType: option.value } }))
                }
              />
            )}
          </>
        }
        onClear={() => dispatch(reduceSetInventoryItemFilters(EMPTY_INVENTORY_ITEM_FILTERS))}
        numFiltersActive={getNumFiltersActive(filters)}
      />
    </>
  )
}
