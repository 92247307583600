import { LoggedInUser } from '../../data/user/interfaces'
import { useDispatch } from 'react-redux'
import { getLocalStorageItem } from '../localStorage'
import { selectLoggedInUser } from '../../data/user/slice'
import { useEffect } from 'react'
import { dispatchGetUserLara } from '../../data/user/api'
import { useAppSelector } from '../../hooks'
import { UseCurrentSalonId } from './UseCurrentSalonId'
import { dispatchLaraGetSalon } from "../../data/salon/api";
import { selectActiveSalonContext } from "../../data/salon/slice";
import { selectLoadingState } from "../loading/slice";
import { LOADING_SALON } from "../../data/salon/constants";

// three possible states
// user exists
// user does not exist -> no user in state, no local storage token or user id
// loading -> we have the token and user id and are getting the user
export const UseCurrentUser = (salonIdOverride?: number): { user: LoggedInUser | null; loadingUser: boolean } => {
  const dispatch = useDispatch()
  // try getting the user from the store
  const user = useAppSelector(selectLoggedInUser)
  const loadingSalon = useAppSelector((state) => selectLoadingState(state, LOADING_SALON))
  const { currentSalonId } = UseCurrentSalonId()
  // use effect deps must be raw variables, convert user to a boolean to prevent duplicate calls
  const haveUser = !!user

  // get the localstorage token and id to call with if we dont have the user in the store
  const localStorageToken = getLocalStorageItem('auth-token')
  const localStorageUserId = getLocalStorageItem('user-id')

  const isLoading = localStorageUserId && localStorageToken && !haveUser
  const salonId = salonIdOverride ? salonIdOverride : currentSalonId
  useEffect(() => {
    if (!haveUser && localStorageToken && localStorageUserId) {
      const params = { token: localStorageToken, userId: localStorageUserId, salonId: salonId || undefined }
      // dispatch(dispatchGetLoggedInUser(params))
      dispatch(dispatchGetUserLara(params))
    }
  }, [haveUser, localStorageToken, localStorageUserId, salonId, dispatch])

  // load active salon from lara
  const activeSalon = useAppSelector(selectActiveSalonContext)
  const salonLoaded = !!activeSalon
  useEffect(() => {
    if (user?.userId && user?.token && !salonLoaded && !loadingSalon && salonId) {
      dispatch(dispatchLaraGetSalon({ token: user.token, userId: user.userId, salonId }))
    }
  }, [dispatch, user?.token, user?.userId, loadingSalon, salonLoaded, salonId])

  return { user, loadingUser: isLoading }
}
