import {
  Box,
  Button,
  Circle, Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { UseViewSize } from '../../core/UseViewSize'
import { DateInput } from '../../mini-lib/dates-and-times/DateInput'
import { ClientAutocomplete } from '../clients/ClientAutocomplete'
import { APISessionMetaCreateLara } from '../../data/sessions/interfaces'
import { Client } from '../../data/clients/interfaces'
import { useDispatch } from 'react-redux'
import {
  dispatchBulkCreateSessionsLara,
  dispatchCreateClientAndSessionLara,
  dispatchCreateSessionLara,
} from '../../data/sessions/api'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { useAppSelector } from '../../hooks'
import { selectRedirectToSessionId } from '../../data/sessions/slice'
import { generatePath, Redirect } from 'react-router-dom'
import { DisableAutofocus } from '../../mini-lib/disable-autofocus/DisableAutofocus'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { UserMeta } from '../../data/user/interfaces'
import { buildLaraServerDateTime } from '../../core/dates'
import { ROUTES } from '../../appRoutes'
import { ClientSelectSheet } from '../clients/ClientSelectSheet'
import { ReleaseOpenDay } from '../../mini-lib/flags/Release'
import { filter } from 'lodash'
import { COLORS } from '../../mini-lib/theme/colors'
import { Gap } from '../../mini-lib/gap/Gap'
import { dispatchCreateClient } from '../../data/clients/api'
import {
  reduceSetRecentlyCreatedClientsById,
  selectRecentlyCreatedClientList,
} from '../../data/clients/slice'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { VARIANTS } from '../../mini-lib/theme/variants'
import { selectLoadingState } from '../../core/loading/slice'
import { CREATING_CLIENT_CONSTANT } from '../../data/clients/constants'
import { Loading } from '../../mini-lib/loading/Loading'
import { buildFormikProps } from "../../mini-lib/form/utils";
import { TextInput } from "../../mini-lib";
import { maybePluralize } from "../../core/text";
import { UserAutocomplete } from "../stylists/UserAutocomplete";

export const SessionCreateButton = () => {
  const [showSheet, setShowSheet] = useState(false)
  return (
    <>
      <Button variant="round" colorScheme="brand.midnight" onClick={() => setShowSheet(true)}>
        New Session
      </Button>
      {showSheet && <SessionCreateSheet showSheet={showSheet} setShowSheet={setShowSheet} />}
    </>
  )
}

export const SessionCreateSheet = (props: { showSheet: boolean; setShowSheet: (boolean) => void }) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const { showSheet, setShowSheet } = props
  const { isMobile } = UseViewSize()
  const redirectSessionId = useAppSelector(selectRedirectToSessionId)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedClient, setSelectedClient] = useState<Client | null>(null)
  const [showClientForm, setShowClientForm] = useState(false)
  const [showStylistInput, setShowStylistInput] = useState(true)
  const [selectedUserMeta, setSelectedStylist] = useState<UserMeta | null>(null)
  const [showClientSheet, setShowClientSheet] = useState(false)
  const [selectedClients, setSelectedClients] = useState<Client[]>([])
  const releaseOpenDay = ReleaseOpenDay()

  const createdClients = useAppSelector(selectRecentlyCreatedClientList)
  const normalizedCreatedClients = createdClients || []
  const isCreatingClient = useAppSelector((state) => selectLoadingState(state, CREATING_CLIENT_CONSTANT))
  // we will always have the stylist id
  const knownUserId: any = user.userId || null
  const enableCreateButton = !!selectedDate && !!selectedClient

  useEffect(() => {
    dispatch(reduceSetRecentlyCreatedClientsById(null))
  }, [dispatch])

  useEffect(() => {
    setSelectedClients([...selectedClients, ...normalizedCreatedClients])

    // dont want to memoize this so just using the length
    // eslint-disable-next-line
  }, [normalizedCreatedClients?.length])

  // note: if they create a client manually it takes them to onCreateClientAndSession, this is not called
  const createSession = () => {
    if (selectedDate && selectedClient) {
      const laraRequest: APISessionMetaCreateLara = {
        date: buildLaraServerDateTime(selectedDate),
        client_id: selectedClient.id,
        user_id: selectedUserMeta ? selectedUserMeta.id : knownUserId,
      }
      dispatch(dispatchCreateSessionLara({ token: user.token, salonId, model: laraRequest }))
    }
  }

  const createSessions = () => {
    if (selectedClients && selectedClients.length > 0) {
      const models: APISessionMetaCreateLara[] = selectedClients.map((client) => {
        return {
          date: buildLaraServerDateTime(selectedDate),
          client_id: client.id,
          user_id: selectedUserMeta ? selectedUserMeta.id : knownUserId,
        }
      })
      dispatch(dispatchBulkCreateSessionsLara({ token: user.token, salonId, models }))
      dispatch(reduceSetRecentlyCreatedClientsById(null))
      setShowSheet(false)
    }
  }
  const onCreateClient = (params: { firstName: string; lastName: string }) => {
    dispatch(
      dispatchCreateClient({
        token: user.token,
        salonId,
        model: { id: -1, firstName: params.firstName, lastName: params.lastName },
      }),
    )
    setShowClientForm(false)
  }

  const onCreateClientAndSession = (values: { id: number; firstName: string; lastName: string }) => {
    if (values.id < 0 && user) {
      const laraSessionRequest: APISessionMetaCreateLara = {
        date: buildLaraServerDateTime(selectedDate),
        client_id: -1,
        user_id: selectedUserMeta ? selectedUserMeta.id : knownUserId,
      }
      const params = {
        token: user.token,
        userId: user.userId,
        salonId,
        client: values,
        session: laraSessionRequest,
      }
      dispatch(dispatchCreateClientAndSessionLara(params))
    }
  }

  const onClientRemove = (client: Client) => {
    setSelectedClients(filter(selectedClients, (c) => c !== client))
  }

  // if a session was created and successfully set redirect to it
  if (redirectSessionId) {
    const url = generatePath(ROUTES.sessionDetails, { salonId, sessionId: redirectSessionId })
    return <Redirect to={url} />
  }

  // const selectedAndCreatedClients = orderBy([...selectedClients, ...normalizedCreatedClients], 'firstName')
  return (
    <>
      {showClientSheet && (
        <ClientSelectSheet
          show={showClientSheet}
          onHide={() => setShowClientSheet(false)}
          selectedClientIds={selectedClients.map((c) => c.id)}
          onClientsSelected={(clients) => {
            setSelectedClients(clients)
            setShowClientSheet(false)
          }}
          subtitleText={''}
        />
      )}
      <Drawer size={isMobile ? 'xs' : 'md'} isOpen={showSheet} placement="right" onClose={() => setShowSheet(false)}>
        <DrawerOverlay />
        <DrawerCloseButton />
        <DrawerContent>
          <DrawerHeader>
            <Text variant="title2">New Session</Text>
          </DrawerHeader>
          <DrawerBody>
            <DisableAutofocus />

            <Box w="100%">
              <DateInput
                label="Date"
                placeholder="Date"
                value={selectedDate}
                onChange={(date) => {
                  setSelectedDate(date)
                }}
                isClearable={false}
              />
            </Box>

            {showStylistInput && (
              <Box w="100%">
                <Box h="24px" />
                <UserAutocomplete
                  label="Stylist"
                  width="100%"
                  initialUserId={knownUserId}
                  isClearable={false}
                  onSelect={(stylist) => setSelectedStylist(stylist)}
                />
                <Box h="24px" />
              </Box>
            )}
            {!showStylistInput && (
              <>
                <Box h="24px" />
                <Flex align="center" justify="space-between">
                  <Text>
                    Stylist: {user.firstName} {user.lastName}
                  </Text>
                  <Box h="12px" />
                  <Flex cursor="pointer" color="brand.lavender.500" onClick={() => setShowStylistInput(true)}>
                    <MaterialIcon name="add_circle" style={{ marginRight: '4px' }} /> select different stylist
                  </Flex>
                </Flex>
                <Box h="24px" />
              </>
            )}

            <>
              <Box w="100%">
                {!releaseOpenDay && !showClientForm && (
                  <>
                    <ClientAutocomplete
                      label="Client"
                      width="100%"
                      isClearable={false}
                      onSelect={(client) => setSelectedClient(client)}
                    />
                    <Gap />
                    <Flex
                      justify="end"
                      cursor="pointer"
                      color="brand.lavender.500"
                      onClick={() => setShowClientForm(true)}
                    >
                      <MaterialIcon name="add_circle" style={{ marginRight: '4px' }} /> create new client
                    </Flex>
                    <Gap />
                  </>
                )}
                {releaseOpenDay && (
                  <>
                    <Text variant={VARIANTS.text.calloutDesktopRegular}>Clients</Text>
                    <Gap s="12px" />
                    {selectedClients.length === 0 && (
                      <EmptyBox
                        p='32px'
                        title="No clients added yet"
                        content="Create a new client or add from existing clients"
                      />
                    )}
                    {selectedClients.map((client) => {
                      return (
                        <Flex key={client.id} justify="space-between" align="center" pb="12px">
                          <Flex align="center" gridGap="12px" fontWeight="bold">
                            <Circle size="44px" color={COLORS.lavender_500} bg={COLORS.lavender_100}>
                              {client.firstName[0]}
                              {client.lastName[0]}
                            </Circle>
                            {client.firstName} {client.lastName}
                          </Flex>
                          <MaterialIcon
                            colorhex={COLORS.shades_neutral_500}
                            cursor="pointer"
                            name="close"
                            onClick={() => onClientRemove(client)}
                          />
                        </Flex>
                      )
                    })}
                    <Gap />
                    <Flex justify="end" gridGap="12px">
                      {/* todo: create the client inline and add them to the list of clients to create a session for */}
                      <Button variant="round-ghost-upper" onClick={() => setShowClientForm(true)}>
                        Create Client
                      </Button>

                      <Button variant="round-outline" onClick={() => setShowClientSheet(true)}>
                        Add Clients
                      </Button>
                    </Flex>

                  </>
                )}
              </Box>
              {showClientForm && (
                <>
                  {releaseOpenDay && <Gap/>}
                  {isCreatingClient ? (
                    <Loading />
                  ) : (
                    <>
                      {releaseOpenDay && (
                        <>
                          <Divider/>
                          <Gap />
                        </>
                      )}
                      <Box w="100%" borderRadius="15px">
                        <Text variant="title3">New Client</Text>
                        <Gap/>
                        <SessionClientForm
                          onCreateClient={releaseOpenDay ? onCreateClient : onCreateClientAndSession}
                          onCancel={() => setShowClientForm(false)}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
              <Gap s='100px'/>
              {releaseOpenDay && !showClientForm && (
                <Flex justify="end" gridGap="12px" bottom="0" right="0" position="absolute" bg="white" left='0' p='12px'>
                  <Button variant="round-outline" colorScheme='brand.midnight' onClick={() => setShowSheet(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="round"
                    colorScheme='brand.midnight'
                    isDisabled={!selectedClients || selectedClients.length === 0}
                    onClick={createSessions}
                  >
                    Create {selectedClients.length} {maybePluralize('Session', selectedClients.length)}
                  </Button>
                </Flex>
              )}
              {!releaseOpenDay && !showClientForm && (
                <Flex justify="end" gridGap="12px" bottom="12px" right="12px" position="absolute" bg="white">
                  <Button variant="round-outline" onClick={() => setShowSheet(false)}>
                    Cancel
                  </Button>
                  <Button variant="round" isDisabled={!enableCreateButton} onClick={createSession}>
                    Create Session
                  </Button>
                </Flex>
              )}
            </>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export const SessionClientForm = (props: { onCreateClient: (values: any) => void; onCancel: () => void }) => {
  const { onCreateClient, onCancel } = props
  const releaseOpenDay = ReleaseOpenDay()

  const formInitialValues = {
    id: -1,
    firstName: '',
    lastName: '',
  }
  const formValidationSchema = Yup.object({
    id: Yup.string(),
    firstName: Yup.string().required('first name is required'),
    lastName: Yup.string().required('last name is required'),
  })


  return (
    <Formik onSubmit={onCreateClient} initialValues={formInitialValues} validationSchema={formValidationSchema}>
      {({ handleSubmit, ...formikParams }) => {
        const firstNameParams = buildFormikProps({ name: 'firstName', formikParams })
        const lastNameParams = buildFormikProps({ name: 'lastName', formikParams })
        return (
          <>
            <Flex gridGap="12px" direction="column" onSubmit={handleSubmit as any}>
              <TextInput
                variant='material'
                placeholder='enter text'
                label="First Name"
                allowFocus={true}
                {...firstNameParams}
              />
              <TextInput
                variant='material'
                placeholder='enter text'
                label="Last Name"
                allowFocus={true}
                {...lastNameParams}
              />
            </Flex>
            <Box h="24px" />
            <Flex justify="end" gridGap="12px">
              <Button onClick={onCancel} className="cy-cancel-button" variant="round-outline">
                Cancel
              </Button>
              <Button variant="round" colorScheme="brand.lavender" onClick={() => handleSubmit()}>
                {releaseOpenDay ? 'Create Client' : 'Create Session'}
              </Button>
            </Flex>
          </>
        )
      }}
    </Formik>
  )
}
