import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { SignupFormLayout } from "./SignupFormLayout";
import { Gap } from "../../../mini-lib/gap/Gap";
import React, { useState } from "react";
import { FormikError } from "../../../mini-lib/formik-utils/FormikError";
import { COLORS } from "../../../mini-lib/theme/colors";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../appRoutes";
import { MaterialIcon } from "../../../mini-lib/icons/MaterialIcon";
import { UseGeneratePathWithQPS } from "../../../mini-lib/router/router-utils";

export const SignupTrialOrPayPage = () => {
  return (
    <SignupFormLayout>
      <Flex justify='center' align='center' direction='column'>
        <Gap/>
        <Text variant='title2-desktop-bold'>Choose the option that works best for you.</Text>
        <Gap s='32px'/>
        <TrialOrPayForm/>
      </Flex>
    </SignupFormLayout>
  )
}


const subscriptionTypeOptions = {
  solo: { value: 'pay', subscriptionType: 'Pay Now', icon: 'discount' },
  team: { value: 'trial', subscriptionType: 'Trial', icon: 'balance' },
}

const TrialOrPayForm = () => {
  const history = useHistory()

  const [selectedOption, setSelectedOption] = useState<string>('')
  const [error, setError] = useState<string>('')
  const optionClicked = (option: string) => {
    setSelectedOption(option)
    setError('')
  }
  const nextUrl = UseGeneratePathWithQPS({ pathname: ROUTES.signupV2User, newParams: {type: selectedOption} })
  const submitClicked = () => {
    if (!selectedOption) {
      setError('Please select a subscription option to continue!')
      return
    }
    history.push(nextUrl)
  }
  return (
    <>
      <Box w='100%'>
        <>
          <Box p='24px'>
            <Option isSelected={selectedOption === subscriptionTypeOptions.solo.value} option={subscriptionTypeOptions.solo} onClick={optionClicked}/>
            <Gap/>
            <Option isSelected={selectedOption === subscriptionTypeOptions.team.value} option={subscriptionTypeOptions.team} onClick={optionClicked}/>
          </Box>

          <Box p='0 12px'>
            <Button variant='round-large' colorScheme='brand.midnight' w='100%' onClick={() => submitClicked()}>
              Continue
            </Button>
            <FormikError showError={!!error} errorText={error}/>
          </Box>
          <Gap s='32px'/>

        </>
      </Box>
    </>
  )
}

const Option = ( props: {
  isSelected: boolean
  option: { value: string, subscriptionType: string, icon: string },
  onClick: ( value: string ) => void
} ) => {
  const { isSelected, option, onClick } = props
  return (
    <Flex
      cursor='pointer'
      align='center'
      p='12px'
      borderRadius='8px'
      border={isSelected ? `2px solid ${COLORS.midnight_900}` : `2px solid ${COLORS.shades_neutral_200}`}
      onClick={() => onClick(option.value)}
    >
      <MaterialIcon variant='button' name={option.icon} size='50px' iconsize='32px'/>
      <Gap s='12px'/>
      <Text fontSize='14px'>{option.subscriptionType}</Text>
    </Flex>
  )
}
