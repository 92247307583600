import { Box } from '@chakra-ui/react'
import React from 'react'
import { UseViewSize } from '../../core/UseViewSize'
import { SIDENAV_WIDTH } from '../../data/constants'
import { COLORS } from '../../mini-lib/theme/colors'


export const SubSidenav = (props: { content: any }) => {
  const { content } = props
  const { showSideNavAsSheet } = UseViewSize()
  return (
    <Box position={showSideNavAsSheet ? "unset" : "fixed"} left={SIDENAV_WIDTH} top={0} zIndex='1'>
      <Box
        bg={COLORS.shades_neutral_50}
        w={SIDENAV_WIDTH}
        minH="100vh"
        h="100%"
        boxSizing="border-box"
        paddingTop={5}
      >
        <Box>
          {content}
        </Box>
      </Box>
    </Box>
  )
}