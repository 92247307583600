import { SelectInput } from "../../mini-lib";
import { keys } from "lodash";
import {
  SESSION_SORT_LABEL_MAP,
  SESSION_SORT_OPTIONS,
  SESSION_STATUS_LABEL_MAP,
  SESSION_STATUS_OPTIONS, SESSION_STYLIST_DATA_LABEL_MAP,
  SESSION_STYLIST_DATA_OPTIONS
} from "../../data/sessions/constants";
import React from "react";

export const SessionStatusFilter = (props: {
  isMobile: boolean,
  statusFilter: string | null
  setStatusFilter: (status: string) => void
}) => {
  const { isMobile, statusFilter, setStatusFilter } = props
  return (
    <SelectInput
      variant="material"
      options={keys(SESSION_STATUS_OPTIONS).map((option) => {
        return { label: SESSION_STATUS_LABEL_MAP[ option ], value: option }
      })}
      w={isMobile ? '47%' : '140px'}
      label="All Statuses"
      value={statusFilter}
      onBlur={() => {
      }}
      onChange={(val) => {
        setStatusFilter(val)
      }}
      isClearable={true}
      isSearchable={false}
      allowFocus={true}
    />
  )
}

export const SessionSortFilter = (props: {
  isMobile: boolean,
  sortFilter: string | null
  setSortFilter: (sort: string) => void
}) => {
  const { isMobile, sortFilter, setSortFilter } = props
  return (
    <SelectInput
      variant="material"
      options={keys(SESSION_SORT_OPTIONS).map((option) => {
        return { label: SESSION_SORT_LABEL_MAP[ option ], value: option }
      })}
      w={isMobile ? '47%' : '140px'}
      label="Sort"
      value={sortFilter}
      onBlur={() => {
      }}
      onChange={(val) => {
        setSortFilter(val)
      }}
      isClearable={true}
      isSearchable={false}
      allowFocus={true}
    />
  )
}

export const ShowStylistsWithNoSessions = (props: {
  isMobile: boolean
  stylistDataFilter: string
  setStylistDataFilter: (status: string) => void
}) => {
  const { setStylistDataFilter, stylistDataFilter, isMobile } = props
  return (
    <SelectInput
      variant="material"
      options={keys(SESSION_STYLIST_DATA_OPTIONS).map((option) => {
        return { label: SESSION_STYLIST_DATA_LABEL_MAP[ option ], value: option }
      })}
      w={isMobile ? '47%' : '140px'}
      label="Columns"
      value={stylistDataFilter}
      onBlur={() => {
      }}
      onChange={(val) => {
        setStylistDataFilter(val)
      }}
      isClearable={false}
      isSearchable={false}
      allowFocus={true}
    />
  )
}