import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text, Tooltip,
} from '@chakra-ui/react'
import { COLORS } from '../theme/colors'
import { MaterialIcon } from '../icons/MaterialIcon'
import { timeAgo } from '../utils/basic'
import { useDispatch, useSelector } from 'react-redux'
import {
  dispatchDeleteNotificationForUser,
  dispatchListNotificationsForUser,
  dispatchMarkAsReadNotificationForUser,
  dispatchNotificationsBulkStatusUpdateForUser,
} from '../../data/notification-user/api'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import {
  selectAllUserNotificationsById,
  selectUnreadUserNotificationsId,
  selectUnviewedNotificationsById,
} from '../../data/notification-user/slice'
import { useAppSelector } from '../../hooks'
import { Loading } from '../../mini-lib/loading/Loading'
import { selectLoadingState } from '../../core/loading/slice'
import { NOTIFICATIONS_LOADING_CONSTANT } from '../../data/notification-user/constants'
import { generatePath, useHistory } from 'react-router-dom'
import { SALONSCALE_LOGO_URL } from '../../data/constants'
import { orderBy, values } from 'lodash'
import { logMixpanelEvent } from '../../integrations/mixpanel/mixpanel'
import { MixpanelEnabled } from '../flags/Release'
import BellIcon from '../../mini-lib/icons/BellIcon'
import { ROUTES } from '../../appRoutes'

export function NotificationPopover() {
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(dispatchListNotificationsForUser({ token, salonId }))
  }, [dispatch, salonId, token])

  const unreadNotifications = useAppSelector(selectUnreadUserNotificationsId) ?? {}
  const unviewedNotifications = useAppSelector(selectUnviewedNotificationsById) ?? {}
  const unreadNotificationsCount = Object.keys(unreadNotifications).length
  const unviewedNotificationsCount = Object.keys(unviewedNotifications).length

  const hasUnreadNotificationStyle = {
    color: COLORS.lavender_500,
    background: unviewedNotificationsCount ? 'unset' : COLORS.lavender_100,
  }

  const noUnreadNotificationStyle = {
    color: COLORS.text_secondary,
    background: 'unset',
  }

  const [isOpen, setIsOpen] = React.useState(false)
  const [notificationType, setNotificationType] = React.useState<'ALL' | 'UNREAD'>('UNREAD')

  const mixpanelEnabled = MixpanelEnabled()
  const open = () => {
    logMixpanelEvent(mixpanelEnabled, 'Announcements Clicked')
    return setIsOpen(!isOpen)
  }
  const close = () => setIsOpen(false)

  return (
    <Popover isOpen={isOpen} onClose={close}>
      <PopoverTrigger>
        <Tooltip label='view salon notifications'>
          <IconButton
            _hover={{ border: `3px solid ${COLORS.lavender_200}` }}
            onClick={open}
            borderRadius="80px"
            style={unreadNotificationsCount > 0 ? hasUnreadNotificationStyle : noUnreadNotificationStyle}
            aria-label="Notification button"
            icon={<NotificationIcon />}
          />
        </Tooltip>
      </PopoverTrigger>
      <PopoverContent width="360px" marginTop="9px">
        <NotificationHeader notificationType={notificationType} setNotificationType={setNotificationType} />
        <NotificationBody notificationType={notificationType} showLearnMoreLink={false} />
      </PopoverContent>
    </Popover>
  )
}

const NotificationHeader = (props) => {
  const { notificationType, setNotificationType } = props
  const selectedStyle = {
    color: COLORS.lavender_500,
    background: COLORS.lavender_100,
    borderRadius: '80px',
    height: '27px'
  }

  const unSelectedStyle = {
    ...selectedStyle,
    background: 'unset',
    color: '#1A202C'
  }

  const dispatch = useDispatch();
  const { user: { token }, salonId } = UseBaseApiParams()

  const toggleList = (type: 'ALL' | 'UNREAD') => {
    setNotificationType(type);
  }
  const unreadNotifications = useAppSelector(selectUnreadUserNotificationsId) ?? {}
  const unreadNotificationIds = Object.keys(unreadNotifications).map(id => Number(id))

  const markAllAsRead = () => {
    unreadNotificationIds.length > 0 && dispatch(dispatchNotificationsBulkStatusUpdateForUser({ token, salonId, model: { status: 'read', id: unreadNotificationIds } }))
  }

  return (
    <PopoverHeader >
      <Text fontSize='18px' fontWeight='bold' marginBottom='1rem'>Notifications</Text>
      <Box>
        <Flex justify='space-between'>
          <Flex>
            <Button onClick={() => { toggleList('ALL') }} style={notificationType === 'ALL' ? selectedStyle : unSelectedStyle} variant='footnote'>
              <Text fontWeight='600' fontSize='15px' variant='footnote'>All</Text>
            </Button>
            <Button onClick={() => { toggleList('UNREAD') }} style={notificationType === 'UNREAD' ? selectedStyle : unSelectedStyle} variant='footnote'>
              <Text fontWeight='600' fontSize='15px' variant='footnote'>Unread</Text>
            </Button>
          </Flex>
          <Flex>
            <Button onClick={markAllAsRead} padding='unset' style={unSelectedStyle} variant='footnote'>
              <Text fontWeight='600' fontSize='15px' color={COLORS.lavender_500} variant='footnote'>Mark all as read</Text>
            </Button>
          </Flex>
        </Flex>
      </Box>
    </PopoverHeader>
  )
}

const NotificationBody = (props: { notificationType: 'ALL' | 'UNREAD', showLearnMoreLink?: boolean }) => {
  const allNotifications = useAppSelector(selectAllUserNotificationsById) ?? {}
  const unreadNotifications = useSelector(selectUnreadUserNotificationsId) ?? {}
  const loadingNotifications = useAppSelector((state) => selectLoadingState(state, NOTIFICATIONS_LOADING_CONSTANT))
  const renderNotifications = props.notificationType === 'ALL' ? allNotifications : unreadNotifications
  const notificationsList = orderBy(values(renderNotifications), ['visibleDate'], ['desc'])
  return (
    <PopoverBody height='350px' overflowY='scroll' padding='unset'>
      {loadingNotifications && <Loading />}
      {!loadingNotifications && notificationsList.length > 0 && notificationsList.map((notification) => {
        const { id, title, description, visibleDate, read, url, avatarUrl } = notification
        return (
          <React.Fragment key={id}>
            <NotificationRow
              id={id}
              title={title}
              description={description}
              visibleDate={visibleDate}
              read={Boolean(read)}
              url={url}
              avatarUrl={avatarUrl}
              showActionMenu
              showLearnMoreLink={props.showLearnMoreLink}
            />
          </React.Fragment>
        )
      })}
      {!loadingNotifications && notificationsList.length === 0 && <NoNotifications />}
    </PopoverBody>
  )
}

export const NotificationRow = (props: {
  id: number,
  title: string,
  description: string | null,
  visibleDate?: Date,
  read: boolean,
  url: string | null,
  avatarUrl: string | null,
  showActionMenu?: boolean,
  additionalStyles?: any
  openOnClick?: boolean
  showLearnMoreLink?: boolean
}) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const { user: { token }, salonId } = UseBaseApiParams()
  const { showLearnMoreLink = true, showActionMenu = false, title, id, avatarUrl, url, description = '', visibleDate, read, additionalStyles, openOnClick = true } = props
  const notificationRecieved = timeAgo(visibleDate ?? new Date())
  const textStyles: any = {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'break-spaces'
  }

  const markAsRead = (e) => {
    e.stopPropagation()
    !read && dispatch(dispatchMarkAsReadNotificationForUser({ token, salonId, notificationId: id, read: true }))
  }
  const openNotification = (e) => {
    if (openOnClick) {
      history.push(generatePath(ROUTES.notifications, { salonId, notificationId: id }))
      markAsRead(e)
    }
  }
  return (<Box onClick={openNotification} padding='0px 1rem !important' background={read ? 'unset' : COLORS.lavender_100} key={id} css={{ ...additionalStyles }}>
    <Box h='12px' />
    <Flex justify='space-between' align='flex-start'>
      <NotificationAvatar avatarUrl={avatarUrl} />
      <Box minWidth = "215"width='215px'>
        <Text variant='body' fontWeight='bold' css={{ ...textStyles }}>{title}</Text>
        <Text variant='body' color={COLORS.text_secondary} css={{ ...textStyles }}>{description}</Text>
        {showLearnMoreLink && url && <LearnMore url={url} read={read} notificationId={id} />}
      </Box>
      <Flex align="flex-end" direction='column'>
        <Text whiteSpace='nowrap' fontWeight='600' fontSize='12px' color={read ? COLORS.text_secondary : COLORS.lavender_500} >{notificationRecieved}</Text>
        {showActionMenu && <NotificationActionMenu notificationId={id} read={read} />}
      </Flex>
    </Flex>
    <Box h='12px' />
    <Divider />
  </Box>)
}

const NoNotifications = () => (
  <Flex marginTop='1rem' align='center' direction='column' >
    <MaterialIcon color={COLORS.lavender_500} name='notifications' size='32px' />
    <Text color={COLORS.lavender_500} marginTop='1rem' align='center' fontSize='15px'>You have no notifications</Text>
  </Flex>
)

const NotificationActionMenu = (props: { notificationId: number, read: boolean }) => {
  const { notificationId, read } = props
  const dispatch = useDispatch();
  const { user: { token }, salonId } = UseBaseApiParams()
  const markAsRead = (e) => {
    e.stopPropagation()
    dispatch(dispatchMarkAsReadNotificationForUser({ token, salonId, notificationId, read: !read }))
  }
  const deleteAnnouncement = (e) => {
    e.stopPropagation()
    dispatch(dispatchDeleteNotificationForUser({ token, salonId, notificationId }))
  }
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label='Options'
        icon={<MaterialIcon name='more_horiz' />}
        variant='ghost'
        justifyContent="flex-end"
        marginRight="-3px"
        onClick={(e) => { e.stopPropagation() }}
        _hover={{ bg: 'none' }}
        _active={{ bg: 'none' }}
        _focus={{ boxShadow: 'none' }}
      />
      <MenuList>
        <MenuItem
          icon={<MaterialIcon name='check' />}
          onClick={markAsRead}
        >
          {`Mark as ${read ? 'Unread' : 'Read'}`}
        </MenuItem>
        <MenuDivider />
        <MenuItem
          icon={<MaterialIcon name='delete' />}
          onClick={deleteAnnouncement}
        >
          Delete Notification
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

const NotificationIcon = () => {
  const dispatch = useDispatch();
  const { user: { token }, salonId } = UseBaseApiParams()
  const countStyle = {
    top: '0px',
    right: '0px',
    border: '1px solid white',
    backgroundColor: COLORS.lavender_500,
    color: 'white',
    fontSize: '10px',
    borderRadius: '80px',
    variant: 'footnote',
    width: '19px',
    height: '19px',
    paddingTop: '2px'
  }
  const unviewedNotifications = useAppSelector(selectUnviewedNotificationsById) ?? {}
  const unreadNotifications = useAppSelector(selectUnreadUserNotificationsId) ?? {}
  const unViewedNotficationIds = Object.keys(unviewedNotifications).map(id => Number(id))
  const unviewedNotficationCount = Object.keys(unviewedNotifications).length
  const unreadNotificationsCount = Object.keys(unreadNotifications).length

  const markAllAsViewed = () => {
    unViewedNotficationIds.length > 0 && dispatch(dispatchNotificationsBulkStatusUpdateForUser({ token, salonId, model: { status: 'viewed', id: unViewedNotficationIds } }))
  }
  return (
    <div onClick={markAllAsViewed}>
      {!unviewedNotficationCount && unreadNotificationsCount ? 
      <MaterialIcon name='notifications' /> : <BellIcon />}
      {Boolean(unviewedNotficationCount) && <Text position='absolute' fontWeight='700' style={countStyle} >
        {unviewedNotficationCount}
      </Text>}
    </div>
  )

}

export const NotificationAvatar = ({ avatarUrl }: { avatarUrl: string | null }) => (
  <Circle size='40px' bg='brand.lavender.500'>
    <img
      alt='notification_avatar'
      style={{ height: '100%', width: '100%', padding: '6px' }}
      src={avatarUrl ?? SALONSCALE_LOGO_URL}
      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = SALONSCALE_LOGO_URL;
      }}
    />
  </Circle>
)

export const LearnMore = ({ url, read, notificationId }: { url: string, read: boolean, notificationId: number }) => {
  const dispatch = useDispatch();
  const { user: { token }, salonId } = UseBaseApiParams()
  const markAsRead = (e) => {
    e.stopPropagation()
    !read && dispatch(dispatchMarkAsReadNotificationForUser({ token, salonId, notificationId, read: true }))
  }

  return (<Flex marginTop={'1rem'}>
    <MaterialIcon color={COLORS.lavender_500} marginRight='5px' name='open_in_new' size='16px' />
    <Link fontSize='14px' lineHeight='1.2' color={COLORS.lavender_500} onClick={markAsRead} href={generatePath(url)} isExternal>Learn More</Link>
  </Flex>)
} 
