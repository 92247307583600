
export interface AdminColumn {
  key: string, header: string, description: string
}

export interface AdminSubscriptionAnalyticsRow {
  date_range: string
  total_active_subscriptions: number | string
  total_solo_monthly_subscriptions: number | string
  total_solo_yearly_subscriptions: number | string
  total_team_monthly_subscriptions: number | string
  total_team_yearly_subscriptions: number | string
  total_new_subscriptions: number | string
  total_trial_conversions: number | string
  total_subscription_churn: number | string
  total_trial_churn: number | string
  total_salons_with_min_sessions: number | string
  total_sessions: number | string
  new_solo_monthly_trials: number | string
  new_solo_yearly_subscriptions: number | string
  new_solo_monthly_trial_conversions: number | string
  new_solo_monthly_trial_churn: number | string
  total_existing_solo_monthly_churn: number | string
  total_existing_solo_yearly_churn: number | string
  new_team_monthly_trials: number | string
  new_team_yearly_subscriptions: number | string
  new_team_monthly_trial_conversions: number | string
  new_team_monthly_trial_churn: number | string
  total_existing_team_monthly_churn: number | string
  total_existing_team_yearly_churn: number | string
}

export interface AdminUserAnalyticsRow {
  created_at: number | string
  user_id: number | string
  salon_id: number | string
  chargebee_customer_link: any
  chargebee_subscription_link: any
  admin_user_link: any
  admin_salon_link: any
  first_name: number | string
  last_name: number | string
  email: number | string
  phone: number | string
  status: number | string
  plan_id: number | string
  plan_amount: number | string
  salon_name: number | string
}

export const ALL_INTERVALS = [
  '1 day',
  '1 week',
  '1 month',
  '1 year'
]

export const INTERVAL_STRING_TO_NUMBER_MAP = {
  '1 day': 1,
  '1 week': 7,
  '1 month': 30,
  '1 year': 365,
}
