import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import { selectChecklistItem } from '../../../data/start-guide/slice'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { generatePath } from 'react-router-dom'
import { sumBy } from 'lodash'
import { Box } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { StepContent, StepHeader } from './SGIndexPage'
import { ROUTES } from "../../../appRoutes";

export const InventorySteps = (props: { isDefaultOpen: boolean }) => {
  const { isDefaultOpen } = props
  const { salonId } = UseBaseApiParams()
  const [expanded, setExpanded] = useState(isDefaultOpen)

  const intro = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.inventoryIntroduction))
  const targets = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.inventorySetTargets))
  const onHand = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.inventorySetOnHand))
  const stockAlerts = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.inventoryStockAlerts))
  const orders = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.inventoryOrders))
  const addingStock = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.inventoryAddingStock))

  const steps = [
    {
      label: 'Introduction to Inventory',
      complete: intro.completed,
      link: generatePath(ROUTES.sgInventoryIntro, { salonId }),
    },
    {
      label: 'Set Your Backbar Targets',
      complete: targets.completed,
      link: generatePath(ROUTES.sgInventoryTargetsIntro, { salonId }),
    },
    {
      label: 'Set Your Backbar On-Hand',
      complete: onHand.completed,
      link: generatePath(ROUTES.sgInventoryOnHandIntro, { salonId }),
    },
    {
      label: 'Stock Alerts',
      complete: stockAlerts.completed,
      link: generatePath(ROUTES.sgInventoryStockAlertsIntro, { salonId }),
    },
    {
      label: 'Orders',
      complete: orders.completed,
      link: generatePath(ROUTES.sgInventoryOrdersIntro, { salonId }),
    },
    {
      label: 'Adding Stock',
      complete: addingStock.completed,
      link: generatePath(ROUTES.sgInventoryAddingStockIntro, { salonId }),
    },
  ]

  const totalSteps = steps.length
  const stepsComplete = sumBy(steps, 'complete')

  return (
    <Box border={`1px solid ${COLORS.shades_neutral_300}`} borderRadius="15px">
      <StepHeader
        iconName="implementation"
        label="Set Up Inventory"
        setExpanded={setExpanded}
        expanded={expanded}
        stepsComplete={stepsComplete}
        totalSteps={totalSteps}
      />
      {expanded && <StepContent steps={steps} />}
    </Box>
  )
}

export const sgInventoryStepCodes = [
  CHECKLIST_CODES.inventoryIntroduction,
  CHECKLIST_CODES.inventorySetTargets,
  CHECKLIST_CODES.inventorySetOnHand,
  CHECKLIST_CODES.inventoryStockAlerts,
  CHECKLIST_CODES.inventoryOrders,
  CHECKLIST_CODES.inventoryAddingStock,
]
