import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  Text,
} from '@chakra-ui/react'
import { ColorForMode } from '../../../../theme'
import { UseViewSize } from '../../../../core/UseViewSize'
import { assign, find, includes, keyBy, keys, map, uniq, xor } from 'lodash'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { MaterialIcon } from '../../../../mini-lib/icons/MaterialIcon'
import {
  dispatchCreateLaborServices,
  dispatchCreateLaborServicesAndLaborTierServices,
  dispatchCreateLaborTiers,
  dispatchListLaborTiers,
} from '../../../../data/labor/api'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../../core/UseBaseApiParams'
import {
  APILaborServiceCreate,
  APILaborTierCreate,
  LABOR_SERVICE_TYPES,
  LaborServiceOption,
} from '../../../../data/labor/interfaces'
import { capitalCase } from '../../../../mini-lib/utils/basic'
import { useAppSelector } from '../../../../hooks'
import { selectLaborTierList } from '../../../../data/labor/slice'
import { EditableButton } from '../../../../mini-lib/button/EditableButton'
import { DEFAULT_CATEGORY_NAME, DEFAULT_TIER_NAME, initialServiceOptions } from '../../../../data/labor/constants'
import { stringNumberSort } from '../../../../mini-lib/utils/sorting'
import { ROUTES } from "../../../../appRoutes";

export const Pages = {
  howDoYouCharge: 'howDoYouCharge',
  selectServicesFlat: 'selectServicesFlat',
  selectServicesHybridFlat: 'selectServicesHybridFlat',
  selectServicesHourly: 'selectServicesHourly',
  doYouUseTiers: 'doYouUseTiers',
  selectTiers: 'selectTiers',
  pricePerHour: 'pricePerHour',
}

export const LaborOnboard = (props: {}) => {
  const [page, setPage] = useState(Pages.howDoYouCharge)
  // this sets an optional 'nextPage' for more complicated paths
  const [shouldFinish, setShouldFinish] = useState(false)
  if (page === Pages.howDoYouCharge) {
    return <HowDoYouCharge setPage={setPage} />
  }
  if (page === Pages.selectServicesFlat) {
    return <SelectFlatServices setPage={setPage} setShouldFinish={setShouldFinish} />
  }
  if (page === Pages.selectServicesHybridFlat) {
    return <HybridFlatServices setPage={setPage} />
  }
  if (page === Pages.doYouUseTiers) {
    return <DoYouUseTiers setPage={setPage} />
  }
  if (page === Pages.selectTiers) {
    return <SelectTiers setPage={setPage} shouldFinish={shouldFinish} />
  }
  if (page === Pages.pricePerHour) {
    return <PricePerHour />
  }
  return <HowDoYouCharge setPage={setPage} />
}

export const HowDoYouCharge = (props: { setPage: (string) => void }) => {
  const options = {
    flat: 'flat',
    hourly: 'hourly',
    both: 'both',
  }
  const { setPage } = props
  const { isMobile } = UseViewSize()
  const [selected, setSelected] = useState(options.flat)

  const goToNextPage = () => {
    if (selected === options.flat) {
      setPage(Pages.selectServicesFlat)
    } else if (selected === options.hourly) {
      setPage(Pages.doYouUseTiers)
    } else if (selected === options.both) {
      setPage(Pages.selectServicesHybridFlat)
    }
  }
  return (
    <Flex direction="column" justify="center" align="center">
      <Box h={isMobile ? '24px' : '72px'} />
      <Text variant="title1" fontWeight="bold">
        HOW DO YOU CHARGE FOR LABOR?
      </Text>

      <Box h="24px" />
      <Button
        w="600px"
        maxW="100%"
        colorScheme="brand.midnight"
        variant={selected === options.flat ? 'round' : 'round-outline'}
        onClick={() => setSelected(options.flat)}
      >
        SERVICE FEE
      </Button>
      <Box h="24px" />
      <Button
        w="600px"
        maxW="100%"
        variant={selected === options.hourly ? 'round' : 'round-outline'}
        colorScheme="brand.midnight"
        onClick={() => setSelected(options.hourly)}
      >
        HOURLY RATE
      </Button>
      <Box h="24px" />
      <Button
        w="600px"
        maxW="100%"
        variant={selected === options.both ? 'round' : 'round-outline'}
        colorScheme="brand.midnight"
        onClick={() => setSelected(options.both)}
      >
        SOME OF EACH
      </Button>
      <Box h="72px" />
      <Flex justify="flex-end" maxW="100%" w="600px">
        <Button variant="round" onClick={goToNextPage} w="150px">
          NEXT
        </Button>
      </Flex>
    </Flex>
  )
}

export const SelectFlatServices = (props: { setPage: (string) => void; setShouldFinish: (boolean) => void }) => {
  const dispatch = useDispatch()
  const {
    user: { token, userId },
    salonId,
  } = UseBaseApiParams()
  const { setPage, setShouldFinish } = props
  const { isMobile } = UseViewSize()
  const st = ColorForMode('secondary-text')

  const [options, setOptions] = useState<LaborServiceOption[]>(initialServiceOptions)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = useState('')

  const createOption = () => {
    const newOption = { id: options.length + 1, label: 'NEW SERVICE' }
    setOptions([...options, newOption])
    addOrRemoveOption('add', newOption)
  }
  const addOrRemoveOption = (action: 'add' | 'remove', option: LaborServiceOption) => {
    if (action === 'remove') {
      const updatedList = xor(selectedIds, [option.id])
      setSelectedIds(updatedList)
    } else {
      setSelectedIds([...selectedIds, option.id])
    }
  }
  const updateOption = (id: number, updatedLabel: string) => {
    const updated = { id: id, label: updatedLabel }
    const updatedOptions = map(options, (o) => (o.id === id ? updated : o))
    setOptions(updatedOptions)
  }
  const isSelected = (option: LaborServiceOption) => {
    return includes(selectedIds, option.id)
  }
  const onNext = () => {
    if (selectedIds.length === 0) {
      setError('* Please select at least one service')
      return
    }
    const services: APILaborServiceCreate[] = uniq(selectedIds).map((id) => {
      const option: LaborServiceOption | any = find(options, (o) => o.id === id)
      return { type: LABOR_SERVICE_TYPES.service, name: capitalCase(option.label), category: DEFAULT_CATEGORY_NAME }
    })
    dispatch(
      dispatchCreateLaborServices({
        token: token,
        userId: userId,
        salonId: salonId,
        models: services,
      }),
    )
    setSelectedIds([])
    setShouldFinish(true)
    setPage(Pages.doYouUseTiers)
  }

  return (
    <Flex direction="column" justify="center" align="center">
      <Box h={isMobile ? '24px' : '72px'} />
      <Text variant="title1" fontWeight="bold">
        WHAT SERVICES DO YOU PROVIDE?
      </Text>
      <Text color={st} maxW="100%" w="640px">
        Select services you provide in your salon from the list below or click the plus to add your own services.
      </Text>
      <Box h="24px" />
      <Grid
        templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'}
        width="700px"
        maxWidth="100%"
        gridGap="12px"
      >
        {options.map((option) => {
          const selected = isSelected(option)
          const onRemove = (e) => {
            e.stopPropagation()
            addOrRemoveOption('remove', option)
          }
          return (
            <GridItem key={option.id}>
              <EditableButton
                isSelected={selected}
                defaultValue={option.label}
                onClick={() => addOrRemoveOption('add', option)}
                onUpdate={(updatedLabel) => updateOption(option.id, updatedLabel)}
                onRemove={onRemove}
              />
            </GridItem>
          )
        })}
      </Grid>
      <Flex justify="flex-end" maxW="100%" w="700px" mt="12px">
        <Button variant="ghost" w="150px" onClick={createOption}>
          <MaterialIcon name="add_circle" style={{ marginRight: '4px' }} /> add new item
        </Button>
      </Flex>
      <Box h="24px" />
      <Flex h="24px" justify="flex-end" maxW="100%" w="700px" color="danger">
        {error}
      </Flex>
      <Box h="12px" />
      <Flex justify="flex-end" maxW="100%" w="700px">
        <Button variant="round" w="150px" onClick={onNext}>
          NEXT
        </Button>
      </Flex>
    </Flex>
  )
}

export const HybridFlatServices = (props: { setPage: (string) => void }) => {
  const dispatch = useDispatch()
  const {
    user: { token, userId },
    salonId,
  } = UseBaseApiParams()
  const { setPage } = props
  const { isMobile } = UseViewSize()
  const st = ColorForMode('secondary-text')

  const [options, setOptions] = useState<LaborServiceOption[]>(initialServiceOptions)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = useState('')

  const createOption = () => {
    const newOption = { id: options.length + 1, label: 'NEW SERVICE' }
    setOptions([...options, newOption])
    addOrRemoveOption('add', newOption)
  }
  const addOrRemoveOption = (action: 'add' | 'remove', option: LaborServiceOption) => {
    if (action === 'remove') {
      const updatedList = xor(selectedIds, [option.id])
      setSelectedIds(updatedList)
    } else {
      setSelectedIds([...selectedIds, option.id])
    }
  }
  const updateOption = (id: number, updatedLabel: string) => {
    const updated = { id: id, label: updatedLabel }
    const updatedOptions = map(options, (o) => (o.id === id ? updated : o))
    setOptions(updatedOptions)
  }
  const isSelected = (option: LaborServiceOption) => {
    return includes(selectedIds, option.id)
  }
  const onNext = () => {
    if (selectedIds.length === 0) {
      setError('* Please select at least one service')
      return
    }
    const services: APILaborServiceCreate[] = uniq(selectedIds).map((id) => {
      const option: LaborServiceOption | any = find(options, (o) => o.id === id)
      return { type: LABOR_SERVICE_TYPES.service, name: capitalCase(option.label), category: DEFAULT_CATEGORY_NAME }
    })
    dispatch(
      dispatchCreateLaborServices({
        token: token,
        userId: userId,
        salonId: salonId,
        models: services,
      }),
    )
    setSelectedIds([])
    setPage(Pages.doYouUseTiers)
  }

  return (
    <Flex direction="column" justify="center" align="center">
      <Box h={isMobile ? '24px' : '72px'} />
      <Text variant="title1" fontWeight="bold" textTransform="uppercase">
        What services Do you provide that you charge by the service?
      </Text>
      <Box h="24px" />
      <Text color={st} maxW="100%" w="640px">
        Select services you provide in your salon from the list below or click the plus to add your own services. You
        will add services by the hour in a few more steps.
      </Text>
      <Box h="72px" />
      <Grid
        templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'}
        width="700px"
        maxWidth="100%"
        gridGap="12px"
      >
        {options.map((option) => {
          const selected = isSelected(option)
          const onRemove = (e) => {
            e.stopPropagation()
            addOrRemoveOption('remove', option)
          }
          return (
            <GridItem key={option.id}>
              <EditableButton
                isSelected={selected}
                defaultValue={option.label}
                onClick={() => addOrRemoveOption('add', option)}
                onUpdate={(updatedLabel) => updateOption(option.id, updatedLabel)}
                onRemove={onRemove}
              />
            </GridItem>
          )
        })}
      </Grid>
      <Flex justify="flex-end" maxW="100%" w="700px" mt="12px">
        <Button variant="ghost" w="150px" onClick={createOption}>
          <MaterialIcon name="add_circle" style={{ marginRight: '4px' }} /> add new item
        </Button>
      </Flex>
      <Box h="24px" />
      <Flex h="24px" justify="flex-end" maxW="100%" w="700px" color="danger">
        {error}
      </Flex>
      <Box h="12px" />
      <Flex justify="flex-end" maxW="100%" w="700px">
        <Button variant="round" w="150px" onClick={onNext}>
          NEXT
        </Button>
      </Flex>
    </Flex>
  )
}

export const SelectHourlyServices = (props: {
  error: string
  setError: (string) => void
  onCreateServices: (services: APILaborServiceCreate[]) => void
}) => {
  const st = ColorForMode('secondary-text')
  const { error, setError, onCreateServices } = props
  const { isMobile } = UseViewSize()

  const initialMap: { [key: number]: LaborServiceOption } = keyBy(initialServiceOptions, 'id')

  const [optionMap, setOptionMap] = useState<{ [key: number]: LaborServiceOption }>(initialMap)
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const createOption = () => {
    const newKey = keys(optionMap).length + 1
    const newOption = { id: newKey, label: 'NEW SERVICE' }
    const updatedMap = assign({}, optionMap, { [newKey]: newOption })
    setOptionMap(updatedMap)
    addOrRemoveOption('add', newOption)
  }
  const addOrRemoveOption = (action: 'add' | 'remove', option: LaborServiceOption) => {
    if (action === 'remove') {
      const updatedList = xor(selectedIds, [option.id])
      setSelectedIds(updatedList)
    } else {
      setSelectedIds(uniq([...selectedIds, option.id]))
    }
  }
  const updateOption = (id: number, updatedLabel: string) => {
    const updated = { id: id, label: updatedLabel }
    const updatedMap = assign({}, optionMap, { [id]: updated })
    setOptionMap(updatedMap)
  }
  const isSelected = (option: LaborServiceOption) => {
    return includes(selectedIds, option.id)
  }
  const onNext = () => {
    if (selectedIds.length === 0) {
      setError('* Please select at least one service')
      return
    }
    const services: APILaborServiceCreate[] = uniq(selectedIds).map((id) => {
      const option: LaborServiceOption | any = optionMap[id]
      return { type: LABOR_SERVICE_TYPES.hourly, name: capitalCase(option.label), category: DEFAULT_CATEGORY_NAME }
    })
    onCreateServices(services)
    setSelectedIds([])
  }

  return (
    <Flex direction="column" justify="center" align="center">
      <Box h="48px" />
      <Text variant="title1" fontWeight="bold" textTransform="uppercase">
        Add your services that are priced per hour
      </Text>
      <Box h="24px" />
      <Text color={st} maxW="100%" w="640px">
        Tap at least one of the items below to add to your service menu or add your own by clicking the + button.
      </Text>
      <Box h="48px" />
      <Grid
        templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'}
        width="700px"
        maxWidth="100%"
        gridGap="12px"
      >
        {keys(optionMap).map((key) => {
          const option = optionMap[key]
          const selected = isSelected(option)
          const onRemove = (e) => {
            e.stopPropagation()
            addOrRemoveOption('remove', option)
          }
          return (
            <GridItem key={option.id}>
              <EditableButton
                isSelected={selected}
                defaultValue={option.label}
                onClick={() => addOrRemoveOption('add', option)}
                onUpdate={(updatedLabel) => updateOption(option.id, updatedLabel)}
                onRemove={onRemove}
              />
            </GridItem>
          )
        })}
      </Grid>
      <Flex justify="flex-end" maxW="100%" w="700px" mt="12px">
        <Button variant="ghost" w="150px" onClick={createOption}>
          <MaterialIcon name="add_circle" style={{ marginRight: '4px' }} /> add new item
        </Button>
      </Flex>
      <Box h="24px" />
      <Flex h="24px" justify="flex-end" maxW="100%" w="700px" color="danger">
        {error}
      </Flex>
      <Box h="12px" />
      <Flex justify="flex-end" maxW="100%" w="700px">
        <Button variant="round" w="150px" onClick={onNext}>
          NEXT
        </Button>
      </Flex>
    </Flex>
  )
}

export const DoYouUseTiers = (props: { setPage: (string) => void }) => {
  const options = {
    yes: 'yes',
    no: 'no',
  }
  const { setPage } = props
  const { isMobile } = UseViewSize()
  const [selected, setSelected] = useState(options.yes)
  const history = useHistory()
  const params: any = useParams()
  const { salonId } = params

  const onNext = () => {
    if (selected === options.yes) {
      setPage(Pages.selectTiers)
    } else if (selected === options.no) {
      const url = `${generatePath(ROUTES.labor, { salonId })}?showTable=true`
      history.push(url)
    }
  }
  return (
    <Flex direction="column" justify="center" align="center">
      <Box h={isMobile ? '24px' : '72px'} />
      <Text variant="title1" fontWeight="bold">
        DO YOU USE A PRICING LEVEL SYSTEM?
      </Text>

      <Box h="24px" />
      <Button
        w="600px"
        maxW="100%"
        colorScheme="brand.midnight"
        variant={selected === options.yes ? 'round' : 'round-outline'}
        onClick={() => setSelected(options.yes)}
      >
        YES
      </Button>
      <Box h="24px" />
      <Button
        w="600px"
        maxW="100%"
        variant={selected === options.no ? 'round' : 'round-outline'}
        colorScheme="brand.midnight"
        onClick={() => setSelected(options.no)}
      >
        NO
      </Button>
      <Box h="72px" />
      <Flex justify="flex-end" maxW="100%" w="600px">
        <Button variant="round" onClick={onNext} w="150px">
          NEXT
        </Button>
      </Flex>
    </Flex>
  )
}

export const SelectTiers = (props: { shouldFinish: boolean; setPage: (string) => void }) => {
  const { setPage, shouldFinish } = props

  interface TierOption {
    id: number
    label: string
  }

  const dispatch = useDispatch()
  const {
    user: { token, userId },
    salonId,
  } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  const initialOptions = [
    { id: 1, label: 'LEVEL ONE' },
    { id: 2, label: 'LEVEL TWO' },
    { id: 3, label: 'LEVEL THREE' },
  ]
  const history = useHistory()
  const [options, setOptions] = useState<TierOption[]>(initialOptions)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = useState('')

  const addOrRemoveOption = (action: 'add' | 'remove', option: TierOption) => {
    if (action === 'remove') {
      const updatedList = xor(selectedIds, [option.id])
      setSelectedIds(updatedList)
    } else {
      setSelectedIds([...selectedIds, option.id])
    }
  }
  const isSelected = (option: TierOption) => {
    return includes(selectedIds, option.id)
  }
  const createOption = () => {
    const newOption = { id: options.length + 1, label: 'NEW LEVEL' }
    setOptions([...options, newOption])
    addOrRemoveOption('add', newOption)
  }
  const updateOption = (id: number, updatedLabel: string) => {
    const updated = { id: id, label: updatedLabel }
    const updatedOptions = map(options, (o) => (o.id === id ? updated : o))
    setOptions(updatedOptions)
  }
  const onNext = () => {
    if (selectedIds.length === 0) {
      setError('* Please select at least one level')
      return
    }
    const tiers: APILaborTierCreate[] = uniq(selectedIds).map((id) => {
      const option: TierOption | any = find(options, (o) => o.id === id)
      return { name: capitalCase(option.label) }
    })
    dispatch(
      dispatchCreateLaborTiers({
        token: token,
        userId: userId,
        salonId: salonId,
        models: tiers,
      }),
    )
    setSelectedIds([])
    if (shouldFinish) {
      history.push(generatePath(ROUTES.labor, { salonId }))
    } else {
      setPage(Pages.pricePerHour)
    }
  }

  return (
    <Flex direction="column" justify="center" align="center">
      <Box h={isMobile ? '24px' : '72px'} />
      <Text variant="title1" fontWeight="bold">
        ADD AND LABEL YOUR LEVELS
      </Text>
      <Box h="24px" />
      <Flex justify="space-between" width="600px" maxWidth="100%" wrap="wrap" gridGap="12px">
        {options.map((option) => {
          const selected = isSelected(option)
          const onRemove = (e) => {
            e.stopPropagation()
            addOrRemoveOption('remove', option)
          }
          return (
            <EditableButton
              key={option.id}
              width="100%"
              isSelected={selected}
              defaultValue={option.label}
              onClick={() => addOrRemoveOption('add', option)}
              onUpdate={(updatedLabel) => updateOption(option.id, updatedLabel)}
              onRemove={onRemove}
            />
          )
        })}
      </Flex>
      <Flex justify="flex-end" maxW="100%" w="600px" mt="12px">
        <Button variant="ghost" w="150px" onClick={createOption}>
          <MaterialIcon name="add_circle" style={{ marginRight: '4px' }} /> add new item
        </Button>
      </Flex>
      <Box h="24px" />
      <Flex h="24px" justify="flex-end" maxW="100%" w="600px" color="danger">
        {error}
      </Flex>
      <Box h="12px" />
      <Flex justify="flex-end" maxW="100%" w="600px" gridGap="24px">
        <Button variant="round" w="150px" onClick={onNext}>
          NEXT
        </Button>
      </Flex>
    </Flex>
  )
}

export const PricePerHour = (props: {}) => {
  type PriceMap = { [key: string]: number }
  const initialPriceMap: { [key: string]: number } = {}

  const dispatch = useDispatch()
  const {
    user: { token, userId },
    salonId,
  } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  const history = useHistory()
  const tiers = useAppSelector(selectLaborTierList)

  const [priceMap, setPriceMap] = useState<PriceMap>(initialPriceMap)
  const [error, setError] = useState('')

  const updateTierPrice = (tierId: number, updatedPrice: string) => {
    const updatedMap = assign({}, priceMap, { [tierId]: updatedPrice })
    setPriceMap(updatedMap)
  }
  const onCreateServices = (services: APILaborServiceCreate[]) => {
    if (!tiers || keys(priceMap).length === 0) {
      setError('* Please set a price for at least one tier')
      return
    }
    dispatch(
      dispatchCreateLaborServicesAndLaborTierServices({
        token: token,
        userId: userId,
        salonId: salonId,
        tiers: tiers ? tiers : [],
        services,
        tierPriceMap: priceMap,
      }),
    )
    history.push(generatePath(ROUTES.labor, { salonId }))
  }
  const tiersLoaded = !!tiers
  useEffect(() => {
    if (!tiersLoaded) {
      dispatch(dispatchListLaborTiers({token, salonId}))
    }
  }, [dispatch, tiersLoaded, token, salonId])

  const colorScheme = 'midnight'
  return (
    <>
      <Flex direction="column" justify="center" align="center">
        <Box h={isMobile ? '24px' : '72px'} />
        <Flex justify="center" width="600px" maxWidth="100%" wrap="wrap" gridGap="12px">
          {tiers &&
            stringNumberSort(tiers, 'name', DEFAULT_TIER_NAME).map((tier) => {
              const val = priceMap[tier.id] ? priceMap[tier.id] : 0
              return (
                <Flex key={tier.id} direction="column" justify="center" align="center">
                  {tier.name}
                  <NumberInput
                    onChange={(updatedPrice) => updateTierPrice(tier.id, updatedPrice)}
                    defaultValue={val}
                    precision={2}
                    step={0.2}
                  >
                    <InputLeftElement
                      h="100%"
                      fontSize="26px"
                      pointerEvents="none"
                      color={`${colorScheme}.500`}
                      children="$"
                    />
                    <NumberInputField
                      alignContent="center"
                      p="12px 12px 12px 30px"
                      h="45px"
                      w="100px"
                      borderRadius="50px"
                      border="1px solid"
                      borderColor={`${colorScheme}.500`}
                      color={`${colorScheme}.500`}
                      fontSize="26px"
                      fontFamily="Oswald, sans-serif"
                    />
                  </NumberInput>
                </Flex>
              )
            })}
        </Flex>
      </Flex>
      <SelectHourlyServices setError={setError} error={error} onCreateServices={onCreateServices} />
    </>
  )
}
