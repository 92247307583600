import { Box, Button, Flex, Image, Modal, ModalBody, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import { Gap } from "../../mini-lib/gap/Gap";
import { UseViewSize } from "../../core/UseViewSize";
import appleQrCode from '../../assets/trial/apple-qr-code.png'
import androidQrCode from '../../assets/trial/android-qr-code.png'
import React, { useState } from "react";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { useDispatch } from "react-redux";
import { dispatchUpdateUserChecklistItem } from "../../data/start-guide/api";
import { CHECKLIST_CODES } from "../../data/start-guide/constants";

export const TrialQrCodesButtonAndModal = () => {
  const [showModal, setShowModal] = useState(false)
  const { isMobile } = UseViewSize()
  const {user} = UseBaseApiParams()
  const dispatch = useDispatch()
  return (
    <>
      <Button
        w='100%'
        variant='round'
        colorScheme='brand.lavender'
        onClick={() => {
          setShowModal(true)
          dispatch(dispatchUpdateUserChecklistItem({ token: user.token, checklistItemCode: CHECKLIST_CODES.trialDownloadApp}))
        }}
      >
        Download the mobile app
      </Button>
      {showModal && (
        <Modal onClose={() => setShowModal(false)} isOpen={showModal} isCentered={true}>
          <ModalOverlay/>
          <ModalContent m="12px">
            <ModalBody>
              <Gap/>
              <Text>Download the SalonScale Mobile App for iOS or Android</Text>
              <Gap/>
              <Flex wrap={isMobile ? 'wrap' :  'nowrap' } gridGap='24px' >
                <Apple/>
                <Android/>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
export const Apple = ( props: {} ) => {
  const { isMobile } = UseViewSize()
  return (
    <Flex
      w={'650px'}
      direction="column"
      align="center"
    >
      <Image src={appleQrCode} h={isMobile ? '100px' : '140px'}/>
      <Gap/>
      <Box>
        <a target="_blank" href="https://apps.apple.com/ca/app/salonscale/id1499547297" rel="noreferrer">
          <img
            alt="logo"
            height="140px"
            width="140px"
            src={
              'https://firebasestorage.googleapis.com/v0/b/salonscale.appspot.com/o/images%2Fapple-badge.png?alt=media&token=daefa700-8125-45b2-82d1-525a67581b94'
            }
          ></img>
        </a>
      </Box>
      <Gap/>
    </Flex>
  )
}


export const Android = ( props: {} ) => {
  const { isMobile } = UseViewSize()
  return (
    <Flex
      w={'650px'}
      direction="column"
      align="center"
    >
      <Image src={androidQrCode} h={isMobile ? '100px' : '140px'}/>
      <Gap s='14px'/>
      <Box>
        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.salonscale.salonscale" rel="noreferrer">
          <img
            alt="logo"
            height="170px"
            width="170px"
            src={
              'https://firebasestorage.googleapis.com/v0/b/salonscale.appspot.com/o/images%2Fgoogle-play-badge.png?alt=media&token=171381dd-a6b0-4f0e-a1a2-995afddfc1c1'
            }
          ></img>
        </a>
      </Box>
    </Flex>
  )
}

export const AndroidQR = () => {
  return
}
