import { UseBaseApiParams } from '../core/UseBaseApiParams'
import { generatePath } from 'react-router-dom'
import { PageLayout } from '../mini-lib/layouts/PageLayout'
import { PageHeader } from '../mini-lib/page-header/PageHeader'
import { AdminCreateTabs } from './admin-notifications/AdminCreateTabs'
import { ROUTES } from "../appRoutes";

export const AdminPage = () => {
  const { salonId } = UseBaseApiParams()
  
  const breadcrumbs = [
    {
      label: 'Admin',
      url: generatePath(ROUTES.adminNotification, { salonId }),
    },
    { label: 'Notification' },
  ]

  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Notification" breadcrumbs={breadcrumbs} />}
      content={<AdminCreateTabs/>}
    />
  )
}
