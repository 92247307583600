import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { UseViewSize } from '../../../core/UseViewSize'
import { useAppSelector } from '../../../hooks'
import { dispatchBulkMergeClients, dispatchListBulkMergeableClients } from '../../../data/clients/api'
import { selectBulkMergeableClientSets } from '../../../data/clients/slice'
import { APIClient } from '../../../data/clients/interfaces'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { ReleaseBulkClients } from "../../../mini-lib/flags/Release";

export const ClientBulkMergeSheetButton = () => {
  const [showSheet, setShowSheet] = useState(false)
  const releaseBulkClients = ReleaseBulkClients()
  return (
    <>
      <Button variant={releaseBulkClients ? "round-ghost-upper" : "round-outline"} colorScheme="brand.midnight" onClick={() => setShowSheet(true)}>
        Merge Clients
      </Button>
      {showSheet && <ClientBulkMergeSheet showSheet={showSheet} setShowSheet={setShowSheet} />}
    </>
  )
}
export const ClientBulkMergeSheet = (props: { showSheet: boolean; setShowSheet: (boolean) => void }) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const { showSheet, setShowSheet } = props
  const { isMobile } = UseViewSize()
  const clientSets = useAppSelector(selectBulkMergeableClientSets)
  const modelsLoaded = !!clientSets
  useEffect(() => {
    dispatch(dispatchListBulkMergeableClients({ token: user.token, salonId }))
  }, [dispatch, modelsLoaded, user.token, salonId, showSheet])

  const mergeAllClientSets = () => {
    if (clientSets) {
      const sets = clientSets.map((set: any) => {
        return { clients: set }
      })
      dispatch(dispatchBulkMergeClients({ token: user.token, salonId, clientSets: sets }))
      setShowSheet(false)
    }
  }
  return (
    <>
      <Drawer size={isMobile ? 'xs' : 'md'} isOpen={showSheet} placement="right" onClose={() => setShowSheet(false)}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Flex justify="space-between" >
              <Text variant="title2">Merge Clients</Text>
              <Flex gridGap='12px'>
                <Button variant="round-outline" onClick={() => setShowSheet(false)}>
                  Cancel
                </Button>
                <Button variant="round" onClick={mergeAllClientSets}>
                  Merge All
                </Button>
              </Flex>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <DisableAutofocus />
            <Flex width="100%" align="center" p="20px" borderRadius="15px" bg="brand.lavender.100">
              <Text fontSize="14px">Duplicate clients have the exact same first name and last name.</Text>
            </Flex>
            <Flex direction="column" gridGap="24px" p="24px 0">
              {modelsLoaded &&
                clientSets &&
                clientSets.length > 0 &&
                clientSets.map((clients, index) => {
                  return <BulkMergeClientSet key={index} clients={clients} />
                })}
              {modelsLoaded && clientSets && clientSets.length === 0 && (
                <EmptyBox content="you have no duplicate clients" />
              )}
              {!modelsLoaded && <Loading />}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
export const BulkMergeClientSet = (props: { clients: APIClient[] }) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const { clients } = props
  const mergeClientSet = () => {
    const set = { clients: clients }
    dispatch(dispatchBulkMergeClients({ token: user.token, salonId, clientSets: [set] }))
  }
  return (
    <Flex justify="space-between">
      <Box>
        <Text>
          {clients[0].first_name} {clients[0].last_name}
        </Text>
        <Text color="text.secondary">{clients.length} duplicates</Text>
      </Box>
      <Button variant="round-ghost-lower" onClick={mergeClientSet}>
        Merge
      </Button>
    </Flex>
  )
}
