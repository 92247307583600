import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Flex } from '@chakra-ui/react'

import { useAppSelector } from '../../hooks'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { StylistList } from './StylistList'
import { StylistDetailsSheetButton } from './StylistDetailsSheet'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { EmptySearch } from '../../mini-lib/empty/EmptySearch'
import { Loading } from '../../mini-lib/loading/Loading'
import { StickySearch } from '../../mini-lib/search/StickySearch'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { generatePath, Link } from 'react-router-dom'
import { HelpStepper, Step } from '../start-guide/common-components/HelpStepper'
import { HelpPopover } from '../start-guide/common-components/HelpPopover'
import { HelpModal } from '../start-guide/common-components/HelpModal'
import { HelpStepCompleteDrawer } from '../start-guide/common-components/HelpStepCompleteDrawer'
import { dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import {
  reduceSetCurrentStepCode,
  reduceSetCurrentSubStepIndex,
  reduceSetStepCompleteDrawerState,
  selectStepCompleteDrawerState,
  selectStepsCompleted,
} from '../../data/start-guide/slice'
import { ReleasePageHelp, ReleaseStartGuideV2 } from '../../mini-lib/flags/Release'
import { HelpStepperV2 } from '../start-guide-v2/HelpStepperV2'
import { ROUTES } from '../../appRoutes'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { HelpDrawer } from "../start-guide/common-components/HelpDrawer";
import { VIDEO_MAP } from "../../mini-lib/video/constants";
import { dispatchListSalonUsers } from "../../data/salon-user/api";
import { selectSalonUsers } from "../../data/salon-user/slice";
import { filterSalonUsers } from "../../data/salon-user/utils";

export function StylistsPage(props: { showBreadCrumbs?: boolean }) {
  const { showBreadCrumbs = true } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = showBreadCrumbs ? [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Team' }] : []
  const dispatch = useDispatch()
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const [searchText, setSearchText] = useState('')
  const salonUsers = useAppSelector(selectSalonUsers)
  const filteredSalonUsers = salonUsers ? filterSalonUsers({users: salonUsers, filters: {searchText, tierId: null, roleId: null}}) : []

  const helpSteps: Step[] = [
    {
      title: 'Invite a Stylist',
      state: 'current',
      link: generatePath(ROUTES.stylists, { salonId }) + `?guide=${CHECKLIST_CODES.addStylist}`,
    },
  ]

  const queryParams = UseQueryParams()
  const qpSearchText = queryParams.get('search') || ''

  useEffect(() => {
    setSearchText(qpSearchText.toLowerCase().trim())
  }, [dispatch, qpSearchText])


  useEffect(() => {
    dispatch(dispatchListSalonUsers({ token, salonId }))
  }, [dispatch, token, salonId])


  const releaseStartGuideV2 = ReleaseStartGuideV2()
  const releasePageHelp = ReleasePageHelp()
  const stepCodes = [CHECKLIST_CODES.addStylist, CHECKLIST_CODES.addClient]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes)) || 0
  const previousStepLink = generatePath(ROUTES.sgAddTeamIntro, { salonId })
  const nextStepLink = generatePath(ROUTES.sgAddClientsIntro, { salonId })
  return (
    <PageLayout
      variant="full"
      top={
        <>
          {releasePageHelp && (
            <HelpDrawer
              video={VIDEO_MAP.addingStylists}
              title='Let’s take a look inside adding your Team'
              content={'It is easy to add your team members to SalonScale. Simply enter their email address or phone number, and your team will be prompted to create their account and download the app from the App Store or GooglePlay store.\n'}
            />
          )}
          {releaseStartGuideV2 ? (
            <HelpStepperV2
              title="Invite your team"
              titleIconName="stylists"
              currentStepNumber={1}
              stepsComplete={stepsCompleted}
              totalSteps={stepCodes.length}
              previousStepLink={previousStepLink}
              nextStepLink={nextStepLink}
              guideType={CHECKLIST_CODES.addStylist}
            />
          ) : (
            <HelpStepper title="Invite your team" steps={helpSteps} guideType={CHECKLIST_CODES.addStylist} />
          )}
        </>
      }
      header={
        <PageHeader
          showHelp={true}
          title="Team"
          breadcrumbs={breadcrumbs}
          actions={
            <HelpPopover
              description="Click 'Invite Team' to start adding a stylist"
              stepIndex={0}
              guideTypes={[CHECKLIST_CODES.addStylist]}
            >
              <Box>
                <StylistDetailsSheetButton />
              </Box>
            </HelpPopover>
          }
        />
      }
      content={
        <>
          <HelpModal
            iconName="stylists"
            guideType={CHECKLIST_CODES.addStylist}
            title="Invite your team"
            subtitle="Invite your team to use the SalonScale app! You add their names and invite with either a cell phone number or email address."
          />
          <HelpStepCompleteDrawer
            guideType={CHECKLIST_CODES.addStylist}
            title="Way To Go! You Invited Your first Team member."
            subtitle="Make sure you invite all your team members before you continue."
            actions={
              <Flex align="center" gridGap="12px">
                {stepCompleteDrawerState !== 'button' && (
                  <Button
                    variant="round-outline"
                    colorScheme="brand.midnight"
                    onClick={() => dispatch(reduceSetStepCompleteDrawerState('button'))}
                  >
                    Continue Inviting Team
                  </Button>
                )}
                <Link to={releaseStartGuideV2 ? nextStepLink : generatePath(ROUTES.startGuide, { salonId })}>
                  <Button
                    variant="round"
                    colorScheme="brand.midnight"
                    onClick={() => {
                      dispatch(
                        dispatchUpdateUserChecklistItem({ token, checklistItemCode: CHECKLIST_CODES.addStylist }),
                      )
                      dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.addClient))
                      dispatch(reduceSetStepCompleteDrawerState('hidden'))
                      dispatch(reduceSetCurrentSubStepIndex(0))
                    }}
                  >
                    Next Step
                  </Button>
                </Link>
              </Flex>
            }
          />
          {salonUsers ? (
            <>
              <Box p="12px">
                <StickySearch
                  variant={'clear'}
                  placeholder="Search Stylists"
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </Box>
              {filteredSalonUsers.length > 0 ? (
                <StylistList stylists={filteredSalonUsers} />
              ) : (
                <EmptySearch variant="clear">No Stylists Found</EmptySearch>
              )}
            </>
          ) : (
            <Loading variant="clear" />
          )}
        </>
      }
    />
  )
}
