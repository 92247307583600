export const NUMBER_OWNER_CHECKLIST_STEPS = 8
export const CHECKLIST_GROUP_INDEXES = {
  group1: 0,
  group2: 1,
  group3: 2,
  group4: 3,
}
export const CHECKLIST_CODES = {
  welcomeVideo: 'learn-welcome',
  addLines: 'add-lines',
  autoPricing: 'auto-pricing',
  addPricing: 'add-pricing',
  addAddons: 'add-addons',
  addExtensions: 'add-extensions',
  addExtensionsPricing: 'extensions-pricing',
  addStylist: 'add-team',
  addClient: 'add-client',
  learnSessions: 'learn-sessions',
  learnReports: 'learn-reports',
  learnOrders: 'learn-orders',
  learnAcademy: 'learn-academy',
  inventoryIntroduction: 'inventory-intro',
  inventorySetTargets: 'inventory-targets',
  inventorySetOnHand: 'inventory-onhand',
  inventoryCompleteCount: 'inventory-count', // <-- currently no step for this in start guide v2
  inventoryStockAlerts: 'inventory-alerts',
  inventoryAddingStock: 'inventory-addstock',
  inventoryOrders: 'inventory-orders',
  learnPOS: 'learn-pos',
  guideComplete: 'complete',
  trialWhyColor: 'trial-why-color',
  trialDownloadApp: 'trial-download-app',
  trialFirstSession: 'trial-first-session',
  trialFreeConsult: 'trial-free-consult',

  addLabor: 'add-labor',

  // this should really be in a 'start guides' constant not a checklist codes constant
  addLaborSolo: 'add-labor-solo',
  addLaborTeam: 'add-labor-team',

}

export const CHECKLIST_GROUPS = {
  group1: [CHECKLIST_CODES.welcomeVideo],
  group2: [
    CHECKLIST_CODES.addLines,
    CHECKLIST_CODES.addAddons,
    CHECKLIST_CODES.addExtensions,
    CHECKLIST_CODES.addStylist,
    CHECKLIST_CODES.addClient,
    CHECKLIST_CODES.learnSessions,
    // CHECKLIST_CODES.learnReports,
    // CHECKLIST_CODES.learnOrders,
  ],
  group3: [
    CHECKLIST_CODES.inventoryIntroduction,
    CHECKLIST_CODES.inventorySetTargets,
    CHECKLIST_CODES.inventoryCompleteCount,
    CHECKLIST_CODES.inventoryStockAlerts,
    CHECKLIST_CODES.inventoryOrders,
    CHECKLIST_CODES.inventoryAddingStock,
  ],
  group4: [CHECKLIST_CODES.learnAcademy],
}

export const ALL_CHECKLIST_CODES_IN_ORDER = [
  CHECKLIST_CODES.welcomeVideo,
  CHECKLIST_CODES.addLines,
  CHECKLIST_CODES.addAddons,
  CHECKLIST_CODES.addExtensions,
  CHECKLIST_CODES.addStylist,
  CHECKLIST_CODES.addClient,
  CHECKLIST_CODES.learnSessions,
  CHECKLIST_CODES.inventoryIntroduction,
  CHECKLIST_CODES.inventorySetTargets,
  CHECKLIST_CODES.inventoryCompleteCount,
  CHECKLIST_CODES.inventoryStockAlerts,
  CHECKLIST_CODES.inventoryOrders,
  CHECKLIST_CODES.inventoryAddingStock,
  CHECKLIST_CODES.learnAcademy,
]

export const CHECKLIST_ITEMS_LOADING = 'inventory-snapshot'

export const SALON_IMPLEMENTATION_GUIDE_URL = "https://uploads-ssl.webflow.com/66570799098af00ad5ed9eea/668c1840e080967c00b9002a_SalonScale%20Implementation%20guide%20for%20Salon%20Owners%20(19July2023)%20(1).pdf"
export const SOLO_IMPLEMENTATION_GUIDE_URL = "https://uploads-ssl.webflow.com/66570799098af00ad5ed9eea/668c186702753c78f41ad520_SalonScale%20Implementation%20Guide%20for%20Solos(04April2024).pdf"

