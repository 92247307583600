export const buildFormikProps = (params: {
  name: string,
  formikParams: {
    values: any,
    setFieldTouched: any,
    setFieldValue: any,
    touched: any,
    errors: any
    setFieldError: any
  }
})=> {
  const { name, formikParams: {values, errors, touched, setFieldValue, setFieldError, setFieldTouched}  } = params
  return {
    value: values[name],
    onChange: val => setFieldValue(name, val),
    onBlur: val => setFieldTouched(name, true),
    onError: err => setFieldError(name, err),
    errorText: touched[name] ? errors[name] : '',
  }
}
