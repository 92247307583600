/////////////// api.ts
//
//

import axios from 'axios'
import { Dispatch } from '@reduxjs/toolkit'

import {
  reduceCreateSupply,
  reduceDeleteSupplies,
  reduceDeleteSupply,
  reduceListSupply,
  reduceUpdateSupply
} from './slice'
import { APISupply, APISupplyCreate, Supply } from './interfaces'
import { mapAPISuppliesToSupplies } from './mappers'
import { GetServerBaseUrl } from '../../env'
import { delay } from "lodash";
import { reduceSetLoadingState } from "../../core/loading/slice";
import { buildLaraConfig } from "../../mini-lib/lara/lara-utils";

// apis
//
//
export const apiListSupply = ( token: string, salon_id: number, pageNumber = 1, searchText = '' ): Promise<Supply[]> => {
  const url = `${GetServerBaseUrl()}/supplies/?token=${token}&salon_id=${salon_id}&page=${pageNumber}`
  return axios
    .get(url)
    .then(( response: { data: { data: APISupply[] } } ) => {
      return mapAPISuppliesToSupplies(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}

export const apiUpdateSupplies = ( params: {
  token: string
  userId: number
  salonId: number
  models: APISupply[]
} ): Promise<Supply[]> => {
  const { token, userId, salonId, models } = params
  const url = `${GetServerBaseUrl()}/supplies/?token=${token}&user_id=${userId}&salon_id=${salonId}`
  const body = {
    supplies: models,
  }
  return axios
    .put(url, body)
    .then(( response: any ) => {
      return mapAPISuppliesToSupplies(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}
export const apiCreateSupplies = ( params: {
  token: string
  user_id: number
  salon_id: number
  models: APISupplyCreate[]
} ): Promise<Supply[]> => {
  const { token, user_id, salon_id, models } = params
  const url = `${GetServerBaseUrl()}/supplies/?token=${token}&user_id=${user_id}&salon_id=${salon_id}`
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    supplies: models,
  }
  return axios
    .post(url, body)
    .then(( response: any ) => {
      return mapAPISuppliesToSupplies(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}
export const apiDeleteSupplies = ( params: {
  token: string
  user_id: number
  salon_id: number
  models: Supply[]
} ): Promise<any> => {
  const { token, salon_id, user_id, models } = params
  const modelIds = models.map(( model ) => model.id).join(',')
  const url = `${GetServerBaseUrl()}/supplies/?token=${token}&user_id=${user_id}&salon_id=${salon_id}&ids=${modelIds}`
  return axios
    .delete(url)
    .then(( response: any ) => {
      return { ...response.data, id: models[0].id }
    })
    .catch(( error ) => {
      throw error
    })
}

// lara apis
//
//
//
export const apiListSupplyLara = ( params: { token: string, salonId: number } ): Promise<Supply[]> => {
  const { token, salonId } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/supplies/`
  return axios
    .get(url, config)
    .then(( response: { data: { data: APISupply[] } } ) => {
      return mapAPISuppliesToSupplies(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}
export const apiCrateOrUpdateSuppliesLara = ( params: {
  token: string
  salonId: number
  models: APISupply[]
} ): Promise<Supply[]> => {
  const { token, salonId, models } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/supplies/`
  const body = {
    supplies: models,
  }
  return axios
    .post(url, body, config)
    .then(( response: any ) => {
      return mapAPISuppliesToSupplies(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}

export const apiDeleteSuppliesLara = ( params: {
  token: string
  salonId: number
  models: Supply[]
} ): Promise<any> => {
  const { token, salonId, models } = params
  const modelIds = models.map(( model ) => model.id).join(',')
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/supplies/?ids=${modelIds}`
  return axios
    .delete(url, config)
    .then(() => {
      return models
    })
    .catch(( error ) => {
      throw error
    })
}

// actions
//
//
export const dispatchListSupplies = ( params: { token: string, salonId: number, releaseLaraSupplies: boolean } ) => {
  const { token, salonId, releaseLaraSupplies } = params
  if (releaseLaraSupplies) {
    return ( dispatch: Dispatch ) => {
      return apiListSupplyLara(params).then(( resp ) => {
        dispatch(reduceListSupply(resp))
      })
    }
  } else {
    return ( dispatch: Dispatch ) => {
      return apiListSupply(token, salonId).then(( resp ) => {
        dispatch(reduceListSupply(resp))
      })
    }
  }
}
export const dispatchUpdateSupplies = ( params: {
  token: string;
  userId: number;
  salonId: number;
  models: APISupply[],
  loadingId: string,
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiUpdateSupplies(params)
      .then(( resp ) => {
        dispatch(reduceUpdateSupply(resp))
        delay(() => {
          dispatch(reduceSetLoadingState({ name: params.loadingId, state: false }))
        }, 2000)
      })
      .catch(( error ) => {
        throw error
      })
  }
}
export const dispatchCreateOrUpdateSuppliesLara = ( params: {
  token: string;
  userId: number;
  salonId: number;
  models: APISupply[],
  loadingId: string,
} ) => {

    return ( dispatch: Dispatch ) => {
      return apiCrateOrUpdateSuppliesLara(params)
        .then(( resp ) => {
          dispatch(reduceUpdateSupply(resp))
          delay(() => {
            dispatch(reduceSetLoadingState({ name: params.loadingId, state: false }))
          }, 2000)
        })
        .catch(( error ) => {
          throw error
        })
    }
}
export const dispatchCreateSupplies = ( params: {
  token: string;
  user_id: number;
  salon_id: number;
  models: APISupplyCreate[]
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiCreateSupplies(params)
      .then(( resp ) => {
        dispatch(reduceCreateSupply(resp))
      })
      .catch(( error ) => {
        throw error
      })
  }
}
export const dispatchDeleteSupplies = ( params: {
  token: string;
  user_id: number;
  salon_id: number;
  models: Supply[]
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteSupplies(params)
      .then(( resp ) => {
        dispatch(reduceDeleteSupply(resp))
      })
      .catch(( error ) => {
        throw error
      })
  }
}

export const dispatchDeleteSuppliesLara = ( params: {
  token: string;
  salonId: number;
  models: Supply[]
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteSuppliesLara(params)
      .then(( resp ) => {
        dispatch(reduceDeleteSupplies(resp))
      })
      .catch(( error ) => {
        throw error
      })
  }
}
