import { Dispatch } from '@reduxjs/toolkit'

import { reduceUpdateChecklistItem, reduceUserChecklistItems } from './slice'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { APIChecklistItem, ChecklistItem } from './interfaces'
import axios, { AxiosResponse } from 'axios'
import { mapAPIChecklistItemsToChecklistItems, mapAPIChecklistItemToChecklistItem } from './mappers'
import { GetServerBaseUrl } from '../../env'
import { CHECKLIST_ITEMS_LOADING } from './constants'
import { RootState } from "../../store";
import { hasActiveQuickTrialAndNoSubscription } from "../salon/utils";
import { assign } from "lodash";
import { TRIAL_V3_SALON_IDS } from "../../mini-lib/flags/constants";

// apis
//
//
// use this to develop with checklist items locally
//
// TODO: REMOVE THIS
// return mockChecklistItems()
export const apiListUserChecklistItems = (
  token: string,
  userId: number,
  dispatch: Dispatch,
): Promise<ChecklistItem[]> => {
  const url = `${GetServerBaseUrl('v3', 'lara')}/users/checklistitems/?token=${token}&impersonate_user_id=${userId}`
  // return mockChecklistItems()
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: APIChecklistItem[] }>) => {
      return mapAPIChecklistItemsToChecklistItems(response.data.data)
    })
    .catch((error) => {
      return []
    })
    .finally(() => {
      dispatch(reduceSetLoadingState({ name: CHECKLIST_ITEMS_LOADING, state: false }))
    })
}

export const apiUpdateUserChecklistItem = (params: {
  token: string
  userId: number
  checklistItemCode: string
}): Promise<ChecklistItem> => {
  const { token, userId, checklistItemCode } = params
  // return Promise.resolve({ id: -1, code: checklistItemCode, completed: true })

  const url = `${GetServerBaseUrl('v3', 'lara')}/users/${userId}/checklistitems/?token=${token}`
  const body = { code: checklistItemCode }
  return axios
    .post(url, body)
    .then((response: AxiosResponse<{ data: APIChecklistItem }> | any) => {
      return mapAPIChecklistItemToChecklistItem(response.data.data)
    })
}
// actions
//
//
export const dispatchListUserChecklistItems = (token: string, userId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: CHECKLIST_ITEMS_LOADING, state: true }))
    return apiListUserChecklistItems(token, userId, dispatch).then((checklistItems) => {
      dispatch(reduceUserChecklistItems(checklistItems))
      dispatch(reduceSetLoadingState({ name: CHECKLIST_ITEMS_LOADING, state: false }))
    })
  }
}
export const dispatchUpdateUserChecklistItem = (params: { token: string, checklistItemCode: string }) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    const user = state.users.loggedInUser

    // note: it would be preferable to pass this in and use a hook BUT this is called ~50 times and putting this here
    // is preferable to rewriting 50 files
    const releaseTrialV3 = user?.currentSalonContext?.salonId ? TRIAL_V3_SALON_IDS.includes(user?.currentSalonContext?.salonId) : false
    const userInTrial = user && user.currentSalonContext
      ? hasActiveQuickTrialAndNoSubscription(user.currentSalonContext)
      : false

    // users in trial should not be able to update the checklist items unless they are on trial v3
    if (user && (!userInTrial || releaseTrialV3)) {
      const updatedParams = assign({}, params, {userId: user.userId})
      return apiUpdateUserChecklistItem(updatedParams).then((resp) => {
        dispatch(reduceUpdateChecklistItem(resp))
      })
    }
  }
}

// comment or uncomment the checklist items you want to simulate being returned from the api
//
export const mockChecklistItems = (): Promise<ChecklistItem[]> =>
  Promise.resolve(
    mapAPIChecklistItemsToChecklistItems([
      // { code: 'trial-why-color', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'trial-first-session', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'learn-welcome', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'add-lines', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'add-addons', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'add-extensions', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'add-team', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'add-client', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'learn-academy', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'learn-orders', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'learn-reports', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'learn-sessions', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'inventory-intro', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'inventory-targets', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'inventory-count', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'inventory-alerts', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'inventory-addstock', completed_at: '2020-10-06T01:42:06.000000Z' },
      // { code: 'inventory-orders', completed_at: '2020-10-06T01:42:06.000000Z' },
    ]),
  )
