import { UseViewSize } from '../../../core/UseViewSize'
import { Flex, Text } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'

export const VideoAction = (props: { duration: string; isDisabled?: boolean }) => {
  const { isMobile } = UseViewSize()
  const { duration, isDisabled = false } = props
  return (
    <Flex
      w={isMobile ? '120px' : '150px'}
      bg={COLORS.skylight_100}
      opacity={isDisabled ? '0.4' : ''}
      align="center"
      justify="center"
      borderRadius="50px"
      padding="4px 12px"
    >
      <MaterialIcon name="videocam" />
      <Text pl="4px" fontSize="12px">
        video {duration}
      </Text>
    </Flex>
  )
}

export const UserAction = (props: { isDisabled?: boolean }) => {
  const { isMobile } = UseViewSize()
  const { isDisabled = false } = props
  return (
    <Flex
      w={isMobile ? '120px' : '150px'}
      bg={COLORS.peach_100}
      opacity={isDisabled ? '0.4' : ''}
      align="center"
      justify="center"
      borderRadius="50px"
      padding="4px 12px 4px 4px"
    >
      <MaterialIcon name="bolt" />
      <Text fontSize="12px">action</Text>
    </Flex>
  )
}

