import { Button, Text, Flex } from '@chakra-ui/react'
import { Gap } from '../../mini-lib/gap/Gap'
import React from 'react'
import { UseViewSize } from '../../core/UseViewSize'
import logoAndName from '../../assets/signup/salonscale-icon-name-light.png'
import {UseQueryParams} from "../../mini-lib/utils/basic";
import {useAppSelector} from "../../hooks";
import {selectLoggedInUser} from "../../data/user/slice";
import {generatePath, useHistory} from "react-router-dom";
import {ROUTES} from "../../appRoutes";

declare var Chargebee: any

export const BlackFridayPage = () => {
  const { isTablet, isMobile } = UseViewSize()
  const backgroundUrl = require('../../assets/signup/black-friday-plan-header-desktop.png').default
  const queryParams = UseQueryParams()
  const type = queryParams.get('type')
  const user = useAppSelector(selectLoggedInUser)
  const email = user ? user.email : ''
  const teamPlanDetails = {
    savings: 500,
    addonId: '2024-black-friday-team',
    subtitle: 'Save $500 off your subscription when you lock in for the year!',
    content: 'Lock in for the year and pay $1,288 (regular price of $1,788 USD) from now till November 30th.',
    buttonLabel: 'Buy Now'
  }
  const soloPlanDetails = {
    savings: 200,
    addonId: '2024-black-friday-solo',
    subtitle: 'Save $150 off your subscription when you lock in for the year!',
    content: 'Lock in for the year and pay $510 (regular price of $660 USD) from now till November 30th.',
    buttonLabel: 'Buy Now'
  }
  const planDetails = type === 'solo' ? soloPlanDetails : teamPlanDetails
  return (
    <Flex w="100%" align="center" justify="flex-start" direction="column" textAlign="center">
      <Flex
        color="white"
        w="100%"
        height={isTablet ? '500px' : isMobile ? '300px' : '300px'}
        align="center"
        justify="center"
        direction="column"
        textAlign="center"
        p={isMobile ? '100px 4px' : '100px 0'}
        backgroundSize={'cover'}
        backgroundRepeat="no-repeat"
        backgroundImage={backgroundUrl}
      >
        <Flex align='center' justify='center'>
          <img alt="logo" style={{ height: isMobile ? '70px' : '80px'}} src={logoAndName}/>
        </Flex>
        <Text variant={isMobile ? 'giganticsubtitle' : 'gigantictext'} mt={isMobile ? '-4px' : '-22px'}>Black Friday Sale</Text>
        <Text variant={isMobile ? 'title1mobile' : 'title1'} mt={isMobile ? '16px' : '8px'} fontWeight='200'>
          {planDetails.subtitle}
        </Text>
      </Flex>

      <Flex maxW='100%' w='560px' direction='column' p='0 12px' align='center'>
        <Gap />
        <Text>{planDetails.content}</Text>
        <Gap />
        <BlackFridayChargebeeAddonButton buttonLabel={planDetails.buttonLabel} addonId={planDetails.addonId} email={email}/>
        <Gap />
        <Text variant='callout-desktop-regular'>*When checking out use the email associated with your SalonScale account.</Text>
        <Gap />
        <Text variant='callout-desktop-regular'>Pricing is determined by the total value of monthly payments accumulated over the course of a year. It may take 2-3 business days to see changes to your account. Offer Ends November 30th and can only be redeemed once per customer per location. Regular monthly payments will begin 1 year from the purchase date.</Text>
        <Gap />
      </Flex>
    </Flex>
  )
}

export const BlackFridayChargebeeAddonButton = (props: { buttonLabel: string, addonId: string, email?: string  }) => {
  const { addonId, buttonLabel, email = '' } = props

  const history = useHistory()
  const user = useAppSelector(selectLoggedInUser)
  const onRedirectToHomeOrLogin = () => {
    const salonId = user?.currentSalonContext?.salonId
    if (salonId) {
      history.push(generatePath(ROUTES.home, { salonId }))
    } else {
      history.push(generatePath(ROUTES.login))
    }
  }
  const onClick = (params: {}) => {
    // access cb instance, inject black friday addon, try to inject user email as well, run them through checkout
    //
    const cbInstance = Chargebee.getInstance()
    const cart = cbInstance.getCart()

    const product = cbInstance.initializeProduct()
    const customer = {
      email: email,
    }
    cart.setCustomer(customer)

    product.addAddon(addonId)
    cart.replaceProduct(product)

    // https://www.chargebee.com/checkout-portal-docs/dropIn.html#setcheckoutcallbacks
    cbInstance.setCheckoutCallbacks((checkoutCart: any) => {
      return {
        loaded: () => {
          // handle checkout loaded
        },
        close: () => {
          // handle checkout closed
        },
        success: (hostedPageId: { id: string }) => {
          // where to redirect on success?
          onRedirectToHomeOrLogin()
        },
        step: (value: string) => {
          // value -> which step in checkout
        },
      }
    })

    cart.proceedToCheckout()
  }

  return (
    <Button variant="round" fontSize='20px' h='48px' bg="black" color="white" w='130px' onClick={onClick}>
      {buttonLabel}
    </Button>
  )
}
