// ref - how to get change image colors https://stackoverflow.com/a/50942954
// filter generator - https://codepen.io/sosuke/pen/Pjoqqp
import { ThemeType } from "../../theme";

export const peachThemeIconStyles = {filter: 'brightness(0) saturate(100%) invert(47%) sepia(56%) saturate(5666%) hue-rotate(358deg) brightness(99%) contrast(84%)'}
export const midnightThemeIconStyles = {filter: 'brightness(0) saturate(100%) invert(20%) sepia(12%) saturate(7422%) hue-rotate(202deg) brightness(97%) contrast(92%)'}

export const lavenderThemeIconStyles = {filter: 'brightness(0) saturate(100%) invert(58%) sepia(51%) saturate(729%) hue-rotate(201deg) brightness(93%) contrast(90%)'}
export const skylightThemeIconStyles = {filter: 'brightness(0) saturate(100%) invert(57%) sepia(96%) saturate(1826%) hue-rotate(189deg) brightness(104%) contrast(110%)'}
export const linenThemeIconStyles = {filter: 'brightness(0) saturate(100%) invert(83%) sepia(41%) saturate(3007%) hue-rotate(327deg) brightness(101%) contrast(103%)'}


export const IconStyleMap = {
  lavender: lavenderThemeIconStyles,
  peach: peachThemeIconStyles,
  linen: linenThemeIconStyles,
  skylight: skylightThemeIconStyles,
  midnight: midnightThemeIconStyles,
}
export const getIconColorStylesForTheme = (theme: ThemeType): {filter: string} => {
  return IconStyleMap[theme]
}
