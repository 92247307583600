import { Circle, Text } from '@chakra-ui/react'
import React from 'react'

export const PhotoCircle = (props: { photoUrl: string; backupText: string; size?: string, fontSize?: string }) => {
  const { photoUrl, backupText, size = '40px', fontSize = '14px' } = props
  return (
    <Circle size={size} bg="brand.lavender.200">
      {photoUrl ? (
        <img alt="circle" src={photoUrl} style={{ height: '40px', width: '40px', borderRadius: '50%' }} />
      ) : (
        <Text fontWeight="bold" color="brand.lavender.500" textTransform="uppercase" fontSize={fontSize}>
          {backupText.slice(0,2)}
        </Text>
      )}
    </Circle>
  )
}
