import { Tab, TabList, Tabs } from '@chakra-ui/react'
import { UseViewSize } from '../../core/UseViewSize'
import { generatePath, useHistory } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'

import { ROUTES } from "../../appRoutes";

export const LINES_TABS = {
  snapshot: 0,
  stockAlerts: 1,
  inventoryCount: 2,
  addStock: 3,
}
export const InventoryTabs = (props: { selectedTab: number }) => {
  const { selectedTab } = props
  const { isMobile } = UseViewSize()
  const history = useHistory()
  const { salonId } = UseBaseApiParams()

  const navigateToTab = (newIndex: number) => {
    if (newIndex === 0) {
      history.push(generatePath(ROUTES.inventorySnapshot, { salonId }))
    } else if (newIndex === 1) {
      history.push(generatePath(ROUTES.inventoryStockAlerts, { salonId }))
    } else if (newIndex === 2) {
      history.push(generatePath(ROUTES.inventoryAudits, { salonId }))
    } else if (newIndex === 3) {
      history.push(generatePath(ROUTES.inventoryAddStock, { salonId }))
    }
  }
  return (
    <Tabs index={selectedTab} onChange={(index) => navigateToTab(index)} colorScheme="brand.lavender">
      <TabList overflowX="auto" padding="4px">
        <Tab minW={isMobile ? '60px' : '150px'}>Snapshot</Tab>
        <Tab minW={isMobile ? '60px' : '150px'}>{isMobile ? 'Alerts' : 'Stock Alerts'}</Tab>
        <Tab minW={isMobile ? '80px' : '150px'}>{isMobile ? 'Count' : 'Inventory Count'}</Tab>
        <Tab minW={isMobile ? '80px' : '150px'}>{isMobile ? 'Add' : 'Add Stock'}</Tab>
      </TabList>
    </Tabs>
  )
}
