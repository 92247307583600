import { APILaborTierCreate } from "./interfaces";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { dispatchCreateLaborTiers } from "./api";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { useAppSelector } from "../../hooks";
import { selectLaborTierList } from "./slice";

export const UseCreateLaborTiersForStartGuide = () => {
  const dispatch = useDispatch()
  const {user, salonId} = UseBaseApiParams()
  const tiers = useAppSelector(selectLaborTierList)
  const tiersLoaded = !!tiers
  const tiersLength = tiers ? tiers.length : null
  const [addedTiers, setAddedTiers] = useState(false)

  const newTiers: APILaborTierCreate[] = [
    {name: 'Level 1'},
    {name: 'Level 2'},
    {name: 'Level 3'},
  ]
  useEffect(() => {
    if (tiersLoaded && tiersLength === 0 && !addedTiers) {
      dispatch(dispatchCreateLaborTiers({ userId: user.userId, salonId, token: user.token, models: newTiers }))
      setAddedTiers(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiersLength, tiersLoaded, addedTiers, salonId, user.userId])
  return
}
