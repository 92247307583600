import { Accordion, AccordionItem, Button } from '@chakra-ui/react'
import { StepGroupAccordionHeader } from '../common-components/StepGroupAccordionHeader'
import { StepGroupAccordionPanel } from '../common-components/StepGroupAccordionPanel'
import { StepAccordionHeader } from '../common-components/StepAccordionHeader'
import { StepAccordionPanel } from '../common-components/StepAccordionPanel'
import {
  reduceSetCurrentStepCode,
  selectChecklistItem,
  selectCurrentStepCode,
  selectStepsCompleted,
} from '../../../data/start-guide/slice'
import { CHECKLIST_CODES, CHECKLIST_GROUPS } from '../../../data/start-guide/constants'
import { useAppSelector } from '../../../hooks'
import { COLORS } from '../../../mini-lib/theme/colors'
import { VIDEO_MAP } from '../../../mini-lib/video/constants'
import { findIndex } from 'lodash'
import { useDispatch } from 'react-redux'
import { VideoAction } from '../common-components/actions'

export const ChecklistGroup1 = () => {
  const stepCodes = CHECKLIST_GROUPS.group1
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  const currentStepCode = useAppSelector(selectCurrentStepCode)
  const stepIndex = findIndex(CHECKLIST_GROUPS.group1, (item) => item === currentStepCode)
  return (
    <AccordionItem p={0} m="0 0 24px 0" border="none">
      <StepGroupAccordionHeader
        initialStepCode={stepCodes[0]}
        iconName="getToKnowSalonScale"
        title="Get to Know SalonScale"
        stepsCompleted={stepsCompleted ? stepsCompleted : 0}
        stepsLength={stepCodes.length}
      />

      <StepGroupAccordionPanel
        steps={
          <Accordion
            allowToggle
            p={0}
            index={stepIndex}
            border={`2px solid ${COLORS.lavender_100}`}
            borderRadius="0 0 15px 15px"
          >
            <WelcomeStep />
          </Accordion>
        }
      />
    </AccordionItem>
  )
}

const WelcomeStep = () => {
  const dispatch = useDispatch()
  const stepCode = CHECKLIST_CODES.welcomeVideo
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))
  const description = `Welcome to SalonScale! We’re excited to help you get set-up and ensure every service you do is a profitable one! 

With SalonScale you will be able to:

  • track every formula you and your stylists use
  • know the exact product cost of every appointment
  • turn your backbar into a profit center  

Follow along with the steps in our start up guide to begin your backbar transformation!
`
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        checklistItem={checklistItem}
        label="1"
        title="See What You Can Do With SalonScale"
        actions={<VideoAction duration="1 min" />}
      />
      <StepAccordionPanel
        stepCode={stepCode}
        description={description}
        video={VIDEO_MAP.designedForYou}
        actions={
          <>
            {checklistItem.completed && (
              <Button
                variant="round-outline"
                colorScheme="brand.midnight"
                onClick={() => {
                  dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.addLines))
                }}
              >
                Go to next step
              </Button>
            )}
          </>
        }
      />
    </AccordionItem>
  )
}
