import { ConfirmModal } from '../../../mini-lib/confirm-modal/ConfirmModal'
import { Button, Flex, Text, Tooltip } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { dispatchListProducts, dispatchUpdateProducts } from '../../../data/products/api'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useAppSelector } from '../../../hooks'
import { selectProductList } from '../../../data/products/slice'
import { dispatchGetTrialPricing } from '../../../data/master-products/api'
import { selectTrialPricingByMasterProductId } from '../../../data/master-products/slice'
import { APIUpdateProduct } from '../../../data/products/interfaces'
import { calculateMarkupNumber } from '../../../data/products/utils'
import { trialPriceByCategory } from '../../../data/master-products/utils'
import { HelpPopover } from '../../start-guide/common-components/HelpPopover'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { ADDING_TRIAL_PRICING, LOADING_TRIAL_PRICING } from '../../../data/master-products/constants'
import { selectLoadingState } from '../../../core/loading/slice'
import { Loading } from '../../../mini-lib/loading/Loading'
import { dispatchUpdateUserChecklistItem } from '../../../data/start-guide/api'
import { reduceSetStepCompleteDrawerState, selectStepCompleteDrawerState } from '../../../data/start-guide/slice'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { UseViewSize } from '../../../core/UseViewSize'
import { Gap } from '../../../mini-lib/gap/Gap'
import { toast } from "react-toastify";
import { HELP_DRAWER_STATES } from '../../start-guide/common-components/HelpStepCompleteDrawer'
import { ReleaseLaraColors } from "../../../mini-lib/flags/Release";

export const TrialPricingButton = () => {
  const [showModal, setShowModal] = useState(false)
  const { isMobile } = UseViewSize()
  const isAddingPricing = useAppSelector(( state ) => selectLoadingState(state, ADDING_TRIAL_PRICING))
  const queryParams: any = UseQueryParams()
  const guideType = queryParams.get('guide')
  return (
    <>
      <HelpPopover
        description={
          guideType === CHECKLIST_CODES.autoPricing
            ? 'Automatically Set your Product Costs'
            : `If you get stuck you can automatically add costs to your products. Don't worry, you can update them later!`
        }
        stepIndex={guideType === CHECKLIST_CODES.autoPricing ? 0 : 3}
        guideTypes={[CHECKLIST_CODES.autoPricing, CHECKLIST_CODES.addPricing]}
      >
        <Button minWidth="140px" variant="round-outline" colorScheme="brand.midnight" onClick={() => setShowModal(true)}>
          {isAddingPricing ? <Loading/> : isMobile ? 'Quick pricing' : 'Quick Start Pricing'}
        </Button>
      </HelpPopover>
      {showModal && <TrialPricingModal showModal={showModal} setShowModal={setShowModal}/>}
    </>
  )
}

export const TrialPricingModal = ( props: { showModal: boolean; setShowModal: ( updated: boolean ) => void } ) => {
  const { showModal, setShowModal } = props
  const dispatch = useDispatch()
  const products = useAppSelector(selectProductList)
  const trialPricingByMasterProductId = useAppSelector(selectTrialPricingByMasterProductId)
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const loadingTrialPricing = useAppSelector((state) => selectLoadingState(state, LOADING_TRIAL_PRICING))
  const productsWithNoPricing = products ? products.filter(( product ) => product.inventory.cost === 0) : null
  const masterProductIdsForProductsWithNoPricing: number[] | null = productsWithNoPricing
    ? productsWithNoPricing.map(( product ) => product.masterProductId)
    : null
  const productsLoaded = !!products

  const { user } = UseBaseApiParams()
  const {
    salonId,
    user: { token },
  } = UseBaseApiParams()

  const releaseLaraColors = ReleaseLaraColors()
  useEffect(() => {
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, releaseLaraColors])

  useEffect(() => {
    if (productsLoaded && masterProductIdsForProductsWithNoPricing) {
      dispatch(dispatchGetTrialPricing({ token, masterProductIds: masterProductIdsForProductsWithNoPricing }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productsLoaded, token])

  const onConfirm = () => {
    const defaultMarkup = user.currentSalonContext?.defaultMarkupPercentage || 0

    if (trialPricingByMasterProductId && productsWithNoPricing && productsWithNoPricing.length > 0) {
      const updatedProducts: APIUpdateProduct[] = productsWithNoPricing.map(( product ) => {
        const trialPrice =
          trialPricingByMasterProductId[product.masterProductId]?.trialPrice ||
          trialPriceByCategory[product.category] ||
          0

        const currentMarkup = calculateMarkupNumber(product)
        return {
          product_id: product.id,
          is_default_price: true,
          wholesale_price: trialPrice,
          master_product_id: product.masterProductId,
          mark_up: currentMarkup ? currentMarkup : defaultMarkup,
        }
      })
      const params = {
        token: user.token,
        user_id: user.userId,
        salon_id: salonId,
        models: updatedProducts,
        loadingName: ADDING_TRIAL_PRICING,
      }
      dispatch(dispatchUpdateProducts(params))
      dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: CHECKLIST_CODES.autoPricing }))
      if (stepCompleteDrawerState !== HELP_DRAWER_STATES.button) {
        dispatch(reduceSetStepCompleteDrawerState('drawer'))
      }
      setShowModal(false)
    } else {
      if (!trialPricingByMasterProductId) {
        toast.error('Failed to load quick product prices')
      }
      if (!productsWithNoPricing || (productsWithNoPricing && productsWithNoPricing.length === 0)) {
        toast.success('All of your prices are already set! No prices were updated.')
        setShowModal(false)
      }
    }
  }
  return (
    <ConfirmModal
      showModal={showModal}
      title={
        <Flex gridGap="12px" align="center">
          <MaterialIcon colorhex={COLORS.linen_500} name="warning"/>{' '}
          <Text variant="title1"> Auto Adding Product Costs</Text>
        </Flex>
      }
      subtitle={<>
        Quick start pricing is based on the average product cost from other SalonScale customers’ backbars, which may
        differ from your stocked products.
        <Gap/>
        Use quick start pricing to learn the app features. You can update your pricing at anytime to customize and
        optimize your backbar pricing.
      </>

    }
      onConfirm={onConfirm}
      confirmOverride={
      <Button
        variant="round"
        colorScheme='brand.midnight'
        onClick={() => {
          if (!loadingTrialPricing) {
            onConfirm()
          }
        }}
      >
        {loadingTrialPricing ? <Flex gridGap='8px' align='center'><Loading size='20px' variant='clear' colorhex='white'/> loading prices</Flex> : 'I Understand'}
      </Button>
      }
      onCancel={() => setShowModal(false)}
      theme="midnight"
    />
  )
}

export const TrialPricingTooltip = () => {
  return (<Tooltip label='* indicates that product(s) are using quick start pricing'><Text>*</Text></Tooltip>)
}

export const TrialPricingBanner = () => {
  return (
    <Flex width="100%" align="center" p="20px" borderRadius="15px" bg={COLORS.lavender_50} maxH="50px">
      <Text color={COLORS.lavender_500}>* indicates that product(s) are using quick start pricing</Text>
    </Flex>
  )
}
