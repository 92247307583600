import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'

import {
  reduceSelectedProductMap,
  reduceSetLineDetailsSheetId,
  reduceSetSelectedProductIds,
  selectSelectedProducts,
} from '../../../data/products/slice'
import { Product } from '../../../data/products/interfaces'
import { SalonScaleProductIcon } from '../../../mini-lib/icons/SalonScaleProductIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'
import { ProductAutoSaveForm, ProductAutoSaveFormTitleRow } from '../products/ProductAutoSaveForm'
import { selectChecklistItemsByCode } from '../../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../../data/start-guide/api'
import { ReleaseLaraColors, ReleaseUnitsSupport } from "../../../mini-lib/flags/Release";
import { ConfirmPopover } from "../../../mini-lib/confirm-popover/ConfirmPopover";
import { maybePluralize } from "../../../core/text";
import { dispatchDeleteProducts } from "../../../data/products/api";
import { Gap } from "../../../mini-lib/gap/Gap";

export const ProductDetailsSheet = () => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const products = useAppSelector(selectSelectedProducts)
  const product: Product | null = products ? products[0] : null
  const uniqId = product ? product.id : 1
  const checklistMap = useAppSelector(selectChecklistItemsByCode)
  const modelsLoaded = !!checklistMap

  useEffect(() => {
    if (!modelsLoaded && user.token) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, modelsLoaded, user.token, user.userId])

  const releaseUnitsSupport = ReleaseUnitsSupport();
  const releaseLaraColors = ReleaseLaraColors();
  const staticUnit = 'g or ml'
  const units = releaseUnitsSupport ? product?.units ?? staticUnit : staticUnit
  const onDrawerClose = () => {
    dispatch(reduceSetSelectedProductIds(null))
    dispatch(reduceSelectedProductMap({}))
    dispatch(reduceSetLineDetailsSheetId(uniqId))
  }

  return (
    <>
      {products && (
        <Drawer
          size="lg"
          isOpen={!!products}
          placement="right"
          onClose={onDrawerClose}
        >
          <DrawerOverlay/>
          <DrawerCloseButton/>
          <DrawerContent>
            <DrawerHeader>
              <DisableAutofocus/>
              {product && products.length === 1 && (
                <>
                  <Flex justify="center">
                    <Text variant="largetitle" textTransform="uppercase">
                      Edit Product
                    </Text>
                    <Button
                      position='fixed'
                      top='12px'
                      right='12px'
                      variant="round-ghost"
                      colorScheme="brand.lavender"
                      onClick={() => {
                        dispatch(reduceSelectedProductMap({}))
                        dispatch(reduceSetLineDetailsSheetId(uniqId))
                        dispatch(reduceSetSelectedProductIds(null))
                      }}
                    >
                      Close
                    </Button>
                  </Flex>
                  <Flex justify="center" align="center" w="100%" direction="column">
                    <Box h="24px"/>
                    <SalonScaleProductIcon size="120px" name={product.category}/>
                    <Box h="24px"/>
                    <Text variant="title1">
                      {product.type} • {product.line.name}
                    </Text>
                    <Box h="12px"/>
                    <Text variant="body" fontWeight="normal" color={COLORS.text_secondary}>
                      {product.category} • {product.size}{units}
                    </Text>
                  </Flex>
                </>
              )}
              {products.length > 1 && (
                <Flex justify="center">
                  <Box textAlign='center'>
                    <Text variant="largetitle" textTransform="uppercase">
                      Edit ({products.length}) Products
                    </Text>
                    <Gap size='4px'/>
                    {/*<Text variant="headline" fontWeight="normal">*/}
                    {/*  Editing Fields for {products.length} Products*/}
                    {/*</Text>*/}
                    <Text variant="headline"  fontWeight="normal">
                      each field will update the selected products independently, please set wholesale before markup
                    </Text>
                  </Box>
                  <Button
                    position='fixed'
                    top='12px'
                    right='12px'
                    variant="round-ghost"
                    colorScheme="brand.lavender"
                    onClick={() => {
                      dispatch(reduceSelectedProductMap({}))
                      dispatch(reduceSetLineDetailsSheetId(uniqId))
                      dispatch(reduceSetSelectedProductIds(null))
                    }}
                  >
                    Close
                  </Button>
                </Flex>
              )}
            </DrawerHeader>

            <DrawerBody>
              <Box h="24px"/>
              <ProductAutoSaveFormTitleRow willOnlySetValueForOneProduct={products.length === 1} showDelete={false}/>
              {product && <ProductAutoSaveForm products={products} showDelete={false}/>}
            </DrawerBody>
            <DrawerFooter>
              {products && (
                <Flex gridGap="12px" justify="flex-end" mt="24px" w="100%">

                  <ConfirmPopover
                    title={`This Action is Permanent`}
                    subtitle={`Deleted extensions will no longer appear in your salon.`}
                    onConfirm={() => {

                      dispatch(dispatchDeleteProducts({
                        token: user.token,
                        salonId,
                        models: products ? products : [],
                        loadingName: 'bulk-delete-extensions',
                        releaseLaraColors
                      }))
                      // close and clear both drawers
                      // todo: add drawer state and use it to close drawers here
                      dispatch(reduceSelectedProductMap({}))
                      dispatch(reduceSetLineDetailsSheetId(uniqId))
                      dispatch(reduceSetSelectedProductIds(null))
                    }}
                  >
                    <Button
                      variant="round-outline"
                      colorScheme="brand.midnight"
                    >
                      Delete {products.length > 1 ? `(${products.length})` : ''} {maybePluralize('Product', products.length)}
                    </Button>

                  </ConfirmPopover>

                  <Button
                    variant="round"
                    colorScheme="brand.midnight"
                    onClick={onDrawerClose}
                  >
                    done
                  </Button>
                </Flex>
              )}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}
