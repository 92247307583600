import { Text } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

export const InputError = ( props: { showError: boolean; children?: ReactNode; errorText?: string }) => {
  const { showError, errorText, children } = props
  return (
    <>
      {showError && (
        <Text color="red.500" fontSize="12px" mt="4px">
          {errorText}
          {children}
        </Text>
      )}
    </>
  )
}
