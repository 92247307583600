import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationForUser, Notification } from './interfaces'
import { RootState } from '../../store'
import { assign, keyBy, omit, pickBy, isEqual } from 'lodash'
import { separateNotificationsByDate } from './utils'

//State
interface NotificationState {
  userNotificationsById: { [key: number]: NotificationForUser } | null
  adminNotificationsById: { [key: number]: Notification } | null
  selectedNotification: Notification | null
}

const initialState: NotificationState = {
  userNotificationsById: {},
  adminNotificationsById: {},
  selectedNotification: null
}

// reducer
//
export const NotificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {

    reduceListNotificationsForAdmin: (state, action: PayloadAction<Notification[]>) => {
      state.adminNotificationsById = assign({}, state.adminNotificationsById, keyBy(action.payload, 'id'))
    },

    reduceCreateNotificationForAdmin: (state, action: PayloadAction<Notification>) => {
      state.adminNotificationsById && (state.adminNotificationsById[action.payload.id] = action.payload)
    },

    reduceUpdateNotificationForAdmin: (state, action: PayloadAction<Notification>) => {
      state.adminNotificationsById && (state.adminNotificationsById[action.payload.id] = action.payload)
    },

    reduceDeleteNotificationForAdmin: (state, action: PayloadAction<number>) => {
      state.adminNotificationsById = omit(state.adminNotificationsById, action.payload)
    },

    reduceSelectNotification: (state, action: PayloadAction<Notification | null>) => {
      state.selectedNotification = action.payload
    },

    reduceDeleteNotificationForUser: (state, action: PayloadAction<number>) => {
      state.userNotificationsById = omit(state.userNotificationsById, action.payload)
    },

    reduceListNotificationsForUser: (state, action: PayloadAction<NotificationForUser[]>) => {
      state.userNotificationsById = assign({}, state.userNotificationsById, keyBy(action.payload, 'id'))
    },

    reduceUpdateNotificationForUser: (state, action: PayloadAction<NotificationForUser>) => {
      state.userNotificationsById && (state.userNotificationsById[action.payload.id] = action.payload)
    },
  },
})

export const {
  reduceListNotificationsForUser,
  reduceUpdateNotificationForUser,
  reduceDeleteNotificationForUser,
  reduceCreateNotificationForAdmin,
  reduceDeleteNotificationForAdmin,
  reduceListNotificationsForAdmin,
  reduceUpdateNotificationForAdmin,
  reduceSelectNotification
} = NotificationSlice.actions

// selector
//
// user
export const selectAllUserNotificationsById = (state: RootState): { [key: number]: NotificationForUser } | null => {
  return state.notification.userNotificationsById
}

export const selectUnreadUserNotificationsId = (state: RootState): { [key: number]: NotificationForUser } | null => {
  const userNotificationsById = state?.notification?.userNotificationsById ?? {};
  const unreadNotifications = pickBy(userNotificationsById, (notification) => isEqual(notification.read, null))
  return unreadNotifications
}

// admin

export const selectAllAdminNotificationsById = (state: RootState): { [key: number]: Notification } | null => {
  return state.notification.adminNotificationsById
}

export const selectPendingNotificationsById = (state: RootState): { [key: number]: Notification } | null => {
  const { pendingNotifications } = separateNotificationsByDate(state.notification.adminNotificationsById)
  return pendingNotifications
}

export const selectSentNotificationsById = (state: RootState): { [key: number]: Notification } | null => {
  const { sentNotifications } = separateNotificationsByDate(state.notification.adminNotificationsById)
  return sentNotifications
}

export const selectViewedNotificationsById = (state: RootState): { [key: number]: Notification } | null => {
  return pickBy(state.notification.userNotificationsById, notification=>Boolean(notification.viewed))
}

export const selectUnviewedNotificationsById = (state: RootState): { [key: number]: Notification } | null => {
 return pickBy(state.notification.userNotificationsById, notification=>!Boolean(notification.viewed))
}

export const selectSelectedNotification = (state: RootState): Notification | null => {
  return state.notification.selectedNotification
}

export default NotificationSlice.reducer
