// @flow
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Checkbox,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { dispatchPatchUserLara } from '../../data/user/api'
import { InputControl } from 'formik-chakra-ui'
import { FileUpload } from '../../mini-lib/file-upload/FileUpload'
import { COLORS } from '../../mini-lib/theme/colors'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { dispatchUpdateChargebeeCustomer } from "../../integrations/chargebee/api";
import { useAppSelector } from "../../hooks";
import { selectFileUrlUploadByName } from "../../core/file-upload/slice";
import { isImageType } from "../../core/validators";
import { dispatchUploadFile } from "../../core/file-upload/api";
import { toast } from "react-toastify";
import { SalonScaleIcon } from "../../mini-lib/icons/SalonScaleIcon";
import { getIsUserOwnerOfAnySalon } from '../../data/user/utils'

export const UserSettingsSideSheet = ( props: { show: boolean; setShow: ( show: boolean ) => void } ) => {
  const { show, setShow } = props
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const [updateChargebeeEmail, setUpdateChargebeeEmail] = useState(true)

  const isOwnerOfAnySalon = getIsUserOwnerOfAnySalon(user)
  const photoUploadName = 'photo-upload'
  const uploadedPhotoUrl = useAppSelector(( state ) => selectFileUrlUploadByName(state, photoUploadName))
  const [photoUrl, setPhotoUrl] = useState<string>('')

  useEffect(() => {
    if (uploadedPhotoUrl) {
      setPhotoUrl(uploadedPhotoUrl)
    }
  }, [uploadedPhotoUrl])

  const initialValues = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
  }
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required(),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
  })
  const onSubmit = ( values ) => {
    if (!values.email || !values.firstName || !values.lastName) {
      return
    }
    const body = {
      first_name: values.firstName,
      last_name: values.lastName,
      email_address: values.email,
    }
    if (photoUrl) {
      body['user_photo_url'] = photoUrl
    }
    dispatch(dispatchPatchUserLara({ token: user.token, userId: user.userId, salonId, body }))

    if (isOwnerOfAnySalon && updateChargebeeEmail) {
      dispatch(dispatchUpdateChargebeeCustomer({ token: user.token, userId: user.userId, email: values.email }))
    }
    setShow(false)
  }


  const onFileUpload = ( file: File | null ) => {
    if (file && isImageType(file.type)) {
      dispatch(dispatchUploadFile({ token: user.token, uploadName: photoUploadName, file }))
    } else {
      toast.error('Please enter a valid image')
    }
  }

  return (
    <Drawer placement="right" onClose={() => setShow(false)} isOpen={show}>
      <DrawerOverlay/>
      <DrawerContent>
        <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
          {( { handleSubmit, values, resetForm, errors } ) => (
            <>
              <DrawerHeader>
                <Text variant="title2">Update Profile</Text>
              </DrawerHeader>
              <DrawerBody>
                <Flex justify="center">
                  <Circle size="100px" position="relative" bg={COLORS.shades_neutral_200} p="4px">
                    <Circle overflow="hidden">
                      {photoUrl || user.photoUrl
                        ? <Image h="90px" w="90px" src={photoUrl || user.photoUrl}/>
                        : <SalonScaleIcon size="90px" h="90px" w="90px" name="profile"/>
                      }
                      <Circle
                        cursor="pointer"
                        borderRadius="100px"
                        size="32px"
                        p="4px"
                        bg="white"
                        border={`1px solid ${COLORS.lavender_500}`}
                        position="absolute"
                        bottom={0}
                        right={0}
                      >
                        <FileUpload
                          override={<MaterialIcon colorhex={COLORS.lavender_500} name="photo_camera"/>}
                          onFileUpload={( file ) => onFileUpload(file)}
                        />
                      </Circle>
                    </Circle>
                  </Circle>
                </Flex>

                <Box h="32px"/>
                <InputControl
                  inputProps={{ autoFocus: true, borderRadius: '100px' }}
                  name="firstName"
                  label="First Name"
                  labelProps={{ color: COLORS.text_secondary }}
                />
                <Box h="24px"/>
                <InputControl
                  inputProps={{ borderRadius: '100px' }}
                  name="lastName"
                  label="Last Name"
                  labelProps={{ color: COLORS.text_secondary }}
                />
                <Box h="24px"/>

                <InputControl
                  inputProps={{ autoFocus: true, borderRadius: '100px' }}
                  name="email"
                  label="Email"
                  labelProps={{ color: COLORS.text_secondary }}
                />
                <Box h="12px"/>
                { isOwnerOfAnySalon &&
                  <Flex align="center">
                    <Checkbox
                      isChecked={updateChargebeeEmail}
                      colorScheme="brand.lavender"
                      onChange={() => setUpdateChargebeeEmail(!updateChargebeeEmail)}
                    >
                      <Text variant="footnote" color="shades.neutral.500">
                        Also update my billing email
                      </Text>
                    </Checkbox>
                  </Flex>
                }
                <Box h="24px"/>
              </DrawerBody>
              <DrawerFooter>
                <Button minW="100px" variant="round-outline" mr={3} onClick={() => setShow(false)}>
                  Cancel
                </Button>
                <Button minW="100px" variant="round" colorScheme="brand.lavender" onClick={() => handleSubmit()}>
                  Save
                </Button>
              </DrawerFooter>
            </>
          )}
        </Formik>
      </DrawerContent>
    </Drawer>
  )
}
