import { useEffect, useState } from 'react'
import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Switch, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { reduceSetSalonType, reduceSetSubscriptionType, selectSalonType } from '../../../data/signup/slice'
import { useAppSelector } from '../../../hooks'
import { scrollToId } from '../../../mini-lib/utils/basic'
import { UseViewSize } from '../../../core/UseViewSize'
import { generatePath, Link } from 'react-router-dom'
import { TopNav } from '../../../mini-lib/top-nav/TopNav'
import { SignupFooter } from './SignupFooter'
import { dispatchGetLoggedInUser } from '../../../data/user/api'
import { UserTransferFromAngular } from '../../../AuthDataLoadContainer'
import { InputControl } from 'formik-chakra-ui'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { getLocalStorageItem, setLocalStorageItem } from '../../../core/localStorage'
import { SIGNUP_LOCALSTORAGE_KEYS } from '../../../data/signup/constants'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { ReleaseSpecialPricing } from '../../../mini-lib/flags/Release'
import { CBSalonType, CBSubscriptionType } from '../../../integrations/chargebee/constants'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { UseBuildSignupQueryParams, UseCanSeeScales, UseInitializeSignupState } from './signupHooks'
import { UseUtmQueryParams } from '../../../integrations/utm'
import { Helmet } from 'react-helmet'
import { Gap } from "../../../mini-lib/gap/Gap";
import { COLORS } from "../../../mini-lib/theme/colors";
import { createUpdateActiveCampaignContact } from "../../../integrations/activecampagin/activecampagin";
import { ROUTES } from "../../../appRoutes";

export const PlanPage = () => {
  const dispatch = useDispatch()
  UseInitializeSignupState()
  const canSeeScales = UseCanSeeScales()
  const headerUrl = require('../../../assets/signup/plan-header-desktop.png').default
  const headerMobileUrl = require('../../../assets/signup/plan-header-mobile.png').default

  const specialPricingHeaderUrl = require('../../../assets/signup/dec19-plan-header-desktop.png').default
  const specialPricingHeaderMobileUrl = require('../../../assets/signup/dec19-plan-header-mobile.png').default

  const salonType = useAppSelector(selectSalonType)
  const { isMobile, isTablet } = UseViewSize()
  const { token, userId } = UserTransferFromAngular()
  const releaseSpecialPricing = ReleaseSpecialPricing()

  const planBackgroundUrl = isMobile
    ? releaseSpecialPricing ? `url(${specialPricingHeaderMobileUrl})` : `url(${headerMobileUrl})`
    : releaseSpecialPricing ? `url(${specialPricingHeaderUrl})` : `url(${headerUrl})`

  // get logged in user from angular transfer
  useEffect(() => {
    if (token && userId) {
      // todo: replace with lara
      dispatch(dispatchGetLoggedInUser({ token, userId }))
    }
  }, [token, userId, dispatch])

  const toggleSalonType = () => {
    if (salonType === 'team') {
      dispatch(reduceSetSalonType('solo'))
    } else {
      dispatch(reduceSetSalonType('team'))
    }
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Signup</title>
        {/*experiment: trying to get google linksets to pick this description up */}
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
        <meta name="description" content="Select your plan and start with SalonScale today!" />
      </Helmet>
      <TopNav />

      {/* <EmailGrabDialog /> */}

      {/* header */}
      <Flex
        height={isTablet ? '500px' : isMobile ? '700px': '800px'}
        align="center"
        justify={'flex-start'}
        direction="column"
        textAlign="center"
        p={isMobile ? '100px 0' : '100px 0'}
        backgroundSize={"100% 100%"}
        backgroundRepeat="no-repeat"
        backgroundImage={planBackgroundUrl}
      >

        {releaseSpecialPricing
          ? <>
            <Text
              variant='title1'
              color={COLORS.midnight_900}
            >
              LIMITED TIME OFFER
            </Text>
            <Text
              mb={isMobile ? '' : '-24px'}
              variant={'largetitle'}
              color={COLORS.midnight_900}
              fontSize={isMobile ? '64px' : '92px'}
            >
              BUY ONE YEAR
            </Text>
            <Text
              variant={'largetitle'}
              color={COLORS.midnight_900}
              fontSize={isMobile ? '64px' : '92px'}
            >
              GET YOUR SECOND FREE
            </Text>
          </>
          : <>
            <Text
              variant={isMobile ? 'largetitle' : 'gigantictext'}
              color="brand.midnight.900"
              mb={isMobile ? '' : '-30px'}
            >
              GET STARTED
            </Text>
            <Text
              variant={isMobile ? 'largetitle' : 'gigantictext'}
              color="brand.midnight.900"
              mb={isMobile ? '' : '-30px'}
            >
              WITH SALONSCALE
            </Text>
            <Text variant={isMobile ? 'largetitle' : 'gigantictext'} color="white">
              IN 3 EASY STEPS!
            </Text>
          </>
        }
        <Box h="24px" />
        {!releaseSpecialPricing &&
            <>
                <Flex color="white" flexWrap="wrap" gridGap="12px" textTransform="uppercase">
                    <Flex pl={isMobile ? '25%' : ''} align="center" w={isMobile ? '100%' : ''}>
                        <StepNumber stepNumber={1}/>
                        <Text variant="title3">Choose subscription</Text>
                    </Flex>
                    <Flex pl={isMobile ? '25%' : ''} gridGap='2px' align="center" w={isMobile ? '100%' : ''}>
                        <StepNumber stepNumber={2}/>
                        <Text variant="title3">Select Add-Ons</Text>
                    </Flex>
                    <Flex pl={isMobile ? '25%' : ''} align="center" w={isMobile ? '100%' : ''}>
                        <StepNumber stepNumber={3}/>
                        <Text variant="title3">accessorize</Text>
                    </Flex>
                </Flex>
                <Box h="48px"/>
            </>
        }
        <Button
          p='0 48px'
          variant="round"
          background={COLORS.midnight_500}
          onClick={() => {
            scrollToId('signup-step-1')
          }}
        >
          Let's Get Started!
        </Button>
      </Flex>

      {/* note: keep this box as it is an anchor for the autoscrolling */}
      <Box id="signup-step-1" h='72px'/>

      {releaseSpecialPricing && <Banner />}

      {/* toggle */}
      <Flex justify="center" align="center" textTransform="uppercase" gridGap="24px" m="24px 0">
        <Text variant={isMobile ? 'title1' : 'largetitle'} fontWeight="bold">
          for solos
        </Text>
        <Switch
          sx={{ baseStyle: { bg: releaseSpecialPricing ? 'black' : 'brand.lavender.500' } }}
          isChecked={salonType === 'team'}
          size="lg"
          colorScheme='brand.midnight'
          onChange={toggleSalonType}
        />
        <Text variant={isMobile ? 'title1' : 'largetitle'} fontWeight="bold">
          for teams
        </Text>
      </Flex>

      {/* solo */}
      {salonType === 'solo' && (
        <>
          {/* plan info */}
          <Flex justify="center" align="center" direction="column" m="32px 0">
            <Text variant="gigantictext">solo plans</Text>
            <Box h="12px" />
            <Text variant="title1" fontWeight="bold">
              1 Stylist
            </Text>
            <Box h="12px" />
            <Text maxWidth="550px" textAlign="center" p="0 12px" lineHeight="138%">
              Solo plans are meant for the independent business operator with one person using the subscription. Whether
              you rent a chair, operate out of a suite, or are a nomadic stylist, if you fly solo this is the option for
              you!
            </Text>
          </Flex>
          {/*{releaseSpecialPricing && <SaleBanner bghex={COLORS.lavender_100} colorhex={COLORS.lavender_500} />}*/}
          {/* subscription options */}
          <Flex
            justify="center"
            align="end"
            gridGap={isMobile ? '' : '24px'}
            m={isMobile ? '32px 12px' : '32px 0'}
            wrap={isMobile ? 'wrap' : 'nowrap'}
          >
            <SubscriptionCard
              minH='450px'
              saveText={releaseSpecialPricing ? 'BOGO EXCLUSIVE' : "Total Savings $165"}
              title="Annual Plan"
              price={releaseSpecialPricing ? '$555' : "$47"}
              priceSubtitle={releaseSpecialPricing
                ? <><Text>Total Value $1380</Text><Text fontSize='14px'>Billed in USD</Text></>
                : "per month, billed annually at $555 USD"}
              subtitle={''}
              subtitleSubtitle={''}
              bulletPoints={[
                'Step-by-step start guide to help you along the way to get started',
                ...(canSeeScales ? ['Scale Included'] : []),
                'Save $165 per year with annual',
              ]}
              theme="lavender"
              isSelected={true}
              subscriptionType="yearly"
              salonType={salonType}
              isSpecialPricing={releaseSpecialPricing}
            />
            <SubscriptionCard
              minH='450px'
              saveText=""
              title="Monthly Plan"
              price="$55"
              priceSubtitle="Billed monthly in USD"
              subtitle=""
              subtitleSubtitle={''}
              bulletPoints={[
                'Step-by-step start guide to help you along the way to get started',
                ...(canSeeScales ? ['Scale Included'] : []),
              ]}
              theme={releaseSpecialPricing ? 'midnight' : 'lavender'}
              isSelected={false}
              subscriptionType="monthly"
              salonType={salonType}
              isSpecialPricing={false}
            />
          </Flex>
        </>
      )}

      {/* team plan info */}
      {salonType === 'team' && (
        <>
          <Flex justify="center" align="center" direction="column" m="32px 0">
            <Text variant="gigantictext">team plans</Text>
            <Box h="12px" />
            <Text variant="title1" fontWeight="bold">
              unlimited stylists
            </Text>
            <Box h="12px" />
            <Text maxWidth="550px" minH="150px" textAlign="center">
              Whether your salon has 30 stylists or 2, SalonScale will help you manage your team, track your usage and
              ensure every appointment is a profitable one. Our team plans include the same features as our solo plans
              but include the ability to add unlimited stylists to your account. You can also set permissions in your
              team account to help define roles to increase efficiency in your salon.
            </Text>
          </Flex>

          {/*{releaseSpecialPricing && <SaleBanner bghex={COLORS.midnight_50} colorhex={COLORS.midnight_500} />}*/}
          {/* subscription options */}
          <Flex
            justify="center"
            align="end"
            gridGap={isMobile ? '' : '24px'}
            m={isMobile ? '32px 12px' : '32px 0'}
            wrap={isMobile ? 'wrap' : 'nowrap'}
          >
            <SubscriptionCard
              minH={'500px'}
              saveText={releaseSpecialPricing ? "BOGO EXCLUSIVE" : "Total Savings $349"}
              title="Annual Plan"
              price={releaseSpecialPricing ? '$1499' : "$125"}
              priceSubtitle={releaseSpecialPricing
                ? <><Text>Total Value $3636</Text><Text fontSize='14px'>Billed in USD</Text></>
                : "per month, billed annually at $1499 USD"
            }
              subtitle=''
              subtitleSubtitle=""
              bulletPoints={[
                'Step-by-step start guide to help you along the way to get started',
                'Account Manager included to help you launch',
                ...(canSeeScales ? ['Scale Included'] : []),
                'Save $349 per year with annual',
              ]}
              theme="midnight"
              isSelected={true}
              subscriptionType="yearly"
              salonType={salonType}
              isSpecialPricing={releaseSpecialPricing}
            />
            <SubscriptionCard
              minH={'500px'}
              saveText=""
              title="Monthly Plan"
              price="$149"
              priceSubtitle="Billed monthly in USD"
              subtitle=""
              subtitleSubtitle={''}
              bulletPoints={[
                'Step-by-step start guide to help you along the way to get started',
                ...(canSeeScales ? ['Scale Included'] : []),
              ]}
              theme="midnight"
              isSelected={false}
              subscriptionType="monthly"
              salonType={salonType}
              isSpecialPricing={false}
            />
          </Flex>
        </>
      )}

      {/* help */}
      <Flex align='center' justify="center" mb="24px" direction='column'>
        {canSeeScales && (
          <>
            <Text fontStyle="italic">*Scales can only be shipped to customers in USA and Canada*
              <a target="_blank" rel="noreferrer"
                 href="https://helpcenter.salonscale.com/en/articles/6457888-is-salonscale-available-outside-of-north-america">
                {" "} <Text as="span" textDecoration="underline">Click to Learn More</Text>
              </a>
            </Text>
            <Gap/>
          </>
        )}
        <a target="_blank" rel="noreferrer" href="https://salonscale.com/product/">
          <Button variant='round-outline' color={COLORS.lavender_500} borderColor={COLORS.lavender_500}>
            See All Software Features
          </Button>
        </a>
      </Flex>

      {/* help footer */}
      <Flex align="center" justify="center" direction="column" p="100px 0" background={releaseSpecialPricing ? 'black' : COLORS.midnight_900}>
        <Text
          variant={isMobile ? 'title1' : 'gigantictext'}
          fontWeight="bold"
          color="white"
          mb={isMobile ? '-4px' : '-20px'}
          textAlign='center'
        >
          {salonType === 'team' ? 'NEED HELP PICKING A PLAN?' : 'Learn more about how salonscale can work for you'}
        </Text>
        <Gap s='12px'/>
        <Text variant={isMobile ? 'title2' : 'giganticsubtitle'} color="shades.neutral.500">
          {salonType === 'team' ? 'TALK TO A SALONSCALE EXPERT' : 'Sips and Salonscale webinar'}
        </Text>
        <Box h="32px" />

        <Flex gridGap='12px' align='center' justify='center' wrap='wrap'>
          {/*<a target="_blank" rel="noreferrer" href="https://meetings.hubspot.com/whitney56/intro-chat-with-whitney-2">*/}
          {/*<a target="_blank" rel="noreferrer" href="https://us02web.zoom.us/webinar/register/WN_k44CXlEESiGwUIoZXwMM4w?__hstc=260738642.[…]280828198&submissionGuid=484b3c83-4736-4d45-9bc2-d8128da4cc1c">*/}
          {/*<a target="_blank" rel="noreferrer" href="https://calendly.com/salonscalecustomersuccess/30-minute-demo-with-salonscale-expert">*/}

          {/* marketing site landing page */}
          {/* https://salonscale.com/book-a-demo/ */}
          <a
            target="_blank"
            rel="noreferrer"
            href={salonType === 'team' ? 'https://calendly.com/d/5gf-58v-jpr/book-a-30-minute-live-demo-with-a-salonscale-expert?month=2024-03' : 'https://us02web.zoom.us/meeting/register/tZIsc-qspj0pHtLupPkuxj3Npf4n7WDaL9Go#/registration'}
          >
            <Button w={isMobile ? '100%' : "350px"} variant="round-outline">
              {salonType === 'team' ? 'book a demo' : 'sign up for webinar'}
            </Button>
          </a>
          {/*<Text w={isMobile ? '100%' : ""} textAlign='center' variant={isMobile ? 'title2' : 'giganticsubtitle'} fontSize='16px' color="shades.neutral.500">or</Text>*/}
          {/*<a target="_blank" rel="noreferrer" href="https://us02web.zoom.us/webinar/register/WN_k44CXlEESiGwUIoZXwMM4w">*/}
          {/*  <Button w={isMobile ? '100%' : "350px"} variant="round-outline">*/}
          {/*    book a webinar*/}
          {/*  </Button>*/}
          {/*</a>*/}
        </Flex>
      </Flex>

      {/* faq footer */}
      <Flex direction="column" p={isMobile ? '50px' : '100px'}>
        <Text
          variant={isMobile ? 'title2' : 'giganticsubtitle'}
          color="brand.midnight.900"
          mb={isMobile ? '' : '-20px'}
        >
          have questions?
        </Text>
        <Text variant={isMobile ? 'title1' : 'gigantictext'} fontWeight="bold" color="brand.midnight.900">
          we have answers.
        </Text>
        <Box h="32px" />
        <Flex gridGap={isMobile ? '40px 50px' : '40px 50px'} flexWrap="wrap">
          <Faq
            question="What forms of payment do you accept?"
            answer="We accept all forms of major credit card companies. We also accept Apple Pay and PayPal."
          />
          <Faq question="How will I be billed?" answer="We bill monthly or annually depending on your plan type." />
          <Faq
            question="Do I need to sign a contract?"
            answer="Nope! SalonScale does not require you to sign a contract; however, by signing up you are agreeing to our terms of use."
          />
          <Faq
            question="I’m not sure what plan is right for me. Is there someone I can talk to?"
            answer={
              <Text>
                Yes. Please give us a call at 1-833-697-2253, send us a text at 306-900-4373 or <a href="https://salonscale.com/book-a-demo/" target="_blank" rel="noopener noreferrer" style={{color: '#888BE2'}}>book a demo call</a>.
              </Text>
            }
          />
          <Faq
            question="Do I need a SalonScale bluetooth scale to use SalonScale?"
            answer="The bluetooth scale is a useful accessory to the SalonScale mobile app but it is not required."
          />
        </Flex>
        <Box h="48px" />
        {/* help footer */}
      </Flex>
      <Flex align="center" justify="center" direction="column" p="100px 0" background="brand.lavender.300">
        <Flex align="center">
          <Text variant={isMobile ? 'title1' : 'gigantictext'} fontWeight="bold" color="white" mr="8px">
            I'M IN!
          </Text>
          <Text variant={isMobile ? 'title2' : 'gigantictext'} color="black">
            SIGN ME UP!
          </Text>
        </Flex>
        <Box h="32px" />
        <Button
          variant="round"
          onClick={() => {
            scrollToId('signup-step-1')
          }}
        >
          Lets Get Started!
        </Button>
      </Flex>
      <SignupFooter />
    </div>
  )
}

export const EmailGrabDialog = (props: {}) => {
  // email grab logic
  const email = getLocalStorageItem('signup-email')
  const [showGrabDialog, setShowGrabDialog] = useState(!email)
  const { user } = UseBaseApiParams()
  useEffect(() => {
    if (user?.token) {
      setShowGrabDialog(false)
    }
  }, [user?.token])

  const { isMobile } = UseViewSize()
  const initialValues = {
    email: '',
  }
  const validationSchema = Yup.object({
    email: Yup.string().email('Please enter a valid email to continue').required('Please enter an email to continue'),
  })
  const utmParams = UseUtmQueryParams()
  const onEmailGrabbed = (email: string) => {
    createUpdateActiveCampaignContact({ email, utmParams }).then()

    setLocalStorageItem(SIGNUP_LOCALSTORAGE_KEYS.email, email)
    setShowGrabDialog(false)
    scrollToId('signup-step-1')
  }
  return (
    <>
      <Modal
        isOpen={showGrabDialog}
        onClose={() => {
          setShowGrabDialog(false)
        }}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent w="650px" maxW="90%">
          <ModalBody>
            <Flex direction="column" align="center" justify="center" p="48px">
              <Text variant={isMobile ? 'title2' : 'giganticsubtitle'} fontWeight="bold" textAlign="center">
                backbar management made simple.
              </Text>
              <Box h="24px" />
              <Text variant="title3" fontSize={isMobile ? '14px' : ''}>
                But first, we need your email.
              </Text>
              <Box h="48px" />
              <Formik
                onSubmit={(values) => onEmailGrabbed(values.email)}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ handleSubmit, values, resetForm, errors }) => (
                  <>
                    <InputControl name="email" inputProps={{ placeholder: 'Enter email address' }} />
                    <Box h="24px" />
                    <Button
                      variant="round"
                      colorScheme="brand.midnight"
                      w="100%"
                      fontSize={isMobile ? '' : '24px'}
                      h="60px"
                      onClick={() => handleSubmit()}
                    >
                      continue to pricing
                    </Button>
                  </>
                )}
              </Formik>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export const SubscriptionCard = (props: {
  saveText: string
  title: string
  price: string
  priceSubtitle: string | any
  subtitle: string
  subtitleSubtitle: string
  bulletPoints: string[]
  theme: string
  isSelected: boolean
  subscriptionType: CBSubscriptionType
  salonType: CBSalonType
  isSpecialPricing?: boolean
  minH?: string
}) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const {
    minH = 'auto',
    saveText,
    title,
    price,
    priceSubtitle,
    subtitle,
    subtitleSubtitle,
    bulletPoints,
    theme,
    isSelected,
    subscriptionType,
    salonType,
    // isSpecialPricing = false
  } = props
  const canSeeScales = UseCanSeeScales()
  const setSubscriptionType = () => {
    dispatch(reduceSetSubscriptionType(subscriptionType))
  }
  // const specialPricingBowUrl = require('../../../assets/signup/dec19-card-bow.png').default
  // const bowUrl = `url(${specialPricingBowUrl})`
  const themeColor = theme === 'midnight' ? COLORS.midnight_900 : COLORS.lavender_500
  return (
    <Box w={isMobile ? '100%' : '350px'} position='relative'>
      {saveText !== '' ? (
        <Flex
          margin="auto"
          h="60px"
          p="4px"
          w={'100%'}
          justify="center"
          textAlign="center"
          align="center"
          direction="column"
          borderRadius="15px 15px 0 0"
          background={themeColor}
          color="white"
        >
          <Text fontFamily='Oswald, sans-serif' fontSize={isMobile ? '20px' : '24px'}>
            {saveText}
          </Text>
        </Flex>
      ) : (
        <Box h="32px" />
      )}
      {/* {isSpecialPricing && (
        <Flex
          border='2px solid green'
          position='absolute'
          top='48px'
          w='100%'
          borderWidth='0 3px 0 3px'
          borderColor={COLORS.midnight_900}
          backgroundSize={"100% 100%"}
          backgroundRepeat="no-repeat"
          height='60px'
          backgroundImage={bowUrl}
        />
      )} */}
      <Flex
        w='100%'
        justify="space-between"
        align="center"
        direction="column"
        borderRadius={saveText !== '' ? "0 0 15px 15px" : '15px'}
        p={isMobile ? '24px' : '24px'}

        borderWidth={isSelected ? '3px' : '1px'}
        color={COLORS.midnight_900}
        borderColor={isSelected ? themeColor : `shades.neutral.500`}
        minH={minH}
      >
        <Flex 
          w='100%'
          justify="space-between"
          align="center"
          direction="column"
        >
          <Gap s='12px'/>
          <Text variant={isMobile ? 'title2' : 'title1'} fontWeight="bold">
            {title}
          </Text>
          <Text variant="gigantictext">{price}</Text>
          <Text h="24px" variant={isMobile ? 'footnote' : ''} textAlign="center">
            {priceSubtitle}
          </Text>
          <Box h="24px" />
          <Text variant={isMobile ? 'title3' : 'title2'} textAlign="center" textTransform="uppercase">
            {subtitle}
          </Text>
          <Text h={isMobile ? '24px' : '24px'} textAlign="center" variant={isMobile ? 'footnote' : ''} fontStyle='italic'>
            {subtitleSubtitle}
          </Text>
          <Box variant={isMobile ? 'footnote' : ''}>
            {bulletPoints.map((point, index) => {
              return <Flex key={index} gridGap='8px' align='center' p='4px 0'> <MaterialIcon name='check_circle_outline' colorhex={themeColor}/>{point}</Flex>
            })}
          </Box>
          <Gap/>
        </Flex>
        <Link
          to={{
            pathname: canSeeScales ? generatePath(ROUTES.signupAccessories) : generatePath(ROUTES.signupCheckout),
            search: UseBuildSignupQueryParams({ subscriptionType, salonType }),
          }}
        >
          <Button
            className="cy-next"
            variant="round"
            background={themeColor}
            color='white' onClick={setSubscriptionType}
          >
            choose this plan
          </Button>
        </Link>
      </Flex>
    </Box>
  )
}

export const StepNumber = (props: { stepNumber: string | number }) => {
  return (
    <Flex
      mr="6px"
      borderRadius="50%"
      h="32px"
      w="32px"
      justify="center"
      align="center"
      border="1px solid white"
      color="white"
    >
      {props.stepNumber}
    </Flex>
  )
}

export const Faq = (props: { question: string; answer: React.ReactNode }) => {
  const { question, answer } = props
  const { isMobile } = UseViewSize()
  return (
    <Box maxW="500px" w="100%">
      <Text fontSize="18px" lineHeight="126%" fontWeight="bold" color="brand.midnight.900" mb={isMobile ? '' : '-8px'}>
        {question}
      </Text>
      <Box h="12px" />
      <Text variant="footnote">{answer}</Text>
    </Box>
  )
}

export const Banner = () => {
  return (
    <Flex justify="center" align="center">
      <a target='_blank' rel="noreferrer" href='https://salonscale.com/2023-bogo-holiday-sale-existing-customers/'>
        <Button
          color={COLORS.midnight_900}
          bg={COLORS.midnight_100}
          textTransform='none'
          fontStyle='italic'
          borderRadius='50px'
          _hover={{bg: COLORS.midnight_200}}
          _active={{bg: COLORS.midnight_200}}
        >
          <MaterialIcon mr="4px" colorhex={COLORS.midnight_900} name="info" />Already a customer? <Text pl='4px' as='span' textDecoration='underline'>Click here!</Text>
        </Button>
      </a>
    </Flex>
  )
}
