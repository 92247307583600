import React, {useState} from 'react'
import {Box, Button, Divider, Flex, Radio, RadioGroup, Text} from '@chakra-ui/react'
import {Gap} from '../gap/Gap'
import {TextInput} from './TextInput'
import {SelectInput} from './SelectInput'
import {PhoneInput} from './PhoneInput'
import {PasswordInput} from './PasswordInput'
import {ThemeType} from "../../theme";
import {DateInput} from "../dates-and-times/DateInput";

// this is to show the different view states of our various components
// if a component isnt on this page we arent intending to use it long term or need to add it
export const MinilibKitchenSinkPage = () => {
  const [variant, setVariant] = React.useState<any>('material')
  const [errorState, setErrorState] = React.useState('noError')
  const [theme, setTheme] = React.useState<ThemeType | any>('basic')

  const [textField, setTextField] = useState('')
  const [selectField, setSelectField] = useState('')
  const [phoneField, setPhoneField] = useState('')
  const [passwordField, setPasswordField] = useState('')
  const [dateField, setDateField] = useState<Date | null | any>(null)

  const [buttonVariant, setButtonVariant] = useState<string>('round')
  const [textVariant, setTextVariant] = useState<string>('')

  const colorScheme = theme && theme !== 'basic' ? `brand.${theme}` : 'brand.midnight'
  return (
    <Flex justify="center">
      <Box w="450px">
        <Gap/>
        <Box>
          <RadioGroup onChange={setTheme} value={theme} colorScheme={colorScheme}>
            <Flex gridGap='24px'>
              <Radio value={'basic'}>default</Radio>
              <Radio value={'lavender'}>lavender</Radio>
              <Radio value={'midnight'}>midnight</Radio>
              <Radio value={'skylight'}>skylight</Radio>
              <Radio value={'peach'}>peach</Radio>
              <Radio value={'linen'}>linen</Radio>
            </Flex>
          </RadioGroup>

          <Gap/>
          <Divider/>
          <Gap/>
          <Text variant='title1'>Inputs</Text>
          <Gap s='12px'/>
          <RadioGroup onChange={setVariant} value={variant} colorScheme={colorScheme}>
            <Flex gridGap='24px'>
              <Radio value="material">material</Radio>
              <Radio value="basic">base</Radio>
            </Flex>
          </RadioGroup>
          <Gap s='12px'/>
          <RadioGroup onChange={setErrorState} value={errorState} colorScheme={colorScheme}>
            <Flex gridGap='24px'>
              <Radio value={'noError'}>error off</Radio>
              <Radio value={'error'}>error on</Radio>
            </Flex>
          </RadioGroup>
          <Gap s='12px'/>

        </Box>

        <Box w="100%">
          <Gap />
          <TextInput
            variant={variant}
            theme={theme}
            placeholder="enter text"
            label="Text Example"
            value={textField}
            errorText={errorState === 'error' ? 'error will appear here' : ''}
            onBlur={() => {}}
            onChange={setTextField}
            allowFocus={true}
          />

          <Gap s="48px" />

          <SelectInput
            variant={variant}
            theme={theme}
            placeholder="select an option"
            label="Select Example"
            options={[
              { label: 'option 1', value: '1' },
              { label: 'option 2', value: '2' },
            ]}
            value={selectField}
            errorText={errorState === 'error' ? 'error will appear here' : ''}
            onBlur={() => {}}
            onChange={setSelectField}
            allowFocus={true}
          />

          <Gap s="48px" />

          <PhoneInput
            variant={variant}
            theme={theme}
            label="Phone Example"
            value={phoneField}
            onChange={setPhoneField}
            onError={() => {}}
            allowFocus={true}
            errorText={errorState === 'error' ? 'error will appear here' : ''}
          />

          <Gap s="48px" />

          <PasswordInput
            variant={variant}
            theme={theme}
            placeholder="enter password"
            label="Password Example"
            value={passwordField}
            errorText={errorState === 'error' ? 'error will appear here' : ''}
            onBlur={() => {}}
            onChange={setPasswordField}
            allowFocus={true}
          />

          <Gap s="48px" />

          <DateInput
            w={'450px'}
            theme={theme}
            label="Set Date"
            value={dateField}
            errorText={errorState === 'error' ? 'error will appear here' : ''}
            onBlur={() => {}}
            onChange={setDateField}
            allowFocus={true}
          />

          <Gap s="48px" />
          <Divider/>
          <Gap s="48px" />

          <Text variant='title1'>Buttons</Text>
          <Gap s='12px'/>
          <RadioGroup onChange={setButtonVariant} value={buttonVariant} colorScheme={colorScheme}>
            <Flex gridGap='24px'>
              <Radio value={'round'}>round</Radio>
              <Radio value={'round-outline'}>outline</Radio>
              <Radio value={'round-ghost'}>ghost</Radio>
            </Flex>
          </RadioGroup>
          <Gap/>
          <Button variant={buttonVariant} colorScheme={colorScheme}>
            button example
          </Button>

          <Gap s="48px" />
          <Divider/>
          <Gap s="48px" />

          <Text variant='title1'>Text</Text>
          <Gap s='12px'/>
          <RadioGroup onChange={setTextVariant} value={textVariant} colorScheme={colorScheme}>
            <Flex gridGap='24px' wrap='wrap'>
              <Radio value='gigantictext'>gigantictext</Radio>
              <Radio value='giganticsubtitle'>giganticsubtitle</Radio>
              <Radio value='largetitle'>largetitle</Radio>
              <Radio value='largetitlemobile'>largetitlemobile</Radio>
              <Radio value='title1'>title1</Radio>
              <Radio value='title1mobile'>title1mobile</Radio>
              <Radio value='title2'>title2</Radio>
              <Radio value='title2mobile'>title2mobile</Radio>
              <Radio value='title2-desktop-bold'>title2-desktop-bold</Radio>
              <Radio value='title3'>title3</Radio>
              <Radio value='default/regular/title3'>default/regular/title3</Radio>
              <Radio value='title3opensans'>title3opensans</Radio>
              <Radio value='title3mobile'>title3mobile</Radio>
              <Radio value='footnote'>footnote</Radio>
              <Radio value='headline'>headline</Radio>
              <Radio value='caption1'>caption1</Radio>
              <Radio value='caption2'>caption2</Radio>
              <Radio value='callout'>callout</Radio>
              <Radio value='callout-desktop-regular'>callout-desktop-regular</Radio>
              <Radio value='body'>body</Radio>
              <Radio value='button'>button</Radio>
              <Radio value='small-subtitle'>small-subtitle</Radio>
              <Radio value='round'>round</Radio>
              <Radio value='round-outline'>round-outline</Radio>
              <Radio value='round-ghost'>round-ghost</Radio>
            </Flex>
          </RadioGroup>
          <Gap/>
          <Text variant={textVariant}>text example</Text>
          <Gap/>
          <Gap/>
        </Box>
      </Box>

    </Flex>
  )
}
