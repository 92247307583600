import { Box, Flex, Text } from "@chakra-ui/react";
import { Gap } from "../../../mini-lib/gap/Gap";
import { COLORS } from "../../../mini-lib/theme/colors";
import React from "react";
import logo from '../../../assets/salonscale-logo-midnight-outline.svg'
import logoAndName from '../../../assets/signup/salonscale-icon-name.svg'

export const SignupFormLayout = ( props: { children: any, stepNumber?: number, totalSteps?: number } ) => {
  const { children, stepNumber, totalSteps } = props
  return (
    <Box minH='100vh' h='100%' bg={COLORS.shades_neutral_50}>
      <Header/>
      <Gap s='72px'/>
      <Flex align='center' justify='center'>
        <Box
          m='12px'
          maxW='550px'
          width='100%'
          minH='350px'
          border={`1px solid ${COLORS.shades_neutral_300}`}
          bg={COLORS.shades_neutral_0}
          borderRadius='10px'
          position='relative'
        >
          <Logo/>
          <Gap s='60px'/>
          {stepNumber && totalSteps && (
            <Flex justify='center'>
              <Text variant='callout-desktop-regular'>
                Step {stepNumber} of {totalSteps}
              </Text>
            </Flex>
          )}
          {children}
        </Box>
      </Flex>
    </Box>
  )
}

const Header = () => {
  return (
    <Flex align='center' p='24px 0 0 24px'>
      <img alt="logo" style={{ height: '28px',}} src={logoAndName}/>
    </Flex>
  )
}
const Logo = () => {
  return (
    <Flex position='absolute' top='-30px' w='100%' justify='center'>
      <Box w='90px' h='90px' bg={COLORS.lavender_100} borderRadius='100px' p='6px' border={`5px solid ${COLORS.shades_neutral_0}`}>
        <img alt="logo" style={{ height: '100%', width: '100%', padding: '4px' }} src={logo}/>
      </Box>
    </Flex>
  )
}
