import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { UseViewSize } from "../../core/UseViewSize";
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGPricingIntroPage = () => {
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])
  const stepCodes = [CHECKLIST_CODES.addLines, CHECKLIST_CODES.addPricing, CHECKLIST_CODES.autoPricing]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  // normalize to 2 in case they have somehow done auto pricing and also add pricing
  const stepsComplete = stepsCompleted ? (stepsCompleted > 2 ? 2 : stepsCompleted) : 0
  const {isMobile} = UseViewSize()
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="products"
          title="DETERMINE YOUR PRICING"
          description={descriptionText}
          video={VIDEO_MAP.addPricing}
          actions={
          <SGActionButtons
              action={{
                label: 'Customize Pricing',
                url: generatePath(ROUTES.sgAddPricing, { salonId }) + `?guide=${CHECKLIST_CODES.addPricing}`
              }}
              action2={{
                label: isMobile ? `Auto-Add Costs` : `Auto-Add Product Costs`,
                url: generatePath(ROUTES.sgAutoPricing, { salonId }) + `?guide=${CHECKLIST_CODES.autoPricing}`
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={2} stepsComplete={stepsComplete} />}
          imgPath={require('../../assets/start-guide/pricing.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `Once you gather your pricing information, your SalonScale account will be unique for you!
          
Step 1: Gather your product wholesale costs. This can be found in either:
  • your Beauty Distributor Account
  • receipts or invoices from past purchases 
Step 2: Add the cost of these products
Step 3: Add a mark up to ensure you are profiting every color service. We recommend 100%!

Remember, you can’t manage what you can’t measure. Once you have all these details entered, both measurement and management become a breeze!

Don’t have your pricing details? You can use our quick start pricing option, and customize your pricing later. 

`
