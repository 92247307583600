import { Client } from './interfaces'

export const EMPTY_CLIENT = (): Client => {
  return {
    id: -1,
    firstName: '',
    lastName: '',
    phone: ''
  }
}

export const CLIENTS_LOADING_CONSTANT = 'loading-clients'
export const CREATING_CLIENT_CONSTANT = 'creating-client'
