import React, { useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { UseViewSize } from '../../core/UseViewSize'

export const ImgCarousel = (props: {
  imgUrls: string[]
  showPreviewImgs?: boolean
  children?: any
  imgHeight?: string
  imgWidth?: string
}) => {
  const { isMobile } = UseViewSize()
  const { imgUrls, imgHeight, imgWidth, showPreviewImgs = false, children } = props
  const [selectedIndex, setSelectedIndex] = useState(0)
  return (
    <Flex
      m="12px"
      p="12px"
      borderColor="shades.neutral.200"
      borderWidth="1px"
      borderRadius="15px"
      direction="column"
      align="center"
      justify="center"
      maxW="500px"
      w="100%"
    >
      <CarouselImg heightPx={imgHeight} widthPx={imgWidth} imgUrl={imgUrls[selectedIndex]} />
      <Flex p="24px 0 12px" gridGap="12px">
        {imgUrls.map((url, index) => {
          return (
            <Box
              key={index}
              cursor="pointer"
              h="15px"
              w="15px"
              background={selectedIndex === index ? 'shades.neutral.400' : 'shades.neutral.200'}
              borderRadius="50%"
              onClick={() => setSelectedIndex(index)}
            />
          )
        })}
      </Flex>
      {showPreviewImgs && (
        <Flex p="24px 0 12px" gridGap="12px" cursor="pointer">
          {imgUrls.map((url, index) => {
            return (
              <Box key={index} onClick={() => setSelectedIndex(index)}>
                <CarouselImg heightPx={isMobile ? '40px' : '80px'} widthPx={isMobile ? '40px' : '80px'} imgUrl={url} />
              </Box>
            )
          })}
        </Flex>
      )}
      {children}
    </Flex>
  )
}

export const CarouselImg = (props: { imgUrl: string; heightPx?: string; widthPx?: string }) => {
  const { isMobile } = UseViewSize()
  const { heightPx = isMobile ? '350px' : '500px', widthPx = '100%', imgUrl } = props
  return (
    <Box
      m="12px"
      h={heightPx}
      w={widthPx}
      maxW={'500px'}
      borderRadius="15px"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      backgroundImage={`url(${imgUrl})`}
    />
  )
}
