import React, { useEffect, useState } from 'react'
import { Link, IconButton, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, Flex, Button } from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { dispatchListProductUpcs, dispatchAcceptProductUpc, dispatchDeclineProductUpc } from '../../data/product-upcs/api'
import { COLORS } from '../../mini-lib/theme/colors'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { selectProductUpcs } from '../../data/product-upcs/slice'
import { generatePath } from 'react-router-dom'
import { values } from 'lodash'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { ProductUpc } from '../../data/product-upcs/interfaces'
import { selectLoadingState } from '../../core/loading/slice'
import { Loading } from '../../mini-lib/loading/Loading'
import { UPC_LOADING_CONSTANT } from '../../data/product-upcs/constants'
import { ROUTES } from "../../appRoutes";

export const AdminValidateUpcPage = () => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const productsUpcList = values(useAppSelector(selectProductUpcs)) ?? []
  const loading = useAppSelector((state) => selectLoadingState(state, UPC_LOADING_CONSTANT))
  const [numRowsToShow, setNumRowsToShow] = useState(10)
  const hasMore = numRowsToShow < Object.keys(productsUpcList).length
  const pagedModels = productsUpcList.slice(0, numRowsToShow)

  useEffect(() => {
    dispatch(
      dispatchListProductUpcs({ token: user.token }),
    )
  }, [dispatch, user.token])

  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Product-upc', url: generatePath(ROUTES.adminValidateUpc) }
  ]

  return (
    <>
      <PageLayout
        variant="full"
        header={
          <PageHeader
            title="Validate Product UPC"
            breadcrumbs={breadcrumbs}
          />
        }
        content={
          <>
            {loading ? <Loading /> : <>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th><Text fontSize='14px' variant="small-subtitle">Upc</Text></Th>
                      <Th><Text fontSize='14px' variant="small-subtitle">Brand</Text></Th>
                      <Th><Text fontSize='14px' variant="small-subtitle">Details</Text></Th>
                      <Th><Text fontSize='14px' variant="small-subtitle">Count</Text></Th>
                      <Th><Text fontSize='14px' variant="small-subtitle">Action</Text></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {pagedModels.length > 0 ? pagedModels.map((productsUpc) => (
                      <ProductUpcRow
                        productsUpcDetails={productsUpc}
                      />
                    )) : <NoProductToValidate />}
                  </Tbody>
                </Table>
              </TableContainer>
              {hasMore && (
                <Flex justifyContent={'center'}>
                  <Button
                   variant="round-ghost-lower" onClick={() => setNumRowsToShow(pagedModels.length + 15)}>
                    View More
                  </Button>
                </Flex>
              )}
            </>}

          </>
        }
      />
    </>
  )
}

const ProductUpcRow = ({ productsUpcDetails }: { productsUpcDetails: ProductUpc }) => {
  const { brand, upc, vendor, size, type, category, count } = productsUpcDetails
  return (
    <Tr border="1px solid rgba(0, 0, 0, 0.1)">
      <Td>
        <Link lineHeight='1.2' fontWeight='bold' color={COLORS.lavender_500} href={`https://www.upcitemdb.com/upc/${upc}`} isExternal>{upc}</Link>
      </Td>
      <Td>
        <Text fontSize='14px' variant="callout"><Text display='inline' fontSize='14px' fontWeight='bold' variant="callout">Brand: </Text>{brand}</Text>
        <Text fontSize='14px' variant="callout"><Text display='inline' fontSize='14px' fontWeight='bold' variant="callout">Parent: </Text>{vendor}</Text>
      </Td>
      <Td>
        <Text fontSize='14px' variant="callout"><Text display='inline' fontSize='14px' fontWeight='bold' variant="callout">Category: </Text>{category}</Text>
        <Text fontSize='14px' variant="callout"><Text display='inline' fontSize='14px' fontWeight='bold' variant="callout">Type: </Text>{type}</Text>
        <Text fontSize='14px' variant="callout"><Text display='inline' fontSize='14px' fontWeight='bold' variant="callout">Size: </Text>{size}</Text>
      </Td>
      <Td>
        <Text fontSize='14px' variant="callout" fontWeight='bold'>{count}</Text>
      </Td>
      <Td w='100px'>{<ActionMenu upc={upc} />}
      </Td>
    </Tr>)
}

const NoProductToValidate = () => {
  return (<Text color={COLORS.shades_neutral_500} marginTop='1rem' align='start' fontSize='18px'>No product to validate</Text>)
}

export const ActionMenu = ({ upc }: { upc: number }) => {
  const dispatch = useDispatch()
  const { user: { token } } = UseBaseApiParams()
  const handleAccept = () => {
    dispatch(dispatchAcceptProductUpc({ token, upc }))
  }
  const handleDecline = () => {
    dispatch(dispatchDeclineProductUpc({ token, upc }))
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<MaterialIcon color={COLORS.shades_background_400} name="more_vert" />}
        variant="ghost"
      />
      <MenuList>
        <MenuItem icon={<MaterialIcon name="done" />} onClick={handleAccept}>Accept</MenuItem>
        <MenuItem icon={<MaterialIcon name="close" />} onClick={handleDecline}>Decline</MenuItem>
      </MenuList>
    </Menu>
  )
}
