import { ALL_CHECKLIST_CODES_IN_ORDER, CHECKLIST_GROUP_INDEXES, CHECKLIST_GROUPS } from './constants'
import { ChecklistItem } from './interfaces'

export const getAccordionIndexFromStepCode = (stepCode: string): number => {
  if (CHECKLIST_GROUPS.group1.includes(stepCode)) {
    return CHECKLIST_GROUP_INDEXES.group1
  }
  if (CHECKLIST_GROUPS.group2.includes(stepCode)) {
    return CHECKLIST_GROUP_INDEXES.group2
  }
  if (CHECKLIST_GROUPS.group3.includes(stepCode)) {
    return CHECKLIST_GROUP_INDEXES.group3
  }
  if (CHECKLIST_GROUPS.group4.includes(stepCode)) {
    return CHECKLIST_GROUP_INDEXES.group4
  }
  return 0
}

// we only want to show the help popover on the first row of a list
export const getHelpStepIndex = (rowIndex: number, stepIndex: number): number => {
  if (rowIndex === 0) {
    return stepIndex
  }
  // if it's not the first row return a step index that is out of range
  return -1
}

export const getNextStepCodeForUser = (userChecklistItems: ChecklistItem[]): string => {
  let nextCode = ''
  const completedCodes = userChecklistItems.filter((item) => item.completed).map((item) => item.code)
  ALL_CHECKLIST_CODES_IN_ORDER.forEach((code, index) => {
    if (completedCodes.includes(code) && index + 1 <= ALL_CHECKLIST_CODES_IN_ORDER.length) {
      nextCode = ALL_CHECKLIST_CODES_IN_ORDER[index + 1]
    }
  })
  return nextCode
}

export const getIsStepComplete = (checklistMap:  { [key: string]: ChecklistItem } | null, itemCode: string): boolean => {
  return checklistMap && checklistMap[itemCode] ? checklistMap[itemCode].completed : false
}
