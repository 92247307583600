/////////////// mappers.ts
//
//

// todos: copy paste Analytic and analytic

import {
  Analytic,
  OwnerMetricOptions,
  APITopClient,
  TopClient
} from './interfaces'
import { buildDateYYYYMMDD } from '../../core/dates'


export const mapApiAnalyticsToAnalytics = (
  statType: string,
  analytics: Analytic,
) => {
  if (statType === OwnerMetricOptions.totalRevenueProfit) {
    const data = analytics[OwnerMetricOptions.totalRevenueProfit].data.map(d => {
      const date = d.date ? buildDateYYYYMMDD(d.date) : `${d.year}${d.month ? '-' + d.month : ''}`;
      return ({ 
        cost:  parseFloat(d.wholesale.toFixed(2)),
        revenue: parseFloat(d.retail.toFixed(2)),
        profit: parseFloat(d.profit.toFixed(2)),
        date
       })
    }) ?? []
    return {
      totalRevenueProfit: data,
    }
  }
  if (statType === OwnerMetricOptions.totalSessions) {
    return { totalSessions: analytics[OwnerMetricOptions.totalSessions] }
  }
  if (statType === OwnerMetricOptions.products) {
    return { products: analytics[OwnerMetricOptions.products] }
  }
  if (statType === OwnerMetricOptions.clients) {
    return { clients: analytics[OwnerMetricOptions.clients] }
  }
  return {}
}

export const mapAPITopClientToTopClient = (apis: APITopClient[]): TopClient[] => {
  return apis.map((api) => ({
    id: api.client_id,
    firstName: api.first_name,
    middleName: api.middle_name,
    lastName: api.last_name,
    totalCost: parseFloat(api.total_cost.toFixed(2)),
  }))
}
