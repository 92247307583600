/////////////// slice.ts
//
//

// references
//
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, keyBy, omit, orderBy, uniq, values } from 'lodash'

import { RootState } from '../../store'
import { InventoryCount, InventoryCountItem } from './interfaces'
import { mapCountItemsToCountItemsByCategory } from '../products/mappers'
import { filterCountItems } from "./utils";
import { ItemFilters } from "../../pages/inventory/filters/InventoryItemFilters";
import { EMPTY_INVENTORY_ITEM_FILTERS } from './constants'
import { LaraPagination } from '../../mini-lib/lara/lara-utils'

// state
//
interface InventoryCountState {
  inventoryCountsById: { [key: string]: InventoryCount } | null
  selectedInventoryCount: InventoryCount | null
  selectedInventoryCountItems: InventoryCountItem[] | null
  inventoryAddStockModalVisibility: boolean
  inventoryAuditModalVisibility: boolean
  itemFilters: ItemFilters
  redirectInventoryCountId: number | null
  inventoryCountsPagination: LaraPagination | null
}

const initialState: InventoryCountState = {
  inventoryCountsById: null,
  selectedInventoryCount: null,
  selectedInventoryCountItems: null,
  inventoryAddStockModalVisibility: false,
  inventoryAuditModalVisibility: false,
  itemFilters: EMPTY_INVENTORY_ITEM_FILTERS,
  redirectInventoryCountId: null,
  inventoryCountsPagination: null,
}

// reducer
//
export const InventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    reduceRedirectInventoryCountId: (state, action: PayloadAction<number | null>) => {
      state.redirectInventoryCountId = action.payload
    },
    reduceInventoryAuditModalVisibility: (state, action: PayloadAction<boolean>) => {
      state.inventoryAuditModalVisibility = action.payload
    },
    reduceInventoryAddStockModalVisibility: (state, action: PayloadAction<boolean>) => {
      state.inventoryAddStockModalVisibility = action.payload
    },
    reduceSetSelectedInventoryCount: (state, action: PayloadAction<InventoryCount | null>) => {
      state.selectedInventoryCount = action.payload
    },
    reduceListInventoryCount: (state, action: PayloadAction<InventoryCount[]>) => {
      state.inventoryCountsById = assign({}, state.inventoryCountsById, keyBy(action.payload, 'id'))
    },
    reduceGetInventoryCount: (state, action: PayloadAction<InventoryCount>) => {
      state.selectedInventoryCount = action.payload
    },
    reduceGetInventoryCountItems: (state, action: PayloadAction<InventoryCountItem[]>) => {
      state.selectedInventoryCountItems = action.payload
    },
    reduceInventoryCountsPagination: (state, action: PayloadAction<LaraPagination>) => {
      state.inventoryCountsPagination = action.payload
    },
    reduceSetInventoryItemFilters: (state, action: PayloadAction<ItemFilters>) => {
      state.itemFilters = action.payload
    },
    reduceUpdateInventoryCountItem: (state, action: PayloadAction<InventoryCountItem>) => {
      if (state.selectedInventoryCountItems) {
        const updatedItems = state.selectedInventoryCountItems?.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
        state.selectedInventoryCountItems = updatedItems
      }
    },
    reduceCreateInventoryCount: (state, action: PayloadAction<InventoryCount>) => {
      if (state.inventoryCountsById) {
        state.inventoryCountsById[action.payload.id] = action.payload
      }
    },
    reduceUpdateInventoryCount: (state, action: PayloadAction<InventoryCount>) => {
      const existing = state.inventoryCountsById ? state.inventoryCountsById[action.payload.id] : null
      if (existing && state.inventoryCountsById) {
        state.inventoryCountsById[action.payload.id] = action.payload
      } else if (action.payload?.id) {
        state.inventoryCountsById = { [action.payload.id]: action.payload }
      }
    },
    reduceDeleteInventoryCount: (state, action: PayloadAction<InventoryCount>) => {
      state.inventoryCountsById = omit(state.inventoryCountsById, action.payload.id)
    },
  },
})

// actions
//
export const {
  reduceRedirectInventoryCountId,
  reduceSetInventoryItemFilters,
  reduceInventoryCountsPagination,
  reduceInventoryAuditModalVisibility,
  reduceInventoryAddStockModalVisibility,
  reduceListInventoryCount,
  reduceCreateInventoryCount,
  reduceUpdateInventoryCount,
  reduceDeleteInventoryCount,
  reduceGetInventoryCount,
  reduceGetInventoryCountItems,
  reduceUpdateInventoryCountItem,
} = InventorySlice.actions

// selectors
//
export const selectInventoryAddStockModalVisibility = (state: RootState) =>
  state.inventory.inventoryAddStockModalVisibility

export const selectItemFilters = (state: RootState) => state.inventory.itemFilters

export const selectInventoryAuditModalVisibility = (state: RootState) => state.inventory.inventoryAuditModalVisibility

export const selectInventoryCountsPagination = (state: RootState) => state.inventory.inventoryCountsPagination

export const selectSelectedInventoryCount = (state: RootState) => state.inventory.selectedInventoryCount

export const selectSelectedInventoryCountItems = (state: RootState): null | InventoryCountItem[] =>
  state.inventory.selectedInventoryCountItems

export const selectAllItemCategories = (state: RootState): string[] => {
  return state.inventory.selectedInventoryCountItems
    ? uniq(state.inventory.selectedInventoryCountItems.map((item) => item.product.category))
    : []
}

export const selectAllItemLines = (state: RootState): string[] => {
  if (!state.inventory.selectedInventoryCountItems) {
    return []
  }
  const vendorFilter = state.inventory.itemFilters.vendor

  if (vendorFilter) {
    const filteredItems = state.inventory.selectedInventoryCountItems.filter(item => item.product.vendor.toLowerCase() === vendorFilter.toLowerCase())
    return uniq(filteredItems.map((item) => item.product.line ))
  }
  return uniq(state.inventory.selectedInventoryCountItems.map((item) => item.product.line))
}


export const selectAllItemVendors = (state: RootState): string[] => {
  return state.inventory.selectedInventoryCountItems
    ? uniq(state.inventory.selectedInventoryCountItems.map((item) => item.product.vendor))
    : []
}

export const selectInventoryCountItemsLength = (state: RootState): null | number => {
  if (state.inventory.selectedInventoryCountItems) {
    return state.inventory.selectedInventoryCountItems.length
  }
  return null
}

export const selectFilteredInventoryCountItemsByCategory = (
  state: RootState,
): null | { [key: string]: InventoryCountItem[] } => {
  if (state.inventory.selectedInventoryCountItems) {
    const filteredItems = filterCountItems(state.inventory.selectedInventoryCountItems, state.inventory.itemFilters)
    const vendorSorter = (item: InventoryCountItem) => item.product.vendor.toLowerCase()
    const lineSorter = (item: InventoryCountItem) => item.product.line.toLowerCase()
    const nameSorter = (item: InventoryCountItem) => item.product.type.toLowerCase()
    const sortedFilteredItems = orderBy(
       filteredItems,
        [vendorSorter, lineSorter, nameSorter],
        ['asc', 'asc', 'asc', 'asc'],
      )
    return mapCountItemsToCountItemsByCategory(sortedFilteredItems)
  }
  return null
}

export const selectInventoryCountsById = (state: RootState) => state.inventory.inventoryCountsById
export const selectRedirectInventoryCountId = (state: RootState) => state.inventory.redirectInventoryCountId

export const selectInventoryCounts = (state: RootState): InventoryCount[] | null => {
  const property1Sorter = (inventoryCount) => inventoryCount.id
  return state.inventory.inventoryCountsById
    ? orderBy(values(state.inventory.inventoryCountsById), [property1Sorter], ['desc'])
    : null
}

// export
//
export default InventorySlice.reducer
