import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from "../../store";

// Define a type for the slice state
interface SidenavState {
  navVisible: boolean
  navType: string
  subSideNavVisible: boolean
}

// Define the initial state using that type
const initialState: SidenavState = {
  navVisible: false,
  navType: '',
  subSideNavVisible: false
}

export const slice = createSlice({
  name: 'sidenav',
  initialState,
  reducers: {
    setNavVisible: (state, action: PayloadAction<boolean>) => {
      state.navVisible = action.payload
    },
    setNavType: (state, action: PayloadAction<string>) => {
      state.navType = action.payload
    },
    setSubSidenavVisible: (state, action: PayloadAction<boolean>) => {
      state.subSideNavVisible = action.payload
    }
  },
})

export const {setNavVisible, setNavType, setSubSidenavVisible} = slice.actions

export const selectSidenavVisible = (state: RootState) => state.sidenav.navVisible
export const selectSidenavType = (state: RootState) => state.sidenav.navType
export const selectSubSideNavVisible = (state: RootState) => state.sidenav.subSideNavVisible

export default slice.reducer
