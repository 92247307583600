import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { buildDateFromServer, buildDateYYYYMMDD } from '../../core/dates'
import {
  reduceCustomEndDateForLaborReports,
  reduceCustomStartDateForLaborReports,
  reduceSelectedDateRangeForLaborReports,
} from '../../data/labor-report/slice'
import { reduceCustomEndDate, reduceCustomStartDate, reduceSelectedDateRange } from '../../data/report/slice'
import {
  reduceCustomEndDate as reduceCustomEndDateV2,
  reduceCustomStartDate as reduceCustomStartDateV2,
  reduceSelectedDateRange as reduceSelectedDateRangeV2,
  selectCustomEndDate,
  selectCustomStartDate,
} from '../../data/reports-v2/slice'
import { DateInput } from './DateInput'
import './DateInput.css'
import { useAppSelector } from '../../hooks'

export const CustomDateRangeModal = (props: {
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
  type?: 'default' | 'labor'
  startDateValue?: Date,
  endDateValue?: Date,
  onSelect?: (params: {startDate: Date, endDate: Date, startDateString: string, endDateString: string}) => void
}) => {
  const {
    setIsOpen,
    isOpen,
    onSelect,
    type = 'default',
    startDateValue,
    endDateValue
  } = props
  const dispatch = useDispatch()

  // todo: go through uses of custom date range modal and pass the values in instead of relying on this
  const stateStartDate = useAppSelector(selectCustomStartDate)
  const stateEndDate = useAppSelector(selectCustomEndDate)

  // todo: once state is removed initialStart/initialEnd will just be the values passed in
  const initialStartDate = startDateValue ? startDateValue : stateStartDate ? buildDateFromServer(stateStartDate) : null
  const initialEndDate = endDateValue ? endDateValue : stateEndDate ? buildDateFromServer(stateEndDate) : null

  const [startDate, setStartDate] = useState(initialStartDate ? initialStartDate : new Date())
  const [endDate, setEndDate] = useState(initialEndDate ? initialEndDate : new Date())
  const setCustomDate = () => {
    const startDateString = buildDateYYYYMMDD(startDate)
    const endDateString = buildDateYYYYMMDD(endDate)
    if (!!onSelect){
      onSelect({startDate, endDate, startDateString, endDateString})
    } else {
       if (type === 'labor') {
        dispatch(reduceSelectedDateRangeForLaborReports('custom'))
        dispatch(reduceCustomStartDateForLaborReports(startDateString))
        dispatch(reduceCustomEndDateForLaborReports(endDateString))
      }
      if (type === 'default') {
        dispatch(reduceSelectedDateRange('custom'))
        dispatch(reduceSelectedDateRangeV2('custom'))

        dispatch(reduceCustomStartDate(startDateString))
        dispatch(reduceCustomStartDateV2(startDateString))

        dispatch(reduceCustomEndDate(endDateString))
        dispatch(reduceCustomEndDateV2(endDateString))
      }
    }
    setIsOpen(false)
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>SELECT A DATE RANGE</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column">
              <Flex direction="row" justifyContent={'space-between'}>
                <Text mt="10px">Start Date</Text>
                <Box w="80%">
                  <DateInput
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholder={'Start Date'}
                  />
                </Box>
              </Flex>
              <Box h="10px" />
              <Flex direction="row" justifyContent={'space-between'}>
                <Text mt="10px">End Date</Text>
                <Box w="80%">
                  <DateInput value={endDate} onChange={(date) => setEndDate(date)} placeholder={'Start Date'} />
                </Box>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" borderRadius="15px" mr={3} onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button variant="solid" borderRadius="15px" onClick={setCustomDate}>
              Apply
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
