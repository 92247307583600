import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { OrderLara } from '../../data/orders/interfaces'
import { getOrderStatusColor } from '../../data/orders/utils'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { formatDollars } from '../../mini-lib/units/money'
import { ROUTES } from "../../appRoutes";

export const OrderCardLara = (props: { order: OrderLara }) => {
  const { order } = props
  const { salonId } = UseBaseApiParams()
  const path = generatePath(ROUTES.orderDetails, { salonId, orderId: order.id })
  const statusHex = getOrderStatusColor(order.status)
  const month = Intl.DateTimeFormat('en-US', { month: 'long' }).format(order.orderDate)
  const date = order.orderDate.getDate()
  const year = order.orderDate.getFullYear()
  return (
    <Link to={path}>
      <Box p="12px" maxW="100%">
        <Flex align="center" justify="space-between">
          <Flex gridGap="12px">
            <Box backgroundColor={statusHex} h="50px" w="4px" minW="4px" borderRadius="50px" />
            <Flex flexDirection="column" justify='center'>
              {order && <Text variant="body">{order.name}</Text>}
              <Text variant="caption2" fontWeight="normal">
                {month + ' ' + date + ', ' + year}
              </Text>
            </Flex>
          </Flex>

          <Flex align="center">
            <Flex align="center">
              <Text variant="body">${formatDollars(order.totalOrderedCostDollars)}</Text>
            </Flex>
            <Button variant="text" w="10px" ml="0px">
              <MaterialIcon name="keyboard_arrow_right" />
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Link>
  )
}
export const OrderListLara = (props: { orders: OrderLara[] }) => {
  const { orders } = props
  return (
    <>
      {orders.map((order, index) => {
        return (
          <Box key={index}>
            <OrderCardLara order={order} />
            <Divider />
          </Box>
        )
      })}
    </>
  )
}
