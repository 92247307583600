import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { generatePath } from 'react-router-dom'
import React from 'react'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { COLOR_REPORTS_TABS, ColorReportsTabs } from './ColorReportsTabs'
import { ROUTES } from "../../../appRoutes";

export const ColorClientsReportsPage = (props: {}) => {
  const { salonId } = UseBaseApiParams()
  const breadcrumbs = [
    {
      label: 'Reports',
      url: generatePath(ROUTES.colorBrandReports, { salonId }),
    },
    { label: 'Client Reports' },
  ]
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Color Reports" breadcrumbs={breadcrumbs} />}
      content={
        <>
          <ColorReportsTabs selectedTab={COLOR_REPORTS_TABS.colorClient} onCsvExport={() => {}} />
        </>
      }
    />
  )
}
