export const formatDollars = ( dollars: number | null ): string => {
  return dollars
    ? Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(dollars)
    : '0'
}
export const formatCentsToDollars = ( cents: number | null ): string => {
  const truncatedCents = cents ? Math.trunc(cents) : null
  return truncatedCents
    ? Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(truncatedCents / 100)
    : '0'
}
