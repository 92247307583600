import { Flex, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react'
import { ColorForMode } from '../../theme'
import { STICKY_STYLES } from '../../styles'
import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import React from 'react'

export const StickySearch = (props: {
  variant?: 'card' | 'clear'
  placeholder: string
  searchText: string
  setSearchText: (searchText: string) => void
  searchWidth?: string
  isSticky?: boolean
  iconColor?: string
  children?: any
}) => {
  const {
    placeholder,
    searchText,
    setSearchText,
    searchWidth = '225px',
    variant = 'card',
    isSticky = true,
    iconColor = 'brand.lavender.500',
    children,
  } = props
  return (
    <Flex
      className="cy-sticky-search"
      bg={variant === 'card' ? ColorForMode('card-bg') : ''}
      style={isSticky ? STICKY_STYLES : {}}
      justify="space-between"
    >
      {variant === 'card' && (
        <InputGroup w={searchWidth} maxWidth="100vw">
          <InputLeftElement pointerEvents="none" children={<SearchIcon color={iconColor} />} />
          <Input
            borderRadius="100px"
            value={searchText}
            onChange={(evt) => props.setSearchText(evt.target.value)}
            placeholder={placeholder}
          />
          {searchText && (
            <InputRightElement
              cursor="pointer"
              onClick={() => setSearchText('')}
              children={<CloseIcon color="brand.lavender.500" />}
            />
          )}
        </InputGroup>
      )}
      {variant === 'clear' && (
        <InputGroup border="1px solid" borderColor="shades.neutral.300" style={{ borderRadius: '100px' }}>
          <InputLeftElement pointerEvents="none" children={<SearchIcon color={iconColor} />} />
          <Input
            border="none"
            _focus={{ border: 'none' }}
            value={searchText}
            onChange={(evt) => props.setSearchText(evt.target.value)}
            placeholder={placeholder}
          />
          {searchText && (
            <InputRightElement
              cursor="pointer"
              onClick={() => setSearchText('')}
              children={<CloseIcon color="brand.lavender.500" />}
            />
          )}
        </InputGroup>
      )}
      {children}
    </Flex>
  )
}
