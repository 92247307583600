import { UseViewSize } from '../../core/UseViewSize'
import { VIDEO_MAP, VideoData } from './constants'
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { VideoModal } from './VideoModal'
import { MaterialIcon } from '../icons/MaterialIcon'
import { useEffect } from 'react'

export const Video = (props: {
  video: VideoData
  children?: any
  variant?: 'text' | 'miniplayer' | 'modal'
  height?: string
  width?: string
  isDisabled?: boolean
  videoClicked?: () => void
}) => {
  const {
    children,
    videoClicked = () => {},
    video,
    variant = 'miniplayer',
    height = '130px',
    width = '200px',
    isDisabled = false,
  } = props
  const { isMobile } = UseViewSize()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onClick = () => {
    if (isDisabled) {
      return
    }
    videoClicked()
    if (variant === 'miniplayer') {
      const player = document.getElementById(video.id)
      player?.click()
    }
    if (isMobile) {
      window.open(`https://www.youtube.com/embed/${video.id}`, '_blank')
    } else {
      onOpen()
    }
  }
  const startTimeParam = video.startTime ? `?start=${video.startTime}` : ''
  const src = `https://www.youtube.com/embed/${video.id}${startTimeParam}`

  // ref - original solution that i have modified/improved for our use case https://stackoverflow.com/a/23231136
  useEffect(() => {
    // ensure the main document is focused to allow window blur events to fire when the iframe is interacted with
    window.focus()

    const handleBlur = () => {
      setTimeout(() => {
        if (document?.activeElement?.tagName === 'IFRAME') {
          videoClicked()
        }
      }, 0)
    }

    // add the event listener, but only for the first interaction (once: true)
    window.addEventListener('blur', handleBlur, { once: true })

    // cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('blur', handleBlur)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <VideoModal video={video} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />

      {!children && variant === 'miniplayer' && (
        <Flex onClick={onClick} cursor="pointer" align="center" position="relative">
          <iframe
            title={src}
            style={{ width: width, height: height, borderRadius: '8px' }}
            src={src}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen={true}
          />
        </Flex>
      )}
      {!children && variant === 'modal' && (
        <Flex onClick={onClick} cursor="pointer" align="center" position="relative">
          <Box style={{ width: width, height: height, position: 'absolute' }} />
          <iframe
            title={src}
            style={{ width: width, height: height }}
            src={src}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen={true}
          />
        </Flex>
      )}
      {!children && variant === 'text' && (
        <Flex onClick={onClick} cursor="pointer" align="center">
          <MaterialIcon mr="6px" name="play_circle_icon" />
          {VIDEO_MAP.soloWelcomeVideo.title} <Text> {VIDEO_MAP.soloWelcomeVideo.length}</Text>
        </Flex>
      )}

      {children && (
        <Flex onClick={onClick} cursor="pointer" align="center">
          {children}
        </Flex>
      )}
    </>
  )
}


