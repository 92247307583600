// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {assign, keyBy, omit, orderBy, values} from 'lodash'

import {RootState} from '../../store'
import {APIClient, Client} from './interfaces'
import { EMPTY_CLIENT } from './constants'
import { LaraPagination } from '../../mini-lib/lara/lara-utils'

// state
//
interface ClientState {
  clientsById: { [key: number]: Client } | null
  recentlyCreatedClientsById: { [key: number]: Client } | null
  selectedClientId: number | null
  selectedClient: Client | null
  selectedClientMergeableClients: Client[] | null
  pagination: LaraPagination | null
  bulkMergeableClients: APIClient[][] | null
  clientPagination: LaraPagination | null
}

const initialState: ClientState = {
  clientsById: null, // undefined is an accident, null is a choice, this lets us know when something is loading
  recentlyCreatedClientsById: null,
  selectedClientId: null,
  selectedClient: null,
  selectedClientMergeableClients: null,
  pagination: null,
  bulkMergeableClients: null,
  clientPagination: null
}

// reducer
//
export const ClientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    reduceSetSelectedClientId: (state, action: PayloadAction<number | null>) => {
      state.selectedClientId = action.payload
    },
    reduceSelectedClientMergeableClients: (state, action: PayloadAction<Client[]>) => {
      state.selectedClientMergeableClients = action.payload
    },
    reduceListClients: (state, action: PayloadAction<Client[]>) => {
      state.clientsById = assign({}, state.clientsById, keyBy(action.payload, 'id'))
    },
    reduceListClientsPagination: (state, action: PayloadAction<LaraPagination>) => {
      state.clientPagination = action.payload
    },
    replaceListClients: (state, action: PayloadAction<{ pagination: LaraPagination; clients: Client[] }>) => {
      state.clientsById = assign({}, keyBy(action.payload.clients, 'id'))
      state.pagination = action.payload.pagination
    },
    reduceCreateClient: (state, action: PayloadAction<Client>) => {
      if (state.clientsById) {
        state.clientsById[action.payload.id] = action.payload
      }
      state.recentlyCreatedClientsById =  assign({}, state.recentlyCreatedClientsById, {[action.payload.id]: action.payload})
    },
    reduceUpdateClient: (state, action: PayloadAction<Client>) => {
      const existing = state.clientsById ? state.clientsById[action.payload.id] : null
      if (existing && state.clientsById) {
        state.clientsById[action.payload.id] = action.payload
      } else if (action.payload?.id) {
        state.clientsById = { [action.payload.id]: action.payload }
      }
    },
    reduceDeleteClient: (state, action: PayloadAction<Client>) => {
      state.clientsById = omit(state.clientsById, action.payload.id)
    },
    reduceDeleteClients: (state, action: PayloadAction<number[]>) => {
      state.clientsById = omit(state.clientsById, action.payload)
    },
    reduceBulkMergeableClients: (state, action: PayloadAction<APIClient[][]>) => {
      state.bulkMergeableClients = action.payload
    },
    reduceSetRecentlyCreatedClientsById: (state, action: PayloadAction<{ [key: number]: Client } | null>) => {
      state.recentlyCreatedClientsById = action.payload
    },
  },
})

// actions
//
export const {
  reduceSetSelectedClientId,
  reduceListClients,
  reduceListClientsPagination,
  replaceListClients,
  reduceCreateClient,
  reduceUpdateClient,
  reduceDeleteClient,
  reduceSelectedClientMergeableClients,
  reduceDeleteClients,
  reduceBulkMergeableClients,
  reduceSetRecentlyCreatedClientsById
} = ClientSlice.actions

// selectors
//
export const selectSelectedClient = (state: RootState): Client | null => {
  if (state.clients?.selectedClientId === -1) {
    return EMPTY_CLIENT()
  }
  return state.clients.clientsById &&
    state.clients.selectedClientId &&
    state.clients.selectedClientId in state.clients.clientsById
    ? state.clients?.clientsById[state.clients.selectedClientId]
    : null
}
export const selectSelectedClientMergeableClients = (state: RootState) => state.clients.selectedClientMergeableClients
export const selectClientsById = (state: RootState) => state.clients.clientsById
export const selectClientPagination = (state: RootState) => state.clients.clientPagination
export const selectClientList = (state: RootState): Client[] | null => {
  const property1Sorter = (client) => client.firstName.toLowerCase()
  const property2Sorter = (client) => client.lastName.toLowerCase()
  return state.clients.clientsById
    ? orderBy(values(state.clients.clientsById), [property1Sorter, property2Sorter], ['asc', 'asc'])
    : null
}

export const selectRecentlyCreatedClientList = (state: RootState): Client[] | null => {
  const property1Sorter = (client) => client.firstName.toLowerCase()
  const property2Sorter = (client) => client.lastName.toLowerCase()
  return state.clients.recentlyCreatedClientsById
    ? orderBy(values(state.clients.recentlyCreatedClientsById), [property1Sorter, property2Sorter], ['asc', 'asc'])
    : null
}

export const selectBulkMergeableClientSets = (state: RootState): APIClient[][] | null =>
  state.clients.bulkMergeableClients

// export
//
export default ClientSlice.reducer
