import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { useAppSelector } from '../../hooks'
import { ColorForMode } from '../../theme'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'
import { ExtensionLines } from './ExtensionLines'
import { keys } from 'lodash'
import {
  selectExtensionFilters,
  selectExtensionsByLineCategory,
  selectExtensionsByVendor,
  selectInfoOnExtensionsWithPricing
} from '../../data/extensions/slice'
import { dispatchListExtensions } from '../../data/extensions/api'
import { ExtensionsSheet } from './ExtensionsSheet'
import { HelpStepper, Step } from '../start-guide/common-components/HelpStepper'
import { COLORS } from '../../mini-lib/theme/colors'
import { dispatchListUserChecklistItems, dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import {
  reduceSetCurrentStepCode,
  reduceSetCurrentSubStepIndex,
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectChecklistItemsByCode,
  selectStepCompleteDrawerState,
  selectStepsCompleted,
} from '../../data/start-guide/slice'
import { HelpStepCompleteDrawer } from '../start-guide/common-components/HelpStepCompleteDrawer'
import { SalonTypes } from '../../data/salon/interfaces'
import { GlobalExtensionsMarkupModal } from './GlobalExtensionsMarkupModal'
import { HelpStepperV2 } from '../start-guide-v2/HelpStepperV2'
import {
  ReleaseExtensionsV2,
  ReleaseLaraColors,
  ReleasePageHelp,
  ReleaseStartGuideV2
} from '../../mini-lib/flags/Release'
import { UseQueryParams } from "../../mini-lib/utils/basic";
import { UseResetStartGuideState } from "../start-guide/common-components/UseResetStartGuideState";
import { ExtensionsBulkUpdateSheet } from "./ExtensionsBulkUpdateSheet";
import { EmptyFilters } from "../../mini-lib";
import { filterActive } from "../../data/extensions/utils";
import { ROUTES } from "../../appRoutes";
import { HelpDrawer } from "../start-guide/common-components/HelpDrawer";
import { VIDEO_MAP } from "../../mini-lib/video/constants";

export const ExtensionsPage = ( props: { showBreadCrumbs?: boolean } ) => {
  UseResetStartGuideState()
  const { showBreadCrumbs = true } = props
  const { user, salonId } = UseBaseApiParams()
  const breadcrumbs = showBreadCrumbs ? [{
    label: 'Home',
    url: generatePath(ROUTES.home, { salonId })
  }, { label: 'Extensions' }] : []
  const helpSteps: Step[] = [
    {
      title: 'Select Your Extensions',
      state: 'complete',
      link: generatePath(ROUTES.extensionsAdd, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`,
    },
    {
      title: 'Set Prices',
      state: 'current',
      link: generatePath(ROUTES.extensions, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`,
    },
  ]
  const dispatch = useDispatch()
  const releaseLaraColors = ReleaseLaraColors()
  const releasePageHelp = ReleasePageHelp()

  const extensionsByVendor = useAppSelector(selectExtensionsByVendor)
  const extensionsByCategoryThenByLineSize = useAppSelector(selectExtensionsByLineCategory)
  const infoOnExtensionsWithPricing = useAppSelector(selectInfoOnExtensionsWithPricing)
  const addExtensionsPricingChecklistItem = useAppSelector(( state ) => selectChecklistItem(state, CHECKLIST_CODES.addExtensionsPricing))
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)

  const extensionsLoaded = !!extensionsByVendor
  useEffect(() => {
    if (!extensionsLoaded) {
      dispatch(dispatchListExtensions({ token: user.token, salonId, releaseLaraColors }))
    }
  }, [dispatch, extensionsLoaded, user.token, salonId, releaseLaraColors])
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems

  const queryParams: any = UseQueryParams()
  const guideType = queryParams.get('guide')

  useEffect(() => {
    if (!checklistItemsLoaded && user.userId) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, user.token, user.userId, checklistItemsLoaded])

  useEffect(() => {
    if (guideType === CHECKLIST_CODES.addExtensions && !addExtensionsPricingChecklistItem.completed && infoOnExtensionsWithPricing?.percentage === 100) {
      dispatch(dispatchUpdateUserChecklistItem({
        token: user.token,
        checklistItemCode: CHECKLIST_CODES.addExtensionsPricing
      }))
    }
  }, [dispatch, guideType, infoOnExtensionsWithPricing, addExtensionsPricingChecklistItem.completed, user.token])

  const history = useHistory()
  const releaseExtensionsV2 = ReleaseExtensionsV2()
  const goToOnboard = () => {
    if (releaseExtensionsV2) {
      history.push(generatePath(ROUTES.addExtensionsV2, { salonId }))
    } else {
      history.push(generatePath(ROUTES.extensionsAdd, { salonId }))
    }
  }
  const releaseStartGuideV2 = ReleaseStartGuideV2()
  const stepCodes = [CHECKLIST_CODES.addExtensions, CHECKLIST_CODES.addExtensionsPricing]
  const stepsCompleted = useAppSelector(( state ) => selectStepsCompleted(state, stepCodes))
  const stepsComplete = stepsCompleted || 0
  const previousStepLink = generatePath(releaseExtensionsV2 ? ROUTES.sgAddExtensionsV2 : ROUTES.sgAddExtensions, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`
  const nextStepLink = generatePath(ROUTES.sgIndex, { salonId }) + '?step=extras'
  const filters = useAppSelector(selectExtensionFilters)
  const noExtensionsFound = filterActive(filters) && extensionsByCategoryThenByLineSize !== null && keys(extensionsByCategoryThenByLineSize).length === 0
  return (<>
    {releasePageHelp && (
        <HelpDrawer
          video={VIDEO_MAP.addExtensions}
          title={`Let’s take a look inside Extensions`}
          content={`Just like adding color lines, the Extensions feature helps to track and charge for hair extensions. If you don’t currently use extensions, you can skip this step or revisit later if things in your business change! Pricing tip: If you sell a full pack of extensions to a client every time, put in the pack cost and select how many packs you used on the client in app. If you break your packs down and sell partial packs, divide the pack cost by the quantity of extensions per pack and enter that. When using the app select how many extensions from the pack you used on the client and SalonScale will tally up the total cost of all the extensions used.`}
        />
      )}
    <PageLayout
      variant="full"
      top={
        <>
          {releaseStartGuideV2 ? (
            <HelpStepperV2
              title="Add Extensions to Your Back Bar"
              titleIconName="products"
              currentStepNumber={2}
              stepsComplete={stepsComplete}
              totalSteps={stepCodes.length}
              previousStepLink={previousStepLink}
              nextStepLink={nextStepLink}
              guideType={CHECKLIST_CODES.addExtensions}
            />
          ) : (
            <HelpStepper
              guideType={CHECKLIST_CODES.addExtensions}
              title="Add Extensions to Your Back Bar"
              steps={helpSteps}
              bgHex={COLORS.peach_100}
              borderHex={COLORS.peach_400}
            />
          )}
        </>
      }
      header={
        <PageHeader
          showHelp={true}
          title="Extensions"
          breadcrumbs={breadcrumbs}
          actions={
            <Flex gridGap="12px">
              <GlobalExtensionsMarkupModal/>

              <Link to={generatePath(releaseExtensionsV2 ? ROUTES.addExtensionsV2 : ROUTES.extensionsAdd, { salonId })}>
                <Button variant="round" colorScheme="brand.midnight">
                  Add Extensions
                </Button>
              </Link>
            </Flex>
          }
        />
      }
      content={
        <>
          {releaseStartGuideV2 && guideType === CHECKLIST_CODES.addExtensions && (
            <HelpStepCompleteDrawer
              guideType={CHECKLIST_CODES.addExtensions}
              title={`Way to go! Product Costs have been added for ${infoOnExtensionsWithPricing?.numberCompleted}/${infoOnExtensionsWithPricing?.totalExtensions} of your extensions.`}
              subtitle="Make sure you price all of your extensions. If you're unsure about prices, you can come back to this later"
              actions={
                <Flex align="center" gridGap="12px">
                  {stepCompleteDrawerState !== 'button' && (
                    <Button
                      variant="round-outline"
                      colorScheme="brand.midnight"
                      onClick={() => {
                        dispatch(reduceSetStepCompleteDrawerState('button'))
                      }}
                    >
                      Continue Editing
                    </Button>
                  )}
                  {infoOnExtensionsWithPricing?.percentage === 100 ? (
                    <Link to={generatePath(ROUTES.sgIndex, { salonId }) + '?step=extras&completed=extras'}>
                      <Button
                        variant="round"
                        colorScheme="brand.midnight"
                        onClick={() => {
                          dispatch(reduceSetStepCompleteDrawerState('hidden'))
                          dispatch(reduceSetCurrentSubStepIndex(0))
                          dispatch(
                            dispatchUpdateUserChecklistItem({
                              token: user.token,
                              checklistItemCode: CHECKLIST_CODES.addExtensionsPricing,
                            }),
                          )
                        }}
                      >
                        Finish Set Up
                      </Button>
                    </Link>
                  ) : (
                    <Link to={generatePath(ROUTES.sgIndex, { salonId }) + '?step=extras&completed=extras'}>
                      <Button
                        variant="round"
                        colorScheme="brand.midnight"
                        onClick={() => {
                          dispatch(reduceSetStepCompleteDrawerState('hidden'))
                          dispatch(reduceSetCurrentSubStepIndex(0))
                          dispatch(dispatchUpdateUserChecklistItem({
                            token: user.token,
                            checklistItemCode: CHECKLIST_CODES.addExtensionsPricing
                          }))
                        }}
                      >
                        Next Step
                      </Button>
                    </Link>
                  )}
                </Flex>
              }
            />
          )}
          {!releaseStartGuideV2 &&
              <HelpStepCompleteDrawer
                  guideType={CHECKLIST_CODES.addExtensions}
                  title="Way to go! Now you know how to add prices to your extensions!"
                  subtitle="Feel free to set up more prices while we are here. When you are ready, click below to go to the next step"
                  actions={
                    <Link
                      to={
                        releaseStartGuideV2
                          ? generatePath(ROUTES.sgIndex, { salonId }) + '?step=inventory'
                          : generatePath(ROUTES.startGuide, { salonId })
                      }
                    >
                      <Button
                        variant="round"
                        colorScheme="brand.midnight"
                        onClick={() => {
                          dispatch(
                            dispatchUpdateUserChecklistItem({
                              token: user.token,
                              checklistItemCode: CHECKLIST_CODES.addExtensions,
                            }),
                          )
                          if (user?.currentSalonContext?.salonType === SalonTypes.salon) {
                            dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.addStylist))
                          } else {
                            dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.addClient))
                          }
                          dispatch(reduceSetStepCompleteDrawerState('hidden'))
                          dispatch(reduceSetCurrentSubStepIndex(0))
                        }}
                      >
                        Go to Next Step
                      </Button>
                    </Link>
                  }
              />
          }
          <Box h="24px"/>
          {extensionsByVendor && keys(extensionsByVendor).length > 0 && extensionsByCategoryThenByLineSize && (
            <ExtensionLines extensionsByCategoryThenByLineSize={extensionsByCategoryThenByLineSize}/>
          )}
          {extensionsByVendor && keys(extensionsByVendor).length === 0 && (
            <>
              <Empty/>
            </>
          )}
          {!extensionsByVendor && (
            <Flex h="500px" align="center" justify="center">
              <Spinner/>
            </Flex>
          )}
          {noExtensionsFound && (
            <EmptyFilters
              theme='peach'
              title='search result can’t be found'
              subtitle='We might have what you’re looking for in our extensions list.'
              actions={
                <Button variant="round-outline" colorScheme='brand.midnight' onClick={goToOnboard}>
                  Search Extensions
                </Button>
              }
            />
          )}
          <ExtensionsSheet/>
          <ExtensionsBulkUpdateSheet/>
        </>
      }
    />
  </>)
}

const Empty = ( props: {} ) => {
  const st = ColorForMode('secondary-text')
  const history = useHistory()
  const params: any = useParams()
  const { salonId } = params
  const goToOnboard = () => {
    history.push(generatePath(ROUTES.extensionsAdd, { salonId }))
  }
  const extensionsEmpty = 'https://storage.googleapis.com/salonscale-prod-webapp-assets/extensions-empty-state.png'
  return (
    <Flex direction="column" justify="center" align="center">
      <img alt="logo" style={{ height: '380px', maxWidth: '100%', padding: '4px' }} src={extensionsEmpty}/>
      <Text variant="title1" fontWeight="bold">
        NO EXTENSION LINES ADDED
      </Text>
      <Box h="24px"/>
      <Text textAlign="center" color={st} maxW="100%" w="640px">
        Add your extension lines to start using this feature in the SalonScale App!{' '}
      </Text>
      <Box h="24px"/>
      <Button w="200px" variant="round" bg="brand.midnight.500" onClick={goToOnboard}>
        LET'S GO!
      </Button>
    </Flex>
  )
}
