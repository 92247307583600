import React from 'react'
import { UseViewSize } from '../../../core/UseViewSize'
import { InventoryCount } from '../../../data/inventory/interfaces'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { generatePath, Link } from 'react-router-dom'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { ROUTES } from "../../../appRoutes";

export const InventoryAddStockList = (props: { inventoryCounts: InventoryCount[] }) => {
  const { inventoryCounts } = props
  const { isMobile } = UseViewSize()
  return (
    <>
      {!isMobile && <TitleRow />}
      {inventoryCounts.map((count) => {
        return <Row key={count.id} inventoryCount={count} />
      })}
    </>
  )
}

export const TitleRow = () => {
  return (
    <>
      <Flex justify="space-between" p="24px 0">
        <Box w="150px" pl="12px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Name
          </Text>
        </Box>
        <Box w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Added From
          </Text>
        </Box>
        <Flex justify="center" w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Items
          </Text>
        </Flex>
        <Flex justify="center" w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Estimated Total
          </Text>
        </Flex>
        <Box w="24px" />
      </Flex>
      <Divider />
    </>
  )
}
export const Row = (props: { inventoryCount: InventoryCount }) => {
  const { inventoryCount } = props
  const { salonId } = UseBaseApiParams()
  const statusHex = !!inventoryCount.completedAt ? COLORS.shades_neutral_500 : COLORS.success_500
  const statusLabel = !!inventoryCount.completedAt ? 'Complete' : 'In Progress'
  const { isMobile } = UseViewSize()
  const url = generatePath(ROUTES.inventoryCount, { salonId, countId: inventoryCount.id })

  return (
    <Link to={url}>
      <Box p="12px 0">
        <Flex justify="space-between" p="12px 0">
          <Box w={!isMobile ? '150px' : ''}>
            <Flex gridGap="12px">
              <Box backgroundColor={statusHex} h="50px" w="4px" minW="4px" borderRadius="50px" />
              <Flex flexDirection="column">
                <Text color={statusHex} variant="caption2" fontWeight="bold">
                  {statusLabel}
                </Text>
                <Text variant="body">{inventoryCount.title}</Text>
                <Text variant="small-subtitle" fontWeight="normal">
                  {buildDateYYYYMMDD(inventoryCount.createdAt)}
                </Text>
              </Flex>
            </Flex>
          </Box>
          {!isMobile && (
            <>
              <Box w="150px">{inventoryCount.countType}</Box>
              <Flex justify="center" w="150px">
                {inventoryCount.totalItemCount}
              </Flex>
              <Flex justify="center" w="150px">
                ${inventoryCount.stockCost}
              </Flex>
              <MaterialIcon name="chevron_right" />
            </>
          )}
          {isMobile && (
            <Flex align="center">
              <Box w="100px">
                <Text>{inventoryCount.totalItemCount} Items</Text>
              </Box>
              <MaterialIcon name="chevron_right" />
            </Flex>
          )}
        </Flex>
        <Divider />
      </Box>
    </Link>
  )
}
