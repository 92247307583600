import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import {
  reduceSelectedProductMap,
  reduceSetInventoryInfoSheetVisibility,
  reduceSetSelectedProduct,
  reduceSetSelectedProductIds,
  selectSelectedProductMap,
} from '../../../data/products/slice'
import { assign, groupBy, keys, map, omit, orderBy } from 'lodash'
import { APIUpdateProduct, Product } from '../../../data/products/interfaces'
import { selectLoggedInUser } from '../../../data/user/slice'
import { useParams } from 'react-router-dom'
import { dispatchUpdateProducts } from '../../../data/products/api'
import { ProductForm } from './ProductForm'
import { ColorForMode } from '../../../theme'
import { buildLoadingName } from '../../../core/loading/utils'
import { COLORS } from '../../../mini-lib/theme/colors'
import { EMPTY_PRODUCT_FILTERS, GROUPED_CATEGORIES } from '../../../data/products/constants'
import { calculateInventoryCost, filterProducts } from '../../../data/products/utils'
import { DebouncedInput } from '../../../mini-lib/debounced/DebouncedInput'
import { SearchIcon } from '@chakra-ui/icons'
import { UseViewSize } from '../../../core/UseViewSize'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { ProductAutoSaveForm, ProductAutoSaveFormTitleRow } from '../products/ProductAutoSaveForm'
import { HELP_DRAWER_STATES } from '../../start-guide/common-components/HelpStepCompleteDrawer'
import {
  reduceSetStepCompleteDrawerState,
  selectChecklistItemsByCode,
  selectStepCompleteDrawerState,
} from '../../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../../data/start-guide/api'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { ReleaseUnitsSupport } from "../../../mini-lib/flags/Release";
import { TrialPricingBanner } from "./TrialPricing";

export const LineDetailsSheet = (props: {
  products: Product[]
  isVisible: boolean
  onClose: () => void
  isEntireCategory?: boolean
}) => {
  const { isMobile } = UseViewSize()
  const { user } = UseBaseApiParams()
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const { products, isVisible, onClose, isEntireCategory = true } = props
  const filteredProducts = filterProducts(products, { ...EMPTY_PRODUCT_FILTERS, searchText })
  const productsByLine = groupBy(filteredProducts, (product) => product.line.name)
  const lines: string[] = keys(productsByLine).sort()
  const [expandedIndex, setExpandedIndex] = useState(0)
  const firstProduct = products && products.length > 0 ? products[0] : null
  // const filteredLines = lines.filter((lineName) => {
  //   return lineName.toLowerCase().includes(searchText.toLowerCase())
  // })
  const isGroupedCategory = firstProduct?.category
    ? GROUPED_CATEGORIES.includes(firstProduct?.category?.toLowerCase())
    : false
  const totalInventoryCost = calculateInventoryCost(products)
  // const filters = useAppSelector(selectProductFilters)
  const checklistMap = useAppSelector(selectChecklistItemsByCode)
  const modelsLoaded = !!checklistMap

  useEffect(() => {
    if (!modelsLoaded && user.token) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, modelsLoaded, user.token, user.userId])

  const releaseUnitsSupport = ReleaseUnitsSupport();
  const staticUnit = 'g or ml'
  const units = releaseUnitsSupport ? firstProduct?.units ?? staticUnit : staticUnit
  return (
    <Drawer size="lg" isOpen={isVisible} placement="right" onClose={onClose} autoFocus={false}>
      <DrawerOverlay />
      <DrawerContent overflow="scroll" bg={ColorForMode('card-bg')}>
        <DrawerHeader p={0}>
          <Flex
            pl={isMobile ? '12px' : '0'}
            pr={isMobile ? '12px' : '0'}
            pt="48px"
            position="relative"
            direction="column"
            align="center"
            justify="center"
            bg={COLORS.lavender_50}
          >
            <Text variant="largetitle" textTransform="uppercase">
              {isEntireCategory ? firstProduct?.category : firstProduct?.line.name}
            </Text>
            {!isEntireCategory && (
              <>
                <Box h="12px" />
                <Text fontSize="20px" fontWeight="normal" p="0 12px" textAlign="center">
                  {firstProduct?.vendor.name} • {firstProduct?.category} •{' '}
                  {isGroupedCategory ? 'Multiple Sizes' : `${firstProduct?.size}${units}`}
                </Text>
                <Box h="12px" />
                <Flex background={COLORS.midnight_50} borderRadius="50px" padding="8px 12px">
                  <Text variant="body" fontWeight="normal">
                    Cost of Inventory: ${totalInventoryCost}
                  </Text>
                </Flex>
                <Box h="12px" />
                <Flex
                  align="center"
                  gridGap="4px"
                  onClick={() => {
                    dispatch(reduceSetInventoryInfoSheetVisibility(true))
                  }}
                  cursor="pointer"
                >
                  <MaterialIcon name="info" colorhex={COLORS.lavender_500} />
                  <Text variant="body" fontWeight="normal" color={COLORS.lavender_500} textDecoration="underline">
                    Learn about your inventory costs here!
                  </Text>
                </Flex>
              </>
            )}
            <Box h="24px" />
            <Box w="100%" p="0 24px">
              <DebouncedInput
                backgroundhex="white"
                inputLeftElement={<SearchIcon color={COLORS.placeholder} />}
                width="100%"
                initialValue={searchText}
                onValueChange={(searchText) => {
                  setSearchText(searchText)
                  // dispatch(reduceSetProductFilters({ ...filters, ...{ searchText } }))
                }}
              />
            </Box>
            <Box h="24px" />
            {/*<Flex align="center" gridGap="12px">*/}
            {/*{isEntireCategory && (*/}
            {/*  <BulkUpdateDialog*/}
            {/*    products={products}*/}
            {/*    title={`Set all Prices and Markup`}*/}
            {/*    subtitle={`All products will be affected`}*/}
            {/*  >*/}
            {/*    <Button variant="round-outline-lower">Edit All {categoryName} Prices</Button>*/}
            {/*  </BulkUpdateDialog>*/}
            {/*)}*/}
            <Button variant="round-ghost" onClick={onClose} position="fixed" top="12px" right="12px">
              Close
            </Button>
            {/*</Flex>*/}
          </Flex>
        </DrawerHeader>

        <Accordion allowToggle index={expandedIndex}>
          {lines.map((lineName, index) => {
            return (
              <CategoryCard
                isEntireCategory={isEntireCategory}
                key={lineName}
                index={index}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
                categoryName={lineName}
                products={productsByLine[lineName]}
                variant="edit"
                onClose={onClose}
              />
            )
          })}
        </Accordion>
      </DrawerContent>
    </Drawer>
  )
}

export const CategoryCard = (props: {
  categoryName: string
  isEntireCategory: boolean
  products: Product[]
  index: number
  expandedIndex: number
  setExpandedIndex: (index: number) => void
  variant: 'view' | 'edit'
  onClose: () => void
}) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const [allSelected, setAllSelected] = useState(false)
  const { categoryName, isEntireCategory, products, expandedIndex, setExpandedIndex, index, variant, onClose } = props
  const sorter = (product) => product.type
  const sortedProducts = products ? orderBy(products, [sorter], ['asc']) : []
  const selectedProductMap = useAppSelector(selectSelectedProductMap)
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)

  const toggleProductSelected = (productId: number) => {
    if (productId in selectedProductMap) {
      const updatedMap = omit(selectedProductMap, [productId])
      dispatch(reduceSelectedProductMap(updatedMap))
    } else {
      const updatedMap = assign({}, selectedProductMap, { [productId]: true })
      dispatch(reduceSelectedProductMap(updatedMap))
    }
  }
  const selectAllProducts = () => {
    const allIdMap = {}
    products.forEach((product) => {
      allIdMap[product.id] = true
    })
    dispatch(reduceSelectedProductMap(allIdMap))
  }
  const isChecked = (productId: number): boolean => {
    return !!selectedProductMap[productId]
  }
  const toggleAllSelected = () => {
    if (allSelected) {
      setAllSelected(false)
      dispatch(reduceSelectedProductMap({}))
    } else {
      setAllSelected(true)
      selectAllProducts()
    }
  }
  const releaseUnitsSupport = ReleaseUnitsSupport();

  const usingTrialPricing = sortedProducts.some(p => p.isDefaultPrice)
  const productIdentifierWidth = isMobile ? '100%' : '200px'
  return (
    <>
      <AccordionItem border="none">
        {/* make accordion button invisible unless there are multiple lines*/}
        {isEntireCategory ? (
          <AccordionButton
            bg={ColorForMode('brand-light')}
            color="black"
            // bg={(index + 1) % 2 === 0 ? ColorForMode('secondary-bg') : ''}
            w="100%"
            p="0 12px 0 0"
            border="none"
            _focus={{ border: 'none' }}
            _hover={{ background: ColorForMode('brand-light') }}
            onClick={() => (index === expandedIndex ? setExpandedIndex(-1) : setExpandedIndex(index))}
          >
            <Box p="12px 0" flex="1">
              <Flex align="center" justify="space-between">
                <Flex align="center" gridGap="12px">
                  <Box isTruncated={true} p="12px 24px">
                    <Text variant="title3">{categoryName}</Text>
                  </Box>
                </Flex>
              </Flex>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        ) : (
          <AccordionButton />
        )}
        <AccordionPanel pb={4} bg={(index + 1) % 2 === 0 ? ColorForMode('secondary-bg') : ''}>
          {/*<Flex justify="flex-end">*/}
          {/*  <BulkUpdateDialog*/}
          {/*    products={products}*/}
          {/*    title={`Set ${categoryName} Prices and Markup`}*/}
          {/*    subtitle={`All ${categoryName} products will be affected`}*/}
          {/*  >*/}
          {/*    <Button variant="round-outline-lower" colorScheme="brand.lavender" overflow="hidden">*/}
          {/*      Edit All {categoryName} Prices*/}
          {/*    </Button>*/}
          {/*  </BulkUpdateDialog>*/}
          {/*</Flex>*/}
          {usingTrialPricing && <TrialPricingBanner/>}

          <Flex justify="space-between" align="center" p="12px 4px" minH="65px">
            <Checkbox
              isChecked={allSelected}
              colorScheme="brand.lavender"
              onChange={toggleAllSelected}
              fontStyle="italic"
              fontWeight="bold"
            >
              Select All {products.length} Sub Items
            </Checkbox>
            {keys(selectedProductMap).length > 0 && (
              <Button
                variant="round"
                colorScheme="brand.midnight"
                onClick={() => {
                  dispatch(reduceSetSelectedProductIds(map(keys(selectedProductMap), (key) => parseInt(key))))
                  // reset selected products
                  setAllSelected(false)
                  dispatch(reduceSelectedProductMap({}))
                  onClose()
                }}
              >
                Edit Products ({keys(selectedProductMap).length})
              </Button>
            )}
          </Flex>
          <Flex>
            {!isMobile && <Box minW={productIdentifierWidth} />}

            <ProductAutoSaveFormTitleRow willOnlySetValueForOneProduct={true} showDelete={true}/>
          </Flex>

          {sortedProducts.map((product, productIndex) => {
            const staticUnit = 'g or ml'
            const units = releaseUnitsSupport ? product?.units ?? staticUnit : staticUnit
            return (
              <Box key={product.id}>
                <Flex
                  key={product.id}
                  p="12px 4px"
                  align="center"
                  gridGap="4px"
                  justify="space-between"
                  wrap={isMobile ? 'wrap' : 'nowrap'}
                >
                  <Flex cursor="pointer" minW={productIdentifierWidth} w={productIdentifierWidth} gridGap="12px">
                    <Checkbox
                      colorScheme="brand.lavender"
                      isChecked={isChecked(product.id)}
                      onChange={() => toggleProductSelected(product.id)}
                    >
                      <Flex
                        direction={isMobile ? 'row' : 'column'}
                        justify={isMobile ? 'space-between' : ''}
                        gridGap={isMobile ? '4px' : '0' }
                        align={isMobile ? 'center' : 'left'}
                      >
                        <Text fontSize="sm">{product.type}</Text>
                        <Text fontSize="xs" color={COLORS.text_secondary}>
                          {product.size}{units}
                        </Text>
                      </Flex>
                    </Checkbox>
                  </Flex>
                  {variant === 'view' && (
                    <Button
                      colorScheme="brand.lavender"
                      variant="round-ghost-lower"
                      onClick={() => dispatch(reduceSetSelectedProduct(product))}
                    >
                      View
                    </Button>
                  )}
                  {variant === 'edit' && (
                    <ProductAutoSaveForm
                      index={productIndex}
                      products={[product]}
                      showDelete={true}
                      onProductsUpdated={() => {
                        if (stepCompleteDrawerState !== HELP_DRAWER_STATES.button) {
                          dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
                        }
                      }}
                    />
                  )}
                </Flex>
                {isMobile && (
                  <>
                    <Divider />
                    <Box h="12px" />
                  </>
                )}
              </Box>
            )
          })}
          <Box h="100px" />
        </AccordionPanel>
      </AccordionItem>
    </>
  )
}

export const BulkUpdateDialog = (props: { children: any; title: string; subtitle: string; products: Product[] }) => {
  const { children, title, subtitle, products } = props
  const dispatch = useDispatch()
  const loggedInUser = useAppSelector(selectLoggedInUser)
  const urlParams: any = useParams()
  const { onOpen, onClose, isOpen } = useDisclosure()

  const onSubmit = (values: any) => {
    const { salonId } = urlParams

    const updatedProducts: APIUpdateProduct[] = products.map((product) => {
      return {
        product_id: product.id,
        wholesale_price: parseFloat(values.cost),
        mark_up: parseFloat(values.markup),
      }
    })
    if (loggedInUser) {
      const params = {
        token: loggedInUser.token,
        user_id: loggedInUser.userId,
        salon_id: salonId,
        models: updatedProducts,
        loadingName: buildLoadingName(updatedProducts[0].product_id),
      }
      dispatch(dispatchUpdateProducts(params))
    }
    onClose()
  }
  return (
    <Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Flex p="24px 0" direction="column">
            <Text p="0 0 24px 0" fontSize="lg">
              {title}
            </Text>
            <Text p="0 0 24px 0" fontSize="sm">
              {subtitle}
            </Text>
            <ProductForm product={products[0]} onSubmit={onSubmit} />
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}


