import { Flex, NumberInput, NumberInputField } from '@chakra-ui/react'
import { Loading } from '../../../mini-lib/loading/Loading'
import React from 'react'
import { buildLoadingName } from '../../../core/loading/utils'
import { useAppSelector } from '../../../hooks'
import { selectLoadingState } from '../../../core/loading/slice'
import { InventoryCountItem } from '../../../data/inventory/interfaces'
import { FormikError } from "../../../mini-lib/formik-utils/FormikError";

export const InventoryQuantityField = (props: {
  item: InventoryCountItem
  quantity: number | string | undefined
  onUpdateQuantity: any
  setQuantity: any
  isDisabled: boolean
}) => {
  const { item, quantity, setQuantity, onUpdateQuantity, isDisabled } = props
  const loadingQuantityName = buildLoadingName(`inventory-quantity-${item.id}`)
  const loadingQuantity = useAppSelector((state) => selectLoadingState(state, loadingQuantityName))
  return (
    <Flex w="150px" justify="center" align="center" direction="column">
      <NumberInput
        isDisabled={isDisabled}
        w="100px"
        minW="100px"
        min={0}
        onChange={(updatedPrice) => setQuantity(updatedPrice)}
        onBlur={(evt: any) => {
          // possible todo: if we allow them to set quantities to null in the future allow this but for now it breaks the api
          const currentVal = evt.target.value
          if (currentVal !== '') {
            onUpdateQuantity(currentVal)
          }
        }}
        value={quantity}
      >
        {loadingQuantity ? (
          <Loading />
        ) : (
          <NumberInputField textAlign="center" p="0 16px" placeholder="-" borderRadius="50px" />
        )}
      </NumberInput>
      <FormikError showError={quantity === ''} errorText="must have a value" />
    </Flex>
  )
}
