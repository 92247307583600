import {
  Box,
  Button,
  Circle,
  Flex,
  Image,
  Input,
  InputGroup,
  NumberInput,
  NumberInputField,
  Select,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { UseViewSize } from '../../../core/UseViewSize'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useAppSelector } from '../../../hooks'
import { selectEmptyBowls } from '../../../data/empty-bowls/slice'
import {
  dispatchCreateEmptyBowl,
  dispatchDeleteEmptyBowl,
  dispatchListEmptyBowls,
  dispatchUpdateEmptyBowl,
} from '../../../data/empty-bowls/api'
import { EmptyBowl } from '../../../data/empty-bowls/interfaces'
import { COLORS } from '../../../mini-lib/theme/colors'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { SalonScaleProductIcon } from '../../../mini-lib/icons/SalonScaleProductIcon'
import { assign, values } from 'lodash'
import {
  BOWL_SHEET_VIEW_TYPES_LARA,
  BowlSheetViewTypeLara,
  DISPLAY_UNIT_OPTIONS
} from '../../../data/sessions/constants'
import { ConfirmPopover } from '../../../mini-lib/confirm-popover/ConfirmPopover'
import { FileUpload } from '../../../mini-lib/file-upload/FileUpload'
import { isImageType } from '../../../core/validators'
import { toast } from 'react-toastify'
import { normalizeToGrams } from '../../../data/sessions/utils'
import { dispatchUploadFile } from '../../../core/file-upload/api'
import { selectFileUrlUploadByName } from '../../../core/file-upload/slice'
import { FormikError } from "../../../mini-lib/formik-utils/FormikError";

export const SessionBowlEmptyBowlDetailsView = (props: {
  setSelectedEmptyBowl: (updated: Partial<EmptyBowl> | null) => void
  selectedEmptyBowl: Partial<EmptyBowl> | null
  setBowlView: (view: BowlSheetViewTypeLara) => void
  onEmptyBowlSelect: (bowl: EmptyBowl) => void
}) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const { salonId, user } = UseBaseApiParams()
  const { selectedEmptyBowl, setSelectedEmptyBowl, setBowlView } = props
  const emptyBowls = useAppSelector(selectEmptyBowls)

  const uploadName = selectedEmptyBowl?.id ? selectedEmptyBowl.id.toString() : new Date().getUTCMinutes().toString()
  const uploadedPhotoUrl = useAppSelector((state) => selectFileUrlUploadByName(state, uploadName))

  const emptyBowlsLoaded = !!emptyBowls
  useEffect(() => {
    if (uploadedPhotoUrl) {
      setBowlPhotoUrl(uploadedPhotoUrl)
    }
  }, [uploadedPhotoUrl])

  useEffect(() => {
    if (!emptyBowlsLoaded) {
      dispatch(dispatchListEmptyBowls({ token: user.token, salonId }))
    }
  }, [dispatch, user.token, emptyBowlsLoaded, salonId])

  const onSaveEmptyBowl = () => {
    if (!bowlName) {
      setBowlNameErrorText('Please add a name for your bowl')
      return
    }
    if (!bowlWeight) {
      setBowlWeightErrorText('Please add a weight for your bowl')
      return
    }
    const updatedProperties: Partial<EmptyBowl> = {
      name: bowlName,
      amountInGrams: normalizeToGrams(parseFloat(bowlWeight), bowlUnit),
      photoUrl: bowlPhotoUrl,
    }
    const updatedBowl: EmptyBowl | any = assign({}, selectedEmptyBowl, updatedProperties)
    if (selectedEmptyBowl?.id) {
      dispatch(dispatchUpdateEmptyBowl({ token: user.token, salonId, bowl: updatedBowl }))
    } else {
      dispatch(dispatchCreateEmptyBowl({ token: user.token, salonId, bowl: updatedBowl }))
    }
    setBowlView(BOWL_SHEET_VIEW_TYPES_LARA.emptyBowls)
  }

  const onDeleteEmptyBowl = () => {
    if (!!selectedEmptyBowl?.id) {
      dispatch(dispatchDeleteEmptyBowl({ token: user.token, salonId, bowlId: selectedEmptyBowl.id }))
      setBowlView(BOWL_SHEET_VIEW_TYPES_LARA.emptyBowls)
    }
  }

  const [bowlName, setBowlName] = useState<string>(selectedEmptyBowl?.name || '')
  const [bowlNameErrorText, setBowlNameErrorText] = useState<string>('')

  const [bowlWeight, setBowlWeight] = useState<string>(selectedEmptyBowl?.amountInGrams?.toString() || '0')
  const [bowlWeightErrorText, setBowlWeightErrorText] = useState<string>('')

  const [bowlPhotoUrl, setBowlPhotoUrl] = useState<string>(selectedEmptyBowl?.photoUrl || '')
  const [bowlUnit, setBowlUnit] = useState<'g' | 'oz'>('g')

  const onEditPhoto = (file: File | null) => {
    if (file && isImageType(file.type)) {
      dispatch(dispatchUploadFile({ token: user.token, uploadName, file }))
    } else {
      toast.error('Please enter a valid image')
    }
  }
  return (
    <>
      <Flex justify="space-between" gridGap="12px">
        <MaterialIcon
          cursor="pointer"
          colorhex={COLORS.lavender_500}
          size="36px"
          name="chevron_left"
          onClick={() => {
            setBowlView(BOWL_SHEET_VIEW_TYPES_LARA.emptyBowls)
            setSelectedEmptyBowl(null)
          }}
        />
        <Flex align="center" gridGap="12px">
          {selectedEmptyBowl?.id && (
            <ConfirmPopover
              title={`This Action is Permanent`}
              subtitle={`This will delete the bowl from your list of bowls`}
              onConfirm={() => {
                onDeleteEmptyBowl()
              }}
            >
              <Button variant="round-ghost-upper" color="danger" minW="100px">
                {isMobile ? 'Delete' : 'Delete Bowl'}
              </Button>
            </ConfirmPopover>
          )}

          <Button colorScheme="brand.midnight" variant="round" onClick={onSaveEmptyBowl} minW="100px">
            Save
          </Button>
        </Flex>
      </Flex>

      <Box h="12px" />

      <Text variant="largetitle" textTransform="uppercase">
        {selectedEmptyBowl?.id ? 'Edit Empty Bowl' : 'Add Empty Bowl'}
      </Text>

      <Box h="12px" />

      <Box>
        <Box h="48px" />
        <Flex justify="center">
          <Circle position="relative" bg={COLORS.shades_neutral_200} p="4px">
            {/* having two circles here is planned
              the outer circle is used to anchor the absolutely positioned button
              the inner circle is used to hide the overflow from square photos
            */}

            <Circle overflow="hidden">
              {bowlPhotoUrl && <Image h="100px" w="100px" src={bowlPhotoUrl} />}
              {!bowlPhotoUrl && <SalonScaleProductIcon size="100px" name="emptybowl" />}
              <Circle
                cursor="pointer"
                borderRadius="100px"
                size="40px"
                p="4px"
                bg={COLORS.lavender_500}
                position="absolute"
                bottom={0}
                right={0}
              >
                <FileUpload
                  override={<MaterialIcon colorhex="white" name="edit" />}
                  onFileUpload={(file) => onEditPhoto(file)}
                />
              </Circle>
            </Circle>
          </Circle>
        </Flex>
        <Box h="48px" />
        <Flex justify="space-between" align="center">
          <Text>Bowl Name</Text>
          <Box>
            <InputGroup w="252px" border="1px solid" borderColor="shades.neutral.300" style={{ borderRadius: '100px' }}>
              <Input
                border="none"
                _focus={{ border: 'none' }}
                value={bowlName}
                onChange={(evt) => {
                  evt.target.value === ''
                    ? setBowlNameErrorText('Please add a name for your bowl')
                    : setBowlNameErrorText('')
                  setBowlName(evt.target.value)
                }}
                placeholder="add a bowl name"
              />
            </InputGroup>
          </Box>
        </Flex>
        <Flex justify="right">
          <FormikError showError={bowlNameErrorText !== ''} errorText={bowlNameErrorText} />
        </Flex>

        <Box h="24px" />
        <Flex justify="space-between" align="center">
          <Text>Bowl Weight</Text>
          <Flex gridGap="12px">
            <Box>
              <NumberInput
                w="120px"
                value={bowlWeight}
                inputMode="decimal"
                precision={2}
                min={0}
                onChange={(updatedAmount) => {
                  updatedAmount === ''
                    ? setBowlWeightErrorText('Please add a weight to your bowl')
                    : setBowlWeightErrorText('')
                  setBowlWeight(updatedAmount)
                }}
                borderRadius="100px"
              >
                <NumberInputField borderRadius="100px" />
              </NumberInput>
            </Box>
            <Select
              value={bowlUnit}
              borderRadius="100px"
              w="120px"
              onChange={(evt: any) => setBowlUnit(evt.target.value)}
            >
              {values(DISPLAY_UNIT_OPTIONS).map((option: any, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                )
              })}
            </Select>
          </Flex>
        </Flex>
        <Flex justify="right">
          <FormikError showError={bowlWeightErrorText !== ''} errorText={bowlWeightErrorText} />
        </Flex>
      </Box>
    </>
  )
}
