import { PopupButton, useCalendlyEventListener } from "react-calendly";
import { Box } from "@chakra-ui/react";

export const CalendlyPopupWidget = (props: {text: string, styleOverrides?: object}) => {
  const {text, styleOverrides = {}} = props
  const url = 'https://calendly.com/d/5gf-58v-jpr/book-a-30-minute-live-demo-with-a-salonscale-expert?hide_gdpr_banner=1'
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: ( e ) => console.log(e.data.payload),
  });
  // note: the id='root' is required to render the modal
  const calendlyRoot: any = document.getElementById("root")
  return (
    <Box id='root' width='100%'>
        <PopupButton
          url={url}
          rootElement={calendlyRoot}
          text={text}
          styles={{width: '100%', ...styleOverrides}}
        />
    </Box>
  )
}
