import React, { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { OrderAddProductsButton } from './OrderAddProductsButton'
import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { OrderForm } from './OrderForm'
import { useAppSelector } from '../../hooks'
import {
  reduceSetProductsOnOrder,
  reduceSetSelectedOrderQuantitiesByProductId,
  selectSelectedOrderQuantitiesByProductId,
} from '../../data/orders/slice'
import { calculateOrderTotal } from '../../data/orders/utils'
import { APIOrderCreateLara, APIOrderItemCreateLara } from '../../data/orders/interfaces'
import { delay, values } from 'lodash'
import { useDispatch } from 'react-redux'
import { dispatchCreateOrderLara } from '../../data/orders/api'
import { buildLaraServerDateTime } from '../../core/dates'
import { OrderAddIndividualProductsButton } from './OrderAddIndividualProductsSheet'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { selectChecklistItem } from '../../data/start-guide/slice'
import { Loading } from '../../mini-lib/loading/Loading'
import { CHECKLIST_CODES, CHECKLIST_ITEMS_LOADING } from '../../data/start-guide/constants'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { ReleaseInventoryOrders } from '../../mini-lib/flags/Release'
import { OrderCreateModal } from './OrderCreateModal'
import { selectLoadingState } from '../../core/loading/slice'
import { ROUTES } from "../../appRoutes";
import { OrderNameSheet } from "./OrderNameSheet";
import { MaterialIcon } from "../../mini-lib/icons/MaterialIcon";

export const OrderCreatePage = () => {
  const addressFormRef: any = React.useRef(null);
  const {
    user,
    user: { token, userId },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = UseQueryParams()
  const releaseInventoryOrders = ReleaseInventoryOrders()
  const pageType = params.get('pageType')
  const isInventoryOrderPage = pageType === 'inventory'
  const isLoading = useAppSelector(( state ) => selectLoadingState(state, CHECKLIST_ITEMS_LOADING))
  const [orderName, setOrderName] = useState('')
  const [showOrderNameSheet, setShowOrderNameSheet] = useState(false)

  useEffect(() => {
    Boolean(isInventoryOrderPage && releaseInventoryOrders) && dispatch(dispatchListUserChecklistItems(token, userId))
  }, [dispatch, token, userId, isInventoryOrderPage, releaseInventoryOrders])

  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Orders', url: generatePath(ROUTES.orders, { salonId }) },
    { label: 'Create Order' },
  ]
  const quantitiesByProductId = useAppSelector(selectSelectedOrderQuantitiesByProductId)
  const validOrderItems = values(quantitiesByProductId).filter(( item ) => item.order_item_quantity > 0)
  const validOrderItemsLara: APIOrderItemCreateLara[] = values(quantitiesByProductId).filter(( item ) => item.order_item_quantity > 0).map(item => ( {
    product_id: item.product_id,
    order_quantity: item.order_item_quantity
  } ))

  const preSubmit = () => {
    if (addressFormRef.current) {
      addressFormRef.current.handleSubmit();
  }
  }

  const createOrder = (values, useSalonAddress) => {
    const shippingAddressValues = values
    const total = calculateOrderTotal(quantitiesByProductId)
    const laraRequest: APIOrderCreateLara = {
      name: orderName || null,
      salon_id: salonId,
      status: 'complete',
      total_ordered_cost: total,
      date: buildLaraServerDateTime(new Date()),
      order_items: validOrderItemsLara,

    }

    if(!useSalonAddress){
      laraRequest['shipping_address'] = shippingAddressValues
    }

    dispatch(dispatchCreateOrderLara({ token: user.token, salonId, body: laraRequest }))

    dispatch(reduceSetSelectedOrderQuantitiesByProductId({}))
    dispatch(reduceSetProductsOnOrder([]))
    delay(() => {
      history.push(generatePath(ROUTES.orders, { salonId }))
    }, 1000)
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const stepCodeInventorySetTargets = CHECKLIST_CODES.inventorySetTargets
  const stepCodeInventoryCompleteCount = CHECKLIST_CODES.inventoryCompleteCount
  const checklistItemInventorySetTargets = useAppSelector(( state ) =>
    selectChecklistItem(state, stepCodeInventorySetTargets),
  )
  const checklistItemInventoryCompleteCount = useAppSelector(( state ) =>
    selectChecklistItem(state, stepCodeInventoryCompleteCount),
  )
  const isInventoryGuideCompleted = Boolean(
    checklistItemInventorySetTargets.completed && checklistItemInventoryCompleteCount.completed,
  )

  return (
    <>
      <PageLayout
        variant="full"
        header={
          <PageHeader
            title={
            <Flex align='center' gridGap='12px'>
              {orderName ? orderName : 'Create Order'} <MaterialIcon onClick={() => setShowOrderNameSheet(true)} cursor='pointer' name='edit'/>
            </Flex>
          }
            breadcrumbs={breadcrumbs}
            actions={
              <>
                <Flex align="center" gridGap="12px">
                  {isLoading ? (
                    <Loading/>
                  ) : (
                    <>
                      <OrderAddIndividualProductsButton/>
                      <OrderAddProductsButton
                        openModal={() => {
                          setIsModalOpen(true)
                        }}
                        isInventoryGuideCompleted={isInventoryGuideCompleted}
                      />
                      <Button
                        disabled={validOrderItems.length === 0}
                        variant="round"
                        colorScheme="brand.midnight"
                        onClick={preSubmit}
                      >
                        Create Order
                      </Button>
                    </>
                  )}
                </Flex>
                {isInventoryOrderPage &&
                    <Link width='100%' href={`${generatePath(ROUTES.ordersCreate, { salonId })}`}><Text
                        color="brand.lavender.500">Use legacy order create</Text></Link>}
              </>
            }
          />
        }
        content={
          <>
            {isLoading && <Loading/>}
            {!isLoading && <OrderForm addressFormRef={addressFormRef} onSubmit={createOrder}/>}
          </>
        }
      />
      {releaseInventoryOrders && <OrderCreateModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}/>}

      {showOrderNameSheet && <OrderNameSheet show={showOrderNameSheet} setShow={setShowOrderNameSheet} name={orderName} setName={setOrderName}/>}
    </>
  )
}
