import { InventoryCount, InventoryCountItem } from '../../../data/inventory/interfaces'
import { Box, Divider, Flex, Select, Text } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import React from 'react'
import { SalonScaleProductIcon } from '../../../mini-lib/icons/SalonScaleProductIcon'
import { Loading } from '../../../mini-lib/loading/Loading'
import { values } from 'lodash'
import { INVENTORY_ADD_OR_ORDER_COUNT_REASONS } from '../../../data/inventory/constants'
import { UseViewSize } from '../../../core/UseViewSize'
import { useAppSelector } from '../../../hooks'
import { selectLoadingState } from '../../../core/loading/slice'
import { buildLoadingName } from '../../../core/loading/utils'
import { InventoryQuantityField } from '../common/InventoryQuantityField'
import { centsToDollars } from '../../../core/money/utils'
import { ReleaseUnitsSupport } from "../../../mini-lib/flags/Release";

export const OrderTitle = (props: { count: InventoryCount }) => {
  return (
    <>
      <Flex justify="space-between" p="24px 0">
        <Box w="250px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Product
          </Text>
        </Box>
        <Box w="120px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Brand/Line
          </Text>
        </Box>
        <Flex w="120px" justify="center">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Ordered
          </Text>
        </Flex>
        <Flex w="150px" justify="center">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Delivered
          </Text>
        </Flex>
        <Flex w="150px" justify="center">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Status
          </Text>
        </Flex>
      </Flex>
      <Divider />
    </>
  )
}
export const OrderItemRow = (props: {
  count: InventoryCount
  item: InventoryCountItem
  quantity: string | number | undefined
  onUpdateQuantity: any
  onUpdateReason: any
  setQuantity: any
}) => {
  const { count, item, quantity, setQuantity, onUpdateQuantity, onUpdateReason } = props
  const { isMobile } = UseViewSize()
  const showColoredSelect = item.reason && item.reason !== 'received'
  const loadingReasonName = buildLoadingName(`inventory-reason-${item.id}`)
  const loadingReason = useAppSelector((state) => selectLoadingState(state, loadingReasonName))
  const releaseUnitsSupport = ReleaseUnitsSupport();
  const staticUnit = 'g or ml'
  const units = releaseUnitsSupport ? item.product?.units ?? staticUnit : staticUnit
  const isDisabled = !!count.completedAt
  return (
    <Box p="12px 0">
      <Flex justify="space-between" p="12px 0">
        <Box w={!isMobile ? '250px' : ''}>
          <Flex gridGap="12px">
            <SalonScaleProductIcon name={item.product.category} />
            <Box>
              <Text variant="body">{item.product.type}</Text>
              <Text variant="caption2" fontWeight="normal" color={COLORS.text_secondary}>
                {item.product.size}{units} • ${centsToDollars(item.product.salonPurchasePriceCents)}
              </Text>
            </Box>
          </Flex>
          {isMobile && (
            <Flex gridGap="12px" p="12px 0">
              <InventoryQuantityField
                item={item}
                quantity={quantity}
                onUpdateQuantity={onUpdateQuantity}
                setQuantity={setQuantity}
                isDisabled={isDisabled}
              />
              {loadingReason ? (
                <Loading />
              ) : (
                <Select
                  w="50%"
                  isDisabled={isDisabled}
                  bg={showColoredSelect ? COLORS.lavender_100 : ''}
                  color={showColoredSelect ? COLORS.lavender_500 : ''}
                  borderColor={showColoredSelect ? COLORS.lavender_100 : ''}
                  placeholder="All reasons"
                  value={item.reason || ''}
                  borderRadius="100px"
                  onChange={(e: any) => onUpdateReason(e.target.value)}
                >
                  {values(INVENTORY_ADD_OR_ORDER_COUNT_REASONS).map((option: any, index) => {
                    return (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    )
                  })}
                </Select>
              )}
            </Flex>
          )}
        </Box>
        {!isMobile && (
          <>
            <Flex w="120px" direction="column" justify="center">
              <Text>{item.product.vendor}</Text>
              <Text color={COLORS.shades_neutral_500} variant="footnote">
                {item.product.line}
              </Text>
            </Flex>
            <Flex w="120px" justify="center" align="center">
              {item.expectedCount}
            </Flex>
            <InventoryQuantityField
              item={item}
              quantity={quantity}
              onUpdateQuantity={onUpdateQuantity}
              setQuantity={setQuantity}
              isDisabled={isDisabled}
            />
            <Flex justify="center" w="150px" align="center">
              {loadingReason ? (
                <Loading />
              ) : (
                <Select
                  isDisabled={isDisabled}
                  bg={showColoredSelect ? COLORS.lavender_100 : ''}
                  color={showColoredSelect ? COLORS.lavender_500 : ''}
                  borderColor={showColoredSelect ? COLORS.lavender_100 : ''}
                  placeholder="All reasons"
                  value={item.reason || ''}
                  borderRadius="100px"
                  onChange={(e: any) => onUpdateReason(e.target.value)}
                >
                  {values(INVENTORY_ADD_OR_ORDER_COUNT_REASONS).map((option: any, index) => {
                    return (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    )
                  })}
                </Select>
              )}
            </Flex>
          </>
        )}
      </Flex>
      <Divider />
    </Box>
  )
}
