import { AccordionButton, AccordionIcon, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { UseViewSize } from '../../../core/UseViewSize'
import { useDispatch } from 'react-redux'
import { reduceSetCurrentStepCode } from '../../../data/start-guide/slice'
import { IconName, SalonScaleIcon } from '../../../mini-lib/icons/SalonScaleIcon'

export const StepGroupAccordionHeader = (props: {
  iconName: IconName
  title: string
  stepsCompleted: number
  stepsLength: number
  initialStepCode: string
}) => {
  const dispatch = useDispatch()
  const { iconName, title, stepsCompleted, stepsLength, initialStepCode } = props
  const { isMobile } = UseViewSize()
  return (
    <AccordionButton
      p="12px"
      m={0}
      border="none"
      bg={COLORS.lavender_100}
      borderRadius="15px"
      _expanded={{ borderRadius: '15px 15px 0 0' }}
      _hover={{ bg: COLORS.lavender_300 }}
      onClick={() => dispatch(reduceSetCurrentStepCode(initialStepCode))}
    >
      <Flex justify="space-between" width="100%" align="center">
        <Flex align="center" gridGap="24px">
          <SalonScaleIcon name={iconName} size="48px" />
          <Flex align="flex-start" direction="column">
            <Text variant="title3">{title}</Text>
            {isMobile && (
              <Text variant="caption1">
                {stepsCompleted}/{stepsLength} steps
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex align="center" gridGap="24px">
          {!isMobile && (
            <Text variant="title1 fontWeight='bold'">
              {stepsCompleted}/{stepsLength} steps
            </Text>
          )}
          <AccordionIcon />
        </Flex>
      </Flex>
    </AccordionButton>
  )
}
