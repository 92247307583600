import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { UserTransferFromAngular } from '../../AuthDataLoadContainer'
import { useAppSelector } from '../../hooks'
import { updateQueryParams } from '../../mini-lib/utils/basic'
import { selectSelectedClient, selectSelectedClientMergeableClients } from '../../data/clients/slice'
import {
  dispatchDeleteClient,
  dispatchGetClientMergeableClients,
  dispatchMergeClients,
  dispatchSetSelectedClientId,
} from '../../data/clients/api'
import { Client } from '../../data/clients/interfaces'
import { LoggedInUser } from '../../data/user/interfaces'
import { UseViewSize } from '../../core/UseViewSize'
import { ClientForm } from './ClientForm'
import {
  selectSessionPhotosForClient,
  selectSessionsForClientLara,
} from '../../data/sessions/slice'
import {
  dispatchListAllSessionPhotosLara,
  dispatchListSessionsLara
} from '../../data/sessions/api'
import { buildViewDate } from '../../core/dates'
import { ColorForMode } from '../../theme'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { SessionMetaLara, SessionPhotoLara } from '../../data/sessions/interfaces'
import { Loading } from '../../mini-lib/loading/Loading'
import { selectLoadingState } from '../../core/loading/slice'
import { ROUTES } from "../../appRoutes";
import { capitalize } from "lodash";
import { formatCentsToDollars } from "../../mini-lib/units/money";

export const tabs = {
  edit: 0,
  merge: 1,
  sessions: 2,
  notes: 3,
  photos: 4,
}

export const ClientDetailsSheet = () => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const [currentTab, setCurrentTab] = useState(tabs.edit)
  const client = useAppSelector(selectSelectedClient)
  const { user, salonId } = UseBaseApiParams()
  const sessionsForClient = useAppSelector((state) => selectSessionsForClientLara(state, client?.id || -1))
  const isClient = !!client
  const loadingSessionsForClientKey = client ? `sessions-${client.id}` : ''
  const isLoadingSessions = useAppSelector((state) => selectLoadingState(state, loadingSessionsForClientKey))
  useEffect(() => {
    if (user && isClient && client.id >= 0) {
      dispatch(dispatchListSessionsLara({ token: user.token, salonId, pageSize: 100, pageNumber: 1, clientId: client.id, loadingName: loadingSessionsForClientKey }))
    }
  }, [dispatch, user, isClient, client?.id, salonId, loadingSessionsForClientKey])
  return (
    <Drawer
      size={isMobile ? 'xs' : 'md'}
      isOpen={!!client}
      placement="right"
      onClose={() => dispatch(dispatchSetSelectedClientId({ selectedClientId: null }))}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          {client && client.id >= 0 ? (
            <Flex justify="space-between" align="center">
              <div>
                {client.firstName} {client.lastName}
              </div>
              <DrawerCloseButton className="cy-close-button" />
            </Flex>
          ) : (
            <div>Add Client</div>
          )}
        </DrawerHeader>
        {client && client.id >= 0 ? (
          <Tabs index={currentTab} colorScheme="brand.lavender">
            <TabList overflowX="auto" padding="4px">
              <Tab onClick={() => setCurrentTab(tabs.edit)} className="cy-edit-tab">
                Edit
              </Tab>
              <Tab onClick={() => setCurrentTab(tabs.merge)} className="cy-merge-tab">
                Merge
              </Tab>
              <Tab onClick={() => setCurrentTab(tabs.sessions)} className="cy-sessions-tab">
                Sessions
              </Tab>
              <Tab onClick={() => setCurrentTab(tabs.notes)} className="cy-notes-tab">
                Notes
              </Tab>
              <Tab onClick={() => setCurrentTab(tabs.photos)} className="cy-photos-tab">
                Photos
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>{currentTab === tabs.edit && <ClientForm />}</TabPanel>
              <TabPanel overflow="auto" maxH="85vh">
                {currentTab === tabs.merge && <MergeClients user={user} salonId={salonId} client={client} />}
              </TabPanel>
              {/* session list */}
              <TabPanel p="12px" overflow="auto" maxH="85vh">
                {currentTab === tabs.sessions && (
                  <ClientSessionList
                    client={client}
                    sessions={sessionsForClient}
                    isLoadingSessions={isLoadingSessions}
                  />
                )}
              </TabPanel>

              {/* session notes */}
              <TabPanel p="12px" overflow="auto" maxH="85vh">
                {currentTab === tabs.notes && (
                  <ClientNotes client={client} sessions={sessionsForClient} isLoadingSessions={isLoadingSessions} />
                )}
              </TabPanel>

              {/* session photos */}
              <TabPanel p="12px" overflow="auto" maxH="85vh">
                {currentTab === tabs.photos && (
                  <ClientPhotos client={client} />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <ClientForm />
        )}
      </DrawerContent>
    </Drawer>
  )
}

export const DeleteClient = (props: { client: Client }) => {
  // const { onOpen, onClose, isOpen } = useDisclosure()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { client } = props
  const { token = '' } = UserTransferFromAngular()
  const { salonId } = UseBaseApiParams()
  return (
    <>
      {/*<Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="left">*/}
      {/*<PopoverTrigger>*/}
      <div
        onClick={() => {
          dispatch(dispatchDeleteClient({ token, salonId, model: client }))
          dispatch(dispatchSetSelectedClientId({ selectedClientId: null }))
          updateQueryParams(history, location, [{ action: 'add', paramKey: 'selectedClientId', paramValue: null }])
        }}
      >
        Delete Client
      </div>
      {/*  </PopoverTrigger>*/}
      {/*  <PopoverContent>*/}
      {/*    <PopoverArrow />*/}
      {/*    <PopoverCloseButton />*/}
      {/*    <PopoverHeader>This action is permanent</PopoverHeader>*/}
      {/*    <PopoverBody>*/}
      {/*      <div>Are you sure?</div>*/}
      {/*      <Flex justify="flex-end" gridGap="12px">*/}
      {/*        <div onClick={onClose}>Cancel</div>*/}
      {/*        <Box*/}
      {/*          // colorScheme="red"*/}
      {/*          // variant="solid"*/}
      {/*          onClick={() => {*/}
      {/*            dispatch(dispatchDeleteClient({ token, model: client }))*/}
      {/*            dispatch(reduceSetSelectedClient(null))*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          Yes Delete*/}
      {/*        </Box>*/}
      {/*      </Flex>*/}
      {/*    </PopoverBody>*/}
      {/*  </PopoverContent>*/}
      {/*</Popover>*/}
    </>
  )
}

export const MergeClients = (props: { client: Client; user: LoggedInUser; salonId: number }) => {
  const dispatch = useDispatch()
  const { user, salonId, client } = props

  const mergeableClients = useAppSelector(selectSelectedClientMergeableClients)
  const initialSelectedClients = mergeableClients ? mergeableClients.map(() => false) : []
  const [selectedClients, setSelectedClients] = useState(initialSelectedClients)

  const handleSelect = (checked: boolean, index: number) => {
    const updatedClients = [...selectedClients]
    updatedClients[index] = checked
    setSelectedClients(updatedClients)
  }
  const clientSelected = selectedClients.some((isSelected) => isSelected)
  // todo: make this cleaner, it would be nice if i could just get the value right out of the array
  // some small bug was preventing that, couldnt find it, not worth the time at the moment
  const getClientsToMerge = (): Client[] => {
    const toMerge: Client[] = []
    selectedClients.forEach((checked, index) => {
      if (checked && mergeableClients) {
        toMerge.push(mergeableClients[index])
      }
    })
    return toMerge
  }

  useEffect(() => {
    if (user.token && client && client.id >= 0) {
      dispatch(dispatchGetClientMergeableClients({ token: user.token, salonId: salonId, model: client }))
    }
  }, [dispatch, user.token, salonId, client])

  return (
    <>
      {client && mergeableClients && mergeableClients.length > 0 && (
        <Box p="24px 0">
          {mergeableClients.map((mclient, index) => {
            return (
              <Box key={mclient.id} p="4px">
                <Checkbox
                  colorScheme="brand.lavender"
                  isChecked={selectedClients[index]}
                  onChange={(e) => handleSelect(e.target.checked, index)}
                >
                  {mclient.firstName} {mclient.lastName}
                </Checkbox>
              </Box>
            )
          })}
          <Button
            m="24px 0 0 0"
            variant="round"
            disabled={!clientSelected}
            colorScheme="brand.lavender"
            onClick={() => {
              dispatch(
                dispatchMergeClients({
                  token: user.token,
                  salonId,
                  retainedClientId: client.id,
                  clientsToMerge: getClientsToMerge(),
                }),
              )
            }}
          >
            Merge Selected
          </Button>
        </Box>
      )}
      {client && mergeableClients && mergeableClients.length === 0 && (
        <Alert colorScheme="brand.lavender">
          <AlertIcon />
          <AlertDescription>
            No matches found to merge with {client.firstName} {client.lastName}
          </AlertDescription>
        </Alert>
      )}
      {(!client || !mergeableClients) && <Loading />}
    </>
  )
}

export const ClientSessionList = (props: {
  client: Client
  sessions: SessionMetaLara[] | null
  isLoadingSessions: boolean
}) => {
  const secondaryBgColor = ColorForMode('secondary-bg')
  const { client, sessions, isLoadingSessions } = props
  const { salonId } = UseBaseApiParams()
  return (
    <>
      {isLoadingSessions && <Loading variant="clear" />}
      {!isLoadingSessions && sessions && sessions.length === 0 && (
        <Alert colorScheme="brand.lavender">
          <AlertIcon />
          <AlertDescription>
            No sessions were found for {client.firstName} {client.lastName}
          </AlertDescription>
        </Alert>
      )}
      {!isLoadingSessions &&
        sessions &&
        sessions.length > 0 &&
        sessions.map((session, index) => {
          return (
            <Link key={session.id} to={generatePath(ROUTES.sessionDetails, { salonId, sessionId: session.id })}>
              <Flex align="center" justify="space-between" bg={(index + 1) % 2 === 0 ? secondaryBgColor : ''} p="12px">
                <Box>
                  <Box color="text.secondary">{session.date.toLocaleDateString('en-US')}</Box>
                  <div>
                    {session.user.firstName} {session.user.lastName}
                  </div>
                </Box>
                ${formatCentsToDollars(session.totalClientPurchasePriceCents)}
              </Flex>
            </Link>
          )
        })}
    </>
  )
}

export const ClientNotes = (props: { client: Client; sessions: SessionMetaLara[] | null; isLoadingSessions: boolean }) => {
  const { salonId } = UseBaseApiParams()
  const secondaryBgColor = ColorForMode('secondary-bg')
  const { sessions, client, isLoadingSessions } = props
  const sessionsWithNotes = sessions?.filter((session) => session.notes)
  return (
    <>
      {isLoadingSessions && <Loading />}
      {!isLoadingSessions && sessionsWithNotes && sessionsWithNotes.length === 0 && (
        <Alert colorScheme="brand.lavender">
          <AlertIcon />
          <AlertDescription>
            No notes were found for {client.firstName} {client.lastName}
          </AlertDescription>
        </Alert>
      )}
      {!isLoadingSessions &&
        sessionsWithNotes &&
        sessionsWithNotes.map((session, index) => {
          return (
            <Link key={session.id} to={generatePath(ROUTES.sessionDetails, { salonId, sessionId: session.id })}>
              <Box bg={(index + 1) % 2 === 0 ? secondaryBgColor : ''}>
                <Box color="text.secondary">{session.date.toLocaleDateString('en-US')}</Box>
                <div>{session.notes}</div>
              </Box>
            </Link>
          )
        })}
    </>
  )
}

export const ClientPhotos = (props: { client: Client; }) => {
  const { salonId, user, } = UseBaseApiParams()
  const secondaryBgColor = ColorForMode('secondary-bg')
  const { client } = props
  const dispatch = useDispatch()
  useEffect(() => {
      dispatch(dispatchListAllSessionPhotosLara({ token: user.token, salonId: salonId, clientId: client.id }))
  }, [dispatch, client.id, user.token, salonId])
  const sessionPhotos = useAppSelector(selectSessionPhotosForClient)
  const isLoadingSessions = sessionPhotos === null
  return (
    <>
      {isLoadingSessions && <Loading />}
      {!isLoadingSessions && sessionPhotos && sessionPhotos.length === 0 && (
        <Alert colorScheme="brand.lavender">
          <AlertIcon />
          <AlertDescription>
            No photos were found for {client.firstName} {client.lastName}
          </AlertDescription>
        </Alert>
      )}
      {!isLoadingSessions &&
        sessionPhotos &&
        sessionPhotos.map((sessionPhoto: SessionPhotoLara, index) => {
          return (
            <Link key={sessionPhoto.id} to={generatePath(ROUTES.sessionDetails, { salonId, sessionId: sessionPhoto.sessionId })}>
              <Box bg={(index + 1) % 2 === 0 ? secondaryBgColor : ''}>
                <Box color="text.secondary">{capitalize(sessionPhoto.photoTag)} {buildViewDate(sessionPhoto.sessionDate)}</Box>
                <Flex gridGap="12px" wrap="wrap">
                  <img
                    alt="client"
                    key={sessionPhoto.id}
                    style={{
                      cursor: 'pointer',
                      display: 'inline-block',
                      objectFit: 'cover',
                      height: '200px',
                      width: '200px',
                      borderRadius: '12px',
                    }}
                    src={sessionPhoto.photoUrl}
                      />
                </Flex>
              </Box>
            </Link>
          )
        })}
    </>
  )
}
