import { Box, Flex, Text } from '@chakra-ui/react'
import { sortBy } from 'lodash'
import { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { buildDateYYYYMMDD } from '../../core/dates'
import { selectLoadingState } from '../../core/loading/slice'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { dispatchGetAllAddonReportsForAddons } from '../../data/report/api'
import { REPORTS_LOADING_CONSTANT } from '../../data/report/constants'
import { AddonReportAddon } from '../../data/report/interfaces'
import {
  selectCustomEndDate,
  selectCustomStartDate, selectAddonReportsList, selectReportsDateRangeType
} from '../../data/report/slice'
import { useAppSelector } from '../../hooks'
import { convertDateRangeTypeToDates } from '../../mini-lib/dates-and-times/utils'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { Loading } from '../../mini-lib/loading/Loading'
import { VARIANTS } from '../../mini-lib/theme/variants'
import { TableHead, TableContent, TableTotal } from './Table'

export const AddonPage = (props: { page: string }) => {

  return (
    <AddonReportsTable />
  )
}

export const AddonReportsTable = () => {
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const loadingReport = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const models = useAppSelector(selectAddonReportsList)
  const sumRevenue = models ? models.map((model) => model.retail).reduce((a, b) => a + b, 0) : null

  // future todo: add paging to extensions table
  // const [numRowsToShow, setNumRowsToShow] = useState(30)
  // const modelsToShow = models ? models.slice(0, numRowsToShow) : null

  useEffect(() => {
    dispatch(dispatchGetAllAddonReportsForAddons({ token, salonId, dateStart: sDate, dateEnd: eDate }))
  }, [dispatch, token, salonId, sDate, eDate])

  return (
    <Flex flexDirection="column" width="100%">
      <Flex width="100%" align="center" p="20px" borderRadius="15px" bg="brand.linen.50" maxH="50px">
        <Text variant={VARIANTS.text.caption1}>Addons</Text>
      </Flex>
      <Box h={'25px'} />
      {!loadingReport && models && models.length > 0 && (
        <AddonsReportTableContainer
          modelsToShow={models}
          sumRevenue={sumRevenue}
        />
      )}
      {!loadingReport && models && models.length === 0 && <EmptyBox />}
      {loadingReport && <Loading />}
    </Flex>
  )
}

export const AddonsReportTableContainer = (props: {
  modelsToShow: AddonReportAddon[]
  sumRevenue: number | null
}) => {
  const { modelsToShow, sumRevenue } = props
  const list = [
    { label: 'Title' },
    { label: 'Price', value: sumRevenue, additionalProps: { isNumeric: true } }
  ]
  return (
    <>
      <TableHead list={list} />
      {sortBy(modelsToShow, 'brand').map((product, index) => (
        <Fragment key={index}>
          <AddonsReportTableRow product={product} id={index} />
        </Fragment>
      ))}
      <TableTotal list={list} />
    </>
  )
}

export const AddonsReportTableRow = (props: {
  product: AddonReportAddon
  id: number
}) => {
  const { product, id } = props
  const list = [
    { label: 'Title', value: product.title },
    { label: 'Price', value: product.retail, additionalProps: { isNumeric: true } }
  ]
  return (<TableContent id={id} list={list} action={<div></div>} />)
}
