import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectChecklistItem, selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems, dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGSessionsIntroPage = () => {
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])
  const stepCodes = [CHECKLIST_CODES.learnSessions, CHECKLIST_CODES.learnAcademy]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  const currentCode = CHECKLIST_CODES.learnSessions
  const currentStep = useAppSelector((state) => selectChecklistItem(state, currentCode))
  const maybeUpdateChecklist = () => {
    if (!currentStep.completed) {
      dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: currentCode}))
    }
  }
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="learn"
          title="Learn how to create a session"
          description={descriptionText}
          video={VIDEO_MAP.addSession}
          actions={
           <SGActionButtons
              action={{
                label: 'Next',
                url: generatePath(ROUTES.sgAcademyIntro, { salonId }),
                onClick: maybeUpdateChecklist
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={stepCodes.length} stepsComplete={stepsCompleted || 0} />}
          imgPath={require('../../assets/start-guide/session.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `Watch this video to learn how to add sessions in the app or in the business center.

Tip: Your stylists will use the app on their phone to create sessions during each appointment. Your front desk staff may want to be familiar with viewing sessions here in the Business Center. 

This video will walk you through both examples.`
