import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectChecklistItem, selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems, dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGPOSIntroPage = () => {
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, [CHECKLIST_CODES.learnPOS]))
  const currentCode = CHECKLIST_CODES.learnPOS
  const currentStep = useAppSelector((state) => selectChecklistItem(state, currentCode))
  const maybeUpdateChecklist = () => {
    if (!currentStep.completed) {
      dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: currentCode}))
    }
  }
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="implementation"
          title="CONNECT TO YOUR BOOKING SYSTEM"
          description={descriptionText}
          video={VIDEO_MAP.squareGuideVideo}
          actions={
            <SGActionButtons
              action={{
                label: 'Connect to your booking software',
                url: generatePath(ROUTES.integrationSettings, { salonId }) + `?guide=${CHECKLIST_CODES.learnPOS}`,
                onClick: maybeUpdateChecklist
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={1} stepsComplete={stepsCompleted || 0} />}
          imgPath={require('../../assets/start-guide/pos.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `Charging for color just got easier with SalonScale's integration with Square. Create a seamless checkout experience by connecting your booking software to SalonScale, now available exclusively for Square customers. With this integration, appointments booked through Square are added to SalonScale app daily, ensuring all your data is in one place.

At the end of each session, the color cost is sent to Square so you can add this to the customer’s bill. Easily import team members from Square, and enjoy a smooth onboarding experience with our step-by-step walkthrough. This integration streamlines your operations, making color management and invoicing effortless.`
