import { Box, Button, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import React from 'react'
import { COLORS } from '../../mini-lib/theme/colors'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { reduceWeightUnit, selectedUnit } from '../../data/reports-v2/slice'
import { useAppSelector } from '../../hooks'
import { convertDisplayUnits } from '../../data/sessions/utils'
import { DropdownFilter } from '../../mini-lib/filters/DropdownFilter'
import { formatDollars } from "../../mini-lib/units/money";
import { pluralizeNameIfRequired } from '../../data/reports-v2/utils'

const columnSize = {
  xs: 75,
  sm: 100,
  m: 180,
  l: 340,
  xl: 400,
}

export const ReportTableHeaders = (props: {
  headers: { label: string; variant?: 'text' | 'weight' | string, tooltip?: string }[]
  actions?: any
  variant?: any
}) => {
  const { headers, actions = [] } = props
  const dispatch = useDispatch()
  const currentSelectedunit = useAppSelector(selectedUnit)

  const thProps: any = {
    color: 'text.secondary',
    fontWeight: 'normal',
    fontFamily: 'fonts.body',
    textTransform: 'lowercase',
    fontSize: '16px',
    padding: '1rem 0.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }

  const options = [
    { label: 'g', value: 'g' },
    { label: 'oz', value: 'oz' },
    { label: 'unit', value: 'unit' }
  ]

  return (
    <Thead>
      <Tr>
        {headers.map((header, key) => {
          if (header.variant === 'weight') {
            return (
              <Th key={key} {...thProps}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {header.label}
                  <DropdownFilter
                    variant="ghost"
                    theme="lavender"
                    w="52px"
                    placeholder=""
                    options={options}
                    value={currentSelectedunit}
                    onChange={(e) => {
                      dispatch(reduceWeightUnit(e.value))
                    }}
                  />
                </div>
              </Th>
            )
          }
          return (
            <Th key={key} {...thProps}>
              {header && header.tooltip
                ? <Tooltip label={header?.tooltip}><Box>{header.label}</Box></Tooltip>
                : <>{header.label}</>
              }
            </Th>
          )
        })}
        {/* make sure the table is the right length */}
        {actions.map((action, key) => {
          return <Th key={key} {...thProps} />
        })}
      </Tr>
    </Thead>
  )
}

export const ReportTableContent = (props: {
  properties: {
    name: string
    variant: 'text' | 'money' | 'percentage' | 'weight' | string
    mainText?: boolean
    size?: 'xs' | 'sm' | 'm' | 'l' | number | string
  }[]
  models: any[]
  actions?: { label: string; buildUrl: (urlProp: string) => string; variant: 'button' | string }[]
  urlProperty?: string
}) => {
  const { models, properties, actions, urlProperty = 'brand' } = props
  const currentSelectedunit = useAppSelector(selectedUnit)
  const thProps: any = {
    fontSize: '16px',
    padding: '1rem 0.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
  // To make mainText column responsive or to fit into the remaining space
  let widthMainText = 0
  properties.forEach((property, index) => {
    const { size = 'sm' } = property
    if (index !== 0) {
      widthMainText = widthMainText + columnSize[size]
    }
  })
  actions?.forEach(() => {
    widthMainText = widthMainText + 100
  })

  return (
    <Tbody>
      {models.map((model, index) => {
        return (
          <Tr key={index} borderTop={`1px solid ${COLORS.shades_neutral_300}`}>
            {properties.map((property, index) => {
              const value = property.variant === 'weight' && currentSelectedunit === 'oz' ? convertDisplayUnits(model[property.name], 'g', currentSelectedunit):
              model[property.name]
              const { size = 'sm' } = property
              const width = columnSize[size] ?? size
              // To make mainText column responsive or to fit into the remaining space
              const widthTd = `calc(100% - ${widthMainText}px)`

              return (
                <Td overflowX="hidden" key={property.name} {...thProps} width={property.mainText ? widthTd : width}>
                  <Tooltip label={model[property.name]}>
                    <Box>
                      {property.variant === 'text' && (
                        <Text overflow="hidden" textOverflow="ellipsis" variant="callout" width={width}>
                          {model[property.name]}
                        </Text>
                      )}
                    </Box>
                  </Tooltip>
                  {property.variant === 'money' && (
                    <Text width={width} overflow="hidden" textOverflow="ellipsis" variant="callout">
                      ${formatDollars(model[property.name])}
                    </Text>
                  )}
                  {property.variant === 'percentage' && (
                    <Text width={width} overflow="hidden" textOverflow="ellipsis" variant="callout">
                      {model[property.name]}%
                    </Text>
                  )}
                  {property.variant === 'weight' && (
                    <Text width={width} overflow="hidden" textOverflow="ellipsis" variant="callout">
                      {value}{' '}{pluralizeNameIfRequired(currentSelectedunit, value)}
                    </Text>
                  )}
                </Td>
              )
            })}
            {actions &&
              actions?.map((action, actionIndex) => {
                if (action.variant === 'button') {
                  const url = model[urlProperty] ? action.buildUrl(model[urlProperty]) : ''
                  return (
                    <Td key={actionIndex} textAlign="end" padding="0px" width="100px">
                      <Link to={url}>
                        <Button padding="0px" variant="round-ghost-lower">
                          {action.label}
                        </Button>
                      </Link>
                    </Td>
                  )
                }
                return <></>
              })}
          </Tr>
        )
      })}
    </Tbody>
  )
}

export const ReportTableFooters = (props: { footers: string[]; actions?: any[] }) => {
  const { footers, actions = [] } = props
  const thProps: any = {
    fontSize: '16px',
    padding: '1rem 0.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'black',
  }
  return (
    <Tfoot>
      <Tr fontWeight="bold">
        {footers.map((footer, key) => {
          return (
            <Td key={key} {...thProps}>
              {footer}
            </Td>
          )
        })}
        {/* make sure the table is the right length*/}
        {actions.map((action, key) => {
          return <Td key={key} {...thProps} />
        })}
      </Tr>
    </Tfoot>
  )
}
