import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { dispatchGetAdminDashboardAnalytics } from "../../data/admin-data/api";
import { useAppSelector } from "../../hooks";
import {
  reduceSetAdminAnalyticsColumnFilters,
  reduceSetAdminAnalyticsEndDate,
  reduceSetAdminAnalyticsInterval,
  reduceSetAdminAnalyticsStartDate,
  selectAdminAnalyticsColumnFilters,
  selectAdminAnalyticsEndDate,
  selectAdminAnalyticsForStartEndInterval,
  selectAdminAnalyticsInterval,
  selectAdminAnalyticsStartDate
} from "../../data/admin-data/slice";
import {
  calculateNumIntervals,
  convertAnalyticsToRows,
  filterSubscriptionAnalyticsColumns,
  filterSubscriptionAnalyticsRows
} from "../../data/admin-data/utils";
import { AdminSubscriptionAnalyticsRow, ALL_INTERVALS } from "../../data/admin-data/interfaces";
import { PageHeader } from "../../mini-lib/page-header/PageHeader";
import { DateInput } from "../../mini-lib/dates-and-times/DateInput";
import { UseViewSize } from "../../core/UseViewSize";
import { selectLoadingState } from "../../core/loading/slice";
import { DropdownFilter, DropdownOption } from "../../mini-lib/filters/DropdownFilter";
import { toast } from "react-toastify";
import { COLORS } from "../../mini-lib/theme/colors";
import { Gap } from "../../mini-lib/gap/Gap";
import { buildCsv } from "../../mini-lib/csv/buildCsv";
import { ADMIN_ANALYTICS_LOADING, ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_DATA } from "../../data/admin-data/constants";

export const AdminSubscriptionsAnalytics = () => {
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()
  const startDate = useAppSelector(selectAdminAnalyticsStartDate)
  const endDate = useAppSelector(selectAdminAnalyticsEndDate)
  const interval = useAppSelector(selectAdminAnalyticsInterval)
  const analytics = useAppSelector(state => selectAdminAnalyticsForStartEndInterval(state, startDate, endDate, interval))
  const loadingName = ADMIN_ANALYTICS_LOADING
  const isLoadingMetrics = useAppSelector(state => selectLoadingState(state, loadingName))
  useEffect(() => {
    dispatch(dispatchGetAdminDashboardAnalytics({ loadingName, token: user.token, startDate, endDate, interval }))
    // eslint-disable-next-line
  }, [dispatch, user.token, startDate.toISOString, endDate.toISOString, interval])
  const rows = analytics ? convertAnalyticsToRows(analytics) : null
  return (
    <Box maxW="1200px" margin="auto">
      <PageHeader title="Admin Analytics Dashboard" breadcrumbs={[]}/>
      <Box h="48px"/>
      <AdminSubscriptionAnalyticsFilters rows={rows}/>
      <Box h="24px"/>
      <Gap/>
      {rows && <AdminSubscriptionAnalyticsTable rows={rows}/>}
      {isLoadingMetrics && <LoadingMetrics/>}

    </Box>
  )
}
export const LoadingMetrics = () => {
  const [time, setTime] = useState(0)
  const textArray = [
    'processing request, this might take 30 seconds or so',
    'querying for active salons',
    'finding for new subscriptions',
    'looking for trial conversions',
    'checking for trial churn',
    'searching for subscription churn',
    'looking up salons with sessions',
  ]
  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(( prevTime ) => ( prevTime + 1 ) % textArray.length)
    }, 2000)
    return () => clearTimeout(timer)
  }, [textArray.length, time, setTime])


  return (
    <>
      <Gap s='48px'/>
      <Flex align='center' justify='center' gridGap='12px' color={COLORS.midnight_500}>
        <Spinner/>
        {textArray[time]}
      </Flex>
    </>
  )
}

export const AdminSubscriptionAnalyticsFilters = ( props: { rows: AdminSubscriptionAnalyticsRow[] | null } ) => {
  const { rows } = props
  const { isMobile } = UseViewSize()
  const dispatch = useDispatch()
  const startDate = useAppSelector(selectAdminAnalyticsStartDate)
  const endDate = useAppSelector(selectAdminAnalyticsEndDate)
  const interval = useAppSelector(selectAdminAnalyticsInterval)
  const columnFilters = useAppSelector(selectAdminAnalyticsColumnFilters)
  const [hasNewData, setHasNewData] = useState(false)
  const [localStartDate, setLocalStartDate] = useState(startDate)
  const [localEndDate, setLocalEndDate] = useState(endDate)
  const [localInterval, setLocalInterval] = useState(interval)

  const { user } = UseBaseApiParams()
  const loadingName = ADMIN_ANALYTICS_LOADING
  const isLoadingMetrics = useAppSelector(state => selectLoadingState(state, loadingName))
  const onCsvExport = () => {
    const filteredColumns = filterSubscriptionAnalyticsColumns(ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_DATA, columnFilters)
    const filteredColumnHeaders = filteredColumns.map(h => h.header)
    const filteredColumnKeys = filteredColumns.map(h => h.key)
    const filteredRows = rows ? filterSubscriptionAnalyticsRows(rows, filteredColumnKeys) : []
    buildCsv('admin-metrics', filteredColumnHeaders, filteredRows)
  }
  const columnOptions: DropdownOption[] = [
    { label: 'all', value: '' },
    { label: 'team', value: 'team' },
    { label: 'solo', value: 'solo' },
    { label: 'churn', value: 'churn' },
    { label: 'monthly', value: 'monthly' },
    { label: 'yearly', value: 'yearly' },
  ]
  return (
    <Box>
      <Flex align="center" justify="flex-end" gridGap="12px">
        <Button variant='round-outline' colorScheme='brand.midnight' onClick={onCsvExport}>
          {isLoadingMetrics ? <Spinner/> : 'Export CSV'}
        </Button>
        <Button
          variant={hasNewData ? 'round' : 'round-outline'}
          colorScheme='brand.midnight'
          onClick={() => {
            const numIntervals = calculateNumIntervals(localStartDate, localEndDate, localInterval)
            // prevent from querying for too much data eg data for every day in the entire year
            if (numIntervals > 35) {
              toast.error(`this will query too much data you have ${numIntervals} intervals selected please make it less than 35 intervals!`)
            } else {
              dispatch(reduceSetAdminAnalyticsInterval(localInterval))
              dispatch(reduceSetAdminAnalyticsStartDate(localStartDate))
              dispatch(reduceSetAdminAnalyticsEndDate(localEndDate))
              dispatch(dispatchGetAdminDashboardAnalytics({
                loadingName,
                token: user.token,
                startDate: localStartDate,
                endDate: localEndDate,
                interval
              }))
              setHasNewData(false)
            }
          }}>
          {isLoadingMetrics ? <Spinner/> : 'Get New Analytics'}
        </Button>
      </Flex>
      <Gap/>
      <Flex align="center" justify="flex-end" gridGap="12px">
        <DropdownFilter
          theme='midnight'
          placeholder={'all columns'}
          value={columnFilters.search}
          options={columnOptions}
          onChange={( option ) => {
            dispatch(reduceSetAdminAnalyticsColumnFilters({ search: option.value }))
          }}
        />
        <DropdownFilter
          isClearable={false}
          theme=''
          placeholder='Interval'
          value={localInterval}
          options={ALL_INTERVALS.map(( interval ) => ( { label: interval, value: interval } ))}
          onChange={( option ) => {
            setLocalInterval(option.value)
          }}
        />
        <DateInput
          placeholder='Date'
          w={isMobile ? '50%' : '140px'}
          value={localStartDate}
          onChange={( date ) => {
            setLocalStartDate(date)
            setHasNewData(true)
          }}
        />
        <DateInput
          placeholder='Date'
          w={isMobile ? '50%' : '140px'}
          value={localEndDate}
          onChange={( date ) => {
            setLocalEndDate(date)
            setHasNewData(true)
          }}
        />
      </Flex>
    </Box>
  )
}

export const AdminSubscriptionAnalyticsTable = ( props: { rows: AdminSubscriptionAnalyticsRow[] } ) => {
  const { rows } = props
  const columnFilters = useAppSelector(selectAdminAnalyticsColumnFilters)
  const filteredColumns = filterSubscriptionAnalyticsColumns(ADMIN_SUBSCRIPTION_ANALYTICS_COLUMN_DATA, columnFilters)
  return (
    <TableContainer>
      <Table variant='simple'>
        <Thead>
          <Tr>
            {filteredColumns.map(meta => {
              return (
                <Th key={meta.header} fontSize='12px'><Tooltip label={meta.description}>{meta.header}</Tooltip></Th> )
            })}
          </Tr>
        </Thead>
        <Tbody>
          {rows.reverse().map(row => {
            return (

              <Tr key={row.date_range}>
                {/* get the data that matches up with the header */}
                {filteredColumns.map(meta => {
                  return ( <Td key={meta.key} fontSize='12px'>{row[meta.key]}</Td> )
                })}
              </Tr>
            )
          })}

        </Tbody>
        <Tfoot>
        </Tfoot>
      </Table>
    </TableContainer>
  )
}
