import React, { useEffect, useState } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { InputError } from './InputError'
import { COLORS } from '../theme/colors'
import IntlTelInput from 'react-intl-tel-input'
import { uniqueId } from "lodash";
import { ThemeType } from "../../theme";
import { MaterialLabel } from './MaterialHelpers'

export const PhoneInput = ( props: {
  variant?: 'material' | 'basic'
  theme?: ThemeType
  label?: string
  isDisabled?: boolean
  w?: string

  value: string,
  onBlur?: ( optionValue: string ) => void
  onChange: ( optionValue: string ) => void
  onError: ( errorText: string ) => void
  errorText?: string
  allowFocus?: boolean
} ) => {
  const {
    w = '100%',
    variant = 'basic'
  } = props;
  return (
    <Box w={w}>
      {variant === 'basic' && <BasicPhoneInput {...props} />}
      {variant === 'material' && <MaterialPhoneInput {...props}/>}
    </Box>
  )
}

export const BasicPhoneInput = ( props: {
  variant?: 'material' | 'basic'
  label?: string
  isDisabled?: boolean

  value: string,
  onBlur?: ( optionValue: string ) => void
  onChange: ( optionValue: string ) => void
  onError: ( errorText: string ) => void
  errorText?: string
  theme?: ThemeType
} ) => {
  const {
    label,
    value,
    onChange,
    errorText = '',
    onError,
    isDisabled,
    theme = 'basic',
  } = props;
  const validateField = ( fullNumber: string, isValid: boolean ): void => {
    if (isValid) {
      onError('')
    } else {
      onError('Please enter a valid phone number')
    }
  }
  return (
    <>
      {label && (
        <Text textAlign="left" pb="6px" color={isDisabled ? 'shades.neutral.400' : ''}>
          {label}
        </Text>
      )}

      <IntlTelInput
        value={value}
        style={{ width: '100%' }}
        autoComplete={"new-number"}
        telInputProps={{ style: telStyles(!!errorText, false, false, 'rounded', theme) }}
        onPhoneNumberBlur={( isValid, value, selectedCountryData, fullNumber ) => validateField(fullNumber, isValid)}
        onPhoneNumberChange={( isValid, value, selectedCountryData, fullNumber ) => {
          onChange(value)
        }}
        containerClassName="intl-tel-input"
        inputClassName="form-control"
      />

      <InputError showError={!!errorText} errorText={errorText}/>
    </>
  )
}

export const MaterialPhoneInput = ( props: {
  variant?: 'material' | 'basic'
  border?: 'normal' | 'rounded'
  theme?: ThemeType
  label?: string
  isDisabled?: boolean
  value: string,
  onBlur?: ( optionValue: string ) => void
  onChange: ( optionValue: string ) => void
  onError: ( errorText: string ) => void
  errorText?: string
  allowFocus?: boolean,
} ) => {
  const {
    label,
    value,
    onChange,
    onBlur,
    errorText = '',
    onError,
    isDisabled = false,
    theme = 'basic',
    border = 'rounded',
    allowFocus = false
  } = props;
  const validateField = ( fullNumber: string, isValid: boolean ): void => {
    if (isValid) {
      onError('')
    } else {
      onError('Please enter a valid phone number')
    }
  }
  const [isHovered, setIsHovered] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [hasContent, setHasContent] = useState(value !== '')

  const elementId = uniqueId('formfield-')
  useEffect(() => {
    setHasContent(value !== '')
  }, [value])

  const handleBlur = ( isValid, value, selectedCountryData, fullNumber ) => {
    setIsFocused(false)
    validateField(fullNumber, isValid)
    onBlur && onBlur(value)
  }
  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleChange = ( isValid, value, selectedCountryData, fullNumber ) => {
    onChange(value)
  }
  const themeColor = theme === 'basic' ? 'black' : COLORS[`${theme}_500`]
  return (
    <Box position='relative'>
      {/*
      note: if we ever want to add the empty state interaction we need 
      left={isFocused || hasContent ? '12px' : '40px'}
      */}
      {label && (
        <MaterialLabel
          leftPadding={isFocused || hasContent ? '12px' : '40px'}
          label={label}
          elementId={elementId}
          isFocused={isFocused}
          isDisabled={isDisabled}
          hasContent={hasContent}
          themeColor={themeColor}
          allowFocus={allowFocus}
        />
      )}

      <IntlTelInput
        autoPlaceholder={false}
        placeholder=''
        value={value}
        style={{ width: '100%' }}
        telInputProps={{
          onMouseEnter: () => setIsHovered(true),
          onMouseLeave: () => setIsHovered(false),
          style: telStyles(!!errorText, isHovered, isFocused, border, theme)
        }}
        onPhoneNumberFocus={handleFocus}
        onPhoneNumberBlur={handleBlur}
        onPhoneNumberChange={handleChange}
        containerClassName="intl-tel-input"
        inputClassName="form-control"
        autoComplete={allowFocus ? "new-password" : undefined}
      />

      <InputError showError={!!errorText} errorText={errorText}/>
    </Box>
  )
}

const telStyles = ( hasError: boolean, isHovered: boolean, isFocused: boolean, border: 'rounded' | 'normal', theme: ThemeType ): any => {
  const borderThemeColor = theme === 'basic' ? COLORS.shades_neutral_400 : COLORS[`${theme}_500`]

  return {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: border === 'rounded' ? '50px' : '10px',
    border: hasError
      ? `1px solid ${COLORS.danger}`
      : isFocused
        ? `2px solid ${borderThemeColor}`
        : isHovered
          ? `1px solid #CBD5E0`
          : `1px solid ${COLORS.shades_neutral_300}`,
    padding: '8px 12px 8px 44px',
  }
}
