export const MONTHS = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
}

// start is inclusive, end is exclusive
// eg given start: nov 23, end: nov 28
// nov 23 -> true, nov 28 -> false
export const isDateInRange = (params: { date: Date; start: Date; end: Date }): boolean => {
  const time = params.date.getTime()
  return time >= params.start.getTime() && time <= params.end.getTime()
}

// start is inclusive, end is inclusive
// eg given start: nov 23, end: nov 28
// nov 23 -> true, nov 28 -> true, nov 22 -> false, nov 29 -> false
export const isDateInRangeInclusive = (params: { date: Date; start: Date; end: Date }): boolean => {
  const normalizedDate = params.date.setHours(0, 0, 0, 0)
  return normalizedDate >= params.start.setHours(0, 0, 0, 0) && normalizedDate <= params.end.setHours(0, 0, 0, 0)
}
