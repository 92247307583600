import {
  Button,
  Flex,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { UseViewSize } from '../../../core/UseViewSize'

export const DisabledPopover = (props: {
  show: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children: ReactNode
  placement?: PlacementWithLogical
  description: null | string | ReactNode
}) => {
  const { show, isOpen, setIsOpen, children, placement = 'top', description } = props
  const { isMobile } = UseViewSize()
  const normalizedDescription = !!description
    ? description
    : 'Please complete previous steps before trying to do this step!'
  return (
    <>
      {show && (
        <Popover
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={() => {}}
          placement={isMobile ? 'bottom' : placement}
          closeOnBlur={false}
        >
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent bg="brand.lavender.100">
            <PopoverArrow bg="brand.lavender.100" />
            <PopoverBody>{normalizedDescription}</PopoverBody>
            <PopoverFooter>
              <Flex justify="center">
                <Button bg="brand.lavender.500" color="white" onClick={() => setIsOpen(false)}>
                  Got It
                </Button>
              </Flex>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      )}
      {!show && children}
    </>
  )
}
