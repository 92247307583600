import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { PageLayout } from "../../mini-lib/layouts/PageLayout";
import { PageHeader } from "../../mini-lib/page-header/PageHeader";
import React, {useEffect, useState} from "react";
import {UseBuildSignupQueryParams, UseCanSeeScales, UseInitializeSignupState} from "../signup/v1/signupHooks";
import {
  Box,
  Button,
  Divider,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text
} from "@chakra-ui/react";
import { UseViewSize } from "../../core/UseViewSize";
import { Gap } from "../../mini-lib/gap/Gap";
import { BLACK_MINI_SCALE } from "../../data/signup/constants";
import {
  selectScaleQuantities,
  selectScaleQuantitiesInStockForCountryById
} from "../../data/signup/slice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks";
import { dispatchListScaleQuantitiesInStock } from "../../data/signup/api";
import { Loading } from "../../mini-lib/loading/Loading";
import { EmptyBox } from "../../mini-lib/empty/EmptyBox";
import { BlackMiniScaleAvailable } from "../../mini-lib/flags/PermanentFlags";
import { ReleaseSpecialPricing } from "../../mini-lib/flags/Release";
import { getScalePrice } from "../../data/signup/utils";
import {MaterialIcon} from "../../mini-lib/icons/MaterialIcon";
import {generatePath, Link} from 'react-router-dom'
import {ROUTES} from "../../appRoutes";

export const CheckoutPaidScalesPage = () => {
  const { isMobile } = UseViewSize()
  const breadcrumbs = []
  const dispatch = useDispatch()
  const {user} = UseBaseApiParams()
  UseInitializeSignupState()

  useEffect(() => {
      dispatch(
        dispatchListScaleQuantitiesInStock({ token: user.token}),
      )
  }, [dispatch, user.token])

  const scaleQuantitiesInStockById = useAppSelector(selectScaleQuantitiesInStockForCountryById)
  const blackMiniScalesAvailable = BlackMiniScaleAvailable()
  const hasBlackMiniScales = scaleQuantitiesInStockById ? blackMiniScalesAvailable && scaleQuantitiesInStockById[BLACK_MINI_SCALE.id]?.quantity > 0 : null
  const canSeeScales = UseCanSeeScales()
  return (
    <>
      <PageLayout
        variant="full"
        header={<PageHeader title='Do you want to add a Bluetooth scale?' breadcrumbs={breadcrumbs}/>}
        content={
          <Flex justify='space-around' direction='column' align='center'>

            <Flex justify={isMobile ? 'center' : 'space-between'} gridGap='24px' w={isMobile ? '100%' : ''} wrap={isMobile ? 'wrap' : 'nowrap'}>
              {!canSeeScales && <EmptyBox title="No Scales Available" content="Scales are unavailable in your country" />}
              {!scaleQuantitiesInStockById && <Loading/>}
              {scaleQuantitiesInStockById && hasBlackMiniScales && <MiniScaleOption />}
            </Flex>
          </Flex>
        }
      />
    </>
  )
}
export const MiniScaleOption = () => {

  const {salonId} = UseBaseApiParams()
  const scaleQuantities = useAppSelector(selectScaleQuantities)
  const scale = BLACK_MINI_SCALE
  const {isMobile} = UseViewSize()

  const initialQuantity = scaleQuantities[scale.id] || 0
  const [localQuantity, setLocalQuantity] = useState(initialQuantity)
  const scaleChange = (updatedQuantity: string) => {
    const quantityNumber = parseInt(updatedQuantity)
    if (!isNaN(quantityNumber)) {
      setLocalQuantity(quantityNumber)
    }
  }

  const releaseSpecialPricing = ReleaseSpecialPricing()
  const price = getScalePrice(releaseSpecialPricing)
  const [showProductDescription, setShowProductDescription] = useState(false)
  return (
    <Flex justify="space-between" mt="24px" wrap='wrap' w={isMobile ? '100%' : '1000px'}>
      {/* left column */}
      <Flex w={isMobile ? '100%' : '50%'} justify='center'>
        <ScaleImg heightPx={isMobile ? '40px' : '350px'} widthPx={isMobile ? '40px' : '350px'} imgUrl={scale.imgUrl}/>
      </Flex>

      {/* right column */}
      <Box w={isMobile ? '100%' : '50%'}>
        <Box>
          <Text variant='callout-desktop-regular'>SalonScale</Text>
          <Flex justify='space-between'>
            <Text variant='title2'>BLUETOOTH {scale.label} SCALE</Text>
            <Text variant='title2'>$60</Text>
          </Flex>
          <Gap/>
          <Text variant='callout-desktop-regular'>Quantity</Text>
          <NumberInput size="lg" maxW={32} value={localQuantity} min={0} onChange={scaleChange}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <Gap/>
        <Divider/>
        <Gap/>
        <Flex align="center" justify='space-between'>
          <Text variant='title3'>Order subtotal</Text>
          <Text variant="title3">${localQuantity * price}</Text>
        </Flex>
        <Gap/>
        <Divider/>
        <Gap/>
        <Link to={{ pathname: generatePath(ROUTES.checkoutCart, { salonId }), search: UseBuildSignupQueryParams({scaleQuantities: {'blackmini': localQuantity}}) }}>
          <Button variant='round-outline' w='100%' colorScheme='brand.midnight'>
            Add To Order
          </Button>
        </Link>
        <Gap/>
        <Link to={{ pathname: generatePath(ROUTES.checkoutCart, { salonId }), search: UseBuildSignupQueryParams() }}>

          <Button variant='round' w='100%' colorScheme='brand.midnight'>
              Skip & Go to Review Order
          </Button>
        </Link>
        <Divider/>
        <Gap/>
        <Flex justify='space-between' align='center' cursor='pointer' onClick={() => setShowProductDescription(!showProductDescription)}>
          <Text  fontWeight='bold'>Product Description</Text>
          {showProductDescription ? <MaterialIcon name='keyboard_arrow_up'/> : <MaterialIcon name='keyboard_arrow_down'/>}
        </Flex>
          {showProductDescription && (
              <Box p='12px'>
                <Box>• Power supply - 2*AAAM</Box>
                <Box>• Graduation - 1gM</Box>
                <Box>• Scale Size - 200*140*20.5mmM</Box>
                <Box>• Display - LCDM</Box>
                <Box>• Connection - BluetoothM</Box>
                <Box>• Updated 2.0 sensorM</Box>
              </Box>
          )}
      </Box>
    </Flex>
  )
}

export const ScaleImg = (props: { imgUrl: string; heightPx?: string; widthPx?: string }) => {
  const { isMobile } = UseViewSize()
  const { heightPx = isMobile ? '350px' : '500px', widthPx = '100%', imgUrl } = props
  return (
    <Box
      m="12px"
      h={heightPx}
      w={widthPx}
      maxW={'500px'}
      borderRadius="15px"
      backgroundSize="contain"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundImage={`url(${imgUrl})`}
    />
  )
}