import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react'
import {
  reduceInventoryAddStockModalVisibility,
  selectInventoryAddStockModalVisibility,
} from '../../../data/inventory/slice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { COLORS } from '../../../mini-lib/theme/colors'
import React, { useState } from 'react'
import { StylistAutocompleteDeprecated } from '../../stylists/StylistAutocompleteDeprecated'
import { SalonMember } from '../../../data/user/interfaces'
import { OrderLara } from '../../../data/orders/interfaces'
import { OrderAutocomplete } from '../../orders/OrderAutocomplete'
import { dispatchCreateInventoryCount } from '../../../data/inventory/api'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { APIInventoryCountCreate } from '../../../data/inventory/interfaces'
import { selectMemberListLara } from '../../../data/user/slice'
import { filterSalonMembersLara } from '../../../data/user/utils'
import { dispatchListSalonMembersLara } from '../../../data/user/api'
import { USER_PERMISSIONS } from '../../../data/user/constants'
import { TextInput } from "../../../mini-lib";

export const InventoryAddStockModal = () => {
  const dispatch = useDispatch()

  const inventoryAddStockModalVisible = useAppSelector(selectInventoryAddStockModalVisibility)
  const [type, setType] = useState<'manual' | 'order'>('manual')
  const [title, setTitle] = useState('')
  const [salonMember, setSelectedSalonMember] = useState<SalonMember | null>(null)
  const [order, setSelectedOrder] = useState<OrderLara | null>(null)
  const { user, salonId } = UseBaseApiParams()
  const createAddStock = () => {
    if (salonMember && type && title) {
      const model: APIInventoryCountCreate = {
        assigned_user_id: salonMember?.userId,
        count_type: type,
        title,
      }
      if (type === 'order') {
        model['order_id'] = order?.id
      }
      dispatch(dispatchCreateInventoryCount({ token: user.token, userId: user.userId, salonId, model }))
      dispatch(reduceInventoryAddStockModalVisibility(false))
    }
  }
  return (
    <Modal
      onClose={() => dispatch(reduceInventoryAddStockModalVisibility(false))}
      isOpen={inventoryAddStockModalVisible}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent m="12px">
        <ModalBody>
          <Text variant="largetitle" textTransform="uppercase">
            Add Stock
          </Text>

          <Box h="24px" />
          <Text variant="caption2" color={COLORS.text_secondary}>
            Add stock from
          </Text>

          <Box h="12px" />
          <RadioGroup
            value={type}
            onChange={(selectedValue: 'manual' | 'order') => {
              setType(selectedValue)
            }}
          >
            <Radio colorScheme="brand.midnight" value="manual">
              Manual Entry
            </Radio>
            <Radio ml="24px" colorScheme="brand.midnight" value="order">
              Existing Order
            </Radio>
          </RadioGroup>
          <Box h="24px" />
          {/*<InputGroup*/}
          {/*  border="1px solid"*/}
          {/*  borderColor="shades.neutral.200"*/}
          {/*  borderRadius="100px"*/}
          {/*  width="100%"*/}
          {/*  alignItems="center"*/}
          {/*>*/}
          {/*  <Input*/}
          {/*    borderRadius="100px"*/}
          {/*    value={title}*/}
          {/*    placeholder={'Title'}*/}
          {/*    onChange={(evt) => setTitle(evt.target.value)}*/}
          {/*  />*/}
          {/*</InputGroup>*/}
          <TextInput
            variant="material"
            border="rounded"
            label='Title'
            placeholder="Search anything"
            value={title}
            onChange={v => setTitle(v)}
            onClear={() => setTitle('')}
            allowFocus={true}
          />
          {type === 'order' && (
            <>
              <Box h="24px" />
              <OrderAutocomplete width="100%" isClearable={false} selected={order} onSelect={(order) => setSelectedOrder(order)} />
            </>
          )}
          <Box h="24px" />
          <StylistAutocompleteDeprecated
            width="100%"
            label="Add Contributor"
            isClearable={false}
            onSelect={(salonMember) => setSelectedSalonMember(salonMember)}
            // TODO - remove salonMemberSelector, dispatchForSalonMembers, filterForSalonMembers once users api completely replaced
            salonMemberSelector={selectMemberListLara}
            dispatchForSalonMembers={() => dispatchListSalonMembersLara(user.token, salonId, USER_PERMISSIONS.inventoryAddStock)}
            filterForSalonMembers ={filterSalonMembersLara}
            reloadOnEveryMount={true}
          />
          <Flex justify="flex-end" gridGap="12px" p="36px 0 24px 0">
            <Button
              onClick={() => dispatch(reduceInventoryAddStockModalVisibility(false))}
              colorScheme="brand.midnight"
              variant="round-outline"
            >
              Cancel
            </Button>
            <Button onClick={createAddStock} colorScheme="brand.midnight" variant="round">
              Create
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
