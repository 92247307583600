import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GlobalRoles } from './interfaces'
import { RootState } from '../../store'

interface GlobalRolesState {
  roles: GlobalRoles[] | null
}

const initialState: GlobalRolesState = {
  roles : null
}

// reducer
//
export const NotificationSlice = createSlice({
  name: 'globalRoles',
  initialState,
  reducers: {

    reduceListGlobalRoles: (state, action: PayloadAction<GlobalRoles[]>) => {
      state.roles = action.payload
    },
  },
})

export const {
  reduceListGlobalRoles
} = NotificationSlice.actions

// selector
//
export const selectGolbalRolesList = (state: RootState): GlobalRoles[] | null =>  state.globalRoles.roles

export default NotificationSlice.reducer