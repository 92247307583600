
// session labor apis

import {
  APICreateOrUpdateSessionLaborItemLara, APISessionLaborItemLara, SessionLaborItemLara,
} from "./../interfaces";
import {buildLaraConfig} from "../../../mini-lib/lara/lara-utils";
import {GetServerBaseUrl} from "../../../env";
import axios from "axios";
import {Dispatch} from "@reduxjs/toolkit";
import {reduceSetLoadingState} from "../../../core/loading/slice";
import {mapAPISessionLaborItemsLaraToSessionLaborItemsLara} from "./mappers";
import {reduceActiveSessionLaborItems, reduceDeleteActiveSessionLaborItems} from "../slice";

export const apiListSessionLaborItemsLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionLaborItemLara[]> => {
  const {token, salonId, sessionId} = params
  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/labor-items`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionLaborItemLara[]} }) => {
      return mapAPISessionLaborItemsLaraToSessionLaborItemsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateOrUpdateSessionLaborItemsLara = (params: {
  token: string
  salonId: number
  sessionId: number
  models: APICreateOrUpdateSessionLaborItemLara[]
}): Promise<SessionLaborItemLara[]> => {
  const {token, salonId, sessionId, models} = params

  const config = buildLaraConfig({token})
  const body = models
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/labor-items`

  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionLaborItemLara[]} }) => {
      return mapAPISessionLaborItemsLaraToSessionLaborItemsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteSessionLaborItemLara = (params: {
  token: string
  salonId: number
  sessionId: number
  sessionLaborItemId: number
}): Promise<void> => {
  const {token, salonId, sessionId, sessionLaborItemId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/labor-items/${sessionLaborItemId}`

  return axios
    .delete(url, config)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}


export const dispatchListSessionLaborItemsLara = (params: {
  token: string;
  salonId: number,
  sessionId: number,
  loadingName?: string
}) => {
  const {loadingName = 'loading-session-labor-items'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionLaborItemsLara(params).then((resp) => {
      dispatch(reduceActiveSessionLaborItems(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchCreateOrUpdateSessionLaborItemsLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  models: APICreateOrUpdateSessionLaborItemLara[]
  loadingName?: string
}) => {
  const {loadingName = 'creating-session-labor-items'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiCreateOrUpdateSessionLaborItemsLara(params).then((resp) => {
      dispatch(reduceActiveSessionLaborItems(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchDeleteSessionLaborItemLara = ( params: {
  token: string;
  salonId: number;
  sessionId: number,
  sessionLaborItemId: number
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteSessionLaborItemLara(params)
      .then(( resp ) => {
        dispatch(reduceDeleteActiveSessionLaborItems(params.sessionLaborItemId))
      })
      .catch(( error ) => {
        throw error
      })
  }
}
