import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useAppSelector } from '../../../hooks'
import { generatePath, Link } from 'react-router-dom'
import {
  selectInfoOnProductsWithPricing,
  selectInventoryInfoSheetVisibility,
  selectProductFilters,
  selectSortedFilteredProductsByCategoryThenByLineSize,
} from '../../../data/products/slice'

import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'

import { LinesByCategoryAccordion } from './LinesByCategoryAccordion'
import { ProductDetailsSheet } from './ProductDetailsSheet'
import { Loading } from '../../../mini-lib/loading/Loading'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { GlobalMarkupModal } from './GlobalMarkupModal'
import { keys } from 'lodash'
import { HelpStepper, Step } from '../../start-guide/common-components/HelpStepper'
import { HelpStepCompleteDrawer } from '../../start-guide/common-components/HelpStepCompleteDrawer'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { dispatchUpdateUserChecklistItem } from '../../../data/start-guide/api'
import {
  reduceSetCurrentStepCode,
  reduceSetCurrentSubStepIndex,
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectStepCompleteDrawerState,
  selectStepsCompleted,
} from '../../../data/start-guide/slice'
import { InventoryInfoSheet } from '../../inventory/InventoryInfoSheet'
import { ProductFilters } from '../filters/ProductFilters'
import { HelpModal } from '../../start-guide/common-components/HelpModal'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import {
  ReleaseLaraColors,
  ReleasePageHelp,
  ReleaseStartGuideV2
} from '../../../mini-lib/flags/Release'
import { HelpStepperV2 } from "../../start-guide-v2/HelpStepperV2";
import { UseResetStartGuideState } from "../../start-guide/common-components/UseResetStartGuideState";
import { COLORS } from "../../../mini-lib/theme/colors";
import { EmptyFilters } from '../../../mini-lib'
import { dispatchListProducts } from "../../../data/products/api";
import { ROUTES } from "../../../appRoutes";
import { UseInitializeProductFiltersFromQueryParams } from "../filters/hooks";
import { HelpDrawer } from "../../start-guide/common-components/HelpDrawer";
import { VIDEO_MAP } from "../../../mini-lib/video/constants";
import {selectLoadingState} from "../../../core/loading/slice";
import {RESETTING_PRODUCTS} from "../../../data/products/constants";
import {Gap} from "../../../mini-lib/gap/Gap";
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { UseIsSuperUser } from "../../../core/UseIsSuperUser";
import { SuperUserColorsMenu } from "../../../mini-lib/super-user/SuperUserColorsMenu";
import { TrialPricingButton } from './TrialPricing'

export const LinesPage = ( props: { showBreadCrumbs?: boolean } ) => {
  const { showBreadCrumbs = true } = props
  const dispatch = useDispatch()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  UseResetStartGuideState()

  const releaseStartGuideV2 = ReleaseStartGuideV2()
  const isSuperUser = UseIsSuperUser()
  const releasePageHelp = ReleasePageHelp()


  const breadcrumbs = showBreadCrumbs ? [{
    label: 'Home',
    url: generatePath(ROUTES.home, { salonId })
  }, { label: 'Lines' }] : []
  const inventoryInfoSheetVisible = useAppSelector(selectInventoryInfoSheetVisibility)
  const filters = useAppSelector(selectProductFilters)
  const productsByCategoryThenByLineSize = useAppSelector(selectSortedFilteredProductsByCategoryThenByLineSize)

  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const resettingProducts = useAppSelector((state) => selectLoadingState(state, RESETTING_PRODUCTS))
  const productsLoaded = !!productsByCategoryThenByLineSize && !resettingProducts
  const infoOnProductsWithPricing = useAppSelector(selectInfoOnProductsWithPricing)
  const addPricingChecklistItem = useAppSelector(( state ) => selectChecklistItem(state, CHECKLIST_CODES.addPricing))

  UseInitializeProductFiltersFromQueryParams()

  const queryParams: any = UseQueryParams()
  const guideType = queryParams.get('guide')
  const isGuide = !!guideType
  const [showAddPricingHelpModal, setShowAddPricingHelpModal] = React.useState(guideType === CHECKLIST_CODES.addPricing)

  useEffect(() => {
  }, [dispatch, token, salonId])

  const releaseLaraColors = ReleaseLaraColors()
  useEffect(() => {
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, releaseLaraColors])

  // watch for when they hit 100% completed and then update the checklist item
  useEffect(() => {
    if (isGuide && !addPricingChecklistItem.completed && infoOnProductsWithPricing?.percentage === 100) {
      dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: CHECKLIST_CODES.addPricing }))
    }
  }, [dispatch, isGuide, infoOnProductsWithPricing, addPricingChecklistItem.completed, token])


  const helpSteps: Step[] = [
    {
      title: 'Select Your Lines',
      state: 'complete',
      link: generatePath(ROUTES.addLinesV2, { salonId }) + `?guide=${CHECKLIST_CODES.addLines}`
    },
    {
      title: 'Set Prices',
      state: 'current',
      link: generatePath(ROUTES.lines, { salonId }) + `?guide=${CHECKLIST_CODES.addLines}`,
    },
  ]
  const inventoryHelpSteps: Step[] = [
    {
      title: 'Set product targets',
      state: 'current',
      link: generatePath(ROUTES.lines, { salonId }) + `?guide=${CHECKLIST_CODES.inventorySetTargets}`,
    },
  ]
  const stepCodes = [CHECKLIST_CODES.addLines, CHECKLIST_CODES.addPricing]
  const stepsCompleted = useAppSelector(( state ) => selectStepsCompleted(state, stepCodes))
  const previousStepLink = generatePath(ROUTES.sgAddPricingIntro, { salonId })
  const nextStepLink = generatePath(ROUTES.sgIndex, { salonId }) + '?step=pricing'
  return (<>
    {releasePageHelp && (
      <HelpDrawer
        video={VIDEO_MAP.addLines}
        title={`Let’s take a look inside ColorLines`}
        content={`This is where the magic begins! Choose from over 250+ color lines to customize your digital backbar to match the products that you use everyday in your salon. Set them to your pricing or use our quick add pricing if you want to get playing around. Come back and fine tune your pricing later. You can add a global mark up to all your products for using SalonScale as a parts and labor tool to add the color cost on top the service price, or you can adjust mark up for individual products as well.
`}
      />
    )}
    <PageLayout
      variant="full"
      top={
        <>
          {releaseStartGuideV2 &&
              <>
                  <HelpStepperV2
                      title="Add Your Pricing"
                      titleIconName="products"
                      currentStepNumber={2}
                      stepsComplete={stepsCompleted || 0}
                      totalSteps={stepCodes.length}
                      previousStepLink={previousStepLink}
                      nextStepLink={nextStepLink}
                      guideType={CHECKLIST_CODES.addPricing}
                  />
                  <HelpStepperV2
                      title="Quick Start Pricing"
                      titleIconName="products"
                      currentStepNumber={2}
                      stepsComplete={stepsCompleted || 0}
                      totalSteps={stepCodes.length}
                      previousStepLink={previousStepLink}
                      nextStepLink={nextStepLink}
                      guideType={CHECKLIST_CODES.autoPricing}
                  />
              </>
          }
          <HelpStepper
            title="Add Color Lines to Your Back Bar"
            steps={helpSteps}
            guideType={CHECKLIST_CODES.addLines}
          />
          <HelpStepper
            title="Set Back Bar Targets"
            steps={inventoryHelpSteps}
            guideType={CHECKLIST_CODES.inventorySetTargets}
          />
        </>
      }
      header={
        <PageHeader
          showHelp={true}
          title="Color Lines"
          breadcrumbs={breadcrumbs}
          actions={
            <Flex gridGap="12px" wrap="wrap">
              {releaseStartGuideV2 && <TrialPricingButton/>}
              <GlobalMarkupModal/>
              <Link to={generatePath(ROUTES.addLinesV2, { salonId })}>
                <Button variant="round" colorScheme="brand.midnight">
                  Add Lines
                </Button>
              </Link>
              {isSuperUser && <SuperUserColorsMenu/>}
            </Flex>
          }
        />
      }
      content={
        <>
          <HelpModal guideType={CHECKLIST_CODES.inventorySetTargets} title="Let’s add your product targets!"/>
          {showAddPricingHelpModal && (
            <HelpModal
              guideType={CHECKLIST_CODES.addPricing}
              title="Let’s Determine Your Color Pricing!"
              closeButtons={
                <Flex gridGap="12px">
                  <Link to={generatePath(ROUTES.sgAddPricingIntro, { salonId })}>
                    <Button variant="round-outline" colorScheme="brand.midnight">
                      Go Back
                    </Button>
                  </Link>
                  <Button
                    variant="round"
                    colorScheme="brand.midnight"
                    onClick={() => setShowAddPricingHelpModal(false)}
                  >
                    Let's Go
                  </Button>
                </Flex>
              }
            />
          )}
          {guideType === CHECKLIST_CODES.addLines && (
            <HelpStepCompleteDrawer
              guideType={CHECKLIST_CODES.addLines}
              title="Way to go! Now you know how to set up your colors!"
              subtitle="Feel free to set up more prices while we are here. When you are ready, click below to go to the next step"
              actions={
                <Link to={generatePath(ROUTES.startGuide, { salonId })}>
                  <Button
                    variant="round"
                    colorScheme="brand.midnight"
                    onClick={() => {
                      dispatch(
                        dispatchUpdateUserChecklistItem({
                          token,
                          checklistItemCode: CHECKLIST_CODES.addLines,
                        }),
                      )
                      dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.addAddons))
                      dispatch(reduceSetStepCompleteDrawerState('hidden'))
                      dispatch(reduceSetCurrentSubStepIndex(0))
                    }}
                  >
                    Go to Next Step
                  </Button>
                </Link>
              }
            />
          )}
          {guideType === CHECKLIST_CODES.autoPricing && (
            <HelpStepCompleteDrawer
              guideType={CHECKLIST_CODES.autoPricing}
              title="Way to go! Product Costs have been added for 100% of your color lines."
              subtitle="You’re finished your set up!"
              actions={
                <Flex align="center" gridGap="12px">
                  {stepCompleteDrawerState !== 'button' && (
                    <Button
                      variant="round-outline"
                      colorScheme="brand.midnight"
                      onClick={() => {
                        dispatch(reduceSetStepCompleteDrawerState('button'))
                      }}
                    >
                      Continue Editing
                    </Button>
                  )}
                  <Link to={generatePath(ROUTES.sgIndex, { salonId }) + '?step=pricing&completed=pricing'}>
                    <Button
                      variant="round"
                      colorScheme="brand.midnight"
                      onClick={() => {
                        dispatch(reduceSetStepCompleteDrawerState('hidden'))
                        dispatch(reduceSetCurrentSubStepIndex(0))
                        dispatch(
                          dispatchUpdateUserChecklistItem({
                            token: token,
                            checklistItemCode: CHECKLIST_CODES.addPricing,
                          }),
                        )
                      }}
                    >
                      Finish Set Up
                    </Button>
                  </Link>
                </Flex>
              }
            />
          )}
          {guideType === CHECKLIST_CODES.addPricing && (
            <HelpStepCompleteDrawer
              guideType={CHECKLIST_CODES.addPricing}
              title={infoOnProductsWithPricing?.percentage === 100
                ? `Way To Go! You’ve Completed Setting Your Product Costs`
                : `Way to go! Costs have been added for ${infoOnProductsWithPricing?.numberCompleted}/${infoOnProductsWithPricing?.totalProducts} of your products.`}
              subtitle={infoOnProductsWithPricing?.percentage === 100
                ? "You’re finished your set up!"
                : "Make sure you add your pricing for 100% of your color lines. If you're unsure of a product's cost, you can press the 'auto add costs' button to fill in the rest of your prices"}
              actions={
                <Flex align="center" gridGap="12px">
                  {stepCompleteDrawerState !== 'button' && (
                    <Button
                      variant="round-outline"
                      colorScheme="brand.midnight"
                      onClick={() => {
                        dispatch(reduceSetStepCompleteDrawerState('button'))
                      }}
                    >
                      Continue Editing
                    </Button>
                  )}
                  {infoOnProductsWithPricing?.percentage === 100 ? (
                    <Link to={generatePath(ROUTES.sgIndex, { salonId }) + '?step=pricing&completed=pricing'}>
                      <Button
                        variant="round"
                        colorScheme="brand.midnight"
                        onClick={() => {
                          dispatch(reduceSetStepCompleteDrawerState('hidden'))
                          dispatch(reduceSetCurrentSubStepIndex(0))
                          dispatch(
                            dispatchUpdateUserChecklistItem({
                              token: token,
                              checklistItemCode: CHECKLIST_CODES.autoPricing,
                            }),
                          )
                        }}
                      >
                        Finish Set Up
                      </Button>
                    </Link>
                  ) : (
                    <Text color={COLORS.midnight_500}>
                      {infoOnProductsWithPricing?.numberCompleted}/{infoOnProductsWithPricing?.totalProducts} Completed
                    </Text>
                  )}
                </Flex>
              }
            />
          )}
          {guideType === CHECKLIST_CODES.inventorySetTargets && (
            <HelpStepCompleteDrawer
              guideType={CHECKLIST_CODES.inventorySetTargets}
              title="Way to go! You have your product targets set!"
              subtitle="Feel free to set up more targets while we are here. When you are ready, click below to go to the next step"
              actions={
                <Link to={generatePath(ROUTES.startGuide, { salonId })}>
                  <Button
                    variant="round"
                    colorScheme="brand.midnight"
                    onClick={() => {
                      dispatch(
                        dispatchUpdateUserChecklistItem({
                          token,
                          checklistItemCode: CHECKLIST_CODES.inventorySetTargets,
                        }),
                      )
                      dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.inventoryCompleteCount))
                      dispatch(reduceSetStepCompleteDrawerState('hidden'))
                      dispatch(reduceSetCurrentSubStepIndex(0))
                    }}
                  >
                    Go to Next Step
                  </Button>
                </Link>
              }
            />
          )}
          <Box h="24px"/>
          <ProductFilters/>

          <Box h="24px"/>
          {productsLoaded && keys(productsByCategoryThenByLineSize).length > 0 && (
            <LinesByCategoryAccordion productsByCategoryThenByLineSize={productsByCategoryThenByLineSize}/>
          )}
          {/* empty search state */}
          {productsLoaded &&
            keys(productsByCategoryThenByLineSize).length === 0 &&
            filters &&
            filters.searchText &&
            filters.searchText?.length > 0 && (
              <EmptyFilters
                theme='lavender'
                title='search result can’t be found'
                subtitle='We might have what you’re looking for in our color lines.'
                actions={
                  <Link to={generatePath(ROUTES.addLinesV2, { salonId }) + `?search=${filters.searchText} `}>
                    <Button variant="round-outline" colorScheme='brand.midnight'>
                      Search Color Lines
                    </Button>
                  </Link>
                }
              />
            )}
          {/* no products state */}
          {productsLoaded &&
            keys(productsByCategoryThenByLineSize).length === 0 &&
            ( !filters.searchText || filters.searchText?.length === 0 ) && (
              <EmptyBox
                title="NO COLOR LINES ADDED"
                content="Add your color lines to start using this feature in the SalonScale App!"
              >
                <Box h="24px"/>
                <Link to={generatePath(ROUTES.addLinesV2, { salonId })}>
                  <Button variant="round" bg="brand.midnight.500">
                    LET'S GO!
                  </Button>
                </Link>
              </EmptyBox>
            )}
          {!productsLoaded && <><Gap/><Loading/></>}
        </>
      }
    >
      {inventoryInfoSheetVisible && <InventoryInfoSheet/>}
      <ProductDetailsSheet/>
    </PageLayout>
  </>)
}
