import { Box, Flex, Text } from '@chakra-ui/react'
import Lottie from 'react-lottie-player'
import pageNotFoundLottie from '../../assets/404-page-not-found-lottie.json'
import { Link } from 'react-router-dom'
import { COLORS } from '../../mini-lib/theme/colors'

export const RouteNotFoundRedirectPage = () => {
  return (
    <>
      <Flex justify="center" align="center" direction="column" p="40px">
        <Text variant="title1" fontWeight="bold">
          the page you were looking for was not found
        </Text>
        <Box h="24px" />
        <Text>
          please contact your salon owner or support or{' '}
          <Link to="/">
            <Text as="span" color={COLORS.lavender_500}>
              click here to go back to the business center
            </Text>
          </Link>
        </Text>

        <Lottie loop animationData={pageNotFoundLottie} play style={{ maxWidth: '100%' }} />
      </Flex>
    </>
  )
}
