import { Box, Button, Flex, Tab, Tabs, Text } from '@chakra-ui/react'
import { values } from 'lodash'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { UseViewSize } from '../../../core/UseViewSize'
import {
  reduceSelectedDateRangeForLaborReports,
  selectLaborCSV,
  selectLaborCSVTitle,
  selectReportsDateRangeTypeForLaborReports,
} from '../../../data/labor-report/slice'
import { useAppSelector } from '../../../hooks'
import { DATE_RANGE_OPTION_MAP_WITH_CUSTOM, DateRangeType } from '../../../mini-lib/dates-and-times/constants'
import { CustomDateRangeModal } from '../../../mini-lib/dates-and-times/CustomDateRangeModal'
import { DropdownFilter } from '../../../mini-lib/filters/DropdownFilter'
import { CSVLink } from 'react-csv'
import { ROUTES } from "../../../appRoutes";

export const LaborReportsTabs = (props: { selectedTab: number }) => {
  const { selectedTab } = props
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const history = useHistory()
  const { salonId } = UseBaseApiParams()
  const selectedDateRange = useAppSelector(selectReportsDateRangeTypeForLaborReports)
  const csvData = useAppSelector(selectLaborCSV)
  const csvTitle = useAppSelector(selectLaborCSVTitle)

  const [isOpen, setIsOpen] = useState(false)
  const onDateRangeSelect = (option: DateRangeType) => {
    if (option === 'custom') {
      setIsOpen(true)
    } else {
      dispatch(reduceSelectedDateRangeForLaborReports(option))
      setIsOpen(false)
    }
  }

  const navigateToTab = (newIndex: number) => {
    if (newIndex === 0) {
      history.push(generatePath(ROUTES.laborLaborReports, { salonId }))
    }
    //   else if (newIndex === 1) {
    //     history.push(generatePath(ROUTES.laborStylistReports, { salonId }))
    //   } else if (newIndex === 2) {
    //     history.push(generatePath(ROUTES.laborClientReports, { salonId }))
    //   }
  }
  return (
    <Tabs index={selectedTab} onChange={(index) => navigateToTab(index)} colorScheme="brand.lavender">
      <Flex w="100%" direction={isMobile ? 'column-reverse' : 'row'} borderBottom="1px solid #BDBDBD">
        <Tab minW={isMobile ? '60px' : '158px'}>Labour Reports</Tab>
        {/* <Tab minW={isMobile ? '60px' : '200px'}>{isMobile ? 'Stylist' : 'Stylist Reports'}</Tab>        
          <Tab minW={isMobile ? '80px' : '200px'}>{isMobile ? 'Client' : 'Client Reports'}</Tab> */}

        <Flex align={'center'} justify={'flex-end'} padding="4px" w="100%">
          {!isMobile && <Text>Filter By:</Text>}
          <Box pl={'10px'}>
            <DropdownFilter
              theme='lavender'
              w="150px"
              placeholder="Date Range"
              value={selectedDateRange || null}
              options={values(DATE_RANGE_OPTION_MAP_WITH_CUSTOM)}
              onChange={(option: any) => onDateRangeSelect(option.value)}
            />
          </Box>
          {isOpen && <CustomDateRangeModal setIsOpen={setIsOpen} isOpen={isOpen} type="labor" />}
          <Box pl={'10px'} mr="24px">
            <Button variant="round" bg="brand.midnight.500">
              <CSVLink filename={csvTitle} data={csvData ? csvData : []}>
                EXPORT CSV
              </CSVLink>
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Tabs>
  )
}
