import { Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'
import { GetServerBaseUrl } from '../../env'
import { buildLaraConfig } from '../../mini-lib/lara/lara-utils'
import { reduceListGlobalRoles } from './slice'
import { GLOBAL_ROLES_LOADING_CONSTANT } from './constants'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { APIGlobalRoles, GlobalRoles } from './interfaces'
import { mapAPIGlobalRolesToGlobalRoles } from './mappers'


// --- user ---
// api
const apiListRoles = (
  token: string
): Promise<APIGlobalRoles[]> => {
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/admins/roles`
  return axios
    .get(url, config)
    .then((response: { data: { data: GlobalRoles[] } }) => {
      return mapAPIGlobalRolesToGlobalRoles(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

// dispatch
export const dispatchListGlobalRoles = (
  token: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: GLOBAL_ROLES_LOADING_CONSTANT, state: true }))
    return apiListRoles(token).then((resp) => {
      dispatch(reduceListGlobalRoles(resp))
      dispatch(reduceSetLoadingState({ name: GLOBAL_ROLES_LOADING_CONSTANT, state: false }))
    })
  }
}