import { Flex } from '@chakra-ui/react'
import { UseViewSize } from '../../../core/UseViewSize'
import { COLORS } from '../../../mini-lib/theme/colors'
import { ReportActions } from '../ReportActions'


export const COLOR_BRAND_DETAILS_REPORTS_TABS = {
  usedColors: 0,
  unusedColors: 1,
  wasteColors: 2,
}
export const ColorLineDetailsReportsTabs = (props: { selectedTab: number; onCsvExport: () => void }) => {
  const { onCsvExport } = props

  const { isMobile, isTablet } = UseViewSize()

  return (
    <Flex
      w="100%"
      direction={isMobile || isTablet ? 'column-reverse' : 'row'}
      justify="space-between"
      borderBottom={`1px solid ${COLORS.shades_neutral_300}`}
      pb="4px"
    >
      <ReportActions onCsvExport={onCsvExport} />
    </Flex>
  )
}
