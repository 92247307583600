import { ExtensionFilters } from './interfaces'

export const LOADING_EXTENSIONS = 'loading-extensions'
export const EMPTY_EXTENSION_FILTERS: ExtensionFilters = {
  searchText: null,
  extensionName: null,
  lineName: null,
  vendorName: null,
  categoryName: null,
  extensionSize: null,
  pricingFilter: null,
}
