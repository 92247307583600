import { ColorForMode } from '../../theme'
import { Box, Flex, Text } from '@chakra-ui/react'
import laborEmpty from '../../assets/labor-empty.svg'
import React from 'react'
import { UseViewSize } from '../../core/UseViewSize'

export const EmptyPage = (props: { title: string; content: string; imgUrl?: string; children?: any }) => {
  const st = ColorForMode('secondary-text')
  const { isMobile } = UseViewSize()
  const { title, content, children, imgUrl = laborEmpty } = props
  return (
    <Flex direction="column" justify="center" align="center">
      <img alt="logo" style={{ height: isMobile ? '250px' : '380px', maxWidth: '100%', padding: '4px' }} src={imgUrl} />
      <Text variant="title1" fontWeight="bold">
        {title}
      </Text>
      <Box h="24px" />
      <Text textAlign="center" color={st} maxW="100%" w="640px">
        {content}
      </Text>
      {children}
    </Flex>
  )
}
