import { APIInventoryCountItemUpdate, InventoryCount, InventoryCountItem } from '../../../data/inventory/interfaces'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Button, Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { UseViewSize } from '../../../core/UseViewSize'
import { SalonScaleProductIcon } from '../../../mini-lib/icons/SalonScaleProductIcon'
import { useDispatch } from 'react-redux'
import { dispatchUpdateInventoryCountItem } from '../../../data/inventory/api'
import { capitalize, includes, keys } from 'lodash'
import { ColorForMode } from '../../../theme'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { ManualItemRow, ManualTitle } from '../add-stock/ManualColumns'
import { OrderItemRow, OrderTitle } from '../add-stock/OrderColumns'
import { AuditItemRow, AuditTitle } from '../audit/AuditColumns'
import { buildLoadingName } from '../../../core/loading/utils'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { reduceSetStepCompleteDrawerState, selectStepCompleteDrawerState } from '../../../data/start-guide/slice'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { HELP_DRAWER_STATES } from '../../start-guide/common-components/HelpStepCompleteDrawer'
import { useAppSelector } from '../../../hooks'

export const InventoryCountAccordion = (props: {
  count: InventoryCount
  itemsByCategory: { [key: string]: InventoryCountItem[] }
}) => {
  const { itemsByCategory, count } = props
  const [expandedIndexes, setExpandedIndexes] = useState([0])
  return (
    <Accordion
      padding="0"
      allowToggle
      allowMultiple={true}
      defaultIndex={[0]}
      onChange={(indexes: any[]) => {
        setExpandedIndexes(indexes)
      }}
    >
      {keys(itemsByCategory)
        .sort()
        .map((category, index) => {
          const items = itemsByCategory[category]
          return (
            <CategoryAccordionPanel
              key={index}
              count={count}
              categoryName={category}
              index={index}
              items={items}
              expandedIndexes={expandedIndexes}
            />
          )
        })}
    </Accordion>
  )
}

export const CategoryAccordionPanel = ({
  count,
  expandedIndexes,
  index,
  categoryName,
  items,
}: {
  count: InventoryCount
  expandedIndexes: number[]
  index: number
  categoryName: string
  items: InventoryCountItem[]
}) => {
  const bgColor = ColorForMode('secondary-bg')
  const { isMobile } = UseViewSize()
  const panelVisible = includes(expandedIndexes, index)
  const [numRowsToShow, setNumRowsToShow] = useState(10)
  return (
    <AccordionItem border="none">
      <AccordionButton
        color="black"
        bg="brand.lavender.100"
        p="12px 12px 12px 0"
        borderRadius="8px"
        mb="8px"
        w="100%"
        maxH="50px"
        border="none"
        _hover={{ bg: 'brand.lavender.200' }}
        _focus={{ border: 'none' }}
      >
        <Flex align="center" p="12px" w="100%" maxW="100%" justify="space-between">
          <Flex align="center" gridGap="12px">
            <SalonScaleProductIcon variant="nobackground" size="24px" name={categoryName.toLowerCase()} />
            <Text variant="title3" color="black" fontWeight="normal">
              {capitalize(categoryName)}
            </Text>
          </Flex>
        </Flex>
        {panelVisible ? <MaterialIcon name="arrow_drop_up" /> : <MaterialIcon name="arrow_drop_down" />}
      </AccordionButton>
      {panelVisible && (
        <AccordionPanel padding="0 0 4px 0" bg={(index + 1) % 2 === 0 ? bgColor : ''}>
          {!isMobile && <TitleRow count={count} />}
          {items.slice(0, numRowsToShow).map((item, rowIndex) => {
            return <Row key={item.id} count={count} item={item} index={rowIndex} />
          })}
          {numRowsToShow < items.length && (
            <Flex justify="center" mt="8px">
              <Button variant="round-ghost-upper" onClick={() => setNumRowsToShow(numRowsToShow + 10)}>
                View More
              </Button>
            </Flex>
          )}
        </AccordionPanel>
      )}
    </AccordionItem>
  )
}

export const TitleRow = (props: { count: InventoryCount }) => {
  const { count } = props
  return (
    <>
      {count.countType === 'manual' && <ManualTitle count={count} />}
      {count.countType === 'order' && <OrderTitle count={count} />}
      {count.countType === 'inventory' && <AuditTitle count={count} />}
    </>
  )
}
export const Row = (props: { count: InventoryCount; item: InventoryCountItem; index: number }) => {
  const { count, item, index } = props
  const dispatch = useDispatch()
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const queryParams: any = UseQueryParams()
  const guideType = queryParams.get('guide')
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  // number input wants undefined not null which is annoying but that's the world we live in
  const [quantity, setQuantity] = useState<string | number | undefined>(item.actualCount ? item.actualCount : undefined)
  const onUpdateQuantity = (quantity: string) => {
    if (quantity !== null && quantity !== undefined && quantity !== '') {
      const updatedItem: APIInventoryCountItemUpdate = {
        id: item.id,
        product_id: item.product.id,
        count_id: count.id,
        count: parseFloat(quantity),
        reason: item.reason,
      }
      if (guideType === CHECKLIST_CODES.inventoryCompleteCount) {
        if (stepCompleteDrawerState !== HELP_DRAWER_STATES.button) {
          dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
        }
      }
      const loadingName = buildLoadingName(`inventory-quantity-${item.id}`)
      dispatch(dispatchUpdateInventoryCountItem({ token, salonId, countId: count.id, item: updatedItem, loadingName }))
    }
  }

  const onUpdateReason = (reason: string) => {
    const updatedItem: APIInventoryCountItemUpdate = {
      id: item.id,
      product_id: item.product.id,
      count_id: count.id,
      count: item.actualCount || 0,
      reason: reason,
    }
    const loadingName = buildLoadingName(`inventory-reason-${item.id}`)
    dispatch(dispatchUpdateInventoryCountItem({ token, salonId, countId: count.id, item: updatedItem, loadingName }))
  }
  return (
    <>
      {count.countType === 'manual' && (
        <ManualItemRow
          count={count}
          item={item}
          quantity={quantity}
          setQuantity={setQuantity}
          onUpdateQuantity={onUpdateQuantity}
        />
      )}
      {count.countType === 'order' && (
        <OrderItemRow
          count={count}
          item={item}
          quantity={quantity}
          setQuantity={setQuantity}
          onUpdateQuantity={onUpdateQuantity}
          onUpdateReason={onUpdateReason}
        />
      )}
      {count.countType === 'inventory' && (
        <AuditItemRow
          index={index}
          count={count}
          item={item}
          quantity={quantity}
          setQuantity={setQuantity}
          onUpdateQuantity={onUpdateQuantity}
          onUpdateReason={onUpdateReason}
        />
      )}
    </>
  )
}
