import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  NumberInput,
  NumberInputField, Select,
  Text
} from '@chakra-ui/react'
import { reduceSetSelectedBowlId, selectActiveSessionDisplayUnit, selectBowlTypes } from '../../../data/sessions/slice'
import { ConfirmPopover } from '../../../mini-lib/confirm-popover/ConfirmPopover'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UseViewSize } from '../../../core/UseViewSize'
import { BowlType, DisplayUnit, SessionColorLara } from '../../../data/sessions/interfaces'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { find, values } from 'lodash'
import {
  BOWL_SHEET_VIEW_TYPES_LARA,
  BowlSheetViewTypeLara,
  DISPLAY_UNIT_OPTIONS
} from '../../../data/sessions/constants'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useAppSelector } from '../../../hooks'
import { dispatchGetBowlTypes } from '../../../data/sessions/api'
import { COLORS } from '../../../mini-lib/theme/colors'
import { DropdownFilter } from '../../../mini-lib/filters/DropdownFilter'
import { convertDisplayUnits, getBowlTagLara, getBowlTypeLara } from '../../../data/sessions/utils'
import { formatCentsToDollars } from "../../../mini-lib/units/money";
import { convertPricePerGramToPricePerOz } from "../../../data/products/utils";
import { UseQueryParams } from "../../../mini-lib/utils/basic";

export const SessionBowlColorsView = (props: {
  setBowlView: (view: BowlSheetViewTypeLara) => void
  setShowProductSheet: (showSheet: boolean) => void
  sessionColors: SessionColorLara[]
  onBowlTypeSelect: (type: BowlType | null) => void
  onBowlTagChange: (bowlTag: string) => void
  onDeleteSessionItem: (item: SessionColorLara) => void
  updateSessionColor: (params: {sessionColor: SessionColorLara, amount?: number, waste?: number}) => void
  onDeleteBowl: () => void
  onSave: () => void
  isDisabled: boolean
}) => {
  const {
    setBowlView,
    setShowProductSheet,
    sessionColors,
    onBowlTypeSelect,
    onBowlTagChange,
    onDeleteSessionItem,
    updateSessionColor,
    onDeleteBowl,
    onSave,
    isDisabled,
  } = props
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()

  const sessionItemsWithWaste = sessionColors.filter((item) => item.wasteGrams && item.wasteGrams > 0)
  return (
    <>
      <Flex align="center" justify="space-between" gridGap="12px">
        <MaterialIcon
          cursor="pointer"
          colorhex={COLORS.lavender_500}
          size="36px"
          name="chevron_left"
          onClick={() => dispatch(reduceSetSelectedBowlId(null))}
        />
        <Flex align="center" gridGap="12px">
          <ConfirmPopover
            title={`This Action is Permanent`}
            subtitle={`This will delete the bowl from your session`}
            onConfirm={() => {
              onDeleteBowl()
            }}
          >
            <Button variant="round-ghost-upper" color="danger" minW="100px">
              {isMobile ? 'Delete' : 'Delete Bowl'}
            </Button>
          </ConfirmPopover>

          <Button colorScheme="brand.midnight" variant="round" onClick={onSave} minW="100px">
            Done
          </Button>
        </Flex>
      </Flex>
      <Box h="12px" />
      <Text variant="largetitle" textTransform="uppercase">
        Edit Bowl
      </Text>
      <Box h="12px" />

      {sessionColors.length > 0 && (
        <Flex gridGap="12px" align="center">
          <BowlTypeSelect bowlType={getBowlTypeLara(sessionColors[0])} onBowlTypeSelect={onBowlTypeSelect} />
          <Input
            placeholder='Bowl Tag'
            w='160px' fontSize={13}
            borderRadius="100px"
            defaultValue={getBowlTagLara(sessionColors[0])}
            onBlur={( evt ) => onBowlTagChange(evt.currentTarget.value)}
    />
        </Flex>
      )}
      <ConfirmPopover
        title={`You can't edit this bowl`}
        subtitle={`A session with waste cannot be modified`}
        onConfirm={() => { }}
        showCancel={false}
        confirmText="Ok"
        showPopover={isDisabled}
      >
        <Box>
          {sessionItemsWithWaste.length > 0 && (
            <>
              <Box h="24px" />
              <Text variant="title1">Color Mixed</Text>
              <Box h="12px" />
            </>
          )}

          {sessionColors &&
            sessionColors.length > 0 &&
            sessionColors.map((sessionColor, index) => {
              return (
                <SessionItemRow
                  bowlView={'amount'} // todo: delete bowlView prop
                  key={index}
                  isDisabled={isDisabled}
                  sessionColor={sessionColor}
                  updateSessionColor={updateSessionColor}
                  deleteItem={onDeleteSessionItem}
                  showUnits={true}
                />
              )
            })}
        </Box>
      </ConfirmPopover>

      {sessionColors.length === 0 && (
        <>
          {' '}
          <EmptyBox h="200px" />
          <Box h="12px" />
        </>
      )}

      {isMobile && <Box h="12px" />}

      <Flex justify="flex-end" gridGap="12px">
        {sessionColors.length > 0 && (
          <Button
            variant="round-outline"
            colorScheme="brand.lavender"
            onClick={() => setBowlView(BOWL_SHEET_VIEW_TYPES_LARA.waste)}
          >
            {isDisabled ? 'View Waste' : 'Record Waste'}
          </Button>
        )}

        <Button
          disabled={isDisabled}
          variant="round-outline"
          colorScheme="brand.lavender"
          onClick={() => setShowProductSheet(true)}
        >
          <MaterialIcon name="add" style={{ marginRight: '4px' }} /> Products
        </Button>
      </Flex>

      <Box h="36px" />
      {sessionItemsWithWaste.length > 0 && (
        <>
          <Text variant="title1">Color Wasted</Text>
          <Box h="12px" />
          {sessionItemsWithWaste &&
            sessionItemsWithWaste.length > 0 &&
            sessionItemsWithWaste.map((sessionItem, index) => {
              return (
                <SessionItemRow
                  isDisabled={isDisabled}
                  bowlView={'waste-disabled'}
                  key={index}
                  sessionColor={sessionItem}
                  updateSessionColor={updateSessionColor}
                  deleteItem={onDeleteSessionItem}
                  showUnits={false}
                />
              )
            })}
        </>
      )}
    </>
  )
}

export const SessionItemRow = (props: {
  sessionColor: SessionColorLara
  updateSessionColor: (params: {sessionColor: SessionColorLara, amount?: number, waste?: number, displayUnit?: DisplayUnit}) => void
  deleteItem: (item: SessionColorLara) => void
  bowlView: 'waste' | 'amount' | 'waste-disabled'
  isDisabled: boolean
  showUnits: boolean
}) => {
  const queryParams = UseQueryParams()
  const dev = queryParams.get('dev')
  const { isMobile } = UseViewSize()
  const { isDisabled, bowlView, sessionColor, updateSessionColor, deleteItem, showUnits } = props
  const displayUnit = useAppSelector(selectActiveSessionDisplayUnit)
  const initialValue = getSessionItemRowInitialValue(bowlView, displayUnit, sessionColor)
  const [numInputValue, setNumInputValue] = useState<string | number>(initialValue)

  const colorDisplayUnit = sessionColor.displayUnit
  const amount = sessionColor.amountGrams ? convertDisplayUnits(sessionColor.amountGrams, 'g', colorDisplayUnit) : 0
  const waste = sessionColor.wasteGrams ? convertDisplayUnits(sessionColor.wasteGrams, 'g', colorDisplayUnit) : 0

  const productIsDeleted = sessionColor.product.isDeleted

  useEffect(() => {
    if (bowlView === 'amount') {
      setNumInputValue(amount)
    }
    if (bowlView === 'waste') {
      setNumInputValue(waste)
    }
  }, [amount, waste, bowlView, colorDisplayUnit])

  const pricePerDisplayUnit = formatCentsToDollars(convertDisplayUnits(sessionColor.product.clientPurchaseCentsPerGram, 'g', displayUnit))
  return (
    <>
      <Box h="12px" />
      <Flex
        key={sessionColor.id}
        align={isMobile ? 'start' : 'center'}
        justify="space-between"
        p="0 0 12px 0"
        cursor="pointer"
        gridGap="12px"
        direction={isMobile ? 'column' : 'row'}
      >
        <Box maxW="60%" w="200px">
          <Flex align='center' gridGap='4px'>
            <Text variant={isMobile ? 'title' : 'title3opensans'}>{sessionColor?.product?.type}</Text>
            <Text color={COLORS.danger}>{productIsDeleted ? ' (deleted)' : ''}</Text>
          </Flex>
          <Text color="text.secondary">{sessionColor?.product?.line.name} {dev === 'true' && ` • ${pricePerDisplayUnit}/${displayUnit}`}</Text>
        </Box>
        <Flex align="center" justify="end" gridGap="12px">
          <Box>
            <Text color="text.secondary">Amount</Text>
            <Flex align='center' gridGap='4px'>
              <NumberInput
                isDisabled={bowlView === 'waste-disabled' || isDisabled || productIsDeleted}
                w="120px"
                value={numInputValue}
                inputMode="decimal"
                precision={2}
                min={0}
                onChange={(updatedAmount) => {

                  if (updatedAmount === '') {
                    return setNumInputValue('')
                  }
                  const numberAmount = parseFloat(updatedAmount)
                  if (numberAmount >= 0) {
                    setNumInputValue(updatedAmount)
                  }
                }}
                onBlur={(evt) => {
                  const updatedAmount = evt.target.value
                  // base case, empty input
                  if (updatedAmount === '') {
                    bowlView === 'waste' && updateSessionColor({sessionColor, waste: 0 })
                    bowlView === 'amount' && updateSessionColor({sessionColor, amount: 0})
                  }
                  // if there actually is an input
                  const numberAmount = parseFloat(updatedAmount)
                  if (numberAmount >= 0) {
                    // convert to grams before updating
                    const amountGrams = convertDisplayUnits(numberAmount, sessionColor.displayUnit, 'g')
                    bowlView === 'waste'&& updateSessionColor({sessionColor, waste: amountGrams })
                    bowlView === 'amount'&& updateSessionColor({sessionColor, amount: amountGrams})
                  }
                }}
                borderRadius="100px"
              >
                <NumberInputField borderRadius="100px" />
              </NumberInput>
              {showUnits && (
                <Select
                  disabled={bowlView === 'waste' || isDisabled || productIsDeleted}
                  value={sessionColor.displayUnit}
                  borderRadius="100px"
                  w="70px"
                  onChange={(evt: any) => {
                    const updatedUnit = evt.target.value
                    if (updatedUnit) {
                      updateSessionColor({sessionColor, displayUnit: updatedUnit})
                    }
                  }}
                >
                  {values(DISPLAY_UNIT_OPTIONS).map((option: any, index) => {
                    return (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    )
                  })}
                </Select>
              )}
            </Flex>
          </Box>
          {/* todo: maybe allow this but probably not, we are moving to a session wide displayUnit selector */}
          {/*{(bowlView === 'waste' || bowlView === 'amount') && (*/}
          {/*  <Box>*/}
          {/*    <Text color="text.secondary">Units</Text>*/}
          {/*    <Select*/}
          {/*      disabled={bowlView === 'waste' || isDisabled}*/}
          {/*      defaultValue={units}*/}
          {/*      borderRadius="100px"*/}
          {/*      w="120px"*/}
          {/*      onChange={(evt: any) => {*/}
          {/*        const updatedUnit = evt.target.value*/}
          {/*        if (updatedUnit) {*/}
          {/*          updateSessionColor(sessionColor, { displayUnit: updatedUnit })*/}
          {/*        }*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {values(DISPLAY_UNIT_OPTIONS).map((option: any, index) => {*/}
          {/*        return (*/}
          {/*          <option key={index} value={option.value}>*/}
          {/*            {option.label}*/}
          {/*          </option>*/}
          {/*        )*/}
          {/*      })}*/}
          {/*    </Select>*/}
          {/*  </Box>*/}
          {/*)}*/}
          {bowlView === 'amount' && !isDisabled && (
            <MaterialIcon mt="16px" name="delete" onClick={() => deleteItem(sessionColor)} />
          )}
        </Flex>
      </Flex>
      <Box h="12px" />
      {isMobile && <Divider />}
    </>
  )
}

const getSessionItemRowInitialValue = (bowlView: string, displayUnit: DisplayUnit, sessionItem: SessionColorLara): number | string => {
  if (displayUnit === 'g') {

    if (bowlView === 'amount') {
      return sessionItem.amountGrams || 0
    }
    if (bowlView === 'waste') {
      return sessionItem.wasteGrams || 0
    }
    // bowlView === 'waste-disabled'
    return `${sessionItem.wasteGrams ? convertDisplayUnits(sessionItem.wasteGrams, 'g', sessionItem.displayUnit) : 0}${sessionItem.displayUnit}`
  }
  // if display unit is oz
  if (bowlView === 'amount') {
      return sessionItem.amountGrams ? convertPricePerGramToPricePerOz({pricePerGram: sessionItem.amountGrams}) : 0
    }
    if (bowlView === 'waste') {
      return sessionItem.wasteGrams ? convertPricePerGramToPricePerOz({pricePerGram: sessionItem.wasteGrams}) : 0
    }
    // bowlView === 'waste-disabled'
    return `${sessionItem.wasteGrams ? convertPricePerGramToPricePerOz({pricePerGram: sessionItem.wasteGrams}) : 0}${sessionItem.displayUnit}`

}

const BowlTypeSelect = (props: {
  bowlType: BowlType | null
  onBowlTypeSelect: (bowlType: BowlType | null) => void
}) => {
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()
  const bowlTypes = useAppSelector(selectBowlTypes)
  const { bowlType, onBowlTypeSelect } = props
  const bowlTypesLoaded = !!bowlTypes
  useEffect(() => {
    if (!bowlTypesLoaded) {
      dispatch(dispatchGetBowlTypes({ token: user.token }))
    }
  }, [dispatch, user.token, bowlTypesLoaded])

  const bowlTypesOptions = bowlTypes?.map((e) => ({ label: e.name, value: e.id }))
  // Adding none option
  bowlTypesOptions?.splice(0, 0, { label: 'none', value: '' })
  const selectedOption = bowlType?.id ?? ''
  return (
    <>
      <DropdownFilter
        w="160px"
        placeholder="Bowl Type"
        value={selectedOption}
        options={bowlTypesOptions ?? []}
        variant='round'
        theme=''
        onChange={(evt) => {
          const typeId = evt.value
          if (typeId === '') {
            onBowlTypeSelect(null)
          } else {
            const selectedType: BowlType | any = find(bowlTypes, (type) => type.id === typeId)
            onBowlTypeSelect(selectedType ?? null)
          }

        }}
        isClearable={false}
      />
    </>
  )
}

