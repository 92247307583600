import React, { useEffect, useState } from 'react'
import { Box, Button, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react'
import { InputError } from './InputError'
import { COLORS } from '../theme/colors'
import { ThemeType } from '../../theme'
import { uniqueId } from 'lodash'
import { MaterialLabel } from './MaterialHelpers'
import { MaterialIcon } from '../icons/MaterialIcon'

export const PasswordInput = (props: {
  label?: string
  placeholder?: string
  isDisabled?: boolean
  w?: string
  value: string
  onBlur: (val: string) => void
  onChange: (val: string) => void
  errorText?: string
  variant?: 'basic' | 'material'
  theme?: ThemeType
  allowFocus?: boolean
}) => {
  const { w, variant = 'basic' } = props

  return (
    <Box w={w}>
      {variant === 'basic' && <BasicPasswordInput {...props} />}
      {variant === 'material' && <MaterialPasswordInput {...props} />}
    </Box>
  )
}

export const BasicPasswordInput = (props: {
  label?: string;
  placeholder?: string;
  isDisabled?: boolean;
  value: string;
  onBlur: (val:string) => void;
  onChange: ( val: string ) => void;
  errorText?: string;
}) => {
  const {
    label,
    placeholder = 'Enter Password',
    isDisabled,
    value,
    onChange,
    onBlur,
    errorText = '',
  } = props;
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  return (
    <>
      {label && (
        <Text textAlign="left" pb="6px" color={isDisabled ? 'shades.neutral.400' : ''}>
          {label}
        </Text>
      )}

      <InputGroup>
        {/*
        note: manually turning auto capitalize off because safari things this should be autocapitalized
        ref: https://stackoverflow.com/questions/11102729/how-to-prevent-auto-capitalization-in-ios-safari-input-tags
        */}
        <Input
          value={value}
          onBlur={evt => onBlur(evt.target.value)}
          onChange={evt => onChange(evt.target.value)}
          borderRadius="100px"
          isDisabled={isDisabled}
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          borderColor={!!errorText ? COLORS.danger : COLORS.shades_neutral_200}
          borderWidth={!!errorText ? '2px' : '1px'}
          autoCapitalize="off"
        />
        <InputRightElement w="50px" mr="12px">
          <Button size="sm" h="24px" onClick={handleClick}>
            {show ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      </InputGroup>
      <InputError showError={!!errorText} errorText={errorText}/>
  </>
  )
}


export const MaterialPasswordInput = ( props: {
  label?: string;
  isDisabled?: boolean
  value: string
  onChange: ( val: string ) => void
  onBlur: ( val: string ) => void
  errorText?: string
  theme?: ThemeType
  border?: 'normal' | 'rounded'
  allowFocus?: boolean
} ) => {
  const {
    label,
    isDisabled = false,
    value,
    onChange,
    onBlur,
    errorText = '',
    theme = 'basic',
    border = 'rounded',
    allowFocus = false
  } = props
  const [isFocused, setIsFocused] = useState(false)
  const [hasContent, setHasContent] = useState(value !== '')

  const elementId = uniqueId('formfield-')
  useEffect(() => {
    setHasContent(value !== '')
  }, [value])

  const handleBlur = ( val ) => {
    setIsFocused(false)
    onBlur(val)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }
  const [show, setShow] = useState(false)

  const themeColor = theme === 'basic' ? 'black' : COLORS[`${theme}_500`]
  return (
    <Box position='relative'>
      {label && (
        <MaterialLabel
          label={label}
          elementId={elementId}
          isFocused={isFocused}
          isDisabled={isDisabled}
          hasContent={hasContent}
          themeColor={themeColor}
          allowFocus={allowFocus}
        />
      )}

      <InputGroup>
        {/*
        note: manually turning auto capitalize off because safari things this should be autocapitalized
        ref: https://stackoverflow.com/questions/11102729/how-to-prevent-auto-capitalization-in-ios-safari-input-tags
        */}
        <Input
          id={elementId}
          type={show ? 'text' : 'password'}
          borderRadius={border === 'rounded' ? '50px' : '10px'}
          isDisabled={isDisabled}
          borderColor={!!errorText ? COLORS.danger : COLORS.shades_neutral_200}
          borderWidth='1px'
          _hover={{ borderColor: 'gray.300' }}
          _focus={{
            borderColor: theme === 'basic' ? COLORS.shades_neutral_400 : themeColor,
            borderWidth: '2px'
          }}
          value={value}
          onFocus={handleFocus}
          onBlur={( evt ) => handleBlur(evt.target.value)}
          onChange={( evt ) => onChange(evt.target.value)}
          size="md"
          autoCapitalize="off"
          autoComplete={allowFocus ? "new-password" : undefined}
        />
        <InputRightElement w="50px" cursor='pointer'>
          {show
            ? <MaterialIcon colorhex={theme === 'basic' ? COLORS.shades_neutral_400 : themeColor} name='visibility_off' onClick={() => setShow(!show)}/>
            : <MaterialIcon colorhex={theme === 'basic' ? COLORS.shades_neutral_400 : themeColor} name='visibility' onClick={() => setShow(!show)}/>
          }
          </InputRightElement>
      </InputGroup>
      <InputError showError={!!errorText} errorText={errorText}/>
    </Box>
  )
}
