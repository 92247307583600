import React, { ReactNode, useState } from 'react'
import { AccordionPanel, Box, Flex, Text } from '@chakra-ui/react'
import { UseViewSize } from '../../../core/UseViewSize'
import { Video } from '../../../mini-lib/video/Video'
import { VideoData } from '../../../mini-lib/video/constants'
import { useDispatch } from 'react-redux'
import { dispatchUpdateUserChecklistItem } from '../../../data/start-guide/api'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { COLORS } from '../../../mini-lib/theme/colors'
import { DisabledPopover } from './DisabledPopover'

export const StepAccordionPanel = (props: {
  disableState?: { isDisabled: boolean; disabledMessage: string | ReactNode | null }
  stepCode: string
  descriptionSubHeading?: string
  description: string
  video?: VideoData
  actions?: ReactNode
}) => {
  const {
    disableState = { isDisabled: false, disabledMessage: null },
    stepCode,
    description,
    video,
    actions,
    descriptionSubHeading,
  } = props
  const { isMobile } = UseViewSize()
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()
  const updateChecklistItem = () => {
    dispatch(
      dispatchUpdateUserChecklistItem({
        token: user.token,
        checklistItemCode: stepCode,
      }),
    )
  }
  const [disabledPopoverOpen, setDisabledPopoverOpen] = useState(false)
  return (
    <AccordionPanel p={0}>
      <DisabledPopover
        show={disableState.isDisabled}
        isOpen={disabledPopoverOpen}
        setIsOpen={setDisabledPopoverOpen}
        description={disableState.disabledMessage}
      >
        <Flex
          p={isMobile ? '12px' : '24px'}
          gridGap="24px"
          wrap="wrap"
          justify="space-between"
          onClick={() => {
            if (disableState.isDisabled) {
              setDisabledPopoverOpen(true)
            }
          }}
        >
          <Box w={isMobile || !video ? '100%' : '50%'}>
            {disableState.isDisabled && (
              <>
                {disableState.disabledMessage}
                <Box h="12px" />
              </>
            )}
            <Text
              fontWeight="bold"
              variant="footnote"
              maxW="450px"
              whiteSpace="pre-wrap"
              color={disableState.isDisabled ? COLORS.text_secondary : ''}
            >
              {descriptionSubHeading}
            </Text>
            <Text
              variant="footnote"
              maxW="450px"
              whiteSpace="pre-wrap"
              color={disableState.isDisabled ? COLORS.text_secondary : ''}
            >
              {description}
            </Text>
            {!disableState.isDisabled && <Box mt="24px">{actions}</Box>}
          </Box>
          {video && (
            <Box w={isMobile ? '100%' : '45%'}>
              <Video
                isDisabled={disableState.isDisabled}
                variant="modal"
                video={video}
                height="280px"
                width="100%"
                videoClicked={updateChecklistItem}
              />
            </Box>
          )}
        </Flex>
      </DisabledPopover>
    </AccordionPanel>
  )
}
