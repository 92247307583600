import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '../../mini-lib/theme/colors'
import { useAppSelector } from '../../hooks'
import { selectActiveSalonContext } from '../../data/salon/slice'
import { generatePath, Link } from 'react-router-dom'
import React, { useEffect } from 'react'
import { ROUTES } from '../../appRoutes'
import { UseViewSize } from '../../core/UseViewSize'
import { getTrialDaysLeft, getTrialDaysLeftText } from './utils'
import { dispatchListSessionsLara } from '../../data/sessions/api'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { selectSessionsLara } from '../../data/sessions/slice'
import { TrialQrCodesButtonAndModal } from './TrialQrCodes'
import { Loading } from '../../mini-lib/loading/Loading'
import { Banner } from '../../mini-lib'

export const TrialDaysRemaining = () => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()

  const sessions = useAppSelector(selectSessionsLara)
  const hasSessions = sessions && sessions.length > 0

  const salon = useAppSelector(selectActiveSalonContext)
  const daysLeft = salon?.trialExpiryDate ? getTrialDaysLeft(salon.trialExpiryDate) : null
  const daysLeftText = getTrialDaysLeftText(daysLeft)

  const trialActive: boolean = !!(daysLeft && daysLeft >= 0)
  const sessionsLoaded = !!sessions || !trialActive

  useEffect(() => {
    dispatch(dispatchListSessionsLara({ token: user.token, salonId }))
  }, [dispatch, user.token, salonId])

  return (
    <>
      {!sessionsLoaded && (
        <Flex w="100%" justify="center">
          <Loading />
        </Flex>
      )}

      {sessionsLoaded && !trialActive && (
        <Banner
          theme="peach"
          iconName="warning"
          content={
            <Flex w="100%" justify="space-between" align="center">
              <Text color="black">Your trial has ended. Please upgrade to continue using SalonScale!</Text>
              <CheckoutLink trialActive={trialActive} />
            </Flex>
          }
        />
      )}
      {sessionsLoaded && trialActive && hasSessions && (
        <Banner
          theme="lavender"
          content={
            <Flex w="100%" justify="space-between" align="center">
              <Text color="black">{daysLeftText}</Text>
              <CheckoutLink trialActive={trialActive} />
            </Flex>
          }
        />
      )}
      {sessionsLoaded && trialActive && !hasSessions && (
        <Banner
          theme="lavender"
          content={
            <Flex w="100%" justify="space-between" align="center">
              <Text color="black">Download the mobile app to try now!</Text>
              <Box><TrialQrCodesButtonAndModal /></Box>
            </Flex>
          }
        />
      )}
    </>
  )
}

const CheckoutLink = (props: { trialActive: boolean }) => {
  const { trialActive } = props
  const { salonId } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  return (
    <Link to={generatePath(ROUTES.checkoutSubscription, { salonId })} style={{ width: isMobile ? '100%' : '' }}>
      <Button
        variant="round-border"
        borderColor={trialActive ? COLORS.lavender_500 : 'black'}
        color={trialActive ? COLORS.lavender_500 : 'black'}
      >
        Upgrade Now
      </Button>
    </Link>
  )
}
