// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, has, keyBy, keys, map, omit, orderBy, uniq, values } from 'lodash'

import { RootState } from '../../store'
import {
  LineNamesByVendor,
  LineOption,
  LinesById,
  LinesByVendor,
  Product,
  ProductFilters,
  ProductsByCategoryThenByLineSize,
  VendorsById,
} from './interfaces'
import {
  mapLinesByIdToLinesByVendor,
  mapLinesByIdToVendorsById,
  mapProductsByCategoryThenByLineSize,
  mapProductsToLineNamesByVendor,
  mapProductsToProductsByVendor,
} from './mappers'
import { EMPTY_PRODUCT_FILTERS } from './constants'
import { filterProducts } from './utils'

// state
//
interface ProductState {
  productsById: { [key: string]: Product } | null
  allLinesById: LinesById | null
  allLines: LineOption[] | null
  selectedProduct: Product | null
  selectedProductIds: number[] | null
  selectedLineName: string | null
  productFilters: ProductFilters
  productSheetVisibility: boolean
  lineDetailsSheetId: number | string | null
  productSheetFilters: any
  inventoryInfoSheetVisibility: boolean
  selectedProductMap: { [key: string]: boolean }
  selectedProductsById: { [key: string]: Product }
  bulkProductSheetVisibility: boolean
  selectedCategories: string[]
}

const initialState: ProductState = {
  productsById: null, // undefined is an accident, null is a choice, this lets us know when something is loading
  allLines: null,
  allLinesById: null,
  selectedProduct: null,
  selectedProductIds: null,
  selectedLineName: null,
  productSheetVisibility: false,
  lineDetailsSheetId: null,
  productSheetFilters: null,
  productFilters: EMPTY_PRODUCT_FILTERS,
  inventoryInfoSheetVisibility: false,
  selectedProductMap: {},
  selectedProductsById: {},
  bulkProductSheetVisibility: false,
  selectedCategories: [],
}

// reducer
//
export const ProductSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    reduceSelectedProductMap: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.selectedProductMap = action.payload
    },
    reduceSetSelectedProduct: (state, action: PayloadAction<Product | null>) => {
      state.selectedProduct = action.payload
    },
    reduceSetSelectedProductIds: (state, action: PayloadAction<number[] | null>) => {
      state.selectedProductIds = action.payload
    },
    reduceSetProductFilters: (state, action: PayloadAction<ProductFilters>) => {
      state.productFilters = action.payload
    },
    reduceSetProductSheetVisibility: (state, action: PayloadAction<boolean>) => {
      state.productSheetVisibility = action.payload
    },
    reduceBulkProductSheetVisibility: (state, action: PayloadAction<boolean>) => {
      state.bulkProductSheetVisibility = action.payload
    },
    reduceSetLineDetailsSheetId: (state, action: PayloadAction<number | string | null>) => {
      state.lineDetailsSheetId = action.payload
    },
    reduceSetInventoryInfoSheetVisibility: (state, action: PayloadAction<boolean>) => {
      state.inventoryInfoSheetVisibility = action.payload
    },
    reduceSetProductSheetFilters: (state, action: PayloadAction<any>) => {
      state.productSheetFilters = action.payload
    },
    reduceSetSelectedLineName: (state, action: PayloadAction<string | null>) => {
      state.selectedLineName = action.payload
    },
    reduceListProduct: (state, action: PayloadAction<Product[]>) => {
      state.productsById = assign({}, state.productsById, keyBy(action.payload, 'id'))
    },
    reduceCreateProduct: (state, action: PayloadAction<Product>) => {
      if (state.productsById) {
        state.productsById[action.payload.id] = action.payload
      }
    },
    reduceUpdateProducts: (state, action: PayloadAction<Product[]>) => {
      state.productsById = assign({}, state.productsById, keyBy(action.payload, 'id'))
    },
    reduceDeleteProduct: (state, action: PayloadAction<Product>) => {
      state.productsById = omit(state.productsById, action.payload.id)
    },
    reduceDeleteProducts: (state, action: PayloadAction<Product[]>) => {
      const productIds = map(action.payload, 'id')
      state.productsById = omit(state.productsById, productIds)
    },
    reduceDeleteLine: (state, action: PayloadAction<Product[]>) => {
      const productIdsToRemove = action?.payload?.map((p) => p.id)
      state.productsById = omit(state.productsById, productIdsToRemove)
    },
    reduceListAllLines: (state, action: PayloadAction<LineOption[]>) => {
      if (action.payload && action.payload.length > 0) {
        state.allLinesById = assign({}, state.allLinesById, keyBy(action.payload, 'lineId'))
      }
    },
    reduceRemoveProductsFromSelectedProductMap: (state, action: PayloadAction<Product[]>) => {
      const productIds = map(action.payload, 'id')
      state.selectedProductsById = omit(state.selectedProductsById, productIds)
    },
    reduceAddProductsToSelectedProductMap: (state, action: PayloadAction<Product[]>) => {
      state.selectedProductsById = assign({}, state.selectedProductsById, keyBy(action.payload, 'id'))
    },
    reduceResetSelectedProductMap: (state) => {
      state.selectedProductsById = {}
    },
    reduceResetProducts: (state) => {
      state.productsById = {}
    },
    reduceAddSelectedCategory: (state, action: PayloadAction<string>) => {
      state.selectedCategories = [...state.selectedCategories, action.payload]
    },
    reduceRemoveSelectedCategory: (state, action: PayloadAction<string>) => {
      state.selectedCategories = state.selectedCategories.filter(key => key !== action.payload);
    },
    reduceSetSelectedCategories: (state, action: PayloadAction<string[]>) => {
      state.selectedCategories = action.payload
    },
  },
})

// actions
//
export const {
  reduceSelectedProductMap,
  reduceSetInventoryInfoSheetVisibility,
  reduceSetSelectedProductIds,
  reduceSetSelectedProduct,
  reduceListProduct,
  reduceCreateProduct,
  reduceUpdateProducts,
  reduceDeleteProduct,
  reduceDeleteProducts,
  reduceDeleteLine,
  reduceSetSelectedLineName,
  reduceListAllLines,
  reduceSetProductFilters,
  reduceSetProductSheetVisibility,
  reduceSetLineDetailsSheetId,
  reduceSetProductSheetFilters,
  reduceAddProductsToSelectedProductMap,
  reduceRemoveProductsFromSelectedProductMap,
  reduceResetSelectedProductMap,
  reduceBulkProductSheetVisibility,
  reduceSetSelectedCategories,
  reduceRemoveSelectedCategory,
  reduceAddSelectedCategory,
  reduceResetProducts
} = ProductSlice.actions

// selectors
//
// vendors, lines, and products the salon uses
export const selectSelectedProductMap = (state: RootState) => state.products.selectedProductMap
export const selectSelectedProduct = (state: RootState) => state.products.selectedProduct
export const selectSelectedProducts = (state: RootState): Product[] | null => {
  const productsById = state.products.productsById
  if (state.products.selectedProductIds && productsById) {
    const products: Product[] = []
    state.products.selectedProductIds.forEach((id) => {
      const product = productsById[id]
      products.push(product)
    })
    return products
  }
  return null
}
export const selectSelectedLineName = (state: RootState) => state.products.selectedLineName
export const selectProductSheetVisibility = (state: RootState) => state.products.productSheetVisibility
export const selectLineDetailsSheetId = (state: RootState) => state.products.lineDetailsSheetId
export const selectInventoryInfoSheetVisibility = (state: RootState) => state.products.inventoryInfoSheetVisibility

export const selectProductsById = (state: RootState) => state.products.productsById

export const selectSelectedProductsById = (state: RootState) => state.products.selectedProductsById
export const selectLineNamesByVendor = (state: RootState): LineNamesByVendor | null => {
  return state.products.productsById ? mapProductsToLineNamesByVendor(values(state.products.productsById)) : null
}

export const selectProductsByVendor = (state: RootState): { [key: string]: Product[] } | null => {
  return state.products.productsById ? mapProductsToProductsByVendor(values(state.products.productsById)) : null
}

export const selectProductFilters = (state: RootState): ProductFilters => {
  return state.products.productFilters
}

export const selectInfoOnProductsWithPricing = ( state: RootState ): { percentage: number, totalProducts: number, numberCompleted: number } | null => {
  if (!state.products.productsById) return null
  const productsById = state.products.productsById
  const products = values(productsById)
  const numberCompleted = products.filter(( p ) => p.pricing && p.pricing.price > 0).length
  const percentage = Math.round(( numberCompleted / products.length ) * 100)
  return { totalProducts: products.length, percentage, numberCompleted }
}
export const selectInfoOnProductsWithTarget = ( state: RootState): {percentage: number, totalProducts: number, numberCompleted: number} | null => {
  if (!state.products.productsById) return null
  const productsById = state.products.productsById
  const products = values(productsById)
  const productsWithTarget = products?.filter((p) => {
    return p?.inventory && p?.inventory?.maxStockLevel !== null
  })
  const numberCompleted = productsWithTarget?.length || 0
  const percentage = Math.round((numberCompleted / products.length) * 100)

  return  {totalProducts: products.length, percentage, numberCompleted}
}

// note: quantityOnHand is defaulted to 0 not null, the lowest quantity they can have is 0
// unfortunately that means we cant really calculate if they have set all of their inventory
export const selectInfoOnProductsWithOnHand = (state: RootState): {percentage: number, totalProducts: number, numberCompleted: number} | null => {
  if (!state.products.productsById) return null
  const productsById = state.products.productsById
  const products = values(productsById)
  const productsWithOnHand = products?.filter((p) => {
    return p?.inventory && p?.inventory?.quantityOnHand !== 0
  })
  const numberCompleted = productsWithOnHand?.length || 0
  const percentage = Math.round((numberCompleted / products.length) * 100)
  return  {totalProducts: products.length, percentage, numberCompleted}
}

// vendors, lines, and products the salon does not have
export const selectAllLines = (state: RootState): LineOption[] | null => {
  return state.products.allLines
}
export const selectAllLinesByVendor = (state: RootState): LinesByVendor | null => {
  return state.products.allLinesById ? mapLinesByIdToLinesByVendor(state.products.allLinesById) : null
}

export const selectVendorsById = (state: RootState): VendorsById | null => {
  return state.products.allLinesById ? mapLinesByIdToVendorsById(state.products.allLinesById) : null
}
export const selectProductList = (state: RootState): Product[] | null => {
  const property1Sorter = (product) => product.type.toLowerCase()
  const property2Sorter = (product) => product.line.name.toLowerCase()
  const property3Sorter = (product) => product.vendor.name.toLowerCase()
  return state.products.productsById
    ? orderBy(
        values(state.products.productsById),
        [property1Sorter, property2Sorter, property3Sorter],
        ['asc', 'asc', 'asc'],
      )
    : null
}
// note: the old api does not return line id so we need to use vendor id to ensure that similiarly named lines (eg Keratin Complex) don't break this
export const selectProductListForLine = (state: RootState, lineName: string, vendorName: string): Product[] | null => {
  if (!state.products.productsById) {
    return null
  }
  const property1Sorter = (product) => product.type.toLowerCase()
  const property2Sorter = (product) => product.line.name.toLowerCase()
  const property3Sorter = (product) => product.vendor.name.toLowerCase()
  const productsForLine = values(state.products.productsById).filter((product) => product.line.name === lineName && product.vendor.name === vendorName)
  return productsForLine
    ? orderBy(productsForLine, [property1Sorter, property2Sorter, property3Sorter], ['asc', 'asc', 'asc'])
    : null
}
export const selectProductListSortedByStockLevel = (state: RootState): Product[] | null => {
  const lineSorter = (product) => product.line.name.toLowerCase()
  const nameSorter = (product) => product.type.toLowerCase()
  return state.products.productsById
    ? orderBy(
        values(state.products.productsById),
        [lineSorter , nameSorter],
        ['asc', 'asc'],
      )
    : null
}

export const selectProductSnapshotList = (state: RootState): Product[] | null => {
  const vendorSorter = (product) => product.vendor.name.toLowerCase()
  const lineSorter = (product) => product.line.name.toLowerCase()
  const categorySorter = (product) => product.category.toLowerCase()
  const nameSorter = (product) => product.type.toLowerCase()
  return state.products.productsById
    ? orderBy(
        values(state.products.productsById),
        [categorySorter, vendorSorter, lineSorter, nameSorter],
        ['asc', 'asc', 'asc', 'asc'],
      )
    : null
}

export const selectFilteredProductList = (state: RootState): Product[] | null => {
  const property1Sorter = (product) => product.type.toLowerCase()
  const property2Sorter = (product) => product.line.name.toLowerCase()
  const property3Sorter = (product) => product.vendor.name.toLowerCase()
  const products = values(state.products.productsById)
  const filteredProducts = filterProducts(products, state.products.productFilters)

  return state.products.productsById
    ? orderBy(filteredProducts, [property1Sorter, property2Sorter, property3Sorter], ['asc', 'asc', 'asc'])
    : null
}
export const selectNumProducts = (state: RootState): number | null => {
  return state.products.productsById ? keys(state.products.productsById).length : null
}

// this will look like
// {
//   category1: {
//     line1-size1: [{product1}, {product2}]
//     line1-size2: [{product1}, {product2}]
//     line2-size1: [{product1}, {product2}]
//   }
//   category1: {...}
// }
// this is a complex selector and does a lot of work
// when there are 1000s of products they are being filtered in multiple components you are going to have a bad time
// this is a consolidation of the view logic + filtering and should run in ~O(2n) time n being products
export const selectSortedFilteredProductsByCategoryThenByLineSize = (
  state: RootState,
): ProductsByCategoryThenByLineSize | null => {
  if (!state.products.productsById) {
    return null
  }
  const products = values(state.products.productsById)
  const filters = state.products.productFilters
  const productsByCategoryThenByLineSize = mapProductsByCategoryThenByLineSize(products, filters)
  return productsByCategoryThenByLineSize
}

export const selectLineNamesFilteredByVendorNames = (state: RootState): string[] => {
  const vendorFilter = state.products.productFilters.vendorName
  const products = values(state.products.productsById)
  if (vendorFilter) {
    const productsFilteredByVendor = products.filter((product) => product.vendor.name === vendorFilter)
    const filteredLineNames = map(productsFilteredByVendor, (p) => p.line.name)
    return uniq(filteredLineNames)
  }
  const allLineNames = map(products, (p) => p.line.name)
  return uniq(allLineNames)
}

export const selectVendorNames = (state: RootState): string[] => {
  const products = values(state.products.productsById)
  const allNames = map(products, (p) => p.vendor.name)
  return uniq(allNames)
}

export const selectAllCategories = (state: RootState): string[] => {
  const products = values(state.products.productsById)
  const all = map(products, (p) => p.category)
  return orderBy(uniq(all))
}

export const selectIsProductChecked = (state: RootState, productId: number): boolean => {
  return has(state.products.selectedProductsById, productId)
}

export const selectBulkProductSheetVisibility = (state: RootState): boolean => {
  return state.products.bulkProductSheetVisibility
}

export const selectSelectedCategories = (state: RootState): string[] => {
  return state.products.selectedCategories
}

// export
//
export default ProductSlice.reducer
