import { APIEmptyBowl, EmptyBowl } from './interfaces'
import { map } from 'lodash'

export const mapAPIEmptyBowlToEmptyBowl = (api: APIEmptyBowl): EmptyBowl => {
  return {
    id: api.id,
    name: api.name,
    amountInGrams: api.amount_in_grams,
    photoUrl: api.photo_url,
  }
}

export const mapAPIEmptyBowlsToEmptyBowls = (api: APIEmptyBowl[]): EmptyBowl[] => {
  return map(api, mapAPIEmptyBowlToEmptyBowl)
}

export const mapBowlToAPICreateBowl = (model: Partial<EmptyBowl>): Partial<APIEmptyBowl> => {
  return {
    id: model.id || undefined,
    name: model.name || '',
    amount_in_grams: model.amountInGrams || 0,
    photo_url: model.photoUrl || '',
  }
}
