import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'

import { reduceSetSelectedClientId, selectClientList, selectClientPagination } from '../../data/clients/slice'
import { filterClients } from '../../data/clients/utils'
import { dispatchListClients, dispatchSetSelectedClientId } from '../../data/clients/api'
import { CLIENTS_LOADING_CONSTANT, EMPTY_CLIENT } from '../../data/clients/constants'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { ClientList } from './ClientList'
import { ClientDetailsSheet } from './ClientDetailsSheet'
import { LaraPagination } from '../../mini-lib/lara/lara-utils'
import { LoggedInUser } from '../../data/user/interfaces'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { ColorForMode } from '../../theme'
import { EmptySearch } from '../../mini-lib/empty/EmptySearch'
import { UseViewSize } from '../../core/UseViewSize'
import { DebouncedInput } from '../../mini-lib/debounced/DebouncedInput'
import { generatePath, Link } from 'react-router-dom'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { ClientBulkMergeSheetButton } from './bulk-merge/ClientBulkMergeSheet'
import { DisableAutofocus } from '../../mini-lib/disable-autofocus/DisableAutofocus'
import { selectLoadingState } from '../../core/loading/slice'
import { HelpStepper, Step } from '../start-guide/common-components/HelpStepper'
import { HelpPopover } from '../start-guide/common-components/HelpPopover'
import { dispatchListUserChecklistItems, dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import {
  reduceSetCurrentStepCode,
  reduceSetCurrentSubStepIndex,
  reduceSetStepCompleteDrawerState,
  selectChecklistItemsByCode,
  selectStepCompleteDrawerState,
  selectStepsCompleted,
} from '../../data/start-guide/slice'
import { HelpModal } from '../start-guide/common-components/HelpModal'
import { HelpStepCompleteDrawer } from '../start-guide/common-components/HelpStepCompleteDrawer'
import { HelpStepperV2 } from '../start-guide-v2/HelpStepperV2'
import { SalonTypes } from '../../data/salon/interfaces'
import { ReleaseBulkClients, ReleasePageHelp, ReleaseStartGuideV2 } from '../../mini-lib/flags/Release'
import { ROUTES } from '../../appRoutes'
import { HelpDrawer } from "../start-guide/common-components/HelpDrawer";
import { VIDEO_MAP } from "../../mini-lib/video/constants";

export const ClientsPage = (props: { showBreadCrumbs?: boolean }) => {
  const { showBreadCrumbs = true } = props
  const { user, salonId } = UseBaseApiParams()
  const breadcrumbs = showBreadCrumbs
    ? [
        {
          label: 'Home',
          url: generatePath(ROUTES.home, { salonId }),
        },
        { label: 'Clients' },
      ]
    : []
  const helpSteps: Step[] = [
    {
      title: 'Add a client',
      state: 'current',
      link: generatePath(ROUTES.clients, { salonId }) + `?guide=${CHECKLIST_CODES.addClient}`,
    },
  ]

  // initialize state via query params
  let queryParams = UseQueryParams()
  const selectedClientId = queryParams.get('selectedClientId')

  const dispatch = useDispatch()
  const models = useAppSelector(selectClientList)
  const pagination = useAppSelector(selectClientPagination)
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const isLoading = useAppSelector((state) => selectLoadingState(state, CLIENTS_LOADING_CONSTANT))
  const { isMobile } = UseViewSize()

  const [searchText, setSearchText] = useState('')
  const modelsLoaded = !!models

  useEffect(() => {
    if (!modelsLoaded) {
      dispatch(dispatchListClients(user.token, salonId))
    }
  }, [dispatch, modelsLoaded, user.token, salonId])

  const qpSearchText = queryParams.get('search') || ''
  useEffect(() => {
    setSearchText(qpSearchText.toLowerCase().trim())
  }, [dispatch, qpSearchText])

  useEffect(() => {
    dispatch(reduceSetCurrentSubStepIndex(0))
  }, [dispatch])

  useEffect(() => {
    if (selectedClientId) {
      dispatch(dispatchSetSelectedClientId({ selectedClientId }))
    }
  }, [dispatch, selectedClientId])

  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && user.userId) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, user.token, user.userId, checklistItemsLoaded])

  const filteredModels = models ? filterClients(models, searchText) : []
  const releaseStartGuideV2 = ReleaseStartGuideV2()
  const releasePageHelp = ReleasePageHelp()
  const releaseBulkClients = ReleaseBulkClients()
  const previousStepLink = generatePath(ROUTES.sgAddClientsIntro, { salonId })
  const nextStepLink = generatePath(ROUTES.sgIndex, { salonId }) + '?step=team'

  const canAddTeam = user?.currentSalonContext?.salonType === SalonTypes.salon
  const stepCodes = canAddTeam ? [CHECKLIST_CODES.addStylist, CHECKLIST_CODES.addClient] : [CHECKLIST_CODES.addClient]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes)) || 0

  return (
    <PageLayout
      variant="full"
      top={
        <>
          {releasePageHelp && (
            <HelpDrawer
              video={VIDEO_MAP.addingClients}
              title='Let’s take a look inside Adding Clients'
              content={'Clients can be created ahead of time in your business center (where you are now!) or on-the-fly at the beginning of an appointment using the mobile app. Simply add the client’s first and last name and voila, you have created a client! You can bulk upload your clients to our template or Email our Support Team if you have an exported .csv file and we can upload your clients for you.'}
            />
          )}
          {releaseStartGuideV2 ? (
            <HelpStepperV2
              title="Add Your Clients"
              titleIconName="clients"
              currentStepNumber={2}
              stepsComplete={stepsCompleted}
              totalSteps={stepCodes.length}
              previousStepLink={previousStepLink}
              nextStepLink={nextStepLink}
              guideType={CHECKLIST_CODES.addClient}
            />
          ) : (
            <HelpStepper title="Add a client" steps={helpSteps} guideType={CHECKLIST_CODES.addClient} />
          )}
        </>
      }
      header={
        <PageHeader
          showHelp={true}
          title="Clients"
          breadcrumbs={breadcrumbs}
          actions={
            <Flex align="center" gridGap="12px">
              <ClientBulkMergeSheetButton />
              {releaseBulkClients && (
                <Link to={generatePath(ROUTES.clientsBulkUpload, { salonId })}>
                  <Button variant="round-outline" className="cy-client-add" colorScheme="brand.midnight">
                    {isMobile ? 'Bulk Add' : 'Bulk Add Clients'}
                  </Button>
                </Link>
              )}
              <HelpPopover
                description="Click 'Add Client' to get started"
                stepIndex={0}
                guideTypes={[CHECKLIST_CODES.addClient]}
              >
                <Button
                  variant="round"
                  className="cy-client-add"
                  colorScheme="brand.midnight"
                  onClick={() => dispatch(reduceSetSelectedClientId(-1))}
                >
                  {isMobile ? 'Add' : 'Add Client'}
                </Button>
              </HelpPopover>
            </Flex>
          }
        />
      }
      content={
        <>
          <HelpModal
            guideType={CHECKLIST_CODES.addClient}
            iconName="clients"
            title="Add Your Clients"
            subtitle="It's really easy to add clients as you create sessions in app, but if you want to get a head start and add some of them now this is where you will do it!"
          />
          <HelpStepCompleteDrawer
            guideType={CHECKLIST_CODES.addClient}
            title="Way to go, Now you know how to add clients!"
            subtitle="Feel free to add more clients while we are here. When you are ready, click below to go to the next step"
            actions={
              <Flex align="center" gridGap="12px">
                {stepCompleteDrawerState !== 'button' && (
                  <Button
                    variant="round-outline"
                    colorScheme="brand.midnight"
                    onClick={() => dispatch(reduceSetStepCompleteDrawerState('button'))}
                  >
                    Continue Adding Clients
                  </Button>
                )}
                <Link to={releaseStartGuideV2 ? nextStepLink : generatePath(ROUTES.startGuide, { salonId })}>
                  <Button
                    variant="round"
                    colorScheme="brand.midnight"
                    onClick={() => {
                      dispatch(
                        dispatchUpdateUserChecklistItem({
                          token: user.token,
                          checklistItemCode: CHECKLIST_CODES.addClient,
                        }),
                      )
                      dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.learnSessions))
                      dispatch(reduceSetStepCompleteDrawerState('hidden'))
                      dispatch(reduceSetCurrentSubStepIndex(0))
                    }}
                  >
                    Next Step
                  </Button>
                </Link>
              </Flex>
            }
          />
          <PaginatedSearch
            setSearchText={setSearchText}
            value={searchText}
            salonId={salonId}
            user={user}
            pagination={pagination}
          />
          {models && pagination && (
            <>
              <DisableAutofocus />
              {filteredModels && filteredModels.length > 0 ? (
                <ClientList clients={filteredModels} />
              ) : (
                <>{searchText ? <EmptySearch>No Clients Found for "{searchText}"</EmptySearch> : <Empty />}</>
              )}
            </>
          )}
          {(isLoading || !models) && (
            <Flex h={models ? '72px' : '500px'} align="center" justify="center">
              <Spinner />
            </Flex>
          )}
          {!searchText && pagination && pagination.currentPage !== pagination.lastPage && (
            <Flex justify="center" p="12px">
              <Button
                colorScheme="brand.lavender"
                variant="round-outline"
                onClick={() => {
                  dispatch(dispatchListClients(user.token, salonId, pagination.currentPage + 1))
                }}
              >
                View More
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <ClientDetailsSheet />
    </PageLayout>
  )
}

const PaginatedSearch = ( props: {
  value: string
  setSearchText: any
  salonId: number
  user: LoggedInUser
  pagination: LaraPagination | null
} ) => {
  const dispatch = useDispatch()
  const { user, salonId, value, setSearchText } = props
  const onSearch = ( updatedValue ) => {
    setSearchText(updatedValue)
    dispatch(dispatchListClients(user.token, salonId, 1, updatedValue ? updatedValue : ''))
  }
  return (
    <Box p="12px" w='100%'>
      <DebouncedInput value={value} initialValue={value} onValueChange={onSearch} placeholder="Search clients" width='100%'/>
    </Box>
  )
}

const Empty = ( props: {} ) => {
  const dispatch = useDispatch()
  const st = ColorForMode('secondary-text')
  // todo: ask Nicki for base empty state
  const clientsEmpty = 'https://storage.googleapis.com/salonscale-prod-webapp-assets/extensions-empty-state.png'
  return (
    <Flex direction="column" justify="center" align="center">
      <img alt="logo" style={{ height: '380px', maxWidth: '100%', padding: '4px' }} src={clientsEmpty}/>
      <Text variant="title1" fontWeight="bold">
        NO CLIENTS ADDED
      </Text>
      <Box h="24px"/>
      <Text textAlign="center" color={st} maxW="100%" w="640px">
        You can add clients here or later in app!
      </Text>
      <Box h="24px"/>
      <Button
        w="200px"
        variant="round"
        bg="brand.midnight.500"
        onClick={() => dispatch(reduceSetSelectedClientId(EMPTY_CLIENT().id))}
      >
        ADD YOUR FIRST CLIENT
      </Button>
    </Flex>
  )
}
