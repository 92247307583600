import React from 'react'
import { UseCurrentUser } from '../../core/hooks/UseCurrentUser'
import { Flex, Spinner } from '@chakra-ui/react'
import { AuthRedirect } from "./AuthRedirect";
import { Redirect } from "react-router-dom";
import { UseGeneratePathWithQPS } from "../../mini-lib/router/router-utils";
import { ROUTES } from "../../appRoutes";

export const UserRedirectPage = () => {
  const { user, loadingUser } = UseCurrentUser()
  const toLogin = UseGeneratePathWithQPS({ pathname: ROUTES.login })

  if (!user && !loadingUser) {
    // preserves query params, used for redirects coming from GRIN and hubspot links
    return <Redirect to={toLogin} />
  }
  if (!user) {
    return <Flex justify="center" h="100vh" align="center"><Spinner/></Flex>
  }
  return <AuthRedirect user={user}/>
}
