import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { DropdownFilter } from '../../mini-lib/filters/DropdownFilter'
import { values } from 'lodash'
import { DATE_RANGE_OPTION_MAP_WITH_CUSTOM, DateRangeType } from '../../mini-lib/dates-and-times/constants'
import { CustomDateRangeModal } from '../../mini-lib/dates-and-times/CustomDateRangeModal'
import { UseViewSize } from '../../core/UseViewSize'
import { useState } from 'react'
import { useAppSelector } from '../../hooks'
import { reduceSelectedDateRange, selectReportsDateRangeType } from '../../data/reports-v2/slice'
import { useDispatch } from 'react-redux'

export const ReportActions = (props: { onCsvExport: () => void }) => {
  const dispatch = useDispatch()
  const { onCsvExport } = props
  const [customDateRangeOpen, setCustomDateRangeOpen] = useState(false)
  const selectedDateRange = useAppSelector(selectReportsDateRangeType)
  const { isMobile } = UseViewSize()
  const onDateRangeSelect = (option: DateRangeType) => {
    if (option === 'custom') {
      setCustomDateRangeOpen(true)
    } else {
      dispatch(reduceSelectedDateRange(option))
      setCustomDateRangeOpen(false)
    }
  }
  return (
    <Flex align={'center'} justify={'flex-end'} padding="4px" w="100%">
      {!isMobile && <Text>Filter By:</Text>}
      <Box pl={'10px'}>
        <DropdownFilter
          theme='lavender'
          w="150px"
          placeholder="Date Range"
          value={selectedDateRange || null}
          options={values(DATE_RANGE_OPTION_MAP_WITH_CUSTOM)}
          onChange={(option: any) => onDateRangeSelect(option.value)}
        />
      </Box>
      {customDateRangeOpen && <CustomDateRangeModal setIsOpen={setCustomDateRangeOpen} isOpen={customDateRangeOpen} />}
      <Box pl={'10px'} mr="24px">
        <Button variant="round" bg="brand.midnight.500" onClick={onCsvExport}>
          EXPORT CSV
        </Button>
      </Box>
    </Flex>
  )
}
