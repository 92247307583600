import { useRef, useEffect, useState, RefObject, useMemo } from "react";
import { useLocation } from 'react-router-dom'
import { keys, throttle } from 'lodash'
import { format, differenceInMilliseconds } from "date-fns";

// ref - https://stackoverflow.com/a/50940452/10795885
// to use this just add id='derp' and then call it like (click)="scrollToId('derp')
export function scrollToId(id: string): void {
  const element = document.getElementById(id)
  element && element.scrollIntoView({ block: 'start', behavior: 'smooth' })
}


// check if an element is inside a sidescroll window
// ref: https://stackoverflow.com/a/61719846
// offset - number of pixels up to the observable element from the top
export function UseSideScrollVisibility<Element extends HTMLElement>(
  offset = 0,
  throttleMilliseconds = 100,
  initialValue = false
): [Boolean, RefObject<Element>] {
  const [isVisible, setIsVisible] = useState(initialValue);
  const currentElement: any = useRef<Element>();

  const onScroll = throttle(() => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }
    const left = currentElement.current.getBoundingClientRect().left;
    setIsVisible(left + offset >= 0 && left - offset <= window.innerWidth);
  }, throttleMilliseconds);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });
  return [isVisible, currentElement];
}


export function kebabCase(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export function capitalCase(s: string): string {
  return (s && s[0].toUpperCase() + s.slice(1).toLowerCase()) || ''
}

export const UseQueryParams = (): any => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

export interface ParamToUpdate {
  paramKey: string
  paramValue: string | number | null
  action: 'add' | 'delete'
}

export const updateQueryParams = (history: any, location: any, queryParamsToUpdate: ParamToUpdate[]) => {
  const queryParams = new URLSearchParams(location.search)
  queryParamsToUpdate.forEach((qp) => {
    if (qp.action === 'add') {
      queryParams.set(qp.paramKey, qp.paramValue?.toString() || '')
    }
    if (qp.action === 'delete') {
      queryParams.delete(qp.paramKey)
    }
  })
  history.replace({
    search: queryParams.toString(),
  })
}

export const addNumberSuffix = (number: number): number | string | null => {
  if (isNaN(number)) return null // will only work value is a number
  if (number === null) return null
  if (number === 0) return 0
  number = Math.round(number * 100) / 100
  let abs = Math.abs(number)
  const rounder = Math.pow(10, 1)
  const isNegative = number < 0 // will also work for Negative numbers
  let key = ''

  const powers = [
    { key: 'Q', value: Math.pow(10, 15) },
    { key: 'T', value: Math.pow(10, 12) },
    { key: 'B', value: Math.pow(10, 9) },
    { key: 'M', value: Math.pow(10, 6) },
    { key: 'K', value: 1000 },
  ]

  for (let i = 0; i < powers.length; i++) {
    let reduced = abs / powers[i].value
    reduced = Math.round(reduced * rounder) / rounder
    if (reduced >= 1) {
      abs = reduced
      key = powers[i].key
      break
    }
  }
  return (isNegative ? '-' : '') + abs + key
}

export const flattenMapOfListsToList = (mapOfLists: { [key: string]: any[] }): any[] => {
  const returnList: any[] = []
  keys(mapOfLists).forEach((key) => {
    returnList.push(...mapOfLists[key])
  })
  return returnList
}

// export const addDollarSign = (val) => `$` + val
export const addDollarSign = (val: string | null): string => {
  if (val) {
    return `$` + val
  }
  return ''
}
export const removeDollarSign = (val) => val.replace(/^\$/, '')

export const addPercent = (val) => (val ? `${val}%` : 0)
export const removePercent = (val) => val.replace(/%/, '')

export const timeAgo = (date: Date) => {
  const dateNow: any = new Date();

  const formattedDateNow = format(dateNow, "yyyy-MM-dd HH:mm:ss");

  const millisecondsDiff = differenceInMilliseconds(
    new Date(formattedDateNow),
    new Date(date)
  );
  const seconds = Math.floor(millisecondsDiff / 1000);

  let interval = Math.floor(seconds / 31536000);

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' mos';
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' d';
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' hr';
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' min';
  }

  if (seconds < 10) return 'now';

  return Math.floor(seconds) + ' sec';
};