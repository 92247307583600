/////////////// slice.ts
//
//

// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, keyBy, omit, orderBy, values } from 'lodash'

import { RootState } from '../../store'
import { Supply } from './interfaces'

// state
//
interface SupplyState {
  suppliesById: { [key: string]: Supply } | null
}

const initialState: SupplyState = {
  suppliesById: null, // undefined is an accident, null is a choice, this lets us know when something is loading
}

// reducer
//
export const SupplySlice = createSlice({
  name: 'supplies',
  initialState,
  reducers: {
    reduceListSupply: (state, action: PayloadAction<Supply[]>) => {
      state.suppliesById = assign({}, state.suppliesById, keyBy(action.payload, 'id'))
    },
    reduceCreateSupply: (state, action: PayloadAction<Supply[]>) => {
      state.suppliesById = assign({}, state.suppliesById, keyBy(action.payload, 'id'))
    },
    reduceUpdateSupply: (state, action: PayloadAction<Supply[]>) => {
      state.suppliesById = assign({}, state.suppliesById, keyBy(action.payload, 'id'))
    },
    reduceDeleteSupply: (state, action: PayloadAction<Supply>) => {
      state.suppliesById = omit(state.suppliesById, action.payload.id)
    },
    reduceDeleteSupplies: (state, action: PayloadAction<Supply[]>) => {
      const supplyIdsToRemove = action?.payload?.map((s) => s.id)
      state.suppliesById = omit(state.suppliesById, supplyIdsToRemove)
    },
  },
})

// actions
//
export const { reduceListSupply, reduceCreateSupply, reduceUpdateSupply, reduceDeleteSupply, reduceDeleteSupplies } = SupplySlice.actions

// selectors
//
export const selectSuppliesById = (state: RootState) => state.supplies.suppliesById
export const selectSupplyList = (state: RootState): Supply[] | null => {
  const property1Sorter = (supply) => supply.name.toLowerCase()
  const property2Sorter = (supply) => supply.priceDollars
  return state.supplies.suppliesById
    ? orderBy(values(state.supplies.suppliesById), [property1Sorter, property2Sorter], ['asc', 'asc'])
    : null
}

// export
//
export default SupplySlice.reducer
