

// a simple function to wrap the create react app env variables
// to move to a different build system hopefully we just update where this function points
//
// note:  if we need to make a hook for this and use it at the top level ideally we just keep this as is
//        inject this into a new hook instead of refactoring it
//        this is necessary because we use this in service methods which dont have access to the dom
export const getEnvConfig = (): {
  env: string | any
  isProd: boolean
  angularHost: string | any
  serverHost: string | any
  chargebeeEnv: string | any
  launchDarklyKey: string | any
  mixPanelKey: string | any
  squareHost: string | any
  squareClientId: string | any
  squareAuthUrl: string | any
  smartlookKey: string | any
} => {
  return {
    env: process.env.REACT_APP_ENVIRONMENT, // local | production
    isProd: process.env.REACT_APP_IS_PRODUCTION === 'true', // 'true' | 'false'
    angularHost: process.env.REACT_APP_ANGULAR_HOST, // http://localhost:4200
    serverHost: process.env.REACT_APP_SERVER_HOST, // https://stage.salonscale.com
    chargebeeEnv: process.env.REACT_APP_CHARGEBEE, // salonscale-test
    launchDarklyKey: process.env.REACT_APP_LAUNCHDARKLY, // asdf1234
    mixPanelKey: process.env.REACT_APP_MIXPANEL, // asdf1234
    squareHost: process.env.REACT_APP_SQUARE_BASE_URL, // https://connect.squareupsandbox.com
    squareClientId: process.env.REACT_APP_SQUARE_CLIENT_ID, // sandbox-asdf1234-asdf1324
    squareAuthUrl: process.env.REACT_APP_SQUARE_AUTHORIZE_URL, // oauth2/authorize?
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY, // asdf1234
  }
}
