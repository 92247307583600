import { InventoryCount, InventoryCountItem } from '../../../data/inventory/interfaces'
import { Box, Divider, Flex, Select, Text } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import React from 'react'
import { SalonScaleProductIcon } from '../../../mini-lib/icons/SalonScaleProductIcon'
import { Loading } from '../../../mini-lib/loading/Loading'
import { values } from 'lodash'
import { INVENTORY_AUDIT_REASONS } from '../../../data/inventory/constants'
import { UseViewSize } from '../../../core/UseViewSize'
import { buildLoadingName } from '../../../core/loading/utils'
import { useAppSelector } from '../../../hooks'
import { selectLoadingState } from '../../../core/loading/slice'
import { HelpPopover } from '../../start-guide/common-components/HelpPopover'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { getHelpStepIndex } from '../../../data/start-guide/utils'
import { InventoryQuantityField } from '../common/InventoryQuantityField'
import { centsToDollars } from '../../../core/money/utils'
import { ReleaseUnitsSupport } from "../../../mini-lib/flags/Release";

export const AuditTitle = (props: { count: InventoryCount }) => {
  return (
    <>
      <Flex p="24px 0" justify="space-between">
        {/* product column */}
        <Box w="250px" minW="250px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Product
          </Text>
        </Box>

        {/* brand column */}
        <Box w="120px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Brand/Line
          </Text>
        </Box>

        {/* expected column */}
        <Flex justify="center" w="120px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Expected
          </Text>
        </Flex>

        {/* actual count column */}
        <Flex justify="center" w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Actual Count
          </Text>
        </Flex>

        {/* reason column */}
        <Flex justify="center" w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Reason
          </Text>
        </Flex>
        <Flex justify="center" w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Staff
          </Text>
        </Flex>
      </Flex>
      <Divider />
    </>
  )
}
export const AuditItemRow = (props: {
  count: InventoryCount
  item: InventoryCountItem
  quantity: number | string | undefined
  onUpdateQuantity: any
  onUpdateReason: any
  setQuantity: any
  index: number
}) => {
  const { item, count, quantity, setQuantity, onUpdateQuantity, onUpdateReason, index } = props
  const { isMobile } = UseViewSize()
  const showColoredSelect = item.reason && item.reason !== 'received'
  const loadingReasonName = buildLoadingName(`inventory-reason-${item.id}`)
  const loadingReason = useAppSelector((state) => selectLoadingState(state, loadingReasonName))
  const releaseUnitsSupport = ReleaseUnitsSupport();
  const staticUnit = 'g or ml'
  const units = releaseUnitsSupport ? item.product?.units ?? staticUnit : staticUnit
  const isDisabled = !!count.completedAt
  return (
    <>
      <Flex
        p="24px 0"
        justify={isMobile ? 'flex-end' : 'space-between'}
        wrap={isMobile ? 'wrap' : 'nowrap'}
        gridGap={isMobile ? '12px' : ''}
      >
        {/* product column */}
        <Box w={!isMobile ? '250px' : '100%'} minW={!isMobile ? '250px' : ''} pr="12px">
          <Flex gridGap="12px">
            <SalonScaleProductIcon name={item.product.category} />
            <Box>
              <Text variant="body">{item.product.type}</Text>
              <Text variant="caption2" fontWeight="normal" color={COLORS.text_secondary}>
                {item.product.size}{units} • ${centsToDollars(item.product.salonPurchasePriceCents)}
              </Text>
            </Box>
          </Flex>
        </Box>

        {/* brand column */}
        {!isMobile && (
          <Flex w="120px" direction="column" justify="center">
            <Text>{item.product.vendor}</Text>
            <Text color={COLORS.shades_neutral_500} variant="footnote">
              {item.product.line}
            </Text>
          </Flex>
        )}

        {/* expected column */}
        {!isMobile && (
          <Flex w="120px" align="center" justify="center">
            {item.expectedCount}
          </Flex>
        )}

        {/* actual count column */}
        <HelpPopover
          guideTypes={[CHECKLIST_CODES.inventoryCompleteCount]}
          children={
            <Flex align='center'>
              <InventoryQuantityField
                item={item}
                quantity={quantity}
                onUpdateQuantity={onUpdateQuantity}
                setQuantity={setQuantity}
                isDisabled={isDisabled}
              />
            </Flex>
          }
          description="Enter your current amount of the product on you shelf. try to estimate if there is 1/4, 1/2 or 3/4 of  a tube the best you can. "
          stepIndex={getHelpStepIndex(index, 0)}
          placement="left"
        />
        {/* reason column */}
        <Flex justify="center" w="150px" align="center">
          {loadingReason ? (
            <Loading />
          ) : (
            <HelpPopover
              guideTypes={[CHECKLIST_CODES.inventoryCompleteCount]}
              children={
                <Select
                  isDisabled={isDisabled}
                  bg={showColoredSelect ? COLORS.lavender_100 : ''}
                  color={showColoredSelect ? COLORS.lavender_500 : ''}
                  borderColor={showColoredSelect ? COLORS.lavender_100 : COLORS.shades_neutral_300}
                  placeholder="none"
                  value={item.reason || ''}
                  borderRadius="100px"
                  onChange={(e: any) => onUpdateReason(e.target.value)}
                >
                  {values(INVENTORY_AUDIT_REASONS).map((option: any, index) => {
                    return (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    )
                  })}
                </Select>
              }
              description="If your count is off you can add a reason."
              stepIndex={getHelpStepIndex(index, 1)}
              placement="left"
            />
          )}
        </Flex>

        {/* expected column */}
        {!isMobile && (
          <Flex w="150px" align="center" justify="center">
            {count?.assignedUser?.firstName}
          </Flex>
        )}
      </Flex>
      <Divider />
    </>
  )
}
