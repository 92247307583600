import { Box, Flex, Image } from '@chakra-ui/react'
import scheduledMaintenance from '../assets/scheduled-maintenance-lottie.json'
import Lottie from 'react-lottie-player'
import { COLORS } from '../mini-lib/theme/colors'
import { SALONSCALE_LOGO_URL } from '../data/constants'
import { UseViewSize } from '../core/UseViewSize'

export const ScheduledMaintenancePage = () => {
  const { isMobile } = UseViewSize()
  return (
    <Flex direction="column" align="center" justify="center" minH="100vh" minW="100vw" bg={COLORS.lavender_100}>
      <Box h="24px" />
      <Flex gridGap="12px">
        <Image src={SALONSCALE_LOGO_URL} h={isMobile ? '40px' : '80px'} />
        <Image
          src="https://storage.googleapis.com/salonscale-prod-webapp-assets/salonscale-text-logo-navy.png"
          h={isMobile ? '40px' : '80px'}
        />
      </Flex>
      <Box h="24px" />
      <Lottie loop animationData={scheduledMaintenance} play style={{ maxWidth: '100%' }} />
      <Box h="24px" />
      <Box textAlign="center">Our system is currently unavailable due to scheduled maintenance.</Box>
      <Box h="24px" />
      <Box textAlign="center">We appologize for any inconvenience.</Box>
      <Box h="24px" />
      <Box color={COLORS.lavender_500}>Come back soon!</Box>
    </Flex>
  )
}
