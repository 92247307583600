import {Accordion, AccordionItem} from '@chakra-ui/react'
import {StepGroupAccordionHeader} from '../common-components/StepGroupAccordionHeader'
import {StepGroupAccordionPanel} from '../common-components/StepGroupAccordionPanel'
import {StepAccordionHeader} from '../common-components/StepAccordionHeader'
import {StepAccordionPanel} from '../common-components/StepAccordionPanel'
import {selectChecklistItem, selectCurrentStepCode, selectStepsCompleted} from '../../../data/start-guide/slice'
import {CHECKLIST_CODES, CHECKLIST_GROUPS} from '../../../data/start-guide/constants'
import {useAppSelector} from '../../../hooks'
import {COLORS} from '../../../mini-lib/theme/colors'
import {findIndex} from 'lodash'
import React from 'react'
import {AcademyLinks} from '../../start-guide-v2/SGAcademyIntroPage'

export const ChecklistGroup4 = () => {
  const stepCodes = CHECKLIST_GROUPS.group4
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  const currentStepCode = useAppSelector(selectCurrentStepCode)
  const stepIndex = findIndex(CHECKLIST_GROUPS.group4, (item) => item === currentStepCode)
  return (
    <AccordionItem p={0} m="0 0 24px 0" border="none">
      <StepGroupAccordionHeader
        initialStepCode={stepCodes[0]}
        iconName="implementation"
        title="Implementation"
        stepsCompleted={stepsCompleted ? stepsCompleted : 0}
        stepsLength={stepCodes.length}
      />

      <StepGroupAccordionPanel
        steps={
          <Accordion
            allowToggle
            p={0}
            index={stepIndex}
            border={`2px solid ${COLORS.lavender_100}`}
            borderRadius="0 0 15px 15px"
          >
            <SalonscaleAcademy/>
          </Accordion>
        }
      />
    </AccordionItem>
  )
}

const SalonscaleAcademy = () => {
  const stepCode = CHECKLIST_CODES.learnAcademy
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))
  const description = `Congratulations, you are ready to use SalonScale!

Remember: we are always here to help! From scripts to tips to downloads and more, we have resources to help you feel confident in your implementation within your salon.

Here are a few places we suggest checking out:

SalonScale Business Playbook
  • Your business meets SalonScale. We dig into questions like how to find your breakeven point and tips on pricing, transparency and other financial tips!

SalonScale Academy
  • Here you will find scripts for talking to your team and clients, downloadables and other resources to make you feel confident about SalonScale

Product Question Library
  • Here you will find answers to everything in the product and videos to help you navigate along the way 
`
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        checklistItem={checklistItem}
        label="1"
        title="Successfully Implementing SalonScale"
        actions={<></>}
      />
      <StepAccordionPanel
        stepCode={stepCode}
        description={description}
        actions={
          <AcademyLinks/>
        }
      />
    </AccordionItem>
  )
}
