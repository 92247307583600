import { Select } from '@chakra-ui/react'
import { values } from 'lodash'
import { DATE_RANGE_OPTION_MAP, DateRangeType } from './constants'

export const DateRangeSelect = (props: {
  onChange: (DateRangeType) => void
  initialDateRangeType?: DateRangeType
  options?: { [key: string]: { label: string; value: string } }
}) => {
  const { initialDateRangeType, onChange, options = DATE_RANGE_OPTION_MAP } = props

  return (
    <Select defaultValue={initialDateRangeType} borderRadius="100px" onChange={(e: any) => onChange(e.target.value)}>
      {values(options).map((option: any, index) => {
        return (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        )
      })}
    </Select>
  )
}
