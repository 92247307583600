import { LoggedInUser, LoggedInUserSalonPermissions } from '../../data/user/interfaces'
import {
  hasActiveQuickTrialAndNoSubscription,
  hasExpiredQuickTrialAndNoSubscription,
  hasNoQuickTrialAndNoSubscription,
} from '../../data/salon/utils'
import { generatePath } from 'react-router-dom'

import { ROUTES } from '../../appRoutes'
import { hasInactiveSubscription, isStylistUser } from '../../data/user/utils'

export const getRedirectUrl = (params: {
  user: LoggedInUser | null
  userPermissions: LoggedInUserSalonPermissions | null
  finishedGuide: boolean | null
  releaseQuickTrial: boolean
  releaseTrialV3: boolean
  redirectUrl?: string | null
}): string | null => {
  const { user, userPermissions, finishedGuide, releaseQuickTrial, releaseTrialV3, redirectUrl } = params

  if (!user) {
    return null
  }

  if (redirectUrl) {
    return redirectUrl
  }

  if (user.isSuperuser) {
    return 'admin'
  }

  if (!user.salonContexts || user.salonContexts.length === 0) {
    const url = releaseQuickTrial ? generatePath(ROUTES.signupV2Salon) : generatePath(ROUTES.signupPlans)
    return url
  }

  if (user.currentSalonContext && hasActiveQuickTrialAndNoSubscription(user.currentSalonContext) && !releaseTrialV3) {
    const url = generatePath(ROUTES.trialIndex, { salonId: user.currentSalonContext.salonId })
    return url
  }

  if (user.currentSalonContext && hasActiveQuickTrialAndNoSubscription(user.currentSalonContext) && releaseTrialV3) {
    const url = finishedGuide
      ? generatePath(ROUTES.home, { salonId: user.currentSalonContext.salonId })
      : generatePath(ROUTES.startGuideV2, { salonId: user.currentSalonContext.salonId })
    return url
  }

  if (user.currentSalonContext && hasExpiredQuickTrialAndNoSubscription(user.currentSalonContext)) {
    // note: leaving this here in case we decide we want to go to a different page upon expiry
    const url = generatePath(ROUTES.trialIndex, { salonId: user.currentSalonContext.salonId })
    return url
  }
  if (user.currentSalonContext && hasNoQuickTrialAndNoSubscription(user.currentSalonContext)) {
    const url = releaseQuickTrial ? generatePath(ROUTES.signupV2Type) : generatePath(ROUTES.signupPlans)
    return url
  }

  if (user.currentSalonContext && hasInactiveSubscription(user.currentSalonContext)) {
    const url = generatePath(ROUTES.settings, { salonId: user.currentSalonContext.salonId }) + '?tab=subscription'
    return url
  }

  if (user.currentSalonContext && userPermissions && isStylistUser(userPermissions)) {
    const url = generatePath(ROUTES.sessions, { salonId: user.currentSalonContext.salonId })
    return url
  }
  // todo: add page for stylist with inactive subscription
  //
  //

  if (user.currentSalonContext && finishedGuide) {
    const url = generatePath(ROUTES.home, { salonId: user.currentSalonContext.salonId })
    return url
  }
  if (user.currentSalonContext && !finishedGuide) {
    const url = generatePath(ROUTES.sgIndex, { salonId: user.currentSalonContext.salonId })
    return url
  }

  return null
}
