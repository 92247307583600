import { Dispatch } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { GetServerBaseUrl } from '../../env'
import { PermissionSetting } from './interfaces'
import { reduceListPermissionSetting, reduceUpdatePermissionSetting } from './slice'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { LoggedInUserSalonContext } from '../user/interfaces'
import { dispatchGetUserLara } from '../user/api'
import { reduceSquareConnectionResponse } from "../integrations/slice";

export const apiListPermissionSettings = (token: string, salon_id: number): Promise<PermissionSetting[]> => {
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salon_id}/roles?token=${token}`

  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: PermissionSetting[] }>) => {
      return response.data.data
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdatePermissionSetting = (params: {
  token: string
  salon_id: number
  role_id: number
  permissionName: string
  hasAccess: boolean
}): Promise<PermissionSetting> => {
  const { token, salon_id, role_id, permissionName, hasAccess } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salon_id}/roles/${role_id}?token=${token}`
  const body = {
    permission: permissionName,
    access: hasAccess,
  }

  return axios
    .patch(url, body)
    .then((response: AxiosResponse<{ data: PermissionSetting }>) => {
      return response.data.data
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchListPermissionSettings = (token: string, salon_id: number) => {
  return (dispatch: Dispatch) => {
    return apiListPermissionSettings(token, salon_id).then((resp) => {
      dispatch(reduceListPermissionSetting(resp))
    })
  }
}

export const dispatchUpdatePermissionSetting = (params: {
  token: string
  salon_id: number
  role_id: number
  permissionName: string
  hasAccess: boolean
  loadingKey: string
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: params.loadingKey, state: true }))
    return apiUpdatePermissionSetting(params)
      .then((resp) => {
        dispatch(reduceUpdatePermissionSetting(resp))
        dispatch(reduceSetLoadingState({ name: params.loadingKey, state: false }))
      })
      .catch((error) => {
        throw error
      })
  }
}

export const apiDisconnectSquare = async ({ token, salonId }: { token: string, salonId: number }): Promise<void> => {
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/bookings/revokeOAuthToken/?token=${token}`
  return axios
    .get(url)
    .then((_) => { })
    .catch((error) => {
      throw error
    })
}


export const apiConnectSquare = ( params: {authorizationCode: string, salonId: number, token: string}): Promise<void> => {
    const {authorizationCode, salonId, token} = params
    const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/bookings/obtainOAuthToken/?token=${token}&code=${authorizationCode}`
    return axios
      .get(url)
      .then((_) => { })
      .catch((error) => {
        throw error
      })
}

export const dispatchDisconnectSquare = (params: {
  token: string
  userId: number
  salonId: number
  currentSalonContext: LoggedInUserSalonContext
  loadingKey: string
}) => (dispatch: Dispatch | any) => {
  dispatch(reduceSetLoadingState({ name: params.loadingKey, state: true }))
  return apiDisconnectSquare(params)
    .then((_) => {
      dispatch(dispatchGetUserLara(params))
      dispatch(reduceSetLoadingState({ name: params.loadingKey, state: false }))
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchConnectSquare = (params: {
  token: string
  userId: number,
  salonId: number,
  authorizationCode: string,
  loadingKey: string
}) => (dispatch: Dispatch | any) => {
  dispatch(reduceSetLoadingState({ name: params.loadingKey, state: true }))
  return apiConnectSquare(params)
    .then((_) => {
      dispatch(dispatchGetUserLara(params))
      dispatch(reduceSquareConnectionResponse('success'))
      dispatch(reduceSetLoadingState({ name: params.loadingKey, state: false }))
    })
    .catch((error) => {
      const extractedMessage = error?.response?.data?.message
      if (extractedMessage.includes("Authorization code is expired")) {
        dispatch(reduceSquareConnectionResponse('Authorization code is expired'))
      } else if (extractedMessage.includes("Authorization code is already claimed")) {
        dispatch(reduceSquareConnectionResponse('success'))
      } else {
        dispatch(reduceSquareConnectionResponse('Square Failed to Connect'))
      }
      dispatch(reduceSetLoadingState({ name: params.loadingKey, state: false }))
      throw error
    })
}
