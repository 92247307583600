import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { reduceSetProductFilters, selectProductFilters } from '../../../data/products/slice'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { useEffect } from 'react'

export const UseInitializeProductFiltersFromQueryParams = () => {
  const dispatch = useDispatch()
  const filters = useAppSelector(selectProductFilters)

  const queryParams = UseQueryParams()

  const qpSearchText = queryParams.get('search') || ''
  useEffect(() => {
    dispatch(reduceSetProductFilters({ ...filters, ...{ searchText: qpSearchText.toLowerCase().trim() } }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, qpSearchText])

  return
}