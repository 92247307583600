import { useState } from 'react'
import { Button, Circle, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { SalonScaleIcon } from '../../../mini-lib/icons/SalonScaleIcon'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { Gap } from '../../../mini-lib/gap/Gap'
import { ThemeType } from "../../../theme";

export const HelpModal = (props: {
  title: string
  guideType: string
  subtitle?: string
  iconName?: string
  closeText?: string
  theme?: ThemeType
  closeButtons?: any
}) => {
  const {
    closeButtons,
    guideType,
    iconName = 'products',
    title,
    subtitle = '',
    closeText = "Let's Go!",
    theme = 'midnight'
  } = props
  const [isOpen, setIsOpen] = useState(true)
  const queryParams: any = UseQueryParams()
  const guideParam = queryParams.get('guide')
  const isGuide = guideParam === guideType
  return (
    <>
      {isGuide && (
        <Modal onClose={() => setIsOpen(false)} size="xl" isOpen={isOpen} isCentered={true}>
          <ModalOverlay />
          <ModalContent m="12px">
            <ModalBody>
              <Flex justify="center" align="center" direction="column" p="48px 0" textAlign='center'>
                <Circle bg="brand.lavender.100" p="12px">
                  <SalonScaleIcon name={iconName} size="48px" />
                </Circle>
                <Gap s="24px" />
                <Text variant="title1" fontWeight="bold">
                  {title}
                </Text>
                <Gap s="24px" />
                <Text variant="subtitle">{subtitle}</Text>
                <Gap s="24px" />
                {closeButtons ? (
                  closeButtons
                  
                ) : (
                  <Button variant="round" colorScheme={`brand.${theme}`} onClick={() => setIsOpen(false)} w="220px">
                    {closeText}
                  </Button>
                )}
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
