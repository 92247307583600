import { Box, Button, Divider, Flex, Img, Text } from "@chakra-ui/react";
import { SignupFormLayout } from "./SignupFormLayout";
import { Gap } from "../../../mini-lib/gap/Gap";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks";
import { UseBaseApiParams } from "../../../core/UseBaseApiParams";
import { useDispatch } from "react-redux";
import { dispatchListVendors } from "../../../data/products/api";
import { SearchFilter } from "../../../mini-lib/filters/SearchFilter";
import { COLORS } from "../../../mini-lib/theme/colors";
import { FONTS } from "../../../mini-lib/theme/fonts";
import { BlurBottom, BlurScrollContainer } from "../../../mini-lib/blur/Blur";
import { dispatchAddMasterColorsLara } from "../../../data/master-products/api";
import { LOADING_MASTER_PRODUCTS } from "../../../data/master-products/constants";
import { selectVendors } from "../../../data/master-products/slice";
import { MaterialIcon } from "../../../mini-lib/icons/MaterialIcon";
import { generatePath, Redirect } from "react-router-dom";
import { ROUTES } from "../../../appRoutes";
import { maybePluralize } from "../../../core/text";
import { getVendorsForIds } from "../../../data/signup/utils";
import { POPULAR_VENDOR_IDS } from '../../../data/signup/constants'
import { VendorLara } from "../../../data/products/interfaces";
import { without } from "lodash";
import { Banner } from "../../../mini-lib";
import { toast } from "react-toastify";

export const SignupAddColorsPage = () => {
  return (
    <SignupFormLayout stepNumber={4} totalSteps={4}>
      <Flex justify='center' align='center' direction='column'>
        <Gap/>
        <Text p='0 24px' textAlign='center' variant='title2-desktop-bold'>What is the primary color brand you use?</Text>
        <Gap s='6px'/>
        <Text variant='callout-desktop-regular'>Choose up to four brands.</Text>
        <Gap s='32px'/>
        <Flex justify='center'>
          <Box w='350px'>
            <AddColorsByVendor/>
          </Box>
        </Flex>

      </Flex>
    </SignupFormLayout>
  )
}


export const AddColorsByVendor = () => {
  const dispatch = useDispatch()
  const vendors = useAppSelector(selectVendors)
  const { user } = UseBaseApiParams()
  const salonId = user?.currentSalonContext?.salonId
  const vendorsLoaded = !!vendors
  const [searchText, setSearchText] = useState('')
  const [selectedVendors, setSelectedVendors] = useState<VendorLara[]>([])
  const selectedVendorIds = selectedVendors.map(v => v.id)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!vendorsLoaded && user.token) {
      dispatch(dispatchListVendors({token: user.token}))
    }
  }, [dispatch, user.token, vendorsLoaded])
  const loadingName = LOADING_MASTER_PRODUCTS
  const onContinue = () => {
    if (selectedVendors.length > 0 && salonId) {

      dispatch(
        dispatchAddMasterColorsLara({
          token: user.token,
          salonId,
          vendorIds: selectedVendors.map(v => v.id),
          loadingName,
        }),
      )
      setSubmitted(true)
    }
  }
  const hasFilters = searchText !== ''
  const popularVendors = vendors ? getVendorsForIds(POPULAR_VENDOR_IDS, vendors) : null
  const filteredVendors = vendors?.filter(vendor => vendor.name.toLowerCase().includes(searchText.toLowerCase()))

  if (submitted) {
    return <Redirect to={generatePath(ROUTES.signupV2SuccessTrial)}/>
  }

  const onVendorSelect = (vendor: VendorLara) => {
    const isSelected = selectedVendorIds.includes(vendor.id)
    if (selectedVendorIds.length >= 4 && !isSelected) {
      toast.warning('Only four brands can be selected')
      return
    }
    if (isSelected) {
      setSelectedVendors(without(selectedVendors, vendor))
    } else {
      setSelectedVendors([...selectedVendors, vendor])
    }
  }
  return (
    <Box pb='24px' w='100%'>
      <SearchFilter
        width='100%'
        placeholder="Search"
        value={searchText}
        onChange={( searchText ) => setSearchText(searchText)}
        onClear={() => {
          setSearchText('')
        }}
      />
      <SelectedVendors
        selectedVendors={selectedVendors}
        onVendorSelect={onVendorSelect}
      />
      <Gap/>
      <BlurScrollContainer>
        <Flex w='100%' gridGap='12px' direction='column' h='350px' overflow='scroll'>
          {!hasFilters && <Banner content={<Text variant='title2-desktop-bold' fontStyle='normal'>Popular Brands</Text>}/>}
          {!hasFilters && popularVendors && popularVendors.map(vendor => {
            const isSelected = selectedVendorIds.includes(vendor.id)
            return (
              <Box key={vendor.id} onClick={() => onVendorSelect(vendor)}>
                <VendorRow  vendor={vendor} isSelected={isSelected}/>
              </Box>
            )
          })}
          {!hasFilters && <Divider/>}
          <Banner content={<Text variant='title2-desktop-bold' fontStyle='normal'>All Brands</Text>}/>
          {filteredVendors && filteredVendors.map(vendor => {
            const isSelected = selectedVendorIds.includes(vendor.id)
            return (
              <Box key={vendor.id} onClick={() => onVendorSelect(vendor)}>
                <VendorRow  vendor={vendor} isSelected={isSelected}/>
              </Box>
            )
          })}
        </Flex>
        <BlurBottom/>
      </BlurScrollContainer>
      <Box p='0 12px'>
        <Button
          disabled={selectedVendors.length === 0}
          variant='round-large'
          colorScheme='brand.midnight'
          w='100%'
          onClick={() => onContinue()}
        >
          Continue With {selectedVendors.length > 0 && selectedVendors.length} {maybePluralize('Brand', selectedVendors.length)}
        </Button>
      </Box>
    </Box>

  )
}

export const VendorRow = (props: { vendor: VendorLara; isSelected: boolean }) => {
  const { vendor, isSelected } = props
  return (
    <Box key={vendor.id} border={'1.5px solid white'} borderRadius="12px" p="4px" cursor="pointer">
      <Flex w="100%" justify="space-between" align="center">
        <Flex align="center" gridGap="12px">
          <VendorLogo vendor={vendor} />
          <Text fontSize="16px" fontFamily={FONTS.openSans}>
            {vendor.name}
          </Text>
        </Flex>
        <MaterialIcon colorhex={COLORS.lavender_500} name={isSelected ? 'check_box' : 'check_box_outline_blank'} />
      </Flex>
    </Box>
  )
}
const VendorLogo = ( props: {vendor: VendorLara} ) => {
  const { vendor } = props
  return (
    <>
      {vendor.logoUrl ? (
        <Flex
          maxW='50px'
          maxH='50px'
          w='50px'
          h='50px'
          justify='center'
          align='center'
          borderRadius='50%'
          border={`1px solid ${COLORS.midnight_900}`} p='4px'
        >
          <Img

            // w='auto'
            // h='100%'
            m='4px'
            objectFit='cover'
            alt="vendor"
            key={vendor.id}
            src={vendor.logoUrl ? vendor.logoUrl : ''}
          />
        </Flex>
      ) : (
        <Flex maxW='50px' maxH='50px' w='50px' h='50px' justify='center' align='center' borderRadius='50%'
              border={`1px solid ${COLORS.midnight_900}`} p='4px' bg={COLORS.lavender_50}>
          {vendor.name[0]}
        </Flex>
      )}
    </>
  )
}

export const SelectedVendors = (props: {
  selectedVendors: VendorLara[],
  onVendorSelect: (vendor: VendorLara) => void}) => {
  const {selectedVendors, onVendorSelect} = props
  return (
    <>
      {selectedVendors.length > 0 && (
        <>
          <Gap />
          <Flex overflowX="scroll">
            {selectedVendors.map((vendor) => {
              return (
                <Flex
                  key={vendor.id}
                  onClick={() => onVendorSelect(vendor)}
                  w="60px"
                  h="60px"
                  position="relative"
                  align="center"
                  justify="center"
                  cursor="pointer"
                >
                  <Box bg="white" borderRadius="50%" w="24px" h="24px" position="absolute" top="0" right="-1">
                    <MaterialIcon name="cancel" colorhex={COLORS.lavender_500} />
                  </Box>
                  <VendorLogo vendor={vendor} />
                </Flex>
              )
            })}
          </Flex>
          <Gap />
          <Divider />
        </>
      )}
    </>
  )
}

