import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { Box, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react'
import { COLORS } from '../theme/colors'
import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import { UseViewSize } from '../../core/UseViewSize'
import { ThemeType } from '../../theme'
import { BASE_INPUT_STYLES } from "../../styles";

export const SearchFilter = (props: {
  value?: string
  onChange: (string) => void
  buttonClickToSearch?: boolean
  onClear?: () => void
  placeholder?: string
  theme?: ThemeType
  width?: string
  debounceTime?: number
  minSearchLength?: number
}) => {
  const { isMobile } = UseViewSize()

  const defaultWidth = isMobile ? '100%' : '270px'
  const {
    value,
    onChange,
    onClear,
    buttonClickToSearch = false,
    debounceTime = 300,
    placeholder = 'search',
    width = defaultWidth,
    theme ,
    minSearchLength = 3,
  } = props

  const themeDarkhex = COLORS[`${theme}_500`]
  const themeBorderhex = COLORS.shades_neutral_200
  const themeDisabledHex = COLORS[`${theme}_500_disabled`]

  const [currentValue, setCurrentValue] = useState(value)

  // if you are wondering why this is in a useCallback instead of just being a function
  // tldr; it's because we need it as a dep for debouncedOnInputChange and so it has to only be defined once
  // if it's not in a callback it gets redefined every time this PaginatedSearch component rerenders
  // ref: https://stackoverflow.com/a/62601621/10795885
  const onInputChange = useCallback(
    (event: any) => {
      if (!buttonClickToSearch) {
        const updatedValue = event?.target?.value
        onChange(updatedValue)
      }
    },
    [onChange, buttonClickToSearch],
  )

  // ref nice example hit up https://dmitripavlutin.com/react-throttle-debounce/
  const debouncedOnInputChange = useMemo(() => {
    return debounce(onInputChange, debounceTime)
  }, [debounceTime, onInputChange])

  // this cancels the handler if they navigate away from the page
  useEffect(() => {
    return () => {
      debouncedOnInputChange.cancel()
    }
  }, [debouncedOnInputChange])

  // hard overrides the current value
  useEffect(() => {
    if (value !== null && value !== undefined) {
      setCurrentValue(value)
    }
  }, [value])

  return (
    <InputGroup
      border="1px solid"
      borderColor={themeBorderhex}
      borderRadius="100px"
      width={width}
      maxWidth="100%"
      alignItems="center"
      color={themeDarkhex}
    >
      {!buttonClickToSearch && <InputLeftElement pointerEvents="none" children={<SearchIcon color={theme ? themeDarkhex : COLORS.lavender_500} />} />}
      {buttonClickToSearch && (
        <InputLeftElement fontSize="12px" cursor="pointer" ml="12px" onClick={() => onChange(currentValue)}>
          <Box
            p="4px 8px"
            bg={currentValue && currentValue.length >= minSearchLength ? themeDarkhex : themeDisabledHex}
            color="white"
            borderRadius="15px"
          >
            search
          </Box>
        </InputLeftElement>
      )}
      <Input
        pl={buttonClickToSearch ? '72px' : ''}
        fontSize={BASE_INPUT_STYLES.fontSize}
        borderRadius="100px"
        value={currentValue}
        border="none"
        _focus={{ border: 'none' }}
        onKeyPress={(evt) => {
          if (evt.key === 'Enter' && currentValue && currentValue.length >= minSearchLength) {
            onChange(currentValue)
          }
        }}
        onChange={(evt) => {
          debouncedOnInputChange(evt)
          setCurrentValue(evt.target.value)
        }}
        placeholder={placeholder}
      />
      {value && (
        <InputRightElement fontSize="12px" cursor="pointer" onClick={onClear}>
          <CloseIcon color={themeDarkhex} />
        </InputRightElement>
      )}
    </InputGroup>
  )
}
