import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { UseViewSize } from '../../../core/UseViewSize'

export const SignupFooter = () => {
  const { isMobile } = UseViewSize()
  if (isMobile) {
    return (
      <Flex justify="center" align="center" direction="column" gridGap="12px" p="12px">
        <a href="https://app.salonscale.com/legal/privacy" target="_blank" rel="noreferrer">
          <Text variant="footnote">Privacy Policy</Text>
        </a>
        <a href="https://app.salonscale.com/legal/terms" target="_blank" rel="noreferrer">
          <Text variant="footnote">Terms of Service</Text>
        </a>

        <Text maxW="250px" textAlign="center" variant="footnote">
          © SalonScale 2022, SalonScale Technology Inc. All rights reserved.
        </Text>
      </Flex>
    )
  }
  return (
    <Flex align="center" justify="space-between" p="12px" bg="shades.neutral.50" flexWrap="wrap" gridGap="24px">
      <Flex align="center" gridGap="24px" flexWrap="wrap">
        <a href="https://app.salonscale.com/legal/privacy" target="_blank" rel="noreferrer">
          <Text variant="footnote">Privacy Policy</Text>
        </a>
        <a href="https://app.salonscale.com/legal/terms" target="_blank" rel="noreferrer">
          <Text variant="footnote">Terms of Service</Text>
        </a>
      </Flex>
      <Box>
        <Text variant="footnote">© SalonScale 2022, SalonScale Technology Inc. All rights reserved.</Text>
      </Box>
    </Flex>
  )
}
