export const COLORS = {
  success_500: '#4CAF50',
  highlight: '#00C9A7',
  warning: '#FFC75F',
  danger: '#E23D00',

  lavender_50: '#f9fafd',
  lavender_100: '#EAEBF9',
  lavender_200: '#DDDEF5',
  lavender_300: '#B8BAF5',
  lavender_400: '#9FA2F5',
  lavender_500: '#888BE2',
  lavender_500_disabled: '#c0bcf0',
  lavender_600: '#6F72C9',
  lavender_700: '#6657C6',
  lavender_800: '#5F4DB9',
  lavender_900: '#5339A2',
  lavender_gradient: 'linear-gradient(180deg, rgba(137, 139, 226, 0.2) 0%, rgba(217, 217, 217, 0) 100%)',

  peach_50: '#FFEFE9',
  peach_100: '#FEC2AA',
  peach_200: '#F79B77',
  peach_201: '#F28866',
  peach_300: '#EE7644',
  peach_400: '#E85A19',
  peach_500: '#E85A19', // peach_500: '#E23D00', <- peach 500 is too aggressive to fit in with the other 500s, changed it to 400 for now
  peach_600: '#E1521E',
  peach_700: '#D64504',

  linen_50: '#FFF5E8',
  linen_100: '#FFE5C5',
  linen_200: '#FFD49B',
  linen_300: '#FFC16D',
  linen_400: '#FFB24F',
  linen_500: '#FFA53E',
  linen_600: '#FF993B',
  linen_700: '#FF8A38',

  skylight_50: '#E8F5FF',
  skylight_100: '#C7E6FF',
  skylight_200: '#A4D7FF',
  skylight_300: '#A4D7FF',
  skylight_500: '#54AEFF',
  skylight_600: '#4E9FF9',
  skylight_700: '#478CE5',
  skylight_800: '#417BD3',
  skylight_900: '#375CB4',

  midnight_50: '#E4E6EE',
  midnight_100: '#BBC1D6',
  midnight_200: '#8F9ABB',
  midnight_300: '#6573A0',
  midnight_500: '#233D7D',
  midnight_500_disabled: 'rgba(35, 60, 123, 0.5)',
  midnight_700: '#132E6A',
  midnight_900: '#001139',

  shades_background_100: '#000000',
  shades_background_200: '#000000',
  shades_background_400: '#424242',
  shades_background_600: '#f2f2f2',
  shades_background_700: '#f2f2f2',

  shades_neutral_0: '#FFFFFF',
  shades_neutral_50: '#FAFAFA',
  shades_neutral_100: '#F4F4F4',
  shades_neutral_200: '#EEEEEE',
  shades_neutral_300: '#E0E0E0', // common border color
  shades_neutral_400: '#BDBDBD',
  shades_neutral_500: '#9E9E9E',
  shades_neutral_600: '#667088',

  placeholder: '#A0AEC0',
  hover: '#F5F5F5',
  text_primary: '#000000',
  text_secondary: '#00113999',
  text_tertiary: '#0011394D',

  form_control: '#F2F2F7',
  border: '#8C8C8F'
}
