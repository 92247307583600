import React, { ReactNode } from 'react'
import { Box, Drawer, DrawerBody, DrawerContent, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { useDispatch } from 'react-redux'
import { reduceSetStepCompleteDrawerState, selectStepCompleteDrawerState } from '../../../data/start-guide/slice'
import { useAppSelector } from '../../../hooks'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { UseViewSize } from '../../../core/UseViewSize'
import { Gap } from '../../../mini-lib/gap/Gap'
import { MaterialIcon } from "../../../mini-lib/icons/MaterialIcon";

export const HELP_DRAWER_STATES: any = {
  drawer: 'drawer',
  button: 'button',
}

export const HelpStepCompleteDrawer = ( props: {
  title: string
  subtitle?: string
  actions: ReactNode
  guideType: string
} ) => {
  const dispatch = useDispatch()
  const { title, subtitle = '', actions, guideType } = props
  const drawerState = useAppSelector(selectStepCompleteDrawerState)
  const { isMobile }: any = UseViewSize()
  const queryParams: any = UseQueryParams()
  const guideParam = queryParams.get('guide')
  const isGuide = guideParam === guideType
  const toggleDrawerState = () => {
    if (drawerState === HELP_DRAWER_STATES.button) {
      dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
    } else {
      dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.button))
    }
  }
  return (
    <>
      {isGuide && (
        <Drawer
          variant="alwaysOpen"
          isOpen={drawerState === HELP_DRAWER_STATES.drawer || drawerState === HELP_DRAWER_STATES.button}
          placement="bottom"
          onClose={toggleDrawerState}
          blockScrollOnMount={false}
          trapFocus={false}
        >
          <DrawerContent>
            <DisableAutofocus/>

            {drawerState === HELP_DRAWER_STATES.button && (
              <DrawerBody borderTop={`2px solid ${COLORS.lavender_500}`} p='12px'>
                  <Flex justify='end' align='center' gridGap='8px'>
                    {actions}
                    <MaterialIcon cursor='pointer' size='28px' name='keyboard_arrow_up' theme='midnight' onClick={() => dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))}/>
                  </Flex>
              </DrawerBody>
            )}
            {drawerState === HELP_DRAWER_STATES.drawer && (
              <DrawerBody borderTop={`2px solid ${COLORS.lavender_500}`} >
                <MaterialIcon cursor='pointer' size='28px' position='fixed' top='24px' right='24px' name='keyboard_arrow_down' theme='midnight' onClick={() => dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.button))}/>
                <Flex justify="center" align="center" direction="column" p={isMobile ? "24px 0" : "48px 0"} >
                  <Box h="24px"/>
                  <Text variant={isMobile ? 'title1mobile' : 'title1'} fontWeight="bold">
                    {title}
                  </Text>
                  <Gap s="12px"/>
                  <Text variant="subtitle">{subtitle}</Text>
                  <Box h="24px"/>
                  {actions}
                </Flex>
              </DrawerBody>
            )}
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}
