import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  NumberInput,
  NumberInputField,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { UseViewSize } from '../../../core/UseViewSize'
import {
  APIUpdateProduct,
  Product,
  ProductsByCategoryThenByLineSize,
  ProductsByLineSize,
} from '../../../data/products/interfaces'

import { capitalize, includes, keys } from 'lodash'
import { dispatchDeleteProducts, dispatchUpdateProducts } from '../../../data/products/api'
import { ConfirmPopover } from '../../../mini-lib/confirm-popover/ConfirmPopover'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { flattenMapOfListsToList, UseQueryParams } from '../../../mini-lib/utils/basic'
import {
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectStepCompleteDrawerState,
} from '../../../data/start-guide/slice'
import { HelpPopover } from '../../start-guide/common-components/HelpPopover'
import { useAppSelector } from '../../../hooks'
import { selectLoadingState } from '../../../core/loading/slice'
import { buildLoadingName } from '../../../core/loading/utils'
import { Loading } from '../../../mini-lib/loading/Loading'
import { HELP_DRAWER_STATES } from '../../start-guide/common-components/HelpStepCompleteDrawer'
import { COLORS } from '../../../mini-lib/theme/colors'
import { SalonScaleProductIcon } from '../../../mini-lib/icons/SalonScaleProductIcon'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { getHelpStepIndex } from '../../../data/start-guide/utils'
import { getColumnWidth } from '../../../mini-lib/table/utils'
import { dispatchUpdateUserChecklistItem } from '../../../data/start-guide/api'
import {
  reduceAddProductsToSelectedProductMap,
  reduceAddSelectedCategory,
  reduceBulkProductSheetVisibility,
  reduceRemoveProductsFromSelectedProductMap,
  reduceRemoveSelectedCategory,
  selectIsProductChecked,
  selectSelectedCategories,
  selectSelectedProductsById,
} from '../../../data/products/slice'
import { Gap } from '../../../mini-lib/gap/Gap'
import { ReleaseLaraColors, ReleaseUnitsSupport } from "../../../mini-lib/flags/Release";

export const ProductsByCategoryAccordion = ( props: {
  productPropertyToUpdate: 'target' | 'on hand'
  productsByCategoryThenByLineSize: ProductsByCategoryThenByLineSize
} ) => {
  const { productsByCategoryThenByLineSize, productPropertyToUpdate } = props
  // using the expanded indexes to speed things up by hiding the accordion panels
  const [expandedIndexes, setExpandedIndexes] = useState([0])
  const selectedCategories = useAppSelector(selectSelectedCategories)
  return (
    <>
      <BulkProductButton productPropertyToUpdate={productPropertyToUpdate} />
      <Accordion
        allowToggle
        allowMultiple={true}
        defaultIndex={[0]}
        onChange={( indexes: any[] ) => {
          setExpandedIndexes(indexes)
        }}
      >
        {keys(productsByCategoryThenByLineSize)
          .sort()
          .map(( categoryKey, index ) => {
            const productsByLineSize = productsByCategoryThenByLineSize[categoryKey]
            return (
              <CategoryAccordionPanel
                selectedCategories={selectedCategories}
                productPropertyToUpdate={productPropertyToUpdate}
                expandedIndexes={expandedIndexes}
                index={index}
                categoryName={categoryKey}
                key={categoryKey}
                productsByLineSize={productsByLineSize}
              />
            )
          })}
      </Accordion>
    </>
  )
}

export const CategoryAccordionPanel = ({
  expandedIndexes,
  index,
  categoryName,
  productsByLineSize,
  productPropertyToUpdate,
  selectedCategories,
}: {
  expandedIndexes: number[]
  index: number
  categoryName: string
  productsByLineSize: ProductsByLineSize
  productPropertyToUpdate: 'target' | 'on hand'
  selectedCategories: string[]
} ) => {
  // this has to be outside of the panel or the state will reset every time the panel closes
  // i also dont want to make a selector that has to search all selected products to derive if it's selected
  // const [selectAllChecked, setSelectAllChecked] = useState(false)
  const categoryKey = `${productPropertyToUpdate}-${categoryName}`
  const selectAllChecked = selectedCategories.includes(categoryKey)
  // possibly try to move this into the modal
  const products: Product[] = flattenMapOfListsToList(productsByLineSize)
  const panelVisible = includes(expandedIndexes, index)
  const [numRowsToShow, setNumRowsToShow] = useState(10)
  return (
    <AccordionItem border="none">
      <AccordionButton
        color="black"
        bg="brand.lavender.100"
        p="12px 12px 12px 0"
        borderRadius="8px"
        mb="8px"
        w="100%"
        maxH="50px"
        border="none"
        _hover={{ bg: 'brand.lavender.200' }}
        _focus={{ border: 'none' }}
      >
        <CategoryAccordionPanelHeader categoryName={categoryName} panelVisible={panelVisible}/>
      </AccordionButton>

      {panelVisible && (
        <AccordionPanel p="0 0 4px 0">
          <CategorySelectAll selected={selectAllChecked} categoryName={categoryName} categoryKey={categoryKey} categoryProducts={products}/>
          <ProductTitleRow productPropertyToUpdate={productPropertyToUpdate}/>
          {products.slice(0, numRowsToShow).map(( product, index ) => {
            return (
              <ProductRow
                productPropertyToUpdate={productPropertyToUpdate}
                key={product.id}
                index={index}
                product={product}
              />
            )
          })}
          {products && products.length > numRowsToShow && (
            <Flex justify="center">
              <Button variant="round-ghost-lower" onClick={() => setNumRowsToShow(numRowsToShow + 10)}>
                View More
              </Button>
            </Flex>
          )}
        </AccordionPanel>
      )}
    </AccordionItem>
  )
}
export const CategoryAccordionPanelHeader = ( props: { categoryName: string; panelVisible: boolean } ) => {
  const { categoryName, panelVisible } = props
  return (
    <>
      <Flex align="center" p="12px" w="100%" maxW="100%" gridGap="12px">
        <SalonScaleProductIcon variant="nobackground" size="28px" name={categoryName.toLowerCase()}/>
        <Text variant="title3" color="black" fontWeight="normal">
          {capitalize(categoryName)}
        </Text>
      </Flex>
      {panelVisible ? <MaterialIcon name="arrow_drop_up"/> : <MaterialIcon name="arrow_drop_down"/>}
    </>
  )
}

export const ProductTitleRow = ( { productPropertyToUpdate }: { productPropertyToUpdate: 'target' | 'on hand' } ) => {
  const { isMobile, isTablet, isDesktop } = UseViewSize()
  return (
    <Flex gridGap="12px" justify="space-between" pb="24px">
      <Box w={getColumnWidth({ size: 'big', isMobile, isTablet, isDesktop })} color="text.secondary">
        product
      </Box>
      {!isMobile && (
        <Box w={getColumnWidth({ size: 'medium', isMobile, isTablet, isDesktop })} color="text.secondary">
          brand/line
        </Box>
      )}
      <Flex color="text.secondary" w="120px" justify="center">
        {productPropertyToUpdate}
      </Flex>
      <Box w={isMobile ? '24px' : '200px'}/>
    </Flex>
  )
}

export const ProductRow = ( props: {
  product: Product
  index: number
  productPropertyToUpdate: 'target' | 'on hand'
} ) => {
  const dispatch = useDispatch()
  const { isMobile, isTablet, isDesktop } = UseViewSize()
  const { user, salonId } = UseBaseApiParams()
  const { product, index, productPropertyToUpdate } = props

  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const addOnHandChecklistItem = useAppSelector(( state ) => selectChecklistItem(state, CHECKLIST_CODES.inventorySetOnHand))
  const releaseLaraColors = ReleaseLaraColors();

  const queryParams: any = UseQueryParams()
  const isGuide = !!queryParams.get('guide')

  const initialValue =
    productPropertyToUpdate === 'target'
      ? product?.inventory?.maxStockLevel?.toString()
        ? product.inventory.maxStockLevel
        : '-'
      : product?.inventory?.quantityOnHand?.toString()
        ? product.inventory.quantityOnHand > 0
          ? product.inventory.quantityOnHand
          : 0
        : '-'
  const [value, setValue] = useState<any>(initialValue)

  const loadingName = buildLoadingName(product.id)
  const isLoading = useAppSelector(( state ) => selectLoadingState(state, loadingName))

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onUpdateProperty = ( updatedProperty: string ) => {
    // dont call update api if nothing changed
    if (initialValue.toString() === updatedProperty) {
      return
    }

    const updatedProduct: APIUpdateProduct = {
      product_id: product.id,
    }
    if (productPropertyToUpdate === 'target') {
      const normalizedTarget = updatedProperty ? parseFloat(updatedProperty) : 0
      updatedProduct['max_stock_level'] = normalizedTarget
    }
    if (productPropertyToUpdate === 'on hand') {
      const normalizedOnHand = updatedProperty ? parseFloat(updatedProperty) : 0
      const amountGramsInStock = Math.round(normalizedOnHand * product.size)
      updatedProduct['amount_grams_in_stock'] = amountGramsInStock || 0
      if (isGuide && !addOnHandChecklistItem.completed) {
        dispatch(dispatchUpdateUserChecklistItem({
          token: user.token,
          checklistItemCode: CHECKLIST_CODES.inventorySetOnHand
        }))
      }
    }
    const params = {
      token: user.token,
      user_id: user.userId,
      salon_id: salonId,
      models: [updatedProduct],
      loadingName,
    }

    dispatch(dispatchUpdateProducts(params))
    if (stepCompleteDrawerState !== HELP_DRAWER_STATES.button) {
      dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
    }
  }

  return (
    <>
      <Flex className="cy-line-size-row" justify="space-between" align="center" pb="24px" maxWidth="100%" width="100%">
        {/* line column */}
        {productPropertyToUpdate === 'target' || productPropertyToUpdate === 'on hand'
          ? <CheckboxColumn
            width={getColumnWidth({ size: 'big', isMobile, isTablet, isDesktop })}
            product={product}
          />
          : <ProductColumn
            width={getColumnWidth({ size: 'big', isMobile, isTablet, isDesktop })}
            product={product}
            isMobile={isMobile}
          />
        }
        {!isMobile && (
          <Box w={getColumnWidth({ size: 'medium', isMobile, isTablet, isDesktop })}>
            <Text variant="callout">{product.line.name}</Text>
            <Text variant="footnote" color={COLORS.text_secondary}>
              {product.vendor.name}
            </Text>
          </Box>
        )}
        <Flex align="center" w="120px">
          <HelpPopover
            description={
              productPropertyToUpdate === 'target'
                ? 'Quickly enter the target for each of your products here'
                : 'Enter your current amount of the product on your shelf. Try to estimate if there is 1/4 (.25), 1/2 (.5) or 3/4 (.75) of a tube the best you can.'
            }
            stepIndex={getHelpStepIndex(index, 0)}
            guideTypes={[CHECKLIST_CODES.inventorySetTargets, CHECKLIST_CODES.inventorySetOnHand]}
          >
            <Box>
              <HelpPopover
                description="If you currently stock a product but do not want to re-order it, set the target to “0” and it will still be available to use in your app."
                stepIndex={getHelpStepIndex(index, 2)}
                guideTypes={[CHECKLIST_CODES.inventorySetTargets]}
              >
                {/* consider extracting this to several components and doing the check for checklist item inside that component so every bulk input does not have to check for state that it doesnt care about if its a target or price variant */}
                {/* j-ref: onFocus call below -  how to select all text in an input when it is clicked */}
                <NumberInput
                  w="120px"
                  minW="120px"
                  min={0}
                  precision={2}
                  onChange={( updatedValue ) => setValue(updatedValue)}
                  onBlur={( evt: any ) => onUpdateProperty(evt.target.value)}
                  value={value}
                  onFocus={( event ) => event?.target?.select()}
                >
                  {isLoading ? (
                    <Loading/>
                  ) : (
                    <Flex>
                      <NumberInputField textAlign="center" p="0 16px" borderRadius="50px"/>
                    </Flex>
                  )}
                </NumberInput>
              </HelpPopover>
            </Box>
          </HelpPopover>
        </Flex>

        {/* action items */}
        <Flex align="center" w={isMobile ? '24px' : '200px'}>
          <HelpPopover
            guideTypes={[CHECKLIST_CODES.inventorySetTargets, CHECKLIST_CODES.inventorySetOnHand]}
            description={
              productPropertyToUpdate === 'target'
                ? 'If you do not stock a product you can delete it from your products list here. Deleted products will not show up in app.'
                : 'If you do not stock a product you can delete it from your products list here. Deleted products will not show up in app.'
            }
            stepIndex={getHelpStepIndex(index, 1)}
            children={
              <Box>
                <ConfirmPopover
                  title={`This Action is Permanent`}
                  subtitle={`This will remove ${product.type} from your salon`}
                  onConfirm={() => {
                    dispatch(
                      dispatchDeleteProducts({
                        token: user.token,
                        salonId,
                        models: [product],
                        releaseLaraColors
                      }),
                    )
                  }}
                >
                  {isMobile ? (
                    <Box>
                      <MaterialIcon name="delete" colorhex={COLORS.midnight_500}/>
                    </Box>
                  ) : (
                    <Button w="100px" colorScheme="brand.lavender" variant="round-ghost">
                      Delete
                    </Button>
                  )}
                </ConfirmPopover>
              </Box>
            }
          />
        </Flex>
      </Flex>
    </>
  )
}
const CheckboxColumn = ( { width, product } ) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const isChecked = useAppSelector(( state ) => selectIsProductChecked(state, product.id))
  const toggleIsChecked = () => {
    if (isChecked) {
      dispatch(reduceRemoveProductsFromSelectedProductMap([product]))
    } else {
      dispatch(reduceAddProductsToSelectedProductMap([product]))
    }
  }
  return (
    <Checkbox isChecked={isChecked} onChange={toggleIsChecked} colorScheme='brand.lavender'>
      <ProductColumn
        width={width}
        product={product}
        isMobile={isMobile}
      />
    </Checkbox>
  )
}
const ProductColumn = ( { width, product, isMobile } ) => {
  const releaseUnitsSupport = ReleaseUnitsSupport();
  const staticUnit = isMobile ? 'g/ml' : 'g or ml'
  const units = releaseUnitsSupport ? product?.units ?? staticUnit : staticUnit
  return (
    <Flex align="center" gridGap="12px" w={width} paddingRight="12px">
      <SalonScaleProductIcon name={product?.category?.toLowerCase()}/>
      <Flex direction="column" justify="center" isTruncated>
        <Tooltip label={product.type}>
          <Box>
            <Text variant="callout">{product.type}</Text>
          </Box>
        </Tooltip>
        {isMobile && <Text variant="footnote">{product.line.name}</Text>}
        <Flex gridGap="4px">
          <Text variant="footnote" color={COLORS.text_secondary}>
            {product.size}
            {units}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
const BulkProductButton = ( props: { productPropertyToUpdate: 'on hand' | 'target' } ) => {

  const { productPropertyToUpdate } = props
  const selectedProductsById = useAppSelector(selectSelectedProductsById)
  const numSelectedProducts = selectedProductsById ? keys(selectedProductsById).length : 0

  const dispatch = useDispatch()

  const bulkSetProperty = () => {
    dispatch(reduceBulkProductSheetVisibility(true))
  }
  const drawerState = useAppSelector(selectStepCompleteDrawerState)
  // this interacts with the start guide drawer a lot, we need to ensure the start guide drawer does not go over this
  const bottomPadding = drawerState === 'button' ? '72px' : drawerState === 'drawer' ? '300px' : '24px'
  return (
    <>
      {numSelectedProducts > 0 && (
        <Flex
          justify="space-between"
          align="center"
          p={`24px 24px ${bottomPadding} 24px`}
          style={{ position: 'fixed', bottom: '0', right: '0', zIndex: 1 }}
          bg="white"
          borderRadius="24px 0 0 0"
        >
          <Button variant="round" colorScheme="brand.midnight" onClick={bulkSetProperty}>
            Set {productPropertyToUpdate} ({numSelectedProducts})
          </Button>
        </Flex>
      )}
    </>
  )
}

export const CategorySelectAll = ( { selected, categoryName, categoryKey, categoryProducts } ) => {
  const dispatch = useDispatch()
  const toggleSelected = () => {
    if (selected) {
      dispatch(reduceRemoveSelectedCategory(categoryKey))
      dispatch(reduceRemoveProductsFromSelectedProductMap(categoryProducts))
    } else {
      dispatch(reduceAddSelectedCategory(categoryKey))
      dispatch(reduceAddProductsToSelectedProductMap(categoryProducts))
    }
  }
  return (
    <>
      <Gap s='12px'/>
      <Checkbox
        isChecked={selected}
        colorScheme="brand.lavender"
        onChange={toggleSelected}
        fontStyle="italic"
        fontWeight="bold"
      >
        Select All {categoryName}
      </Checkbox>
      <Gap s='12px'/>
    </>
  )
}
