import { T } from '../../core/generics'

// number words become their numbers to the sort, you can specify a 'last property' as well (for new items created by a constant)
//
// eg: takes supplies like       [supply four, supply 2, supply three, suppy one, New Supply, Zed Supply]
// and sorts it like             [suppy one, supply 2, supply three, supply four, Zed Supply, New Supply]
export const stringNumberSort = (l: T[], sortProperty: string, lastProperty = 'new'): T[] => {
  return l.sort((x, y) => {
    const normalizedX = replaceAll(x[sortProperty], numberMap())
    const normalizedY = replaceAll(y[sortProperty], numberMap())

    // this makes lastProperty always last
    if (normalizedX === lastProperty) {
      return 1
    }
    //
    if (normalizedY === lastProperty) {
      return -1
    }
    ////

    // regular sorting (with words turned into numbers)
    if (normalizedX < normalizedY) {
      return -1
    }
    if (normalizedX > normalizedY) {
      return 1
    }
    return 0
  })
}

// https://stackoverflow.com/a/15604206/10795885
export const replaceAll = (str: string, mapObj: { [key: string]: string }): string => {
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi')

  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()]
  })
}

// todo: extend this if we want
export const numberMap = (): { [key: string]: string } => {
  return {
    one: '1',
    two: '2',
    three: '3',
    four: '4',
    five: '5',
    six: '6',
    seven: '7',
    eight: '8',
    nine: '9',
    ten: '10',
  }
}
