import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Gap } from '../../mini-lib/gap/Gap'
import {dispatchSyncMasterColorsToExistingSalonsLara} from "../../data/master-products/api";
import {TextInput} from "../../mini-lib";
import {isNaN} from "lodash";

export const AdminMasterProductsPage = () => {
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()
  const [masterProductId, setMasterProductId] = useState('')
  const [masterProductIdErrorText, setMasterProductIdErrorText] = useState('')

  const validateMasterProductIdField = ( val: string ) => {
    const masterProductIdNumber = parseInt(val)
    if (isNaN(masterProductIdNumber)) {
      setMasterProductIdErrorText('please enter a valid number')
    } else {
      setMasterProductIdErrorText('')
    }
  }
  const onSync = () => {
    const masterProductIdNumber = parseInt(masterProductId)
    if (masterProductIdNumber && !isNaN(masterProductIdNumber)) {
      dispatch(dispatchSyncMasterColorsToExistingSalonsLara({token: user.token, masterProductIds: [masterProductIdNumber]}))
    }
  }
  return (
    <Box maxW="1200px" margin="auto">
      <PageHeader
        title="Admin Master Products"
        breadcrumbs={[]}
        actions={
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.loom.com/share/aab1b60b909f442fb75c9ef928c291e1?sid=48c77111-42de-4f47-aed1-41c80e9f1896`}
          >
            <Button colorScheme="brand.midnight" variant="round">
              loom explanation
            </Button>
          </a>
        }
      />
      <Gap />

      <Text variant="title3">Sync Master Products to Existing Salons</Text>
      <Gap s='12px'/>
      <Text variant="footnote">
        enter a master product id to sync it to salons that already have other products in the same line
      </Text>
      <Text variant="footnote">
        eg: if a salon has 8/10 products in this products line this will automatically add this product to that salon
        and set the price automatically
      </Text>
      <Text variant="footnote">note: this will only sync products that are semi, demi, permanent, or highlift</Text>

      <Gap />

      <Flex gridGap="12px">
        <TextInput
          variant="material"
          placeholder="enter text"
          label="master product id to sync"
          value={masterProductId}
          errorText={masterProductIdErrorText}
          onBlur={setMasterProductId}
          onChange={(val) => {
            validateMasterProductIdField(val)
            setMasterProductId(val)
          }}
        />
        <Button
          variant="round"
          colorScheme="brand.midnight"
          onClick={onSync}
          isDisabled={masterProductIdErrorText !== ''}
        >
          Sync to Salons
        </Button>
      </Flex>
    </Box>
  )
}
