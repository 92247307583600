import { capitalize } from 'lodash'
import { replaceAll } from '../../mini-lib/utils/sorting'
import { LoggedInUser } from '../user/interfaces'

export const getPermissionCellWidth = (isMobile: boolean): string => {
  return isMobile ? '140px' : '200px'
}
export const buildRolePermissionKey = (params: { id: number; roleName: string; permissionName: string }): string => {
  const { id, roleName, permissionName } = params
  return `${id}-${roleName.toLowerCase()}-${permissionName.toLowerCase()}`
}

export const buildRoleLabel = (roleName): string => {
  return roleName === 'frontdesk' ? 'Front Desk' : capitalize(roleName)
}

export const buildRoleTooltip = (roleName): string => {
  const roleLabel = roleName === 'frontdesk' ? 'Front Desk' : capitalize(roleName)
  return `This controls what a ${roleLabel} can access.`
}

export const buildPermissionTooltip = (permission): string => {
  return `This controls who can access the ${permission} tab`
}

export const getPermissionDisplayName = (name: string): string => {
  const replaceMap = {
    stylists: 'team',
    'color lines': 'edit color lines',
    addons: 'edit addons',
    extensions: 'edit extensions',
  }
  return replaceAll(name.toLowerCase(), replaceMap)
}

export const buildSupportEmailUrl = (user: LoggedInUser, salonId: number): string => {
  const salonscaleEmail = 'info@salonscale.com'
  const subject = 'Support%20Request'
  const body = `
User: ${user.firstName} ${user.lastName} %0D%0A 
Salon Id: ${salonId} %0D%0A
Registered Email: ${user.email}
  `
  return `mailto:${salonscaleEmail}?subject=${subject}&body=${body}`
}

export const buildContextlessEmailUrl = (): string => {
  const salonscaleEmail = 'info@salonscale.com'
  const subject = 'Support%20Request'
  const body = ``
  return `mailto:${salonscaleEmail}?subject=${subject}&body=${body}`
}

export const buildFeatureRequestEmailUrl = (user: LoggedInUser, salonId: number): string => {
  const salonscaleEmail = 'info@salonscale.com'
  const subject = 'Feature%20Request'
  const body = `
User: ${user.firstName} ${user.lastName} %0D%0A 
Salon Id: ${salonId} %0D%0A
Registered Email: ${user.email}
  `
  return `mailto:${salonscaleEmail}?subject=${subject}&body=${body}`
}
