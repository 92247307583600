import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import { useDispatch } from 'react-redux'
import { UseViewSize } from '../../../core/UseViewSize'
import {
  APICreateOrUpdateSessionExtensionLara,
  SessionExtensionLara,
  SessionMetaLara
} from '../../../data/sessions/interfaces'
import { reduceIsExtensionSheetVisible, selectIsExtensionSheetVisible } from '../../../data/sessions/slice'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { ExtensionSelectSheet } from './ExtensionSelectSheet'
import { Extension } from '../../../data/extensions/interfaces'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'
import { formatCentsToDollars } from "../../../mini-lib/units/money";
import {
  dispatchCreateOrUpdateSessionExtensionsLara,
  dispatchDeleteSessionExtensionLara
} from "../../../data/sessions/api";
import { UseBaseApiParams } from "../../../core/UseBaseApiParams";
import { find } from "lodash";

export const SessionExtensionsSheet = (props: { sessionExtensions: SessionExtensionLara[], sessionMeta: SessionMetaLara; }) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()

  const isExtensionSheetVisible = useAppSelector(selectIsExtensionSheetVisible)
  const { sessionExtensions, sessionMeta } = props
  const [showExtensionSheet, setShowExtensionSheet] = useState(false)
  const {user, salonId} = UseBaseApiParams()
  const updateExtensions = (sessionExtension: SessionExtensionLara, quantity: number) => {
    const updatedSessionSupply: APICreateOrUpdateSessionExtensionLara = { id: sessionExtension.id, product_id: sessionExtension.product.id, quantity }
    dispatch(dispatchCreateOrUpdateSessionExtensionsLara({ token: user.token, salonId, sessionId: sessionMeta.id, models: [updatedSessionSupply] }))
  }
  const deleteExtension = (sessionExtension: SessionExtensionLara) => {
    dispatch(dispatchDeleteSessionExtensionLara({token: user.token, salonId, sessionId: sessionMeta.id, sessionExtensionIds: [sessionExtension.id]}))
  }

  const updateSelectedExtensions = (extensionsToAdd: Extension[]) => {
    const newExtensions: APICreateOrUpdateSessionExtensionLara[] = []

    extensionsToAdd.forEach((extension) => {
      // don't re add any extensions that already exist
      const alreadyExists: SessionExtensionLara | undefined = find(
        sessionExtensions,
        (item) => item?.product.id === extension.id,
      )
      if (!alreadyExists) {
        const newSessionExtension: APICreateOrUpdateSessionExtensionLara = {
          product_id: extension.id,
          quantity: 1,
        }
        newExtensions.push(newSessionExtension)
      }
    })
    const extensionsToDelete: SessionExtensionLara[] = []
    sessionExtensions?.forEach(sessionExtension => {
      const existsInExtensionsToAdd = find(extensionsToAdd, (extension) => extension.id === sessionExtension.product.id)
      if (!existsInExtensionsToAdd) {
        extensionsToDelete.push(sessionExtension)
      }
    })
    if (extensionsToDelete.length > 0) {
      const idsToDelete = extensionsToDelete.map(ext => ext.id)
      dispatch(dispatchDeleteSessionExtensionLara({token: user.token, salonId, sessionId: sessionMeta.id, sessionExtensionIds: idsToDelete}))
    }
    dispatch(dispatchCreateOrUpdateSessionExtensionsLara({ token: user.token, salonId, sessionId: sessionMeta.id, models: newExtensions }))
  }

  const onSave = () => {
    // const apiUpdatedExtensions = mapSessionItemsToAPISessionItems(updatedExtensions)
    // updateSession({ extensions: apiUpdatedExtensions })
    dispatch(reduceIsExtensionSheetVisible(false))
  }
  const selectedExtensionIds = sessionExtensions.map((ext) => ext.product.id)
  return (
    <Drawer
      size={isMobile ? 'xs' : 'md'}
      isOpen={isExtensionSheetVisible}
      placement="right"
      onClose={() => dispatch(reduceIsExtensionSheetVisible(false))}
    >
      {showExtensionSheet && (
        <ExtensionSelectSheet
          selectedExtensionIds={selectedExtensionIds}
          show={showExtensionSheet}
          onHide={() => setShowExtensionSheet(false)}
          onExtensionsSelected={(extensions) => {
            updateSelectedExtensions(extensions)
            setShowExtensionSheet(false)
          }}
        />
      )}
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent>
        <DrawerHeader>
          <Flex justify="space-between" align="center">
            <Text variant="title3">Edit Extensions</Text>
            <Flex align="center" gridGap="12px">

              <Button bg="brand.peach.200" variant="round" onClick={onSave} minW="100px">
                Done
              </Button>
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <>
            <DisableAutofocus />
            <Box h="24px" />
            {sessionExtensions.map((extension, index) => {
                return (
                  <ExtensionRow
                    key={index}
                    extension={extension}
                    updateExtensions={updateExtensions}
                    deleteExtension={deleteExtension}
                  />
                )
              })}
            {sessionExtensions.length === 0 && <EmptyBox h="200px" />}
            <Box h="12px" />
            <Flex justify="flex-end">
              <Button
                className="cy-add-extensions"
                variant="round-outline"
                color="brand.peach.200"
                onClick={() => setShowExtensionSheet(true)}
              >
                <MaterialIcon name="add" style={{ marginRight: '4px' }} /> Extensions
              </Button>
            </Flex>
          </>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const ExtensionRow = (props: {
  extension: SessionExtensionLara
  updateExtensions: (extension: SessionExtensionLara, quantity: number) => void
  deleteExtension: (extension: SessionExtensionLara) => void
}) => {
  const { extension, updateExtensions, deleteExtension } = props
  return (
    <Flex key={extension.id} align="center" justify="space-between" p="0 0 24px 0" cursor="pointer">
      <Box>
        <Text variant="title3" mr='4px'>
          {extension?.product?.size} • {extension?.product?.category} • {extension?.product?.type}
        </Text>

        <Text color="text.secondary">{extension?.product?.line.name} • ${formatCentsToDollars(extension.clientPurchasePrice)}</Text>
      </Box>
      <Flex align="center" gridGap="12px">
        <Box>
          <Text color="text.secondary">Quantity</Text>
          <NumberInput
            w="120px"
            defaultValue={extension.quantity || 0}
            min={0}
            onBlur={(evt) => updateExtensions(extension, parseInt(evt.target.value) )}
            borderRadius="100px"
          >
            <NumberInputField borderRadius="100px" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <MaterialIcon mt="16px" name="delete" onClick={() => deleteExtension(extension)} />
      </Flex>
    </Flex>
  )
}
