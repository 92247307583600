import { Accordion, AccordionItem, Button, Divider } from '@chakra-ui/react'
import { StepGroupAccordionHeader } from '../common-components/StepGroupAccordionHeader'
import { StepGroupAccordionPanel } from '../common-components/StepGroupAccordionPanel'
import { StepAccordionHeader } from '../common-components/StepAccordionHeader'
import { StepAccordionPanel } from '../common-components/StepAccordionPanel'
import {
  reduceSetCurrentStepCode,
  selectChecklistItem, selectChecklistItemsByCode,
  selectCurrentStepCode,
  selectStepsCompleted,
} from '../../../data/start-guide/slice'
import { CHECKLIST_CODES, CHECKLIST_GROUP_INDEXES, CHECKLIST_GROUPS } from '../../../data/start-guide/constants'
import { useAppSelector } from '../../../hooks'
import { COLORS } from '../../../mini-lib/theme/colors'
import { findIndex } from 'lodash'
import { getAccordionIndexFromStepCode } from '../../../data/start-guide/utils'
import { useDispatch } from 'react-redux'
import { UserAction, VideoAction } from '../common-components/actions'
import { generatePath, Link } from 'react-router-dom'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { VIDEO_MAP, VideoData } from '../../../mini-lib/video/constants'
import { selectActiveSalonContext } from "../../../data/salon/slice";
import { isColorAndTargetComplete, isColorsComplete } from "../../../data/inventory/utils";
import { ROUTES } from "../../../appRoutes";

export const ChecklistGroup3 = () => {
  const stepCodes = CHECKLIST_GROUPS.group3
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  const currentStepCode = useAppSelector(selectCurrentStepCode)
  const stepIndex = findIndex(CHECKLIST_GROUPS.group3, (item) => item === currentStepCode)
  const stepGroupIndex = getAccordionIndexFromStepCode(currentStepCode)
  return (
    <AccordionItem p={0} m="0 0 24px 0" border="none">
      <StepGroupAccordionHeader
        initialStepCode={stepCodes[0]}
        iconName="implementation"
        title="Set Up Inventory"
        stepsCompleted={stepsCompleted ? stepsCompleted : 0}
        stepsLength={stepCodes.length}
      />

      {stepGroupIndex === CHECKLIST_GROUP_INDEXES.group3 && (
        <StepGroupAccordionPanel
          steps={
            <Accordion
              allowToggle
              p={0}
              index={stepIndex}
              border={`2px solid ${COLORS.lavender_100}`}
              borderRadius="0 0 15px 15px"
            >
              <InventoryIntroduction />
              <Divider />
              <InventorySetTargets />
              <Divider />
              <InventoryCompleteCount />
              <Divider />
              <InventoryStockAlerts />
              <Divider />
              <InventoryOrders />
              <Divider />
              <InventoryAddingStock />
            </Accordion>
          }
        />
      )}
    </AccordionItem>
  )
}


const InventoryIntroduction = () => {
  const dispatch = useDispatch()
  const stepCode = CHECKLIST_CODES.inventoryIntroduction
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))

  const video: VideoData = VIDEO_MAP.inventoryIntroduction
  const descriptionSubHeading = `Ensure you and your team (if applicable) are confident using the SalonScale app to create sessions.

Choose a go live date for inventory, and inform your team of when it is scheduled. You must complete your first count ahead of your go live date. This is the day that the products used in a session will start deducting from your inventory.`
  const description = `
Meet the first ever usage based inventory system! What does that mean? It means that while you are already weighing products used at your appointments, SalonScale is tracking those exact products to determine when it’s time for you to restock.

Did you know that on average 40% of backbar products go used?

Unlike other inventory management systems, SalonScale goes by the exact amount that is used plus with our new ordering feature, we easily track the in’s and ourt’s of your backbar. Bonus, you can manage it all right from your mobile device!

Easily see how much of each product you have in stock, see your stock levels, update your count and see the totalt dollar value of your backbar too.`

  const salon = useAppSelector(selectActiveSalonContext)
  const checklistItemsByCode = useAppSelector(selectChecklistItemsByCode)
  const isDisabled = !isColorsComplete({salon, checklistItemsByCode})


  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        isDisabled={isDisabled}
        checklistItem={checklistItem}
        label="1"
        title="Introduction to Inventory"
        actions={<VideoAction isDisabled={isDisabled} duration={video.length} />}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage: 'Please add some products before trying to complete this step!' }}
        stepCode={stepCode}
        descriptionSubHeading={descriptionSubHeading}
        description={description}
        video={video}
        actions={
          <>
            {checklistItem.completed && (
              <Button
                variant="round"
                colorScheme="brand.midnight"
                onClick={() => {
                  dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.inventorySetTargets))
                }}
              >
                Get Started
              </Button>
            )}
          </>
        }
      />
    </AccordionItem>
  )
}

const InventorySetTargets = () => {
  const { salonId } = UseBaseApiParams()
  const stepCode = CHECKLIST_CODES.inventorySetTargets
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))
  const description = `The first step is to set your inventory targets. Targets are your ideal quantity you want to have in on hand for each specific product at any given time.

Where do you find your target? This could be a number you have in mind for each product, or the the typical amount that you would order to have your stock. For example, if you order on a biweekly basis, you will want to ensure you have enough product on hand to sustain you for that period.
`
  const salon = useAppSelector(selectActiveSalonContext)
  const checklistItemsByCode = useAppSelector(selectChecklistItemsByCode)
  const isDisabled = !isColorsComplete({salon, checklistItemsByCode})
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        isDisabled={isDisabled}
        checklistItem={checklistItem}
        label="2"
        title="Set your back bar targets"
        actions={<UserAction isDisabled={isDisabled} />}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage: 'Please add some products before trying to complete this step!' }}
        video={VIDEO_MAP.inventoryBackBarTargets}
        stepCode={stepCode}
        description={description}
        actions={
          <Link to={generatePath(ROUTES.lines, { salonId }) + `?guide=${CHECKLIST_CODES.inventorySetTargets}`}>
            <Button variant="round" colorScheme="brand.midnight">
              Get Started
            </Button>
          </Link>
        }
      />
    </AccordionItem>
  )
}

const InventoryCompleteCount = () => {
  const { salonId } = UseBaseApiParams()
  const stepCode = CHECKLIST_CODES.inventoryCompleteCount
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))

  const description = `Next is to complete your first count! This step is important because it lets us know what you currently have at your backbar so we can accurately track what is being used and what is being replenished. We recommend completing this step when you have some downtime or when the salon is closed, as you will want to have accurate information! This step can be completed in the business center or the mobile app. Scan or manually update your inventory and collaborate with your team to complete inventory counts.`

  const salon = useAppSelector(selectActiveSalonContext)
  const checklistItemsByCode = useAppSelector(selectChecklistItemsByCode)
  const isDisabled = !isColorAndTargetComplete({salon, checklistItemsByCode})

  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        isDisabled={isDisabled}
        checklistItem={checklistItem}
        label="3"
        title="Complete Your First Count"
        actions={<UserAction isDisabled={isDisabled} />}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage: 'Please add some products and set their targets before trying to complete this step!' }}
        stepCode={stepCode}
        video={VIDEO_MAP.inventoryCount}
        description={description}
        actions={
          <Link
            to={generatePath(ROUTES.inventoryAudits, { salonId }) + `?guide=${CHECKLIST_CODES.inventoryCompleteCount}`}
          >
            <Button variant="round" colorScheme="brand.midnight">
              Get Started
            </Button>
          </Link>
        }
      />
    </AccordionItem>
  )
}

const InventoryStockAlerts = () => {
  const dispatch = useDispatch()
  const stepCode = CHECKLIST_CODES.inventoryStockAlerts
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))
  const description = `Get the pulse on your backbar! Here is where you can get a health level of all of your inventory items at the backbar. Learn the different stock alerts as well as some bonus tips to ensure your backbar is being run efficiently.`
  const video: VideoData = VIDEO_MAP.inventoryStockAlerts

  const salon = useAppSelector(selectActiveSalonContext)
  const checklistItemsByCode = useAppSelector(selectChecklistItemsByCode)
  const isDisabled = !isColorAndTargetComplete({salon, checklistItemsByCode})
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        isDisabled={isDisabled}
        checklistItem={checklistItem}
        label="4"
        title="Stock Alerts"
        actions={<VideoAction isDisabled={isDisabled} duration={video.length} />}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage: 'Please add some products and set their targets before trying to complete this step!' }}
        stepCode={stepCode}
        description={description}
        video={video}
        actions={
          <>
            {checklistItem.completed && (
              <Button
                variant="round-outline"
                colorScheme="brand.midnight"
                onClick={() => {
                  dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.inventoryOrders))
                }}
              >
                go to next step
              </Button>
            )}
          </>
        }
      />
    </AccordionItem>
  )
}

const InventoryOrders = () => {
  const dispatch = useDispatch()
  const stepCode = CHECKLIST_CODES.inventoryOrders
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))
  const description = `The order feature is a real wow factor, and we can't wait for you to see it! We like to say it changes ordering from "Just in Case" to a much more manageable "Just in Time". Plus, if you use a 100% markup, it literally pays for itself.
Based on your exact usage, SalonScale will create an order form for your products used. Download this to send to your sales rep, or as reference to easily plug in your orders online. No more ripping tabs, writing on napkins or scrambling to get that order in.`
  const video: VideoData = VIDEO_MAP.inventoryOrders

  const salon = useAppSelector(selectActiveSalonContext)
  const checklistItemsByCode = useAppSelector(selectChecklistItemsByCode)
  const isDisabled = !isColorAndTargetComplete({salon, checklistItemsByCode})
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        isDisabled={isDisabled}
        checklistItem={checklistItem}
        label="5"
        title="Orders"
        actions={<VideoAction isDisabled={isDisabled} duration={video.length} />}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage: 'Please add some products and set their targets before trying to complete this step!' }}
        stepCode={stepCode}
        description={description}
        video={video}
        actions={
          <>
            {checklistItem.completed && (
              <Button
                variant="round-outline"
                colorScheme="brand.midnight"
                onClick={() => {
                  dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.inventoryAddingStock))
                }}
              >
                go to next step
              </Button>
            )}
          </>
        }
      />
    </AccordionItem>
  )
}

const InventoryAddingStock = () => {
  const dispatch = useDispatch()
  const stepCode = CHECKLIST_CODES.inventoryAddingStock
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))

  const description = `There are 2 ways you can add stock:

        • Through an existing order form in SalonScale

        • Manually updating your inventory

When you order inventory or purchase color to replenish your backbar stock it is important to record this in the app so your on-hand number reflects that you added the product back into your backbar. This step can be completed in the business center or the mobile app.`
  const video: VideoData = VIDEO_MAP.inventoryAddingStock

  const salon = useAppSelector(selectActiveSalonContext)
  const checklistItemsByCode = useAppSelector(selectChecklistItemsByCode)
  const isDisabled = !isColorAndTargetComplete({salon, checklistItemsByCode})
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        isDisabled={isDisabled}
        checklistItem={checklistItem}
        label="6"
        title="Adding Stock"
        actions={<VideoAction isDisabled={isDisabled} duration={video.length} />}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage: 'Please add some products and set their targets before trying to complete this step!' }}
        video={video}
        stepCode={stepCode}
        description={description}
        actions={
          <>
            {checklistItem.completed && (
              <Button
                variant="round-outline"
                colorScheme="brand.midnight"
                onClick={() => {
                  dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.learnAcademy))
                }}
              >
                go to next step
              </Button>
            )}
          </>
        }
      />
    </AccordionItem>
  )
}
