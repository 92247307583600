/////////////// slice.ts
//
//

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign } from 'lodash'

import { RootState } from '../../store'
import { buildDateYYYYMMDD } from '../../core/dates'
import { buildAnalyticsKey, buildUserAnalyticsKey } from "./utils";


// state
//
interface AdminAnalyticsState {
  interval: string,
  startDate: Date,
  endDate: Date,
  minSessions: number,
  columnFilters: {search: string},
  analyticsByStartEndInterval: { [key: string]: any } | null
  usersByStartEndInterval: { [key: string]: any } | null
}
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = currentDate.getMonth();

// Start date is the first day of the month, two months ago
const startDate = new Date(year, month - 2, 1);

// End date is the first day of last month
const endDate = new Date(year, month - 1, 1);

const initialState: AdminAnalyticsState = {
  interval: '1 month',
  startDate: startDate,
  endDate: endDate,
  minSessions: 1,
  columnFilters: {search: ''},
  analyticsByStartEndInterval: null,
  usersByStartEndInterval: null,
}

// reducer
//
export const AdminAnalyticSlice = createSlice({
  name: 'admin-analytics',
  initialState,
  reducers: {
    reduceDashboardAnalytics: (
      state,
      action: PayloadAction<{ startDateString: string; endDateString: string; interval: string; analytics: any }>,
    ) => {
      const key = buildAnalyticsKey({
        startDateString: action.payload.startDateString,
        endDateString: action.payload.endDateString,
        interval: action.payload.interval,
      })
      state.analyticsByStartEndInterval = assign({}, state.analyticsByStartEndInterval, {
        [key]: action.payload.analytics,
      })
    },
    reduceUsersByStartEndInterval: (
      state,
      action: PayloadAction<{ startDateString: string; endDateString: string; analytics: any }>,
    ) => {
      const key = buildUserAnalyticsKey({
        startDateString: action.payload.startDateString,
        endDateString: action.payload.endDateString,
      })
      const updated = assign({}, state.usersByStartEndInterval, {
        [key]: action.payload.analytics,
      })
      state.usersByStartEndInterval = updated
    },
    reduceSetAdminAnalyticsInterval: ( state, action: PayloadAction<string> ) => {
      state.interval = action.payload
    },
    reduceSetAdminAnalyticsColumnFilters: ( state, action: PayloadAction<{ search: string }> ) => {
      state.columnFilters = action.payload
    },
    reduceSetAdminAnalyticsStartDate: ( state, action: PayloadAction<Date> ) => {
      state.startDate = action.payload
    },
    reduceSetAdminAnalyticsEndDate: ( state, action: PayloadAction<Date> ) => {
      state.endDate = action.payload
    },
    reduceSetAdminAnalyticsMinSessions: ( state, action: PayloadAction<number> ) => {
      state.minSessions = action.payload
    },
  },
})

// actions
//
export const {
  reduceDashboardAnalytics,
  reduceSetAdminAnalyticsInterval,
  reduceSetAdminAnalyticsStartDate,
  reduceSetAdminAnalyticsEndDate,
  reduceSetAdminAnalyticsMinSessions,
  reduceSetAdminAnalyticsColumnFilters,
  reduceUsersByStartEndInterval,
} = AdminAnalyticSlice.actions

// selectors
//
export const selectAdminAnalyticsForStartEndInterval = (
  state: RootState,
  startDate: Date,
  endDate: Date,
  interval: string
): any | null => {
  const key = buildAnalyticsKey({
    startDateString: buildDateYYYYMMDD(startDate),
    endDateString: buildDateYYYYMMDD(endDate),
    interval,
  })
  const analytics: any | null = state.adminAnalytics.analyticsByStartEndInterval && state.adminAnalytics.analyticsByStartEndInterval[key]
  return analytics ? analytics : null
}

export const selectAdminUserAnalyticsForStartEndInterval = (
  state: RootState,
  startDate: Date,
  endDate: Date,
): any | null => {
  const key = buildUserAnalyticsKey({startDateString: buildDateYYYYMMDD(startDate), endDateString: buildDateYYYYMMDD(endDate)})
  const analytics: any | null = state.adminAnalytics.usersByStartEndInterval && state.adminAnalytics.usersByStartEndInterval[key]
  return analytics ? analytics : null
}

export const selectAdminAnalyticsStartDate = ( state: RootState ): Date => {
  return state.adminAnalytics.startDate
}
export const selectAdminAnalyticsEndDate = ( state: RootState ): Date => {
  return state.adminAnalytics.endDate
}
export const selectAdminAnalyticsInterval = ( state: RootState ): string => {
  return state.adminAnalytics.interval
}

export const selectAdminAnalyticsColumnFilters = ( state: RootState ): { search: string } => {
  return state.adminAnalytics.columnFilters
}

// export
//
export default AdminAnalyticSlice.reducer
