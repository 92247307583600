// our error message structure is a little wild at the moment
// axios also wraps it as well i think
import { AxiosError } from "axios";
import * as Sentry from '@sentry/browser';
import { includes } from "lodash";

export const extractErrorMessage = (error: any, fallback: string): string => {

  if (includes(error.response.config.url, 'lara')) {
    return error.response.data.message
  }

  if (error && error.response && error.response.data && error.response.data.error) {
    const errorDetails = error.response.data.error.details;
    if (typeof errorDetails === 'string') {
      return errorDetails;
    }
    if (typeof errorDetails === 'object' && errorDetails.email) {
      return errorDetails.email;
    }
  }
  return fallback;
};

export const extractErrorStatusCode = (error: any): number | null => {
  return error?.response?.status ? error.response.status : null
}

export const handleError = (error: AxiosError): void => {
  if (error.response) {
    console.error('Server Error:', error.response.status);
    Sentry.captureException(new Error(`Server Error: ${error.response.status}`));
  } else if (error.request) {
    console.error('Network Error:', error.request);
    Sentry.captureException(new Error(`Network Error: ${error.request}`));
  } else {
    console.error('Error:', error.message);
    Sentry.captureException(new Error(`General Error: ${error.message}`));
  }
};
