/////////////// interfaces.ts
//
//

// external interfaces
//
//
export interface APIInventoryCount {
  id: number
  assigned_user: {
    user_id: number
    full_name: string
    first_name: string
    last_name: string
  }
  title: string
  progress_item_count: number
  total_item_count: number
  progress_percentage: string // '0%'
  stock_cost: number
  count_type: InventoryCountType
  created_at: string // 2022-11-23T15:43:59.000000Z
  completed_at: string // 2022-11-23T15:43:59.000000Z
}
export interface APIInventoryCountItem {
  id: number
  product: {
    id: number
    on_hand: number
    target: number
    category: string
    brand: string
    parent_brand: string
    type: string
    nickname: string
    size: number
    salon_purchase_price_in_cents: number
    units: string | null
  }
  expected_count: string // '0.00'
  actual_count: string // '0.00'
  reason: string
}

export interface APIInventoryCountItemUpdate {
  id: number
  product_id: number
  count_id: number
  count: number
  reason?: string
}

export interface APIInventoryCountItemCreate {
  product_id: number
  count_id: number
  count: number
  reason?: string
}

export interface APIInventoryCountCreate {
  assigned_user_id: number
  count_type: InventoryCountType
  order_id?: number // if count_type is order -> order_id is required
  title: string
}

// internal interfaces
//
//
export interface InventoryCount {
  id: number
  assignedUser: {
    userId: number
    fullName: string
    firstName: string
    lastName: string
  }
  title: string
  progressItemCount: number
  totalItemCount: number
  progressPercentage: string // '0%'
  stockCost: number
  countType: InventoryCountType
  createdAt: Date
  completedAt: string | null // date string
}

export interface InventoryCountItem {
  id: number
  product: {
    id: number
    onHand: number
    target: number
    category: string
    line: string
    vendor: string
    type: string
    nickname: string
    size: number
    salonPurchasePriceCents: number
    units: string | null
  }
  expectedCount: number | null
  actualCount: number | null
  reason: string
}

export interface InventoryCsvRow {
  productName: string
  productVendor: string
  productCategory: string
  expectedProducts: number
  actualProducts: number
  reason: string
  countedBy: string
}

export const InventoryCsvTitleRow = [
  'product name',
  'product category',
  'product parent brand',
  'product brand',
  '# products expected',
  '# products received',
  'reason',
  'counted by',
]

export type InventoryCountType = 'manual' | 'order' | 'inventory' | string
export type InventoryProgressType = 'completed' | 'in progress' | string
export type InventoryCountReason = 'received' | 'not received' | 'partially received' | 'back ordered' | string
export type InventoryCountedState = 'counted' | 'not counted' | string
