import { APISquareMember } from "./interfaces";

export const LOADING_CONNECT_SQUARE = 'connect-square'
export const MOCK_SQUARE_MEMBERS: APISquareMember[] = [
  {
    team_member_id: 'abc123',
    first_name: 'John',
    last_name: 'Mason',
    email_address: 'demo@salonscale.com',
    phone_number: '306 250 6924',
    // permission_level: 'stylist',
    // existingUserMeta: null,
  },
  {
    team_member_id: 'abc456',
    first_name: 'Karson',
    last_name: 'Braaten',
    email_address: 'karson@salonscale.com',
    phone_number: '111 111 1111',
    // permissionLevel: 'stylist',
    // existingUserMeta: null,
  },
  {
    team_member_id: 'abc789',
    first_name: 'Ryan',
    last_name: 'William',
    email_address: 'ryan@salonscale.com',
    phone_number: '222 222 2222',
    // permissionLevel: 'stylist',
    // existingUserMeta: null,
  },
]