import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { Provider } from 'react-redux'
import store from './store'
import { ChakraProvider, ColorModeScript, LightMode } from '@chakra-ui/react'
import { chakraTheme } from './theme'

import { InitializeChargebee } from './integrations/chargebee/service'
import { InitializeLDProvider } from './integrations/launchDarkly'

import { InitializeSentry } from './integrations/sentry/service'
import { InitializeGtag } from "./integrations/gtag"

( async () => {
  InitializeSentry()
  InitializeChargebee()
  InitializeGtag()
  const LaunchDarklyProvider = await InitializeLDProvider()

  ReactDOM.render(
    <ChakraProvider theme={chakraTheme}>
      <Provider store={store}>
        {/* the ld provider depends on the salon id and name in the store - ensure the store always wraps ld*/}
        <LaunchDarklyProvider>
          <LightMode>
            <ColorModeScript initialColorMode={'light'}/>
            <App/>
          </LightMode>
        </LaunchDarklyProvider>
      </Provider>
    </ChakraProvider>,
    document.getElementById('root'),
  )
} )()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
