import { Box, Button, Flex, Spinner, Text, Tooltip } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { generatePath, Link } from 'react-router-dom'
import { get } from 'lodash'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import {
  dispatchAddExtensionLines,
  dispatchDeleteExtensionLine,
  dispatchListAllExtensionLines,
  dispatchListExtensions,
} from '../../data/extensions/api'
import { ROUNDED_ALL_STYLES } from '../../styles'
import { selectAllExtensionLines, selectExtensionsById, selectExtensionsByLine } from '../../data/extensions/slice'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { LineOption } from '../../data/products/interfaces'
import { buildLoadingName } from '../../core/loading/utils'
import { selectLoadingState } from '../../core/loading/slice'
import { dispatchSetLoadingType } from '../../core/loading/api'
import { UseViewSize } from '../../core/UseViewSize'
import { ConfirmPopover } from '../../mini-lib/confirm-popover/ConfirmPopover'
import { HelpStepper, Step } from '../start-guide/common-components/HelpStepper'
import { HelpModal } from '../start-guide/common-components/HelpModal'
import { COLORS } from '../../mini-lib/theme/colors'
import {
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectChecklistItemsByCode,
  selectStepCompleteDrawerState,
  selectStepsCompleted,
} from '../../data/start-guide/slice'
import { HELP_DRAWER_STATES, HelpStepCompleteDrawer } from '../start-guide/common-components/HelpStepCompleteDrawer'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { HelpStepperV2 } from '../start-guide-v2/HelpStepperV2'
import { ReleaseLaraColors, ReleaseStartGuideV2 } from '../../mini-lib/flags/Release'
import { dispatchListUserChecklistItems, dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { ROUTES } from "../../appRoutes";

export const ExtensionsAddPage = (props: {showBreadCrumbs?: boolean}) => {
  const {showBreadCrumbs = true} = props
  const {
    user: { token, userId },
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = showBreadCrumbs ? [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Extensions', url: generatePath(ROUTES.extensions, { salonId }) },
    { label: 'Add' },
  ] : []
  const helpSteps: Step[] = [
    {
      title: 'Select Your Extensions',
      state: 'current',
      link: generatePath(ROUTES.extensionsAdd, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`,
    },
    {
      title: 'Set Prices',
      state: 'incomplete',
      link: generatePath(ROUTES.extensions, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`,
    },
  ]
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const releaseLaraColors = ReleaseLaraColors()
  const productsById = useAppSelector(selectExtensionsById)
  const extensionsByLine = useAppSelector(selectExtensionsByLine)
  const allLines = useAppSelector(selectAllExtensionLines)

  const allLinesLoaded = !!allLines
  useEffect(() => {
    if (!allLinesLoaded) {
      dispatch(dispatchListAllExtensionLines(token, salonId))
    }
  }, [dispatch, token, allLinesLoaded, salonId])

  const productsLoaded = !!productsById
  useEffect(() => {
    if (!productsLoaded) {
      dispatch(dispatchListExtensions({ token, salonId, releaseLaraColors }))
    }
  }, [dispatch, token, productsLoaded, salonId, releaseLaraColors])
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])

  const releaseStartGuideV2 = ReleaseStartGuideV2()
  const stepCodes = [CHECKLIST_CODES.addAddons, CHECKLIST_CODES.addExtensions]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  const stepsComplete = stepsCompleted || 0
  const previousStepLink = generatePath(ROUTES.sgAddExtensionsIntro, { salonId })
  const nextStepLink = generatePath(ROUTES.sgExtensions, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`
  return (
    <PageLayout
      variant="full"
      top={
        <>
          {releaseStartGuideV2 ? (
            <HelpStepperV2
              title="Add Extensions to Your Back Bar"
              titleIconName="products"
              currentStepNumber={1}
              stepsComplete={stepsComplete}
              totalSteps={stepCodes.length}
              previousStepLink={previousStepLink}
              nextStepLink={nextStepLink}
              guideType={CHECKLIST_CODES.addExtensions}
            />
          ) : (
            <HelpStepper
              guideType={CHECKLIST_CODES.addExtensions}
              title="Add Extensions to Your Back Bar"
              steps={helpSteps}
              bgHex={COLORS.peach_100}
              borderHex={COLORS.peach_400}
            />
          )}
        </>
      }
      header={
        <PageHeader
          title={isMobile ? 'Add Extensions' : 'Add Extension Lines'}
          breadcrumbs={breadcrumbs}
          actions={
            <Button variant="round" colorScheme="brand.midnight">
              <Link to={generatePath(ROUTES.extensions, { salonId })}>My Extensions</Link>
            </Button>
          }
        />
      }
      content={
        <>
          <HelpStepCompleteDrawer
            guideType={CHECKLIST_CODES.addExtensions}
            title="Congratulations on adding your first extensions!"
            subtitle="Feel free to add some more brands while we are here. When you are ready, click below to go to the next step"
            actions={
              <Link
                to={
                  releaseStartGuideV2
                    ? generatePath(ROUTES.sgExtensions, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`
                    : generatePath(ROUTES.extensions, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`
                }
              >
                <Button
                  variant="round"
                  colorScheme="brand.midnight"
                  onClick={() => dispatch(reduceSetStepCompleteDrawerState('hidden'))}
                >
                  Go to Next Step
                </Button>
              </Link>
            }
          />
          <HelpModal title="Let’s Setup your Extension lines!" guideType={CHECKLIST_CODES.addExtensions} />
          <Box bg="brand.peach.100" p="24px" color="black" style={ROUNDED_ALL_STYLES}>
            <Text variant="title3">ALL BRANDS</Text>
          </Box>
          {allLines &&
            allLines.map((line) => {
              return (
                <LineRow
                  key={line.lineId}
                  line={line}
                  isAdded={extensionsByLine ? !!get(extensionsByLine, line.lineName) : null}
                />
              )
            })}
          {!allLinesLoaded && (
            <Flex justify="center" align="center" h="100px">
              <Spinner mr="20px" />{' '}
            </Flex>
          )}
        </>
      }
    />
  )
}

const LineRow = (props: { line: LineOption; isAdded: boolean | null }) => {
  const dispatch = useDispatch()
  const { line, isAdded } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  const loadingName = buildLoadingName(line.lineName)
  const loadingLine = useAppSelector((state) => selectLoadingState(state, loadingName))
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const addExtensionsChecklistItem = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.addExtensions))

  return (
    <Flex minHeight="65px" justify="space-between" align="center" key={line.lineName} p="12px 5px">
      <Flex align="center" gridGap="12px">
        {line.lineName}
      </Flex>
      {loadingLine ? (
        <Spinner mr="20px" />
      ) : (
        <>
          {isAdded === null && <Spinner mr="20px" />}
          {isAdded === true && (
            <Flex align="center" gridGap={isMobile ? '4px' : '12px'}>
              <Tooltip label="This will not automatically set product prices">
                <Button
                  w={isMobile ? '50px' : ''}
                  variant="round-ghost-upper"
                  colorScheme="brand.midnight"
                  onClick={() => {
                    dispatch(dispatchSetLoadingType({ name: loadingName, state: true }))
                    dispatch(dispatchAddExtensionLines(token, salonId, [line]))
                  }}
                >
                  {isMobile ? 'Update ' : 'Update Extensions'}
                </Button>
              </Tooltip>
              <ConfirmPopover
                title={`This Action is Permanent`}
                subtitle={`Removing this extension line means it will no longer appear in your salon.`}
                onConfirm={() => {
                  dispatch(dispatchDeleteExtensionLine({ token, salonId, lineName: line.lineName }))
                }}
              >
                <Button w="80px" variant="round-outline" colorScheme="brand.midnight">
                  Delete
                </Button>
              </ConfirmPopover>
            </Flex>
          )}
          {isAdded === false && (
            <Button
              w={isMobile ? '40px' : '80px'}
              variant="round-outline"
              colorScheme="brand.midnight"
              onClick={() => {
                if (stepCompleteDrawerState !== HELP_DRAWER_STATES.button) {
                  dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
                }
                dispatch(dispatchSetLoadingType({ name: loadingName, state: true }))
                dispatch(dispatchAddExtensionLines(token, salonId, [line]))
                if (!addExtensionsChecklistItem.completed) {
                  dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: CHECKLIST_CODES.addExtensions}))
                }
              }}
            >
              Add
            </Button>
          )}
        </>
      )}
    </Flex>
  )
}
