import { GetServerBaseUrl } from '../../env'
import axios from 'axios'
import { APIEmptyBowl, EmptyBowl } from './interfaces'
import { mapAPIEmptyBowlsToEmptyBowls, mapAPIEmptyBowlToEmptyBowl, mapBowlToAPICreateBowl } from './mappers'
import { APIPagedLaraResponse, buildLaraConfig, buildLaraPageParams } from '../../mini-lib/lara/lara-utils'
import { Dispatch } from '@reduxjs/toolkit'
import { reduceCreateEmptyBowl, reduceDeleteEmptyBowl, reduceListEmpyBowls, reduceUpdateEmptyBowl } from './slice'

export const apiListEmptyBowls = (params: { token: string; salonId: number }): Promise<EmptyBowl[]> => {
  const { token, salonId } = params
  const config = buildLaraConfig({ token })
  const { pageSizeParam, pageNumberParam } = buildLaraPageParams({ pageSize: 10000 })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/salon-bowls/?${pageSizeParam}&${pageNumberParam}`

  return axios
    .get(url, config)
    .then((response: { data: APIPagedLaraResponse }) => {
      return mapAPIEmptyBowlsToEmptyBowls(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateEmptyBowl = (params: {
  token: string
  salonId: number
  bowl: Partial<EmptyBowl>
}): Promise<EmptyBowl> => {
  const { token, salonId, bowl } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/salon-bowls/`
  const body = mapBowlToAPICreateBowl(bowl)

  return axios
    .post(url, body, config)
    .then((response: { data: { data: APIEmptyBowl } }) => {
      return mapAPIEmptyBowlToEmptyBowl(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateEmptyBowl = (params: { token: string; salonId: number; bowl: EmptyBowl }): Promise<EmptyBowl> => {
  const { token, salonId, bowl } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/salon-bowls/${bowl.id}`
  const body = mapBowlToAPICreateBowl(bowl)

  return axios
    .patch(url, body, config)
    .then((response: { data: { data: APIEmptyBowl } }) => {
      return mapAPIEmptyBowlToEmptyBowl(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteEmptyBowl = (params: { token: string; salonId: number; bowlId: number }): Promise<string> => {
  const { token, salonId, bowlId } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/salon-bowls/${bowlId}`
  return axios
    .delete(url, config)
    .then(() => {
      return 'success'
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchListEmptyBowls = (params: { token: string; salonId: number }) => {
  return (dispatch: Dispatch) => {
    return apiListEmptyBowls(params).then((resp) => {
      dispatch(reduceListEmpyBowls(resp))
    })
  }
}

export const dispatchCreateEmptyBowl = (params: { token: string; salonId: number; bowl: EmptyBowl }) => {
  return (dispatch: Dispatch) => {
    return apiCreateEmptyBowl(params).then((resp) => {
      dispatch(reduceCreateEmptyBowl(resp))
    })
  }
}

export const dispatchUpdateEmptyBowl = (params: { token: string; salonId: number; bowl: EmptyBowl }) => {
  return (dispatch: Dispatch) => {
    return apiUpdateEmptyBowl(params).then((resp) => {
      dispatch(reduceUpdateEmptyBowl(resp))
    })
  }
}

export const dispatchDeleteEmptyBowl = (params: { token: string; salonId: number; bowlId }) => {
  return (dispatch: Dispatch) => {
    return apiDeleteEmptyBowl(params).then((resp) => {
      dispatch(reduceDeleteEmptyBowl(params.bowlId))
    })
  }
}
