import { AccordionButton, AccordionIcon, Box, Circle, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { ReactNode } from 'react'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { UseViewSize } from '../../../core/UseViewSize'
import { useAppSelector } from '../../../hooks'
import { reduceSetCurrentStepCode, selectCurrentStepCode } from '../../../data/start-guide/slice'
import { ChecklistItem } from '../../../data/start-guide/interfaces'
import { useDispatch } from 'react-redux'

export const StepAccordionHeader = (props: {
  checklistItem: ChecklistItem
  label: string
  title: string
  actions: ReactNode
  isDisabled?: boolean
}) => {
  const { isDisabled = false, label, checklistItem, title, actions } = props
  const { isMobile } = UseViewSize()
  const dispatch = useDispatch()
  return (
    <AccordionButton
      p="12px"
      m={0}
      border="none"
      borderRadius="0 0 15px 15px"
      _expanded={{ borderRadius: '0' }}
      onClick={() => dispatch(reduceSetCurrentStepCode(checklistItem.code))}
    >
      <Box w="100%">
        <Flex justify="space-between" width="100%" align="center">
          <Flex align="center" gridGap={isMobile ? '6px' : '24px'}>
            <AccordionNumberIcon isDisabled={isDisabled} label={label} checklistItem={checklistItem} />
            <Box>
              <Text color={isDisabled ? COLORS.text_secondary : ''} fontWeight="bold">
                {title}
              </Text>
            </Box>
          </Flex>

          <Flex align="center" gridGap="24px">
            {!isMobile && <>{actions}</>}
            <AccordionIcon />
          </Flex>
        </Flex>
        {isMobile && <Box p="4px 0 0 24px">{actions}</Box>}
      </Box>
    </AccordionButton>
  )
}

export const AccordionNumberIcon = (props: { label: string; checklistItem: ChecklistItem; isDisabled?: boolean }) => {
  const currentStepCode = useAppSelector(selectCurrentStepCode)
  const { isMobile } = UseViewSize()
  const { label, checklistItem, isDisabled = false } = props

  // if step is disabled show exclamation point
  //
  if (isDisabled) {
    return (
      <Circle size={isMobile ? '20px' : '24px'} m="2px" bg={COLORS.lavender_500_disabled} color="white">
        !
      </Circle>
    )
  }

  // if step is complete show complete
  //
  if (checklistItem && checklistItem.completed) {
    return <MaterialIcon name="check_circle" colorhex={COLORS.lavender_500} size={isMobile ? '22px' : '28px'} />
  }
  // if step is incomplete and the currently selected step show step number
  //
  if (checklistItem && checklistItem.code === currentStepCode) {
    return (
      <Circle size={isMobile ? '20px' : '24px'} m="2px" bg={COLORS.lavender_500} color="white">
        {label}
      </Circle>
    )
  }
  // by default return the basic step number
  //
  return (
    <Circle
      size={isMobile ? '20px' : '24px'}
      m="2px"
      border={`1px solid ${COLORS.lavender_500}`}
      color={COLORS.lavender_500}
    >
      {label}
    </Circle>
  )
}
