import React from 'react'
import { InventoryCountType, InventoryProgressType } from '../../../data/inventory/interfaces'
import { FilterBar } from '../../../mini-lib/filters/FilterBar'
import { SearchFilter } from '../../../mini-lib/filters/SearchFilter'
import {
  EMPTY_INVENTORY_COUNT_FILTERS,
  INVENTORY_COUNT_TYPES,
  INVENTORY_PROGRESS_OPTIONS,
  MANUAL_ORDER_COUNT_TYPE_OPTIONS,
} from '../../../data/inventory/constants'
import { assign } from 'lodash'
import { UseViewSize } from '../../../core/UseViewSize'
import { SelectInput } from "../../../mini-lib";
import { DateInput } from "../../../mini-lib/dates-and-times/DateInput";

export interface CountFilters {
  searchText: string
  countTypes: InventoryCountType[] // this needs to be a list so we can get all but inventory
  progressType: InventoryProgressType
  startDate: Date | null
  endDate: Date | null
}

export const InventoryCountFilters = (props: {
  variant: 'audits' | 'orders and manual'
  filters: CountFilters
  setFilters: (filters: CountFilters) => void
  showDateFilters: boolean
  showCountTypeFilter: boolean
  showProgressFilter: boolean
}) => {
  const { variant, filters, setFilters, showDateFilters, showCountTypeFilter, showProgressFilter } = props
  // this just gets 1 or 0, we just want to know if any are active
  const anyFiltersActive = getAreCountFiltersActive(filters)
  const { isMobile } = UseViewSize()
  return (
    <>
      <FilterBar
        search={
          <SearchFilter
            placeholder="Search by name"
            value={filters.searchText}
            onChange={(searchText) => setFilters({ ...filters, ...{ searchText } })}
            onClear={() => setFilters({ ...filters, ...{ searchText: '' } })}
          />
        }
        filters={
          <>
            {showCountTypeFilter && (
              <SelectInput
                variant='material'
                border='rounded'
                label='Count Type'
                w={isMobile ? '49%' : '150px'}
                placeholder="All Types"
                value={filters.countTypes.length === 1 ? filters.countTypes[0] : ''}
                options={MANUAL_ORDER_COUNT_TYPE_OPTIONS}
                onChange={(option) => {
                  const updatedCountTypes =
                    option === '' ? [INVENTORY_COUNT_TYPES.manual, INVENTORY_COUNT_TYPES.order] : [option]
                  setFilters({ ...filters, ...{ countTypes: updatedCountTypes } })
                }}
                allowFocus={true}
              />
            )}
            {showProgressFilter && (
              <SelectInput
                variant='material'
                border='rounded'
                label='Progress Status'
                w={isMobile ? '49%' : '160px'}
                placeholder="All Progresses"
                value={filters.progressType}
                options={INVENTORY_PROGRESS_OPTIONS}
                onChange={(value) => {
                  setFilters({ ...filters, ...{ progressType: value } })
                }}
                allowFocus={true}
              />
            )}
            {showDateFilters && (
              <>
                <DateInput
                  w={isMobile ? '49%' : '150px'}
                  label='Date Start'
                  value={filters.startDate}
                  onChange={(date) => setFilters({ ...filters, ...{ startDate: date } })}
                  isClearable={true}
                />

                <DateInput
                  w={isMobile ? '49%' : '150px'}
                  label='Date End'
                  value={filters.endDate}
                  onChange={(date) => setFilters({ ...filters, ...{ endDate: date } })}
                  isClearable={true}
                />
              </>
            )}
          </>
        }
        onClear={() => {
          if (variant === 'audits') {
            setFilters(assign({}, EMPTY_INVENTORY_COUNT_FILTERS, { countTypes: [INVENTORY_COUNT_TYPES.inventory] }))
          } else if (variant === 'orders and manual') {
            setFilters(
              assign({}, EMPTY_INVENTORY_COUNT_FILTERS, {
                countTypes: [INVENTORY_COUNT_TYPES.order, INVENTORY_COUNT_TYPES.manual],
              }),
            )
          }
        }}
        numFiltersActive={anyFiltersActive}
      />
    </>
  )
}

export const getAreCountFiltersActive = (filters: CountFilters): number => {
  const { searchText, countTypes, progressType, startDate, endDate } = filters
  if (searchText || progressType || startDate || endDate) {
    return 1
  }
  if (countTypes && countTypes.length === 1 && countTypes[0] === 'inventory') {
    return 0
  }
  if (countTypes && countTypes.length === 2) {
    return 0
  }
  if (countTypes) {
    return 1
  }
  return 0
}
