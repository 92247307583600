import { Box, Button } from '@chakra-ui/react'
import { useRef } from 'react'

export const FileUpload = (props: { override?: any; onFileUpload: (file: File | null) => void }) => {
  const { onFileUpload, override } = props
  // create a reference to the hidden file input element
  const hiddenFileInput: any = useRef(null)

  // programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput?.current?.click()
  }
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      onFileUpload(fileUploaded)
    }
  }
  return (
    <>
      {!override && (
        <Button onClick={handleClick} variant="round">
          Upload a file
        </Button>
      )}
      {/* pass in whatever you want and when the user clicks it the file uploader will work*/}
      <Box onClick={handleClick} cursor="pointer">
        {override}
      </Box>
      <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />
    </>
  )
}
