import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { generatePath, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'

import { Box, Table, TableContainer } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { ReportTableContent, ReportTableFooters, ReportTableHeaders } from '../ReportTable'
import {
  selectColorStylistProducts,
  selectColorStylistWasteProducts,
  selectCustomEndDate,
  selectCustomStartDate,
  selectedUnit,
  selectReportsDateRangeType
} from '../../../data/reports-v2/slice'
import { dispatchGetColorStylistDetailsReport } from '../../../data/reports-v2/api'
import { ReportSectionHeader } from '../ReportSectionHeader'
import { COLORS } from '../../../mini-lib/theme/colors'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { REPORTS_LOADING_CONSTANT } from '../../../data/reports-v2/constants'
import { selectLoadingState } from '../../../core/loading/slice'
import { convertDateRangeTypeToDates } from '../../../mini-lib/dates-and-times/utils'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { buildCsv } from '../../../mini-lib/csv/buildCsv'
import { ColorStylistReport } from '../../../data/reports-v2/interfaces'
import { COLOR_REPORTS_TABS, ColorReportsTabs } from '../../reports-v1/ColorReportsTabs'
import { UseViewSize } from '../../../core/UseViewSize'
import { totalWastePercentageCalculator, pluralizeNameIfRequired } from '../../../data/reports-v2/utils'
import { convertDisplayUnits } from '../../../data/sessions/utils'
import { sumBy } from 'lodash'
import { formatDollars } from "../../../mini-lib/units/money";
import { Units } from '../../../data/reports-v2/interfaces'
import { ROUTES } from "../../../appRoutes";

export const ColorStylistWasteProductReportsPage = () => {
  const params: any = useParams()
  const { stylistId } = params
  const { isMobile } = UseViewSize()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()


  const breadcrumbs = [
    { label: 'Reports', url: generatePath(ROUTES.colorStylistReports, { salonId }) },
    { label: isMobile ? 'Stylist Waste' : 'Stylist Waste Reports', url: generatePath(ROUTES.colorStylistWasteReports, { salonId }) },
    { label: isMobile ? 'Waste Products' : 'Stylist Waste Products Report' },
  ]
  const dispatch = useDispatch()
  const models = useAppSelector(selectColorStylistWasteProducts)
  const totalProducts = useAppSelector(selectColorStylistProducts)
  const currentSelectedUnit: Units = useAppSelector(selectedUnit)
  const currentlyLoading = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))

  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null

  const sumWasteGrams = models ? models.map((model) => model.wasteGrams).reduce((a, b) => Number(a) + Number(b), 0) : 0
  const sumWaste = convertDisplayUnits(sumWasteGrams, 'g', currentSelectedUnit)
  const sumWasteCost = models ? models.map((model) => model.wasteCost).reduce((a, b) => a + b, 0) : 0

  const totalProductGrams =  sumBy(totalProducts, ({totalGrams})=> totalGrams);
  const totalWasteProductGrams =  sumBy(models, ({wasteGrams})=> wasteGrams);
  const totalWastePercentage = totalWastePercentageCalculator(totalWasteProductGrams, totalProductGrams)

  useEffect(() => {
    dispatch(
      dispatchGetColorStylistDetailsReport({
        token,
        salonId,
        dateStart: sDate,
        dateEnd: eDate,
        stylistId,
        kind: 'color',
      }),
    )
  }, [dispatch, token, stylistId, salonId, sDate, eDate])

  const stylistName = models?.[0]?.stylistName ?? ''
  const tableHeaders = [{ label: 'Title' }, { label: 'Waste', variant: 'weight' }, { label: 'Waste %' }, { label: 'Waste $' }]
  const tableProperties = [
    { name: 'type', variant: 'text', size: 's' },
    currentSelectedUnit === 'unit' ? 
    { name: 'wasteQuantity', variant: 'weight'} : 
    { name: 'wasteGrams', variant: 'weight' },
    { name: 'wastePercentage', variant: 'percentage' },
    { name: 'wasteCost', variant: 'money' },
  ]
  const tableFooters = [
    'Total',
    currentSelectedUnit === 'unit' ? "" : sumWaste.toFixed(2) + currentSelectedUnit,
    totalWastePercentage.toFixed(2) + '%',
    '$' + formatDollars(sumWasteCost),
  ]

  const onCsvExport = () => {
    const totals: ColorStylistReport | any = {
      stylist: 'total',
      type: '',
      wasteGrams: currentSelectedUnit === 'unit' ? "" : sumWaste.toFixed(2) + currentSelectedUnit,
      wastePercentage: totalWastePercentage.toFixed(2) + '%',
      wasteCost: '$' + formatDollars(sumWasteCost),
    }
    const formattedModels: ColorStylistReport[] | any[] = models
      ? models.map((model) => {
        const waste = convertDisplayUnits(model.wasteGrams, 'g', currentSelectedUnit)
          return {
            stylist: model.stylistName,
            type: model.type,
            wasteGrams: `${currentSelectedUnit === 'unit' ? model.wasteQuantity : waste}${' '}${pluralizeNameIfRequired(currentSelectedUnit, model.wasteQuantity)}`,
            wastePercentage: model.wastePercentage + '%',
            wasteCost: '$' + formatDollars(model.wasteCost),
          }
        })
      : []
    const rows: any = formattedModels ? [...formattedModels, totals] : []
    const tableHeadersForCsv = ['Stylist', 'Title', `Waste (${currentSelectedUnit})`, 'Waste %', 'Waste $']
    buildCsv('color brand waste products report', tableHeadersForCsv, rows)
  }

  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Color Reports" breadcrumbs={breadcrumbs} />}
      content={
        <>
          {!isMobile && <Box h="24px" />}
          <ColorReportsTabs selectedTab={COLOR_REPORTS_TABS.colorStylist} onCsvExport={onCsvExport} />
          <Box h="24px" />
          <ReportSectionHeader
            label={stylistName ? `${stylistName} waste` : ''}
            bgHex={COLORS.lavender_100}
            backButtonUrl={generatePath(ROUTES.colorStylistWasteReports, { salonId })}
          />

          {!currentlyLoading && !!models && models.length > 0 && (
            <TableContainer>
              <Table>
                <ReportTableHeaders headers={tableHeaders} />
                <ReportTableContent properties={tableProperties} models={models} />
                <ReportTableFooters footers={tableFooters} />
              </Table>
            </TableContainer>
          )}
          {!currentlyLoading && !!models && models.length === 0 && (
            <EmptyBox content="No colors were used in this time period" />
          )}
          <Box h="24px" />
          {(!models || currentlyLoading) && <Loading />}
        </>
      }
    />
  )
}
