import { Box, Flex, Tooltip, Text } from '@chakra-ui/react'
import { Fragment } from 'react';

import { formatDollars } from "../../mini-lib/units/money";

const WIDTH = 120
const calculateWidth = (list) => list.reduce((w, currentValue) => w + (currentValue?.additionalPorps?.width ?? WIDTH), 0)

export const TableHead: any = ({ list, minWidth = '280px', noAction = false }) => {
  const thProps: any = {
    color: 'text.secondary',
    fontWeight: 'normal',
    fontFamily: 'fonts.body',
    textTransform: 'lowercase',
    fontSize: '16px',
    padding: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
  const mainColumn = list.splice(0, 1);
  const maxWidth = `calc(100% - ${calculateWidth(list)}px)`
  return (
    <Flex justifyContent={'space-between'}>
      <Box minWidth={minWidth} maxWidth={maxWidth} {...thProps}>{mainColumn[0].label}</Box>
      <Flex>
        {list.map(({ label, additionalProps }, index) => {
          const width = `${additionalProps?.width ?? WIDTH}px`;
          return (<Box key={index} width={width} {...thProps}>{label}</Box>)
        })}
        {!noAction && <Box width={'85px'} {...thProps} padding={'1rem 0px'}></Box>}
      </Flex>
    </Flex>
  )
};

export const TableContent: any = ({ action = null, list, minWidth = '280px', id }) => {
  const mainColumn = list.splice(0, 1);
  const maxWidth = `calc(100% - ${calculateWidth(list)}px)`
  const thProps: any = {
    fontSize: '16px',
    padding: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
  return (
    <Fragment key={id}>
      <Flex borderBottom="1px solid #BDBDBD" justifyContent={'space-between'}>
        <Box minWidth={minWidth} maxWidth={maxWidth} {...thProps}>
          <Tooltip label={mainColumn[0].value}>
            {mainColumn[0].value}
          </Tooltip>
        </Box>
        <Flex>
          {list.map(({ value, additionalProps }, index) => {
            const width = `${additionalProps?.width ?? WIDTH}px`;
            const formatedNumber = additionalProps?.isNumeric ? `$${formatDollars(value)}` : value
            return (
              <Box key={index} width={additionalProps?.width ?? width} {...thProps}>
                <>
                  <Tooltip label={formatedNumber}>
                    {String(formatedNumber)}
                  </Tooltip>
                </>
              </Box>
            )
          })}
          {action && <Flex justifyContent={'center'} width={'85px'} {...thProps}>
            {action}
          </Flex>}
        </Flex>
      </Flex>
    </Fragment>
  )
};

export const TableTotal: any = ({ list, minWidth = '280px', noAction = false }) => {
  const maxWidth = `calc(100% - ${calculateWidth(list)}px)`
  const thProps: any = {
    fontSize: '16px',
    padding: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
  return (
    <Flex marginBottom={'2rem'} justifyContent={'space-between'}>
      <Box minWidth={minWidth} maxWidth={maxWidth} {...thProps}>
        <Tooltip label={'Total'}>
          <Text fontWeight="bold">Total</Text>
        </Tooltip>
      </Box>
      <Flex>
        {list.map(({ value, additionalProps }, index) => {
          const width = `${additionalProps?.width ?? WIDTH}px`;
          if (additionalProps?.noTotal) {
            return (<Box key={index} fontWeight="bold" width={width} {...thProps}>{ }</Box>)
          }
          const formatedNumber = additionalProps?.isNumeric ? `$${formatDollars(value)}` : value
          return (
            <Box key={index} fontWeight="bold" width={additionalProps?.width ?? width} {...thProps}>
              <>
                <Tooltip label={formatedNumber}>
                  {String(formatedNumber)}
                </Tooltip>
              </>
            </Box>
          )
        })}
        {!noAction && <Box width={'85px'} {...thProps} padding={'1rem 0px'}></Box>}
      </Flex>
    </Flex>
  )
};
