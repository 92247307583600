import { CountFilters } from '../../pages/inventory/filters/InventoryCountFilters'
import { ItemFilters } from '../../pages/inventory/filters/InventoryItemFilters'
import { STOCK_LEVEL_TYPES } from '../products/interfaces'

export const INVENTORY_COUNT_TYPES = {
  inventory: 'inventory',
  manual: 'manual',
  order: 'order',
  all: '',
}
export const MANUAL_ORDER_COUNT_TYPE_OPTIONS = [
  // { label: 'All Types', value: INVENTORY_COUNT_TYPES.all },
  { label: 'Orders', value: INVENTORY_COUNT_TYPES.order },
  { label: 'Manual', value: INVENTORY_COUNT_TYPES.manual },
]

export const INVENTORY_PROGRESS_TYPES = {
  completed: 'completed',
  inProgress: 'in progress',
  all: '',
}
export const INVENTORY_PROGRESS_OPTIONS = [
  // { label: 'All Progresses', value: INVENTORY_PROGRESS_TYPES.all },
  { label: 'Completed', value: INVENTORY_PROGRESS_TYPES.completed },
  { label: 'In Progress', value: INVENTORY_PROGRESS_TYPES.inProgress },
]

export const STOCK_LEVEL_OPTIONS = [
  { label: 'Out of Stock', value: STOCK_LEVEL_TYPES.out },
  { label: 'Low Stock', value: STOCK_LEVEL_TYPES.low },
  { label: 'Over Stocked', value: STOCK_LEVEL_TYPES.over },
  // { label: 'Stock Ok', value: STOCK_LEVEL_TYPES.normal },
]
export const INVENTORY_ADD_OR_ORDER_COUNT_REASONS = [
  { label: 'Received', value: 'received' },
  { label: 'Not Recieved', value: 'not received' },
  { label: 'Partially Received', value: 'partially received' },
  { label: 'Back Ordered', value: 'back ordered' },
]
export const INVENTORY_AUDIT_REASONS = [
  { label: 'Expired', value: 'expired' },
  { label: 'Spillage', value: 'spillage' },
  { label: 'Shrinkage', value: 'shrinkage' },
  { label: 'Other', value: 'other' },
]
export const INVENTORY_CREATE_LOADING_CONSTANT = 'loading-create-inventory'
export const INVENTORY_LOADING_CONSTANT = 'loading-inventory'
export const INVENTORY_LOADING_REASON = 'loading-inventory-reason'

export const EMPTY_INVENTORY_COUNT_FILTERS: CountFilters = {
  searchText: '',
  countTypes: [],
  progressType: '',
  startDate: null,
  endDate: null,
}

export const EMPTY_INVENTORY_ITEM_FILTERS: ItemFilters = {
  searchText: '',
  vendor: '',
  line: '',
  category: '',
  reasonType: '',
  countedState: '',
}
