import {
  Box, Divider, Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TextInput } from '../form/TextInput'
import { COLORS } from '../theme/colors'
import { generatePath, Link, useLocation } from 'react-router-dom'
import { ROUTES } from '../../appRoutes'
import { UseViewSize } from '../../core/UseViewSize'
import { SearchIcon } from "@chakra-ui/icons";
import { LoggedInUserSalonPermissions } from "../../data/user/interfaces";
import { USER_PERMISSIONS } from "../../data/user/constants";

export const SearchAnything = (props: {userPermissions: LoggedInUserSalonPermissions}) => {
  const { userPermissions } = props
  const { salonId } = UseBaseApiParams()
  const [searchText, setSearchText] = useState('')
  const { isMobile } = UseViewSize()

  const location = useLocation()
  const isTrial = location.pathname.includes('trial') || location.pathname.includes('checkout')

  const onClose = (delayMilliseconds: number) => {
    // adding timeout because it resets the text so fast that it's removed from the link query params
    setTimeout(() => {
      setSearchText('')
    }, delayMilliseconds) // delay in milliseconds
  }
  return (
    <Box w={isMobile ? '150px' : '400px'}>
      {/*
        todo: remove trigger hover when focus is controlled and stays on the TextInput
        for now trigger='hover' prevents focus from going to the popover when user starts typing
      */}
      <Popover isOpen={searchText.length > 0} trigger="hover" placement={isMobile ? 'bottom' : 'bottom-start'}>
        <PopoverTrigger>
          <Box>
            <TextInput
              theme="lavender"
              variant="material"
              border="rounded"
              label={
                <Flex align="center" gridGap="4px">
                  <SearchIcon color={COLORS.lavender_500} /> {isMobile ? 'Search' : 'Search anything'}
                </Flex>
              }
              placeholder="Search anything"
              value={searchText}
              onChange={setSearchText}
              onClear={() => setSearchText('')}
              allowFocus={true}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent w={isMobile ? '90vw' : '400px'}>
          <PopoverArrow />
          <PopoverBody m={0} p={0}>
            {!isTrial && userPermissions.permissions.includes(USER_PERMISSIONS.colorLines) && (
              <Link to={generatePath(ROUTES.lines, { salonId }) + `?search=${searchText} `}>
                <SearchItem label="Search colors for" searchText={searchText} onClose={() => onClose(100)} />
              </Link>
            )}

            {/*<Link to={generatePath(ROUTES.addLinesV2, { salonId }) + `?search=${searchText} `}>*/}
            {/*  <SearchItem label="Search new colors for" searchText={searchText} onClose={() => onClose(100)} />*/}
            {/*</Link>*/}
            {!isTrial && userPermissions.permissions.includes(USER_PERMISSIONS.stylists) && (
              <Link to={generatePath(ROUTES.stylists, { salonId }) + `?search=${searchText} `}>
                <SearchItem label="Search stylists for" searchText={searchText} onClose={() => onClose(100)} />
              </Link>
            )}
            {!isTrial && (
              <Link to={generatePath(ROUTES.clients, { salonId }) + `?search=${searchText} `}>
                <SearchItem label="Search clients for" searchText={searchText} onClose={() => onClose(100)} />
              </Link>
            )}

            <Divider />
            <a
              href={`https://helpcenter.salonscale.com/en/?q=${searchText.toLowerCase().trim()}`}
              target="_blank"
              rel="noreferrer"
            >
              <SearchItem label="Search our help center for" searchText={searchText} onClose={() => onClose(100)} />
            </a>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export const SearchItem = ({ label, searchText, onClose }) => {
  return (
    <Box cursor="pointer" _hover={{ bg: COLORS.lavender_100 }} p="12px" onClick={onClose}>
      {label}: '
      <Text as="span" color={COLORS.lavender_500}>
        {searchText}
      </Text>
      '
    </Box>
  )
}
