import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Loading } from '../../mini-lib/loading/Loading'
import { Box, Flex, Text } from '@chakra-ui/react'
import {  OrderLara } from '../../data/orders/interfaces'
import { dispatchListOrdersLara } from '../../data/orders/api'
import { filterOrdersLara } from '../../data/orders/utils'
import { selectOrdersLara } from '../../data/orders/slice'
import { COLORS } from '../../mini-lib/theme/colors'
import { dispatchListProducts } from "../../data/products/api";
import { ReleaseLaraColors } from "../../mini-lib/flags/Release";
import { selectProductsById } from "../../data/products/slice";
import { SelectInput } from "../../mini-lib";

export const OrderAutocomplete = (props: {
  selected?: OrderLara | null
  onSelect: (order: OrderLara | null) => void
  width?: string
  isClearable?: boolean
  disabled?: boolean
}) => {
  const dispatch = useDispatch()
  const { selected, onSelect, width = '350px', isClearable = true, disabled = false } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const models = useAppSelector(selectOrdersLara)
  const releaseLaraColors = ReleaseLaraColors()
  const [searchText, setSearchText] = useState('')
  const productsById = useAppSelector(selectProductsById)

  const filteredModels = models && productsById ? filterOrdersLara(models, productsById, searchText, null, null) : []
  const modelsLoaded = !!models
  const [localSelected, setLocalSelected] = useState<OrderLara | null>(null)

  useEffect(() => {
    if (!modelsLoaded) {
      dispatch(dispatchListOrdersLara({token, salonId}))
    }
  }, [dispatch, modelsLoaded, token, salonId])

    useEffect(() => {
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, releaseLaraColors])

  const dropdownOptions: { value: OrderLara; label: any }[] | null = models
    ? filteredModels.map((model) => {
        return {
          value: model,
          label: (
            <Flex justify='space-between' align='center'>
              <Text>{model.name}</Text>
              <Text fontSize="12px" color={COLORS.text_secondary}>
                {model.orderDate.toDateString()}
              </Text>
            </Flex>
          ),
        }
      })
    : null

  return (
    <Box w={width} maxW="100%">
      {disabled && (
        <Box w="100%" isTruncated={true}>
          {selected ? selected.orderDate.toDateString() : ''}
        </Box>
      )}
      {!disabled && dropdownOptions && (
        <SelectInput
          allowFocus={true}
          variant='material'
          value={localSelected}
          label="Order"
          options={dropdownOptions}
          onInputChange={(searchText: string) => {
            setSearchText(searchText)
          }}
          onChange={(option: OrderLara) => {
            if (option) {
              setLocalSelected(option)
              onSelect(option)
            } else {
              setLocalSelected(null)
              onSelect(null)
            }
          }}
          isClearable={isClearable}
        />
      )}
      {!dropdownOptions && <Loading />}
    </Box>
  )
}
