import React, { HTMLAttributes, ReactNode, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'

import 'react-datepicker/dist/react-datepicker.css'
import './DateInput.css'
import { buildDateYYYYMMDD } from '../../core/dates'
import ReactDatePicker from 'react-datepicker'
import { MaterialLabel } from "../form/MaterialHelpers";
import { uniqueId } from "lodash";
import { COLORS } from "../theme/colors";
import { BASE_INPUT_STYLES } from "../../styles";
import { MaterialIcon } from '../icons/MaterialIcon'
import { InputError } from "../form/InputError";

interface Props {
  value: Date | null
  onChange: (date: Date) => void
  label?: string | ReactNode
  placeholder?: string

  onBlur?: (date: Date) => void
  isClearable?: boolean
  showTimeSelect?: boolean
  showPopperArrow?: boolean
  onKeyDown?: (evt: any) => void
  w?: string
  disabled?: boolean
  theme?: string
  errorText?: string
  allowFocus?: boolean
}
// ref - https://gist.github.com/igoro00/99e9d244677ccafbf39667c24b5b35ed

export const DateInput = ({
  showTimeSelect = false,
  value,
  label = '',
  onChange,
  onBlur = () => {},
  isClearable = false,
  showPopperArrow = false,
  placeholder,
  w,
  disabled = false,
  theme,
  errorText = '',
  allowFocus = true,
  ...props
}: (Props & HTMLAttributes<HTMLElement>)) => {
  const elementId = uniqueId('formfield-')
  const themeColor = theme === 'basic' ? 'black' : COLORS[`${theme}_500`]
  const [isFocused, setIsFocused] = useState(false)
  const normalizedW = w && !w.includes('%') ? parseInt(w.slice(0, -2)) - 20 + 'px' : '144px'
  const labelWithDownArrow = label && typeof label === 'string'
    ? value || isFocused
      ? label
      : <Flex justify='space-between' align='center' w={normalizedW}><Box>{label}</Box><MaterialIcon pr="4px" name={'expand_more'} /></Flex>
    : label
  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleChange = ( date: Date) => {
    onChange(date)
    setIsFocused(false)
  }
  const handleBlur = ( date: Date) => {
    onBlur(date)
    setIsFocused(false)
  }

  return (
    <Flex className={'light-theme'} w={w} h={BASE_INPUT_STYLES.height} align='center'>
      {disabled && value ? (
        <>{buildDateYYYYMMDD(value)}</>
      ) : (
        <Box position='relative' width='100%'>
          <MaterialLabel
            label={labelWithDownArrow}
            elementId={elementId}
            isDisabled={false}
            hasContent={!!value}
            themeColor={themeColor}
            isFocused={isFocused}
            allowFocus={allowFocus}
          />
          <ReactDatePicker
            placeholderText={placeholder}
            showTimeSelect={showTimeSelect}
            selected={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            isClearable={isClearable}
            showPopperArrow={showPopperArrow}
            wrapperClassName={`react-datepicker__input-container react-datepicker__input-container__lavender ${!!errorText ? 'error' : ''} `}
            className={`react-datapicker__input-text`} //input is white by default and there is no already defined class for it so I created a new one
            dateFormat={'MMM dd yyyy'}
            {...props}
          />
        </Box>
      )}
      <InputError showError={!!errorText} errorText={errorText}/>
    </Flex>
  )
}
