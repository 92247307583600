import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { sgInventoryStepCodes } from './SGIndex/SGInventoryIndex'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGInventoryOnHandIntroPage = () => {
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, sgInventoryStepCodes))
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="implementation"
          title="Set Your Backbar On-Hand"
          description={descriptionText}
          video={VIDEO_MAP.inventoryCount}
          actions={
            <SGActionButtons
              action={{
                label: 'Set Backbar OnHand',
                url: generatePath(ROUTES.sgInventoryOnHand, { salonId }) + `?guide=${CHECKLIST_CODES.inventorySetOnHand}`,
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={sgInventoryStepCodes.length} stepsComplete={stepsCompleted || 0} />}
          imgPath={require('../../assets/start-guide/backbar-onhand.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `Next is to complete your first count! This step is important because it lets us know what you currently have at your backbar so we can accurately track what is being used and what is being replenished.

We recommend completing this step when you have some downtime or when the salon is closed, as you will want to have accurate information! This step can be completed in the business center or the mobile app. Scan the UPS or manually update your inventory and collaborate with your team to complete inventory counts.

`
