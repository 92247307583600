import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { assign, keys, omit, orderBy } from 'lodash'
import { ColorForMode } from '../../theme'
import {
  reduceExtensionsBulkUpdateSheetVisibility,
  reduceExtensionsSheetVisibility,
  reduceSelectedExtensionsById,
  reduceSelectedExtensionsByIdBulkUpdate,
  reduceSetSelectedFilters,
  selectExtensionsForSelectedFilters,
  selectExtensionsSheetVisibility,
  selectSelectedExtensionsById
} from '../../data/extensions/slice'
import { Extension } from '../../data/extensions/interfaces'
import { UseViewSize } from '../../core/UseViewSize'
import { ExtensionAutoSaveFormTitleRow, ExtensionsAutoSaveForm } from './ExtensionsAutoSaveForm'
import { COLORS } from '../../mini-lib/theme/colors'
import { DebouncedInput } from "../../mini-lib/debounced/DebouncedInput";
import { SearchIcon } from "@chakra-ui/icons";
import { filterExtensions } from "../../data/extensions/utils";
import { EMPTY_EXTENSION_FILTERS } from "../../data/extensions/constants";
import { Gap } from "../../mini-lib/gap/Gap";
import { ReleaseExtensionsV2 } from "../../mini-lib/flags/Release";

export const ExtensionsSheet = () => {
  const dispatch = useDispatch()
  const extensions = useAppSelector(selectExtensionsForSelectedFilters)
  const [searchText, setSearchText] = useState('')
  const bgColor = ColorForMode('card-bg')
  const onClose = () => {
    dispatch(reduceSetSelectedFilters(null))
    dispatch(reduceExtensionsSheetVisibility(false))
  }
  const releaseExtensionsV2 = ReleaseExtensionsV2()
  const filteredExtensions = filterExtensions(extensions, { ...EMPTY_EXTENSION_FILTERS, searchText })
  const selectedExtensionsById = useAppSelector(selectSelectedExtensionsById)
  const sheetVisible = useAppSelector(selectExtensionsSheetVisibility)
  const numSelectedExtensions = keys(selectedExtensionsById).length
  const [allSelected, setAllSelected] = useState(false)
  const { isMobile } = UseViewSize()
  const identifierWidth = isMobile ? '100%' : '250px'

  const toggleExtensionSelected = ( extensionId: number ) => {
    if (extensionId in selectedExtensionsById) {
      const updatedMap = omit(selectedExtensionsById, [extensionId])
      dispatch(reduceSelectedExtensionsById(updatedMap))
    } else {
      const updatedMap = assign({}, selectedExtensionsById, { [extensionId]: true })
      dispatch(reduceSelectedExtensionsById(updatedMap))
    }
  }
  const selectAllExtensions = () => {
    const allIdMap = {}
    extensions.forEach(( extension ) => {
      allIdMap[extension.id] = true
    })
    dispatch(reduceSelectedExtensionsById(allIdMap))
  }
  const isChecked = ( extensionId: number ): boolean => {
    return !!selectedExtensionsById[extensionId]
  }
  const toggleAllSelected = () => {
    if (allSelected) {
      setAllSelected(false)
      dispatch(reduceSelectedExtensionsById({}))
    } else {
      setAllSelected(true)
      selectAllExtensions()
    }
  }
  return (
    <Drawer
      size="lg"
      isOpen={sheetVisible}
      placement="right"
      autoFocus={false}
      onClose={onClose}
    >
      {extensions && extensions.length > 0 && (
        <>
          <DrawerOverlay/>
          <DrawerContent overflow="scroll" bg={bgColor} p={0}>
            <DrawerHeader p={0}>
              <Button variant="round-ghost" onClick={onClose} position="fixed" top="12px" right="12px">
                Close
              </Button>
              <Flex justify="center" direction='column' gridGap='12px' align="center" p='48px 24px 24px 24px'
                    bg={COLORS.peach_50}>
                <Text variant="largetitle" textTransform="uppercase">
                  {extensions[0].line.name}
                </Text>
                <Text fontSize="20px" fontWeight="normal" p="0 12px" textAlign="center">
                  {extensions[0].size}" • {extensions[0].category}
                </Text>
                {/*<BulkUpdateDialog*/}
                {/*  extensions={extensions}*/}
                {/*  title={`Set ${extensions[0].category} Prices and Markup`}*/}
                {/*  subtitle={`All ${extensions[0].category} extensions will be affected`}*/}
                {/*>*/}
                {/*  <Button isTruncated variant="round-outline" color="brand.peach.200" borderColor="brand.peach.200">*/}
                {/*    {isMobile ? 'Edit All' : 'Edit all Prices'}*/}
                {/*  </Button>*/}
                {/*</BulkUpdateDialog>*/}
                <DebouncedInput
                  backgroundhex="white"
                  inputLeftElement={<SearchIcon color={COLORS.placeholder}/>}
                  width="100%"
                  initialValue={searchText}
                  onValueChange={( searchText ) => {
                    setSearchText(searchText)
                    // dispatch(reduceSetProductFilters({ ...filters, ...{ searchText } }))
                  }}
                />
              </Flex>
            </DrawerHeader>

            <Box p='24px'>
              {releaseExtensionsV2 && (
                <Flex justify='space-between' align='center' h='48px'>
                  <Checkbox
                    isChecked={allSelected}
                    colorScheme="brand.lavender"
                    onChange={toggleAllSelected}
                    fontStyle="italic"
                    fontWeight="bold"
                  >
                    Select All {extensions.length} Sub Items
                  </Checkbox>
                  {numSelectedExtensions > 0 && ( <Button onClick={() => {
                    dispatch(reduceSelectedExtensionsByIdBulkUpdate(selectedExtensionsById))
                    setAllSelected(false)
                    dispatch(reduceExtensionsSheetVisibility(false))
                    dispatch(reduceExtensionsBulkUpdateSheetVisibility(true))
                  }} variant='round' colorScheme='brand.midnight'>Edit Extensions ({numSelectedExtensions})</Button> )}
                </Flex>
              )}
              <Gap/>

              <Flex>
                {!isMobile && <Box w={identifierWidth} minW={identifierWidth}/>}
                <ExtensionAutoSaveFormTitleRow showDelete={releaseExtensionsV2}/>
              </Flex>
              {filteredExtensions.map(( extension, index ) => {
                return (
                  <Box key={extension.id}>
                    {isMobile && <><Gap/></>}
                  <ExtensionRow
                    identifierWidth={identifierWidth}
                    isChecked={isChecked}
                    toggleExtensionSelected={toggleExtensionSelected}
                    extensions={[extension]}
                  />
                  {isMobile && <><Gap/><Divider/></>}
                  </Box>
                )
              })}
            </Box>
          </DrawerContent>
        </>
      )}
    </Drawer>
  )
}

export const ExtensionRow = ( props: {
  extensions: Extension[]
  toggleExtensionSelected: any
  isChecked: any
  identifierWidth: string
} ) => {
  const {
    extensions,
    isChecked,
    toggleExtensionSelected,
    identifierWidth
  } = props
  const { isMobile } = UseViewSize()
  const sorter = ( extension ) => extension.type
  const sortedExtensions = extensions ? orderBy(extensions, [sorter], ['asc']) : []
  const releaseExtensionsV2 = ReleaseExtensionsV2()
  return (
    <Box>
      {sortedExtensions.map(( extension, index ) => {
        return (
          <Flex
            key={extension.id}
            align="center"
            justify="center"
            gridGap="12px"
            mt="12px"
            wrap={isMobile ? 'wrap' : 'nowrap'}
          >
            <Flex align="center" w={identifierWidth} minW={identifierWidth}>
              {releaseExtensionsV2
                ? <Checkbox
                  colorScheme="brand.lavender"
                  isChecked={isChecked(extension.id)}
                  onChange={() => toggleExtensionSelected(extension.id)}
                >
                  <Text fontSize="sm">{extension.type}</Text>
                </Checkbox>
                : <Text fontSize="sm">{extension.type}</Text>
              }
            </Flex>
            <ExtensionsAutoSaveForm extensions={[extension]} showDelete={releaseExtensionsV2}/>
          </Flex>
        )
      })}
    </Box>
  )
}

export const BulkUpdateDialog = ( props: {
  children: any
  title: string
  subtitle: string
  extensions: Extension[]
} ) => {
  const { children, title, subtitle, extensions } = props
  const { onOpen, onClose, isOpen } = useDisclosure()
  return (
    <Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow/>
        <PopoverCloseButton/>
        <PopoverBody>
          <Flex p="24px 0" direction="column">
            <Text p="0 0 24px 0" fontSize="lg">
              {title}
            </Text>
            <Text p="0 0 24px 0" fontSize="sm">
              {subtitle}
            </Text>
            <Box h="12px"/>
            <ExtensionsAutoSaveForm extensions={extensions}/>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
