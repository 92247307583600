import { Flex } from '@chakra-ui/react'

export const COLOR_STYLIST_PRODUCTS_DETAILS_REPORTS_TABS = {
  products: 0,
  wasteProducts: 1,
}
export const ColorStylistReportsTabs = (props: { selectedTab: number }) => {
  return (
    <Flex
      w="100%"
      direction={'row'}
      justify="space-between"
      pb="4px"
    >
    </Flex>
  )
}
