// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, omit } from 'lodash'

import { RootState } from '../../store'

// state
//
interface FileUploadState {
  uploadsByName: { [key: string]: string }
}

const initialState: FileUploadState = {
  uploadsByName: {},
}

// reducer
//
export const FileUploadSlice = createSlice({
  name: 'fileupload',
  initialState,
  reducers: {
    reduceFileUpload: (state, action: PayloadAction<{ uploadName: string; fileUrl: string }>) => {
      state.uploadsByName = assign({}, state.uploadsByName, { [action.payload.uploadName]: action.payload.fileUrl })
    },
    reduceRemoveFileUpload: (state, action: PayloadAction< string >) => {
      state.uploadsByName = omit(state.uploadsByName, action.payload)
    },
  },
})

// actions
//
export const {reduceFileUpload, reduceRemoveFileUpload} = FileUploadSlice.actions

// selectors
//
export const selectFileUrlUploadByName = (state: RootState, uploadName: string): string | null => {
  return state.fileupload.uploadsByName.hasOwnProperty(uploadName) ? state.fileupload.uploadsByName[uploadName] : null
}

// export
//
export default FileUploadSlice.reducer
