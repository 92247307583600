// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {assign} from 'lodash'

import {RootState} from '../../store'
import {LoadingType} from './interfaces'

// state
//
interface LoadingState {
  loadingStatesById: { [key: string]: boolean }
}

const initialState: LoadingState = {
  loadingStatesById: {},
}

// reducer
//
export const LoadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    reduceSetLoadingState: (state, action: PayloadAction<LoadingType>) => {
      state.loadingStatesById = assign({}, state.loadingStatesById, { [action.payload.name]: action.payload.state })
    },
  },
})

// actions
//
export const { reduceSetLoadingState } = LoadingSlice.actions

// selectors
//
export const selectLoadingState = (state: RootState, loadingTypeName: string): boolean => {
  const loading = state.loading.loadingStatesById[loadingTypeName]
  return !!loading
}

// export
//
export default LoadingSlice.reducer
