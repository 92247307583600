// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, keyBy, keys } from 'lodash'

import { RootState } from '../../store'
import { ChecklistItem, DrawerState } from './interfaces'
import { CHECKLIST_CODES, NUMBER_OWNER_CHECKLIST_STEPS } from './constants'
import { HELP_DRAWER_STATES, HelpDrawerState } from "../../pages/start-guide/common-components/HelpDrawer";

// state
//
interface StartGuideState {
  checklistItemsByCode: { [key: string]: ChecklistItem } | null
  currentStepCode: string
  stepCompleteDrawerState: DrawerState
  currentSubStepIndex: number
  helpDrawerState: HelpDrawerState
}

const initialState: StartGuideState = {
  checklistItemsByCode: null, // undefined is an accident, null is a choice, this lets us know when something is loading
  currentStepCode: CHECKLIST_CODES.welcomeVideo,
  stepCompleteDrawerState: 'hidden',
  helpDrawerState: HELP_DRAWER_STATES.hidden,
  currentSubStepIndex: 0,
}

// reducer
//
export const StartGuideSlice = createSlice({
  name: 'start-guide',
  initialState,
  reducers: {
    reduceUserChecklistItems: (state, action: PayloadAction<ChecklistItem[]>) => {
      state.checklistItemsByCode = assign({}, state.checklistItemsByCode, keyBy(action.payload, 'code'))
    },
    reduceUpdateChecklistItem: (state, action: PayloadAction<ChecklistItem>) => {
      state.checklistItemsByCode = assign({}, state.checklistItemsByCode, { [action.payload.code]: action.payload })
    },
    reduceSetCurrentStepCode: (state, action: PayloadAction<string>) => {
      state.currentStepCode = action.payload
    },
    reduceSetStepCompleteDrawerState: (state, action: PayloadAction<DrawerState>) => {
      state.stepCompleteDrawerState = action.payload
    },
    reduceHelpDrawerState: (state, action: PayloadAction<HelpDrawerState>) => {
      state.helpDrawerState = action.payload
    },
    reduceSetCurrentSubStepIndex: (state, action: PayloadAction<number>) => {
      state.currentSubStepIndex = action.payload
    },
  },
})

// actions
//
export const {
  reduceUserChecklistItems,
  reduceUpdateChecklistItem,
  reduceSetCurrentStepCode,
  reduceSetStepCompleteDrawerState,
  reduceSetCurrentSubStepIndex,
  reduceHelpDrawerState,
} = StartGuideSlice.actions
// selectors
//
export const selectChecklistItemsByCode = (state: RootState): { [key: string]: ChecklistItem } | null => {
  return state.startGuide.checklistItemsByCode
}
export const selectChecklistItem = (state: RootState, code: string): ChecklistItem => {
  const defaultChecklistItem = { id: -1, code, completed: false }
  return state.startGuide.checklistItemsByCode && state.startGuide.checklistItemsByCode[code]
    ? state.startGuide.checklistItemsByCode[code]
    : defaultChecklistItem
}

export const selectStepsCompleted = (state: RootState, codes: string[]): number | null => {
  if (state.startGuide.checklistItemsByCode) {
    let numCompleted = 0
    codes.forEach((code) => {
      const checklistItem = state.startGuide.checklistItemsByCode ? state.startGuide.checklistItemsByCode[code] : null
      if (checklistItem && checklistItem.completed) {
        numCompleted++
      }
    })
    return numCompleted
  }
  return null
}
export const selectIsChecklistCompete = (state: RootState): boolean | null => {
  if (state.startGuide.checklistItemsByCode) {
    const numCompleted = keys(state.startGuide.checklistItemsByCode).length
    return numCompleted >= NUMBER_OWNER_CHECKLIST_STEPS
  }
  return null
}
export const selectCurrentStepCode = (state: RootState): string => {
  return state.startGuide.currentStepCode
}
export const selectCurrentSubStepIndex = (state: RootState): number => {
  return state.startGuide.currentSubStepIndex
}

export const selectStepCompleteDrawerState = (state: RootState): DrawerState => {
  return state.startGuide.stepCompleteDrawerState
}

export const selectHelpDrawerState = (state: RootState): HelpDrawerState => {
  return state.startGuide.helpDrawerState
}
// export
//
export default StartGuideSlice.reducer
