import { DateInterval, DateRangeType, MONTHS } from './constants'

export const convertDateRangeTypeToDates = (
  option: DateRangeType,
): { start: Date; end: Date; dataInterval: DateInterval } => {
  const currentDate = new Date()
  const end = new Date()
  let start: Date
  let dataInterval: DateInterval

  switch (option) {
    case 'all':
      start = new Date(currentDate.getFullYear() - 5, currentDate.getMonth(), currentDate.getDate())
      dataInterval = 'month'
      break
    case 'year':
      start = new Date(currentDate.getFullYear(), MONTHS.january, 1)
      dataInterval = 'month'
      break
    case 'month':
      start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      dataInterval = 'date'
      break
    case 'twoweeks':
      start = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 14)
      dataInterval = 'date'
      break
    case 'fourweeks':
      start = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 28)
      dataInterval = 'date'
      break
    case 'week':
      start = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7)
      dataInterval = 'date'
      break
    case 'day':
      start = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
      dataInterval = 'date'
      break
    default:
      start = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
      dataInterval = 'date'
  }
  return { start, end, dataInterval }
}
