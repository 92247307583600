import { UseQueryParams } from '../../mini-lib/utils/basic'

// ref - these are all the known query params a GRIN link requires to successfully call the grinConversion function
//       they need to stay on the url because the grin integration script grabs them from there
export const GRIN_KEYS = {
  platform: 'platform',
  linkId: 'link_id',
  token: 'token',
  contactId: 'contact_id',
  attributionWindow: 'attribution_window',
}

export interface GrinParams {
  platform: string
  linkId: string
  token: string
  contactId: string
  attributionWindow: string
}

export const UseGrinQueryParams = () => {
  const queryParams = UseQueryParams()
  const platform = queryParams.get(GRIN_KEYS.platform)
  const linkId = queryParams.get(GRIN_KEYS.linkId)
  const token = queryParams.get(GRIN_KEYS.token)
  const contactId = queryParams.get(GRIN_KEYS.contactId)
  const attributionWindow = queryParams.get(GRIN_KEYS.attributionWindow)
  return { platform, linkId, token, contactId, attributionWindow }
}

export const buildGrinQueryParamString = (params: GrinParams): string => {
  const { platform, linkId, token, contactId, attributionWindow } = params
  let qp = ''
  if (platform) {
    qp += `${GRIN_KEYS.platform}=${platform}&`
  }
  if (linkId) {
    qp += `${GRIN_KEYS.linkId}=${linkId}&`
  }
  if (token) {
    qp += `${GRIN_KEYS.token}=${token}&`
  }
  if (contactId) {
    qp += `${GRIN_KEYS.contactId}=${contactId}&`
  }
  if (attributionWindow) {
    qp += `${GRIN_KEYS.attributionWindow}=${attributionWindow}&`
  }
  return qp
}

