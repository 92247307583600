import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { generatePath, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'

import { Box, Table, TableContainer } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { ReportTableContent, ReportTableFooters, ReportTableHeaders } from '../ReportTable'
import {
  selectColorStylistProducts,
  selectCustomEndDate,
  selectCustomStartDate,
  selectReportsDateRangeType,
  selectedUnit
} from '../../../data/reports-v2/slice'
import { Units } from '../../../data/reports-v2/interfaces'
import { dispatchGetColorStylistDetailsReport } from '../../../data/reports-v2/api'
import { ReportSectionHeader } from '../ReportSectionHeader'
import { COLORS } from '../../../mini-lib/theme/colors'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { REPORTS_LOADING_CONSTANT } from '../../../data/reports-v2/constants'
import { selectLoadingState } from '../../../core/loading/slice'
import { convertDateRangeTypeToDates } from '../../../mini-lib/dates-and-times/utils'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { buildCsv } from '../../../mini-lib/csv/buildCsv'
import { ColorStylistReport } from '../../../data/reports-v2/interfaces'
import { UseViewSize } from '../../../core/UseViewSize'
import { COLOR_REPORTS_TABS, ColorReportsTabs } from '../../reports-v1/ColorReportsTabs'
import { formatDollars } from "../../../mini-lib/units/money";
import { pluralizeNameIfRequired } from '../../../data/reports-v2/utils'
import { ROUTES } from "../../../appRoutes";

export const ColorStylistProductReportsPage = () => {
  const params: any = useParams()
  const { stylistId } = params
  const { isMobile, isTablet } = UseViewSize()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()


  const breadcrumbs = [
    { label: 'Reports', url: generatePath(ROUTES.colorStylistReports, { salonId }) },
    { label: isMobile ? 'Stylist' : 'Stylist Reports', url: generatePath(ROUTES.colorStylistReports, { salonId }) },
    { label: isMobile ? 'Stylist Products' : 'Stylist Products Report' },
  ]

  const dispatch = useDispatch()
  const models = useAppSelector(selectColorStylistProducts)

  const currentlyLoading = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const currentSelectedUnit: Units = useAppSelector(selectedUnit)

  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null

  const sumProfit = models ? models.map((model) => model.profit).reduce((a, b) => a + b, 0) : null
  const sumRevenue = models ? models.map((model) => model.retail).reduce((a, b) => a + b, 0) : null
  const sumWholesale = models ? models.map((model) => model.wholesale).reduce((a, b) => a + b, 0) : null

  useEffect(() => {
    dispatch(
      dispatchGetColorStylistDetailsReport({
        token,
        salonId,
        dateStart: sDate,
        dateEnd: eDate,
        stylistId,
        kind: 'color',
      }),
    )
  }, [dispatch, token, stylistId, salonId, sDate, eDate])

  const tableHeaders = [
    { label: 'Line' },
    { label: 'Color' },
    { label: 'Type' },
    { label: 'Qty', variant: 'weight' },
    { label: 'Cost' },
    { label: 'Price' },
    { label: 'Mark up' },
  ]
  const tableProperties = [
    { name: 'line', variant: 'text', size: isMobile || isTablet ? 'sm' : 'm' },
    { name: 'type', variant: 'text', size: isMobile || isTablet ? 'xs' : 'm', mainText: true },
    { name: 'category', variant: 'text', size: 'sm' },
    currentSelectedUnit === 'unit' ? 
      { name: 'quantity', variant: 'weight', size: isMobile || isTablet ? 'xs' : 'sm' } : 
      { name: 'totalGrams', variant: 'weight', size: isMobile || isTablet ? 'xs' : 'sm' },
    { name: 'wholesale', variant: 'money' },
    { name: 'retail', variant: 'money' },
    { name: 'profit', variant: 'money' },
  ]
  const tableFooters = [
    'Total',
    '',
    '',
    '',
    '$' + formatDollars(sumWholesale),
    '$' + formatDollars(sumRevenue),
    '$' + formatDollars(sumProfit),
  ]
  const onCsvExport = () => {
    const totals: ColorStylistReport | any = {
      stylist: 'total',
      line: '',
      color: '',
      type: '',
      quantity: '',
      wholesale: '$' + formatDollars(sumWholesale),
      retail: '$' + formatDollars(sumRevenue),
      profit: '$' + formatDollars(sumProfit),
    }

    const formattedModels: ColorStylistReport[] | any[] = models
      ? models.map((model) => ({
        stylist: model.stylistName,
        line: model.line,
        color: model.type,
        type: model.category,
        quantity: `${currentSelectedUnit === 'unit' ? model.quantity : model.totalGrams}${' '}${pluralizeNameIfRequired(currentSelectedUnit, Number(model.quantity))}`,
        wholesale: '$' + formatDollars(model.wholesale),
        retail: '$' + formatDollars(model.retail),
        profit: '$' + formatDollars(model.profit),
      }))
      : []
    const rows: any = formattedModels ? [...formattedModels, totals] : []
    const tableHeadersForCSV = ['Stylist', 'Line', 'Color', 'Type', 'Qty', 'Cost', 'Price', 'Mark up']
    buildCsv('color stylist products report', tableHeadersForCSV, rows)
  }
  const stylistName = models?.[0]?.stylistName ?? ''
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Color Reports" breadcrumbs={breadcrumbs} />}
      content={
        <>
          {!isMobile && <Box h="24px" />}
          <ColorReportsTabs selectedTab={COLOR_REPORTS_TABS.colorStylist} onCsvExport={onCsvExport} />
          <Box h="24px" />
          <ReportSectionHeader
            label={stylistName}
            bgHex={COLORS.lavender_100}
            backButtonUrl={generatePath(ROUTES.colorStylistReports, { salonId })}
          />

          {!currentlyLoading && !!models && models.length > 0 && (
            <TableContainer>
              <Table>
                <ReportTableHeaders headers={tableHeaders} />
                <ReportTableContent properties={tableProperties} models={models} />
                <ReportTableFooters footers={tableFooters} />
              </Table>
            </TableContainer>
          )}
          {!currentlyLoading && !!models && models.length === 0 && (
            <EmptyBox content="No colors were used in this time period" />
          )}
          <Box h="24px" />
          {(!models || currentlyLoading) && <Loading />}
        </>
      }
    />
  )
}
