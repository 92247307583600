import { useDispatch } from 'react-redux'
import { Box, Button, Drawer, DrawerContent, DrawerOverlay, Text } from '@chakra-ui/react'
import React from 'react'
import { reduceSetInventoryInfoSheetVisibility, selectInventoryInfoSheetVisibility } from '../../data/products/slice'
import { useAppSelector } from '../../hooks'

export const InventoryInfoSheet = () => {
  const dispatch = useDispatch()
  const inventorySheetVisible = useAppSelector(selectInventoryInfoSheetVisibility)
  const onClose = () => dispatch(reduceSetInventoryInfoSheetVisibility(false))
  return (
    <Drawer size="md" placement="right" onClose={onClose} isOpen={inventorySheetVisible}>
      <DrawerOverlay />
      <DrawerContent>
        <Button variant="ghost" onClick={onClose} position="fixed" top="12px" right="12px">
          Done
        </Button>
        <Box p="48px">
          <Text variant="largetitle">Learn About Your Inventory</Text>

          <Box h="24px" />
          <Text variant="title3opensans" fontWeight="bold">
            What is your “On Hand” inventory?
          </Text>
          <Box h="12px" />
          <Text variant="footnote">
            Your “On-Hand” inventory is the current amount of product you have in stock at your salon. This can be found
            by doing a count of your current backbar stock.
          </Text>

          <Box h="24px" />
          <Text variant="title3opensans" fontWeight="bold">
            What is your “Target” inventory?
          </Text>
          <Box h="12px" />
          <Text variant="footnote">
            Your “Target” inventory is the ideal or maximum quantity that you would like to have in stock of each
            product. This amount should be enough to ensure you do not run out of stock and can meet demand between now
            and next order.
          </Text>

          <Box h="24px" />
          <Text variant="title3opensans" fontWeight="bold">
            What a Wholesale Cost?
          </Text>
          <Box h="12px" />
          <Text variant="footnote">
            The “Wholesale Cost” is the price you pay to the distributor for the product. This can be found on your
            invoice or itemized receipt.
          </Text>

          <Box h="24px" />
          <Text variant="title3opensans" fontWeight="bold">
            What is a Markup?
          </Text>
          <Box h="12px" />
          <Text variant="footnote">
            “Markup” is the percentage that dictates how much profit the salon will make on a particular product. Markup
            is based off of the amount that is added on top of your wholesale cost to give you your final cost to the
            customer. For example, a product with a wholesale cost of $10 and has a markup of 100% will have a cost to
            the customer of $20. This means that $10 covers what it costs the salon to use that product and the other
            $10 is profit for the salon.
          </Text>
        </Box>
      </DrawerContent>
    </Drawer>
  )
}
