import { Box, Button } from '@chakra-ui/react'
import { buildCsv } from '../../../mini-lib/csv/buildCsv'
import { calculateMarkupNumber } from '../../../data/products/utils'
import { calculateStockCost } from '../../../data/inventory/utils'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { excelCompatibleStringConvertor } from '../../../mini-lib/csv/utils'
import { Product } from '../../../data/products/interfaces'
import { sumBy } from 'lodash'
import { ReleaseUnitsSupport } from "../../../mini-lib/flags/Release";


export const InventorySnapshotCSVButton = (props: { products: Product[]}) => {
  const { products } = props
  const releaseUnitsSupport = ReleaseUnitsSupport();
  const sumTarget = sumBy(products, 'inventory.maxStockLevel')
  const sumCost = sumBy(products, 'inventory.cost')
  const sumStockCost = sumBy(products, (model)=>calculateStockCost(model))
  
  const onCsvExport = () => {
    const totals = {
      product: 'Total',
      size: '',
      brand: '',
      line: '',
      category: '',
      onHand: '',
      target: sumTarget?.toFixed(2) ?? '',
      cost: sumCost?.toFixed(2) ?? '',
      stockCost: sumStockCost?.toFixed(2) ?? '',
      markup: '',
    }

    const formattedModels = products
      ? products.map((model) => {
        const staticUnit = 'g or ml'
        const units = releaseUnitsSupport ? model.units ?? staticUnit : staticUnit
        return({
          product: excelCompatibleStringConvertor(model.type),
          size: model.size + units,
          brand: model.line.name,
          line: model.vendor.name,
          category: model.category,
          onHand: model.inventory.quantityOnHand,
          target: model.inventory.maxStockLevel,
          cost: model.inventory.cost,
          stockCost: calculateStockCost(model).toFixed(2),
          markup: `${calculateMarkupNumber(model) ?? 0}%`,
        })
      })
      : []

    const rows: any = formattedModels ? [...formattedModels, totals] : []
    const headers = [
      'Product',
      'Size',
      'Brand',
      'Line',
      'Category',
      'On Hand',
      'Target',
      'Cost/Unit',
      'Stock Cost',
      'Mark up',
    ]
    buildCsv(`inventory-snapshot-${buildDateYYYYMMDD(new Date())}`, headers, rows)
  }

  return (
    <>
      <Box pl={'10px'} mr="24px">
        <Button variant="round" colorScheme='brand.midnight' onClick={onCsvExport}>
          EXPORT CSV
        </Button>
      </Box>
    </>
  )
}
