import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Flex, Text, Link } from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'

import { selectClientList } from '../../data/clients/slice'
import { extractClientCSVData } from '../../data/clients/utils'
import { dispatchCreateClient, dispatchCreateClients, dispatchListClients } from '../../data/clients/api'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { generatePath, useHistory } from 'react-router-dom'
import { CsvUpload } from '../../mini-lib/csv/CsvUpload'
import { CSVClient } from '../../data/clients/interfaces'
import { COLORS } from '../../mini-lib/theme/colors'
import { buildCsv } from '../../mini-lib/csv/buildCsv'
import { UseViewSize } from '../../core/UseViewSize'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { Gap } from '../../mini-lib/gap/Gap'
import { ConfirmPopover } from '../../mini-lib/confirm-popover/ConfirmPopover'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { ROUTES } from "../../appRoutes";

export const ClientsBulkUploadPage = () => {
  const { user, salonId } = UseBaseApiParams()
  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Clients', url: generatePath(ROUTES.clients, { salonId }) },
  ]
  const dispatch = useDispatch()
  const models = useAppSelector(selectClientList)
  const modelsLoaded = !!models
  const { isMobile } = UseViewSize()
  const [rows, setRows] = useState<CSVClient[]>([])
  const [numRowsToShow, setNumRowsToShow] = useState(10)
  const hasMore = numRowsToShow < rows.length
  const rowAdded = (rowIndex: number) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].isAdded = true;
    setRows(updatedRows);
  };
  const handleFileUpload = (fileRows: any) => {
    const normalizedRows = extractClientCSVData(fileRows)
    setRows(normalizedRows)
  }
  const exportCSVTemplate = () => {
    buildCsv(
      `client-upload-template`,
      ['first_name', 'last_name'],
      [{ first_name: '', last_name: ''}],
    )
  }
  useEffect(() => {
    if (!modelsLoaded) {
      dispatch(dispatchListClients(user.token, salonId))
    }
  }, [dispatch, modelsLoaded, user.token, salonId])

  return (
    <PageLayout
      variant="full"
      header={
        <PageHeader
          actions={
            <Flex gridGap="12px">
              <Button colorScheme="brand.midnight" variant="round-outline" onClick={exportCSVTemplate}>
                Client CSV Template
              </Button>
              <CsvUpload theme="midnight" variant="round" onFileUpload={(fileRows) => handleFileUpload(fileRows)} />
            </Flex>
          }
          title="Clients Bulk Upload"
          breadcrumbs={breadcrumbs}
        />
      }
      content={
        <Flex flexDirection={isMobile ? 'column' : 'row'} justify="center" mt='24px' gridGap={5}>
          <Box w={isMobile ? '100%' : '50%'} border={`1px solid ${COLORS.shades_neutral_300}`} borderRadius={'16px'} padding={'2rem'}>
            <Text mb={8} variant='largetitlemobile' textTransform="uppercase">
              how to bulk add clients
            </Text>
            <Flex flexDirection={'column'}>
              <Steps1 handleClick={exportCSVTemplate} />
              <Steps2 />
              <Tip />
              <Steps3 />
              <Steps4 />
            </Flex>

          </Box>
          <Box w={isMobile ? '100%' : '50%'}>
            {(!rows || rows.length === 0) && (
              <EmptyBox content="upload a client csv" borderRadius={'16px'}>
                <>
                  <Gap s="24px" />
                  <CsvUpload theme="midnight" variant="round" onFileUpload={(fileRows) => handleFileUpload(fileRows)} />
                </>
              </EmptyBox>
            )}
            {rows && rows.length > 0 && <HeaderRow csvClients={rows} />}
            {rows?.slice(0, numRowsToShow).map((row, index) => {
              return <Row key={index} row={row} onAdded={() => rowAdded(index)} />
            })}
            {hasMore && (
              <Flex justifyContent={'center'}>
                <Button variant="round-ghost-lower" onClick={() => setNumRowsToShow(numRowsToShow + 10)}>
                  View More
                </Button>
              </Flex>
            )}
          </Box>
        </Flex>
      }
    />
  )
}

const Row = (props: { row: CSVClient, onAdded: () => void }) => {
  const { row, onAdded } = props
  const bgHex = COLORS.shades_neutral_50
  const cHex = 'black'
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const createClient = () => {
    dispatch(dispatchCreateClient({ token: user.token, salonId, model: row }))
    setIsAdded(true)
    onAdded()
  }
  const { isMobile } = UseViewSize()
  const [isAdded, setIsAdded] = useState(false)
  return (
    <Flex m="12px 0" p="0 12px" bg={bgHex} color={cHex} borderRadius="15px" justify="space-between" align="center">
      <Flex gridGap="12px" align="center" p="24px 0">
        {row.firstName && <Text w={isMobile ? "100px" : "150px"}>{row.firstName}</Text>}
        {row.lastName && <Text w={isMobile ? "100px" : "150px"}>{row.lastName}</Text>}
      </Flex>
      {isAdded ? (
        <MaterialIcon name="check_circle" colorhex={COLORS.midnight_500} />
      ) : (
        <Button colorScheme="brand.midnight" variant="round-outline" onClick={createClient}>
          Add Client
        </Button>
      )}
    </Flex>
  )
}

const HeaderRow = (props: { csvClients: CSVClient[] }) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const { csvClients } = props
  const history = useHistory()
  const createClients = () => {
    const clientsNoIds = csvClients.map(({ id, ...props }) => props)
    const nonAddedRows = clientsNoIds.filter(client => !client.isAdded)
    dispatch(dispatchCreateClients({ token: user.token, salonId, models: nonAddedRows }))
    history.push(generatePath(ROUTES.clients, { salonId }))
  }
  const { isMobile } = UseViewSize()
  return (
    <Flex m="12px 0" p="0 12px" borderRadius="15px" justify="space-between" align="center">
      <Flex gridGap="12px" align="center" p="24px 0">
        <Text w={isMobile ? "100px" : "150px"}>First Name</Text>
        <Text w={isMobile ? "100px" : "150px"}>Last Name</Text>
      </Flex>
      <ConfirmPopover
        title="Create Clients"
        subtitle="This will add all of the clients below to your Salon including any duplicates"
        onConfirm={() => createClients()}
        confirmText="Add Clients"
      >
        <Button colorScheme="brand.midnight" variant="round">
          {isMobile ? "Add All" : "Add All Clients"}
        </Button>
      </ConfirmPopover>
    </Flex>
  )
}

const textStyle: any = {
  variant: "footnote",
  whiteSpace: "pre-wrap",
  color: COLORS.text_secondary,
  display: 'inline'
}

const Steps1 = ({ handleClick }) => (
  <Box mb={4}>
    <Text as='b' {...textStyle}>Step 1: </Text>
    <Text
      {...textStyle}
    >
      <Link color="brand.lavender.500" onClick={handleClick}>
        {"Download the CSV Template "}
      </Link>
      provided
    </Text>
  </Box>
)

const Steps2 = () => (
  <Box mb={4}>
    <Text as='b' {...textStyle}>Step 2: </Text>
    <Text {...textStyle}>Fill in the template as exactly as shown. Do not leave any blank spaces.</Text>
  </Box>
)

const Tip = () => (
  <Box mb={4}>
    <Text as='i' {...textStyle}>Tip: if you have a list from your POS system you can copy and paste the clients from your existing list into the template.</Text>
  </Box>
)

const Steps3 = () => (
  <Box mb={4}>
    <Text as='b' {...textStyle}>Step 3: </Text>
    <Text {...textStyle}>Review and Save the template once you are done filling it out.</Text>
  </Box>
)

const Steps4 = () => (
  <Box>
    <Text as='b' {...textStyle}>Step 4: </Text>
    <Text {...textStyle}>Upload your CSV to SalonScale by clicking upload CSV and selecting the completed template from the location you saved it to on your computer.</Text>
  </Box>
)