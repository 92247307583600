import { Box, Button, Flex, Image, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useState } from 'react'
import { COLORS } from '../../mini-lib/theme/colors'
import logo from '../../assets/salonscale-logo-midnight-outline.svg'
import { UseViewSize } from '../../core/UseViewSize'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { selectLoggedInUser } from '../../data/user/slice'
import { LoggedInUser } from '../../data/user/interfaces'
import { generatePath, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UseGeneratePathWithQPS } from '../../mini-lib/router/router-utils'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { dispatchLogInWithLaraAndDjango } from '../../data/user/api'
import { AuthRedirect } from '../user-redirect/AuthRedirect'
import { ROUTES } from '../../appRoutes'
import { selectLoadingState } from '../../core/loading/slice'
import { Loading } from '../../mini-lib/loading/Loading'
import { LOADING_USER } from '../../data/user/constants'
import { ReleaseQuickTrial } from "../../mini-lib/flags/Release";
import { Banner } from "../../mini-lib";

export const LoginPage = () => {
  const user: LoggedInUser | null = useAppSelector(selectLoggedInUser)

  const { isMobile } = UseViewSize()
  const redirectUrl = UseQueryParams().get('redirect-url') || null
  return (
    <>
      {user && <AuthRedirect user={user} redirectUrl={redirectUrl} />}
      <LoginForm />
      {/* sticky images */}

      {!isMobile && (
        <Image
          left="0"
          bottom="0"
          position="absolute"
          src="https://storage.googleapis.com/salonscale-prod-webapp-assets/backgroup-left-illutration-signup.png"
        />
      )}

      {!isMobile && (
        <Image
          right="0"
          bottom="0"
          position="absolute"
          src="https://storage.googleapis.com/salonscale-prod-webapp-assets/backgroup-right-illutration-signup.png"
        />
      )}
    </>
  )
}

export const LoginForm = (props: { salonIdOverride?: number, stylistInviteRedirect?: boolean }) => {
  const { salonIdOverride, stylistInviteRedirect = false } = props
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const legacySignupUrl = UseGeneratePathWithQPS({ pathname: ROUTES.signupPlans })
  const quickTrialSignupUrl = UseGeneratePathWithQPS({ pathname: ROUTES.signupV2Type })
  const releaseQuickTrial = ReleaseQuickTrial()

  const signupUrl = releaseQuickTrial ? quickTrialSignupUrl : legacySignupUrl

  const loadingUser = useAppSelector((state) => selectLoadingState(state, LOADING_USER))
  const onLogin = (email: string, password: string) => {
    if (!email || !password) {
      toast.error('Please enter a valid Email and Password')
    }
    if (email && password) {
      // dispatch(dispatchLogIn({ email, password, salonId: salonIdOverride }))
      dispatch(dispatchLogInWithLaraAndDjango({ email, password, salonId: salonIdOverride }))
    }
  }
  return (
    <Flex align="center" minH="100vh" direction="column" padding={isMobile ? '12px' : '0'}>
      <Box h="72px" />
      <Image h="100px" src={logo} />
      <Box h="24px" />
      {stylistInviteRedirect && (
        <>
          <Banner iconName='info' content='Sign into your SalonScale account to accept invite'/>
          <Box h="24px" />
        </>
      )}
      <Box
        w="100%"
        bg="white"
        minH="218px"
        maxW="400px"
        border={`1px solid ${COLORS.shades_neutral_300}`}
        p="24px"
        borderRadius="15px"
        zIndex={1000}
      >
        {loadingUser ? (
          <Loading />
        ) : (
          <>
            {/* email */}
            <InputGroup size="md" id="email">
              <Input
                className="cy-login-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                borderRadius="100px"
                id="email"
                name="email"
                placeholder="Enter Email"
              />
            </InputGroup>

            <Box h="24px" />
            {/* password */}
            <InputGroup size="md">
              <Input
                className="cy-login-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                borderRadius="100px"
                type={show ? 'text' : 'password'}
                name="password"
                placeholder="Enter password"
              />
              <InputRightElement w="50px" mr="12px">
                <Button size="sm" h="24px" onClick={() => setShow(!show)}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>

            <Box h="24px" />

            <Flex justify="flex-end" gridGap="12px">
              {!stylistInviteRedirect && (
                <Link to={signupUrl}>
                  <Button variant="round-ghost-upper">Create Account</Button>
                </Link>
              )}

              {/* move forgot password to secondary action for stylist redirect component */}
              {/**/}
              {stylistInviteRedirect && (
                <Link to={generatePath(ROUTES.forgotPassword)}>
                  <Button zIndex={1000} variant="round-ghost-upper">
                    Forgot Password
                  </Button>
                </Link>
              )}
              <Button className="cy-login-button" variant="round" onClick={() => onLogin(email, password)}>
                Log In
              </Button>
            </Flex>
          </>
        )}
      </Box>

      {/* for regular logins move forgot password out of the main box */}
      {/**/}
      {!stylistInviteRedirect && (
        <Flex w="100%" maxW="400px">
          <Link to={generatePath(ROUTES.forgotPassword)}>
            <Button zIndex={1000} variant="round-ghost-upper">
              Forgot Password
            </Button>
          </Link>
        </Flex>
      )}
    </Flex>
  )
}
