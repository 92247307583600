import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Gap } from "../../mini-lib/gap/Gap";
import { COLORS } from "../../mini-lib/theme/colors";
import { buildContextlessEmailUrl } from "../../data/settings/utils";

export const CancellationPolicyPage = () => {
  return (
    <Flex justify='center'>
      <Box maxW='600px' w='100%'>
        <Gap/>
        <Text fontWeight='bold' variant='title2'>Cancellation Policy</Text>
        <Gap/>
        You may cancel your subscription at any time. Please note as per our Refund Policy that you are not entitled
        to
        a refund upon cancellation. As the subscriber, it is your responsibility to cancel your service before your
        next
        billing period to avoid further charges. No refund will be made after your renewal date. You may cancel your
        Subscription renewal either through your online account management page or by contacting SalonScale customer
        support team at <a style={{ color: COLORS.lavender_500 }} target="_blank" href={buildContextlessEmailUrl()}
                           rel="noreferrer">info@salonscale.com</a>.
        <Gap/>
        <Text fontWeight='bold'>Cancellation Process/Procedure:</Text>
        <Gap/>
        <Text fontWeight='bold'>What is this?</Text>
        <Gap/>
        When a customer reaches out and wants to cancel their subscription during or after their auto renewal date
        follow this procedure.
        <Gap/>
        <Text fontWeight='bold'>Who is this for?</Text>
        <Gap/>
        Any customer that reaches out and wants to cancel their subscription during or after their auto renewal.
        <Gap/>
        <Text fontWeight='bold'>When does this cancellation process happen?</Text>
        <Gap/>
        When a customer contacts us (via any method-email, phone, dm…) and wants to cancel their subscription.
        <Gap/>
        <Text fontWeight='bold'>How does the cancellation of the subscription process happen?</Text>
        <Gap/>
        If this occurs before their renewal date:
        <Gap/>
        <Box pl='24px'>

          1. Why do they want to cancel? Determine the reason and try to educate them on the success they can have
          by using
          the app and do not cancel subscription.

          <Gap/>
          <Text pl='24px'>OR</Text>
          <Gap/>

          2. If they do not wish to proceed, cancel their subscription on their behalf- no refund will be issued for any
          subscription time remaining.
          <Gap/>
        </Box>

        In good faith, within 24 hours of the subscription transaction we will reverse the charges on their behalf.
        <Gap/>
        If this occurs after their renewal date we will not refund them any remaining funds on their subscription as per
        our policy.
        <Gap/>
        <Box pl='24px'>
          1. Advise them that as per our policy, we do not offer refunds of their subscription payment and when they
          signed
          up for our service they accepted our terms of use acknowledging they read/understood this.
          <Gap/>
          2. Offer to assist them in using the app with educational resources from our Knowledge Hub including videos
          and
          reading material.
          <Gap/>
          3. Offer them assistance with using the app, a demo of the app or videos to share with their team.
          <Gap/>
          4. If the customer objects or asks to speak with a manager, fill out the <a
          style={{ color: COLORS.lavender_500 }} href="https://forms.gle/fdgthRh9BXpiWMB68" target="_blank"
          rel="noreferrer">Escalation Form</a> and once reviewed you
          will
          receive directions on how to proceed. Review and response will happen as soon as possible.
        </Box>
        <Gap/>
      </Box>
    </Flex>
  )
}
