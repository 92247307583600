import React from 'react'
import { Button, Flex, Text } from '@chakra-ui/react'
import { Modal as ChakraModal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, TextProps } from '@chakra-ui/react'
import { generatePath, Link, useHistory } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { ROUTES } from "../../appRoutes";

export const OrderCreateLegacyModal = ({ setIsModalOpen, isModalOpen, showModal }: { setIsModalOpen: (e: boolean) => void, isModalOpen: boolean, showModal: boolean }) => {
  const history = useHistory()
  const { salonId } = UseBaseApiParams()
  const path = generatePath(ROUTES.ordersCreate, { salonId })
  const onClickCreateNewOrder = () => { showModal ? setIsModalOpen(true) : history.push(path) }
  const onClose = () => { setIsModalOpen(false) }
  const textStyles: TextProps = {
    variant: "gigantictext",
    textAlign: 'center',
    fontSize: '26px',
    lineHeight: '32px',
    fontWeight: '700',
    textTransform: 'uppercase'
  }
  return (
    <ChakraModal isOpen={isModalOpen} onClose={onClose} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody marginTop={'3rem'} marginBottom={'2rem'}>

          <Text {...textStyles}>Please note this feature is going away.</Text>
          <Text marginTop='2rem' {...textStyles}>You have till May 31, 2023 to set up inventory for a more accurate ordering feature.</Text>
          <Flex alignItems={'center'} justifyContent={'center'} marginTop={'2rem'}>
            <Link to={path}>
              <Button onClick={onClickCreateNewOrder} variant="round" className="cy-client-add" colorScheme="brand.midnight">
                Create New Order
              </Button>
            </Link>
            <Link to={path}>
              <Text>
                Use legacy order create
              </Text>
            </Link>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}
