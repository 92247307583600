// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {assign, keyBy, values} from 'lodash'

import {RootState} from '../../store'
import {Salon} from './interfaces'

// state
//
interface SalonState {
  salonsById: { [key: string]: Salon } | null
  salonContext: Salon | null
}

const initialState: SalonState = {
  salonsById: null, // undefined is an accident, null is a choice, this lets us know when something is loading
  salonContext: null,
}

// reducer
//
export const SalonSlice = createSlice({
  name: 'salons',
  initialState,
  reducers: {
    reduceCreateSalon: (state, action: PayloadAction<Salon>) => {
      state.salonsById = assign({}, state.salonsById, { [action.payload.id]: action.payload })
      state.salonContext = assign({}, state.salonContext, action.payload)
    },
    reduceUpdateSalon: (state, action: PayloadAction<Salon>) => {
      state.salonsById = assign({}, state.salonsById, { [action.payload.id]: action.payload })
      state.salonContext = assign({}, state.salonContext, action.payload)
    },
    reduceListSalons: (state, action: PayloadAction<Salon[]>) => {
      state.salonsById = assign({}, state.salonsById, keyBy(action.payload, 'id'))
    },
    reduceSalonContext: (state, action: PayloadAction<Salon>) => {
      state.salonContext = action.payload
    },
  },
})

// actions
//
export const { reduceSalonContext, reduceCreateSalon, reduceUpdateSalon, reduceListSalons } = SalonSlice.actions

// selectors
//
export const selectSalonsById = (state: RootState) => state.salons.salonsById
export const selectSalons = (state: RootState): Salon[] | null => {
  return state.salons.salonsById ? values(state.salons.salonsById) : null
}
export const selectActiveSalonContext = (state: RootState) => state.salons.salonContext

// export
//
export default SalonSlice.reducer
