import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { CsvUpload } from '../../mini-lib/csv/CsvUpload'
import { useAppSelector } from '../../hooks'
import { selectMasterProducts } from '../../data/master-products/slice'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { dispatchCreateMasterProductUpcs, dispatchListMasterProducts } from '../../data/master-products/api'
import { filterMergedRowsAndMasterProducts, mergeRowsAndMasterProducts } from '../../data/master-products/utils'
import {
  APIMasterProductUpcCreate,
  CSVMasterProduct,
  csvStatusOptions,
  rowTypeOptions,
} from '../../data/master-products/interfaces'
import { COLORS } from '../../mini-lib/theme/colors'
import { buildCsv, convertObjectsToCsvTitleAndRows } from '../../mini-lib/csv/buildCsv'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { SearchFilter } from '../../mini-lib/filters/SearchFilter'
import { DropdownFilter } from '../../mini-lib/filters/DropdownFilter'
import { selectLoadingState } from '../../core/loading/slice'
import { LOADING_MASTER_PRODUCTS } from '../../data/master-products/constants'
import { Loading } from '../../mini-lib/loading/Loading'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { FilterBar } from '../../mini-lib/filters/FilterBar'

export const AdminUpcUpload = () => {
  const dispatch = useDispatch()
  const [mpSearchText, setMpSearchText] = useState<any>('')
  const [previousSearches, setPreviousSearches] = useState<any>([])
  const [searchText, setSearchText] = useState<any>('')
  const [rows, setRows] = useState<any>([])
  const [rowTypeFilter, setRowTypeFilter] = useState('')
  const [csvRowStatusFilter, setCsvRowStatusFilter] = useState('')
  const { user } = UseBaseApiParams()
  const masterProducts = useAppSelector(selectMasterProducts)
  const loadingMasterProducts = useAppSelector((state) => selectLoadingState(state, LOADING_MASTER_PRODUCTS))
  const masterProductsLoaded = !!masterProducts

  const mergedRowsAndMasterProducts = mergeRowsAndMasterProducts(rows, masterProducts)
  const rowsToUpload = mergedRowsAndMasterProducts.filter(
    (row) => row.matches?.length > 0 && rows.existing?.length === 0,
  )
  const filters = {
    rowType: rowTypeFilter,
    csvRowStatus: csvRowStatusFilter,
  }
  const filteredMergedRowsAndMasterProducts = filterMergedRowsAndMasterProducts(mergedRowsAndMasterProducts, filters)
  const uploadMatches = () => {
    const models: APIMasterProductUpcCreate[] = rowsToUpload.map((row) => {
      return { master_product_id: row.matches[0].id, upc: parseInt(row.upc) }
    })
    dispatch(dispatchCreateMasterProductUpcs({ token: user.token, models }))
  }

  const exportNoMatches = () => {
    const noMatches = mergedRowsAndMasterProducts.filter((row) => {
      const matchesLength = row?.matches?.length || 0
      const existingLength = row?.existing?.length || 0
      const notMasterProduct = row.id === -1
      return matchesLength === 0 && existingLength === 0 && notMasterProduct
    })
    if (noMatches?.length > 0) {
      const { titleRow, rows } = convertObjectsToCsvTitleAndRows(noMatches)
      const date = new Date()
      buildCsv(`${noMatches[0].brand}-no-matches-${date.getHours()}-${date.getMinutes()}`, titleRow, rows)
    }
  }

  const exportCurrentRows = () => {
    if (rows?.length > 0) {
      const { titleRow, rows } = convertObjectsToCsvTitleAndRows(filteredMergedRowsAndMasterProducts)
      const date = new Date()
      buildCsv(`product-upc-upload-${date.getHours()}-${date.getMinutes()}`, titleRow, rows)
    }
  }
  const handleFileUpload = (fileRows: any) => {
    const filteredRows = fileRows.filter((row) => !!row.upc)
    const normalizedRows = normalizeCsvUploadRows(filteredRows)
    setRows(normalizedRows)
  }

  useEffect(() => {
    if (mpSearchText && mpSearchText.length >= 3) {
      dispatch(
        dispatchListMasterProducts({
          token: user.token,
          search: mpSearchText,
          loadingName: LOADING_MASTER_PRODUCTS,
        }),
      )
    }
  }, [dispatch, user.token, mpSearchText])

  return (
    <Box maxW="1200px" margin="auto">
      <PageHeader title="Bulk upload UPCS" breadcrumbs={[]} />
      <Box h="24px" />

      <Flex gridGap="12px" justify="space-between" align="center">
        <Flex align="center" gridGap="12px">
          {/* the api is expensive to search, make them explicitly click search button*/}
          <SearchFilter
            theme="midnight"
            buttonClickToSearch={true}
            placeholder="search master products"
            onChange={(v) => {
              setMpSearchText(v)
              setPreviousSearches([...previousSearches, v])
            }}
            value={mpSearchText}
            onClear={() => setMpSearchText('')}
          />
          <Flex h="40px" justify="center" align="center">
            {loadingMasterProducts && (
              <Flex gridGap="12px" align="center" color={COLORS.lavender_500}>
                Loading <Loading spinnerhex={COLORS.lavender_500} />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex gridGap="12px">
          <CsvUpload
            theme="midnight"
            variant={rows.length > 0 ? 'round-outline' : 'round'}
            onFileUpload={(fileRows) => handleFileUpload(fileRows)}
          />
          {mergedRowsAndMasterProducts?.length > 0 && (
            <>
              <Button variant="round-outline" colorScheme="brand.midnight" onClick={exportCurrentRows}>
                export current rows
              </Button>
              <Button variant="round-outline" colorScheme="brand.midnight" onClick={exportNoMatches}>
                export rows with no matches
              </Button>
              <Button variant="round" colorScheme="brand.midnight" onClick={uploadMatches}>
                add upcs for matches
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      <Flex gridGap="6px" p={'12px 0'}>
        {previousSearches.map((search, index) => {
          return <Badge key={index} bgHex={COLORS.lavender_50} cHex={COLORS.lavender_500} label={search} />
        })}
      </Flex>

      <Box h="40px" />
      <Text variant="title2">Row Filters</Text>
      <Box h="12px" />
      {mergedRowsAndMasterProducts?.length > 0 && (
        <FilterBar
          search={
            <SearchFilter
              theme="lavender"
              buttonClickToSearch={true}
              placeholder="search rows"
              onChange={(v) => setSearchText(v)}
              value={searchText}
              onClear={() => setSearchText('')}
            />
          }
          filters={
            <>
              <DropdownFilter
                theme='lavender'
                options={rowTypeOptions}
                placeholder="row type"
                value={rowTypeFilter}
                onChange={(v) => setRowTypeFilter(v.value)}
              />
              <DropdownFilter
                theme='lavender'
                options={csvStatusOptions}
                placeholder="csv status"
                value={csvRowStatusFilter}
                onChange={(v) => setCsvRowStatusFilter(v.value)}
              />
            </>
          }
        />
      )}
      <Box h="24px" />
      {filteredMergedRowsAndMasterProducts?.map((row, index) => {
        return <Row key={index} row={row} />
      })}

      {rows.length === 0 && !masterProductsLoaded && <EmptyBox h="500px" />}
    </Box>
  )
}

const Row = (props: { row: CSVMasterProduct }) => {
  const { row } = props
  const isMasterProduct = row.id > 0
  const { label, bgHex, cHex } = getRowColorsAndBadge(row)
  return (
    <Box m="12px 0" p="0 12px" bg={bgHex} color={cHex} borderRadius="15px">
      <Flex gridGap="12px" align="center" p="24px 0">
        <Text w="100px">{isMasterProduct ? 'product' : 'csv row'}</Text>
        <Text w="180px">{row.upc}</Text>
        {row.parent_brand && <Text w="200px">{row.parent_brand}</Text>}
        {row.brand && <Text w="180px">{row.brand}</Text>}
        {row.category && <Text w="100px">{row.category}</Text>}
        {row.size && <Text w="50px">{row.size}</Text>}
        {row.type && <Text w="240px">{row.type}</Text>}
        <Box w="120px">{!isMasterProduct && <Badge w="100%" label={label} bgHex={bgHex} cHex={cHex} />}</Box>
      </Flex>
    </Box>
  )
}

const getRowColorsAndBadge = (row: CSVMasterProduct): { label: string; bgHex: string; cHex: string } => {
  const { id, existing, matches } = row
  if (id > 0) {
    return { label: '', bgHex: COLORS.shades_neutral_50, cHex: 'black' }
  }
  if (existing.length > 0) {
    return { label: 'UPC exists', bgHex: COLORS.shades_neutral_50, cHex: 'black' }
  }
  if (matches.length > 0) {
    return { label: 'has match', bgHex: COLORS.lavender_100, cHex: COLORS.lavender_500 }
  }
  return { label: 'no match', bgHex: COLORS.peach_100, cHex: COLORS.peach_500 }
}

const Badge = (props: { bgHex: string; cHex: string; label: string; w?: string }) => {
  const { bgHex, cHex, label, w } = props
  return (
    <Flex w={w} h="30px" color={cHex} bg={bgHex} padding="4px 12px" borderRadius="15px" align="center" justify="center">
      {label}
    </Flex>
  )
}

const normalizeCsvUploadRows = (rows: any): CSVMasterProduct[] => {
  return rows.map((row) => {
    return {
      id: -1,
      brand: 'brand' in row ? row.brand : '',
      parent_brand: 'parent_brand' in row ? row.parent_brand : '',
      type: 'type' in row ? row.type : '',
      category: 'category' in row ? row.category : '',
      size: 'size' in row ? row.size : '',
      upc: 'upc' in row ? row.upc : '',
      existing: 'existing' in row ? row.existing : '',
      matches: 'matches' in row ? row.matches : '',
    }
  })
}
