import {APISessionLaborItemLara, SessionLaborItemLara} from "../interfaces";
import {map} from "lodash";

export const mapAPISessionLaborItemLaraToSessionLaborItemLara = ( api: APISessionLaborItemLara ): SessionLaborItemLara => {
  return {
    id: api.id,
    laborItemId: api.labor_item_id,
    type: api.type,
    name: api.name,
    pricePerHour: api.price_per_hour,
    durationSeconds: api.duration_seconds,
    laborItem: {serviceName: api.labor_item.service_name, tierName: api.labor_item.tier_name, pricePerHour: api.labor_item.price_per_hour}
  }
}
export const mapAPISessionLaborItemsLaraToSessionLaborItemsLara = (api: APISessionLaborItemLara[] ): SessionLaborItemLara[] => {
  return map(api, mapAPISessionLaborItemLaraToSessionLaborItemLara)
}
