/////////////// mappers.ts
//
//

import { map } from 'lodash'
import { APISquareMember, SquareMember } from './interfaces'
import { APIBulkCreateUserLara } from '../user/interfaces'

export const mapAPISquareMemberToSquareMember = (api: APISquareMember): SquareMember => {
  return {
    squareId: api.team_member_id,
    first: api.first_name || '',
    last: api.last_name || '',
    email: api.email_address || '',
    phone: api.phone_number || '',
    permissionLevel: 'stylist',
    existingUserMeta: null,
  }
}
export const mapAPISquareMembersToSquareMembers = (apiModels: APISquareMember[]): SquareMember[] => {
  return map(apiModels, mapAPISquareMemberToSquareMember)
}

export const mapSquareMemberToAPIBulkCreateUserLara = (members: SquareMember): APIBulkCreateUserLara => {
  const api = {
    square_id: members.squareId,
    first_name: members.first,
    last_name: members.last,
    email_address: members.email,
    phone: members.phone,
    permission_level: members.permissionLevel,
  }
  if (members.existingUserMeta?.id) {
    api['user_id'] = members.existingUserMeta?.id
  }
  return api
}

export const mapSquareMembersToAPIBulkCreateUsersLara = (members: SquareMember[]): APIBulkCreateUserLara[] => {
  return map(members, mapSquareMemberToAPIBulkCreateUserLara)
}
