import { APIUpdateProduct, Product, ProductsByCategoryThenByLineSize } from '../products/interfaces'
import { APIExtensionLara, APIExtensionPatchLara, Extension, ExtensionFilters } from './interfaces'
import { filterExtension } from './utils'
import { map } from "lodash";
import { getQuantityOnHand, getStockLevel } from "../inventory/utils";
import { centsToDollars } from "../../core/money/utils";
import { convertDisplayUnits } from "../sessions/utils";

export const mapExtensionsByCategoryThenByLineSize = (
  products: Product[],
  filters: ExtensionFilters,
): ProductsByCategoryThenByLineSize => {
  const m: { [key: string]: { [key: string]: Product[] } } = {}
  products.forEach(( product ) => {
    const category = product.category
    const lineSize = `${product.line.name}-${product.size}`
    if (filterExtension(product, filters)) {
      if (m[category]) {
        if (m[category][lineSize]) {
          m[category][lineSize] = [...m[category][lineSize], product]
        } else {
          m[category][lineSize] = [product]
        }
      } else {
        m[category] = {}
        m[category][lineSize] = [product]
      }
    }
  })
  return m
}


export const mapAPIUpdateProductToAPIExtensionPatchLara = ( apis: APIUpdateProduct[] ): {
  extensions: APIExtensionPatchLara[]
} => {
  const apiExtensions = apis.map(api => {
    const apiExtension: APIExtensionPatchLara = { id: api.product_id }
    if (api.nickname !== null) apiExtension.nickname = api.nickname;
    if (api.mark_up !== null) apiExtension.mark_up = api.mark_up;
    if (api.wholesale_price !== null) apiExtension.salon_purchase_price_cents = api.wholesale_price ? Math.round(api.wholesale_price * 100) : 0;
    if (api.is_default_price !== null) apiExtension.is_default_price = api.is_default_price;
    if (api.max_stock_level !== null) apiExtension.target = api.max_stock_level;
    if (api.amount_grams_in_stock !== null) apiExtension.amount_grams_in_stock = api.amount_grams_in_stock;
    return apiExtension
  })
  return { extensions: apiExtensions }
}


export const mapAPIExtensionLaraToExtension = ( api: APIExtensionLara ): Extension => {
  const quantityOnHand = getQuantityOnHand(api.amount_grams_in_stock, api.size) // todo: should be using `api.quantity_on_hand` but it's returning 0,
  return {
    id: api.id,
    units: api?.units ?? null,
    masterProductId: api.master_product_id,
    category: api.category,
    // lineName: api.brand_name, // this is confusing
    // vendorName: api.parent_brand_name, // also super confusing
    // vendorLogoUrl: api.parent_brand_logo_url,
    type: api.type,
    typeAlias: api.nickname,
    inventory: {
      minStockLevel: null,
      maxStockLevel: api.target,
      amountGramsInStock: api.amount_grams_in_stock,
      quantityOnHand: quantityOnHand,
      cost: centsToDollars(api.salon_purchase_price),
      costPerG: centsToDollars(api.salon_purchase_price_per_gram),
      costPerOz: centsToDollars(convertDisplayUnits(api.salon_purchase_price_per_gram, 'g', 'oz')),
      stockLevel: getStockLevel(quantityOnHand, api.target),
    },
    pricing: {
      price: centsToDollars(api.client_purchase_price),
      pricePerG: centsToDollars(api.client_purchase_price_per_gram),
      pricePerOz: centsToDollars(convertDisplayUnits(api.client_purchase_price_per_gram, 'g', 'oz')),
    },
    vendor: {
      id: api.vendor.id,
      name: api.vendor.name,
      logoUrl: api.vendor.logo_url,
    },
    line: {
      id: api.line.id,
      name: api.line.name,
    },
    isDefaultPrice: api.is_default_price,
    size: api.size,
    lastUpdated: '',
  }
}
export const mapAPIExtensionLarasToToExtensions = ( api: APIExtensionLara[] ): Extension[] => {
  return map(api, mapAPIExtensionLaraToExtension)
}
