import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { selectLoggedInUser } from '../../data/user/slice';
import { useAppSelector } from '../../hooks';
import { MixpanelEnabled } from '../../mini-lib/flags/Release';
import { getEnvConfig } from "../../config";

export const InitializeMixpanel = () => {
    const mixpanelEnabled = MixpanelEnabled()
    const config = getEnvConfig()
    if (mixpanelEnabled) {
        mixpanel.init(
            config.mixPanelKey || '', {
            debug: false,
            autotrack: false, // pricing plan is based on events sent, so keep it minimal as we learn what we can do
            track_pageview: false,
            opt_out_tracking_by_default: true,
            persistence: 'localStorage' // https://docs.mixpanel.com/docs/tracking/javascript-quickstart#what-are-the-recommended-configuration-options
        })
    }

    const user = useAppSelector(selectLoggedInUser)
    const email = user?.email
    const userId = user?.userId ?? null
    const salonId = user?.currentSalonContext?.salonId ?? null
    const salonType = user?.currentSalonContext?.subscription?.salonType ?? null

    useEffect(() => {
        if (!mixpanelEnabled) return;

        if (userId !== null && salonId !== null && ![5].includes(salonId)) { // Eventually INTERNAL_SALONS.allInternal.includes(salonId)
            mixpanel.opt_in_tracking()
            mixpanel.identify(`${userId}`)
            mixpanel.people.set({ 'Email': email, 'Plan': salonType })
        } else {
            mixpanel.opt_out_tracking()
            mixpanel.reset()
        }
    }, [email, userId, salonId, salonType, mixpanelEnabled]);
};

export const logMixpanelEvent = (
    isEnabled: boolean,
    name: string,
    properties: { [key: string]: string } = {},
): void => {
    if (!isEnabled) return
    try {
        mixpanel.track(name, properties);
    } catch (_) {
        // if something goes awry (ie. accidentally called before mixpanel is init), silent fail
    }
};
