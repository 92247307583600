/////////////// interfaces.ts
//
//
export const OwnerMetricOptions = {
  totalRevenueProfit: 'total-revenue',
  totalSessions: 'total-sessions',
  products: 'products',
  clients: 'top-clients',
}

export const IntervalOptions = {
  date: 'date',
  month: 'month',
  year: 'year',
}

// each of the different metric types produces a different interface
// this is not our best work
//
//


export interface Analytic {
  totalRevenueProfit?: number
  totalSessions?: number
  products?: {
    wholesale: number
    retail: number
    profit: number
    date: string
  }[]
}


export interface APITopClient {
  client_id: number
  first_name: string
  middle_name: string
  last_name: string
  total_cost: number
}
export interface TopClient {
  id: number
  firstName: string
  middleName: string
  lastName: string
  totalCost: number
}

export type Analytics = Analytic | any
