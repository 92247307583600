import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { generatePath, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'

import { Box, Table, TableContainer } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { ReportTableContent, ReportTableHeaders } from '../ReportTable'
import {
  reduceSelectedBrand,
  selectColorBrandUnusedProducts,
  selectCustomEndDate,
  selectCustomStartDate,
  selectReportsDateRangeType,
  selectSelectedBrand,
} from '../../../data/reports-v2/slice'
import { dispatchGetColorBrandUnusedDetailsReport } from '../../../data/reports-v2/api'
import { ReportSectionHeader } from '../ReportSectionHeader'
import { COLORS } from '../../../mini-lib/theme/colors'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { REPORTS_LOADING_CONSTANT } from '../../../data/reports-v2/constants'
import { selectLoadingState } from '../../../core/loading/slice'
import { convertDateRangeTypeToDates } from '../../../mini-lib/dates-and-times/utils'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { buildCsv } from '../../../mini-lib/csv/buildCsv'
import { ColorBrandReport } from '../../../data/reports-v2/interfaces'
import { COLOR_BRAND_DETAILS_REPORTS_TABS, ColorLineDetailsReportsTabs } from './ColorLineDetailsReportsTabs'
import { UseViewSize } from '../../../core/UseViewSize'
import { ROUTES } from "../../../appRoutes";

export const AllColorLineUnusedProductsReportPage = () => {
  const dispatch = useDispatch()
  const { isMobile, isTablet } = UseViewSize()
  const params: any = useParams()
  const { brand } = params

  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = [
    { label: 'Reports', url: generatePath(ROUTES.colorBrandReports, { salonId }) },
    { label: isMobile ? 'Lines' : 'Color Line Reports', url: generatePath(ROUTES.colorBrandReports, { salonId }) },
    { label: isMobile ? 'Line' : 'Color Line Report' },
  ]
  const models = useAppSelector(selectColorBrandUnusedProducts)
  const currentlyLoading = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))

  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const currentBrand = useAppSelector(selectSelectedBrand)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null

  useEffect(() => {
    if (brand !== currentBrand) {
      dispatch(reduceSelectedBrand(brand))
    }
  }, [dispatch, brand, currentBrand])

  useEffect(() => {
    dispatch(dispatchGetColorBrandUnusedDetailsReport({ token, salonId, brand: null, dateStart: sDate, dateEnd: eDate }))
  }, [dispatch, token, brand, salonId, sDate, eDate, currentBrand])

  const tableHeaders = [{ label: 'Line' }, { label: 'Color' }, { label: 'Type' }]
  const tableProperties = [
    { name: 'line', variant: 'text', mainText: true, size: 'xl' },
    { name: 'type', variant: 'text', size: 'm' },
    { name: 'category', variant: 'text', size: isMobile || isTablet ? 'sm' : 'm' },
  ]
  const onCsvExport = () => {
    const formattedModels: ColorBrandReport[] | any[] = models
      ? models.map((model) => ({
          line: model.line,
          category: model.type,
          quantity: model.category,
        }))
      : []
    const rows: any = formattedModels ?? []
    const headers = tableHeaders.map((header) => header.label)
    buildCsv('color brand unused products report', headers, rows)
  }

  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Color Reports" breadcrumbs={breadcrumbs} />}
      content={
        <>
          {!isMobile && <Box h="24px" />}
          <ColorLineDetailsReportsTabs
            selectedTab={COLOR_BRAND_DETAILS_REPORTS_TABS.unusedColors}
            onCsvExport={onCsvExport}
          />
          <Box h="24px" />
          <ReportSectionHeader
            label={'Unused colors'}
            bgHex={COLORS.lavender_100}
          />

          {!currentlyLoading && !!models && models.length > 0 && (
            <TableContainer>
              <Table>
                <ReportTableHeaders headers={tableHeaders} />
                <ReportTableContent properties={tableProperties} models={models} />
              </Table>
            </TableContainer>
          )}
          {!currentlyLoading && !!models && models.length === 0 && (
            <EmptyBox content="No colors were unused in this time period" />
          )}
          <Box h="24px" />
          {(!models || currentlyLoading) && <Loading />}
        </>
      }
    />
  )
}
