import React from 'react'
import { Button, Flex, Text, TextProps } from '@chakra-ui/react'
import { Modal as ChakraModal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react'

export const OrderCreateModal = ({ setIsModalOpen, isModalOpen }: { setIsModalOpen: (e: boolean) => void, isModalOpen: boolean }) => {
  const onClose = () => { setIsModalOpen(false) }
  const textStyles: TextProps = {
    variant: "gigantictext",
    textAlign: 'center',
    fontSize: '26px',
    lineHeight: '32px',
    fontWeight: '700',
    textTransform: 'uppercase'
  }
  return (
    <ChakraModal isOpen={isModalOpen} onClose={onClose} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody marginTop={'3rem'} marginBottom={'2rem'}>
          <Text {...textStyles}>This feature works with our inventory feature only.</Text>
          <Text marginTop="2rem" {...textStyles}>
            Please set up inventory through the start guide.
          </Text>
          <Flex alignItems={'center'} justifyContent={'center'} marginTop={'2rem'}>
            <Button onClick={onClose} variant="round" className="cy-client-add" colorScheme="brand.midnight">
              Go Back
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}
