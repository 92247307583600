import { map } from 'lodash'
import { APIChecklistItem, ChecklistItem } from './interfaces'

export const mapAPIChecklistItemToChecklistItem = (api: APIChecklistItem): ChecklistItem => {
  return {
    code: api.code,
    completed: !!api.completed_at,
  }
}
export const mapAPIChecklistItemsToChecklistItems = (api: APIChecklistItem[]): ChecklistItem[] => {
  return map(api, mapAPIChecklistItemToChecklistItem)
}
