import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { generatePath } from 'react-router-dom'
import React, { useEffect } from 'react'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { ColorReportsTabs } from './ColorReportsTabs'

import { Box, Table, TableContainer } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { ReportTableContent, ReportTableFooters, ReportTableHeaders } from '../reports-v2//ReportTable'
import {
  selectColorBrandReports,
  selectCustomEndDate,
  selectCustomStartDate,
  selectReportsDateRangeType,
} from '../../data/reports-v2/slice'
import { dispatchGetColorBrandReports } from '../../data/reports-v2/api'
import { ReportSectionHeader } from '../reports-v2/ReportSectionHeader'
import { COLORS } from '../../mini-lib/theme/colors'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { Loading } from '../../mini-lib/loading/Loading'
import { REPORTS_LOADING_CONSTANT } from '../../data/reports-v2/constants'
import { selectLoadingState } from '../../core/loading/slice'
import { convertDateRangeTypeToDates } from '../../mini-lib/dates-and-times/utils'
import { buildDateYYYYMMDD } from '../../core/dates'
import { buildCsv } from '../../mini-lib/csv/buildCsv'
import { ColorBrandReport } from '../../data/reports-v2/interfaces'
import { UseViewSize } from '../../core/UseViewSize'
import { formatDollars } from "../../mini-lib/units/money";
import { ROUTES } from "../../appRoutes";

export const ColorLineReportsPage = (props: { selectedTab: number }) => {
  const { selectedTab } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = [
    {
      label: 'Reports',
      url: generatePath(ROUTES.colorBrandReports, { salonId }),
    },
    { label: 'Color Reports' },
  ]
  const dispatch = useDispatch()
  const { isMobile, isTablet } = UseViewSize()
  const models = useAppSelector(selectColorBrandReports)
  const currentlyLoading = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))

  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null

  const sumProfit = models ? models.map((model) => model.profit).reduce((a, b) => a + b, 0) : null
  const sumRevenue = models ? models.map((model) => model.retail).reduce((a, b) => a + b, 0) : null
  const sumWholesale = models ? models.map((model) => model.wholesale).reduce((a, b) => a + b, 0) : null

  useEffect(() => {
    dispatch(dispatchGetColorBrandReports({ token, salonId, dateStart: sDate, dateEnd: eDate }))
  }, [dispatch, token, salonId, sDate, eDate])

  const tableHeaders = [{ label: 'Title' }, { label: 'Cost' }, { label: 'Price' }, { label: 'Mark up' }]
  const tableProperties = [
    { name: 'brand', variant: 'text', mainText: true, size: isMobile || isTablet ? 'l' : 'xl' },
    { name: 'wholesale', variant: 'money' },
    { name: 'retail', variant: 'money' },
    { name: 'profit', variant: 'money' },
  ]
  const tableActions = [
    {
      label: 'view all',
      buildUrl: (brand: string): string => generatePath(ROUTES.colorBrandUsedProductReports, { salonId, brand }),
      variant: 'button',
    },
  ]
  const tableFooters = [
    'Total',
    '$' + formatDollars(sumWholesale),
    '$' + formatDollars(sumRevenue),
    '$' + formatDollars(sumProfit),
  ]
  const onCsvExport = () => {
    const totals: ColorBrandReport | any = {
      brand: 'total',
      wholesale: '$' + formatDollars(sumWholesale),
      retail: '$' + formatDollars(sumRevenue),
      profit: '$' + formatDollars(sumProfit),
    }
    const formattedModels: ColorBrandReport[] | any[] = models
      ? models.map((model) => ({
          brand: model.brand,
          wholesale: '$' + formatDollars(model.wholesale),
          retail: '$' + formatDollars(model.retail),
          profit: '$' + formatDollars(model.profit),
        }))
      : []
    const rows: any = formattedModels ? [...formattedModels, totals] : []
    const headers = tableHeaders.map((header) => header.label)
    buildCsv('color brand list report', headers, rows)
  }
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Color Reports" breadcrumbs={breadcrumbs} />}
      content={
        <>
          <ColorReportsTabs selectedTab={selectedTab} onCsvExport={onCsvExport} />
          <Box h="24px" />
          <ReportSectionHeader label="COLORS" bgHex={COLORS.lavender_100} />
          <Box h="24px" />
          {!currentlyLoading && !!models && models.length > 0 && (
            <TableContainer>
              <Table>
                <ReportTableHeaders headers={tableHeaders} actions={tableActions} />
                <ReportTableContent properties={tableProperties} models={models} actions={tableActions} />
                <ReportTableFooters footers={tableFooters} actions={tableActions} />
              </Table>
            </TableContainer>
          )}
          {!currentlyLoading && !!models && models.length === 0 && (
            <EmptyBox content="No colors were used in this time period" />
          )}
          <Box h="24px" />
          {(!models || currentlyLoading) && <Loading />}
        </>
      }
    />
  )
}
