import { Flex } from '@chakra-ui/react'
import React from 'react'
import { COLORS } from '../theme/colors'
import { ThemeType } from "../../theme";
import { getIconColorStylesForTheme } from "./utils";

export type ProductIconName =
  | 'permanent'
  | 'highlift'
  | 'developer'
  | 'demi'
  | 'semi'
  | 'lightener'
  | 'bleach'
  | 'miscellaneous'
  | 'additive'
  | 'bonder'
  | 'emptybowl'
  | 'wavinghand'
  | 'extensions'

export const SalonScaleProductIcon = (
  props:
    | {
        variant?: 'default' | 'nobackground'
        theme?: ThemeType
        name: ProductIconName
        size?: string
        colorhex?: string
        backgroundhex?: string
      }
    | any,
) => {
  const {
    variant = 'default',
    theme,
    name,
    size = '44px',
    colorhex = COLORS.lavender_500,
    backgroundhex = COLORS.lavender_100,
  } = props

  const defaultIcon = iconNameToImgPathMap['permanent']
  const imgImport = name && name in iconNameToImgPathMap ? iconNameToImgPathMap[name] : defaultIcon
  const iconColorStyles = theme ? getIconColorStylesForTheme(theme) : {}
  return (
    <>
      {variant === 'default' && (
        <Flex
          {...props}
          align="center"
          justify="center"
          bg={backgroundhex}
          color={colorhex}
          borderRadius="50%"
          p="10px"
          borderColor={colorhex}
          border="2px solid"
          minHeight={size}
          maxHeight={size}
          minWidth={size}
          maxWidth={size}
          height={size}
          width={size}
        >
          <img
            style={{
              height: '80%',
              width: '80%',
              fontSize: size,
              color: colorhex,
              ...iconColorStyles
            }}
            src={imgImport}
            alt="iconimg"
          />
        </Flex>
      )}
      {variant === 'nobackground' && (
        <img
          style={{
            height: size,
            width: size,
            fontSize: size,
            color: colorhex,
            ...iconColorStyles
          }}
          src={imgImport}
          alt="iconimg"
        />
      )}
    </>
  )
}

const iconNameToImgPathMap = {
  permanent: require('../../assets/icons/Product-Permanent-Highlift.png').default,
  highlift: require('../../assets/icons/Product-Permanent-Highlift.png').default,
  developer: require('../../assets/icons/Product-Developer.png').default,
  demi: require('../../assets/icons/Product-Demi-Semi.png').default,
  semi: require('../../assets/icons/Product-Demi-Semi.png').default,
  lightener: require('../../assets/icons/Product-Bleach-Lightener.png').default,
  bleach: require('../../assets/icons/Product-Bleach-Lightener.png').default,
  miscellaneous: require('../../assets/icons/Product-Additive-Bonder-Miscellaneous.png').default,
  additive: require('../../assets/icons/Product-Additive-Bonder-Miscellaneous.png').default,
  bonder: require('../../assets/icons/Product-Additive-Bonder-Miscellaneous.png').default,
  emptybowl: require('../../assets/icons/EmptyBowl.png').default,
  wavinghand: require('../../assets/icons/WavingHand.png').default,
  extensions: require('../../assets/icons/Extensions.svg').default,
}
