import { ExportToCsv } from 'export-to-csv'
import { keys, map, omit, values } from 'lodash'

export const buildCsv = (fileName: string, titleRow: string[], rows: any[]) => {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    filename: fileName,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers: titleRow,
  }

  const csvExporter = new ExportToCsv(options)

  if (rows && rows.length > 0) {
    csvExporter.generateCsv(rows)
  }
}

export const convertObjectsToCsvTitleAndRows = (objs: any[]): { titleRow: string[]; rows: string[][] } => {
  const objsWithRemovedProps = map(objs, (row) => {
    return omit(row, ['existing', 'matches', 'id'])
  })
  const titleRow = keys(objsWithRemovedProps[0])
  const rows = objsWithRemovedProps.map((obj) => values(obj))
  return { titleRow, rows }
}
