import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { COLORS } from '../theme/colors'
import { SearchIcon } from '@chakra-ui/icons'

export const DebouncedInput = (props: {
  value?: string
  initialValue: string
  onValueChange: (string) => void
  placeholder?: string
  inputLeftElement?: ReactNode
  inputRightElement?: ReactNode
  debounceTime?: number
  width?: string
  backgroundhex?: string
  borderhex?: string
  colorhex?: string
}) => {
  const {
    value,
    initialValue,
    onValueChange,
    inputRightElement,
    inputLeftElement,
    debounceTime = 300,
    width = '500px',
    placeholder = 'search',
    backgroundhex,
    borderhex = COLORS.shades_neutral_300,
    colorhex = COLORS.text_primary,
  } = props

  const [currentValue, setCurrentValue] = useState(initialValue)
  // if you are wondering why this is in a useCallback instead of just being a function
  // tldr; it's because we need it as a dep for debouncedOnInputChange and so it has to only be defined once
  // if it's not in a callback it gets redefined every time this PaginatedSearch component rerenders
  // ref: https://stackoverflow.com/a/62601621/10795885
  const onInputChange = useCallback(
    (event: any) => {
      const updatedValue = event?.target?.value
      onValueChange(updatedValue)
    },
    [onValueChange],
  )

  // ref nice example hit up https://dmitripavlutin.com/react-throttle-debounce/
  const debouncedOnInputChange = useMemo(() => {
    return debounce(onInputChange, debounceTime)
  }, [debounceTime, onInputChange])

  // this cancels the handler if they navigate away from the page
  useEffect(() => {
    return () => {
      debouncedOnInputChange.cancel()
    }
  }, [debouncedOnInputChange])

  // hard overrides the current value
  useEffect(() => {
    if (value !== null && value !== undefined) {
      setCurrentValue(value)
    }
  }, [value])

  return (
    <InputGroup
      border="1px solid"
      borderColor={borderhex}
      borderRadius="100px"
      width={width}
      maxWidth="100%"
      alignItems="center"
      bg={backgroundhex}
      color={colorhex}
    >
      <InputLeftElement
        pointerEvents="none"
        children={inputLeftElement ? inputLeftElement : <SearchIcon color={COLORS.lavender_500} />}
      />

      <Input
        borderRadius="100px"
        value={currentValue}
        border="none"
        _focus={{ border: 'none' }}
        className="cy-searchbar-input"
        onChange={(evt) => {
          debouncedOnInputChange(evt)
          setCurrentValue(evt.target.value)
        }}
        placeholder={placeholder}
      />
      <Box mr="4px">{inputRightElement}</Box>
    </InputGroup>
  )
}
