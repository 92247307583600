/////////////// api.ts
//
//

import axios from 'axios'
import { Dispatch } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import {
  reduceCreateInventoryCount,
  reduceDeleteInventoryCount,
  reduceGetInventoryCount,
  reduceGetInventoryCountItems,
  reduceInventoryCountsPagination,
  reduceListInventoryCount,
  reduceRedirectInventoryCountId,
  reduceUpdateInventoryCount,
  reduceUpdateInventoryCountItem,
} from './slice'
import {
  APIInventoryCount,
  APIInventoryCountCreate,
  APIInventoryCountItem,
  APIInventoryCountItemUpdate,
  InventoryCount,
  InventoryCountItem,
  InventoryCountType,
} from './interfaces'
import {
  mapAPIInventoryCountItemsToInventoryCountItems,
  mapAPIInventoryCountItemToInventoryCountItem,
  mapAPIInventoryCountToInventoryCount,
  mapAPIInventoryCountToInventoryCounts,
} from './mappers'
import { GetServerBaseUrl } from '../../env'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { INVENTORY_CREATE_LOADING_CONSTANT, INVENTORY_LOADING_CONSTANT } from './constants'
import {
  APIPagedLaraResponse,
  buildLaraConfig,
  buildLaraPageParams,
  LaraPagination,
  mapAPILaraPaginationToLaraPagination,
} from '../../mini-lib/lara/lara-utils'
import { dispatchListProducts } from '../products/api'

// apis
//
//
export const apiListInventoryCounts = (params: {
  token: string
  salonId: number
  countType?: InventoryCountType
  pageNumber?: number
}): Promise<{ models: InventoryCount[]; pagination: LaraPagination }> => {
  const { token, salonId, countType, pageNumber = 1 } = params
  const countTypeParam = countType ? `&filter[count_type]=${countType}` : ''
  const config = buildLaraConfig({ token })
  const { pageSizeParam, pageNumberParam } = buildLaraPageParams({ pageSize: 10000, pageNumber })
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/inventory-counts/?${pageSizeParam}&${pageNumberParam}${countTypeParam}`

  return axios
    .get(url, config)
    .then((response: { data: APIPagedLaraResponse }) => {
      return {
        pagination: mapAPILaraPaginationToLaraPagination(response.data.meta),
        models: mapAPIInventoryCountToInventoryCounts(response.data.data),
      }
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetInventoryCount = (params: {
  token: string
  salonId: number
  countId: number
}): Promise<InventoryCount> => {
  const { token, salonId, countId } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/inventory-counts/${countId}?token=${token}`

  return axios
    .get(url)
    .then((response: { data: { data: APIInventoryCount } }) => {
      return mapAPIInventoryCountToInventoryCount(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetInventoryCountItems = (params: {
  token: string
  salonId: number
  countId: number
}): Promise<InventoryCountItem[]> => {
  const { token, salonId, countId } = params
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/inventory-counts/${countId}/inventory-count-items?token=${token}`

  return axios
    .get(url)
    .then((response: { data: { data: APIInventoryCountItem[] } }) => {
      return mapAPIInventoryCountItemsToInventoryCountItems(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateInventoryCountItems = (params: {
  token: string
  salonId: number
  countId: number
  items: { product_id: number }[]
}): Promise<InventoryCountItem[]> => {
  const { token, salonId, countId, items } = params
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/inventory-counts/${countId}/inventory-count-items/bulk?token=${token}`
  const body = {
    products: items,
  }
  return axios
    .post(url, body)
    .then((response: { data: { data: APIInventoryCountItem[] } }) => {
      return mapAPIInventoryCountItemsToInventoryCountItems(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateInventoryCountItems = (params: {
  token: string
  salonId: number
  countId: number
  item: APIInventoryCountItemUpdate
}): Promise<InventoryCountItem> => {
  const { token, salonId, item } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/inventory-count-items/${item.id}?token=${token}`
  const body = item
  return axios
    .patch(url, body)
    .then((response: { data: { data: APIInventoryCountItem } }) => {
      return mapAPIInventoryCountItemToInventoryCountItem(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiCompleteInventoryCount = (params: {
  token: string
  salonId: number
  model: InventoryCount
}): Promise<InventoryCount> => {
  const { token, salonId, model } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/inventory-counts/${model.id}/complete?token=${token}`
  const body = {}
  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPIInventoryCountToInventoryCount(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateInventoryCount = (params: {
  token: string
  userId: number
  salonId: number
  model: APIInventoryCountCreate
}): Promise<InventoryCount> => {
  const { token, salonId, model } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/inventory-counts/?token=${token}`
  return axios
    .post(url, model)
    .then((response: any) => {
      return mapAPIInventoryCountToInventoryCount(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteInventoryCount = (params: {
  token: string
  salonId: number
  model: InventoryCount
}): Promise<any> => {
  const { token, salonId, model } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/inventory-counts/${model.id}/?token=${token}`
  return axios
    .delete(url)
    .then((response: any) => {
      return { ...response.data, id: model.id }
    })
    .catch((error) => {
      throw error
    })
}

// actions
//
//
export const dispatchListInventoryCounts = (params: { token: string; salonId: number, countType?: InventoryCountType, pageNumber?: number }) => {
  return (dispatch: Dispatch) => {
    return apiListInventoryCounts(params).then((resp) => {
      dispatch(reduceListInventoryCount(resp.models))
      dispatch(reduceInventoryCountsPagination(resp.pagination))
    })
  }
}

export const dispatchGetInventoryCount = (params: { token: string; salonId: number; countId: number }) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: INVENTORY_LOADING_CONSTANT, state: true }))
    return apiGetInventoryCount(params).then((resp) => {
      dispatch(reduceGetInventoryCount(resp))
      dispatch(reduceSetLoadingState({ name: INVENTORY_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetInventoryCountItems = (params: { token: string; salonId: number; countId: number }) => {
  return (dispatch: Dispatch) => {
    return apiGetInventoryCountItems(params).then((resp) => {
      dispatch(reduceGetInventoryCountItems(resp))
    })
  }
}

export const dispatchCreateInventoryCountItems = (params: {
  token: string
  salonId: number
  countId: number
  items: { product_id: number }[]
}) => {
  return (dispatch: Dispatch) => {
    return apiCreateInventoryCountItems(params).then((resp) => {
      dispatch(reduceGetInventoryCountItems(resp))
    })
  }
}

export const dispatchUpdateInventoryCountItem = (params: {
  token: string
  salonId: number
  countId: number
  item: APIInventoryCountItemUpdate
  loadingName: string
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: params.loadingName, state: true }))
    return apiUpdateInventoryCountItems(params).then((resp) => {
      dispatch(reduceUpdateInventoryCountItem(resp))
      dispatch(reduceSetLoadingState({ name: params.loadingName, state: false }))
    })
  }
}
export const dispatchCompleteInventoryCount = (params: { token: string; salonId: number; model: InventoryCount, releaseLaraColors: boolean }) => {
  return (dispatch: any) => {
    const { salonId, token, releaseLaraColors } = params
    return apiCompleteInventoryCount(params)
      .then((resp) => {
        dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
        dispatch(reduceUpdateInventoryCount(resp))
        toast.success('Completed Count')
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchCreateInventoryCount = (params: {
  token: string
  userId: number
  salonId: number
  model: APIInventoryCountCreate
  // history: any
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: INVENTORY_CREATE_LOADING_CONSTANT, state: true }))
    return apiCreateInventoryCount(params)
      .then((resp) => {
        dispatch(reduceCreateInventoryCount(resp))
        dispatch(reduceRedirectInventoryCountId(resp.id))
        dispatch(reduceSetLoadingState({ name: INVENTORY_CREATE_LOADING_CONSTANT, state: false }))
        toast.success('Created Count')
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDeleteInventoryCount = (params: { token: string; salonId: number; model: InventoryCount }) => {
  return (dispatch: Dispatch) => {
    return apiDeleteInventoryCount(params)
      .then((resp) => {
        dispatch(reduceDeleteInventoryCount(resp))
        toast.success('Deleted Count')
      })
      .catch((error) => {
        throw error
      })
  }
}
