import { Icon } from '@chakra-ui/icons'
import { Box, Button, Circle, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseViewSize } from '../../core/UseViewSize'
import { dispatchDeleteClient, dispatchSetSelectedClientId } from '../../data/clients/api'
import { Client } from '../../data/clients/interfaces'
import { MOBILE_IDENTIFIER_COLUMN_MAX_WIDTH } from '../../data/constants'
import { ConfirmPopover } from '../../mini-lib/confirm-popover/ConfirmPopover'
import { updateQueryParams } from '../../mini-lib/utils/basic'

interface Props {
  clients: Client[]
}

export const ClientCard = ({ client }: { client: Client }) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  const history = useHistory()
  const location = useLocation()
  return (
    <Box p="12px" maxW="100%" className="cy-client-row">
      <Flex align="center" justify="space-between">
        <Flex align="center" gridGap="12px">
          <Circle size="40px" bg="brand.lavender.200">
            <Text fontWeight="bold" variant="body" color="brand.lavender.500" textTransform="uppercase">
              {client.firstName[0]}
              {client.lastName[0]}
            </Text>
          </Circle>
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: MOBILE_IDENTIFIER_COLUMN_MAX_WIDTH,
              width: '100%',
              whiteSpace: 'nowrap',
            }}
          >
            <Text variant="body" fontWeight="bold">
              {client.firstName} {client.lastName}
            </Text>
          </div>
        </Flex>
        {isMobile ? (
          <Menu>
            <MenuButton>
              <Icon as={() => <i className="material-icons-round">more_vert</i>} />
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  dispatch(dispatchSetSelectedClientId({ selectedClientId: client.id }))
                  updateQueryParams(history, location, [
                    {
                      action: 'add',
                      paramKey: 'selectedClientId',
                      paramValue: client.id,
                    },
                  ])
                }}
              >
                View
              </MenuItem>
              <ConfirmPopover
                title={`This Action is Permanent`}
                subtitle={`Removing this client line means they will no longer appear in your salon.`}
                onConfirm={() => {
                  dispatch(dispatchDeleteClient({ token: user.token, salonId, model: client }))
                }}
              >
                <Text p="12px" w="100px" color="danger">
                  Delete
                </Text>
              </ConfirmPopover>
            </MenuList>
          </Menu>
        ) : (
          <Flex align="center" gridGap="12px">
            <Button
              colorScheme="brand.lavender"
              variant="round-ghost-lower"
              onClick={() => {
                dispatch(dispatchSetSelectedClientId({ selectedClientId: client.id }))
                updateQueryParams(history, location, [
                  {
                    action: 'add',
                    paramKey: 'selectedClientId',
                    paramValue: client.id,
                  },
                ])
              }}
            >
              View
            </Button>
            <Flex align="center">
              <ConfirmPopover
                title={`This Action is Permanent`}
                subtitle={`Removing this client line means they will no longer appear in your salon.`}
                onConfirm={() => {
                  dispatch(dispatchDeleteClient({ token: user.token, salonId, model: client }))
                }}
              >
                <Button w="100px" color="danger" variant="round-ghost-lower">
                  Delete
                </Button>
              </ConfirmPopover>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

export const ClientList = (props: Props) => {
  const { clients } = props
  return (
    <>
      {clients.map((client) => {
        return (
          <Box key={client.id}>
            <ClientCard client={client} />
            <Divider />
          </Box>
        )
      })}
    </>
  )
}
