import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { ThemeType } from '../../theme'

export const ConfirmModal = (props: {
  title: string | ReactNode
  subtitle: string | ReactNode
  confirmOverride?: ReactNode
  onConfirm: () => void
  onCancel: () => void
  confirmText?: string
  showCancel?: boolean
  showModal?: boolean
  theme?: ThemeType
}) => {
  const {
    showModal = false,
    confirmOverride,
    onConfirm,
    onCancel,
    title,
    subtitle,
    confirmText = 'Yes',
    showCancel = true,
    theme = 'lavender',
  } = props
  return (
    <>
      {showModal && (
        <Modal onClose={onCancel} isOpen={showModal} isCentered={true}>
          <ModalOverlay />
          <ModalContent m="12px">
            <ModalBody>
              <Flex p="36px 0 24px" direction="column">
                <Box textAlign="center" fontSize="20px" fontWeight="bold">
                  {title}
                </Box>
                <Box h="12px" />
                <Box textAlign="center">{subtitle}</Box>
                <Box h="24px" />
                <Flex justify="center" gridGap="12px">
                  {showCancel && (
                    <Button variant="round-ghost-upper" colorScheme={`brand.${theme}`} onClick={onCancel}>
                      Cancel
                    </Button>
                  )}
                  {confirmOverride ? confirmOverride :
                    <Button
                      variant="round"
                      colorScheme={`brand.${theme}`}
                      onClick={() => {
                        onConfirm()
                      }}
                    >
                      {confirmText}
                    </Button>
                  }
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
