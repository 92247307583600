import { SalonUser } from "./interfaces";
import { filter } from "lodash";

export interface SalonUserFilters {
  searchText: string | null,
  tierId: number | null,
  roleId: number | null
}
export const filterSalonUsers = ( params: {
  users: SalonUser[],
  filters: SalonUserFilters
}): SalonUser[] => {
  const {users} = params
  const { searchText, tierId, roleId } = params.filters
  const normalizedText = searchText ? searchText.toLowerCase() : ''

  return filter(users, (user) => {
    const hasFirstName = normalizedText ? user.firstName.toLowerCase().includes(normalizedText) : true
    const hasLastName = normalizedText ? user.lastName.toLowerCase().includes(normalizedText) : true
    const hasEmail = normalizedText ? user.email.toLowerCase().includes(normalizedText) : true
    const hasSearchText = hasFirstName || hasLastName || hasEmail

    const matchesTierId = tierId ? user.tierId === tierId : true
    const matchesRoleId = roleId ? user.roleId === roleId : true
    return hasSearchText && matchesTierId && matchesRoleId
  })

}
export const updateSalonUsers = (params: {
  usersById: { [key: string]: SalonUser } | null
  userUpdates: Partial<SalonUser>[]
}): { [key: string]: SalonUser } | null => {
  const { usersById, userUpdates } = params

  if (usersById === null) return null

  const updatedUsersById = { ...usersById }

  userUpdates.forEach((userUpdate) => {
    if (userUpdate && userUpdate.userId) {
      const existingUser = updatedUsersById[userUpdate.userId]

      if (existingUser) {
        updatedUsersById[userUpdate.userId] = {
          ...existingUser,
          ...userUpdate,
        }
      }
    }
  })

  return updatedUsersById
}

export const EMPTY_SALON_USER: SalonUser = {
  id: -1,
  userId: -1,
  legacyStylistId: -1,

  fullName: '',
  firstName: '',
  lastName: '',

  email: '',
  phone: '',

  userPhotoUrl: '',
  roleId: -1,
  roleName: '',
  userTierId: null,
  tierId: null,
  tierName: null

}
