import React, { ReactNode, useEffect, useState } from 'react'
import { Box, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { InputError } from './InputError'
import { COLORS } from '../theme/colors'
import { uniqueId } from 'lodash'
import { ThemeType } from '../../theme'
import { MaterialLabel } from './MaterialHelpers'
import { CloseIcon } from "@chakra-ui/icons";
import { BASE_INPUT_STYLES } from "../../styles";

export const TextInput = (props: {
  label?: string | ReactNode
  placeholder?: string
  isDisabled?: boolean
  w?: string
  h?: string
  value: string
  onChange: (val: string) => void
  onBlur?: (val: string) => void
  onClear?: () => void
  errorText?: string
  theme?: ThemeType
  variant?: 'basic' | 'material'
  border?: 'normal' | 'rounded'
  allowFocus?: boolean
}) => {
  const { w, variant = 'basic'} = props
  return (
    <Box w={w}>
      {variant === 'basic' && <BasicTextInput {...props} />}
      {variant === 'material' && <MaterialTextInput {...props} />}
    </Box>
  )
}

export const BasicTextInput = (props: {
  label?: string | ReactNode
  placeholder?: string
  isDisabled?: boolean
  w?: string
  value: string
  onChange: (val: string) => void
  onBlur?: (val: string) => void
  onClear?: () => void
  errorText?: string
}) => {
  const {
    label,
    placeholder,
    isDisabled,
    value,
    onChange,
    onBlur = () => {},
    errorText = ''
  } = props
  return (
    <>
      {label && (
        <Box textAlign="left" pb="6px" color={isDisabled ? 'shades.neutral.400' : ''}>
          {label}
        </Box>
      )}

      <InputGroup>
        <Input
          value={value}
          borderRadius="100px"
          isDisabled={isDisabled}
          type='text'
          placeholder={placeholder}
          borderColor={!!errorText ? COLORS.danger : COLORS.shades_neutral_200}
          borderWidth={!!errorText ? '2px' : '1px'}
          onBlur={(evt) => onBlur(evt.target.value)}
          onChange={(evt) => onChange(evt.target.value)}
        />
      </InputGroup>
      <InputError showError={!!errorText} errorText={errorText} />
    </>
  )
}


export const MaterialTextInput = (props: {
  label?: string | ReactNode
  isDisabled?: boolean
  value: string
  onChange: (val: string) => void
  onBlur?: (val: string) => void
  onClear?: () => void
  errorText?: string
  theme?: ThemeType
  border?: 'normal' | 'rounded'
  h?: string
  allowFocus?: boolean
}) => {
  const {
    label,
    isDisabled = false,
    value,
    onChange,
    onBlur = () => {},
    onClear,
    errorText = '',
    theme = 'basic',
    border = 'rounded',
    h = '38px',
    allowFocus = false,
  } = props
  const [isFocused, setIsFocused] = useState(false)
  const [hasContent, setHasContent] = useState(value !== '')

  const elementId = uniqueId('formfield-')
  useEffect(() => {
    setHasContent(value !== '')
  }, [value])

  const handleBlur = (val) => {
    setIsFocused(false)
    onBlur(val)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const themeColor = theme === 'basic' ? 'black' : COLORS[`${theme}_500`]
  const borderRadius = border === 'rounded' ? '50px' : '10px'
  return (
    <Box position='relative'>
      {label && (
        <MaterialLabel
          allowFocus={allowFocus}
          label={label}
          elementId={elementId}
          isFocused={isFocused}
          isDisabled={isDisabled}
          hasContent={hasContent}
          themeColor={themeColor}
        />
      )}

      <InputGroup>
        <Input
          id={elementId}
          borderRadius={borderRadius}
          isDisabled={isDisabled}
          type='text'
          borderColor={!!errorText ? COLORS.danger : BASE_INPUT_STYLES.borderRegular}
          borderWidth='1px'
          _hover={{ borderColor: BASE_INPUT_STYLES.borderHover }}
          _focus={{
            borderColor: theme === 'basic' ? COLORS.shades_neutral_400 : themeColor,
            borderWidth: '2px'
          }}
          value={value}
          onFocus={handleFocus}
          onBlur={(evt) => handleBlur(evt.target.value)}
          onChange={(evt) => onChange(evt.target.value)}
          size="md"
          h={h}
        />
        {value && onClear && (
          <InputRightElement fontSize="12px" cursor="pointer" onClick={onClear}>
            <CloseIcon />
          </InputRightElement>
        )}
      </InputGroup>
      <InputError showError={!!errorText} errorText={errorText} />
    </Box>
  )
}

