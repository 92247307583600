import React from 'react'
import { useDispatch } from 'react-redux'
import { setNavVisible } from '../../mini-lib/sidenav/slice'
import { generatePath, Link } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { UseViewSize } from '../../core/UseViewSize'
import { ReleaseMultiLocation } from '../../mini-lib/flags/Release'
import { isSuperUser } from '../../data/user/utils'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Sidenav, SidenavHeader, SidenavTile } from '../../mini-lib/sidenav/Sidenav'
import { useAppSelector } from "../../hooks";
import { selectChecklistItem } from "../../data/start-guide/slice";
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { OwnerSidenavLinks } from "../../mini-lib/sidenav/OwnerSidenav";
import { selectActiveSalonContext } from "../../data/salon/slice";
import { Loading } from "../../mini-lib/loading/Loading";
import { ROUTES } from "../../appRoutes";
import { UseIsInTrialV2 } from "../trial-v1/utils";
import { TrialSidenavLinks } from "../trial-v1/TrialSidenav";

export const StartGuideSidenav = ( props: { children: any } ) => {
  const { children } = props

  const releaseMultiLocation = ReleaseMultiLocation()
  const { user } = UseBaseApiParams()
  const isTrial = UseIsInTrialV2()
  if (isTrial) {
    return (
      <Sidenav
        header={<SidenavHeader />}
        showLogout={!releaseMultiLocation}
        showStopImpersonating={user && isSuperUser(user)}
        content={<TrialSidenavLinks />}
      >
        {children}
      </Sidenav>
    )
  }
  return (
    <Sidenav
      header={<SidenavHeader/>}
      showLogout={!releaseMultiLocation}
      showStopImpersonating={user && isSuperUser(user)}
      content={<StartGuideSidenavLinks/>}
    >
      {children}
    </Sidenav>
  )
}

export const StartGuideSidenavLinks = () => {
  const { isMobile, isTablet } = UseViewSize()
  const { salonId } = UseBaseApiParams()
  const dispatch = useDispatch()

  const pricingStep = useAppSelector(( state ) => selectChecklistItem(state, CHECKLIST_CODES.addPricing))
  const autoPricingStep = useAppSelector(( state ) => selectChecklistItem(state, CHECKLIST_CODES.autoPricing))
  const salon = useAppSelector(selectActiveSalonContext)

  const mobileDismissNav = () => {
    if (isMobile || isTablet) {
      dispatch(setNavVisible(false))
    }
  }

  const isLoading = !pricingStep || !salon
  const showFullNav = pricingStep.completed || autoPricingStep.completed || ( !!salon?.productsCount && salon.productsCount > 0 )

  return (
    <Box>
      {/* todo: productsCount does not tell us if they currently have 100% of their pricing but it's the best i've got right now */}
      {isLoading && <Loading mt='48px'/>}
      {!isLoading && showFullNav && <OwnerSidenavLinks/>}
      {!isLoading && !showFullNav && (
        <Link onClick={mobileDismissNav} to={generatePath(ROUTES.sgIndex, { salonId })}>
          <SidenavTile iconName="startGuide" label="Start Guide" isSelected={true}/>
        </Link>
      )}
    </Box>
  )
}


export const MockStartGuideSidenavLinks = ( props: { children } ) => {
  return (
    <Sidenav
      header={<SidenavHeader/>}
      showLogout={false}
      showStopImpersonating={false}
      content={<MockSideNavLinks/>}
    >
      {props.children}
    </Sidenav>
  )

}
export const MockSideNavLinks = () => {
  return ( <Box>
      <SidenavTile iconName="startGuide" label="Start Guide" isSelected={true}/>
    </Box>
  )
}
