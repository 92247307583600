import { COLORS } from './mini-lib/theme/colors'

export const TRUNCATE_STYLES = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
export const STICKY_STYLES: Object = { position: 'sticky', top: '0', zIndex: 1 }
export const SMALL_INPUT_STYLES: Object | any = { width: '180px' }
export const ROUNDED_ALL_STYLES: Object | any = { borderRadius: '12px' }
export const BORDER_STYLE_STRING: string = '1px solid rgba(0,0,0,.12)'
export const FOCUS_STYLES: Object = {
  borderColor: COLORS.lavender_200,
  boxShadow: `0 0 0 3px ${COLORS.lavender_200}`,
}

export const BASE_INPUT_STYLES = {
  materialLabelInputHasTextFontSize: '10px',
  fontSize: '16px',
  height: '38px',

  borderRegular: COLORS.shades_neutral_300,
  borderHover: COLORS.shades_neutral_400,
  borderFocus: COLORS.border,
}
export const BASE_STYLES = {
  borderSquare: '8px',
}
