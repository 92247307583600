import { getLineColor } from '../../../mini-lib/charts/utils'
import { OwnerMetricOptions } from '../../../data/analytics/interfaces'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useAppSelector } from '../../../hooks'
import { selectDateRangeData } from '../../../data/analytics/slice'
import React, { useEffect } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { orderBy } from 'lodash'
import { selectLoadingState } from '../../../core/loading/slice'
import { Bar, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Loading } from '../../../mini-lib/loading/Loading'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { addNumberSuffix } from '../../../mini-lib/utils/basic'
import { UseViewSize } from '../../../core/UseViewSize'
import { dispatchGetColorBrandReports } from '../../../data/reports-v2/api'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { selectColorBrandReports } from '../../../data/reports-v2/slice'

export const HomeProductBarGraph = () => {
  const dispatch = useDispatch()
  const { isMobile, isTablet } = UseViewSize()
  const { user: { token }, salonId } = UseBaseApiParams()
  const { dateRangeType, start, end } = useAppSelector(selectDateRangeData)
  const isLoading = useAppSelector((state) => selectLoadingState(state, OwnerMetricOptions.products))

  const sDate = buildDateYYYYMMDD(start)
  const eDate = buildDateYYYYMMDD(end)
  const models = useAppSelector(selectColorBrandReports)
  useEffect(() => {
    dispatch(dispatchGetColorBrandReports({ token, salonId, dateStart: sDate, dateEnd: eDate }))
  }, [dispatch, token, salonId, sDate, eDate])

  const colorBrandReports = models?.map(({ retail, brand }) => ({
    revenue: parseFloat(retail.toFixed(2)),
    brand
  }))

  const sortedProductChartData = orderBy(colorBrandReports, [(item) => item.revenue], ['desc'])
  const dataKeys = ['revenue']
  const libExampleMarginStyles = { top: 20, right: 20, bottom: 20, left: 20 }
  const chartData = sortedProductChartData.slice(0, 5)
  const maxLabelLetters = 15
  return (
    <>
      <Text variant="title1">top color line revenue</Text>
      <Box h="250px" w={isMobile || isTablet ? '100%' : '500px'} maxW="100%">
        {chartData && chartData.length > 0 && (
          <ResponsiveContainer width="100%">
            <ComposedChart layout="vertical" data={chartData} margin={libExampleMarginStyles}>
              {/*<CartesianGrid stroke="#f5f5f5" />*/}
              {dateRangeType === 'all' ? (
                <XAxis
                  type="number"
                  tickFormatter={(x) => {
                    const formattedX = addNumberSuffix(x)
                    return formattedX ? formattedX.toLocaleString() : '0'
                  }}
                />
              ) : (
                <XAxis type="number" tickFormatter={(x) => `$${x.toLocaleString()}`} />
              )}
              <YAxis
                dataKey="brand"
                type="category"
                scale="band"
                tickFormatter={(x) => (x.length > maxLabelLetters ? `${x.slice(0, maxLabelLetters)}..` : `${x}`)}
                width={140}
              />
              <Tooltip />
              <Legend />
              {dataKeys.map((key, index) => {
                return <Bar key={key} dataKey={key} fill={getLineColor(index)} />
              })}
            </ComposedChart>
          </ResponsiveContainer>
        )}
        {chartData && chartData.length === 0 && (
          <EmptyBox title="No product data yet!" content="Create some sessions to see metrics" />
        )}
        {!chartData && isLoading && <Loading />}
      </Box>
    </>
  )
}
