import { PermissionSetting } from './interfaces'
import { buildRolePermissionKey } from './utils'

export const mapPermissionSettingToPermissionRoleMap = (
  permissionSetting: PermissionSetting[],
): { [key: string]: boolean } => {
  const permissionRoleMap = {}
  permissionSetting.forEach((permissionSetting) => {
    permissionSetting.permissions.forEach((permission) => {
      const key = buildRolePermissionKey({
        id: permissionSetting.id,
        roleName: permissionSetting.role,
        permissionName: permission,
      })
      permissionRoleMap[key] = true
    })
  })
  return permissionRoleMap
}
