// @flow
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import { selectSettingsSheetType, setSettingsSheetType } from './settingsSlice'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { dispatchUpdateLoggedInUser, dispatchUpdateLoggedInUserPhoto } from '../../data/user/api'
import { InputControl } from 'formik-chakra-ui'
import { FileUpload } from '../../mini-lib/file-upload/FileUpload'
import { toast } from 'react-toastify'
import { dispatchUpdateSalon } from '../../data/salon/api'
import { APIUpdateSalon } from '../../data/salon/interfaces'
import { isImageType } from '../../core/validators'
import { TimezoneDropdown } from '../../mini-lib/time/TimezoneDropdown'

export const SettingsSideSheet = (props: {}) => {
  const dispatch = useDispatch()
  const type = useSelector(selectSettingsSheetType)
  return (
    <Drawer placement="right" onClose={() => dispatch(setSettingsSheetType('closed'))} isOpen={type !== 'closed'}>
      <DrawerOverlay />
      <DrawerContent>
        {type === 'name' && <NameForm />}
        {type === 'email' && <EmailForm />}
        {type === 'photo' && <PhotoForm />}
        {type === 'salon-name' && <SalonNameForm />}
        {type === 'salon-timezone' && <SalonTimezoneForm />}
      </DrawerContent>
    </Drawer>
  )
}
const EmailForm = (props: {}) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()

  const initialValues = {
    email: user.email,
  }
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required(),
  })
  const onSubmit = (values) => {
    if (values.email) {
      const params = {
        token: user.token,
        user_id: user.userId,
        salon_id: salonId,
        email: values.email,
      }
      dispatch(dispatchUpdateLoggedInUser(params))
      dispatch(setSettingsSheetType('closed'))
    }
  }
  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleSubmit, values, resetForm, errors }) => (
          <>
            <DrawerHeader>
              <Text variant="title1" fontWeight="bold">
                CHANGE EMAIL
              </Text>
            </DrawerHeader>
            <DrawerBody>
              <Flex gridGap="12px" direction="column">
                <InputControl inputProps={{ autoFocus: true, borderRadius: '100px' }} name="email" label="Email" />
              </Flex>
            </DrawerBody>
            <DrawerFooter>
              <Button
                minW="100px"
                className="cy-back-button"
                variant="round-outline"
                mr={3}
                onClick={() => dispatch(setSettingsSheetType('closed'))}
              >
                Cancel
              </Button>
              <Button minW="100px" variant="round" colorScheme="brand.lavender" onClick={() => handleSubmit()}>
                Save
              </Button>
            </DrawerFooter>
          </>
        )}
      </Formik>
    </>
  )
}

const PhotoForm = (props: {}) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const [file, setFile] = useState<File | null>(null)
  const onSubmit = () => {
    if (file) {
      dispatch(
        dispatchUpdateLoggedInUserPhoto({
          token: user.token,
          salon_id: salonId,
          user_id: user.userId,
          photo: file,
        }),
      )
      dispatch(setSettingsSheetType('closed'))
    } else {
      toast.error('Please Select a File')
    }
  }
  const onFileUpload = (file: File | null) => {
    if (file && isImageType(file.type)) {
      setFile(file)
    } else {
      toast.error('Please enter a valid image')
    }
  }
  return (
    <>
      <DrawerHeader>
        <Text variant="title1" fontWeight="bold">
          update profile
        </Text>
      </DrawerHeader>
      <DrawerBody>
        <Flex gridGap="12px" direction="column">
          <Box h="24px" />
          <FileUpload onFileUpload={onFileUpload} />
          <Box h="24px" />
          {file ? (
            <>
              <Flex justify="center" align="center" maxW="500px" w="100%" direction="column" gridGap="24px">
                <Text>Image Preview</Text>
                <img alt="alt" src={URL.createObjectURL(file)} />
              </Flex>
            </>
          ) : (
            <Text>No Image Selected</Text>
          )}
        </Flex>
      </DrawerBody>
      <DrawerFooter>
        <Button
          minW="100px"
          className="cy-back-button"
          variant="round-outline"
          mr={3}
          onClick={() => dispatch(setSettingsSheetType('closed'))}
        >
          Cancel
        </Button>
        <Button minW="100px" variant="round" colorScheme="brand.lavender" onClick={() => onSubmit()}>
          Save
        </Button>
      </DrawerFooter>
    </>
  )
}

const NameForm = (props: {}) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()

  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
  }
  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
  })
  const onSubmit = (values) => {
    if (values.firstName && values.lastName) {
      const params = {
        token: user.token,
        user_id: user.userId,
        salon_id: salonId,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
      }
      dispatch(dispatchUpdateLoggedInUser(params))
      dispatch(setSettingsSheetType('closed'))
    }
  }
  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleSubmit, values, resetForm, errors }) => (
          <>
            <DrawerHeader>
              <Text variant="title1" fontWeight="bold">
                CHANGE NAME
              </Text>
            </DrawerHeader>
            <DrawerBody>
              <Flex gridGap="12px" direction="column">
                <InputControl
                  inputProps={{ autoFocus: true, borderRadius: '100px' }}
                  name="firstName"
                  label="First Name"
                />
                <InputControl inputProps={{ borderRadius: '100px' }} name="lastName" label="Last Name" />
              </Flex>
            </DrawerBody>
            <DrawerFooter>
              <Button
                minW="100px"
                className="cy-back-button"
                variant="round-outline"
                mr={3}
                onClick={() => dispatch(setSettingsSheetType('closed'))}
              >
                Cancel
              </Button>
              <Button minW="100px" variant="round" colorScheme="brand.lavender" onClick={() => handleSubmit()}>
                Save
              </Button>
            </DrawerFooter>
          </>
        )}
      </Formik>
    </>
  )
}

const SalonNameForm = (props: {}) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()

  const initialValues = {
    name: user.currentSalonContext?.salonName,
  }
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
  })
  const onSubmit = (values) => {
    if (values.name) {
      const request: APIUpdateSalon = {
        name: values.name,
      }
      dispatch(
        dispatchUpdateSalon({
          token: user.token,
          userId: user.userId,
          salonId,
          currentSalonContext: user.currentSalonContext,
          request,
        }),
      )
      dispatch(setSettingsSheetType('closed'))
    }
  }
  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleSubmit, values, resetForm, errors }) => (
          <>
            <DrawerHeader>
              <Text variant="title1" fontWeight="bold">
                CHANGE NAME
              </Text>
            </DrawerHeader>
            <DrawerBody>
              <Flex gridGap="12px" direction="column">
                <InputControl inputProps={{ autoFocus: true, borderRadius: '100px' }} name="name" label="Salon Name" />
              </Flex>
            </DrawerBody>
            <DrawerFooter>
              <Button
                minW="100px"
                className="cy-back-button"
                variant="round-outline"
                mr={3}
                onClick={() => dispatch(setSettingsSheetType('closed'))}
              >
                Cancel
              </Button>
              <Button minW="100px" variant="round" colorScheme="brand.lavender" onClick={() => handleSubmit()}>
                Save
              </Button>
            </DrawerFooter>
          </>
        )}
      </Formik>
    </>
  )
}

const SalonTimezoneForm = (props: {}) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()

  const initialTimezone = user.currentSalonContext?.timezone || null
  const [localTimezone, setLocalTimezone] = useState<string | null>(initialTimezone)

  const onSave = () => {
    const request: APIUpdateSalon = {
      timezone: localTimezone || '',
    }
    dispatch(
      dispatchUpdateSalon({
        token: user.token,
        userId: user.userId,
        salonId,
        currentSalonContext: user.currentSalonContext,
        request,
      }),
    )
    dispatch(setSettingsSheetType('closed'))
  }
  return (
    <>
      <DrawerHeader>
        <Text variant="title1" fontWeight="bold">
          CHANGE TIMEZONE
        </Text>
      </DrawerHeader>
      <DrawerBody>
        <Flex gridGap="12px" direction="column">
          <TimezoneDropdown value={localTimezone} onChange={(tz) => setLocalTimezone(tz.value)} />
        </Flex>
      </DrawerBody>
      <DrawerFooter>
        <Button
          minW="100px"
          className="cy-back-button"
          variant="round-outline"
          mr={3}
          onClick={() => dispatch(setSettingsSheetType('closed'))}
        >
          Cancel
        </Button>
        <Button minW="100px" variant="round" colorScheme="brand.lavender" onClick={() => onSave()}>
          Save
        </Button>
      </DrawerFooter>
    </>
  )
}
