import { Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { GetServerBaseUrl } from '../../env'
import {
  reduceColorBrandReports,
  reduceColorBrandUsedProducts,
  reduceColorBrandWasteProducts,
  reduceColorBrandUnusedProducts,
  reduceColorStylistsReports,
  reduceColorStylistProducts,
  reduceColorStylistWasteProducts,
  reduceColorStylistsWasteReports
} from './slice'
import { REPORTS_LOADING_CONSTANT } from './constants'
import {
  APIColorBrandProductDetail,
  APIColorBrandReport,
  ColorBrandProductDetail,
  ColorBrandWasteProductDetail,
  APIColorStylistDetail,
  ColorStylistReport,
  ColorStylistWasteReport,
  APIColorStylistProductsDetail,
  ColorStylistProductsDetail,
  ColorStylistWasteProductsDetail
} from './interfaces'
import {
  mapAPIColorBrandProductDetailsToColorBrandProductDetails,
  mapAPIColorBrandReportsToColorBrandReports,
  mapAPIColorStylistReportsToColorStylistReports,
  mapAPIColorStylistReportsToColorStylistWasteReports,
  mapAPIColorStylistProductDetailsToColorStylistProductDetails
} from './mappers'

export const apiGetColorBrandReports = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<APIColorBrandReport[]> => {
  const { token, salonId, dateStart, dateEnd } = props
  const kindParam = '&filter[kind]=color'
  const dateRangeParam = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/products/?token=${token}${kindParam}${dateRangeParam}`
  return axios
    .get(url)
    .then((response: { data: { data: APIColorBrandReport[] } }) => {
      return mapAPIColorBrandReportsToColorBrandReports(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetColorBrandDetailsReport = (props: {
  token: string
  salonId: number
  brand: string | null
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<{ brand: string | null; usedProducts: ColorBrandProductDetail[]; wasteProducts: ColorBrandWasteProductDetail[] }> => {
  const { token, salonId, brand, dateStart, dateEnd } = props
  const brandParam = brand ? `&filter[brand]=${encodeURIComponent(brand)}` : ``
  const kindParam = '&filter[kind]=color'
  const dateRangeParam = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/products/detail/?token=${token}${brandParam}${kindParam}${dateRangeParam}`
  return axios
    .get(url)
    .then((response: { data: { data: APIColorBrandProductDetail[] } }) => {
      return {
        brand,
        usedProducts: mapAPIColorBrandProductDetailsToColorBrandProductDetails('used', response.data.data),
        wasteProducts: mapAPIColorBrandProductDetailsToColorBrandProductDetails('waste', response.data.data)
      }
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetColorBrandDetailsUnusedReport = (props: {
  token: string
  salonId: number
  brand: string | null
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<{ unusedProducts: ColorBrandProductDetail[] }> => {
  const { token, salonId, brand, dateStart, dateEnd } = props
  const brandParam = brand ? `&filter[brand]=${encodeURIComponent(brand)}` : ``
  const kindParam = '&filter[kind]=color'
  const dateRangeParam = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/products/detail-unused/?token=${token}${brandParam}${kindParam}${dateRangeParam}`
  return axios
    .get(url)
    .then((response: { data: { data: APIColorBrandProductDetail[] } }) => {
      return {
        unusedProducts: mapAPIColorBrandProductDetailsToColorBrandProductDetails('unused', response.data.data),
      }
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchGetColorBrandReports = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetColorBrandReports(props).then((resp) => {
      dispatch(reduceColorBrandReports(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetColorBrandDetailsReport = (props: {
  token: string
  salonId: number
  brand: string | null
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetColorBrandDetailsReport(props).then((resp) => {
      dispatch(reduceColorBrandUsedProducts(resp.usedProducts))
      dispatch(reduceColorBrandWasteProducts(resp.wasteProducts))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const apiGetColorStylistsReport = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
  kind?: 'extension' | 'color'
}): Promise<{ productsTotal: ColorStylistReport[], wasteProductsTotal: ColorStylistWasteReport[] }> => {
  const { token, dateStart, salonId, dateEnd, kind } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/stylists?token=${token}&filter[kind]=${kind}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIColorStylistDetail[] } }) => {      
      return {
        productsTotal: mapAPIColorStylistReportsToColorStylistReports(response.data.data),
        wasteProductsTotal: mapAPIColorStylistReportsToColorStylistWasteReports(response.data.data.filter((e)=> e.waste_cost > 0))
      }
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchGetColorStylistReports = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
  kind?: 'extension' | 'color'
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetColorStylistsReport(props).then((resp) => {
      dispatch(reduceColorStylistsReports(resp.productsTotal))
      dispatch(reduceColorStylistsWasteReports(resp.wasteProductsTotal))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const apiGetColorStylistDetailsReport = (props: {
  token: string
  salonId: number
  stylistId: number
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<{ usedProducts: ColorStylistProductsDetail[], wasteProducts: ColorStylistWasteProductsDetail[] }> => {
  const { token, salonId, stylistId, dateStart, dateEnd, kind } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/reports/stylists/${stylistId}?token=${token}&filter[kind]=${kind}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APIColorStylistProductsDetail[] } }) => {
      return {
        usedProducts: mapAPIColorStylistProductDetailsToColorStylistProductDetails('used', response.data.data),
        wasteProducts: mapAPIColorStylistProductDetailsToColorStylistProductDetails('waste', response.data.data)
      }
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchGetColorStylistDetailsReport = (props: {
  token: string 
  salonId: number
  stylistId: number
  kind: string
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetColorStylistDetailsReport(props).then((resp) => {
      dispatch(reduceColorStylistProducts(resp.usedProducts))
      dispatch(reduceColorStylistWasteProducts(resp.wasteProducts))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchGetColorBrandUnusedDetailsReport = (props: {
  token: string
  salonId: number
  brand: string | null
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetColorBrandDetailsUnusedReport(props).then((resp) => {
      dispatch(reduceColorBrandUnusedProducts(resp.unusedProducts))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}