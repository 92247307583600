import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import { useDispatch } from 'react-redux'
import { UseViewSize } from '../../../core/UseViewSize'
import { APICreateOrUpdateSessionSupplyLara, SessionSupplyLara } from '../../../data/sessions/interfaces'
import {
  reduceIsSupplySheetVisible,
  selectActiveSessionSupplies,
  selectIsSupplySheetVisible
} from '../../../data/sessions/slice'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { SupplySelectSheet } from './SupplySelectSheet'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'
import { dispatchCreateOrUpdateSessionSuppliesLara, dispatchDeleteSessionSupplyLara } from "../../../data/sessions/api";
import { UseBaseApiParams } from "../../../core/UseBaseApiParams";
import { useParams } from "react-router-dom";
import { Supply } from "../../../data/supplies/interfaces";
import { EmptyBox } from "../../../mini-lib/empty/EmptyBox";
import { find } from "lodash";
import { Loading } from "../../../mini-lib/loading/Loading";
import { formatCentsToDollars } from "../../../mini-lib/units/money";

export const SessionSuppliesSheet = () => {
  const params: any = useParams()
  const { sessionId } = params
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()

  const isSupplySheetVisible = useAppSelector(selectIsSupplySheetVisible)
  const sessionSupplies = useAppSelector(selectActiveSessionSupplies)
  const {user, salonId} = UseBaseApiParams()

  const [showSupplySheet, setShowSupplySheet] = useState(false)

  const updateSupplies = (supply: SessionSupplyLara, quantity: number) => {
    const updatedSessionSupply: APICreateOrUpdateSessionSupplyLara = { id: supply.id, supply_id: supply.supply.id, quantity }
    dispatch(dispatchCreateOrUpdateSessionSuppliesLara({ token: user.token, salonId, sessionId, models: [updatedSessionSupply] }))
  }

  const addSelectedSupplies = (suppliesToAdd: Supply[]) => {
    const newSupplies: APICreateOrUpdateSessionSupplyLara[] = []

    suppliesToAdd.forEach((supply) => {
      // don't re add any supplies that already exist
      const alreadyExists: SessionSupplyLara | undefined = find(
        sessionSupplies,
        (item) => item?.supply.id === supply.id,
      )
      if (!alreadyExists) {
        const newSessionSupply: APICreateOrUpdateSessionSupplyLara = {
          supply_id: supply.id,
          quantity: 1
        }
        newSupplies.push(newSessionSupply)
      }
    })
    const suppliesToDelete: SessionSupplyLara[] = []
    sessionSupplies?.forEach(sessionSupply => {
      const existsInSuppliesToAdd = find(suppliesToAdd, (supply) => supply.id === sessionSupply.supply.id)
      if (!existsInSuppliesToAdd) {
        suppliesToDelete.push(sessionSupply)
      }
    })
    suppliesToDelete.forEach(sessionSupply => {
      dispatch(dispatchDeleteSessionSupplyLara({token: user.token, salonId, sessionId, sessionSupplyId: sessionSupply.id}))
    })

    dispatch(dispatchCreateOrUpdateSessionSuppliesLara({ token: user.token, salonId, sessionId, models: newSupplies }))
  }

  const onClose = () => {
    dispatch(reduceIsSupplySheetVisible(false))
  }
  const selectedSupplyIds: number[] = sessionSupplies ? sessionSupplies.map((item) => item.supply.id) : []
  return (
    <Drawer
      size={isMobile ? 'xs' : 'md'}
      isOpen={isSupplySheetVisible}
      placement="right"
      onClose={() => dispatch(reduceIsSupplySheetVisible(false))}
    >
      {showSupplySheet && (
        <SupplySelectSheet
          selectedSupplyIds={selectedSupplyIds}
          show={showSupplySheet}
          onHide={() => setShowSupplySheet(false)}
          onSuppliesSelected={(supplies) => {
            addSelectedSupplies(supplies)
            setShowSupplySheet(false)
          }}
        />
      )}
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent>
        <DrawerHeader>
          <Flex justify="space-between" align="center">
            <Text variant="title3">Edit Add-Ons</Text>
            <Flex align="center" gridGap="12px">
              <Button colorScheme="brand.linen" variant="round" onClick={onClose} minW="100px">
                Done
              </Button>
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <>
            <DisableAutofocus />
            <Box h="24px" />
            {sessionSupplies &&
              sessionSupplies.length > 0 &&
              sessionSupplies.map((supply, index) => {
                return (
                  <SupplyRow key={index} sessionSupply={supply} updateSupplies={updateSupplies} />
                )
              })}
            {sessionSupplies && sessionSupplies.length === 0 && <EmptyBox h="200px" />}
            {!sessionSupplies && <Loading/>}
            <Box h="12px" />
            <Flex justify="flex-end">
              <Button variant="round-outline" colorScheme="brand.linen" onClick={() => setShowSupplySheet(true)}>
                <MaterialIcon name="add" style={{ marginRight: '4px' }} /> Supplies
              </Button>
            </Flex>
          </>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const SupplyRow = (props: {
  sessionSupply: SessionSupplyLara,
  updateSupplies: (sessionSupply: SessionSupplyLara, quantity: number) => void
}) => {
  const dispatch = useDispatch()
  const { sessionSupply, updateSupplies } = props
  const params: any = useParams()
  const { sessionId } = params
  const {user, salonId} = UseBaseApiParams()

  const onDelete = () => {
    dispatch(dispatchDeleteSessionSupplyLara({token: user.token, salonId, sessionId, sessionSupplyId: sessionSupply.id}))
  }
  return (
    <Flex key={sessionSupply.id} align="center" justify="space-between" p="0 0 24px 0" cursor="pointer">
      <Box>
        <Text variant="title3">{sessionSupply?.name}</Text>
        <Text color="text.secondary">${formatCentsToDollars(sessionSupply.priceCents)}</Text>
      </Box>
      <Flex align="center" gridGap="12px">
        <Box>
          <Text color="text.secondary">Amount</Text>
          <NumberInput
            maxW={32}
            defaultValue={sessionSupply.quantity || 0}
            min={0}
            onBlur={(evt) => updateSupplies(sessionSupply,  parseInt(evt.target.value))}
            borderRadius="100px"
          >
            <NumberInputField borderRadius="100px" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <MaterialIcon mt="16px" name="delete" onClick={onDelete} />
      </Flex>
    </Flex>
  )
}
