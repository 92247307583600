import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setNavVisible } from '../../mini-lib/sidenav/slice'
import { generatePath, Link, useLocation } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { UseViewSize } from '../../core/UseViewSize'
import { ReleaseMultiLocation } from '../../mini-lib/flags/Release'
import { isSuperUser } from '../../data/user/utils'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Sidenav, SidenavHeader, SidenavTile } from '../../mini-lib/sidenav/Sidenav'
import { useAppSelector } from "../../hooks";
import { selectActiveSalonContext } from "../../data/salon/slice";
import { Loading } from "../../mini-lib/loading/Loading";
import { dispatchLaraGetSalon } from "../../data/salon/api";
import { ROUTES } from "../../appRoutes";

export const TrialSidenav = ( props: { children: any } ) => {
  const { children } = props

  const releaseMultiLocation = ReleaseMultiLocation()
  const { user } = UseBaseApiParams()
  return (
    <Sidenav
      header={<SidenavHeader/>}
      showLogout={!releaseMultiLocation}
      showStopImpersonating={user && isSuperUser(user)}
      content={<TrialSidenavLinks/>}
    >
      {children}
    </Sidenav>
  )
}

export const TrialSidenavLinks = () => {
  const { isMobile, isTablet } = UseViewSize()
  const { salonId, user } = UseBaseApiParams()
  const dispatch = useDispatch()

  const salon = useAppSelector(selectActiveSalonContext)
  const isLoading = !salon
  const mobileDismissNav = () => {
    if (isMobile || isTablet) {
      dispatch(setNavVisible(false))
    }
  }

  useEffect(() => {
    if (user?.token && user?.userId) {
      dispatch(dispatchLaraGetSalon({ token: user.token, userId: user.userId, salonId }))
    }
  }, [dispatch, user?.token, user?.userId, salonId])

  const location = useLocation()
  const isSelected = (route: string): boolean => location.pathname.includes(route)

  return (
    <Box>
      {isLoading && <Loading mt='48px'/>}
      {!isLoading && (
        <>
          <Link onClick={mobileDismissNav} to={generatePath(ROUTES.trialIndex, { salonId })}>
            <SidenavTile iconName="home" label="Home" isSelected={isSelected('index')}/>
          </Link>
          <Link onClick={mobileDismissNav} to={generatePath(ROUTES.checkoutSubscription, { salonId })}>
            <SidenavTile iconName="orders" label="Upgrade" isSelected={isSelected('checkout')}/>
          </Link>
        </>
      )}
    </Box>
  )
}


