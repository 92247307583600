import React from 'react';
import { COLORS } from '../theme/colors'

function BellIcon({ color = COLORS.text_secondary, size = "24px" }: { color?: string, size?: string | number }) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="notifications_FILL0_wght400_GRAD200_opsz48 1">
                <path id="Vector" d="M5.03345 25.3667V22.9333H7.86678V13.1333C7.86678 11.2444 8.41678 9.55555 9.51678 8.06666C10.6168 6.57778 12.0779 5.61111 13.9001 5.16666V4.2C13.9001 3.62406 14.1063 3.14675 14.5187 2.76806C14.9312 2.38935 15.4256 2.2 16.0021 2.2C16.5785 2.2 17.0723 2.38935 17.4834 2.76806C17.8946 3.14675 18.1001 3.62406 18.1001 4.2V5.16666C19.9223 5.61111 21.3946 6.57778 22.5168 8.06666C23.639 9.55555 24.2001 11.2444 24.2001 13.1333V22.9333H26.9668V25.3667H5.03345ZM15.9989 29.7667C15.2325 29.7667 14.5633 29.486 13.9913 28.9246C13.4194 28.3632 13.1334 27.6883 13.1334 26.9H18.8668C18.8668 27.7 18.586 28.3778 18.0243 28.9333C17.4627 29.4889 16.7876 29.7667 15.9989 29.7667ZM10.3001 22.9333H21.7668V13.1333C21.7668 11.5333 21.2112 10.1722 20.1001 9.05C18.989 7.92777 17.6334 7.36666 16.0334 7.36666C14.4334 7.36666 13.0779 7.92777 11.9668 9.05C10.8557 10.1722 10.3001 11.5333 10.3001 13.1333V22.9333Z" fill={color} />
            </g>
        </svg>
    );
}

export default BellIcon;