/////////////// utils.ts
//
//

import { Product, STOCK_LEVEL_TYPES, StockLevel } from '../products/interfaces'
import { COLORS } from '../../mini-lib/theme/colors'
import {
  InventoryCount,
  InventoryCountedState,
  InventoryCountItem,
  InventoryCsvRow,
  InventoryProgressType,
} from './interfaces'
import { CountFilters } from '../../pages/inventory/filters/InventoryCountFilters'
import { isDateInRangeInclusive } from '../../mini-lib/utils/dates'
import { ItemFilters } from '../../pages/inventory/filters/InventoryItemFilters'
import { INVENTORY_PROGRESS_TYPES } from './constants'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { CHECKLIST_CODES } from '../start-guide/constants'
import { useAppSelector } from '../../hooks'
import { selectChecklistItem, selectChecklistItemsByCode } from '../start-guide/slice'
import { useLocation } from 'react-router-dom'
import { selectInventoryCounts } from './slice'
import { useEffect } from 'react'
import { dispatchListInventoryCounts } from './api'
import { dispatchListUserChecklistItems } from '../start-guide/api'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Salon } from "../salon/interfaces";
import { ChecklistItem } from "../start-guide/interfaces";
import { selectActiveSalonContext } from "../salon/slice";

export const calculateStockCost = ( product: Product ): number => {
  return product.inventory.quantityOnHand * product.inventory.cost
}
export const getQuantityOnHand = (gramsInStock: number, productSizeGrams: number) => {
  if (gramsInStock && productSizeGrams) {
    return gramsInStock / productSizeGrams
  }
  return 0 
}
export const getStockLevel = ( quantityOnHand: number | null, maxStockLevel: number | null ): StockLevel => {
  const current = quantityOnHand
  const target = maxStockLevel

  if (!current || current === 0) {
    return STOCK_LEVEL_TYPES.out
  }
  if (target === null) {
    return STOCK_LEVEL_TYPES.normal
  }
  if (target === 0 && current > 0) {
    return STOCK_LEVEL_TYPES.over
  }
  if (current > target) {
    return STOCK_LEVEL_TYPES.over
  }
  if (current < target && current > 0) {
    return STOCK_LEVEL_TYPES.low
  }
  return STOCK_LEVEL_TYPES.normal
}

export const getStockStateInfo = (
  product: Product,
  isDesktop: boolean,
): { label: string; bgHex: string; cHex: string } => {
  if (product.inventory.stockLevel === STOCK_LEVEL_TYPES.out) {
    return { label: isDesktop ? 'out of stock' : 'out', bgHex: COLORS.peach_50, cHex: COLORS.peach_500 }
  }
  if (product.inventory.stockLevel === STOCK_LEVEL_TYPES.over) {
    return { label: isDesktop ? 'over stocked' : 'over', bgHex: COLORS.skylight_50, cHex: COLORS.skylight_800 }
  }
  if (product.inventory.stockLevel === STOCK_LEVEL_TYPES.low) {
    return { label: isDesktop ? 'low stock' : 'low', bgHex: COLORS.linen_50, cHex: COLORS.linen_500 }
  }
  return { label: '', bgHex: COLORS.lavender_50, cHex: COLORS.lavender_500 }
}

export const filterCount = ( count: InventoryCount, filters: CountFilters ): boolean => {
  const { searchText, countTypes, progressType, startDate, endDate } = filters

  // normalize search and see if it exists
  const normalizedSearch = searchText ? searchText.toLowerCase() : ''
  const matchesSearchFilter = normalizedSearch ? count.title.toLowerCase().includes(normalizedSearch) : true

  const normalizedCountTypes = countTypes ? countTypes.map(( type ) => type.toLowerCase()) : ''
  const normalizedCountType = count.countType.toLowerCase()
  const hasCountType =
    normalizedCountTypes && normalizedCountTypes.length > 0 ? normalizedCountTypes.includes(normalizedCountType) : true

  const hasProgressType = getMatchesProgressType(progressType, count.completedAt)

  const matchesDateRange = getMatchesDateRange(count.createdAt, startDate, endDate)
  // we want all filters to match for the product to be added
  return matchesSearchFilter && hasCountType && hasProgressType && matchesDateRange
}

export const filterInventoryCounts = ( counts: InventoryCount[], filters: CountFilters ): InventoryCount[] => {
  return counts.filter(( count ) => filterCount(count, filters))
}

export const getMatchesProgressType = ( progressType: InventoryProgressType, completedAt: string | null ): boolean => {
  if (!progressType) {
    return true
  }
  if (progressType === INVENTORY_PROGRESS_TYPES.completed && !!completedAt) {
    return true
  }
  if (progressType === INVENTORY_PROGRESS_TYPES.inProgress && !completedAt) {
    return true
  }
  return false
}

export const getMatchesDateRange = ( date: Date, start: Date | null, end: Date | null ): boolean => {
  if (start && end) {
    return isDateInRangeInclusive({ date, start, end })
  }
  if (start) {
    // make end date past any possible data creation
    const endDate = new Date()
    endDate.setDate(endDate.getDate() + 1)
    return isDateInRangeInclusive({ date, start, end: endDate })
  }
  if (end) {
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - 10000)
    return isDateInRangeInclusive({ date, start: startDate, end })
  }
  // if no start date and no end date pass the check
  return true
}

export const filterCountItem = ( item: InventoryCountItem, filters: ItemFilters ): boolean => {
  const { searchText, category, line, vendor, countedState, reasonType } = filters
  // normalize search and see if it exists
  const normalizedSearch = searchText ? searchText.toLowerCase() : ''
  const matchesSearchFilter = normalizedSearch ? item.product.type.toLowerCase().includes(normalizedSearch) : true

  const normalizedVendor = vendor ? vendor.toLowerCase() : ''
  const hasVendor = normalizedVendor ? item.product.vendor.toLowerCase().includes(normalizedVendor) : true

  const normalizedLine = line ? line.toLowerCase() : ''
  const hasLine = normalizedLine ? item.product.line.toLowerCase().includes(normalizedLine) : true
  
  const normalizedCategory = category ? category.toLowerCase() : ''
  const hasCategory = normalizedCategory ? item.product.category.toLowerCase().includes(normalizedCategory) : true

  const matchesCountState = countedState ? getMatchesCountedState(item, countedState) : true
  const matchesReasonType = reasonType ? item?.reason?.toLowerCase() === reasonType.toLowerCase() : true

  return matchesSearchFilter && hasVendor && hasLine && hasCategory && matchesCountState && matchesReasonType
}

export const filterCountItems = ( items: InventoryCountItem[], filters: ItemFilters ): InventoryCountItem[] => {
  return items.filter(( item ) => filterCountItem(item, filters))
}

export const getMatchesCountedState = ( item: InventoryCountItem, countedState: InventoryCountedState ): boolean => {
  if (countedState === 'counted' && item.actualCount !== null) {
    return true
  }
  if (countedState === 'not counted' && item.actualCount === null) {
    return true
  }
  return false
}

export const buildInventoryAuditCsvRows = ( items: InventoryCountItem[], countedBy: any ): InventoryCsvRow[] | any => {
  return items.map(( item ) => {
    return {
      productName: item.product.type,
      productCategory: item.product.category,
      productVendor: item.product.vendor,
      productLine: item.product.line,
      expectedProducts: item.expectedCount ? item.expectedCount : 0,
      actualProducts: item.actualCount ? item.actualCount : 0,
      reason: item.reason ? item.reason : '',
      countedBy,
    }
  })
}

export const UseShouldShowTarget = (): boolean => {
  const salon = useAppSelector(selectActiveSalonContext)
  const hasTarget = !!(salon && salon?.targetSetCount > 0)
  const targetCode = CHECKLIST_CODES.inventorySetTargets

  // if the guide is active show it
  const queryParams: any = UseQueryParams()
  const guideParam = queryParams.get('guide')
  const isGuideActive = guideParam === targetCode

  // if the start guide step is completed show it
  const checklistItem = useAppSelector(( state ) => selectChecklistItem(state, targetCode))
  const isStepCompleted = checklistItem?.completed || false

  // if the user is on an inventory page show
  const location = useLocation()
  const isOnInventoryPage = location.pathname.includes('inventory')

  return isGuideActive || isStepCompleted || isOnInventoryPage || hasTarget
}

export const UseShouldShowOnHand = ( willOnlySetValueForOneProduct: boolean ): boolean => {
  const salon = useAppSelector(selectActiveSalonContext)
  const hasOnHand = !!(salon && salon?.onHandSetCount > 0)
  const onHandCode = CHECKLIST_CODES.inventoryCompleteCount

  // if the guide is active show it
  const queryParams: any = UseQueryParams()
  const guideParam = queryParams.get('guide')
  const isGuideActive = guideParam === onHandCode

  // if the start guide step is completed show it
  const checklistItem = useAppSelector(( state ) => selectChecklistItem(state, onHandCode))
  const isStepCompleted = checklistItem?.completed || false

  // if the user is on an inventory page show it
  const location = useLocation()
  const isOnInventoryPage = location.pathname.includes('inventory')

  return willOnlySetValueForOneProduct && ( isGuideActive || isStepCompleted || isOnInventoryPage || hasOnHand)
}

export const UseCanSeeInventory = (): boolean => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const token = user && user.token
  const userId = user && user.userId

  const inventoryCounts = useAppSelector(selectInventoryCounts)
  const inventoryCountsLoaded = !!inventoryCounts
  useEffect(() => {
    if (!inventoryCountsLoaded && salonId && token) {
      dispatch(dispatchListInventoryCounts({ token, salonId }))
    }
  }, [dispatch, token, salonId, inventoryCountsLoaded])

  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])

  const hasCompletedInventoryChecklistItems = checklistItems && !!checklistItems[CHECKLIST_CODES.inventoryCompleteCount]
  const hasInventoryCount = inventoryCountsLoaded && inventoryCounts.length > 0
  return hasCompletedInventoryChecklistItems || hasInventoryCount
}

export const isColorAndTargetComplete = ( params: {
  salon: Salon | null,
  checklistItemsByCode: { [key: string]: ChecklistItem } | null
} ): boolean | null => {
  const { salon, checklistItemsByCode } = params
  if (!checklistItemsByCode || !salon) {
    return null
  }
  const hasColors = checklistItemsByCode[CHECKLIST_CODES.addLines]?.completed ||  salon.colorsCount > 0
  const hasTargets = checklistItemsByCode[CHECKLIST_CODES.inventorySetTargets]?.completed ||  salon.targetSetCount > 0

  // const noOnHand = !checklistItemsByCode[CHECKLIST_CODES.inventorySetOnHand]?.completed // note: don't have salon.onHandSetCount data
  return hasColors && hasTargets // && hasOnHand
}

export const isColorAndTargetAndInventoryComplete = ( params: {
  salon: Salon | null,
  checklistItemsByCode: { [key: string]: ChecklistItem } | null
} ): boolean | null => {
  const { salon, checklistItemsByCode } = params
  if (!checklistItemsByCode || !salon) {
    return null
  }
  const hasColors = checklistItemsByCode[CHECKLIST_CODES.addLines]?.completed ||  salon.colorsCount > 0
  const hasTargets = checklistItemsByCode[CHECKLIST_CODES.inventorySetTargets]?.completed ||  salon.targetSetCount > 0
  const hasCounts = checklistItemsByCode[CHECKLIST_CODES.inventoryCompleteCount]?.completed ||  salon.inventoryCompletedCount > 0

  // const noOnHand = !checklistItemsByCode[CHECKLIST_CODES.inventorySetOnHand]?.completed // note: don't have salon.onHandSetCount data
  return hasColors && hasTargets && hasCounts// && hasOnHand
}

export const isColorsComplete = ( params: {
  salon: Salon | null,
  checklistItemsByCode: { [key: string]: ChecklistItem } | null
} ): boolean | null => {
  const { salon, checklistItemsByCode } = params
  if (!checklistItemsByCode || !salon) {
    return null
  }

  const hasColors = checklistItemsByCode[CHECKLIST_CODES.addLines]?.completed || (salon && salon?.colorsCount > 0)

  return hasColors
}


