import { LoggedInUser } from '../data/user/interfaces'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../hooks'
import { selectLoggedInUser } from '../data/user/slice'

export const UseBaseApiParams = (): { user: LoggedInUser; salonId: number } => {
  const params: any = useParams()
  const { salonId } = params
  // this should only be used inside the auth load container
  const knownUser: any = useAppSelector(selectLoggedInUser)
  return { user: knownUser, salonId: parseInt(salonId) }
}

