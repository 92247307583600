import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'

import {
  selectClientList,
  selectClientsById,
} from '../../data/clients/slice'
import { dispatchListClients } from '../../data/clients/api'
import { ColorForMode } from '../../theme'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Loading } from '../../mini-lib/loading/Loading'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { assign, keys, omit } from 'lodash'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../mini-lib/theme/colors'
import { filterClients } from '../../data/clients/utils'
import { StickySearch } from '../../mini-lib/search/StickySearch'
import { Client } from "../../data/clients/interfaces";

export const ClientSelectSheet = (props: {
  show: boolean
  onHide: () => void
  selectedClientIds: number[]
  onClientsSelected: (clients: Client[]) => void
  subtitleText?: string
}) => {
  const [search, setSearch] = useState('')

  const {
    show,
    onHide,
    onClientsSelected,
    selectedClientIds,
    subtitleText = 'Select clients to add to bowl',
  } = props
  return (
    <Drawer isOpen={show} placement="right" size="md" onClose={onHide}>
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent p="0">
        <DrawerBody p="0">
          <Box p="24px">
            <Flex justify="space-between">
              <Text variant="title1" fontWeight="bold">
                Clients
              </Text>
              <Button
                colorScheme="brand.midnight"
                variant="round-outline"
                minW="100px"
                onClick={onHide}
              >
                Cancel
              </Button>
            </Flex>
           
            <Flex justify="space-between" align="center" gridGap="12px" mt="12px">
            <Text color="text.secondary">{subtitleText}</Text>

             
            </Flex>
            <Box h="24px" />
            <Flex mb={5} gridGap="12px" justify="space-between">
              <Box w="100%">
                <StickySearch
                  isSticky={false}
                  variant="clear"
                  placeholder={'Search Clients'}
                  searchText={search}
                  setSearchText={(t: string) => {
                    setSearch(t)
                  }}
                />
              </Box>

            </Flex>
          </Box>
          <ClientSheetList
            searchText={search}
            selectedClientIds={selectedClientIds}
            onClientsSelected={onClientsSelected}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const ClientSheetList = (props: {
  searchText: string,
  selectedClientIds: number[]
  onClientsSelected: (clients: Client[]) => void
}) => {
  const dispatch = useDispatch()
  const clients = useAppSelector(selectClientList)
  const clientsById = useAppSelector(selectClientsById)
  const { user, salonId } = UseBaseApiParams()
  const clientsLoaded = !!clients
  const { selectedClientIds, onClientsSelected, searchText } = props
  const [numClientsToShow, setNumClientsToShow] = useState(50)

  useEffect(() => {
    dispatch(dispatchListClients(user.token, salonId, 1, searchText ))
  }, [dispatch, user.token, salonId, searchText])

  const initiallySelectedClientMap = {}
  selectedClientIds.forEach((id) => {
    initiallySelectedClientMap[id] = true
  })

  const [selectedClientMap, setSelectedClientMap] = useState(initiallySelectedClientMap)
  const toggleClientSelected = (clientId: number) => {
    if (clientId in selectedClientMap) {
      const updatedMap = omit(selectedClientMap, [clientId])
      setSelectedClientMap(updatedMap)
    } else {
      const updatedMap = assign({}, selectedClientMap, { [clientId]: true })
      setSelectedClientMap(updatedMap)
    }
  }
  const filteredClients: Client[] = clients ? filterClients(clients, searchText) : []
  const selectedClients = clientsById ? keys(selectedClientMap).map((clientId) => clientsById[clientId]) : []
  // clean out null clients if they have deleted the client since creating the session
  const cleanedSelectedClients = selectedClients.filter((client) => client)
  return (
    <>
      {!clientsLoaded && <Loading variant="clear" />}
      {clientsLoaded && clients.length > 0 && (
        <>
          {filteredClients && filteredClients.length > 0 ? (
            filteredClients.slice(0, numClientsToShow).map((client, index) => {
              return (
                <ClientRow
                  toggleClientSelected={toggleClientSelected}
                  index={index}
                  isSelected={selectedClientMap[client.id] || false}
                  client={client}
                  key={client.id}
                />
              )
            })
          ) : (
            <EmptyBox content="No clients match that filter" />
          )}
          {numClientsToShow < filteredClients.length && (
            <Flex justify="center" m="12px 0">
              <Button variant="ghost" onClick={() => setNumClientsToShow(numClientsToShow + 50)}>
                View More Clients
              </Button>
            </Flex>
          )}
          <Box h="60px" />
          <Box position="absolute" p="" m="0">
            <Flex position="fixed" bottom="0" justify="center" p="0" w="100%" h="60px">
              <Button
                variant="round"
                maxW="100%"
                w="350px"
                p="24px"
                onClick={() => onClientsSelected(cleanedSelectedClients)}
              >
                <Box w="100%">
                  <Box w="100%">Select ({cleanedSelectedClients.length}) clients</Box>
                  <Box w="100%">
                  </Box>
                </Box>
              </Button>
            </Flex>
          </Box>
        </>
      )}
      {clientsLoaded && clients.length === 0 && (
        <EmptyBox title="You don't have any clients yet!" content="Please add some clients and come back" />
      )}
    </>
  )
}

export const ClientRow = ({
  index,
  client,
  isSelected,
  toggleClientSelected,
}: {
  index: number
  client: Client
  isSelected: boolean
  toggleClientSelected: (clientId: number) => void
}) => {
  return (
    <Box
      className="cy-client-row"
      p="12px 24px"
      bg={(index + 1) % 2 === 0 ? ColorForMode('secondary-bg') : ''}
      maxW="100%"
      onClick={() => toggleClientSelected(client.id)}
    >
      <Flex align="center" gridGap="12px" cursor="pointer">
        {isSelected ? (
          <MaterialIcon colorhex={COLORS.lavender_500} name="check_circle" />
        ) : (
          <MaterialIcon colorhex={COLORS.lavender_500} name="radio_button_unchecked" />
        )}
        <Flex align="center" gridGap="12px" justify="space-between">
          <Box isTruncated={true}>
            <Text>{client.firstName} {client.lastName}</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}