import { DisplayUnit, SessionColorLara, SessionMetaLara, SessionStatus } from '../../../data/sessions/interfaces'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import {
  convertDisplayUnits,
  getBowlMapLara,
  getBowlTitleLara,
  getSessionColorsByBowlLara,
  getTotalSessionColorCostLara
} from '../../../data/sessions/utils'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import {
  reduceSetSelectedBowlId,
  selectActiveSessionColors,
  selectActiveSessionDisplayUnit,
  selectSelectedBowlId
} from '../../../data/sessions/slice'
import { COLORS } from '../../../mini-lib/theme/colors'
import { useAppSelector } from '../../../hooks'
import { SessionBowlSheet } from './SessionBowlSheet'
import { SESSION_STATUS_OPTIONS } from '../../../data/sessions/constants'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'

import { formatCentsToDollars } from "../../../mini-lib/units/money";
import { keys, map } from "lodash";

export const SessionBowls = (props: { sessionMeta: SessionMetaLara}) => {
  const dispatch = useDispatch()

  const { sessionMeta } = props
  const [show, setShow] = useState(true)



  // lara session apis
  //
  //

  const selectedBowlId = useAppSelector(selectSelectedBowlId)
  const displayUnit = useAppSelector(selectActiveSessionDisplayUnit)
  const sessionColors = useAppSelector(selectActiveSessionColors)

  // todo: consider putting this in a useEffet with sessionColors as the dep
  const bowlMapLara = sessionColors ? getBowlMapLara(sessionColors) : null
  const bowlListLara = bowlMapLara ? getSessionColorsByBowlLara(bowlMapLara) : null
  const bowlColors = bowlMapLara && selectedBowlId ? bowlMapLara[selectedBowlId] : []
  const bowlIds = bowlMapLara ? map(keys(bowlMapLara), key => parseInt(key)) : []
  const highestBowlId = bowlIds && bowlIds.length > 0 ? Math.max(...bowlIds) : 0
  const [newBowlId, setNewBowlId] = useState(highestBowlId + 1)

  return (
    <>
      {selectedBowlId && <SessionBowlSheet bowlColors={bowlColors || []} sessionMeta={sessionMeta} />}
      <Flex
        minH="60px"
        borderRadius="8px"
        bg="brand.lavender.100"
        align="center"
        justify="space-between"
        p="24px"
        onClick={() => setShow(!show)}
        cursor="pointer"
      >
        <Text variant="title3">Color</Text>
        <Flex align="center" gridGap="24px">
          <Text>${sessionColors ? formatCentsToDollars(getTotalSessionColorCostLara(sessionColors, 'amount')) : 0}</Text>
          <MaterialIcon pr="4px" name={show ? 'arrow_drop_down' : 'arrow_drop_up'} />
        </Flex>
      </Flex>
      {show && (
        <Box>
          {bowlListLara?.map((bowlColors, index) => {
            return <SessionBowl key={index} sessionStatus={sessionMeta?.status || SESSION_STATUS_OPTIONS.open} displayUnit={displayUnit} bowlColors={bowlColors} />
          })}
          {bowlListLara?.length === 0 && <EmptyBox h="200px" />}
          {sessionMeta.status === SESSION_STATUS_OPTIONS.open && (
            <Flex
              align="center"
              p="12px"
              onClick={() => {
                dispatch(reduceSetSelectedBowlId(newBowlId))
                setNewBowlId(newBowlId + 1)
              }}
              cursor="pointer"
            >
              <MaterialIcon colorhex={COLORS.lavender_500} name="add_circle" style={{ marginRight: '4px' }} />{' '}
              <Text color="brand.lavender.500">add bowl</Text>
            </Flex>
          )}
        </Box>
      )}
    </>
  )
}

export const SessionBowl = (props: { sessionStatus: SessionStatus; displayUnit: DisplayUnit, bowlColors: SessionColorLara[] }) => {
  const dispatch = useDispatch()
  const { sessionStatus, displayUnit, bowlColors } = props
  const { bowlType, bowlTag } = getBowlTitleLara(bowlColors[0])
  const bowlAmountTotal = getTotalSessionColorCostLara(bowlColors,'amount')
  return (
    <Box
      onClick={() => {
        if (sessionStatus === SESSION_STATUS_OPTIONS.open) {
          dispatch(reduceSetSelectedBowlId(bowlColors[0].bowl))
        }
      }}
      cursor={sessionStatus === SESSION_STATUS_OPTIONS.open ? 'pointer' : ''}
      className="cy-session-color"
    >
      <Box p="24px 12px">
        <Box>{bowlType} {bowlType && bowlTag && '•'} {bowlTag}</Box>
        <Flex align="center" justify="space-between" gridGap="12px" pr="24px">
          <Flex align="center">
            <BowlFormula bowlColors={bowlColors} displayUnit={displayUnit} bowlView="amount" />
          </Flex>
          <Text>${formatCentsToDollars(bowlAmountTotal)}</Text>
        </Flex>
      </Box>
      <Divider />
    </Box>
  )
}

export const BowlFormula = (props: { bowlView: 'amount' | 'waste'; displayUnit: DisplayUnit, bowlColors: SessionColorLara[] }) => {
  const { bowlColors, bowlView } = props
  // many of our types have a + on the end of them, that is confusing here
  const cleanType = (type: string): string => (type.trim().slice(-1) === '+' ? type.slice(0, type.length - 1) : type)

  const text = bowlColors.reduce((text, item, index) => {
    const maybePlusSign = index + 1 !== bowlColors.length ? ' + ' : ''
    // note: the original units will always be grams because the api always sends amounts as grams
    if (bowlView === 'waste') {
      return `${text} ${convertDisplayUnits(item.wasteGrams || 0, 'g', item.displayUnit)} ${item.displayUnit} ${cleanType(item.product.type)}${maybePlusSign}`
    }
    return `${text} ${convertDisplayUnits(item.amountGrams, 'g', item.displayUnit)} ${item.displayUnit} ${cleanType(item.product.type)}${maybePlusSign}`

  }, '')
  return (
    <Text variant={bowlView === 'waste' ? 'footnote' : ''} color="text.secondary">
      {text}
    </Text>
  )
}
