import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'

import { ColorForMode } from '../../../theme'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { Loading } from '../../../mini-lib/loading/Loading'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { assign, keys, omit } from 'lodash'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { StickySearch } from '../../../mini-lib/search/StickySearch'
import {selectLaborItemList, selectLaborItemsById} from "../../../data/labor/slice";
import {dispatchListLaborItems} from "../../../data/labor/api";
import {LaborItem} from "../../../data/labor/interfaces";
import {selectActiveSessionMeta} from "../../../data/sessions/slice";

export const LaborSelectSheet = (props: {
  show: boolean
  onHide: () => void
  selectedLaborItemIds: number[]
  onLaborItemsSelected: (laborItems: LaborItem[]) => void
}) => {
  const [searchText, setSearchText] = useState('')
  const { show, onHide, onLaborItemsSelected, selectedLaborItemIds } = props
  return (
    <Drawer isOpen={show} placement="right" size="md" onClose={onHide}>
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent p="0">
        <DrawerBody p="0">
          <Box p="24px">
            <Flex justify="space-between">
              <Text variant="title1" fontWeight="bold">
                Services
              </Text>
              <Button
                className="cy-laborItem-sheet-cancel"
                colorScheme="brand.skylight"
                variant="round-outline"
                minW="100px"
                onClick={onHide}
              >
                Cancel
              </Button>
            </Flex>
            <Box h="24px" />
            <StickySearch
              isSticky={false}
              iconColor="brand.skylight.500"
              variant="clear"
              placeholder={'Search Services'}
              searchText={searchText || ''}
              setSearchText={(t: string) => setSearchText(t)}
            />
          </Box>
          <LaborItemSheetList
            searchText={searchText}
            selectedLaborItemIds={selectedLaborItemIds}
            onLaborItemsSelected={onLaborItemsSelected}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const LaborItemSheetList = (props: {
  searchText: string
  selectedLaborItemIds: number[]
  onLaborItemsSelected: (laborItems: LaborItem[]) => void
}) => {
  const dispatch = useDispatch()
  const laborItems = useAppSelector(selectLaborItemList)
  const laborItemsById = useAppSelector(selectLaborItemsById)
  const { user, salonId } = UseBaseApiParams()

  const sessionMeta = useAppSelector(selectActiveSessionMeta)
  const sessionUserId = sessionMeta?.user.userId
  const laborItemsLoaded = !!laborItems
  const { searchText, selectedLaborItemIds, onLaborItemsSelected } = props
  const [numLaborItemsToShow, setNumLaborItemsToShow] = useState(50)

  useEffect(() => {
    dispatch(dispatchListLaborItems({token: user.token, salonId, userId: sessionUserId}))
  }, [dispatch, user.token, salonId, laborItemsLoaded, sessionUserId])

  const initiallySelectedLaborItemMap = {}
  selectedLaborItemIds.forEach((id) => {
    initiallySelectedLaborItemMap[id] = true
  })

  const [selectedLaborItemMap, setSelectedLaborItemMap] = useState(initiallySelectedLaborItemMap)
  const toggleLaborItemSelected = (laborItemId: number) => {
    if (laborItemId in selectedLaborItemMap) {
      const updatedMap = omit(selectedLaborItemMap, [laborItemId])
      setSelectedLaborItemMap(updatedMap)
    } else {
      const updatedMap = assign({}, selectedLaborItemMap, { [laborItemId]: true })
      setSelectedLaborItemMap(updatedMap)
    }
  }
  const filteredLaborItems: LaborItem[] = laborItems ? laborItems.filter((laborItem) => laborItem.service.name.toLowerCase().includes(searchText)) : []

  const selectedLaborItems: LaborItem[] | any[] = laborItemsById
    ? keys(selectedLaborItemMap).map((laborItemId) => {
        return laborItemsById[laborItemId.toString()]
      })
    : []
  return (
    <>
      {!laborItemsLoaded && <Loading variant="clear" />}
      {laborItemsLoaded && laborItems.length > 0 && (
        <>
          {filteredLaborItems && filteredLaborItems.length > 0 ? (
            filteredLaborItems.slice(0, numLaborItemsToShow).map((laborItem, index) => {
              return (
                <LaborItemRow
                  toggleLaborItemSelected={toggleLaborItemSelected}
                  index={index}
                  isSelected={selectedLaborItemMap[laborItem.id] || false}
                  laborItem={laborItem}
                  key={laborItem.id}
                />
              )
            })
          ) : (
            <EmptyBox content="No laborItems match that filter" />
          )}
          {numLaborItemsToShow < laborItems.length && (
            <Flex justify="center" m="12px 0">
              <Button
                colorScheme="brand.skylight"
                variant="ghost"
                onClick={() => setNumLaborItemsToShow(numLaborItemsToShow + 50)}
              >
                View More Services
              </Button>
            </Flex>
          )}
          <Box h="60px" />
          <Box position="absolute" p="" m="0">
            <Flex position="fixed" bottom="0" justify="center" p="0" w="100%" h="60px">
              <Button
                colorScheme="brand.skylight"
                variant="round"
                maxW="100%"
                w="350px"
                p="24px"
                onClick={() => onLaborItemsSelected(selectedLaborItems)}
              >
                <Box w="100%">
                  <Box w="100%">Select Services</Box>
                  <Box w="100%">
                    <SelectedItems laborItems={selectedLaborItems} />
                  </Box>
                </Box>
              </Button>
            </Flex>
          </Box>
        </>
      )}
      {laborItemsLoaded && laborItems.length === 0 && (
        <EmptyBox title="You don't have any services yet!" content="Please add some services and come back" />
      )}
    </>
  )
}

export const LaborItemRow = ({
  index,
  laborItem,
  isSelected,
  toggleLaborItemSelected,
}: {
  index: number
  laborItem: LaborItem
  isSelected: boolean
  toggleLaborItemSelected: (laborItemId: number) => void
}) => {
  return (
    <Box
      p="12px 24px"
      bg={(index + 1) % 2 === 0 ? ColorForMode('secondary-bg') : ''}
      maxW="100%"
      onClick={() => toggleLaborItemSelected(laborItem.id)}
    >
      <Flex align="center" gridGap="12px" cursor="pointer">
        {isSelected ? (
          <MaterialIcon colorhex={COLORS.skylight_500} name="check_circle" />
        ) : (
          <MaterialIcon colorhex={COLORS.skylight_500} name="radio_button_unchecked" />
        )}
        <Flex align="center" gridGap="12px">
          <Box isTruncated={true}>
            <Text>{laborItem.service.name}</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export const SelectedItems = (props: { laborItems: LaborItem[] }) => {
  const { laborItems } = props
  return (
    <Flex align="center" justify="center" isTruncated={true}>
      {laborItems.map((laborItem, index) => {
        return (
          <Flex key={index} align="center">
            <Text fontSize="10px">{laborItem?.service?.name}</Text>
            {index + 1 !== laborItems.length && <Box m="0 8px">+</Box>}
          </Flex>
        )
      })}
    </Flex>
  )
}
