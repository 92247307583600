/////////////// mappers.ts
//
import {map} from 'lodash'
import {APIInventoryCount, APIInventoryCountItem, InventoryCount, InventoryCountItem} from './interfaces'

export const mapAPIInventoryCountToInventoryCount = (api: APIInventoryCount): InventoryCount => {
  return {
    id: api.id,
    assignedUser: {
      userId: api.assigned_user.user_id,
      fullName: api.assigned_user.full_name,
      firstName: api.assigned_user.first_name,
      lastName: api.assigned_user.last_name,
    },
    title: api.title,
    progressItemCount: api.progress_item_count,
    totalItemCount: api.total_item_count,
    progressPercentage: api.progress_percentage,
    stockCost: api.stock_cost,
    countType: api.count_type,
    createdAt: new Date(api.created_at), // date string
    completedAt: api.completed_at, // date string
  }
}
export const mapAPIInventoryCountToInventoryCounts = (apiModels: APIInventoryCount[]): InventoryCount[] => {
  return map(apiModels, mapAPIInventoryCountToInventoryCount)
}

export const mapAPIInventoryCountItemToInventoryCountItem = (api: APIInventoryCountItem): InventoryCountItem => {
  return {
    id: api.id,
    product: {
      id: api.product.id,
      onHand: api.product.on_hand,
      target: api.product.target,
      category: api.product.category,
      line: api.product.brand,
      vendor: api.product.parent_brand,
      type: api.product.type,
      nickname: api.product.nickname,
      size: api.product.size,
      salonPurchasePriceCents: api.product.salon_purchase_price_in_cents,
      units: api.product?.units ?? null
    },
    expectedCount: api.expected_count !== null ? parseFloat(api.expected_count) : null,
    actualCount: api.actual_count !== null ? parseFloat(api.actual_count) : null,
    reason: api.reason,
  }
}
export const mapAPIInventoryCountItemsToInventoryCountItems = (
  apiModels: APIInventoryCountItem[],
): InventoryCountItem[] => {
  return map(apiModels, mapAPIInventoryCountItemToInventoryCountItem)
}
