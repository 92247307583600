import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Divider,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'

import { SessionMetaLara } from '../../data/sessions/interfaces'
import { reduceSetSelectedSessionId } from '../../data/sessions/slice'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { getColorForSessionStatus } from '../../data/sessions/utils'
import { Loading } from '../../mini-lib/loading/Loading'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { COLORS } from '../../mini-lib/theme/colors'

import { formatCentsToDollars } from '../../mini-lib/units/money'
import { reduce } from 'lodash'
import { UserMeta } from '../../data/user/interfaces'
import { SESSION_STYLIST_DATA_OPTIONS } from '../../data/sessions/constants'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../appRoutes'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseViewSize } from '../../core/UseViewSize'
import { PhotoCircle } from '../../mini-lib'
import { UseSideScrollVisibility, scrollToId } from '../../mini-lib/utils/basic'

export const SessionListBySalonMember = (props: {
  allSalonMembers: UserMeta[] | null
  sessions: SessionMetaLara[]
  stylistDataFilter: string
  sessionsLoading: boolean
}) => {
  const { sessions, sessionsLoading, allSalonMembers, stylistDataFilter } = props
  const { isMobile, isTablet, isDesktop } = UseViewSize()
  const sessionsByUser = reduce(
    sessions,
    (acc, session) => {
      const userId = session.user.id
      if (!acc[userId]) {
        acc[userId] = []
      }
      acc[userId].push(session)
      return acc
    },
    {},
  )
  const filteredUsersMeta = allSalonMembers
    ? stylistDataFilter === SESSION_STYLIST_DATA_OPTIONS.all
      ? allSalonMembers
      : allSalonMembers.filter((user) => sessionsByUser[user.id] && sessionsByUser[user.id].length > 0)
    : null

  const buildElementId = (index: number) => {
    return `scroll-index-${index}`
  }
  const [ firstMemberVisible, firstMemberElement ] = UseSideScrollVisibility<HTMLDivElement>(0, 100, true);
  const [ lastMemberVisible, lastMemberElement ] = UseSideScrollVisibility<HTMLDivElement>(0);

  const colWBasedOnNumMembers = getColWidthForNumberOfMembers(filteredUsersMeta?.length || 0, isDesktop)

  const showSideScroll = isMobile
    ? true
    : isTablet
      ? filteredUsersMeta && filteredUsersMeta.length > 3
      : isDesktop
        ? filteredUsersMeta && filteredUsersMeta.length > 5
        : false

  const showLeftScrollButton = !isMobile && !firstMemberVisible && !sessionsLoading && showSideScroll
  const showRightScrollButton = !isMobile && !lastMemberVisible && !sessionsLoading && showSideScroll

  // add refs to the first and last indexes of the salon member columns so we can tell if they are in the viewport
  // if the first member is in the viewport hide the left scroll button
  // if the last member is in the viewport hide the right scroll button
  //
  const maybeBuildRef = (index: number) => {
    return index === 0
      ? firstMemberElement
      : filteredUsersMeta && index === filteredUsersMeta.length - 1
        ? lastMemberElement
        : null
  }
  return (
    <>
      {/* in this case we want the scroll at the top of the container */}
      {/* ref https://stackoverflow.com/a/63507769/10795885 */}
      {/**/}
      <Flex w={isMobile ? '100%' : ''} overflowX="scroll" transform="rotateX(180deg)" position='relative' id='ssm-scroll-container'>
        <Flex transform="rotateX(180deg)" pt="4px" position='relative' justify='start' align='start' w={!showSideScroll ? '100%' : undefined}>

          {/* left scroll button */}
          {/**/}
          {showLeftScrollButton && (
            <Flex position='sticky' left={0} top={0} onClick={() => scrollToId(buildElementId(0))} w='60px' h='100%' bg={COLORS.shades_neutral_50} justify='center' align='top' pt='30vh' cursor='pointer'>
              <MaterialIcon name='chevron_left'/>
            </Flex>
          )}

          {/* columns */}
          {/**/}
          {filteredUsersMeta && !sessionsLoading && filteredUsersMeta.map((member, index) => {
              const sessionsForUser = sessionsByUser[member.id] || []
              const elementId = `scroll-index-${index}`
              return (
                <Box
                  minW={isMobile ? '300px' : colWBasedOnNumMembers}
                  w={isMobile ? '300px' : colWBasedOnNumMembers}
                  h='100%'
                  key={member.id}
                  id={elementId}
                  ref={maybeBuildRef(index)}
                >
                  <SessionsForMemberHeader user={member} />
                  <SessionsForMemberBody sessions={sessionsForUser} index={index} />
                </Box>
              )
            })}

          {/* right scroll button */}
          {/**/}
          {showRightScrollButton && (
            <Flex position='sticky' right={0} top={0} onClick={() => scrollToId(buildElementId(filteredUsersMeta ? filteredUsersMeta.length - 1 : 10))} w='60px' h='100%' bg={COLORS.shades_neutral_50} justify='center' align='top' pt='30vh' cursor='pointer'>
              <MaterialIcon name='chevron_right'/>
            </Flex>
          )}
        </Flex>
      </Flex>

      {sessionsLoading && <Loading mt="40px" color="brand.lavender.500" />}

      {/*{!sessionsLoading && sessions && sessions.length === 0 && (*/}
      {/*  <>*/}
      {/*    <Box h="48px" />*/}
      {/*    <EmptyBox*/}
      {/*      icon={*/}
      {/*        <img*/}
      {/*          alt="logo"*/}
      {/*          style={{ height: '70px', maxWidth: '100%', padding: '4px' }}*/}
      {/*          src={require('../../assets/icons/Calendar.png').default}*/}
      {/*        />*/}
      {/*      }*/}
      {/*      title="NO SESSIONS YET!"*/}
      {/*      content="Start new session to see data."*/}
      {/*    />*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  )
}

const SessionsForMemberHeader = (props: { user: UserMeta }) => {
  const { user } = props
  return (
    <Flex bg={COLORS.shades_neutral_50} p="12px 24px" fontWeight="bold" align="center" gridGap="12px" w="100%">
      <PhotoCircle photoUrl={user.userPhotoUrl} backupText={`${user.firstName[0]}${user.lastName[0]}`} />
      <Text w='100%' isTruncated={true}>{user.fullName}</Text>
    </Flex>
  )
}

const SessionsForMemberBody = (props: { sessions: SessionMetaLara[]; index: number }) => {
  const { sessions, index } = props
  const bg = index % 2 ? COLORS.lavender_50 : ''
  return (
    <Box bg={bg} minH="800px" height='100%'>
      {sessions && sessions.length === 0 && (
        <EmptyBox
          h='200px'
          bg={bg}
          icon={
            <img
              alt="logo"
              style={{ height: '70px', maxWidth: '100%', padding: '4px' }}
              src={require('../../assets/icons/Calendar.png').default}
            />
          }
          title="NO SESSIONS YET!"
          content="Start new session to see data."
        />
      )}
      {sessions &&
        sessions.length > 0 &&
        sessions.map((session, index) => {
          return <SessionForMemberTile key={index} session={session} />
        })}
    </Box>
  )
}

const SessionForMemberTile = (props: { session: SessionMetaLara }) => {
  const { session } = props
  const { salonId } = UseBaseApiParams()
  const dispatch = useDispatch()
  const statusColor = getColorForSessionStatus(session.status || 'open')
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Link to={generatePath(ROUTES.sessionDetails, { salonId, sessionId: session.id })}>
          <Flex
            align="center"
            justify="space-between"
            p="12px"
            w="100%"
            maxW="100%"
            gridGap="12px"
            onClick={() => dispatch(reduceSetSelectedSessionId(session.id))}
          >
            <Flex align="center" gridGap="12px">
              <Box minH="100%" h="60px" w="4px" borderRadius="100px" bg={statusColor} />
              <Box>
                <Text color={statusColor} variant="caption2">
                  {session.status}
                </Text>
                <Text variant="body" isTruncated={true} maxW="140px">
                  {session.client.firstName} {session.client.lastName}
                </Text>

                {/*<Text variant="caption2" fontWeight="normal">*/}
                {/*  {session.date.toLocaleDateString('en-US')}*/}
                {/*</Text>*/}
              </Box>
            </Flex>
            <Flex align="center" justify="space-between" gridGap="8px">
              <Text>${formatCentsToDollars(session.totalClientPurchasePriceCents)}</Text>
              <MaterialIcon size="32px" colorhex={COLORS.lavender_500} name="chevron_right" />
            </Flex>
          </Flex>
        </Link>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Flex align="center">
            <Box w="120px">colors</Box> ${formatCentsToDollars(session.totalClientPurchasePriceSessionColorItemsCents)}
          </Flex>
          <Flex align="center">
            <Box w="120px">addons</Box> ${formatCentsToDollars(session.totalClientPurchasePriceSessionSupplyCents)}
          </Flex>
          <Flex align="center">
            <Box w="120px">extensions</Box> $
            {formatCentsToDollars(session.totalClientPurchasePriceSessionExtensionItemsCents)}
          </Flex>
          <Divider />
          <Flex align="center">
            <Box w="120px">total</Box> ${formatCentsToDollars(session.totalClientPurchasePriceCents)}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export const getColWidthForNumberOfMembers = (numMembers: number, isDesktop: boolean): string => {
  if (numMembers === 2) {
    return '50%'
  }
  if (numMembers === 3) {
    return '33%'
  }
  if (numMembers === 4 && isDesktop) {
    return '25%'
  }
  if (numMembers === 5 && isDesktop) {
    return '20%'
  }
  return '300px'
}