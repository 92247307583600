import React from 'react'
import { Button, Flex, Box, ModalHeader, Divider, Checkbox, Modal as ChakraModal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { assign, omit, orderBy, startCase, sumBy, toLower } from 'lodash'
import { buildCsv } from '../../mini-lib/csv/buildCsv'
import {
  ClientDetailReportCSV,
  ClientDetailReportLaraCSVRow,
  ClientReportCSV,
  ClientReportLaraCSVRow,
  ExtensionDetailUnusedProductCSVRow,
  StylistReportLaraCSVRow,
  StylistDetailReportLaraCSVRow,
  ExtensionReportLaraCSVRow,
  ExtensionDetailUsedProductCSVRow,
  ExtensionReportDetailsLaraCSVRow,
  AddonReportCSV,
  StylistReportCSV,
  StylistDetailReportCSV
} from '../../data/report/interfaces'

export const ExportReportsCSVModal = ({ setIsModalOpen, isModalOpen, csvDataList = [], keyToDisplay, modalHeader = "", buttonText = "submit", csvTitle, showTotalFor = [] }:
  {
    setIsModalOpen: any,
    isModalOpen: boolean,
    csvDataList:
    | ClientReportLaraCSVRow[]
    | ClientReportLaraCSVRow[]
    | ClientDetailReportLaraCSVRow[]
    | ClientReportCSV[]
    | ClientDetailReportCSV[]
    | ExtensionDetailUnusedProductCSVRow[]
    | ClientReportLaraCSVRow[]
    | StylistReportLaraCSVRow[]
    | StylistDetailReportLaraCSVRow[]
    | ExtensionReportLaraCSVRow[]
    | ExtensionDetailUsedProductCSVRow[]
    | ClientDetailReportLaraCSVRow[]
    | ExtensionReportDetailsLaraCSVRow[]
    | AddonReportCSV[]
    | StylistReportCSV[]
    | StylistDetailReportCSV[]
    | ClientReportCSV[]
    | ClientDetailReportCSV[]
    | null

    keyToDisplay: string,
    modalHeader: string,
    buttonText: string,
    csvTitle: string,
    showTotalFor: string[]
  }) => {

  const [selectedItemsMap, setSelectedItemsMap] = React.useState<{}>({})
  const [itemsMap, setItemMap] = React.useState({})

  React.useEffect(() => {
    const listWithoutTotal = csvDataList?.map(item => item)
    listWithoutTotal?.pop()
    const uniqueMap = {}
    uniqueMap['Combined Report'] = listWithoutTotal
    orderBy(listWithoutTotal, ['stylistName'], ['desc'])?.forEach((item) => {
      const key = item[keyToDisplay]
      uniqueMap[key] = uniqueMap[key] ? [...uniqueMap[key], item] : [item]
    })
    setItemMap(uniqueMap)
  }, [csvDataList, keyToDisplay])

  const onClose = async () => {
    setIsModalOpen(false)
    setSelectedItemsMap({})
  }

  const calculateTotal = (reportName) => {
    let row = { ...selectedItemsMap[reportName][0] }
    Object.keys(row).forEach((rowKey) => {
      if (keyToDisplay === rowKey) {
        row[rowKey] = 'Total'
      } else {
        row[rowKey] = ''
      }
      const keyForTotal = showTotalFor.filter(x => x === rowKey)
      if (keyForTotal.length) {
        row[rowKey] = parseFloat(sumBy(selectedItemsMap[reportName], rowKey).toFixed(2))
      }
    })

    return row
  }

  const onExport = () => {
    const reportKeys = Object.keys(selectedItemsMap)
    const tableHeader = Object.keys(selectedItemsMap[reportKeys[0]][0])
    reportKeys.forEach((reportName) => {
      const totalRow = calculateTotal(reportName)
      const fileName = reportName === 'Combined Report' ? csvTitle : reportName
      
      const reportWithTotal = [...selectedItemsMap[reportName], totalRow]
     
      buildCsv(fileName, tableHeader, reportWithTotal)
    })
    onClose()
  }

  const toggleSelected = (keys: string[]) => {
    let updatedMap = {}
    keys.forEach(key => {
      if (selectedItemsMap[key]) {
        updatedMap = { ...omit(selectedItemsMap, [key]), ...updatedMap }
      } else {
        updatedMap = assign(updatedMap, selectedItemsMap, { [key]: itemsMap[key] })
      };
    })
    setSelectedItemsMap(updatedMap)
  }

  const toggleSelectAll = (checked) => {
    if (checked) {
      toggleSelected([...Object.keys(itemsMap), 'Combined Report'])
    } else {
      setSelectedItemsMap({})
    }

  }
  const isSubmitDisabled = !Boolean(Object.keys(selectedItemsMap).length)
  const isSelectAllReportsDisabled = Object.keys(itemsMap).length === Object.keys(selectedItemsMap).length
  
  return (
    <ChakraModal isOpen={isModalOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt={4} mb={4}>
          <ModalHeader padding={0}>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <Divider mt={3} mb={3} />
          <Box mb={3} mt={3}>
            <Checkbox
              colorScheme="brand.lavender"
              onChange={(e) => { toggleSelectAll(e.target.checked) }}
              isChecked={isSelectAllReportsDisabled}
            >
              <Text as='i'>Select All Reports</Text>

            </Checkbox>
          </Box>
          <Box>
          </Box>
          {Object.keys(itemsMap)?.map((reportName) => {
            return (
              <Box key={reportName} mb={3} mt={3}>
                <Checkbox
                  colorScheme="brand.lavender"
                  onChange={(e) => toggleSelected([reportName])}
                  isChecked={Boolean(selectedItemsMap[reportName])}
                >
                  {startCase(toLower(reportName))}
                </Checkbox>
              </Box>)
          })}
          <Divider mt={4} mb={4} />
          <Flex alignItems={'flex-end'} justifyContent={'end'}>
            <Button disabled={isSubmitDisabled} onClick={onExport} variant="round" className="cy-client-add" colorScheme="brand.midnight">
              {buttonText}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}
