import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, keyBy, omit, orderBy, values } from 'lodash'
import { RootState } from '../../store'
import {
  LoggedInUser,
  LoggedInUserSalonContext,
  LoggedInUserSalonPermissions,
  SalonMember,
  SalonMemberLara,
  SalonRole, SalonUserTier, UserDeviceInfo, UserMeta,
} from './interfaces'
import * as Sentry from '@sentry/react'
import { UserLaborTier } from '../labor/interfaces'

interface UserState {
  salonMembersById: { [key: string]: SalonMember } | null
  rolesByUserId: { [key: string]: SalonRole } | null
  loggedInUser: LoggedInUser | null
  loggedInUserSalonPermissions: LoggedInUserSalonPermissions | null
  selectedStylist: SalonMember | null
  selectedUserMeta: UserMeta | null
  isSalonMemberSheetOpen: boolean
  salonMembersFromLara: { [key: string]: SalonMemberLara } | null
  usersMetaById: { [key: string]: UserMeta } | null
  setPasswordSuccessfully: boolean
  userDeviceInfo: UserDeviceInfo | null
  salonUserTiersByUserId: { [key: string]: SalonUserTier } | null
}

const initialState: UserState = {
  salonMembersById: null, // null = loading, [] = empty
  rolesByUserId: null, // null = loading, [] = empty
  loggedInUser: null,
  loggedInUserSalonPermissions: null,
  selectedStylist: null,
  selectedUserMeta: null,
  isSalonMemberSheetOpen: false,
  salonMembersFromLara: null,
  usersMetaById: null,
  userDeviceInfo: null,
  setPasswordSuccessfully: false,
  salonUserTiersByUserId: null,
}

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // TODO - remove reduceListMembersLara and salonMembersFromLara once the users api completely replaced
    reduceListMembersLara: (state, action: PayloadAction<SalonMemberLara[]>) => {
      state.salonMembersFromLara = keyBy(action.payload, 'userId')
    },
    reduceListMembers: (state, action: PayloadAction<SalonMember[]>) => {
      state.salonMembersById = keyBy(action.payload, 'userId')
    },
    reduceListSalonMemberRoles: (state, action: PayloadAction<SalonRole[]>) => {
      state.rolesByUserId = keyBy(action.payload, 'userId')
    },
    reduceLoggedInUser: (state, action: PayloadAction<LoggedInUser>) => {
      Sentry.setUser({ email: action.payload?.email })
      state.loggedInUser = action.payload
    },
    reduceUpdateLoggedInUser: (state, action: PayloadAction<Partial<LoggedInUser>>) => {
      state.loggedInUser = assign({}, state.loggedInUser, action.payload)
    },
    reduceLoggedInUserSalonPermissions: (state, action: PayloadAction<LoggedInUserSalonPermissions>) => {
      state.loggedInUserSalonPermissions = action.payload
    },
    reduceCreateStylist: (state, action: PayloadAction<SalonMember>) => {
      if (state.salonMembersById) {
        state.salonMembersById[action.payload.userId] = action.payload
      }
    },
    reduceDeleteStylist: (state, action: PayloadAction<{ deleted: boolean; id: number; userId: number }>) => {
      if (action.payload.deleted) {
        state.salonMembersById = omit(state.salonMembersById, action.payload.userId)
      }
    },
    reduceDeleteUserMeta: (state, action: PayloadAction<{ deleted: boolean; id: number; userId: number }>) => {
      if (action.payload.deleted) {
        state.usersMetaById = omit(state.usersMetaById, action.payload.userId)
      }
    },
    reduceSetSelectedStylist: (state, action: PayloadAction<SalonMember | null>) => {
      state.selectedStylist = action.payload
    },
    reduceSetSelectedUserMeta: (state, action: PayloadAction<UserMeta | null>) => {
      state.selectedUserMeta = action.payload
    },
    reduceSetIsSalonMemberSheetOpen: (state, action: PayloadAction<boolean>) => {
      state.isSalonMemberSheetOpen = action.payload
    },

    reduceUpdateLaborTierUsers: (state, action: PayloadAction<UserLaborTier[]>) => {
      action.payload.forEach((userLaborTier) => {
        const existingUser = state.salonMembersById ? state.salonMembersById[userLaborTier.userId] : null
        if (state.salonMembersById && existingUser) {
          state.salonMembersById[userLaborTier.userId] = assign({}, existingUser, {
            laborTier: {
              id: userLaborTier.id,
              name: userLaborTier.name,
            },
          })
        }
      })
    },
    reduceDeleteLaborTierUsers: (state, action: PayloadAction<SalonMember[]>) => {
      action.payload.forEach((salonMember) => {
        const existingUser = state.salonMembersById ? state.salonMembersById[salonMember.userId] : null
        if (state.salonMembersById && existingUser) {
          state.salonMembersById[salonMember.userId] = assign({}, existingUser, { laborTier: { id: null, name: null } })
        }
      })
    },
    reduceUpdateLoggedInUserCurrentSalonContext: (state, action: PayloadAction<LoggedInUserSalonContext | null>) => {
      state.loggedInUser = assign({}, state.loggedInUser, { currentSalonContext: action.payload })
    },
    reduceUpdateSalonMemberRole: (state, action: PayloadAction<SalonRole>) => {
      state.rolesByUserId = assign({}, state.rolesByUserId, { [action.payload.userId]: action.payload })
    },
    reduceUsersMeta: (state, action: PayloadAction<UserMeta[]>) => {
      state.usersMetaById = keyBy(action.payload, 'id')
    },
    reduceUserDeviceInfo: (state, action: PayloadAction<UserDeviceInfo>) => {
      state.userDeviceInfo = action.payload
    },
    reduceSetPasswordSuccessfully: (state, action: PayloadAction<boolean>) => {
      state.setPasswordSuccessfully = action.payload
    },
    reduceUpdateUserMeta: (state, action: PayloadAction<UserMeta>) => {
      const existing = state.usersMetaById ? state.usersMetaById[action.payload.userId] : null
      if (existing && state.usersMetaById) {
        state.usersMetaById[action.payload.userId] = action.payload
      } else if (action.payload?.userId) {
        state.usersMetaById = { [action.payload.userId]: action.payload }
      }
    },
    reduceListSalonUserTiers: (state, action: PayloadAction<SalonUserTier[]>) => {
      state.salonUserTiersByUserId = keyBy(action.payload, 'userId')
    },
    reduceDeleteSalonUserTiers: (state, action: PayloadAction<{userId: number}[]>) => {
      action.payload.forEach(tier => {
        state.salonUserTiersByUserId = omit(state.salonUserTiersByUserId, tier.userId)
      })
    },
    reduceBulkUpsertSalonUserTiers: (state, action: PayloadAction<SalonUserTier[]>) => {
      state.salonUserTiersByUserId = keyBy(action.payload, 'userId')
    },
  },
})

export const {
  reduceUpdateSalonMemberRole,
  reduceListMembers,
  reduceLoggedInUser,
  reduceDeleteStylist,
  reduceUpdateLaborTierUsers,
  reduceDeleteLaborTierUsers,
  reduceSetSelectedStylist,
  reduceDeleteUserMeta,
  reduceSetSelectedUserMeta,
  reduceUpdateLoggedInUserCurrentSalonContext,
  reduceLoggedInUserSalonPermissions,
  reduceSetIsSalonMemberSheetOpen,
  reduceListMembersLara,
  reduceUpdateLoggedInUser,
  reduceUsersMeta,
  reduceSetPasswordSuccessfully,
  reduceUserDeviceInfo,
  reduceUpdateUserMeta,
  reduceListSalonUserTiers,
  reduceDeleteSalonUserTiers,
  reduceBulkUpsertSalonUserTiers
} = slice.actions

export const selectMemberList = (state: RootState): SalonMember[] | null => {
  const lastNameSorter = (member) => member.lastName.toLowerCase()
  const firstNameSorter = (member) => member.firstName.toLowerCase()
  return state.users.salonMembersById
    ? orderBy(values(state.users.salonMembersById), [firstNameSorter, lastNameSorter], ['asc', 'asc'])
    : null
}

export const selectTotalNumberMembers =  (state: RootState): number | null => {
  return state.users.salonMembersById ? values(state.users.salonMembersById).length : null
}

  // TODO - replace the name to selectMemberList once the users api completely replaced
export const selectMemberListLara = (state: RootState): SalonMemberLara[] | null => {
  const lastNameSorter = (member) => member.lastName.toLowerCase()
  const firstNameSorter = (member) => member.firstName.toLowerCase()
  return state.users.salonMembersFromLara
    ? orderBy(values(state.users.salonMembersFromLara), [firstNameSorter, lastNameSorter], ['asc', 'asc'])
    : null
}

export const numberOfOwnersInSalon = (state: RootState): number => {
  const roles = values(state.users.rolesByUserId)
  let numOwners = 0
  roles.forEach((role) => {
    if (role.role.roleId === 1) {
      numOwners += 1
    }
  })
  return numOwners
}
export const selectSalonRolesByUserId = (state: RootState) => state.users.rolesByUserId
export const selectSalonRoleForUser = (state: RootState): SalonRole | null => {
  if (state.users.rolesByUserId && state.users.selectedUserMeta) {
    return state.users.rolesByUserId[state.users.selectedUserMeta.userId] || null
  }
  return null
}
export const selectLoggedInUser = (state: RootState) => state.users.loggedInUser
export const selectLoggedInUserSalonPermissions = (state: RootState) => state.users.loggedInUserSalonPermissions
export const selectCurrentSalonContext = (state: RootState): LoggedInUserSalonContext | null => {
  return state.users?.loggedInUser?.currentSalonContext || null
}

export const selectSelectedStylist = (state: RootState) => state.users.selectedStylist
export const selectSelectedUserMeta = (state: RootState) => state.users.selectedUserMeta
export const selectUserDeviceInfo = (state: RootState) => state.users.userDeviceInfo
export const selectSetIsSalonMemberSheetOpen = (state: RootState) => state.users.isSalonMemberSheetOpen
export const selectUsersMetaById = (state: RootState) => state.users.usersMetaById
export const selectUsersMeta = (state: RootState): UserMeta[] | null => {
  const lastNameSorter = (member) => member.lastName.toLowerCase()
  const firstNameSorter = (member) => member.firstName.toLowerCase()
  return state.users.usersMetaById
    ? orderBy(values(state.users.usersMetaById), [firstNameSorter, lastNameSorter], ['asc', 'asc'])
    : null
}
export const selectSetPasswordSuccessfully = (state: RootState) => state.users.setPasswordSuccessfully

export const selectSalonUserTiersByUserId = (state: RootState) => state.users.salonUserTiersByUserId
export const selectSalonUserTiers = (state: RootState) => {

  return state.users.salonUserTiersByUserId
    ? values(state.users.salonUserTiersByUserId)
    : null
}

export default slice.reducer
