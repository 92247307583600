import { CSVMasterProduct, CsvRowStatus, CsvRowType, MasterProduct } from './interfaces'
import { assign, has, orderBy } from 'lodash'
import { mapMasterProductToCSVMasterProduct } from './mappers'
import { Product } from '../products/interfaces'
import { INTERNAL_VENDOR_NAMES } from "./constants";

export const mergeRowsAndMasterProducts = (
  rows: any[],
  masterProducts: MasterProduct[] | null,
): CSVMasterProduct[] | any => {
  const mergedRows: MasterProduct[] | CSVMasterProduct[] | any = []

  const masterProductIdsToRemove: number[] = []
  if (rows) {
    rows.forEach((row) => {
      const matches = masterProducts ? getMasterProductsThatMatchCsvRow(row, masterProducts) : []
      const existing = masterProducts ? getMasterProductsThatHaveUpc(row, masterProducts) : []
      const matchesIds = matches.map((mp) => mp.id)
      const existingIds = existing.map((mp) => mp.id)
      masterProductIdsToRemove.push(...matchesIds)
      masterProductIdsToRemove.push(...existingIds)
      const updatedRow = assign({}, row, { id: -1, existing, matches })
      mergedRows.push(updatedRow)
    })
  }
  if (masterProducts) {
    const filteredMasterProducts = masterProducts.filter((mp) => !masterProductIdsToRemove.includes(mp.id))
    filteredMasterProducts.forEach((mp) => {
      mergedRows.push(mapMasterProductToCSVMasterProduct(mp))
    })
  }
  const property1Sorter = (row) => row.parent_brand.toLowerCase()
  const property2Sorter = (row) => row.brand.toLowerCase()
  const property3Sorter = (row) => row.type.toLowerCase()
  return orderBy(mergedRows, [property1Sorter, property2Sorter, property3Sorter], ['asc', 'asc', 'asc'])
  // return rows.map((row) => {
  //   const matches = getMasterProductsThatMatchCsvRow(row, masterProducts) || []
  //   const existing = getMasterProductsThatHaveUpc(row, masterProducts) || []
  //   const updatedRow = assign({}, row, { existing, matches })
  //   return updatedRow
  // })
}

export const getHasStatus = (row: CSVMasterProduct, status: CsvRowStatus): boolean => {
  if (status === '') {
    return true
  }
  if (status === 'upc exists' && row.existing.length > 0) {
    return true
  }
  if (status === 'matches' && row.matches.length > 0) {
    return true
  }
  if (status === 'no matches' && row.matches.length === 0 && row.existing.length === 0 && row.id === -1) {
    return true
  }
  return false
}
export const getHasType = (row: CSVMasterProduct, type: CsvRowType): boolean => {
  if (type === '') {
    return true
  }
  if (type === 'csv' && row.id === -1) {
    return true
  }
  if (type === 'master product' && row.id > 0) {
    return true
  }
  return false
}
export const filterSingleRow = (
  row: CSVMasterProduct,
  filters: { csvRowStatus: CsvRowStatus; rowType: CsvRowType },
): boolean => {
  const { csvRowStatus, rowType } = filters
  const hasStatus = getHasStatus(row, csvRowStatus)
  const hasType = getHasType(row, rowType)
  return hasStatus && hasType
}

export const filterMergedRowsAndMasterProducts = (
  rows: CSVMasterProduct[],
  filters: { csvRowStatus: string; rowType: string },
): CSVMasterProduct[] => {
  return rows.filter((row) => filterSingleRow(row, filters))
}

export const getMasterProductsThatHaveUpc = (row: any, masterProducts: MasterProduct[]): MasterProduct[] => {
  if (has(row, 'upc')) {
    return masterProducts.filter((mp) => mp.upcs.includes(row.upc))
  }
  return []
}

export const getMasterProductsThatMatchCsvRow = (row: any, masterProducts: MasterProduct[]): MasterProduct[] => {
  return masterProducts.filter((mp) => masterProductMatchesCsvRow(row, mp)) || []
}

// returns true if they are an exact match
export const masterProductMatchesCsvRow = (row: CSVMasterProduct, masterProduct: MasterProduct): boolean => {
  const masterProductDoesNotAlreadyHaveUpc = !masterProduct.upcs.includes(row.upc.toString())
  const hasBrand = has(row, 'brand') && row.brand.toLowerCase() === masterProduct.brandName.toLowerCase()
  const hasParentBrand =
    has(row, 'parent_brand') && row.parent_brand.toLowerCase() === masterProduct.parentBrandName.toLowerCase()
  const hasCategory = has(row, 'category') && row.category.toLowerCase() === masterProduct.category.toLowerCase()
  const hasType = has(row, 'type') && row.type.toLowerCase() === masterProduct.type.toLowerCase()
  const hasSize = has(row, 'size') && row.size.toString() === masterProduct.size.toString()
  return hasBrand && hasParentBrand && hasCategory && hasType && hasSize && masterProductDoesNotAlreadyHaveUpc
}

// returns true if they are maybe a match
export const masterProductIncludesCsvRow = (row: CSVMasterProduct, masterProduct: MasterProduct): boolean => {
  const includesBrand = has(row, 'brand') && masterProduct.brandName.toLowerCase().includes(row.brand.toLowerCase())
  const includesParentBrand =
    has(row, 'parent_brand') && masterProduct.parentBrandName.toLowerCase().includes(row.parent_brand.toLowerCase())
  const includesCategory =
    has(row, 'category') && masterProduct.category.toLowerCase().includes(row.category.toLowerCase())
  const includesType = has(row, 'type') && masterProduct.type.toLowerCase().includes(row.type.toLowerCase())
  return includesBrand && includesParentBrand && includesCategory && includesType
}

export const getProductForMasterProduct = (masterProduct: MasterProduct, products: Product[]): Product | null => {
  const product = products?.find((p) => {
    return p.type === masterProduct.type && p.line.name === masterProduct.brandName && p.size === masterProduct.size
  })
  return product || null
}

// requirements
// unique loading per line and per product
// single master product -> loading-mp-{mp id}
// multiple master products -> loading-mps-{mp id}-{length of mps} // mp id for uniqueness among multiple lines
export const buildMasterProductsLoadingName = (mps: MasterProduct[]): string => {
  if (mps.length === 1) {
    return `loading-mp-${mps[0].id}`
  }
  if (mps.length > 1) {
    return `loading-mps-${mps[0].id}-${mps.length}`
  }
  return `loading-mps`
}

export const filterMasterProducts = (params: {masterProducts: MasterProduct[], searchText: string, isInternalSalon}): MasterProduct[] => {
  const {masterProducts, searchText, isInternalSalon} = params
  const filteredMasterProducts = masterProducts.filter(( mp ) => {
    const isInternalVendor = mp.parentBrandName && INTERNAL_VENDOR_NAMES.includes(mp.parentBrandName)
    const internalVendorAllowed = !isInternalVendor || (isInternalVendor && isInternalSalon)
    const normalizedSearchText = searchText.toLowerCase()
    const matchesLine = mp.brandName.toLowerCase().includes(normalizedSearchText)
    const matchesType = mp.type.toLowerCase().includes(normalizedSearchText)
    const matchesVendor = mp.parentBrandName.toLowerCase().includes(normalizedSearchText)
    return internalVendorAllowed && (matchesLine || matchesType || matchesVendor)
    }
  )
  return filteredMasterProducts
}

export const trialPriceByCategory = {
  'Developer': 10,
  'Permanent': 10,
  'Bleach': 10,
  'Demi': 10,
  'Semi': 10,
  'Highlift': 10,
  'Temporary': 10,
  'Bonder': 10,
  'Miscellaneous': 10,
  'Additive': 10,
}
