import { Box, Button, Flex, Input } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseViewSize } from '../../core/UseViewSize'
import { dispatchListOrdersLara } from '../../data/orders/api'
import { selectOrdersLara } from '../../data/orders/slice'
import { filterOrdersLara } from '../../data/orders/utils'
import { useAppSelector } from '../../hooks'
import { DateInput } from '../../mini-lib'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { Loading } from '../../mini-lib/loading/Loading'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { OrderListLara } from './OrderList'
import { OrderCreateLegacyModal } from './OrderCreateLegacyModal'
import {
  ReleaseInventoryOrders,
  ReleaseLaraColors,
  ReleasePageHelp
} from '../../mini-lib/flags/Release'
import { selectActiveSalonContext } from '../../data/salon/slice'
import { selectProductsById } from "../../data/products/slice";
import { dispatchListProducts } from "../../data/products/api";
import { EmptyFilters } from "../../mini-lib";
import { EmptyPage } from "../../mini-lib/empty/EmptyPage";
import { ROUTES } from "../../appRoutes";
import { HelpDrawer } from "../start-guide/common-components/HelpDrawer";
import { VIDEO_MAP } from "../../mini-lib/video/constants";

export const OrdersPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const releaseInventoryOrders = ReleaseInventoryOrders()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const activeSalon = useAppSelector(selectActiveSalonContext)
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Orders' }]
  const path = releaseInventoryOrders && Boolean(activeSalon?.targetSetCount) ? generatePath(ROUTES.ordersCreate, { salonId }) + '?pageType=inventory' : generatePath(ROUTES.ordersCreate, { salonId })
  const { isMobile } = UseViewSize()
  const [numRowsToShow, setNumRowsToShow] = useState(20)
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState<null | Date>(null)
  const [endDate, setEndDate] = useState<null | Date>(null)
  const filterActive = searchText !== '' || startDate || endDate

  const productsById = useAppSelector(selectProductsById)

  const ordersLara = useAppSelector(selectOrdersLara)

  const filteredOrdersLara = ordersLara && productsById ? filterOrdersLara(ordersLara, productsById, searchText, startDate, endDate) : null
  const pagedFilteredOrdersLara = filteredOrdersLara ? filteredOrdersLara.slice(0, numRowsToShow) : null


  const [isModalOpen, setIsModalOpen] = React.useState(false)
  // Inventory feature modal only pops if there is ant order before 2023-03-1
  // set to false as the warning modal is not needed after 2023-05-31
  const showModal = false;
  const onClickCreateNewOrder = () => {
    showModal && releaseInventoryOrders ? setIsModalOpen(true) : history.push(path)
  }

  const releaseLaraColors = ReleaseLaraColors()
  const releasePageHelp = ReleasePageHelp()

  useEffect(() => {
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, releaseLaraColors])

  useEffect(() => {
    dispatch(dispatchListOrdersLara({ token, salonId }))
  }, [dispatch, token, salonId])

  return (
    <>
      <PageLayout
        variant="full"
        header={
          <PageHeader
            showHelp={true}
            title="Orders"
            breadcrumbs={breadcrumbs}
            actions={
              <Flex align="center" gridGap="12px">
                <Button onClick={onClickCreateNewOrder} variant="round" className="cy-client-add"
                        colorScheme="brand.midnight">
                  Create New Order
                </Button>
              </Flex>
            }
          />
        }
        content={
          <>
            {releasePageHelp && (
              <HelpDrawer
                video={VIDEO_MAP.createOrder}
                title='Let’s take a look inside Orders'
                content={`The order feature is a real wow factor, and we can't wait for you to see it! We like to say it changes ordering from "Just in Case" to a much more manageable "Just in Time". Plus, if you use a 100% markup, it literally pays for itself.  Based on your exact usage, SalonScale will create an order form for your products used. Download this to send to your sales rep, or as reference to easily plug in your orders online. No more ripping tabs, writing on napkins or scrambling to get that order in.`}
              />
            )}
            <Box h="24px"/>
            <Flex maxW="100%" align={'center'} justify={'space-between'} flexWrap="wrap" gridGap="24px">
              <Box w={isMobile ? '100%' : '300px'}>
                <Input
                  borderRadius="50px"
                  type="search"
                  placeholder="Search Orders"
                  onChange={( evt ) => setSearchText(evt.target.value)}
                />
              </Box>
              <Flex align={'center'}>
                {!isMobile && <Box w="80px">Filter By: </Box>}
                <Box ml={'5px'}>
                  <Flex justify="flex-end" gridGap="12px">
                    <Box>
                      <DateInput
                        placeholder="Start Date"
                        value={startDate}
                        onChange={( date ) => {
                          setStartDate(date)
                        }}
                        isClearable={true}
                      />
                    </Box>
                    <Box>
                      <DateInput
                        placeholder="End Date"
                        value={endDate}
                        onChange={( date ) => {
                          setEndDate(date)
                        }}
                        isClearable={true}
                      />
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
            <Box h="24px"/>

            {/* lara orders start */}
            {/**/}
              {pagedFilteredOrdersLara && pagedFilteredOrdersLara.length > 0 &&
                  <OrderListLara orders={pagedFilteredOrdersLara}/>}

              {pagedFilteredOrdersLara && pagedFilteredOrdersLara.length === 0 && filterActive && (
                <EmptyFilters
                  theme='midnight'
                  title='Nothing matched your filters'
                  subtitle='Please refine your search or create an order'
                />
              )}
              {pagedFilteredOrdersLara && pagedFilteredOrdersLara.length === 0 && !filterActive && (
                <EmptyPage
                  title="NO ORDERS CREATED"
                  content="Create an order to see data here!"
                >
                  <Box h="24px"/>
                  <Button onClick={onClickCreateNewOrder} variant="round" className="cy-client-add"
                          colorScheme="brand.midnight">
                    Let's Go!
                  </Button>
                </EmptyPage>
              )}
              {filteredOrdersLara && filteredOrdersLara.length > numRowsToShow && (
                <Flex justify="center">
                  <Button variant="round-ghost-lower" onClick={() => setNumRowsToShow(numRowsToShow + 20)}>
                    View More
                  </Button>
                </Flex>
              )}
              <Box h="24px"/>
              {!pagedFilteredOrdersLara && <Loading/>}

            {/**/}
            {/* lara orders end */}


          </>
        }
      />
      {releaseInventoryOrders && <OrderCreateLegacyModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}
                                                         showModal={Boolean(showModal)}/>}
    </>
  )
}
