import { Product } from '../../../data/products/interfaces'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { buildDeletingName, buildLoadingName } from '../../../core/loading/utils'
import { useAppSelector } from '../../../hooks'
import { selectLoadingState } from '../../../core/loading/slice'
import { calculateMarkupNumber, getUpdatedProducts } from '../../../data/products/utils'
import React, { useEffect, useState } from 'react'
import { dispatchDeleteProducts, dispatchPatchColors, dispatchUpdateProducts } from '../../../data/products/api'
import { Box, Flex, NumberInput, NumberInputField, Text } from '@chakra-ui/react'
import { Loading } from '../../../mini-lib/loading/Loading'
import { addDollarSign, addPercent, removeDollarSign, removePercent } from '../../../mini-lib/utils/basic'
import { COLORS } from '../../../mini-lib/theme/colors'
import { UseViewSize } from '../../../core/UseViewSize'
import { getHelpStepIndex } from '../../../data/start-guide/utils'
import { HelpPopover } from '../../start-guide/common-components/HelpPopover'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { UseShouldShowOnHand, UseShouldShowTarget } from '../../../data/inventory/utils'
import { ReleaseLaraColors } from '../../../mini-lib/flags/Release'
import { TrialPricingTooltip } from '../lines/TrialPricing'
import { ConfirmPopover } from '../../../mini-lib/confirm-popover/ConfirmPopover'

export const ProductAutoSaveForm = (props: {
  products: Product[]
  onProductsUpdated?: () => void
  index?: number
  showTarget?: boolean
  showDelete?: boolean
}) => {
  const dispatch = useDispatch()
  const { products, showDelete = false, onProductsUpdated = () => {}, index = -1 } = props
  const { user, salonId } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  const releaseColors = ReleaseLaraColors()
  const showTarget = UseShouldShowTarget()
  const showOnHand = UseShouldShowOnHand(products.length === 1)
  const product = products && products.length > 0 ? products[0] : null
  const loadingName = product ? buildLoadingName(product?.id) : 'loading'
  const deletingName = product ? buildDeletingName(product?.id) : 'deleting'
  const isLoading = useAppSelector((state) => selectLoadingState(state, loadingName))
  const isDeleting = useAppSelector((state) => selectLoadingState(state, deletingName))

  const defaultMarkup = user.currentSalonContext?.defaultMarkupPercentage || 0
  const calculatedMarkup = product ? calculateMarkupNumber(product) : null

  const productCost = product?.inventory?.cost ? product.inventory.cost.toString() : ''
  const productOnHand = product?.inventory?.quantityOnHand ? product.inventory.quantityOnHand.toString() : ''
  const productTarget = product?.inventory?.maxStockLevel ? product.inventory.maxStockLevel.toString() : ''
  const productMarkup = calculatedMarkup !== null ? calculatedMarkup.toString() : defaultMarkup.toString()
  const isAutoPrice = product?.isDefaultPrice

  const [loadingField, setLoadingField] = useState<string>('')
  const [localOnHand, setLocalOnHand] = useState<string>(productOnHand)
  const [localTarget, setLocalTarget] = useState<string>(productTarget)
  const [localCostDollars, setLocalCost] = useState<string>(productCost)
  const [localMarkup, setLocalMarkup] = useState<string>(productMarkup)

  // when product prices get set from bulk actions we want to override the current value to the latest product value
  useEffect(() => {
    if (parseFloat(productOnHand) > 0) {
      setLocalOnHand(productOnHand)
    } else {
      setLocalOnHand('')
    }
  }, [productOnHand])

  useEffect(() => {
    if (parseFloat(productTarget) > 0) {
      setLocalTarget(productTarget)
    } else {
      setLocalTarget('')
    }
  }, [productTarget])

  useEffect(() => {
    setLocalCost(productCost)
  }, [productCost])

  useEffect(() => {
    setLocalMarkup(productMarkup)
  }, [productMarkup])

  const updateProduct = (updateType: 'onhand' | 'target' | 'cost' | 'markup') => {
      const updatedProducts = getUpdatedProducts({
        existingProducts: products,
        updatedProperties: {

          setCostDollars: updateType === 'cost',
          costDollars: localCostDollars,

          setMarkup: updateType === 'markup',
          markup: localMarkup,

          setTarget: updateType === 'target',
          target: localTarget,

          setOnHand: updateType === 'onhand',
          onHand: localOnHand
        }
      })
      if (user) {
        const params = {
          token: user.token,
          user_id: user.userId,
          salon_id: salonId,
          models: updatedProducts,
          loadingName: loadingName,
        }
        onProductsUpdated()
        if (releaseColors) {
          dispatch(dispatchPatchColors(params))
        } else {
          dispatch(dispatchUpdateProducts(params))
        }
      }
  }
  const fieldWidth = getFieldWidth(showTarget, showOnHand, showDelete)
  return (
    <Flex align="center" wrap="wrap" gridGap="4px" w="100%">
      <Box h="12px" />
      <Flex
        w="100%"
        gridGap={isMobile ? '2px' : '12px'}
        justify="space-between"
        wrap={isMobile ? 'wrap' : 'nowrap'}
        align="center"
      >
        {showOnHand && (
          <NumberInput
            w={fieldWidth}
            h="40px"
            min={0}
            onChange={(x) => {
              setLocalOnHand(x)
            }}
            value={localOnHand}
            onBlur={() => {
              setLoadingField('onhand')
              updateProduct('onhand')
            }}
          >
            {isLoading && loadingField === 'onhand' ? (
              <Loading h="100%" w="100%" />
            ) : (
              <NumberInputField borderRadius="100px" p="0 16px" textAlign="center" placeholder='not set' _placeholder={{'fontSize': '12px' }}/>
            )}
          </NumberInput>
        )}
        {showTarget && (
          <HelpPopover
            guideTypes={[CHECKLIST_CODES.inventorySetTargets]}
            children={
              <NumberInput
                w={fieldWidth}
                h="40px"
                min={0}
                onChange={(x) => {
                  setLocalTarget(x)
                }}
                value={localTarget}
                onBlur={() => {
                  setLoadingField('target')
                  updateProduct('target')
                }}
              >
                {isLoading && loadingField === 'target' ? (
                  <Loading h="100%" w="100%" />
                ) : (
                  <NumberInputField borderRadius="100px" p="0 16px" textAlign="center" placeholder='not set' _placeholder={{'fontSize': '12px' }}/>
                )}
              </NumberInput>
            }
            description="Add your products target or max amount you want on your shelf."
            stepIndex={getHelpStepIndex(index, 1)}
          />
        )}
        <NumberInput
          w={fieldWidth}
          min={0}
          h="40px"
          onChange={(val) => {
            setLocalCost(removeDollarSign(val))
          }}
          value={localCostDollars ? addDollarSign(localCostDollars) : ''}
          onBlur={() => {
            setLoadingField('cost')
            updateProduct('cost')
          }}
        >
          {isLoading && loadingField === 'cost' ? (
            <Loading h="100%" w="100%" />
          ) : (
            <Flex>
              <NumberInputField borderRadius="100px" p="0 16px" textAlign="center" placeholder='not set' _placeholder={{'fontSize': '12px' }}/>
              {isAutoPrice && <TrialPricingTooltip />}
            </Flex>
          )}
        </NumberInput>
        <NumberInput
          w={fieldWidth}
          min={0}
          h="40px"
          onChange={(val) => {
            setLocalMarkup(removePercent(val))
          }}
          value={addPercent(localMarkup)}
          onBlur={() => {
            setLoadingField('markup')
            updateProduct('markup')
          }}
        >
          {isLoading && loadingField === 'markup' ? (
            <Loading h="100%" w="100%" />
          ) : (
            <NumberInputField borderRadius="100px" p="0 16px" textAlign="center" placeholder='not set' _placeholder={{'fontSize': '12px' }}/>
          )}
        </NumberInput>
        {showDelete && (
          <>
            {isDeleting ? (
              <Box w={fieldWidth}>
                <Loading h="100%" w="100%" />
              </Box>
            ) : (
              <ConfirmPopover
                title={`This Action is Permanent`}
                subtitle={`Removing this color means it will no longer appear in your salon.`}
                onConfirm={() => {
                  dispatch(
                    dispatchDeleteProducts({
                      token: user.token,
                      salonId,
                      models: product ? [product] : [],
                      loadingName: deletingName,
                      releaseLaraColors: releaseColors,
                    }),
                  )
                }}
              >
                <Text fontSize="12px" w={fieldWidth} textAlign="center" cursor="pointer" color={COLORS.lavender_500}>
                  Delete
                </Text>
              </ConfirmPopover>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}

export const ProductAutoSaveFormTitleRow = ( props: { willOnlySetValueForOneProduct: boolean, showDelete: boolean } ) => {
  const { willOnlySetValueForOneProduct, showDelete = false } = props
  const { isMobile } = UseViewSize()
  const showTarget = UseShouldShowTarget()
  const showOnHand = UseShouldShowOnHand(willOnlySetValueForOneProduct)
  const fieldWidth = getFieldWidth(showTarget, showOnHand, showDelete)
  return (
    <Flex w="100%" align="center" wrap="wrap" gridGap="4px" justify="space-between">
      {showOnHand && (
        <Text fontSize={isMobile ? '9px' : '12px'} color={COLORS.text_secondary} w={fieldWidth} textAlign="center">
          on hand
        </Text>
      )}
      {showTarget && (
        <Text fontSize={isMobile ? '9px' : '12px'} color={COLORS.text_secondary} w={fieldWidth} textAlign="center">
          target
        </Text>
      )}
      <Text fontSize={isMobile ? '9px' : '12px'} color={COLORS.text_secondary} w={fieldWidth} textAlign="center">
        wholesale ($)
      </Text>
      <Text fontSize={isMobile ? '9px' : '12px'} color={COLORS.text_secondary} w={fieldWidth} textAlign="center">
        markup (%)
      </Text>
      {showDelete && <Text fontSize={isMobile ? '9px' : '12px'} color={COLORS.text_secondary} w={fieldWidth} />}
    </Flex>
  )
}

const getFieldWidth = ( showTarget: boolean, showOnHand: boolean, showDelete: boolean ): string => {
  const numColumns = 2 + (showTarget ? 1 : 0) + (showOnHand ? 1 : 0) + (showDelete ? 1 : 0)
  return `${(100 / numColumns) - 1}%`
}
