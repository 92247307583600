/////////////// mappers.ts
//
//

// todos: copy paste Labor and labor

import { map } from 'lodash'
import {
  APILaborItem,
  APILaborService,
  APILaborTier,
  LaborItem,
  LaborService,
  LaborTier,
  APIUserLaborTier,
  UserLaborTier, LaborServiceOverride, APILaborServiceOverride,
} from './interfaces'
import { centsToDollars } from '../../core/money/utils'
import { DEFAULT_CATEGORY_NAME } from "./constants";


export const mapAPILaborItemToLaborItem = (api: APILaborItem): LaborItem => {
  return {
    id: api.id,
    priceDollars: centsToDollars(api.price_cents), // convert price from cents to dollars
    tier: {id: api.tier.id, name: api.tier.name},
    service: {id: api.service.id, name: api.service.name, type: api.service.type, category: api.service.category},
  }
}

export const mapAPILaborItemsToLaborItems = (apiModels: APILaborItem[]): LaborItem[] => {
  return map(apiModels, mapAPILaborItemToLaborItem)
}

export const mapAPILaborTierToLaborTier = (api: APILaborTier): LaborTier => {
  return {
    id: api.id,
    name: api.name,
  }
}

export const mapAPILaborTiersToLaborTiers = (apiModels: APILaborTier[]): LaborTier[] => {
  return map(apiModels, mapAPILaborTierToLaborTier)
}

export const mapAPILaborServiceToLaborService = (api: APILaborService): LaborService => {
  return {
    id: api.id,
    name: api.name,
    type: api.type,
    category: api.category || DEFAULT_CATEGORY_NAME
  }
}

export const mapAPILaborServicesToLaborServices = (apiModels: APILaborService[]): LaborService[] => {
  return map(apiModels, mapAPILaborServiceToLaborService)
}

export const mapAPILaborServiceOverrideToLaborServiceOverride = (api: APILaborServiceOverride): LaborServiceOverride => {
  return {
    id: api.id,
    priceCents: api.price_cents,
    salonId: api.salon_id,
    userId: api.user_id,
    laborService: mapAPILaborServiceToLaborService(api.labor_service),
  }
}

export const mapAPILaborServiceOverridesToLaborServiceOverrides = (apiModels: APILaborServiceOverride[]): LaborServiceOverride[] => {
  return map(apiModels, mapAPILaborServiceOverrideToLaborServiceOverride)
}

export const mapAPIUserLaborTierToUserLaborTier = (api: APIUserLaborTier): UserLaborTier => {
  return {
    id: api.tier_id,
    userId: api.user_id,
    name: api.tier_name ? api.tier_name : '',
  }
}

export const mapAPIUserLaborTierToUserLaborTiers = (apiModels: APIUserLaborTier[]): UserLaborTier[] => {
  return map(apiModels, mapAPIUserLaborTierToUserLaborTier)
}

