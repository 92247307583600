import React, { useEffect, useState } from 'react'
import { dispatchListUsersMetaLara } from '../../data/user/api'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { selectUsersMeta } from '../../data/user/slice'
import { filterUsersMeta } from '../../data/user/utils'
import { UserMeta } from '../../data/user/interfaces'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Loading } from '../../mini-lib/loading/Loading'
import { Box } from '@chakra-ui/react'
import { SelectInput } from "../../mini-lib";
import { find } from "lodash";

export const UserAutocomplete = (props: {
  onSelect: (user: UserMeta | null) => void
  initialUserId?: number

  width?: string
  isClearable?: boolean
  disabled?: boolean
  label?: string
}) => {
  const dispatch = useDispatch()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const {
    initialUserId,
    onSelect,
    width = '350px',
    isClearable = true,
    disabled = false,
    label = 'Stylist'
  } = props

  const models = useAppSelector(selectUsersMeta)
  const modelsLoaded = !!models
  const [searchText, setSearchText] = useState('')

  const [selectedUserMeta, setSelectedUserMeta] = useState<UserMeta | null>(null)
  const filteredModels = models ? filterUsersMeta(models, searchText) : []

  const hasSelectedUserMeta = !!selectedUserMeta

  useEffect(() => {
    if (modelsLoaded && !hasSelectedUserMeta && initialUserId) {
      const initialStylistFromUserId: any = find(models, {id: initialUserId})
      setSelectedUserMeta(initialStylistFromUserId)
    }
    // eslint-disable-next-line
  }, [modelsLoaded, initialUserId, hasSelectedUserMeta])


  useEffect(() => {
    // only call once on initial load
    if (!modelsLoaded) {
      dispatch(dispatchListUsersMetaLara({token, salonId}))
    }
  }, [dispatch, token, salonId, modelsLoaded])

  const buildLabel = (member: UserMeta) => member.firstName + ' ' + member.lastName
  const dropdownOptions: { value: UserMeta; label: string }[] | null = models
    ? filteredModels.map((model) => {
      return { value: model, label: buildLabel(model) }
    })
    : null

  return (
    <Box w={width} maxW="100%">
      {disabled && (
        <Box w="100%" isTruncated={true}>
          {selectedUserMeta ? `${selectedUserMeta.firstName} ${selectedUserMeta.lastName}` : ''}
        </Box>
      )}
      {!disabled && dropdownOptions && (
        <SelectInput
          w='100%'
          variant='material'
          border='rounded'
          value={selectedUserMeta}
          placeholder="Search Clients"
          label={label}
          options={dropdownOptions}
          onInputChange={(searchText) => setSearchText(searchText)}
          onChange={(option: UserMeta) => {
            if (option) {
              setSelectedUserMeta(option)
              onSelect(option)
            } else {
              setSelectedUserMeta(null)
              onSelect(null)
            }
          }}
          allowFocus={true}
          isClearable={isClearable}
        />
      )}
      {!dropdownOptions && <Loading />}
    </Box>
  )
}