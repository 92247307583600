import {
  AdminColumn,
  AdminSubscriptionAnalyticsRow,
  AdminUserAnalyticsRow,
  INTERVAL_STRING_TO_NUMBER_MAP
} from "./interfaces";
import { floor, map, sortBy } from "lodash";
import { Button } from "@chakra-ui/react";
import { extractYYYYMMDDFromRawSQLDateString } from "../../core/dates";

export const buildAnalyticsKey = ( params: { startDateString: string, endDateString: string, interval: string } ) => {
  const { startDateString, endDateString, interval } = params
  return `${startDateString}-${endDateString}-${interval.replace(' ', '_')}`
}

export const buildUserAnalyticsKey = ( params: { startDateString: string, endDateString: string } ) => {
  const { startDateString, endDateString } = params
  return `${startDateString}-${endDateString}`
}

export const buildReadableDateRangeStringFromKey = ( key: string ): string => {
  const [startDateString, endDateString] = key.split('---')
  // const startDate = new Date(startDateString)
  // const endDate = new Date(endDateString)
  return `${startDateString} to ${endDateString}`

}
export const convertAnalyticsToRows = ( analytics: { [key: string]: any } ): AdminSubscriptionAnalyticsRow[] => {
  const rows: AdminSubscriptionAnalyticsRow[] = []
  map(analytics, ( values, dateRangeKey ) => {
    rows.push({
      date_range: buildReadableDateRangeStringFromKey(dateRangeKey),
      total_active_subscriptions: values['total_active_subscriptions'] || 0,
      total_solo_monthly_subscriptions: values['total_solo_monthly_subscriptions'] || 0,
      total_solo_yearly_subscriptions: values['total_solo_yearly_subscriptions'] || 0,
      total_team_monthly_subscriptions: values['total_team_monthly_subscriptions'] || 0,
      total_team_yearly_subscriptions: values['total_team_yearly_subscriptions'] || 0,
      total_new_subscriptions: values['total_new_subscriptions'] || 0,
      total_trial_conversions: values['total_trial_conversions'] || 0,
      total_subscription_churn: values['total_subscription_churn'] || 0,
      total_trial_churn: values['total_trial_churn'] || 0,
      total_salons_with_min_sessions: values['total_salons_with_min_sessions'] || 0,
      total_sessions: values['total_sessions'] || 0,
      new_solo_monthly_trials: values['new_solo_monthly_trials'] || 0,
      new_solo_yearly_subscriptions: values['new_solo_yearly_subscriptions'] || 0,
      new_solo_monthly_trial_conversions: values['new_solo_monthly_trial_conversions'] || 0,
      new_solo_monthly_trial_churn: values['new_solo_monthly_trial_churn'] || 0,
      total_existing_solo_monthly_churn: values['total_existing_solo_monthly_churn'] || 0,
      total_existing_solo_yearly_churn: values['total_existing_solo_yearly_churn'] || 0,
      new_team_monthly_trials: values['new_team_monthly_trials'] || 0,
      new_team_yearly_subscriptions: values['new_team_yearly_subscriptions'] || 0,
      new_team_monthly_trial_conversions: values['new_team_monthly_trial_conversions'] || 0,
      new_team_monthly_trial_churn: values['new_team_monthly_trial_churn'] || 0,
      total_existing_team_monthly_churn: values['total_existing_team_monthly_churn'] || 0,
      total_existing_team_yearly_churn: values['total_existing_team_yearly_churn'] || 0,

    })
  })

  return rows
}

export const convertToUserAnalyticsRows = (analytics: { [key: string]: any }): AdminUserAnalyticsRow[] => {
  const rows: AdminUserAnalyticsRow[] = []; // Corrected type here
  map(analytics, (values) => {
    rows.push({
      created_at: extractYYYYMMDDFromRawSQLDateString(values['created_at']),
      user_id: values['user_id'],
      salon_id: values['salon_id'],
      chargebee_customer_link: <a target='_blank' rel='noreferrer' href={`https://salonscale.chargebee.com/d/customers/${values['chargebee_customer_id']}`}><Button colorScheme='brand.midnight' variant='round-ghost'>view customer</Button></a>,
      chargebee_subscription_link: <a target='_blank' rel='noreferrer' href={`https://salonscale.chargebee.com/d/subscriptions/${values['chargebee_subscription_id']}`}><Button colorScheme='brand.midnight' variant='round-ghost'>view sub</Button></a>,
      admin_user_link: <a target='_blank' rel='noreferrer' href={`https://app.salonscale.com/admin/users/${values['user_id']}`}><Button colorScheme='brand.midnight' variant='round-ghost'>view user</Button></a>,
      admin_salon_link: <a target='_blank' rel='noreferrer' href={`https://app.salonscale.com/admin/salons/${values['salon_id']}/stylists`}><Button colorScheme='brand.midnight' variant='round-ghost'>view salon</Button></a>,
      first_name: values['first_name'],
      last_name: values['last_name'],
      email: values['email'],
      phone: values['phone'],
      status: values['status'],
      plan_id: values['plan_id'],
      plan_amount: values['plan_amount'],
      salon_name: values['salon_name'],
    });
  });

  return sortBy(rows, 'created_at');
}

export const calculateNumIntervals = ( startDate: Date, endDate: Date, interval: string ): number => {
  const intervalAsNumber = INTERVAL_STRING_TO_NUMBER_MAP[interval] || 0
  const intervalInMilliseconds = intervalAsNumber * 24 * 60 * 60 * 1000
  const start = startDate.getTime();
  const end = endDate.getTime();
  if (start >= end || intervalInMilliseconds <= 0) {
    return 0;
  }

  return floor(( end - start ) / intervalInMilliseconds);
}

export const filterSubscriptionAnalyticsColumns = ( columnData: AdminColumn[], columnFilters: { search: string }): AdminColumn[] => {
  if (columnFilters.search === '') {
    return columnData
  }
  return columnData.filter(data => {
    return data.key === 'date_range' || data.key.includes(columnFilters.search)
  });
}

export const filterSubscriptionAnalyticsRows = ( rows: AdminSubscriptionAnalyticsRow[], columnKeys: string[]): Partial<AdminSubscriptionAnalyticsRow>[] => {
  const filteredRows: Partial<AdminSubscriptionAnalyticsRow>[]  = []
  rows.forEach(row => {
    const filteredRow = {}
    columnKeys.forEach(columnKey => {
      filteredRow[columnKey] = row[columnKey]
    })
    filteredRows.push(filteredRow)
  })
  return filteredRows
}


export const filterUserAnalyticsColumns = ( columnData: AdminColumn[], columnFilters: { search: string }): AdminColumn[] => {
  if (columnFilters.search === '') {
    return columnData
  }
  return columnData.filter(data => {
    return data.key === 'date_range' || data.key.includes(columnFilters.search)
  });
}

export const filterUserAnalyticsRows = ( rows: AdminUserAnalyticsRow[], columnKeys: string[]): Partial<AdminUserAnalyticsRow>[] => {
  const filteredRows: Partial<AdminUserAnalyticsRow>[]  = []
  rows.forEach(row => {
    const filteredRow = {}
    columnKeys.forEach(columnKey => {
      filteredRow[columnKey] = row[columnKey]
    })
    filteredRows.push(filteredRow)
  })
  return filteredRows
}

export const getUserAnalyticsInitialStartEndDates = (): {initialStartDate: Date, initialEndDate: Date} => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();

  const initialStartDate = new Date(year, month,  day-1);
  const initialEndDate = new Date(year, month, day-1);
  return {initialStartDate, initialEndDate}
}
