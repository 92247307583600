import { Flex, Text } from '@chakra-ui/react'
import { ThemeType } from '../../theme'
import React from "react";
import { COLORS } from "../theme/colors";
import { Gap } from "../gap/Gap";

export const EmptyFilters = (props: {
  title: any,
  subtitle: any,
  actions?: any,
  theme?: ThemeType
}) => {
  const { theme, title, subtitle, actions} = props

  const themeImg = getThemeEmptyImg({theme})
  return (
  <Flex direction="column" justify="center" align="center">
    <Gap size='100px'/>
    <img alt="logo" style={{ height: '70px', maxWidth: '100%', padding: '4px' }} src={themeImg}/>
    <Gap/>
    <Text variant="title1" fontWeight="bold">
      {title}
    </Text>
    <Gap/>
    <Text textAlign="center" color={COLORS.text_secondary} maxW="100%" w="640px">
      {subtitle}
    </Text>
    <Gap/>
    {actions}
  </Flex>
  )
}

const getThemeEmptyImg = (params: {theme?: ThemeType}) => {
  const {theme = 'default'} = params
  return themeToImgPathMap[theme]
}

const themeToImgPathMap = {
  midnight: require('../../assets/icons/EmptyMidnight.svg').default,
  lavender: require('../../assets/icons/EmptyLavender.svg').default,
  peach: require('../../assets/icons/EmptyPeach.svg').default,
  skylight: require('../../assets/icons/EmptySkylight.svg').default,
  linen: require('../../assets/icons/EmptyLinen.svg').default,
  default: require('../../assets/icons/EmptyDefault.svg').default,
}
