import axios from 'axios'
import { Dispatch } from '@reduxjs/toolkit'

// to create another app go go - https://api.slack.com/apps
// slack apps are basically just adding a new channel and a new bot that sends messages to it
// eg: if we wanted a slack channel for 'user created first session' or something we could
//      1 go to https://api.slack.com/apps
//      2 create new app and get a token for it
//      3 generate a url for the app and add it here to our slackAppUrls (call it userFirsts or something)
//      call notifySlack with slackAppUrl=slackAppUrls.userFirsts

export const SlackAppUrls = {
  superdupernotifcations: 'https://hooks.slack.com/services/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',
  importantEvents: 'https://hooks.slack.com/services/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',
  ngerrors: 'https://hooks.slack.com/services/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',
}

export interface SlackProps {
  title: string
  subtitle: string
  content?: string
  slackAppUrl?: string
  color?: string
}

// apis
//
//
export const apiNotifySlack = (props: SlackProps): Promise<any> => {
  const { title, subtitle, content = '', slackAppUrl = SlackAppUrls.superdupernotifcations, color = '#cacae6' } = props

  const body = {
    text: title,
    // more attachment config options https://api.slack.com/messaging/composing/layouts#attachments
    attachments: [
      {
        color: color,
        title: subtitle,
        text: content,
      },
    ],
  }

  const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
  return axios
    .post(slackAppUrl, body, config)
    .then((response: any) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

// actions
//
//
export const dispatchNotifySlack = (props: SlackProps) => {
  return (dispatch: Dispatch) => {
    return apiNotifySlack(props).then(() => {})
  }
}
