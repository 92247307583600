import { getLocalStorageItem, setLocalStorageItem } from './core/localStorage'
import { useHistory, useLocation } from 'react-router-dom'

export const UserTransferFromAngular = (): {
  token?: string
  userId?: number
  theme?: 'light' | 'dark'
  env?: 'staging' | 'prod'
} => {
  // note: make sure the url param is user-id not user_id
  // try token query param
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  if (queryParams) {
    const qpt = queryParams.get('token')
    const env = queryParams.get('env')
    const quid = queryParams.get('user-id')
    const theme = queryParams.get('theme')
    const qpat = queryParams.get('auth-token')

    if (theme) {
      queryParams.delete('theme')
      setLocalStorageItem('theme', theme)
    }

    if (env) {
      setLocalStorageItem('env', env)
      queryParams.delete('env')
    }
    if (qpt && quid) {
      setLocalStorageItem('auth-token', qpt)
      setLocalStorageItem('user-id', quid)
      queryParams.delete('token')
      queryParams.delete('user-id')
      history.replace({
        search: queryParams.toString(),
      })
      return { token: qpt, userId: parseInt(quid) }
    }
    // try auth-token query param
    if (qpat && quid) {
      setLocalStorageItem('auth-token', qpat)
      setLocalStorageItem('user-id', quid)
      queryParams.delete('auth-token')
      queryParams.delete('user-id')
      history.replace({
        search: queryParams.toString(),
      })
      return { token: qpat, userId: parseInt(quid) }
    }
  }

  // try localstorage
  const lst = getLocalStorageItem('auth-token')
  const lsuid = getLocalStorageItem('user-id')
  if (lst && lsuid) {
    return { token: lst, userId: parseInt(lsuid) }
  }
  return {}
}
