import { useFlags } from "launchdarkly-react-client-sdk";
import { Scale } from "../../data/signup/interfaces";
import {BLACK_MINI_SCALE, LINEN_SCALE} from "../../data/signup/constants";
import { getCountryCode } from "../time/utils";

export const LinenScaleAvailable = () => {
  const { scaleLinenAvailable = false } = useFlags() || {}
  return scaleLinenAvailable
}

export const BlackV1ScaleAvailable = () => {
  const { scaleBlackV1Available = false } = useFlags() || {}
  return scaleBlackV1Available
}

export const BlackMiniScaleAvailable = () => {
  const { scaleBlackMiniAvailable = false } = useFlags() || {}
  return scaleBlackMiniAvailable
}

export const GetAvailableScales = (): Scale[] => {
  const availableScales = [BLACK_MINI_SCALE]
  const countryCode = getCountryCode();
  if (LinenScaleAvailable() && (countryCode === "US" || countryCode === 'CA')) {
    availableScales.push(LINEN_SCALE)
  }
  return availableScales
}
