import { Box, Button, Center, Input } from '@chakra-ui/react'
import { useState } from 'react'
import { InitializeGrin } from './service'

declare var Grin: any


export const GrinPage = () => {
  const [amount, setAmount] = useState('1')
  const [orderNumber, setOrderNumber] = useState('order number')
  InitializeGrin()
  const onGrin = () => {
    Grin.push(['conversion', amount, { order_number: orderNumber, name: 'mocked grin order', currency: 'USD' }])
  }
  return (
    <Center p="24px">
      <Box>
        <Box>Amount</Box>
        <Input value={amount} onChange={(e) => setAmount(e.target.value)} />
        <Box h="12px" />
        <Box>Order Number</Box>
        <Input value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} />
        <Box h="24px" />
        <Button variant="round" w="100%" onClick={onGrin}>
          Send Grin Conversion
        </Button>
      </Box>
    </Center>
  )
}
