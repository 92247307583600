import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { buildDeletingName, buildLoadingName } from '../../core/loading/utils'
import { useAppSelector } from '../../hooks'
import { selectLoadingState } from '../../core/loading/slice'
import { useEffect, useState } from 'react'
import { Box, Flex, NumberInput, NumberInputField, Text } from '@chakra-ui/react'
import { Loading } from '../../mini-lib/loading/Loading'
import { addDollarSign, addPercent, removeDollarSign, removePercent } from '../../mini-lib/utils/basic'
import { COLORS } from '../../mini-lib/theme/colors'
import { UseViewSize } from '../../core/UseViewSize'
import { APIUpdateExtension, Extension } from '../../data/extensions/interfaces'
import { calculateMarkupNumber } from '../../data/products/utils'
import { dispatchDeleteExtensions, dispatchUpdateExtensions } from '../../data/extensions/api'
import { ConfirmPopover } from "../../mini-lib/confirm-popover/ConfirmPopover";
import { ReleaseLaraColors } from "../../mini-lib/flags/Release";

export const ExtensionsAutoSaveForm = ( props: {
  extensions: Extension[];
  onSubmit?: () => void;
  showDelete?: boolean,
  onDelete?: () => void
} ) => {
  const dispatch = useDispatch()
  const {
    extensions,
    onSubmit = () => {},
    onDelete = () => {},
    showDelete = false
  } = props
  const { user, salonId } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  const releaseLaraColors = ReleaseLaraColors()
  const extension = extensions && extensions.length > 0 ? extensions[0] : null
  const loadingName = extension ? buildLoadingName(extension?.id) : 'loading'
  const deletingName = extension ? buildDeletingName(extension?.id) : 'deleting'
  const isLoading = useAppSelector(( state ) => selectLoadingState(state, loadingName))
  const isDeleting = useAppSelector(( state ) => selectLoadingState(state, deletingName))

  const defaultMarkup = user.currentSalonContext?.defaultExtensionMarkupPercentage || 0
  const calculatedMarkup = extension ? calculateMarkupNumber(extension) : null

  const extensionCost = extension?.inventory?.cost ? extension.inventory.cost.toFixed(2) : ''
  const extensionMarkup = calculatedMarkup !== null ? calculatedMarkup.toString() : defaultMarkup.toString()
  const [loadingField, setLoadingField] = useState<string>('')
  const [localCost, setLocalCost] = useState<string>(extensionCost)
  const [localMarkup, setLocalMarkup] = useState<string>(extensionMarkup)
  // when extension prices get set from bulk actions we want to override the current value to the latest extension value
  useEffect(() => {
    setLocalCost(extensionCost)
  }, [extensionCost])

  useEffect(() => {
    setLocalMarkup(extensionMarkup)
  }, [extensionMarkup])

  const updateExtension = () => {
    const normalizedCost = localCost ? parseFloat(localCost) : 0
    const normalizedMarkup = localMarkup ? parseFloat(localMarkup) : 0

    const costValid = normalizedCost >= 0
    const markupValid = normalizedMarkup >= 0

    if (extensions && extensions.length > 0 && costValid && markupValid) {
      const updatedExtensions: APIUpdateExtension[] = extensions.map(( extension ) => {
        const updatedExtension = {
          product_id: extension.id,
          wholesale_price: normalizedCost,
          mark_up: normalizedMarkup,
        }
        return updatedExtension
      })

      if (user) {
        const params = {
          token: user.token,
          user_id: user.userId,
          salon_id: salonId,
          models: updatedExtensions,
          loadingName: loadingName,
          releaseLaraColors
        }
        dispatch(dispatchUpdateExtensions(params))
        onSubmit()
      }
    }
  }
  const fieldWidth = getFieldWidth(showDelete, isMobile)
  return (
    <Flex align="center" wrap="wrap" gridGap="12px" w="100%">
      <Flex w="100%" gridGap="12px" justify="space-between" wrap={isMobile ? 'wrap' : 'nowrap'} align="center">
        <NumberInput
          w={fieldWidth}
          min={0}
          h="40px"
          onChange={( val ) => {
            setLocalCost(removeDollarSign(val))
          }}
          value={localCost ? addDollarSign(localCost) : ''}
          onBlur={() => {
            setLoadingField('cost')
            updateExtension()
          }}
        >
          {isLoading && loadingField === 'cost' ? (
            <Loading h="100%" w="100%"/>
          ) : (
            <NumberInputField borderRadius="100px" p="0 16px" textAlign="center"/>
          )}
        </NumberInput>
        <NumberInput
          w={fieldWidth}
          min={0}
          h="40px"
          onChange={( val ) => {
            setLocalMarkup(removePercent(val))
          }}
          value={addPercent(localMarkup)}
          onBlur={() => {
            setLoadingField('markup')
            updateExtension()
          }}
        >
          {isLoading && loadingField === 'markup' ? (
            <Loading h="100%" w="100%"/>
          ) : (
            <NumberInputField borderRadius="100px" p="0 16px" textAlign="center"/>
          )}
        </NumberInput>
        {showDelete && ( <>
          {isDeleting
            ? <Box w={fieldWidth}><Loading h="100%" w="100%"/></Box>
            : <ConfirmPopover
              title={`This Action is Permanent`}
              subtitle={`Deleted extensions will no longer appear in your salon.`}
              onConfirm={() => {
                onDelete()
                dispatch(dispatchDeleteExtensions({
                  token: user.token,
                  salonId,
                  models: extensions ? extensions : [],
                  loadingName: deletingName,
                  releaseLaraColors
                }))
              }}
            >
              <Text fontSize="12px" w={fieldWidth} textAlign="center" cursor='pointer' color={COLORS.lavender_500}>
                Delete
              </Text>
            </ConfirmPopover>
          }
        </> )
        }

      </Flex>
    </Flex>
  )
}

export const ExtensionAutoSaveFormTitleRow = (props: {showDelete?: boolean}) => {
  const {showDelete = false} = props
  const {isMobile} = UseViewSize()
  const fieldWidth = getFieldWidth(showDelete, isMobile)
  return (
    <Flex w="100%" align="center" wrap="wrap" gridGap="12px">
      <Text fontSize="12px" color={COLORS.text_secondary} w={fieldWidth} textAlign="center">
        wholesale ($)
      </Text>
      <Text fontSize="12px" color={COLORS.text_secondary} w={fieldWidth} textAlign="center">
        markup (%)
      </Text>
      <Box w={fieldWidth}/>
    </Flex>
  )
}

const getFieldWidth = ( showDelete: boolean, isMobile: boolean ) => {
  if (!isMobile && showDelete) {
    return '32%'
  }
  return '47%'
}
