import React, { useEffect } from 'react'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { SuppliesOnboard } from './SuppliesOnboard'
import { generatePath } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { HelpStepper, Step } from '../start-guide/common-components/HelpStepper'
import { COLORS } from '../../mini-lib/theme/colors'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { HelpStepperV2 } from '../start-guide-v2/HelpStepperV2'
import { ReleaseLaraSupplies, ReleaseStartGuideV2 } from '../../mini-lib/flags/Release'
import { dispatchListSupplies } from '../../data/supplies/api'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { UseViewSize } from '../../core/UseViewSize'
import { selectChecklistItemsByCode, selectStepsCompleted } from "../../data/start-guide/slice";
import { dispatchListUserChecklistItems } from "../../data/start-guide/api";
import { ROUTES } from "../../appRoutes";

export const SuppliesOnboardingPage = (props: {showBreadCrumbs?: boolean}) => {
  const {showBreadCrumbs = true} = props
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const {
    user,
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = showBreadCrumbs ? [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Add-Ons', url: generatePath(ROUTES.supplies, { salonId: salonId }) },
    { label: 'Onboarding' },
  ] : []
  const helpSteps: Step[] = [
    {
      title: 'Select Your Add-Ons',
      state: 'current',
      link: generatePath(ROUTES.supplyOnboard, { salonId }) + '?guide=supplies',
    },
    { title: 'Set Prices', state: 'incomplete', link: generatePath(ROUTES.supplies, { salonId }) + '?guide=supplies' },
  ]
  const releaseLaraSupplies = ReleaseLaraSupplies()

  useEffect(() => {
    dispatch(dispatchListSupplies({token: user.token, salonId, releaseLaraSupplies}))
  }, [dispatch, user.token, salonId, releaseLaraSupplies])

  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && user.userId) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, user.token, user.userId, checklistItemsLoaded])

  const releaseStartGuideV2 = ReleaseStartGuideV2()
  const stepCodes = [CHECKLIST_CODES.addAddons, CHECKLIST_CODES.addExtensions]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes)) || 0
  const previousStepLink = generatePath(ROUTES.sgAddAddonsIntro, { salonId })
  const nextStepLink = generatePath(ROUTES.sgAddons, { salonId }) + `?guide=${CHECKLIST_CODES.addAddons}`
  return (
    <PageLayout
      variant="full"
      top={
        <>
          {releaseStartGuideV2 ? (
            <HelpStepperV2
              title={isMobile ? 'Add Add-Ons' : 'Add Add-Ons to Your Back Bar'}
              titleIconName="clients"
              currentStepNumber={1}
              stepsComplete={stepsCompleted}
              totalSteps={stepCodes.length}
              previousStepLink={previousStepLink}
              nextStepLink={nextStepLink}
              guideType={CHECKLIST_CODES.addAddons}
            />
          ) : (
            <HelpStepper
              guideType={CHECKLIST_CODES.addAddons}
              title="Add Add-Ons to Your Back Bar"
              steps={helpSteps}
              bgHex={COLORS.linen_100}
              borderHex={COLORS.linen_500}
            />
          )}
        </>
      }
      header={<PageHeader title="Addon Setup" breadcrumbs={breadcrumbs} showHelp={false} />}
      content={<SuppliesOnboard />}
    />
  )
}
