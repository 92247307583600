import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { dispatchGetAdminUserAnalytics } from "../../data/admin-data/api";
import { useAppSelector } from "../../hooks";
import {
  reduceSetAdminAnalyticsEndDate,
  reduceSetAdminAnalyticsStartDate,
  selectAdminAnalyticsColumnFilters,
  selectAdminAnalyticsEndDate,
  selectAdminAnalyticsStartDate,
  selectAdminUserAnalyticsForStartEndInterval
} from "../../data/admin-data/slice";
import {
  convertToUserAnalyticsRows,
  filterUserAnalyticsColumns,
  filterUserAnalyticsRows,
  getUserAnalyticsInitialStartEndDates
} from "../../data/admin-data/utils";
import { AdminUserAnalyticsRow } from "../../data/admin-data/interfaces";

import { PageHeader } from "../../mini-lib/page-header/PageHeader";
import { DateInput } from "../../mini-lib/dates-and-times/DateInput";
import { UseViewSize } from "../../core/UseViewSize";
import { selectLoadingState } from "../../core/loading/slice";
import { COLORS } from "../../mini-lib/theme/colors";
import { Gap } from "../../mini-lib/gap/Gap";
import { buildCsv } from "../../mini-lib/csv/buildCsv";
import { ADMIN_ANALYTICS_LOADING, ADMIN_USER_ANALYTICS_COLUMN_DATA } from "../../data/admin-data/constants";
import { buildDateYYYYMMDD } from "../../core/dates";
import { EmptyFilters } from "../../mini-lib"

export const AdminUsersAnalytics = () => {
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()
  const startDate = useAppSelector(selectAdminAnalyticsStartDate)
  const endDate = useAppSelector(selectAdminAnalyticsEndDate)
  const analytics = useAppSelector(state => selectAdminUserAnalyticsForStartEndInterval(state, startDate, endDate))
  const loadingName = ADMIN_ANALYTICS_LOADING
  const isLoadingMetrics = useAppSelector(state => selectLoadingState(state, loadingName))
  const { initialStartDate, initialEndDate } = getUserAnalyticsInitialStartEndDates()
  useEffect(() => {
    dispatch(reduceSetAdminAnalyticsStartDate(initialStartDate))
    dispatch(reduceSetAdminAnalyticsEndDate(initialEndDate))
    dispatch(dispatchGetAdminUserAnalytics({
      loadingName,
      token: user.token,
      startDate: initialStartDate,
      endDate: initialEndDate
    }))
    // eslint-disable-next-line
  }, [dispatch, user.token, startDate.toISOString, endDate.toISOString])
  const rows = analytics ? convertToUserAnalyticsRows(analytics) : []
  return (
    <Box maxW="1200px" margin="auto">
      <PageHeader title="Admin User Analytics" breadcrumbs={[]}/>
      <Box h="48px"/>
      <AdminUserAnalyticsFilters rows={rows}/>
      <Box h="24px"/>
      <Gap/>
      {rows && <AdminUserAnalyticsTable rows={rows}/>}
      {isLoadingMetrics && <LoadingMetrics/>}
      {!isLoadingMetrics && rows && rows.length === 0 && (
        <EmptyFilters
          theme='midnight'
          title={`NO NEW SUBSCRIPTIONS FOUND BETWEEN ${buildDateYYYYMMDD(startDate)} and ${buildDateYYYYMMDD(endDate)}`}
          subtitle="might have been a slow day or something might be wrong"
        ></EmptyFilters>
      )}

    </Box>
  )
}
export const LoadingMetrics = () => {
  const [time, setTime] = useState(0)
  const textArray = [
    'processing request',
    'querying for users',
    'finding their salons',
    'finding their subscription',
  ]
  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(( prevTime ) => ( prevTime + 1 ) % textArray.length)
    }, 2000)
    return () => clearTimeout(timer)
  }, [textArray.length, time, setTime])


  return (
    <>
      <Gap s='48px'/>
      <Flex align='center' justify='center' gridGap='12px' color={COLORS.midnight_500}>
        <Spinner/>
        {textArray[time]}
      </Flex>
    </>
  )
}

export const AdminUserAnalyticsFilters = ( props: { rows: AdminUserAnalyticsRow[] | null } ) => {
  const { rows } = props
  const { isMobile } = UseViewSize()
  const dispatch = useDispatch()
  const { initialStartDate, initialEndDate } = getUserAnalyticsInitialStartEndDates()
  const columnFilters = useAppSelector(selectAdminAnalyticsColumnFilters)
  const [canGetNewData, setCanGetNewData] = useState(false)
  const [localStartDate, setLocalStartDate] = useState(initialStartDate)
  const [localEndDate, setLocalEndDate] = useState(initialEndDate)
  const { user } = UseBaseApiParams()
  const loadingName = ADMIN_ANALYTICS_LOADING
  const isLoadingMetrics = useAppSelector(state => selectLoadingState(state, loadingName))
  const onCsvExport = () => {
    const csvKeys = [
     'created_at',
     'user_id',
     'salon_id',
     'first_name',
     'last_name',
     'phone',
     'email',
     'status',
     'plan_id',
     'salon_name',
    ]
    const filteredColumnData = ADMIN_USER_ANALYTICS_COLUMN_DATA.filter(item => csvKeys.includes(item.key));
    const filteredColumns = filterUserAnalyticsColumns(filteredColumnData, columnFilters)
    const filteredColumnHeaders = filteredColumns.map(h => h.header)
    const filteredColumnKeys = filteredColumns.map(h => h.key)
    const filteredRows = rows ? filterUserAnalyticsRows(rows, filteredColumnKeys) : []
    buildCsv('admin-metrics', filteredColumnHeaders, filteredRows)
  }
  return (
    <Box>
      <Flex align="center" justify="flex-end" gridGap="12px">
        <Button variant='round-outline' colorScheme='brand.midnight' onClick={onCsvExport}>
          {isLoadingMetrics ? <Spinner/> : 'Export CSV'}
        </Button>
        <Button
          variant={canGetNewData ? 'round' : 'round-outline'}
          colorScheme='brand.midnight'
          onClick={() => {
            dispatch(reduceSetAdminAnalyticsStartDate(localStartDate))
            dispatch(reduceSetAdminAnalyticsEndDate(localEndDate))
            dispatch(dispatchGetAdminUserAnalytics({
              loadingName,
              token: user.token,
              startDate: localStartDate,
              endDate: localEndDate,
            }))
            setCanGetNewData(false)
          }}>
          {isLoadingMetrics ? <Spinner/> : 'Get New Analytics'}
        </Button>
      </Flex>
      <Gap/>
      <Flex align="center" justify="flex-end" gridGap="12px">
        Start:
        <DateInput
          w={isMobile ? '50%' : '140px'}
          value={localStartDate}
          onChange={( date ) => {
            setLocalStartDate(date)
            setCanGetNewData(true)
          }}
        />
        End:
        <DateInput
          w={isMobile ? '50%' : '140px'}
          value={localEndDate}
          onChange={( date ) => {
            setLocalEndDate(date)
            setCanGetNewData(true)
          }}
        />
      </Flex>
    </Box>
  )
}

export const AdminUserAnalyticsTable = ( props: { rows: AdminUserAnalyticsRow[] } ) => {
  const { rows } = props
  const columns = ADMIN_USER_ANALYTICS_COLUMN_DATA
  const columnFilters = useAppSelector(selectAdminAnalyticsColumnFilters)
  const filteredColumns = filterUserAnalyticsColumns(ADMIN_USER_ANALYTICS_COLUMN_DATA, columnFilters)
  return (
    <>
      {rows && rows.length > 0 && (
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                {columns.map(meta => {
                  return (
                    <Th key={meta.header} fontSize='12px'><Tooltip
                      label={meta.description}>{meta.header}</Tooltip></Th> )
                })}
              </Tr>
            </Thead>
            <Tbody>
              {rows.reverse().map(row => {
                return (

                  <Tr key={`${row.user_id}-${row.salon_id}`}>
                    {/* get the data that matches up with the header */}
                    {filteredColumns.map(meta => {
                      return ( <Td key={meta.key} fontSize='12px'>{row[meta.key]}</Td> )
                    })}
                  </Tr>
                )
              })}

            </Tbody>
            <Tfoot>
            </Tfoot>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
