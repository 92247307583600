import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'

import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { InventoryTabs, LINES_TABS } from '../InventoryTabs'
import { useAppSelector } from '../../../hooks'
import { reduceSetProductFilters, selectProductFilters, selectProductSnapshotList } from '../../../data/products/slice'
import { dispatchListProducts } from '../../../data/products/api'
import { InventorySnapshotList } from './InventorySnapshotList'
import { ProductFilters } from '../../products/filters/ProductFilters'
import { calculateInventoryCost, filterProducts } from '../../../data/products/utils'
import { Alert, Box, Flex, Text } from '@chakra-ui/react'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { EmptySearch } from '../../../mini-lib/empty/EmptySearch'
import { Loading } from '../../../mini-lib/loading/Loading'
import { Product } from '../../../data/products/interfaces'
import { COLORS } from '../../../mini-lib/theme/colors'
import { UseViewSize } from '../../../core/UseViewSize'
import { EMPTY_PRODUCT_FILTERS } from '../../../data/products/constants'
import { InventorySnapshotCSVButton } from './InventorySnapshotCSVButton'
import { selectActiveSalonContext } from "../../../data/salon/slice";
import { Gap } from "../../../mini-lib/gap/Gap";
import { dispatchLaraGetSalon } from "../../../data/salon/api";
import { selectChecklistItemsByCode } from "../../../data/start-guide/slice";
import { isColorAndTargetAndInventoryComplete } from '../../../data/inventory/utils'
import { CHECKLIST_CODES } from "../../../data/start-guide/constants";
import { ReleaseLaraColors, ReleasePageHelp, ReleaseStartGuideV2 } from "../../../mini-lib/flags/Release";
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { dispatchListUserChecklistItems } from "../../../data/start-guide/api";
import { ROUTES } from "../../../appRoutes";
import { HelpDrawer } from "../../start-guide/common-components/HelpDrawer";
import { VIDEO_MAP } from "../../../mini-lib/video/constants";

export const InventorySnapshotPage = () => {
  const dispatch = useDispatch()
  const {
    user,
    salonId,
  } = UseBaseApiParams()

  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Inventory', url: generatePath(ROUTES.inventorySnapshot, { salonId }) },
  ]
  const checklistItemsByCode: any = useAppSelector(selectChecklistItemsByCode)
  const salon = useAppSelector(selectActiveSalonContext)
  const colorAndTargetComplete = isColorAndTargetAndInventoryComplete({ salon, checklistItemsByCode })

  const products = useAppSelector(selectProductSnapshotList)
  const filters = useAppSelector(selectProductFilters)

  const filteredProducts = products ? filterProducts(products, filters) : null

  // reset filters on page load
  useEffect(() => {
    dispatch(reduceSetProductFilters(EMPTY_PRODUCT_FILTERS))
  }, [dispatch])

  const releasePageHelp = ReleasePageHelp()
  const releaseLaraColors = ReleaseLaraColors()
  useEffect(() => {
    dispatch(dispatchListProducts({ token: user.token, salonId, releaseLaraColors }))
  }, [dispatch, user.token, salonId, releaseLaraColors])

  useEffect(() => {
    dispatch(dispatchLaraGetSalon({ token: user.token, userId: user.userId, salonId }))
  }, [dispatch, user, salonId])

  return (
    <>
      {releasePageHelp && (
        <HelpDrawer
          video={VIDEO_MAP.inventoryIntroduction}
          title={`Let’s take a look inside Inventory`}
          content={`Watch a demo of how SalonScales inventory works. Your Snapshot give you an inside look at the cost of your inventory and amount of products. You have an overview of everything in your backbar and you can export it to a csv to view it on a spreadsheet. `}
        />
      )}
      <PageLayout
        top={<>
          <InventorySnapshotTotals products={products}/>
          {!colorAndTargetComplete && <><Gap/><InventoryAlert/></>}
        </>}
        variant="full"
        header={<PageHeader showHelp={true} title="Inventory" breadcrumbs={breadcrumbs}
                            actions={<InventorySnapshotCSVButton products={filteredProducts ?? []}/>}/>}
      >

        <InventoryTabs selectedTab={LINES_TABS.snapshot}/>

        <Box h="24px"/>
        <ProductFilters showLineFilter={false}/>
        <Box h="24px"/>
        {filteredProducts && filteredProducts.length > 0 && <InventorySnapshotList products={filteredProducts}/>}
        {products && products.length === 0 && (
          <EmptyBox h="200px" title="You dont have any products yet" content="please add some products"/>
        )}
        {products && products.length > 0 && filteredProducts?.length === 0 && (
          <EmptySearch variant="clear">No products matched your filters</EmptySearch>
        )}
        <Box h="24px"/>
        {!filteredProducts && <Loading/>}
      </PageLayout>
    </>
  )
}

export const InventorySnapshotTotals = ( props: { products: Product[] | null } ) => {
  const { products } = props
  const { isMobile, isDesktop } = UseViewSize()
  const totalInventoryCost = products ? calculateInventoryCost(products) : null
  return (
    <Flex
      bg={COLORS.lavender_300}
      w="100%"
      borderRadius="20px"
      align="center"
      p={isDesktop ? '36px' : '24px'}
      justify="space-between"
      wrap="wrap"
      gridGap="24px"
    >
      <Text
        color="white"
        variant="gigantictext"
        w={isMobile ? '100%' : '33%'}
        fontSize={isDesktop ? '50px' : '32px'}
        lineHeight="1"
      >
        Inventory Snapshot
      </Text>
      <Flex flex="1" justify="space-around" align="center">
        <Box>
          <Text variant="giganticsubtitle" fontWeight="bold" fontSize={isDesktop ? '48px' : '32px'}>
            {totalInventoryCost ? '$' + totalInventoryCost : ''}
          </Text>
          <Box h="8px"/>
          <Text variant="caption1" color="black" fontSize={isMobile ? '10px' : '11px'}>
            {products ? 'total inventory cost' : ''}
          </Text>
        </Box>

        <Box>
          <Text variant="giganticsubtitle" fontWeight="bold" fontSize={isDesktop ? '48px' : '32px'}>
            {products ? products.length : ''}
          </Text>
          <Box h="8px"/>
          <Text variant="caption1" color="black" fontSize={isMobile ? '10px' : '11px'}>
            {products ? 'total products' : ''}
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

const InventoryAlert = () => {
  const dispatch = useDispatch()
  const { salonId, user } = UseBaseApiParams()
  const salon = useAppSelector(selectActiveSalonContext)
  const releaseStartGuide = ReleaseStartGuideV2()
  const checklistItemsByCode = useAppSelector(selectChecklistItemsByCode)

  const checklistLoaded = !!checklistItemsByCode

  const noColors = checklistItemsByCode && !checklistItemsByCode[CHECKLIST_CODES.addLines]?.completed && salon && salon?.colorsCount === 0
  const noTargets = checklistItemsByCode && !checklistItemsByCode[CHECKLIST_CODES.inventorySetTargets]?.completed && salon && salon?.targetSetCount === 0
  const noCounts = checklistItemsByCode && !checklistItemsByCode[CHECKLIST_CODES.inventoryCompleteCount]?.completed && salon && salon?.inventoryCompletedCount === 0
  // const noOnHand = checklistItemsByCode && !checklistItemsByCode[CHECKLIST_CODES.inventorySetOnHand]?.completed // note: don't have salon.onHandSetCount data
  useEffect(() => {
    if (!checklistLoaded) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, checklistLoaded, user.token, user.userId])

  const colorGuideUrl = releaseStartGuide
    ? generatePath(ROUTES.sgAddLinesIntro, { salonId })
    : generatePath(ROUTES.startGuide, { salonId }) + `?step=${CHECKLIST_CODES.addLines}`

  const targetGuideUrl = releaseStartGuide
    ? generatePath(ROUTES.sgInventoryTargetsIntro, { salonId })
    : generatePath(ROUTES.startGuide, { salonId }) + `?step=${CHECKLIST_CODES.inventorySetTargets}`

  const addStockGuideUrl = releaseStartGuide
    ? generatePath(ROUTES.sgInventoryAddingStockIntro, { salonId })
    : generatePath(ROUTES.startGuide, { salonId }) + `?step=${CHECKLIST_CODES.inventoryAddingStock}`

  return (
    <>
      <Alert status="warning" bg={COLORS.linen_50} borderRadius="6px">
        <Box w='100%'>
          <Flex align="center" justify="space-between" w="100%">
            <Flex align="center" gridGap="8px">
              <MaterialIcon name='warning' colorhex={COLORS.linen_500}/>
              It looks like you have a couple things to do before trying use inventory features!
            </Flex>
          </Flex>
          <Gap s='12px'/>
          <Box ml='32px' color={COLORS.lavender_500}>
            {noColors && <Link to={colorGuideUrl}><Text>Set up your Products</Text></Link>}
            {noTargets && <Link to={targetGuideUrl}><Text>Set your Product Targets</Text></Link>}
            {noCounts && <Link to={addStockGuideUrl}><Text>View the Inventory Start Guide</Text></Link>}
          </Box>
        </Box>
      </Alert>
    </>
  )
}
