import { UseBaseApiParams } from "../../../core/UseBaseApiParams";
import React, { useState } from "react";
import { useAppSelector } from "../../../hooks";
import { selectChecklistItem } from "../../../data/start-guide/slice";
import { CHECKLIST_CODES } from "../../../data/start-guide/constants";
import { generatePath } from "react-router-dom";
import { sumBy } from "lodash";
import { Box } from "@chakra-ui/react";
import { COLORS } from "../../../mini-lib/theme/colors";
import { StepContent, StepHeader } from './SGIndexPage'
import { SalonTypes } from '../../../data/salon/interfaces'
import { ROUTES } from "../../../appRoutes";

export const TeamAndClientsSteps = (props: { isDefaultOpen: boolean }) => {
  const { isDefaultOpen } = props
  const { user, salonId } = UseBaseApiParams()
  const [expanded, setExpanded] = useState(isDefaultOpen)
  const addedTeam = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.addStylist))
  const addedClient = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.addClient))

  const canAddTeam = user?.currentSalonContext?.salonType === SalonTypes.salon
  const steps = [
    {
      label: 'Invite Your Team',
      complete: addedTeam.completed,
      link: generatePath(ROUTES.sgAddTeamIntro, { salonId }),
      isDisabled: !canAddTeam,
    },
    {
      label: 'Add Your Clients',
      complete: addedClient.completed,
      link: generatePath(ROUTES.sgAddClientsIntro, { salonId }),
    },
  ]

  const totalSteps = canAddTeam ? 2 : 1
  const stepsComplete = sumBy(steps, 'complete')

  return (
    <Box border={`1px solid ${COLORS.shades_neutral_300}`} borderRadius="15px">
      <StepHeader
        iconName="clients"
        label="Add Your Team & Clients"
        setExpanded={setExpanded}
        expanded={expanded}
        stepsComplete={stepsComplete}
        totalSteps={totalSteps}
      />
      {expanded && <StepContent steps={steps} />}
    </Box>
  )
}
