import React, { useState } from 'react'
import { Button, Tooltip } from '@chakra-ui/react'
import { reduceAddProductsToOrder, reduceAddSelectedOrderQuantitiesByProductId } from '../../data/orders/slice'
import { useDispatch } from 'react-redux'
import { mapProductsToOrderProducts } from '../../data/orders/mappers'
import { ProductSelectSheet } from '../products/products/ProductSelectSheet'
import { UseViewSize } from '../../core/UseViewSize'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { ReleaseInventoryOrders } from '../../mini-lib/flags/Release'

export const OrderAddIndividualProductsButton = () => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const [isOpen, setIsOpen] = useState(false)
  const params = UseQueryParams()
  const pageType = params.get('pageType')
  const isInventoryOrderPage = pageType === 'inventory'
  const releaseInventoryOrders = ReleaseInventoryOrders()
  return (
    <>
      {isOpen && (
        <ProductSelectSheet
          subtitleText="Select products to add to order"
          confirmButtonText="Add to order"
          show={isOpen}
          onHide={() => setIsOpen(false)}
          selectedProductIds={[]}
          onProductsSelected={(products) => {
            const usedProducts = mapProductsToOrderProducts(products, 'manual')
            const usedProductQuantities = usedProducts.map(product => {
              return {
                productId: parseInt(product.id),
                unitCost: product.estWholesalePrice,
                quantityToAdd: 1
              }
            })
            dispatch(reduceAddProductsToOrder(usedProducts))
            dispatch(reduceAddSelectedOrderQuantitiesByProductId(usedProductQuantities))
            setIsOpen(false)
          }}
        />
      )}
      <Tooltip label={isInventoryOrderPage && releaseInventoryOrders ? 'Create an order by picking products from a list' : ''}>
        <Button colorScheme="brand.midnight" variant="round-outline" onClick={() => setIsOpen(true)}>
          {isMobile ? '+ Manually' : 'Add Products Manually'}
        </Button>
      </Tooltip>
    </>
  )
}
