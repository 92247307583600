import { generatePath } from 'react-router-dom'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { LABOR_TABS } from '../common/LaborReportsConstants'
import { LaborReportsContainer } from './LaborReportsContainer'
import { LaborReportsTabs } from '../common/LaborReportsTabs'
import { ROUTES } from "../../../appRoutes";

export const LaborLaborReportsPage = () => {
  const {
    // user: { token },
    salonId,
  } = UseBaseApiParams()

  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Labour Reports', url: generatePath(ROUTES.laborLaborReports, { salonId }) },
  ]

  return (
    <>
      <PageLayout
        variant="full"
        header={<PageHeader title="Labour Reports" breadcrumbs={breadcrumbs} actions={<></>} />}
      >
        <LaborReportsTabs selectedTab={LABOR_TABS.laborReports} />
        <LaborReportsContainer />
      </PageLayout>
    </>
  )
}
