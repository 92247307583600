export function formatTime(time: number): string {
  if (time < 60 && time >= 1) {
    return Math.floor(time) + ' min'
  } else if (time >= 60) {
    if (time % 60 === 0) {
      return time / 60 + ' hr'
    }
    let hours = Math.floor(time / 60)
    let minutes = Math.floor(time % 60)
    return hours + ' hr : ' + minutes + ' min'
  } else {
    return time * 60 + ' sec'
  }
}
