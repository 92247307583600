import { Button, Flex } from '@chakra-ui/react'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../appRoutes'
import { FeatureNotAvailableButton } from '../trial-v1/FeatureNotAvailableInTrialModal'
import React from 'react'
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { UseIsInTrialV2 } from "../trial-v1/utils";

export const SGActionButtons = (props: {
  action: {label: string, url: string, onClick?: () => void},
  action2?: {label: string, url: string, onClick?: () => void},
}) => {
  const {
    action,
    action2,

  } = props
  const {salonId} = UseBaseApiParams()
  const isTrial = UseIsInTrialV2()
  return (
    <Flex gridGap="12px" mb="48px">
      <Link to={isTrial ? generatePath(ROUTES.trialIndex, { salonId }) : generatePath(ROUTES.sgIndex, { salonId })}>
        <Button variant="round-outline" colorScheme="brand.midnight">
          Go Back
        </Button>
      </Link>

      {action2 && (
        <Link to={action2.url}>
          <Button variant="round-outline" colorScheme="brand.midnight" isDisabled={isTrial}>
            {action2.label}
          </Button>
        </Link>
      )}

      {isTrial ? (
        <FeatureNotAvailableButton label={action.label} />
      ) : (
        <Link to={action.url}>
          <Button variant="round" colorScheme="brand.midnight" onClick={action.onClick}>
            {action.label}
          </Button>
        </Link>
      )}
    </Flex>
  )
}
