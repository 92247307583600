import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { PermissionSetting } from './interfaces'
import { mapPermissionSettingToPermissionRoleMap } from './mappers'
import { buildRolePermissionKey } from './utils'
import { orderBy } from 'lodash'

// state
//
interface PermissionSettingState {
  permissionSettings: PermissionSetting[] | null
  permissionRoleMap: { [key: string]: boolean } | null
}

const initialState: PermissionSettingState = {
  permissionSettings: null, // undefined is an accident, null is a choice
  permissionRoleMap: null,
}

// reducer
//
export const PermissionSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    reduceListPermissionSetting: (state, action: PayloadAction<PermissionSetting[]>) => {
      state.permissionSettings = action.payload
      state.permissionRoleMap = mapPermissionSettingToPermissionRoleMap(state.permissionSettings)
    },
    reduceUpdatePermissionSetting: (state, action: PayloadAction<PermissionSetting>) => {
      const updatedPermissions = state.permissionSettings?.map((permission) => {
        if (permission.id === action.payload.id) {
          return action.payload
        }
        return permission
      })
      state.permissionSettings = updatedPermissions ? updatedPermissions : null
      state.permissionRoleMap = updatedPermissions ? mapPermissionSettingToPermissionRoleMap(updatedPermissions) : null
    },
  },
})

// actions
//
export const { reduceListPermissionSetting, reduceUpdatePermissionSetting } = PermissionSlice.actions

// selectors
//

export const selectPermissionSettings = (state: RootState): PermissionSetting[] | null => {
  // the sorting on this is kind of hacky, the server is building the roles in the order we want these sorted
  // instead of manually sorting these i just decided to go by the order of the id which is the order we want
  return state.permissions.permissionSettings ? orderBy(state.permissions.permissionSettings, ['id'], ['asc']) : null
}

export const selectRolePermissionState = (state: RootState, id: number, role: string, permission: string): boolean => {
  const key = buildRolePermissionKey({ id, roleName: role, permissionName: permission })

  if (state.permissions.permissionRoleMap) {
    return !!state.permissions.permissionRoleMap[key]
  } else {
    return false
  }
}

// export
//
export default PermissionSlice.reducer
