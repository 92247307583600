import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectChecklistItem, selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems, dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { sgInventoryStepCodes } from './SGIndex/SGInventoryIndex'
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGInventoryIntroPage = () => {
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, sgInventoryStepCodes))
  const currentCode = CHECKLIST_CODES.inventoryIntroduction
  const currentStep = useAppSelector((state) => selectChecklistItem(state, currentCode))
  const maybeUpdateChecklist = () => {
    if (!currentStep.completed) {
      dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: currentCode}))
    }
  }
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="implementation"
          title="INTRODUCTION TO INVENTORY"
          description={descriptionText}
          video={VIDEO_MAP.inventoryIntroduction}
          actions={
            <SGActionButtons
              action={{
                label: 'Next',
                url: generatePath(ROUTES.sgInventoryTargetsIntro, { salonId }),
                onClick: maybeUpdateChecklist
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={sgInventoryStepCodes.length} stepsComplete={stepsCompleted || 0} />}
          imgPath={require('../../assets/start-guide/inventory.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `Ensure you and your team (if applicable) are confident using the SalonScale app to create sessions before you start Inventory. 

Choose a go live date for inventory, and inform your team of when it is scheduled. You must complete your first count ahead of your go live date. This is the day that the products used in a session will start deducting from your inventory.

SalonScale is a usage based inventory system! What does that mean? It means that while you are already weighing products used at your appointments, SalonScale is tracking those exact products to determine when it’s time for you to restock. 

Did you know that on average 40% of backbar products go unused?

Unlike other inventory management systems, SalonScale goes by the exact amount that is use. Plus with our new ordering feature, we easily track the in’s and out’s of your backbar. Bonus, you can manage it all right from your mobile device!

You can easily see how much of each product you have in stock, see your stock levels, update your stock in 1 click, and see the total dollar value of your backbar too.

`
