import React from 'react'
import { Button } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { ProductSelectSheet } from '../../products/products/ProductSelectSheet'
import { dispatchCreateInventoryCountItems } from '../../../data/inventory/api'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'

export const InventorySelectProductsButton = (props: {
  sideSheetIsOpen: boolean
  setSideSheetIsOpen: (isOpen: boolean) => void
  countId: number
}) => {
  const dispatch = useDispatch()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const { setSideSheetIsOpen, sideSheetIsOpen, countId } = props
  return (
    <>
      {sideSheetIsOpen && (
        <ProductSelectSheet
          subtitleText="Select products to add to inventory"
          confirmButtonText="Add to inventory"
          show={sideSheetIsOpen}
          onHide={() => setSideSheetIsOpen(false)}
          selectedProductIds={[]}
          onProductsSelected={(products) => {
            const items: { product_id: number }[] = products.map((p) => {
              return {
                product_id: p.id,
              }
            })
            dispatch(dispatchCreateInventoryCountItems({ token, countId, salonId, items }))
            setSideSheetIsOpen(false)
          }}
        />
      )}
      <Button colorScheme="brand.midnight" variant="round" onClick={() => setSideSheetIsOpen(true)}>
        Add Products
      </Button>
    </>
  )
}
