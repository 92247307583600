import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  NumberInput,
  NumberInputField,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'

import {
  reduceBulkProductSheetVisibility,
  reduceResetSelectedProductMap,
  reduceSetSelectedCategories,
  selectBulkProductSheetVisibility,
  selectSelectedProductsById,
} from '../../../data/products/slice'
import { APIUpdateProduct, Product } from '../../../data/products/interfaces'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'
import { values } from 'lodash'
import { dispatchUpdateProducts } from '../../../data/products/api'
import { COLORS } from '../../../mini-lib/theme/colors'
import { Loading } from '../../../mini-lib/loading/Loading'
import { selectLoadingState } from '../../../core/loading/slice'
import { Gap } from '../../../mini-lib/gap/Gap'
import { reduceSetStepCompleteDrawerState, selectStepCompleteDrawerState } from "../../../data/start-guide/slice";
import { UseQueryParams } from "../../../mini-lib/utils/basic";

export const ProductBulkUpdateSheet = (props: { propertyToUpdate: 'targets' | 'on hand', checklistStepComplete: boolean }) => {
  const dispatch = useDispatch()
  const { propertyToUpdate, checklistStepComplete } = props
  const selectedProductsById = useAppSelector(selectSelectedProductsById)
  const selectedProducts = selectedProductsById ? values(selectedProductsById) : null
  const showSheet = useAppSelector(selectBulkProductSheetVisibility)
  const [updated, setUpdated] = useState(false)
  const drawerState = useAppSelector(selectStepCompleteDrawerState)
  const queryParams: any = UseQueryParams()
  const isGuide = !!queryParams.get('guide')

  const onDrawerClose = () => {
    dispatch(reduceResetSelectedProductMap())
    dispatch(reduceSetSelectedCategories([]))
    dispatch(reduceBulkProductSheetVisibility(false))
    if (isGuide && updated && drawerState !== 'button' && !checklistStepComplete) {
      dispatch(reduceSetStepCompleteDrawerState('drawer'))
    }
  }
  return (
    <>
      {showSheet && selectedProducts && (
        <Drawer
          isOpen={!!selectedProducts}
          placement="right"
          onClose={onDrawerClose}
        >
          <DrawerOverlay />
          <DrawerCloseButton />
          <DrawerContent>
            <DrawerHeader>
              <DisableAutofocus />
              <Flex justify="space-between">
                <Text variant="largetitle" textTransform="uppercase">
                  Set Product {propertyToUpdate}
                </Text>
              </Flex>
              <Gap s="12px" />
              <Text variant="body" fontWeight="normal" color={COLORS.text_secondary}>
                setting {propertyToUpdate} for {selectedProducts.length} products
              </Text>
            </DrawerHeader>

            <DrawerBody>
              <Box h="24px" />
              <Flex justify="center">
                {propertyToUpdate === 'targets' && <TargetInput selectedProducts={selectedProducts} onUpdate={() => setUpdated(true)}/>}
                {propertyToUpdate === 'on hand' && <OnHandInput selectedProducts={selectedProducts} onUpdate={() => setUpdated(true)}/>}
              </Flex>
            </DrawerBody>
            <DrawerFooter>
              <Flex gridGap="12px" justify="flex-end" mt="24px" w="100%">
                <Button
                  variant="round-outline"
                  colorScheme="brand.lavender"
                  onClick={onDrawerClose}
                >
                  done
                </Button>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}

const TargetInput = (props: {selectedProducts: Product[], onUpdate: () => void}) => {
  const {selectedProducts, onUpdate} = props
  const { user, salonId } = UseBaseApiParams()
  const dispatch = useDispatch()
  const loadingName = 'bulk-property-update'
  const isLoading = useAppSelector((state) => selectLoadingState(state, loadingName))
  const [localProperty, setLocalProperty] = useState('')
  const updateProduct = () => {

    if (localProperty !== '') {
      const updatedProducts: APIUpdateProduct[] = selectedProducts.map((p) => {
        return {
          product_id: p.id,
          max_stock_level: parseFloat(localProperty),
        }
      })

      if (user) {
        const params = {
          token: user.token,
          user_id: user.userId,
          salon_id: salonId,
          models: updatedProducts,
          loadingName,
        }
        dispatch(dispatchUpdateProducts(params))
        onUpdate()
      }
    }
  }
  return (
    <NumberInput
      maxW='200px'
      min={0}
      onChange={(x) => {
        setLocalProperty(x)
      }}
      value={localProperty}
      onBlur={() => {
        updateProduct()
      }}
    >
      {isLoading ? (
        <Loading h="100%" w="100%" />
      ) : (
        <NumberInputField borderRadius="100px" p="0 16px" textAlign="center" />
      )}
    </NumberInput>
  )
}

const OnHandInput = (props: {selectedProducts: Product[], onUpdate: () => void}) => {
  const {selectedProducts, onUpdate} = props
  const { user, salonId } = UseBaseApiParams()
  const dispatch = useDispatch()
  const loadingName = 'bulk-property-update'
  const isLoading = useAppSelector((state) => selectLoadingState(state, loadingName))
  const [localProperty, setLocalProperty] = useState('')
  const updateProduct = () => {
    const normalizedProperty = localProperty ? parseFloat(localProperty) : 0
    const propertyValid = normalizedProperty >= 0

    if (propertyValid) {
      const updatedProducts: APIUpdateProduct[] = selectedProducts.map((p) => {
        return {
          product_id: p.id,
          amount_grams_in_stock: Math.round(normalizedProperty * p.size)
        }
      })

      if (user) {
        const params = {
          token: user.token,
          user_id: user.userId,
          salon_id: salonId,
          models: updatedProducts,
          loadingName,
        }
        dispatch(dispatchUpdateProducts(params))
        onUpdate()
      }
    }
  }
  return (
    <NumberInput
      maxW='200px'
      min={0}
      onChange={(x) => {
        setLocalProperty(x)
      }}
      value={localProperty}
      onBlur={() => {
        updateProduct()
      }}
    >
      {isLoading ? (
        <Loading h="100%" w="100%" />
      ) : (
        <NumberInputField borderRadius="100px" p="0 16px" textAlign="center" />
      )}
    </NumberInput>
  )
}
