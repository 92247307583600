import React from 'react'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { Box, Button, Divider, Flex, Img, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { SettingsSideSheet } from './SettingsSideSheet'
import { useDispatch } from 'react-redux'
import { setSettingsSheetType } from './settingsSlice'
import { UseViewSize } from '../../core/UseViewSize'
import { manageChargebeeSubscription } from '../../integrations/chargebee/service'
import { generatePath } from 'react-router-dom'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { PermissionSettings } from './PermissionSettings'
import { SalonTypes } from '../../data/salon/interfaces'
import { UseSignOut } from '../../core/hooks/UseSignOut'
import { COLORS } from '../../mini-lib/theme/colors'
import { CancellationAndRefundPolicies } from "../signup/v1/CheckoutPage";
import { ROUTES } from "../../appRoutes";

export const TabIndexMap = {
  user: 0,
  salon: 1,
  subscription: 2,
}

export function SettingsPage(props: {}) {
  const { salonId, user } = UseBaseApiParams()
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Settings' }]
  const { isMobile } = UseViewSize()
  const queryParams: any = UseQueryParams()
  const tab = queryParams.get('tab')
  const initialTabIndex = tab ? TabIndexMap[tab] : 0
  const [tabIndex, setTabIndex] = React.useState(initialTabIndex)
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Account Settings" breadcrumbs={breadcrumbs} />}
      content={
        <>
          <SettingsSideSheet />
          <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)} colorScheme="brand.lavender">
            <TabList overflowX="auto" padding="4px">
              <Tab minW={isMobile ? '60px' : '150px'} className="cy-user-tab">
                User
              </Tab>
              <Tab minW={isMobile ? '60px' : '150px'} className="cy-salon-tab">
                Salon
              </Tab>
              <Tab minW={isMobile ? '80px' : '150px'} className="cy-subscriptions-tab">
                Subscription
              </Tab>
              {user?.currentSalonContext?.salonType === SalonTypes.salon && (
                <Tab minW={isMobile ? '80px' : '150px'} className="">
                  Permissions
                </Tab>
              )}
            </TabList>

            <TabPanels>
              <TabPanel maxW="600px">
                <UserSettings />
              </TabPanel>
              <TabPanel maxW="600px">
                <SalonSettings />
              </TabPanel>
              <TabPanel maxW="600px">
                <SubscriptionSettings />
              </TabPanel>
              <TabPanel>
                <PermissionSettings />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      }
    />
  )
}

export const UserSettings = (props: {}) => {
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()
  return (
    <Box>
      {/* profile pic */}
      <Box h="24px" />
      <Text color="shades.neutral.500">Profile Picture</Text>
      <Box h="12px" />
      <Flex justify="space-between" align="center">
        <Img h="64px" w="64px" src={user?.photoUrl} borderRadius="50%" bg={COLORS.lavender_50} />
        <Button variant="round-outline" onClick={() => dispatch(setSettingsSheetType('photo'))}>
          Edit
        </Button>
      </Flex>
      <Box h="24px" />

      <Divider />

      {/* name */}
      <Box h="24px" />
      <Text color="shades.neutral.500">Name</Text>
      <Box h="12px" />
      <Flex justify="space-between" align="center">
        <Text>
          {user.firstName} {user.lastName}
        </Text>
        <Button variant="round-outline" onClick={() => dispatch(setSettingsSheetType('name'))}>
          Edit
        </Button>
      </Flex>
      <Box h="24px" />

      <Divider />

      {/* email */}
      <Box h="24px" />
      <Text color="shades.neutral.500">Email</Text>
      <Flex justify="space-between" align="center">
        <Text>{user.email}</Text>
        <Button variant="round-outline" onClick={() => dispatch(setSettingsSheetType('email'))}>
          Edit
        </Button>
      </Flex>
      <Box h="12px" />
      <Flex align="center">
        <MaterialIcon name="error_outline" colorhex="red" mr="8px" />
        <Text variant="footnote" color="shades.neutral.500">
          This will not change your billing email
        </Text>
      </Flex>
      <Box h="24px" />
      <Divider />
      <Box h="24px" />
      <Flex justify="center">
        <Button colorScheme="brand.midnight" variant="round" w="200px" onClick={UseSignOut}>
          Sign Out
        </Button>
      </Flex>
    </Box>
  )
}

export const SalonSettings = (props: {}) => {
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()
  return (
    <Box>
      {/* name */}
      <Box h="24px" />
      <Text color="shades.neutral.500">Name</Text>
      <Flex justify="space-between" align="center">
        <Text>{user.currentSalonContext?.salonName}</Text>
        <Button variant="round-outline" onClick={() => dispatch(setSettingsSheetType('salon-name'))}>
          Edit
        </Button>
      </Flex>
      <Box h="12px" />
      <Flex align="center">
        <MaterialIcon name="error_outline" colorhex="red" mr="8px" />
        <Text variant="footnote" color="shades.neutral.500">
          Manage your billing address in the payment portal
        </Text>
      </Flex>
      <Box h="24px" />
      <Text color="shades.neutral.500">Timezone</Text>
      <Flex justify="space-between" align="center">
        <Text>{user.currentSalonContext?.timezone || 'no timezone set'}</Text>
        <Button variant="round-outline" onClick={() => dispatch(setSettingsSheetType('salon-timezone'))}>
          Edit
        </Button>
      </Flex>
      <Box h="24px" />
      <Divider />
    </Box>
  )
}

export const SubscriptionSettings = (props: {}) => {
  const { user } = UseBaseApiParams()
  return (
    <Box>
      {/* salon plan */}
      <Box h="24px" />
      <Text color="shades.neutral.500">Plan</Text>
      <Flex justify="space-between" align="center">
        <Text>{user.currentSalonContext?.salonType === SalonTypes.independent ? 'Solo Plan' : 'Team Plan'}</Text>
        <Button variant="round-outline" onClick={() => manageChargebeeSubscription()}>
          Manage Subscription
        </Button>
      </Flex>
      <Box h="24px" />

      <Divider />

      {/* addons */}
      <Box h="24px" />
      <Text color="shades.neutral.500">Add-Ons</Text>
      <Flex justify="space-between" align="center">
        <Text>Purchase a scale</Text>
        <a target="_blank" href="https://salonscale.com/bluetooth-scale/" rel="noreferrer">
          <Button variant="round-outline">SalonScale Scale</Button>
        </a>
      </Flex>
      <Box h="24px" />
      <Flex justify="end" align="center">
        <a
          target="_blank"
          href="https://eightouncecoffee.com/collections/acaia-scales/products/acaia-pearl-coffee-scale-black?rfsn=7136873.0ee803&utm_source=refersion&utm_medium=affiliate&utm_campaign=7136873.0ee803"
          rel="noreferrer"
        >
          <Button variant="round-outline">Acaia Pearl Scale</Button>
        </a>
      </Flex>
      <Box h="24px" />
      <Divider />
      <Box h="24px" />
      <Flex justify='end'>
        <CancellationAndRefundPolicies/>
      </Flex>
    </Box>
  )
}
