import React from 'react'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { useAppSelector } from '../../hooks'
import { selectLoggedInUser } from '../../data/user/slice'
import { Loading } from '../../mini-lib/loading/Loading'
import { Button, Flex, Text } from '@chakra-ui/react'
import { generatePath, Link } from 'react-router-dom'
import { ColorForMode } from '../../theme'
import { BORDER_STYLE_STRING, ROUNDED_ALL_STYLES } from '../../styles'
import { ROUTES } from "../../appRoutes";

export function UserDashboardPage() {
  const user = useAppSelector(selectLoggedInUser)
  const breadcrumbs = [{ label: 'Dashboard' }]
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Your Salons" breadcrumbs={breadcrumbs} />}
      content={
        <>
          {user ? (
            <Flex gridGap="12px" m="24px 0">
              {/*<NewSalonCard />*/}
              {user.salonContexts.map((salonContext) => {
                return <SalonCard key={salonContext.salonId} salonInfo={salonContext} />
              })}
            </Flex>
          ) : (
            <Loading />
          )}
        </>
      }
    />
  )
}

// this will be added back in a future iteration, currently we have no flow for users creating a second salon/sub
export const NewSalonCard = () => {
  return (
    <Flex
      border={BORDER_STYLE_STRING}
      direction="column"
      align="center"
      p="24px"
      w="220px"
      bg={ColorForMode('card-bg')}
      style={ROUNDED_ALL_STYLES}
    >
      <Text fontWeight="bold" fontSize="l">
        New Salon
      </Text>
      <Link to={generatePath(ROUTES.salonSignup)}>
        <Button variant="ghost" colorScheme="brand.lavender">
          Create New
        </Button>
      </Link>
    </Flex>
  )
}

const SalonCard = (props: { salonInfo: { salonId: number; salonName: string } }) => {
  const { salonInfo } = props
  return (
    <Flex
      border={BORDER_STYLE_STRING}
      direction="column"
      align="center"
      p="24px"
      w="220px"
      bg={ColorForMode('card-bg')}
      style={ROUNDED_ALL_STYLES}
    >
      <Text fontWeight="bold" fontSize="l">
        {salonInfo.salonName}
      </Text>
      <a href={generatePath(ROUTES.sessions, { salonId: salonInfo.salonId })}>
        <Button variant="ghost" colorScheme="brand.lavender">
          View Salon
        </Button>
      </a>
    </Flex>
  )
}
