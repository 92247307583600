import { UseQueryParams } from '../mini-lib/utils/basic'

// example url: http://localhost:4201/signup-create-user?dev=true&utm_term=term_content&utm_source=source_content&utm_medium=medium_content&utm_content=content_content&utm_campaign=campaign_content
export const UTM_KEYS = {
  term: 'utm_term',
  source: 'utm_source',
  medium: 'utm_medium',
  content: 'utm_content',
  campaign: 'utm_campaign',
}

export interface UtmParams {
  term: string
  source: string
  medium: string
  content: string
  campaign: string
}

export const UseUtmQueryParams = () => {
  const queryParams = UseQueryParams()
  const term = queryParams.get(UTM_KEYS.term)
  const source = queryParams.get(UTM_KEYS.source)
  const medium = queryParams.get(UTM_KEYS.medium)
  const content = queryParams.get(UTM_KEYS.content)
  const campaign = queryParams.get(UTM_KEYS.campaign)
  return { term, source, medium, content, campaign }
}

export const buildUtmQueryParamString = (params: UtmParams): string => {
  const { term, source, medium, content, campaign } = params
  let qp = ''
  if (term) {
    qp += `${UTM_KEYS.term}=${term}&`
  }
  if (source) {
    qp += `${UTM_KEYS.source}=${source}&`
  }
  if (medium) {
    qp += `${UTM_KEYS.medium}=${medium}&`
  }
  if (content) {
    qp += `${UTM_KEYS.content}=${content}&`
  }
  if (campaign) {
    qp += `${UTM_KEYS.campaign}=${campaign}&`
  }
  return qp
}
