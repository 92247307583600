import { useEffect } from "react";
import { reduceSetCurrentSubStepIndex, reduceSetStepCompleteDrawerState } from "../../../data/start-guide/slice";
import { useDispatch } from "react-redux";

// this will run once on page load and will reset the start guide shared state
export const UseResetStartGuideState = (): void => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(reduceSetStepCompleteDrawerState('hidden'))
    dispatch(reduceSetCurrentSubStepIndex(0))
  }, [dispatch])
}
