import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import { selectChecklistItem } from '../../../data/start-guide/slice'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { generatePath } from 'react-router-dom'
import { sumBy } from 'lodash'
import { Box } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { StepContent, StepHeader } from './SGIndexPage'
import { ROUTES } from "../../../appRoutes";

export const ExtrasSteps = (props: { isDefaultOpen: boolean }) => {
  const { isDefaultOpen } = props
  const { salonId } = UseBaseApiParams()
  const [expanded, setExpanded] = useState(isDefaultOpen)

  const addons = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.addAddons))
  const extensions = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.addExtensionsPricing))

  const steps = [
    {
      label: 'Add & Price Add-ons',
      complete: addons.completed,
      link: generatePath(ROUTES.sgAddAddonsIntro, { salonId }),
    },
    {
      label: 'Add & Price Extensions',
      complete: extensions.completed,
      link: generatePath(ROUTES.sgAddExtensionsIntro, { salonId }),
    },
  ]

  const totalSteps = steps.length
  const stepsComplete = sumBy(steps, 'complete')

  return (
    <Box border={`1px solid ${COLORS.shades_neutral_300}`} borderRadius="15px">
      <StepHeader
        iconName="products"
        label="Back Bar Extras"
        setExpanded={setExpanded}
        expanded={expanded}
        stepsComplete={stepsComplete}
        totalSteps={totalSteps}
      />
      {expanded && <StepContent steps={steps} />}
    </Box>
  )
}

export const sgExtrasStepCodes = [CHECKLIST_CODES.addAddons, CHECKLIST_CODES.addExtensions]
