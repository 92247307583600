import { Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'
import { GetServerBaseUrl } from '../../env'
import { buildLaraConfig } from '../../mini-lib/lara/lara-utils'
import { mapAPINotificationsToUserNotifications, mapAPINotificationsToUserNotification } from './mappers'
import { reduceUpdateNotificationForUser, reduceListNotificationsForUser, reduceDeleteNotificationForUser } from './slice'
import { NOTIFICATIONS_LOADING_CONSTANT } from './constants'
import { reduceSetLoadingState } from '../../core/loading/slice'

import { APINotificationForUser, NotificationForUser } from './interfaces'


// --- user ---

// api
const apiListNotificationsForUser = ({
  token,
  salonId
}: {
  token: string,
  salonId: number
}): Promise<NotificationForUser[]> => {
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/users/announcements`
  return axios
    .get(url, config)
    .then((response: { data: { data: APINotificationForUser[] } }) => {
      return mapAPINotificationsToUserNotifications(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

const apiMarkAsReadtNotificationForUser = ({
  token,
  salonId,
  notificationId,
  read
}: {
  token: string,
  salonId: number,
  notificationId: number,
  read: boolean
}): Promise<NotificationForUser> => {
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/users/announcements/${notificationId}`;
  const body = {
    token,
    read,
  }
  return axios
    .patch(url, body, config)
    .then((response: { data: { data: APINotificationForUser } }) => {
      return mapAPINotificationsToUserNotification(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

const apiNotificationsBulkStatusUpdateForUser = ({
  token,
  salonId,
  model
}: {
  token: string,
  salonId: number,
  model: { id: number[], status: 'viewed' | 'read' }
}): Promise<NotificationForUser[]> => {
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/users/announcements`;
  const body = { ...model, token }
  return axios
    .patch(url, body, config)
    .then((response: { data: { data: APINotificationForUser[] } }) => {
      return mapAPINotificationsToUserNotifications(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

const apiDeletetNotificationForUser = ({
  token,
  salonId,
  notificationId
}: {
  token: string,
  salonId: number,
  notificationId: number,
}): Promise<any> => {
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/users/announcements/${notificationId}`;
  return axios
    .delete(url, config)
    .then((response: any) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

// dispatch
export const dispatchListNotificationsForUser = ({
  token,
  salonId
}: {
  token: string,
  salonId: number
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: NOTIFICATIONS_LOADING_CONSTANT, state: true }))
    return apiListNotificationsForUser({ token, salonId }).then((resp) => {
      dispatch(reduceListNotificationsForUser(resp))
      dispatch(reduceSetLoadingState({ name: NOTIFICATIONS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchMarkAsReadNotificationForUser = ({
  token,
  salonId,
  notificationId,
  read
}: {
  token: string,
  salonId: number,
  notificationId: number,
  read: boolean
}) => {
  return (dispatch: Dispatch) => {
    return apiMarkAsReadtNotificationForUser({ token, salonId, notificationId, read }).then((resp) => {
      dispatch(reduceUpdateNotificationForUser(resp))
    })
  }
}

export const dispatchDeleteNotificationForUser = ({
  token,
  salonId,
  notificationId,
}: {
  token: string,
  salonId: number,
  notificationId: number
}) => {
  return (dispatch: any) => {
    return apiDeletetNotificationForUser({ token, salonId, notificationId }).then((resp) => {
      dispatch(reduceDeleteNotificationForUser(notificationId))
    })
  }
}

export const dispatchMarkAllAsReadNotificationForUser = ({
  token,
  salonId,
  model
}: {
  token: string,
  salonId: number
  model: { id: number[], status: 'viewed' | 'read' }
}) => {
  return (dispatch: any) => {
    dispatch(reduceSetLoadingState({ name: NOTIFICATIONS_LOADING_CONSTANT, state: true }))
    return apiNotificationsBulkStatusUpdateForUser({ token, salonId, model }).then((resp) => {
      dispatch(reduceListNotificationsForUser(resp))
      dispatch(reduceSetLoadingState({ name: NOTIFICATIONS_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchNotificationsBulkStatusUpdateForUser = ({
  token,
  salonId,
  model
}: {
  token: string,
  salonId: number,
  model: { id: number[], status: 'viewed' | 'read' }
}) => {
  return (dispatch: any) => {
    dispatch(reduceSetLoadingState({ name: NOTIFICATIONS_LOADING_CONSTANT, state: true }))
    return apiNotificationsBulkStatusUpdateForUser({ token, salonId, model }).then((resp) => {
      dispatch(reduceListNotificationsForUser(resp))
    }).catch().finally(()=>{
      dispatch(reduceSetLoadingState({ name: NOTIFICATIONS_LOADING_CONSTANT, state: false }))
    })
  }
}
