import { Accordion, AccordionItem, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { StepGroupAccordionHeader } from '../common-components/StepGroupAccordionHeader'
import { StepGroupAccordionPanel } from '../common-components/StepGroupAccordionPanel'
import { StepAccordionHeader } from '../common-components/StepAccordionHeader'
import { StepAccordionPanel } from '../common-components/StepAccordionPanel'
import {
  reduceSetCurrentStepCode,
  selectChecklistItem,
  selectCurrentStepCode,
  selectStepsCompleted,
} from '../../../data/start-guide/slice'
import { CHECKLIST_CODES, CHECKLIST_GROUP_INDEXES, CHECKLIST_GROUPS } from '../../../data/start-guide/constants'
import { useAppSelector } from '../../../hooks'
import { COLORS } from '../../../mini-lib/theme/colors'
import { generatePath, Link } from 'react-router-dom'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { findIndex } from 'lodash'
import { dispatchUpdateUserChecklistItem } from '../../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { VIDEO_MAP } from '../../../mini-lib/video/constants'
import { SalonTypes } from '../../../data/salon/interfaces'
import { getAccordionIndexFromStepCode } from '../../../data/start-guide/utils'
import { UserAction, VideoAction } from '../common-components/actions'
import { ReactNode } from 'react'
import { ROUTES } from "../../../appRoutes";

export const ChecklistGroup2 = () => {
  const stepCodes = CHECKLIST_GROUPS.group2
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  const currentStepCode = useAppSelector(selectCurrentStepCode)
  const stepIndex = findIndex(stepCodes, (item) => item === currentStepCode)
  const stepGroupIndex = getAccordionIndexFromStepCode(currentStepCode)
  return (
    <AccordionItem p={0} m="0 0 24px 0" border="none">
      <StepGroupAccordionHeader
        initialStepCode={stepCodes[0]}
        iconName="settings"
        title="Set Up Salonscale"
        stepsCompleted={stepsCompleted ? stepsCompleted : 0}
        stepsLength={stepCodes.length}
      />

      {stepGroupIndex === CHECKLIST_GROUP_INDEXES.group2 && (
        <StepGroupAccordionPanel
          steps={
            <Accordion
              allowToggle
              p={0}
              index={stepIndex}
              border={`2px solid ${COLORS.lavender_100}`}
              borderRadius="0 0 15px 15px"
            >
              <SetupLines />
              <Divider />
              <SetupAddons />
              <Divider />
              <SetupExtensions />
              <Divider />
              <SetupTeam />
              <Divider />
              <SetupClients />
              <Divider />
              <LearnSessions />
              {/*<Divider/>*/}
              {/*<LearnReports/>*/}
              {/*<Divider/>*/}
              {/*<LearnOrders/>*/}
            </Accordion>
          }
        />
      )}
    </AccordionItem>
  )
}

const SetupLines = () => {
  const { salonId } = UseBaseApiParams()
  const stepCode = CHECKLIST_CODES.addLines
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))
  const description = `This is where the magic begins! Choose from over 250+ color lines to customize your digital backbar to match the products that you use everyday in your salon. Once you gather your pricing information, your SalonScale account will be unique for you!

Step 1: Gather your product wholesale costs. This can be found in either:
  • your Beauty Distributor Account
  • receipts or invoices from past purchases 
Step 2: Add the cost of these products
Step 3: Add a mark up to ensure you are profiting every color service. We recommend 100%!

Remember, you can’t manage what you can’t measure. Once you have all these details entered, both measurement and management become a breeze!

`
  // const previousStepCode = CHECKLIST_CODES.welcomeVideo
  // const previousChecklistItem = useAppSelector((state) => selectChecklistItem(state, previousStepCode))
  const isDisabled = false
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        isDisabled={isDisabled}
        checklistItem={checklistItem}
        label="1"
        title="Add Color Lines to Your Back Bar"
        actions={<UserAction isDisabled={isDisabled} />}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage: '' }}
        stepCode={stepCode}
        description={description}
        actions={
          <Link to={generatePath(ROUTES.addLinesV2, { salonId }) + `?guide=${CHECKLIST_CODES.addLines}`}>
            <Button variant="round" colorScheme="brand.midnight">
              Get Started
            </Button>
          </Link>
        }
        video={VIDEO_MAP.addLines}
      />
    </AccordionItem>
  )
}
const SetupAddons = () => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const stepCode = CHECKLIST_CODES.addAddons
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))
  const description = `You are over halfway done with your set up! Now it is time for “Addons”.

Add-Ons are exactly what they sound like- other costs of goods sold at your backbar or in your salon. The most common examples are:

  • Foils
  • Gloves
  • Shampoo
  • Treatments
  • Specialty drinks or coffee

Remember- this step is optional but every dollar that goes unaccounted for adds up, so we recommend taking some time to think about all the odds and ends in your business.
  `
  // const previousStepCode = CHECKLIST_CODES.addLines
  // const previousChecklistItem = useAppSelector((state) => selectChecklistItem(state, previousStepCode))

  // const salon = useAppSelector(selectActiveSalonContext)
  // const previousStepHasData = !!salon?.productsCount && salon.productsCount > 0
  const isDisabled = false
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        checklistItem={checklistItem}
        label="2"
        title="Add Add-Ons to Your Back Bar"
        actions={<UserAction isDisabled={isDisabled} />}
        isDisabled={isDisabled}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage: '' }}
        stepCode={stepCode}
        description={description}
        actions={
          <Flex gridGap="12px">
            <Link to={generatePath(ROUTES.supplyOnboard, { salonId }) + `?guide=${CHECKLIST_CODES.addAddons}`}>
              <Button variant="round" colorScheme="brand.midnight">
                Get Started
              </Button>
            </Link>
            <Button
              variant="round-outline"
              colorScheme="brand.midnight"
              onClick={() => {
                dispatch(
                  dispatchUpdateUserChecklistItem({
                    token: user.token,
                    checklistItemCode: CHECKLIST_CODES.addAddons,
                  }),
                )
                dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.addExtensions))
              }}
            >
              Skip to next step
            </Button>
          </Flex>
        }
        video={VIDEO_MAP.addAddons}
      />
    </AccordionItem>
  )
}
const SetupExtensions = () => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const stepCode = CHECKLIST_CODES.addExtensions
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))
  const description = `Just like adding color lines, the Extensions feature helps to track and charge for hair extensions. If you don’t currently use extensions, you can skip this step or revisit later if things in your business change!`

  // const previousStepCode = CHECKLIST_CODES.addAddons
  // const previousChecklistItem = useAppSelector((state) => selectChecklistItem(state, previousStepCode))
  const isDisabled = false
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        isDisabled={isDisabled}
        checklistItem={checklistItem}
        label="3"
        title="Add Extensions to Your Back Bar"
        actions={<UserAction isDisabled={isDisabled} />}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage: '' }}
        stepCode={stepCode}
        description={description}
        video={VIDEO_MAP.addExtensions}
        actions={
          <Flex gridGap="12px">
            <Link to={generatePath(ROUTES.extensionsAdd, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`}>
              <Button variant="round" colorScheme="brand.midnight">
                Get Started
              </Button>
            </Link>
            <Button
              variant="round-outline"
              colorScheme="brand.midnight"
              onClick={() => {
                dispatch(
                  dispatchUpdateUserChecklistItem({
                    token: user.token,
                    checklistItemCode: CHECKLIST_CODES.addExtensions,
                  }),
                )
                if (user?.currentSalonContext?.salonType === SalonTypes.salon) {
                  dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.addStylist))
                } else {
                  dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.addClient))
                }
              }}
            >
              Skip to next step
            </Button>
          </Flex>
        }
      />
    </AccordionItem>
  )
}
const SetupTeam = () => {
  const { salonId, user } = UseBaseApiParams()
  const stepCode = CHECKLIST_CODES.addStylist
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))

  // const previousStepCode = CHECKLIST_CODES.addExtensions
  // const previousChecklistItem = useAppSelector((state) => selectChecklistItem(state, previousStepCode))
  const isDisabled = user?.currentSalonContext?.salonType !== SalonTypes.salon

  const description = `It is easy to add your team members to SalonScale. Simply enter their email address or phone number, and your team will be prompted to create their account and download the app from the App Store or GooglePlay store.`
  const disabledMessage: string | ReactNode =
    user?.currentSalonContext?.salonType !== SalonTypes.salon ? (
      <>
        <Text as="span" fontWeight="bold" color={COLORS.lavender_500}>
          Upgrade
        </Text>
        <Text as="span" fontWeight="bold">
          {' '}
          to our Signature Plan to unlock the team feature!
        </Text>
      </>
    ) : (
      ''
    )

  // dont make solos go through setting up team
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        checklistItem={checklistItem}
        label={isDisabled ? '!' : '4'}
        title="Invite Your Team"
        actions={<UserAction isDisabled={isDisabled} />}
        isDisabled={isDisabled}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage }}
        stepCode={stepCode}
        description={description}
        video={VIDEO_MAP.addStylist}
        actions={
          <Link to={generatePath(ROUTES.stylists, { salonId }) + `?guide=${CHECKLIST_CODES.addStylist}`}>
            <Button variant="round" colorScheme="brand.midnight">
              Get Started
            </Button>
          </Link>
        }
      />
    </AccordionItem>
  )
}

const SetupClients = () => {
  const { salonId } = UseBaseApiParams()
  const stepCode = CHECKLIST_CODES.addClient
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))
  const description = `Clients can be created ahead of time in your business center (where you are now!) or on-the-fly at the beginning of an appointment using the mobile app.  

Simply add the client’s first and last name and voila, you have created a client! 
`

  const isDisabled = false
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        isDisabled={isDisabled}
        checklistItem={checklistItem}
        label="5"
        title="Add Your Clients"
        actions={<UserAction isDisabled={isDisabled} />}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage: '' }}
        stepCode={stepCode}
        description={description}
        actions={
          <Link to={generatePath(ROUTES.clients, { salonId }) + `?guide=${CHECKLIST_CODES.addClient}`}>
            <Button variant="round" colorScheme="brand.midnight">
              Get Started
            </Button>
          </Link>
        }
      />
    </AccordionItem>
  )
}

const LearnSessions = () => {
  const dispatch = useDispatch()
  const stepCode = CHECKLIST_CODES.learnSessions
  const checklistItem = useAppSelector((state) => selectChecklistItem(state, stepCode))
  const description = `Watch this video to learn how to add sessions in the app or in the business center.

Tip: Your stylists will use the app on their phone to create sessions during each appointment. Your front desk staff may want to be familiar with viewing sessions here in the Business Center. 

This video will walk you through both examples.
`

  // const previousStepCode = CHECKLIST_CODES.addClient
  // const previousChecklistItem = useAppSelector((state) => selectChecklistItem(state, previousStepCode))
  const isDisabled = false
  return (
    <AccordionItem p={0} m={0} border="none">
      <StepAccordionHeader
        checklistItem={checklistItem}
        label="6"
        title="Sessions"
        actions={<VideoAction isDisabled={isDisabled} duration="5 min" />}
        isDisabled={isDisabled}
      />
      <StepAccordionPanel
        disableState={{ isDisabled, disabledMessage: '' }}
        stepCode={stepCode}
        description={description}
        video={VIDEO_MAP.addSession}
        actions={
          <>
            {checklistItem.completed && (
              <Button
                variant="round-outline"
                colorScheme="brand.midnight"
                onClick={() => {
                  dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.inventoryIntroduction))
                }}
              >
                go to next step
              </Button>
            )}
          </>
        }
      />
    </AccordionItem>
  )
}

