import React from 'react'
import { keys } from 'lodash'
import { ExtensionAccordion } from './ExtensionAccordion'
import { Box } from '@chakra-ui/react'
import { ProductsByCategoryThenByLineSize } from '../../data/products/interfaces'
import {
  reduceExtensionFilters,
  selectAllExtensionCategories,
  selectAllExtensionSizes,
  selectAllExtensionVendorNames,
  selectExtensionFilters,
} from '../../data/extensions/slice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { FilterBar } from '../../mini-lib/filters/FilterBar'
import { SearchFilter } from '../../mini-lib/filters/SearchFilter'
import { UseViewSize } from '../../core/UseViewSize'
import { EMPTY_EXTENSION_FILTERS } from '../../data/extensions/constants'
import { getNumFiltersActive } from '../../data/products/utils'
import { SelectInput } from "../../mini-lib";

export const ExtensionLines = (props: { extensionsByCategoryThenByLineSize: ProductsByCategoryThenByLineSize }) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const { extensionsByCategoryThenByLineSize } = props
  const filters = useAppSelector(selectExtensionFilters)
  const allSizes = useAppSelector(selectAllExtensionSizes)
  const allCategories = useAppSelector(selectAllExtensionCategories)
  const allVendorNames = useAppSelector(selectAllExtensionVendorNames)

  const sortedLineCategoryNames = keys(extensionsByCategoryThenByLineSize).sort()

  return (
    <>
      <FilterBar
        onClear={() => dispatch(reduceExtensionFilters(EMPTY_EXTENSION_FILTERS))}
        numFiltersActive={getNumFiltersActive(filters)}
        search={
          <SearchFilter
            placeholder="Search Extensions"
            value={filters.searchText || ''}
            onChange={(searchText) => dispatch(reduceExtensionFilters({ ...filters, ...{ searchText } }))}
            onClear={() => dispatch(reduceExtensionFilters({ ...filters, ...{ searchText: '' } }))}
          />
        }
        filters={
          <>
            {allSizes && (
              <SelectInput
                w={isMobile ? '49%' : '150px'}
                placeholder={isMobile ? 'Sizes' : 'All Sizes'}
                value={filters.extensionSize ? filters.extensionSize.toString() : ''}
                options={allSizes.map((size) => ({ label: size.toString() + '"', value: size.toString() }))}
                onChange={value =>
                  dispatch(reduceExtensionFilters({ ...filters, ...{ extensionSize: parseInt(value) } }))
                }
              />
            )}
            {allCategories && (
              <SelectInput
                w={isMobile ? '49%' : '150px'}
                placeholder={isMobile ? 'Categories' : 'All Categories'}
                value={filters.categoryName}
                options={allCategories.map((name) => ({ label: name, value: name }))}
                onChange={value =>
                  dispatch(reduceExtensionFilters({ ...filters, ...{ categoryName: value } }))
                }
              />
            )}
            {allVendorNames && (
              <SelectInput
                w={isMobile ? '49%' : '150px'}
                placeholder={isMobile ? 'Brands' : 'All Brands'}
                value={filters.vendorName}
                options={allVendorNames.map((name) => ({ label: name, value: name }))}
                onChange={value => dispatch(reduceExtensionFilters({ ...filters, ...{ vendorName: value } }))}
              />
            )}
          </>
        }
      />
      <Box h="24px" />
      <ExtensionAccordion
        sortedFilteredLineCategoryNames={sortedLineCategoryNames}
        extensionsByCategoryThenByLineSize={extensionsByCategoryThenByLineSize}
      />
    </>
  )
}
