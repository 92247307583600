import { COLORS } from '../../../mini-lib/theme/colors'
import { useDispatch, useSelector } from 'react-redux'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { selectLoggedInUser } from '../../../data/user/slice'
import {
  selectAllowSmsContact,
  selectHeardAboutUsExtraDetails,
  selectHeardAboutUsOptionValue,
  selectSalonType,
  selectScaleQuantities,
  selectSelectedFreeScale,
  selectSubscriptionType,
} from '../../../data/signup/slice'
import { getLocalStorageItem } from '../../../core/localStorage'
import React, { useEffect, useState } from 'react'
import { UseUtmQueryParams } from '../../../integrations/utm'
import { dispatchGetLoggedInUser } from '../../../data/user/api'
import { LOADING_SIGNUP, SIGNUP_LOCALSTORAGE_KEYS } from '../../../data/signup/constants'
import * as Yup from 'yup'
import { launchChargebeePortal } from '../../../integrations/chargebee/service'
import { APICreateUser } from '../../../data/signup/interfaces'
import { APICreateSalon } from '../../../data/salon/interfaces'
import { APISubscriptionRequest } from '../../../integrations/chargebee/interfaces'
import { dispatchCreateSalonAndSubscription, dispatchCreateUserAndSalonAndSubscription } from '../../../data/signup/api'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { Formik } from 'formik'
import { InputControl } from 'formik-chakra-ui'
import IntlTelInput from 'react-intl-tel-input'
import { FormikPasswordControl } from '../../../mini-lib/formik-utils/FormikPasswordControl'
import { AlreadyHaveAccount, HeardAboutUs, TextMessagePolicy } from './CheckoutPage'
import { UseGrinQueryParams } from '../../../integrations/grin/utils'
import { Gap } from "../../../mini-lib/gap/Gap";
import { selectLoadingState } from "../../../core/loading/slice";
import { useAppSelector } from "../../../hooks";
import { ReleaseSpecialPricing } from "../../../mini-lib/flags/Release";
import { UseViewSize } from "../../../core/UseViewSize";
import { UseCanSeeScales } from "./signupHooks";

interface FormProps {
  firstName: string
  lastName: string
  salonName: string
  email: string
  password: string
  phone: string
  instagramHandle?: string
  bookingSoftware?: string
  timezone: string
}

const telStyles = (formValid: boolean | null, isDisabled: boolean) => ({
  width: '100%',
  '&:hover': { border: `1px solid ${COLORS.shades_neutral_200}`, boxShadow: 'none' },
  '&:focus': { border: `1px solid ${COLORS.shades_neutral_200}`, boxShadow: 'none' },
  borderRadius: '100px',
  border:
    formValid === true || formValid === null ? `1px solid ${COLORS.shades_neutral_200}` : `2px solid ${COLORS.danger}`,
  padding: '8px 12px 8px 44px',
  color: isDisabled ? COLORS.shades_neutral_400 : 'black',
  background: 'white',
})

export const SignupForm = (props: {}) => {
  const dispatch = useDispatch()
  const queryParams = UseQueryParams()

  const {isMobile} = UseViewSize()
  const dev = queryParams.get('dev')
  const today = new Date().toISOString().slice(0, 10)
  const utmParams = UseUtmQueryParams()
  const grinParams = UseGrinQueryParams()

  const user = useSelector(selectLoggedInUser)
  const salonType = useSelector(selectSalonType)
  const subscriptionType = useSelector(selectSubscriptionType)
  const selectedFreeScale = useSelector(selectSelectedFreeScale)
  const scaleQuantities = useSelector(selectScaleQuantities)
  const heardAboutSalonscale = useSelector(selectHeardAboutUsOptionValue)
  const heardAboutSalonscaleExtraDetails = useSelector(selectHeardAboutUsExtraDetails)
  const loadingSignup = useAppSelector((state) => selectLoadingState(state, LOADING_SIGNUP))
  const allowSms = useAppSelector(selectAllowSmsContact)
  const localAuthToken = getLocalStorageItem('auth-token')
  const localUserId = getLocalStorageItem('user-id')
  const isSpecialPricing = ReleaseSpecialPricing()
  const canSeeScales = UseCanSeeScales()

  // const [bookingSoftwareOther, setBookingSoftwareOther] = useState('')

  useEffect(() => {
    if (localAuthToken) {
      // todo: replace with lara 
      dispatch(dispatchGetLoggedInUser({ token: localAuthToken, userId: localUserId }))
    }
  }, [dispatch, localAuthToken, localUserId])

  const initialEmail = getLocalStorageItem(SIGNUP_LOCALSTORAGE_KEYS.email)
  const initialTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const initialValues: FormProps = dev
    ? {
        firstName: 'dev',
        lastName: 'test',
        salonName: `Salon ${today}`,
        email: `devtest+${today}@salonscale.com`,
        password: 'Staging1234',
        phone: '3069004372',
        // instagramHandle: '',
        // bookingSoftware: '',
        timezone: initialTimezone,
      }
    : {
        firstName: user ? user.firstName : '',
        lastName: user ? user.lastName : '',
        salonName: user?.currentSalonContext?.salonName ? user.currentSalonContext.salonName : '',
        email: user ? user.email : initialEmail ? initialEmail : '',
        password: user ? 'fakepasswordforpasswordfield' : '',
        phone: user ? (user.phone ? user.phone : '1112223333') : '',
        // instagramHandle: '',
        // bookingSoftware: '',
        timezone: initialTimezone,
      }
  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required').min(2, 'You need at least 2 characters'),
    lastName: Yup.string().required('Last name is required').min(2, 'You need at least 2 characters'),
    salonName: Yup.string().required('Salon name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    password: user?.userId
      ? Yup.string()
      : Yup.string().required('Password is required').min(8, 'You need at least 8 characters'),
    phone: Yup.string().required('Phone is required'),
    // instagramHandle: Yup.string()
    //   .min(2, 'You need at least 2 characters')
    //   .max(100, 'You cant have more than 100 characters'),
    // bookingSoftware: user?.userId ? Yup.string() : Yup.string().required('Booking Software is required'),
    timezone: Yup.string(),
  })

  const onSubmit = (values: FormProps) => {

    // if they've already signed up and have a salon but not a subscription just launch the cb portal
    if (user && user.currentSalonContext && user.currentSalonContext.salonId) {
      launchChargebeePortal({
        salonId: user.currentSalonContext.salonId,
        userId: user.userId,
        token: user.token,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone ? user.phone : '',
        salonscaleCustomerId: user.currentSalonContext?.subscription?.id ? user.currentSalonContext.subscription.id : -1,
        salonType: salonType,
        subscriptionType: subscriptionType,
        freeScale: selectedFreeScale,
        scaleQuantities: scaleQuantities,
        heardAboutSalonscale: heardAboutSalonscale,
        heardAboutSalonscaleExtraDetails: heardAboutSalonscaleExtraDetails,
        utmParams,
        grinParams,
        isSpecialPricing,
        canSeeScales
      })
      // if a user exists but no salon exists just create the salon and subscription
      // this might happen if the salon api fails for some reason or if an admin detaches a user from their previous salon
    } else if (user && !user.currentSalonContext) {
      const salonRequest: Partial<APICreateSalon> = {
        salon_name: values.salonName,
        instagram_handle: values.instagramHandle,
        // booking_software:
        //   values.bookingSoftware === 'Other' && bookingSoftwareOther ? bookingSoftwareOther : values.bookingSoftware,
        timezone: values.timezone,
      }
      const subscriptionRequest: APISubscriptionRequest = {
        salonType,
        subscriptionType,
        selectedFreeScale,
        scaleQuantities,
        heardAboutSalonscale,
        heardAboutSalonscaleExtraDetails,
      }

      dispatch(
        dispatchCreateSalonAndSubscription({
          user,
          salonRequest,
          subscriptionRequest,
          utmParams,
          grinParams,
          allowSms,
          isSpecialPricing,
          canSeeScales,
        }),
      )
      // if they have no user and no salon create everything
    } else {
      const userRequest: APICreateUser = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        phone: values.phone,
      }
      const salonRequest: Partial<APICreateSalon> = {
        salon_name: values.salonName,
        instagram_handle: values.instagramHandle,
        // booking_software:
        //   values.bookingSoftware === 'Other' && bookingSoftwareOther ? bookingSoftwareOther : values.bookingSoftware,
        timezone: values.timezone,
      }
      const subscriptionRequest: APISubscriptionRequest = {
        salonType,
        subscriptionType,
        selectedFreeScale,
        scaleQuantities,
        heardAboutSalonscale,
        heardAboutSalonscaleExtraDetails,
      }

      dispatch(
        dispatchCreateUserAndSalonAndSubscription({
          userRequest,
          salonRequest,
          subscriptionRequest,
          utmParams,
          grinParams,
          allowSms,
          isSpecialPricing,
          canSeeScales,
        }),
      )
    }
  }
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean | null>(null)

  const checkoutClicked = (values: FormProps) => {
    // todo: look at any other manual validation we might have to do and add it here
    if (!user && !values.phone) {
      setPhoneNumberValid(false)
    }
  }
  return (
    <Box>
      {(!localAuthToken || user) && (
        <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
          {({ handleSubmit, validateForm, values, setTouched, resetForm, errors, setFieldValue }) => (
            <>
              <Flex gridGap="12px" direction="column" onSubmit={handleSubmit as any} w="100%">
                <Flex justify='space-between' flexWrap='wrap'>
                  <InputControl
                    isDisabled={!!user?.userId}
                    w={isMobile ? "100%" : '48%'}
                    name="firstName"
                    label="First Name"
                    inputProps={{ autoFocus: true, borderRadius: '100px' }}
                  />
                  <InputControl
                    inputProps={{ borderRadius: '100px' }}
                    isDisabled={!!user?.userId}
                    w={isMobile ? "100%" : '48%'}
                    name="lastName"
                    label="Last Name"
                  />
                </Flex>

                <InputControl
                  inputProps={{ borderRadius: '100px' }}
                  isDisabled={!!user?.currentSalonContext?.salonId}
                  w="100%"
                  name="salonName"
                  label="Salon Name"
                />

                <Text color={!!user?.userId ? 'shades.neutral.400' : ''}>Phone</Text>
                <IntlTelInput
                  defaultValue={values.phone}
                  disabled={!!user?.userId}
                  telInputProps={{ style: telStyles(phoneNumberValid, !!user?.userId) }}
                  onPhoneNumberBlur={(isValid) => setPhoneNumberValid(isValid)}
                  onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber) => {
                    if (isValid) {
                      setPhoneNumberValid(isValid)
                    }
                    setFieldValue('phone', fullNumber)
                  }}
                  containerClassName="intl-tel-input"
                  inputClassName="form-control"
                />
                {phoneNumberValid !== null && !phoneNumberValid && (
                  <Text color="red.500" fontSize="14px">
                    Please enter a valid phone number
                  </Text>
                )}

                {/*<InputControl isDisabled={localAuthToken} maxW="450px" w="100%" name="phone" label="Phone" />*/}

                {/*<InputControl*/}
                {/*  inputProps={{ borderRadius: '100px' }}*/}
                {/*  isDisabled={!!user?.currentSalonContext?.salonId}*/}
                {/*  maxW="450px"*/}
                {/*  w="100%"*/}
                {/*  name="instagramHandle"*/}
                {/*  label="Instagram Handle"*/}
                {/*/>*/}
                {/*<Flex align="center" gridGap="12px" maxW="450px">*/}
                {/*  <SelectControl*/}
                {/*    isDisabled={!!user?.currentSalonContext?.salonId}*/}
                {/*    width={values.bookingSoftware === 'Other' ? '50%' : '100%'}*/}
                {/*    label="Booking Software"*/}
                {/*    name="bookingSoftware"*/}
                {/*    selectProps={{ placeholder: 'What do you use to book appointments?', borderRadius: '100px' }}*/}
                {/*  >*/}
                {/*    {BOOKING_SOFTWARE_OPTIONS.map((option) => {*/}
                {/*      return (*/}
                {/*        <option key={option.id} value={option.label}>*/}
                {/*          {option.label}*/}
                {/*        </option>*/}
                {/*      )*/}
                {/*    })}*/}
                {/*  </SelectControl>*/}
                {/*  {values.bookingSoftware === 'Other' && (*/}
                {/*    <Box w="50%" maxW="450px">*/}
                {/*      <Box p="0 12px 8px 0" color={!!user?.userId ? COLORS.shades_neutral_400 : COLORS.text_primary}>*/}
                {/*        Tell Us More!*/}
                {/*      </Box>*/}
                {/*      <Input*/}
                {/*        isDisabled={!!user?.userId}*/}
                {/*        borderRadius="100px"*/}
                {/*        value={bookingSoftwareOther}*/}
                {/*        onChange={(evt: any) => setBookingSoftwareOther(evt.target.value)}*/}
                {/*      />*/}
                {/*    </Box>*/}
                {/*  )}*/}
                {/*</Flex>*/}
                <InputControl
                  inputProps={{ borderRadius: '100px' }}
                  isDisabled={!!user?.userId}
                  w="100%"
                  name="email"
                  label="Email"
                />
                <FormikPasswordControl
                  defaultValue={values.password}
                  w="100%"
                  name="password"
                  type="password"
                  label="Password"
                  isDisabled={!!user?.userId}
                />
                <HeardAboutUs isDisabled={!!user?.currentSalonContext?.salonId} />
              </Flex>
              <Box h="24px" />
              <TextMessagePolicy/>
              <Box h="24px" />
              <Button
                disabled={loadingSignup}
                variant="round-large"
                bg={COLORS.midnight_900}
                w="100%"
                onClick={() => {
                  checkoutClicked(values)
                  handleSubmit()
                }}
              >
                Continue to Payment
              </Button>
              <Gap s='12px'/>

              <AlreadyHaveAccount />
            </>
          )}
        </Formik>
      )}
    </Box>
  )
}
