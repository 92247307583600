import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectChecklistItem, selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems, dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { sgInventoryStepCodes } from './SGIndex/SGInventoryIndex'
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGInventoryAddingStockIntroPage = () => {
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, sgInventoryStepCodes))
  const currentCode = CHECKLIST_CODES.inventoryAddingStock
  const currentStep = useAppSelector((state) => selectChecklistItem(state, currentCode))
  const maybeUpdateChecklist = () => {
    if (!currentStep.completed) {
      dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: currentCode}))
    }
  }
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="implementation"
          title="ADDING STOCK"
          description={descriptionText}
          video={VIDEO_MAP.inventoryAddingStock}
          actions={
            <SGActionButtons
              action={{
                label: 'Complete Inventory',
                url: generatePath(ROUTES.sgIndex, { salonId }) + '?step=inventory',
                onClick: maybeUpdateChecklist
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={sgInventoryStepCodes.length} stepsComplete={stepsCompleted || 0} />}
          imgPath={require('../../assets/start-guide/adding-stock.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `There are 2 ways you can add stock:

    • Through an existing order form in SalonScale

    • Manually updating your inventory

When you order inventory or purchase color to replenish your backbar stock it is important to record this in the app so your on-hand number reflects that you added the product back into your backbar. This step can be completed in the business center or the mobile app.`
