import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { SignupFormLayout } from "./SignupFormLayout";
import { Gap } from "../../../mini-lib/gap/Gap";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { UseBaseApiParams } from "../../../core/UseBaseApiParams";
import { APILaraPatchSalon } from "../../../data/salon/interfaces";
import { dispatchLaraPatchSalon } from "../../../data/salon/api";
import { COLORS } from "../../../mini-lib/theme/colors";
import { SalonScaleIcon } from "../../../mini-lib/icons/SalonScaleIcon";
import { FormikError } from "../../../mini-lib/formik-utils/FormikError";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../../appRoutes";

export const SignupSalonDescriptionPage = () => {
  return (
    <SignupFormLayout stepNumber={3} totalSteps={4}>
      <Flex justify='center' align='center' direction='column'>
        <Gap/>
        <Text variant='title2-desktop-bold'>Choose the option that best describes you.</Text>
        <Gap s='32px'/>
        <SalonDescriptionForm/>
      </Flex>
    </SignupFormLayout>
  )
}

// todo: add icon assets and use them
const descriptionOptions = {
  solo: { value: 'solo', description: 'I\'m an independent', icon: 'solo' },
  team: { value: 'team', description: 'I employ or manage multiple stylists', icon: 'team' },
}

const SalonDescriptionForm = () => {
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()

  const [submitted, setSubmitted] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string>('')
  const [error, setError] = useState<string>('')
  const optionClicked = (option: string) => {
    setSelectedOption(option)
    setError('')
  }

  const submitClicked = () => {
    if (!selectedOption) {
      setError('Please select a description to continue!')
      return
    }
    const body: APILaraPatchSalon = {
      trial_salon_description: selectedOption,
    }

    dispatch(dispatchLaraPatchSalon({
      token: user.token,
      userId: user.userId,
      currentSalonContext: user.currentSalonContext,
      salonId: user.currentSalonContext?.salonId || -1,
      request: body,
      syncToPipedrive: true
    }))
    setSubmitted(true)
  }
  if (submitted) {
    return <Redirect to={ROUTES.signupV2Colors}/>
  }
  return (
    <>
      <Box w='100%'>
        <>

          <Box p='24px'>
            <SalonDescriptionOption isSelected={selectedOption === descriptionOptions.solo.value} option={descriptionOptions.solo} onClick={optionClicked}/>
            <Gap/>
            <SalonDescriptionOption isSelected={selectedOption === descriptionOptions.team.value} option={descriptionOptions.team} onClick={optionClicked}/>
          </Box>


          <Box p='0 12px'>
            <Button variant='round-large' colorScheme='brand.midnight' w='100%' onClick={() => submitClicked()}>
              Continue
            </Button>
            <FormikError showError={!!error} errorText={error}/>
          </Box>
          <Gap s='32px'/>

        </>
      </Box>
    </>
  )
}

const SalonDescriptionOption = ( props: {
  isSelected: boolean
  option: { value: string, description: string, icon: string },
  onClick: ( value: string ) => void
} ) => {
  const { isSelected, option, onClick } = props
  return (
    <Flex
      cursor='pointer'
      align='center'
      p='12px'
      borderRadius='8px'
      border={isSelected ? `2px solid ${COLORS.midnight_900}` : `2px solid ${COLORS.shades_neutral_200}`}
      onClick={() => onClick(option.value)}
    >
      <SalonScaleIcon name={option.icon} size='60px'/>
      <Gap s='12px'/>
      <Text fontSize='14px'>{option.description}</Text>
    </Flex>
  )
}
