import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Flex, Text } from '@chakra-ui/react'
import { useAppSelector } from '../../../hooks'
import { generatePath, Link } from 'react-router-dom'
import {
  reduceSetProductFilters,
  selectInfoOnProductsWithTarget,
  selectProductFilters,
  selectSortedFilteredProductsByCategoryThenByLineSize,
} from '../../../data/products/slice'
import { dispatchListProducts } from '../../../data/products/api'

import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { Loading } from '../../../mini-lib/loading/Loading'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { keys } from 'lodash'
import { EmptySearch } from '../../../mini-lib/empty/EmptySearch'
import { VARIANTS } from '../../../mini-lib/theme/variants'
import { EmptyPage } from '../../../mini-lib/empty/EmptyPage'
import { HelpStepCompleteDrawer } from '../../start-guide/common-components/HelpStepCompleteDrawer'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { dispatchUpdateUserChecklistItem } from '../../../data/start-guide/api'
import {
  reduceSetCurrentSubStepIndex,
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectStepCompleteDrawerState,
  selectStepsCompleted,
} from '../../../data/start-guide/slice'
import { ProductFilters } from '../filters/ProductFilters'
import { HelpModal } from '../../start-guide/common-components/HelpModal'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { EMPTY_PRODUCT_FILTERS } from '../../../data/products/constants'
import { HelpStepperV2 } from '../../start-guide-v2/HelpStepperV2'
import { sgInventoryStepCodes } from '../../start-guide-v2/SGIndex/SGInventoryIndex'
import { Gap } from '../../../mini-lib/gap/Gap'
import { ProductsByCategoryAccordion } from './ProductsByCategoryAccordion'
import { UseResetStartGuideState } from '../../start-guide/common-components/UseResetStartGuideState'
import { ProductBulkUpdateSheet } from '../products/ProductBulkUpdateSheet'
import { COLORS } from "../../../mini-lib/theme/colors";
import { ReleaseLaraColors } from "../../../mini-lib/flags/Release";
import { ROUTES } from "../../../appRoutes";

export const ProductTargetPage = (props: { showBreadCrumbs?: boolean }) => {
  const { showBreadCrumbs = true } = props
  const dispatch = useDispatch()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  UseResetStartGuideState()
  const breadcrumbs = showBreadCrumbs
    ? [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Set Targets' }]
    : []
  const filters = useAppSelector(selectProductFilters)
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)

  const productsByCategoryThenByLineSize = useAppSelector(selectSortedFilteredProductsByCategoryThenByLineSize)
  const productsLoaded = !!productsByCategoryThenByLineSize
  const infoOnProductsWithTarget = useAppSelector(selectInfoOnProductsWithTarget)
  const addTargetsChecklistItem = useAppSelector((state) =>
    selectChecklistItem(state, CHECKLIST_CODES.inventorySetTargets),
  )
  // reinitialize empty product filters on page load once
  useEffect(() => {
    dispatch(reduceSetProductFilters(EMPTY_PRODUCT_FILTERS))
    dispatch(reduceSetCurrentSubStepIndex(0))
  }, [dispatch])

  const queryParams: any = UseQueryParams()
  const guideType = queryParams.get('guide')
  const isGuide = !!guideType

  const releaseLaraColors = ReleaseLaraColors()
  useEffect(() => {
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, releaseLaraColors])

  // watch for when they hit 100% completed and then update the checklist item
  useEffect(() => {
    if (isGuide && !addTargetsChecklistItem.completed && infoOnProductsWithTarget?.percentage === 100) {
      dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: CHECKLIST_CODES.inventorySetTargets }))
    }
  }, [dispatch, isGuide, infoOnProductsWithTarget?.percentage, addTargetsChecklistItem.completed, token])

  const currentStep = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.inventorySetTargets))
  const previousStepLink = generatePath(ROUTES.sgInventoryTargetsIntro, { salonId })
  const nextStepLink = generatePath(ROUTES.sgInventoryOnHandIntro, { salonId })
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, sgInventoryStepCodes))
  return (
    <PageLayout
      variant="full"
      top={
        <>
          <HelpStepperV2
            title="Set Your Backbar Targets"
            titleIconName="implementation"
            currentStepNumber={1}
            stepsComplete={stepsCompleted || 0}
            totalSteps={sgInventoryStepCodes.length}
            previousStepLink={previousStepLink}
            nextStepLink={nextStepLink}
            guideType={CHECKLIST_CODES.inventorySetTargets}
          />
        </>
      }
      header={<PageHeader title="SET YOUR BACKBAR TARGETS" breadcrumbs={breadcrumbs} />}
      content={
        <>
          <ProductBulkUpdateSheet propertyToUpdate="targets" checklistStepComplete={currentStep?.completed}/>
          <HelpModal guideType={CHECKLIST_CODES.inventorySetTargets} title="Let’s Set Your BackBar Targets!" />

          {guideType === CHECKLIST_CODES.inventorySetTargets && (
            <HelpStepCompleteDrawer
              guideType={CHECKLIST_CODES.inventorySetTargets}
              title={infoOnProductsWithTarget?.percentage === 100 ? "Way To Go! You’ve Completed Setting Your Backbar Targets" : `Way To Go you've set up ${infoOnProductsWithTarget?.numberCompleted} of your products`}
              subtitle={infoOnProductsWithTarget?.percentage === 100 ? "You’re ready to move on to the next step!" : 'Set up all of your products to continue!'}
              actions={
                <Flex align="center" gridGap="12px">
                  {stepCompleteDrawerState !== 'button' && (
                    <Button
                      variant="round-outline"
                      colorScheme="brand.midnight"
                      onClick={() => {
                        dispatch(reduceSetStepCompleteDrawerState('button'))
                      }}
                    >
                      Continue Editing
                    </Button>
                  )}
                  {infoOnProductsWithTarget?.percentage === 100 ? (
                    <Link to={generatePath(ROUTES.sgInventoryOnHandIntro, { salonId })}>
                      <Button
                        variant="round"
                        colorScheme="brand.midnight"
                        onClick={() => {
                          dispatch(reduceSetStepCompleteDrawerState('hidden'))
                          dispatch(reduceSetCurrentSubStepIndex(0))
                        }}
                      >
                        Next Step
                      </Button>
                    </Link>
                  ) : (
                   <Text color={COLORS.midnight_500}>
                      {infoOnProductsWithTarget?.numberCompleted}/{infoOnProductsWithTarget?.totalProducts} Completed
                    </Text>
                  )}
                </Flex>
              }
            />
          )}

          <Gap s="24px" />

          <ProductFilters showTargetFilter={true}/>

          <Gap s="24px" />

          {/* products loaded state */}
          {productsLoaded && keys(productsByCategoryThenByLineSize).length > 0 && (
            <ProductsByCategoryAccordion
              productPropertyToUpdate={'target'}
              productsByCategoryThenByLineSize={productsByCategoryThenByLineSize}
            />
          )}

          {/* empty search state */}
          {productsLoaded &&
            keys(productsByCategoryThenByLineSize).length === 0 &&
            filters &&
            filters.searchText &&
            filters.searchText?.length > 0 && (
              <EmptySearch>
                <Text variant={VARIANTS.text.footnote}>No brands, lines, or products matched your search</Text>
              </EmptySearch>
            )}

          {/* empty state */}
          {productsLoaded &&
            keys(productsByCategoryThenByLineSize).length === 0 &&
            (!filters.searchText || filters.searchText?.length === 0) && (
              <EmptyPage
                title="NO PRODUCTS ADDED"
                content="Add your color lines to start using this feature in the SalonScale App!"
              />
            )}
          {/* loading state */}
          {!productsLoaded && <Loading />}
        </>
      }
    ></PageLayout>
  )
}
