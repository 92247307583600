import { Box, Flex, Progress, Text } from '@chakra-ui/react'
import React from 'react'
import { SalonScaleIcon } from '../../mini-lib/icons/SalonScaleIcon'
import { UseViewSize } from '../../core/UseViewSize'
import { COLORS } from '../../mini-lib/theme/colors'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { Link } from 'react-router-dom'

export interface Step {
  title: string
  link: string
  state: 'complete' | 'current' | 'incomplete'
}

export const HelpStepperV2 = (props: {
  title: string
  titleIconName: string
  previousStepLink: string
  nextStepLink: string
  currentStepNumber: number
  stepsComplete: number
  totalSteps: number
  guideType: string
}) => {
  const { isMobile } = UseViewSize()
  const {
    title,
    titleIconName,
    previousStepLink,
    nextStepLink,
    totalSteps,
    currentStepNumber,
    stepsComplete,
    guideType,
  } = props
  const queryParams: any = UseQueryParams()
  const guideParam = queryParams.get('guide')
  const isGuide = guideParam === guideType
  const allowNextStep = stepsComplete >= currentStepNumber
  return (
    <>
      {isGuide && !isMobile && (
        <Flex w="100%" borderRadius="6px" position='relative' mb="24px" wrap="wrap" bg={COLORS.lavender_100}>
          {/* header */}
          {/*
           */}
          <Flex
            w={isMobile ? '100%' : '50%'}
            justify={isMobile ? 'center' : ''}
            align="center"
            p={isMobile ? '12px' : '24px'}
            gridGap="12px"
          >
            <SalonScaleIcon name={titleIconName} />
            <Text fontSize='20px' fontWeight='bold'>{title}</Text>
          </Flex>
          {/* steps */}
          {/*
           */}
          <Flex w={isMobile ? '100%' : '50%'} align="center" justify="space-between">
            <TaskBar showLabel={true} currentStepNumber={currentStepNumber} stepsComplete={stepsComplete} totalSteps={totalSteps} />
            <Flex pr="24px" gridGap="4px">
              <PreviousStep stepLink={previousStepLink} />
              <NextStep stepLink={allowNextStep ? nextStepLink : null} />
            </Flex>
          </Flex>
        </Flex>
      )}
      {isGuide && isMobile && (
        <Box w="100%" borderRadius="6px" mb="24px" p={'12px'} position='relative' bg={COLORS.lavender_100}>
          <TaskBar showLabel={true} currentStepNumber={currentStepNumber} stepsComplete={stepsComplete} totalSteps={totalSteps} />
          <Box h="12px" />
          <Flex w={'100%'} justify="space-between" align="center">
            <PreviousStep stepLink={previousStepLink} />
            <Flex align="center" gridGap="12px">
              <SalonScaleIcon name={titleIconName} />
              <Text variant="title3mobile">{title}</Text>
            </Flex>
            <NextStep stepLink={stepsComplete >= currentStepNumber ? nextStepLink : null} />
          </Flex>
        </Box>
      )}
    </>
  )
}

export const TaskBar = (props: { showLabel: boolean; currentStepNumber?: number; stepsComplete: number; totalSteps: number }) => {
  const { showLabel, currentStepNumber = 1, stepsComplete, totalSteps } = props
  const { isMobile } = UseViewSize()
  return (
    <Flex align="center" gridGap="12px">
      {showLabel && <Text>Task {currentStepNumber}</Text>}
      <Progress
        bg="#E6E0E9"
        colorScheme="brand.lavender"
        size="xs"
        value={(stepsComplete / totalSteps) * 100}
        width={showLabel ? '100px' : '140px'}
      />
      <Text color={COLORS.text_secondary} fontSize={isMobile ? '12px' : ''}>
        {stepsComplete} of {totalSteps} tasks completed
      </Text>
    </Flex>
  )
}

const NextStep = (props: { stepLink: string | null }) => {
  const { stepLink } = props
  if (stepLink) {
    return (
      <Link to={stepLink}>
        <Box border={`1px solid ${COLORS.lavender_500}`} borderRadius="3px" cursor="pointer">
          <MaterialIcon colorhex={COLORS.lavender_500} name="chevron_right" />
        </Box>
      </Link>
    )
  }
  return (
    <Box border={`1px solid ${COLORS.lavender_300}`} borderRadius="3px">
      <MaterialIcon colorhex={COLORS.lavender_300} name="chevron_right" />
    </Box>
  )
}

const PreviousStep = (props: { stepLink: string | null }) => {
  const { stepLink } = props
  if (stepLink) {
    return (
      <Link to={stepLink}>
        <Box border={`1px solid ${COLORS.lavender_500}`} borderRadius="3px" cursor="pointer">
          <MaterialIcon colorhex={COLORS.lavender_500} name="chevron_left" cursor="pointer" />
        </Box>
      </Link>
    )
  }
  return (
    <Box border={`1px solid ${COLORS.lavender_300}`} borderRadius="3px">
      <MaterialIcon colorhex={COLORS.lavender_300} name="chevron_left" />
    </Box>
  )
}
