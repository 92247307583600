// this is just to help when trying to add theme variants to components
// we will keep adding things here as we add more variants to things
export const VARIANTS = {
  button: {
    round: 'round',
    roundOutline: 'round-outline',
  },
  text: {
    gigantictext: 'gigantictext',
    giganticsubtitle: 'giganticsubtitle',
    largetitle: 'largetitle',
    title1: 'title1',
    title2: 'title2',
    title3: 'title3',
    footnote: 'footnote',
    headline: 'headline',
    caption1: 'caption1',
    caption2: 'caption2',
    button: 'button',
    calloutDesktopRegular: 'callout-desktop-regular',
  },
}
