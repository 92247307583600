/////////////// api.ts
//
//

import axios from 'axios'
import { Dispatch } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import {
  reduceCreateProduct,
  reduceDeleteLine,
  reduceDeleteProduct,
  reduceDeleteProducts,
  reduceListAllLines,
  reduceListProduct, reduceResetProducts,
  reduceUpdateProducts,
} from './slice'
import { APIUpdateProduct, APIVendorLara, LineOption, Product, VendorLara } from './interfaces'
import {
  mapAPIColorsToProducts,
  mapAPILinesToLines,
  mapAPIProductToProduct,
  mapAPIProductToProducts,
  mapAPIUpdateProductToAPIPatchLara,
  mapAPIVendorLaraListToVendorLaraList,
  mapLinesToAPILines
} from './mappers'
import { APIPagedResponse } from '../../core/pagination'
import { GetServerBaseUrl } from '../../env'
import { LineKindOptions, LOADING_PRODUCTS, RESETTING_PRODUCTS } from './constants'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { buildLaraConfig, buildLaraPageParams } from "../../mini-lib/lara/lara-utils";
import { reduceListVendors } from "../master-products/slice";

// apis
//
//
export const apiListProduct = ( params: {
  token: string
  salonId: number
  lineKind: string
  search?: string
} ): Promise<Product[]> => {
  const { token, salonId, lineKind, search } = params
  const kindParam = lineKind ? `&kind=${lineKind}` : '&kind=color'
  const searchParam = search ? `&q=${search}` : ''
  const url = `${GetServerBaseUrl()}/products/?token=${token}&salon_id=${salonId}${searchParam}${kindParam}&disable_paging=true`
  return axios
    .get(url)
    .then(( response: { data: APIPagedResponse } ) => {
      return mapAPIProductToProducts(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}

export const apiUpdateProducts = ( params: {
  token: string
  user_id: number
  salon_id: number
  models: APIUpdateProduct[]
} ): Promise<Product[]> => {
  const { token, user_id, salon_id, models } = params
  const url = `${GetServerBaseUrl()}/products/`
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    products: models,
    update_quantity_only: false,
  }
  return axios
    .put(url, body)
    .then(( response: any ) => {
      return mapAPIProductToProducts(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}
export const apiCreateProduct = ( params: {
  token: string
  user_id: number
  salon_id: number
  model: Product
} ): Promise<Product> => {
  const { token, user_id, salon_id, model } = params
  const url = `${GetServerBaseUrl()}/products/`
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    product: model,
  }
  return axios
    .post(url, body)
    .then(( response: any ) => {
      return mapAPIProductToProduct(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}
export const apiDeleteProduct = ( params: { token: string; salonId: number; model: Product } ): Promise<any> => {
  const { token, salonId, model } = params
  const url = `${GetServerBaseUrl()}/products/${model.id}/?token=${token}&salon_id=${salonId}`
  return axios
    .delete(url)
    .then(( response: any ) => {
      return { ...response.data, id: model.id }
    })
    .catch(( error ) => {
      throw error
    })
}
export const apiDeleteProducts = ( params: {
  token: string
  salonId: number
  models: Product[]
} ): Promise<Product[]> => {
  const { token, models, salonId } = params
  const modelIds = models.map(( model ) => model.id)
  const body = {
    token,
    product_ids: modelIds,
    salon_id: salonId,
  }
  const url = `${GetServerBaseUrl()}/products/bulk-delete/`
  return axios
    .post(url, body)
    .then(( response: any ) => {
      return models
    })
    .catch(( error ) => {
      throw error
    })
}

export const apiDeleteColorsLara = ( params: {
  token: string
  salonId: number
  models: Product[]
} ): Promise<Product[]> => {
  const { token, models, salonId } = params
  const config = buildLaraConfig({ token })
  const modelIds = models.map((model) => model.id).join(',')
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/colors?ids=${modelIds}`
  return axios
    .delete(url, config)
    .then(( response: any ) => {
      return models
    })
    .catch(( error ) => {
      throw error
    })
}
export const apiDeleteLine = ( params: { token: string; salonId: number; lineName: string } ): Promise<any> => {
  const { token, lineName, salonId } = params
  const body = { brand: lineName, salon_id: salonId, token }
  const url = `${GetServerBaseUrl('v2')}/products/brands/delete/?token=${token}`
  return axios
    .delete(url, { data: body })
    .then(( response: any ) => {
      return response?.data?.data?.products ? response?.data?.data?.products : []
    })
    .catch(( error ) => {
      throw error
    })
}
export const apiListAllLines = ( token: string, lineKind: string, salonId: number ): Promise<LineOption[]> => {
  const kindParam = lineKind ? `&kind=${lineKind}` : '&kind=color'
  const url = `${GetServerBaseUrl('v2')}/products/product-lines/?token=${token}${kindParam}&salon_id=${salonId}`
  return axios
    .get(url)
    .then(( response: { data: APIPagedResponse } ) => {
      const lines = mapAPILinesToLines(response.data.data.brand)
      return lines
    })
    .catch(( error ) => {
      throw error
    })
}

export const apiAddLines = ( token: string, salonId: number, lines: LineOption[] ): Promise<LineOption[]> => {
  const url = `${GetServerBaseUrl('v2')}/products/product-lines/add/?token=${token}&salon_id=${salonId}`
  const body = {
    token,
    salon_id: salonId,
    is_default_price: false,
    brands: mapLinesToAPILines(lines),
  }
  return axios
    .post(url, body)
    .then(( response: { data: APIPagedResponse } ) => {
      return mapAPILinesToLines(response.data.data.brand)
    })
    .catch(( error ) => {
      throw error
    })
}

// actions
//
//
export const dispatchListProducts = ( params: {
  token: string,
  salonId: number,
  releaseLaraColors: boolean

  trashedFilter?: 'only' | string
  hasPriceFilter?: boolean
  idFilter?: number
  pageNumber?: number
  pageSize?: number
  nameFilter?: string
  levelFilter?: number
  volumeFilter?: number
} ) => {
  const {releaseLaraColors} = params
  return ( dispatch: Dispatch ) => {
    dispatch(reduceSetLoadingState({ name: LOADING_PRODUCTS, state: true }))
    if (releaseLaraColors) {
      return apiListColorsLara(params).then(( resp ) => {
        dispatch(reduceListProduct(resp))
        dispatch(reduceSetLoadingState({ name: LOADING_PRODUCTS, state: false }))
      })
    } else {
      return apiListProduct({ token: params.token, salonId: params.salonId, lineKind: LineKindOptions.color }).then(( resp ) => {
        dispatch(reduceListProduct(resp))
        dispatch(reduceSetLoadingState({ name: LOADING_PRODUCTS, state: false }))
      })
    }
  }
}

export const dispatchUpdateProducts = ( params: {
  token: string
  user_id: number
  salon_id: number
  models: APIUpdateProduct[]
  loadingName: string
} ) => {
  const { loadingName } = params
  return ( dispatch: Dispatch ) => {
    dispatch(reduceSetLoadingState({ name: loadingName, state: true }))
    return apiUpdateProducts(params)
      .then(( resp ) => {
        dispatch(reduceUpdateProducts(resp))
        dispatch(reduceSetLoadingState({ name: loadingName, state: false }))
        // toast.success(`Updated ${resp.length} ${maybePluralize('Product', resp.length)}`)
      })
      .catch(( error ) => {
        throw error
      })
  }
}
export const dispatchCreateProduct = ( params: {
  token: string;
  user_id: number;
  salon_id: number;
  model: Product
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiCreateProduct(params)
      .then(( resp ) => {
        dispatch(reduceCreateProduct(resp))
        toast.success('Created Product')
      })
      .catch(( error ) => {
        throw error
      })
  }
}
export const dispatchDeleteProduct = ( params: { token: string; salonId: number; model: Product } ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteProduct(params)
      .then(( resp ) => {
        dispatch(reduceDeleteProduct(resp))
        toast.success('Deleted Product')
      })
      .catch(( error ) => {
        throw error
      })
  }
}
export const dispatchDeleteProducts = ( params: {
  token: string
  salonId: number
  models: Product[]
  loadingName?: string
  releaseLaraColors: boolean
} ) => {
  const { loadingName = 'deleting-products', releaseLaraColors } = params
  return ( dispatch: Dispatch ) => {
    dispatch(reduceSetLoadingState({ name: loadingName, state: true }))
    if (releaseLaraColors) {
      return apiDeleteColorsLara(params).then(( resp ) => {
        dispatch(reduceDeleteProducts(resp))
        dispatch(reduceSetLoadingState({ name: loadingName, state: false }))
      })
    } else {
      return apiDeleteProducts(params)
        .then(( resp ) => {
          dispatch(reduceDeleteProducts(resp))
          dispatch(reduceSetLoadingState({ name: loadingName, state: false }))
        })
        .catch(( error ) => {
          throw error
        })
    }
  }
}
export const dispatchDeleteLine = ( params: { token: string; salonId: number; lineName: string } ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteLine(params)
      .then(( resp ) => {
        dispatch(reduceDeleteLine(resp))
        toast.success('Deleted Line')
      })
      .catch(( error ) => {
        throw error
      })
  }
}
export const dispatchListAllLines = ( token: string, salonId: number ) => {
  return ( dispatch: Dispatch ) => {
    return apiListAllLines(token, LineKindOptions.color, salonId).then(( resp ) => {
      dispatch(reduceListAllLines(resp))
    })
  }
}

// lara apis
//
//
//
//
export const apiListColorsLara = ( params: {
  token: string
  salonId: number

  trashedFilter?: 'only' | string
  hasPriceFilter?: boolean
  idFilter?: number
  pageNumber?: number
  pageSize?: number
  nameFilter?: string
  levelFilter?: number
  volumeFilter?: number
} ): Promise<Product[]> => {
  const {
    token,
    salonId,
    trashedFilter,
    hasPriceFilter,
    nameFilter,
    idFilter,
    levelFilter,
    volumeFilter,
    pageNumber= 1,
    pageSize= 10000,
  } = params

  const nameParam = nameFilter ? `&filter[name]=${nameFilter}` : ''
  const trashedParam = trashedFilter ? `&filter[trashed]=${trashedFilter}` : ''
  const hasPriceParam = hasPriceFilter ? `&filter[hasPrice]=${hasPriceFilter}` : ''
  const idParam = idFilter ? `&filter[id]=${idFilter}` : ''
  const levelParam = levelFilter ? `&filter[level]=${levelFilter}` : ''
  const volumeParam = volumeFilter ? `&filter[volume]=${volumeFilter}` : ''
  const { pageSizeParam, pageNumberParam } = buildLaraPageParams({ pageSize, pageNumber })

  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/colors?${pageSizeParam}&${pageNumberParam}${nameParam}${trashedParam}${hasPriceParam}${idParam}${levelParam}${volumeParam}`
  return axios
    .get(url, config)
    .then(( response: { data: APIPagedResponse } ) => {
      return mapAPIColorsToProducts(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}
export const apiPatchColors = ( params: {
  token: string
  user_id: number
  salon_id: number
  models: APIUpdateProduct[]

} ): Promise<Product[]> => {
  const { token, salon_id, models } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salon_id}/colors`

  const body = mapAPIUpdateProductToAPIPatchLara(models)
  return axios
    .patch(url, body, config)
    .then(( response: any ) => {
      return mapAPIColorsToProducts(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}

export const apiListVendorsLara = ( params: {
  token: string
} ): Promise<VendorLara[]> => {
  const { token } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/vendors?page[size]=10000&filter[kind]=color`

  return axios
    .get(url, config)
    .then(( response: {data: {data: APIVendorLara[]}} ) => {
      return mapAPIVendorLaraListToVendorLaraList(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}

export const apiResetPricing = ( params: {token: string, salonId: number, onlyAutoPriced: boolean, shouldArchive: boolean} ): Promise<void> => {
  const { token, salonId, onlyAutoPriced, shouldArchive} = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/colors-reset?only_auto_price=${onlyAutoPriced ? 1 : 0}&should_archive=${shouldArchive ? 1 : 0}`

  return axios
    .delete(url, config)
    .then(() => {
      return
    })
    .catch(( error ) => {
      throw error
    })
}


// lara dispatches
//
//
//
//
export const dispatchListColorsLara = ( params: {
  token: string,
  salonId: number,
  trashedFilter?: 'only' | string

  hasPriceFilter?: boolean
  idFilter?: number
  pageNumber?: number
  pageSize?: number
  nameFilter?: string
  levelFilter?: number
  volumeFilter?: number
} ) => {
  return ( dispatch: Dispatch ) => {
    dispatch(reduceSetLoadingState({ name: LOADING_PRODUCTS, state: true }))
    return apiListColorsLara(params).then(( resp ) => {
      dispatch(reduceListProduct(resp))
      dispatch(reduceSetLoadingState({ name: LOADING_PRODUCTS, state: false }))
    })
  }
}

export const dispatchPatchColors = ( params: {
  token: string
  user_id: number
  salon_id: number
  models: APIUpdateProduct[]
  loadingName: string
} ) => {
  const { loadingName } = params
  return ( dispatch: Dispatch ) => {
    dispatch(reduceSetLoadingState({ name: loadingName, state: true }))
    return apiPatchColors(params)
      .then(( resp ) => {
        dispatch(reduceUpdateProducts(resp))
        dispatch(reduceSetLoadingState({ name: loadingName, state: false }))
        // toast.success(`Updated ${resp.length} ${maybePluralize('Product', resp.length)}`)
      })
      .catch(( error ) => {
        throw error
      })
  }
}

export const dispatchListVendors = ( params: {token: string} ) => {
  return ( dispatch: Dispatch ) => {
    return apiListVendorsLara(params).then(( resp ) => {
      dispatch(reduceListVendors(resp))
    })
  }
}

export const dispatchResetPricing = ( params: {token: string, salonId: number, onlyAutoPriced: boolean, shouldArchive: boolean} ) => {
  const injectedParams = {...params, ...{releaseLaraColors: true}}
  return ( dispatch: Dispatch | any ) => {
    dispatch(reduceSetLoadingState({ name: RESETTING_PRODUCTS, state: true }))
    return apiResetPricing(params).then(( ) => {
      if (params.shouldArchive) {
        dispatch(reduceResetProducts())
      }
      dispatch(dispatchListProducts(injectedParams))
      dispatch(reduceSetLoadingState({ name: RESETTING_PRODUCTS, state: false }))
    })
  }
}
