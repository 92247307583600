// api interfaces
export interface APIPagedLaraResponse {
  data: any[]
  meta: APILaraPagination
}

// eg: all_db_data = ['a','b','c','d','e']
// if we call for that data with page[size]=2
// we will get
//
// data=['c','d'], per_page = 2, current_page = 2, last_page = 3, from = 3 to = 4, total = 2
//
export interface APILaraPagination {
  current_page: number
  last_page: number
  per_page: number
  from: number // index + 1 of start of entries in response
  to: number // index + 1 of last of entry in response
  total: number // total number of entries returned
}

// internal interfaces
export interface LaraPagination {
  currentPage: number
  lastPage: number
  perPage: number
  from: number
  to: number
  total: number
}

export const buildLaraConfig = (params: { token: string }): { headers: { Authorization: string } } => {
  return {
    headers: {Authorization: `Bearer ${params.token}`},
  }
}

export const buildLaraPageParams = (params: {
  pageSize: number
  pageNumber?: number
}): { pageSizeParam: string; pageNumberParam: string } => {
  const {pageSize, pageNumber = 1} = params
  const pageSizeParam = `page[size]=${pageSize}`
  const pageNumberParam = `page[number]=${pageNumber}`
  return {pageSizeParam, pageNumberParam}
}

export const mapAPILaraPaginationToLaraPagination = (api: APILaraPagination): LaraPagination => {
  return {
    currentPage: api.current_page,
    lastPage: api.last_page,
    perPage: api.per_page,
    from: api.from,
    to: api.to,
    total: api.total,
  }
}

export const buildLaraStringFilter = (params: { key?: string, value?: string | number, encode?: boolean }): string => {
  const {key, value, encode = false } = params
  if (encode) {
    return key && value ? `&filter[${key}]=${encodeURIComponent(value)}` : ''
  }
  return key && value ? `&filter[${key}]=${value}` : ''
}

export const hasMore = (params: { pagination: LaraPagination }): boolean => {
  const {pagination} = params
  return pagination.lastPage > pagination.currentPage
}
