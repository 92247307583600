import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import { APIOrderCreateItem, OrderProduct } from '../../data/orders/interfaces'
import { useAppSelector } from '../../hooks'
import {
  reduceRemoveProductFromOrder,
  reduceSetSingleOrderQuantityByProductId,
  selectSelectedOrderQuantitiesByProductId,
  selectSelectedProducts,
} from '../../data/orders/slice'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { calculateOrderTotal } from '../../data/orders/utils'
import { UseViewSize } from '../../core/UseViewSize'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { ReleaseInventoryOrders } from '../../mini-lib/flags/Release'
import { OrderAddressForm } from './OrderAddressForm'
import { has } from "lodash";

export const OrderForm = (props: any) => {
  const { isMobile } = UseViewSize()
  const products = useAppSelector(selectSelectedProducts)
  return (
    <Flex w="100%" direction="row" justifyContent="space-between" gridGap="24px" flexWrap="wrap">
      <OrderAccordions products={products ? products : []} />
      <Box w={isMobile ? '100%' : '50%'}>
        <OrderTotalBox />
        <Box mt={5}><OrderAddressForm onSubmit={props.onSubmit} formRef={props.addressFormRef} /></Box>
      </Box>
    </Flex>
  )
}

export const OrderTotalBox = () => {
  const quantitiesByProductId = useAppSelector(selectSelectedOrderQuantitiesByProductId)
  const total = calculateOrderTotal(quantitiesByProductId)
  return (
    <Flex direction="column">
      <Flex direction="row" justifyContent="space-between" p="10px" borderBottom="1px solid" borderColor={'#00113999'}>
        <Text fontSize="20px">ESTIMATED TOTAL</Text>
        <Text>${total}</Text>
      </Flex>

      <Flex direction="column" p="10px">
        <Box h="20px" />
        <Text fontStyle="italic" fontSize="13px">
          If your costs don't match your distributor's prices you need to update your product prices. Shipping and taxes
          not included.
        </Text>
      </Flex>
    </Flex>
  )
}

export const OrderAccordions = (props: { products: OrderProduct[] }) => {
  const { products } = props
  const { isMobile } = UseViewSize()
  return (
    <Box w={isMobile ? '100%' : '45%'}>
      <Box h="24px" />
      <Accordion allowToggle defaultIndex={0}>
        {/*<AccordionItem border={'0px'}>*/}
        {/*  <h2>*/}
        {/*    <AccordionButton h="60px" borderRadius="8px" bg="#EEEFFD" _hover={{ bg: '#EEEFFD' }}>*/}
        {/*      <Box flex="1" textAlign="left">*/}
        {/*        DELIVERY ADDRESS*/}
        {/*      </Box>*/}
        {/*      <AccordionIcon />*/}
        {/*    </AccordionButton>*/}
        {/*  </h2>*/}
        {/*  <AccordionPanel pb={4}>*/}
        {/*    <Box h="10px" />*/}
        {/*    <Text color="rgba(0, 17, 57, 0.6)" fontSize="14px">*/}
        {/*      Salon Name*/}
        {/*    </Text>*/}
        {/*    <Box h="10px" />*/}
        {/*    <Input variant="round" placeholder="Please Enter Salon Name" />*/}
        {/*    /!* ----- *!/*/}
        {/*    <Box h="20px" />*/}
        {/*    <Text color="rgba(0, 17, 57, 0.6)" fontSize="14px">*/}
        {/*      Country*/}
        {/*    </Text>*/}
        {/*    <Box h="10px" />*/}
        {/*    <Input variant="round" placeholder="Please Enter Country" />*/}
        {/*    /!* ----- *!/*/}
        {/*    <Box h="20px" />*/}
        {/*    <Text color="rgba(0, 17, 57, 0.6)" fontSize="14px">*/}
        {/*      State/Province*/}
        {/*    </Text>*/}
        {/*    <Box h="10px" />*/}
        {/*    <Input variant="round" placeholder="Please Enter State/Province" />*/}
        {/*    /!* ----- *!/*/}
        {/*    <Box h="20px" />*/}
        {/*    <Text color="rgba(0, 17, 57, 0.6)" fontSize="14px">*/}
        {/*      Postal/ZIP Code*/}
        {/*    </Text>*/}
        {/*    <Box h="10px" />*/}
        {/*    <Input variant="round" placeholder="Please Enter Postal/ZIP Code" />*/}
        {/*    /!* ----- *!/*/}
        {/*    <Box h="20px" />*/}
        {/*    <Text color="rgba(0, 17, 57, 0.6)" fontSize="14px">*/}
        {/*      Address*/}
        {/*    </Text>*/}
        {/*    <Box h="10px" />*/}
        {/*    <Input variant="round" placeholder="Please Enter Address" />*/}
        {/*    /!* ----- *!/*/}
        {/*    <Box h="20px" />*/}
        {/*    <Text color="rgba(0, 17, 57, 0.6)" fontSize="14px">*/}
        {/*      City*/}
        {/*    </Text>*/}
        {/*    <Box h="10px" />*/}
        {/*    <Input variant="round" placeholder="Please Enter City" />*/}
        {/*  </AccordionPanel>*/}
        {/*</AccordionItem>*/}
        {/*<Box h="15px" />*/}
        {/* ----------PRODUCTS------- */}
        <AccordionItem border="none">
          <AccordionButton h="60px" borderRadius="8px" bg="#EEEFFD" _hover={{ bg: '#EEEFFD' }}>
            <Box flex="1" textAlign="left">
              PRODUCTS
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <OrderProductCard products={products ? products : []} />
        </AccordionItem>
      </Accordion>
    </Box>
  )
}

export const OrderProductCard = (props: { products: OrderProduct[] }) => {
  const { products } = props
  const params = UseQueryParams()
  const releaseInventoryOrders = ReleaseInventoryOrders()
  const pageType = params.get('pageType')
  const isInventoryOrderPage = pageType === 'inventory'
  const showInventoryOrder = Boolean(releaseInventoryOrders && isInventoryOrderPage)
  const orderQuantitiesByProductId = useAppSelector(selectSelectedOrderQuantitiesByProductId)
  return (
    <AccordionPanel p={0}>
      <Box h="24px" />
      {products &&
        products.length > 0 &&
        products.map((product) => {
          return (
            <Box key={product.id}>
              {showInventoryOrder ?

                <InventoryOrderProductTile data={product} quantitiesById={orderQuantitiesByProductId}/> :
                <OrderProductTile data={product} quantitiesById={orderQuantitiesByProductId}/>}

              <Box h="15px" />
            </Box>
          )
        })}
      {products && products.length === 0 && <EmptyBox h="200px" content="Please add some products" />}
    </AccordionPanel>
  )
}

export const OrderProductTile = ( props: { data: OrderProduct, quantitiesById: { [key: string]: APIOrderCreateItem }
} ) => {
  const { data, quantitiesById } = props
  const dispatch = useDispatch()
  const [localQuantity, setLocalQuantity] = useState(Math.ceil(data.totalContainersUsed))
  const stateQuantity = has(quantitiesById, data.id) ? quantitiesById[data.id].order_item_quantity : 0
  useEffect(() => {
    setLocalQuantity(stateQuantity)
  }, [stateQuantity])

  return (
    <>
      <Flex direction="row" justify="space-between" align="center">
        <Flex direction="column" fontSize="14px">
          <Text>{data.type}</Text>
          <Text color="text.secondary">
            {data.brand} - {data.category}
          </Text>
          <Text color="brand.lavender.500">Used: {data.totalContainersUsed.toFixed(2)} containers </Text>
        </Flex>

        <Flex>
          <NumberInput
            w="150px"
            min={0}
            value={localQuantity}
            onChange={(updatedNumber) => {
              setLocalQuantity(parseInt(updatedNumber))
              const orderQuantity: APIOrderCreateItem = {
                product_id: parseInt(data.id),
                estimated_unit_cost: data.estWholesalePrice,
                order_item_quantity: parseInt(updatedNumber),
              }
              dispatch(reduceSetSingleOrderQuantityByProductId(orderQuantity))
            }}
            colorScheme="brand.lavender"
          >
            <NumberInputField
              justifyContent="center"
              textAlign="center"
              alignContent="center"
              w="100%"
              borderRadius="50px"
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Button variant="text" w="10px" ml="0px">
            <MaterialIcon
              name="delete"
              onClick={() => {
                const orderQuantity: APIOrderCreateItem = {
                  product_id: parseInt(data.id),
                  estimated_unit_cost: data.estWholesalePrice,
                  order_item_quantity: 0,
                }
                setLocalQuantity(0)
                dispatch(reduceSetSingleOrderQuantityByProductId(orderQuantity))
                dispatch(reduceRemoveProductFromOrder(data))
              }}
            />
          </Button>
        </Flex>
      </Flex>
    </>
  )
}

export const InventoryOrderProductTile = (props: { data: OrderProduct, quantitiesById: { [key: string]: APIOrderCreateItem }}) => {
  const { data, data: { onHand, target }, quantitiesById } = props
  const dispatch = useDispatch()
  const [localQuantity, setLocalQuantity] = useState(Math.ceil(data.totalContainersUsed))
  const stateQuantity = has(quantitiesById, data.id) ? quantitiesById[data.id].order_item_quantity : 0
  useEffect(() => {
    setLocalQuantity(Math.ceil(stateQuantity))
  }, [stateQuantity])

  return (
    <>
      <Flex direction="row" justify="space-between" align="center">
        <Flex direction="column" fontSize="14px" width='250px'>
          <Text>{data.type}</Text>
          <Text color="text.secondary">
            {data.brand} - {data.category}
          </Text>
        </Flex>
        <Flex>
          <NumberInput
            w="150px"
            min={0}
            value={localQuantity}
            onChange={(updatedNumber) => {
              const quantity = updatedNumber === '' ? 0 : parseInt(updatedNumber)
              setLocalQuantity(quantity)

              const orderQuantity: APIOrderCreateItem = {
                product_id: Number(data.id),
                estimated_unit_cost: data.estWholesalePrice,
                order_item_quantity: quantity,
              }
              dispatch(reduceSetSingleOrderQuantityByProductId(orderQuantity))
            }}
            colorScheme="brand.lavender"
          >
            <NumberInputField
              justifyContent="center"
              textAlign="center"
              alignContent="center"
              w="100%"
              borderRadius="50px"
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Button variant="text" w="10px" ml="0px">
            <MaterialIcon
              name="delete"
              onClick={() => {
                const orderQuantity: APIOrderCreateItem = {
                  product_id: Number(data.id),
                  estimated_unit_cost: data.estWholesalePrice,
                  order_item_quantity: 0,
                }
                setLocalQuantity(0)
                dispatch(reduceSetSingleOrderQuantityByProductId(orderQuantity))
                dispatch(reduceRemoveProductFromOrder(data))
              }}
            />
          </Button>
        </Flex>
      </Flex>
      <Flex direction="row" justifyContent='flex-start' fontSize="14px">
        <Text color="brand.lavender.500" width='150px'>On hand: {onHand}</Text>
        <Text color="brand.lavender.500">Target: {target}</Text>
      </Flex>
    </>
  )
}
