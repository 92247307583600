import { useField } from 'formik'
import React from 'react'
import { Box, Button, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react'
import { FormikError } from './FormikError'
import { COLORS } from '../theme/colors'

export const FormikPasswordControl = ({ label, isDisabled, ...props }) => {
  const [field, meta] = useField(props as any)
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)
  const hasError = !!(meta.touched && meta.error)
  return (
    <Box>
      <Text textAlign="left" pb="6px" color={isDisabled ? 'shades.neutral.400' : ''}>
        {label}
      </Text>

      <InputGroup size="md" id="password" {...field} {...props}>
        <Input
          defaultValue={props.defaultValue || ''}
          borderRadius="100px"
          isDisabled={isDisabled}
          id={props.name}
          name={props.name}
          type={show ? 'text' : 'password'}
          placeholder="Enter password"
          borderColor={hasError ? COLORS.danger : COLORS.shades_neutral_200}
          borderWidth={hasError ? '2px' : '1px'}
        />
        <InputRightElement w="50px" mr="12px">
          <Button size="sm" h="24px" onClick={handleClick}>
            {show ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormikError showError={hasError} errorText={meta.error} />
    </Box>
  )
}
