import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { UseViewSize } from '../../core/UseViewSize'
import { stringNumberSort } from '../../mini-lib/utils/sorting'
import { Supply } from '../../data/supplies/interfaces'
import { DEFAULT_SUPPLY_NAME } from '../../data/supplies/constants'
import { SupplyAddCell, SupplyNameCell, SupplyPriceCell } from './SupplyTableCells'
import { BRAND_THEMES, ColorForMode } from '../../theme'
import { TableCell } from '../../mini-lib/table/Table'
import { HelpPopover } from '../start-guide/common-components/HelpPopover'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { getHelpStepIndex } from '../../data/start-guide/utils'

export const SupplyTable = (props: { supplies: Supply[] }) => {
  const { supplies } = props
  const { isMobile } = UseViewSize()
  const supplyTheme = BRAND_THEMES.linen
  return (
    <Flex ml={isMobile ? '12px' : ''} isTruncated={true} overflow="scroll" width="2000px" maxWidth="100%">
      <Box>
        {/* table header */}
        <Flex gridGap="24px">
          <TableCell />
          <TableCell>
            <Flex h="100%" align="end" justify="flex-end" color={ColorForMode('secondary-text')} pr={'12px'}>
              price
            </Flex>
          </TableCell>
        </Flex>
        {/* table body */}
        {stringNumberSort(supplies, 'name', DEFAULT_SUPPLY_NAME).map((supply, index) => {
          return (
            <Flex key={supply.id} gridGap="24px">
              <TableCell>
                <SupplyNameCell supply={supply} theme={supplyTheme} />
              </TableCell>
              <TableCell>
                <HelpPopover
                  guideTypes={[CHECKLIST_CODES.addAddons]}
                  description="Click into the cell to add or edit the price"
                  stepIndex={getHelpStepIndex(index, 0)}
                >
                  {/* note: this box HAS to be here to preserve the reference the popover trigger needs */}
                  <Box>
                    <SupplyPriceCell supply={supply} />
                  </Box>
                </HelpPopover>
              </TableCell>
            </Flex>
          )
        })}
        {/* table footer actions */}
        <TableCell>
          <HelpPopover
            description="Add more items by clicking here."
            stepIndex={1}
            guideTypes={[CHECKLIST_CODES.addAddons]}
          >
            {/* note: this box HAS to be here to preserve the reference the popover trigger needs */}
            <Box>
              <SupplyAddCell theme={supplyTheme} />
            </Box>
          </HelpPopover>
        </TableCell>
      </Box>
    </Flex>
  )
}
