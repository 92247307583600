import React from 'react'
import { UseCurrentUser } from '../../core/hooks/UseCurrentUser'
import { generatePath, Redirect } from 'react-router-dom'
import { Loading } from '../loading/Loading'

import { ROUTES } from "../../appRoutes";

export const RedirectWithSalonContext = ({ match }) => {
  const { loadingUser, user } = UseCurrentUser()
  const salonId = user?.currentSalonContext?.salonId
  if (loadingUser) {
    return <Loading h="400px" />
  }
  if (user && salonId) {
    return <Redirect to={`/salon/${salonId}${match.url}`} />
  }
  return <Redirect to={generatePath(ROUTES.login) + `?redirect-url=${match.url}`} />
}
