import { Box, Circle, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '../theme/colors'
import { MaterialIcon } from '../icons/MaterialIcon'
import React, { useState } from 'react'
import { UseViewSize } from '../../core/UseViewSize'

export const FilterBar = (props: {
  theme?: 'lavender' | 'peach' | 'linen' | 'midnight' | 'skylight'
  variant?: 'default'
  search?: JSX.Element
  filters?: JSX.Element
  onClear?: () => void
  numFiltersActive?: number
}) => {
  const { isMobile } = UseViewSize()
  const { theme = 'lavender', variant = 'default', numFiltersActive = 0, search, filters, onClear } = props

  const [showFilters, setShowFilters] = useState(!isMobile)
  const toggleFilters = () => setShowFilters(!showFilters)

  const themeLighthex = theme ? COLORS[`${theme}_100`] : COLORS.lavender_100
  const themeDarkhex = theme ? COLORS[`${theme}_500`] : COLORS.lavender_500

  return (
    <>
      {variant === 'default' && (
        <Flex justify="space-between" wrap="wrap" gridRowGap="12px">
          <Flex align="center" width={isMobile ? '100%' : ''} maxWidth="100%" gridGap={isMobile ? '4px' : '12px'}>
            {search}
            {isMobile && (
              <>
                {numFiltersActive === 0 && (
                  <Circle
                    cursor="pointer"
                    bg={themeLighthex}
                    p="6px"
                    onClick={toggleFilters}
                    size="40px"
                  >
                    <MaterialIcon name="filter_list" />
                  </Circle>
                )}
                {numFiltersActive > 0 && onClear && <ClearAll onClick={onClear} themeDarkhex={themeDarkhex} />}
              </>
            )}
          </Flex>
          {showFilters && (
            <Flex
              align="center"
              gridColumnGap={isMobile ? '' : '12px'}
              gridRowGap='12px'
              width={isMobile ? '100%' : ''}
              justify="space-between"
              wrap='wrap'
            >
              {!isMobile && numFiltersActive === 0 && onClear && <Box minW="65px">Filter By:</Box>}
              {!isMobile && numFiltersActive > 0 && onClear && (
                <ClearAll onClick={onClear} themeDarkhex={themeDarkhex} />
              )}
              {filters}

            </Flex>
          )}
        </Flex>
      )}
    </>
  )
}

const ClearAll = ({ onClick, themeDarkhex }) => {
  const { isMobile } = UseViewSize()
  return (
    <Text color={themeDarkhex} cursor="pointer" onClick={onClick}>
      {isMobile ? 'Clear' : 'Clear Filters'}
    </Text>
  )
}
