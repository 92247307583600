import React, { useState } from 'react'
import { UseViewSize } from '../../../core/UseViewSize'
import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { Product } from '../../../data/products/interfaces'
import { calculateStockCost } from '../../../data/inventory/utils'
import { SalonScaleProductIcon } from '../../../mini-lib/icons/SalonScaleProductIcon'
import { calculateMarkupNumber } from '../../../data/products/utils'
import { ReleaseUnitsSupport } from "../../../mini-lib/flags/Release";

export const InventorySnapshotList = (props: { products: Product[] }) => {
  const { products } = props
  const filteredProducts = products
  const { isDesktop, isTablet } = UseViewSize()
  const [numRowsToShow, setNumRowsToShow] = useState(20)
  return (
    <>
      {(isDesktop || isTablet) && <TitleRow />}
      {products.slice(0, numRowsToShow).map((count) => {
        return <Row key={count.id} product={count} />
      })}
      {filteredProducts && filteredProducts.length > numRowsToShow && (
        <Flex justify="center">
          <Button variant="round-ghost-lower" onClick={() => setNumRowsToShow(numRowsToShow + 20)}>
            View More
          </Button>
        </Flex>
      )}
    </>
  )
}

export const TitleRow = () => {
  const { isDesktop, isTablet } = UseViewSize()
  return (
    <>
      <Flex p="24px 0" gridGap="12px">
        <Flex w={isDesktop || isTablet ? '350px' : '66%'}>
          <Text variant="callout" color={COLORS.text_tertiary}>
            Product
          </Text>
        </Flex>
        {isDesktop && (
          <Flex w="150px">
            <Text variant="callout" color={COLORS.text_tertiary}>
              Brand/Line
            </Text>
          </Flex>
        )}
        <Flex w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Category
          </Text>
        </Flex>
        <Flex justify="center" w="100px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            On Hand
          </Text>
        </Flex>
        <Flex justify="center" w="100px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Target
          </Text>
        </Flex>
        <Flex justify="center" w="100px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            {isDesktop ? 'Cost / Unit' : 'Cost'}
          </Text>
        </Flex>
        <Flex justify="center" w="100px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            {isDesktop ? 'Stock Cost' : 'Cost'}
          </Text>
        </Flex>
        <Flex justify="center" w="100px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Mark up
          </Text>
        </Flex>
      </Flex>
      <Divider />
    </>
  )
}
export const Row = (props: { product: Product }) => {
  const { product } = props
  const { isDesktop, isTablet } = UseViewSize()
  const releaseUnitsSupport = ReleaseUnitsSupport();
  const staticUnit = 'g or ml'
  const units = releaseUnitsSupport ? product?.units ?? staticUnit : staticUnit
  return (
    <Box p="12px 0">
      <Flex p="12px 0" gridGap="12px">
        <Box w={isDesktop || isTablet ? '350px' : '66%'}>
          <Flex gridGap="12px">
            <SalonScaleProductIcon name={product.category} />
            <Box>
              <Text variant="body">{product.type}</Text>
              {isTablet && (
                <Text>
                  {product.line.name} • <span style={{ color: COLORS.text_secondary }}>{product.vendor.name}</span>
                </Text>
              )}

              <Text variant="caption2" fontWeight="normal" color={COLORS.text_secondary}>
                {product.size}{units}
              </Text>
            </Box>
          </Flex>
        </Box>
        {isDesktop && (
          <Box w="150px">
            <Text>{product.line.name}</Text>
            <Text variant="caption2" fontWeight="normal" color={COLORS.text_secondary}>
              {product.vendor.name}
            </Text>
          </Box>
        )}
        {(isDesktop || isTablet) && <Box w="150px">{product.category}</Box>}
        {(isDesktop || isTablet) && (
          <Flex justify="center" w="100px">
            {product.inventory.quantityOnHand}
          </Flex>
        )}
        {(isDesktop || isTablet) && (
          <Flex justify="center" w="100px">
            {product.inventory.maxStockLevel}
          </Flex>
        )}
        {(isDesktop || isTablet) && (
          <Flex w="100px" justify="center">
            {product.inventory.cost}
          </Flex>
        )}
          <Flex w="100px" justify="center">
            ${calculateStockCost(product).toFixed(2)}
          </Flex>
        {(isDesktop || isTablet) && (
          <Flex w="100px" justify="center">
            {calculateMarkupNumber(product)}%
          </Flex>
        )}
      </Flex>
      <Divider />
    </Box>
  )
}
