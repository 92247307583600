/////////////// api.ts
//
//

import axios from 'axios'
import { Dispatch } from '@reduxjs/toolkit'

import { reduceListMasterProducts, reduceListTrialPricing } from './slice'
import {
  mapAPIMasterProductsLaraToMasterProducts,
  mapAPIMasterProductsToMasterProducts,
  mapAPIMasterProductTrialPricesToMasterProductTrialPrices,
} from './mappers'
import { APIPagedResponse } from '../../core/pagination'
import { GetServerBaseUrl } from '../../env'
import { APIMasterProductUpcCreate, MasterProduct, MasterProductTrialPrice } from './interfaces'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { Product } from '../products/interfaces'
import { mapAPIColorsToProducts, mapAPIProductToProducts } from '../products/mappers'
import { reduceListProduct } from '../products/slice'
import {LOADING_MASTER_PRODUCTS, LOADING_TRIAL_PRICING} from './constants'
import { reduceListExtension } from "../extensions/slice";
import {
  APIPagedLaraResponse,
  buildLaraConfig,
  buildLaraPageParams,
  buildLaraStringFilter
} from "../../mini-lib/lara/lara-utils";
import { stripNone } from "../../core/collections/collections";
import {toast} from "react-toastify";

// apis
//
//
export const apiGetTrialPricing = (params: {
  token: string
  masterProductIds: number[]
}): Promise<MasterProductTrialPrice[]> => {
  const { token, masterProductIds } = params
  const masterProductIdsParam = 'filter[master_product_id]=' + masterProductIds.join(',')
  const url = `${GetServerBaseUrl('v3', 'lara')}/trial-pricing/?token=${token}&${masterProductIdsParam}`
  return axios
    .get(url)
    .then((response: { data: APIPagedResponse }) => {
      return mapAPIMasterProductTrialPricesToMasterProductTrialPrices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiListMasterProducts = (params: { token: string; search?: string }): Promise<MasterProduct[]> => {
  const { token, search } = params
  const searchParam = search ? `&q=${encodeURIComponent(search)}` : ''
  const url = `${GetServerBaseUrl()}/master-products/?token=${token}${searchParam}&disable_paging=true`
  return axios
    .get(url)
    .then((response: { data: APIPagedResponse }) => {
      return mapAPIMasterProductsToMasterProducts(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiListMasterProductsLara = (params: { token: string; search?: string }): Promise<MasterProduct[]> => {
  const { token, search } = params
  const searchFilter = buildLaraStringFilter({key: 'search', value: search, encode: true})
  const config = buildLaraConfig({token})
  const { pageSizeParam } = buildLaraPageParams({ pageSize: 10000, pageNumber: 1 })
  const url = `${GetServerBaseUrl('v3', 'lara')}/mastercolors?${searchFilter}&${pageSizeParam}`
  return axios
    .get(url, config)
    .then((response: { data: APIPagedLaraResponse }) => {
      return mapAPIMasterProductsLaraToMasterProducts(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiAddMasterProducts = (params: {
  token: string
  salonId: number
  masterProductIds: number[]
}): Promise<Product[]> => {
  const { token, salonId, masterProductIds } = params
  const url = `${GetServerBaseUrl()}/products/?token=${token}`
  const body = {
    token,
    salon_id: salonId,
    master_product_ids: masterProductIds,
  }
  return axios
    .post(url, body)
    .then((response: { data: APIPagedResponse }) => {
      return mapAPIProductToProducts(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiAddMasterColorsLara = (params: {
  token: string
  salonId: number
  masterProductIds?: number[]
  vendorIds?: number[]
  lineIds?: number[]
  autoPriceMarkup?: number | null
}): Promise<Product[]> => {
  const { token, salonId, masterProductIds = null, lineIds= null, vendorIds = null, autoPriceMarkup = 100 } = params
  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/colors`
  const body = {
    auto_price_markup: autoPriceMarkup,
    master_product_ids: masterProductIds,
    vendor_ids: vendorIds,
    line_ids: lineIds,
  }
  const bodyNoNull = stripNone(body)
  return axios
    .post(url, bodyNoNull, config)
    .then((response: { data: APIPagedResponse }) => {
      return mapAPIColorsToProducts(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiSyncMasterColorsToExistingSalonsLara = (params: {
  token: string
  masterProductIds: number[]
}): Promise<any> => {
  const { token, masterProductIds } = params
  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl('v3', 'lara')}/mastercolors/sync-to-salons/`
  const body = {
    master_color_ids: masterProductIds,
  }
  const bodyNoNull = stripNone(body)
  return axios
    .post(url, bodyNoNull, config)
    .then(response => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateMasterProductUpcs = (params: {
  token: string
  models: APIMasterProductUpcCreate[]
}): Promise<MasterProduct[]> => {
  const { token, models } = params
  const url = `${GetServerBaseUrl()}/master-products-upcs/?token=${token}`
  const body = { master_product_upcs: models }
  return axios
    .post(url, body)
    .then((response: { data: any }) => {
      return mapAPIMasterProductsToMasterProducts(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchCreateMasterProductUpcs = (params: { token: string; models: APIMasterProductUpcCreate[] }) => {
  return (dispatch: Dispatch) => {
    return apiCreateMasterProductUpcs(params).then((resp) => {
      dispatch(reduceListMasterProducts(resp))
    })
  }
}

export const dispatchGetTrialPricing = (params: { token: string; masterProductIds: number[] }) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: LOADING_TRIAL_PRICING, state: true }))
    return apiGetTrialPricing(params).then((resp) => {
      dispatch(reduceListTrialPricing(resp))
      dispatch(reduceSetLoadingState({ name: LOADING_TRIAL_PRICING, state: false }))
    })
  }
}

export const dispatchListMasterProducts = (params: { token: string; search?: string; loadingName: string }) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: params.loadingName, state: true }))
    return apiListMasterProducts(params).then((resp) => {
      dispatch(reduceListMasterProducts(resp))
      dispatch(reduceSetLoadingState({ name: params.loadingName, state: false }))
    })
  }
}

export const dispatchAddMasterProducts = (params: {
  token: string
  salonId: number
  masterProductIds: number[]
  loadingName: string
  type?: 'color' | 'extension'
}) => {
  return (dispatch: Dispatch) => {
    const {type = 'color'} = params
    
    dispatch(reduceSetLoadingState({ name: params.loadingName, state: true }))
    return apiAddMasterProducts(params).then((resp) => {
      if (type === 'color') {
        dispatch(reduceListProduct(resp))
      } else {
        dispatch(reduceListExtension(resp))
      }
      dispatch(reduceSetLoadingState({ name: params.loadingName, state: false }))
    })
  }
}

export const dispatchListMasterProductsLara = (params: { token: string; search?: string; loadingName: string }) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: params.loadingName, state: true }))
    return apiListMasterProductsLara(params).then((resp) => {
      dispatch(reduceListMasterProducts(resp))
      dispatch(reduceSetLoadingState({ name: params.loadingName, state: false }))
    })
  }
}

export const dispatchSyncMasterColorsToExistingSalonsLara = (params: { token: string, masterProductIds: number[] }) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: LOADING_MASTER_PRODUCTS, state: true }))
    return apiSyncMasterColorsToExistingSalonsLara(params).then((resp) => {
      dispatch(reduceSetLoadingState({ name: LOADING_MASTER_PRODUCTS, state: false }))
      const numProductsCreated = resp && resp.data && resp.data && resp.data.length > 0 && resp.data[0]?.created_colors
      if (numProductsCreated) {
        toast.success(`Synced to ${numProductsCreated} salon`)
      }
      if (numProductsCreated === 0) {
        toast.success(`Already synced to all salons`)
      }
    })
  }
}

export const dispatchAddMasterColorsLara = (params: {
  token: string
  salonId: number
  masterProductIds?: number[]
  lineIds?: number[]
  vendorIds?: number[]
  autoPriceMarkup?: number | null
  loadingName: string
}) => {
  return (dispatch: Dispatch) => {

    dispatch(reduceSetLoadingState({ name: params.loadingName, state: true }))
    return apiAddMasterColorsLara(params).then((resp) => {
        dispatch(reduceListProduct(resp))
        dispatch(reduceSetLoadingState({ name: params.loadingName, state: false }))
    })
  }
}
