import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectChecklistItem, selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems, dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { sgInventoryStepCodes } from './SGIndex/SGInventoryIndex'
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGInventoryOrdersIntroPage = () => {
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, sgInventoryStepCodes))
  const currentCode = CHECKLIST_CODES.inventoryOrders
  const currentStep = useAppSelector((state) => selectChecklistItem(state, currentCode))
  const maybeUpdateChecklist = () => {
    if (!currentStep.completed) {
      dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: currentCode}))
    }
  }
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="implementation"
          title="Orders"
          description={descriptionText}
          video={VIDEO_MAP.inventoryOrders}
          actions={
            <SGActionButtons
              action={{
                label: 'Next',
                url: generatePath(ROUTES.sgInventoryAddingStockIntro, { salonId }),
                onClick: maybeUpdateChecklist
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={sgInventoryStepCodes.length} stepsComplete={stepsCompleted || 0} />}
          imgPath={require('../../assets/start-guide/orders.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `The order feature is a real wow factor, and we can't wait for you to see it! We like to say it changes ordering from "Just in Case" to a much more manageable "Just in Time". Plus, if you use a 100% markup, it literally pays for itself.

Based on your exact usage, SalonScale will create an order form for your products used. Download this to send to your sales rep, or as reference to easily plug in your orders online. No more ripping tabs, writing on napkins or scrambling to get that order in.
`
