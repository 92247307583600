import { InventoryCount, InventoryCountItem } from '../../../data/inventory/interfaces'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import React from 'react'
import { SalonScaleProductIcon } from '../../../mini-lib/icons/SalonScaleProductIcon'
import { UseViewSize } from '../../../core/UseViewSize'
import { InventoryQuantityField } from '../common/InventoryQuantityField'
import { centsToDollars } from '../../../core/money/utils'
import { ReleaseUnitsSupport } from "../../../mini-lib/flags/Release";

export const ManualTitle = (props: { count: InventoryCount }) => {
  return (
    <>
      <Flex justify="space-between" p="24px 0">
        <Box w="350px" pl="12px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Product
          </Text>
        </Box>
        <Box w="150px">
          <Text variant="callout" color={COLORS.text_tertiary}>
            Brand/Line
          </Text>
        </Box>
        <>
          <Box w="150px">
            <Text variant="callout" color={COLORS.text_tertiary}>
              Staff
            </Text>
          </Box>
          <Box w="150px">
            <Text variant="callout" color={COLORS.text_tertiary}>
              Quantity Added
            </Text>
          </Box>
        </>
      </Flex>
      <Divider />
    </>
  )
}
export const ManualItemRow = (props: {
  count: InventoryCount
  item: InventoryCountItem
  quantity: string | number | undefined
  onUpdateQuantity: any
  setQuantity: any
}) => {
  const { item, count, quantity, setQuantity, onUpdateQuantity } = props
  const { isMobile } = UseViewSize()
  const isDisabled = !!count.completedAt
  const releaseUnitsSupport = ReleaseUnitsSupport();
  const staticUnit = 'g or ml'
  const units = releaseUnitsSupport ? item.product?.units ?? staticUnit : staticUnit
  return (
    <Box p="12px 0">
      <Flex justify="space-between" p="12px 0" align="center">
        <Box w={!isMobile ? '350px' : ''}>
          <Flex gridGap="12px" align="center">
            <SalonScaleProductIcon name={item.product.category} />
            <Box>
              <Text variant="body">{item.product.type}</Text>
              <Text variant="caption2" fontWeight="normal" color={COLORS.text_secondary}>
                {item.product.size}{units} • ${centsToDollars(item.product.salonPurchasePriceCents)}
              </Text>
            </Box>
          </Flex>
        </Box>
        {!isMobile && (
          <>
            <Flex w="150px" direction="column" justify="center">
              <Text>{item.product.vendor}</Text>
              <Text color={COLORS.shades_neutral_500} variant="footnote">
                {item.product.line}
              </Text>
            </Flex>
            <Flex align="center" w="150px">
              {count.assignedUser.fullName}
            </Flex>
          </>
        )}
        <InventoryQuantityField
          item={item}
          quantity={quantity}
          onUpdateQuantity={onUpdateQuantity}
          setQuantity={setQuantity}
          isDisabled={isDisabled}
        />
      </Flex>
      <Divider />
    </Box>
  )
}
