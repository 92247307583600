import Smartlook from 'smartlook-client'
import { useAppSelector } from "../../hooks";
import { selectLoggedInUser } from "../../data/user/slice";
import { useEffect } from "react";
import {getEnvConfig} from "../../config";

export const InitializeSmartlook = () => {
  const {isProd} = getEnvConfig()
  if (isProd && Smartlook && !Smartlook.initialized()) {
    Smartlook.init('55d626fd9c809c5697cb580d0d9d710a2fc6ac16')

  }
}

export const InitializeSmartlookUser = async () => {
  const user = useAppSelector(selectLoggedInUser)
  const userId = user?.userId
  // const userEmail = user?.email || ''
  // const userName = user ? `${user.firstName} ${user.lastName}` : ''
  const salonId = user && user.currentSalonContext ? user.currentSalonContext.salonId : ''
  const salonName = user && user.currentSalonContext ? user.currentSalonContext.salonName : ''
  const salonType = user && user.currentSalonContext ? user.currentSalonContext.salonType : ''
  const {isProd} = getEnvConfig()
  const smartlookInitialized = Smartlook && Smartlook.initialized()
  useEffect(() => {
    if (userId && isProd && smartlookInitialized) {
      Smartlook.identify(userId, {
        // name: userName,
        // email: userEmail,
        salon_id: salonId,
        salon_name: salonName,
        salon_type: salonType
      })
    }
  }, [
    // userEmail,
    // userName,
    userId,
    salonId,
    salonName,
    salonType,
    isProd,
    smartlookInitialized
  ])
}
