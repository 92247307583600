import {
  Button,
  Flex,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { reduceSetCurrentSubStepIndex, selectCurrentSubStepIndex } from '../../../data/start-guide/slice'
import { useAppSelector } from '../../../hooks'
import { useDispatch } from 'react-redux'
import { UseViewSize } from '../../../core/UseViewSize'

export const HelpPopover = (props: {
  children: ReactNode
  description: string
  stepIndex: number
  guideTypes: string[]
  placement?: PlacementWithLogical
}) => {
  const { children, placement = 'bottom', description, stepIndex, guideTypes } = props
  const dispatch = useDispatch()
  const queryParams: any = UseQueryParams()
  const guideParam = queryParams.get('guide')
  const isGuide = guideTypes.includes(guideParam)
  const currentSubStepIndex = useAppSelector(selectCurrentSubStepIndex)
  const isOpen = stepIndex === currentSubStepIndex
  const { isMobile } = UseViewSize()
  return (
    <>
      {isGuide && (
        <>
          <Popover
            returnFocusOnClose={false}
            isOpen={isOpen}
            onClose={() => {}}
            placement={isMobile ? 'bottom' : placement}
            closeOnBlur={false}
          >
            <PopoverTrigger>{children}</PopoverTrigger>
            <PopoverContent bg="brand.lavender.500" color="white">
              <PopoverArrow bg="brand.lavender.500" />
              <PopoverBody>{description}</PopoverBody>
              <PopoverFooter>
                <Flex justify="center">
                  {stepIndex > 0 && (
                    <Button
                      bg="brand.lavender.500"
                      color="white"
                      onClick={() => dispatch(reduceSetCurrentSubStepIndex(stepIndex - 1))}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    bg="brand.lavender.500"
                    color="white"
                    onClick={() => dispatch(reduceSetCurrentSubStepIndex(stepIndex + 1))}
                  >
                    Got It
                  </Button>
                </Flex>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </>
      )}
      {!isGuide && children}
    </>
  )
}
