import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Flex, Text } from '@chakra-ui/react'
import { useAppSelector } from '../../../hooks'
import { generatePath, Link, useHistory } from 'react-router-dom'
import {
  reduceSetProductFilters,
  selectInfoOnProductsWithOnHand,
  selectProductFilters,
  selectSortedFilteredProductsByCategoryThenByLineSize,
} from '../../../data/products/slice'
import { dispatchListProducts } from '../../../data/products/api'

import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { Loading } from '../../../mini-lib/loading/Loading'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { keys } from 'lodash'
import { EmptySearch } from '../../../mini-lib/empty/EmptySearch'
import { VARIANTS } from '../../../mini-lib/theme/variants'
import { EmptyPage } from '../../../mini-lib/empty/EmptyPage'
import { HelpStepCompleteDrawer } from '../../start-guide/common-components/HelpStepCompleteDrawer'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { dispatchUpdateUserChecklistItem } from '../../../data/start-guide/api'
import {
  reduceSetCurrentSubStepIndex,
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectStepsCompleted,
} from '../../../data/start-guide/slice'
import { ProductFilters } from '../filters/ProductFilters'
import { HelpModal } from '../../start-guide/common-components/HelpModal'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { EMPTY_PRODUCT_FILTERS } from '../../../data/products/constants'
import { HelpStepperV2 } from '../../start-guide-v2/HelpStepperV2'
import { sgInventoryStepCodes } from '../../start-guide-v2/SGIndex/SGInventoryIndex'
import { Gap } from '../../../mini-lib/gap/Gap'
import { ProductsByCategoryAccordion } from './ProductsByCategoryAccordion'
import { UseResetStartGuideState } from "../../start-guide/common-components/UseResetStartGuideState";
import { ProductBulkUpdateSheet } from "../products/ProductBulkUpdateSheet";
import { ConfirmPopover } from "../../../mini-lib/confirm-popover/ConfirmPopover";
import { UseViewSize } from "../../../core/UseViewSize";
import { COLORS } from "../../../mini-lib/theme/colors";
import { ReleaseLaraColors } from "../../../mini-lib/flags/Release";
import { ROUTES } from "../../../appRoutes";

export const ProductOnHandPage = (props: {showBreadCrumbs?: boolean}) => {
  const {showBreadCrumbs = true} = props
  const dispatch = useDispatch()
  const {isMobile} = UseViewSize()
  UseResetStartGuideState()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const breadcrumbs = showBreadCrumbs ? [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Set Targets' }] : []
  const filters = useAppSelector(selectProductFilters)

  const productsByCategoryThenByLineSize = useAppSelector(selectSortedFilteredProductsByCategoryThenByLineSize)
  const productsLoaded = !!productsByCategoryThenByLineSize
  const infoOnProductsWithOnHand = useAppSelector(selectInfoOnProductsWithOnHand)

  // reinitialize empty product filters on page load once
  useEffect(() => {
    dispatch(reduceSetProductFilters(EMPTY_PRODUCT_FILTERS))
    dispatch(reduceSetCurrentSubStepIndex(0))
  }, [dispatch])

  const queryParams: any = UseQueryParams()
  const guideType = queryParams.get('guide')

  const releaseLaraColors = ReleaseLaraColors()
  useEffect(() => {
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, releaseLaraColors])

  const history = useHistory()
  const currentStep = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.inventorySetOnHand))
  const previousStepLink = generatePath(ROUTES.sgInventoryTargetsIntro, { salonId })
  const nextStepLink = generatePath(ROUTES.sgInventoryStockAlertsIntro, { salonId })
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, sgInventoryStepCodes))
  return (
    <PageLayout
      variant="full"
      top={
        <>
          <HelpStepperV2
            title="Set Your Backbar On-Hand"
            titleIconName="implementation"
            currentStepNumber={1}
            stepsComplete={stepsCompleted || 0}
            totalSteps={sgInventoryStepCodes.length}
            previousStepLink={previousStepLink}
            nextStepLink={nextStepLink}
            guideType={CHECKLIST_CODES.inventorySetOnHand}
          />
        </>
      }
      header={<PageHeader title="SET YOUR BACKBAR ON-HAND" breadcrumbs={breadcrumbs} />}
      content={
        <>
          <ProductBulkUpdateSheet propertyToUpdate="on hand" checklistStepComplete={currentStep?.completed}/>
          <HelpModal guideType={CHECKLIST_CODES.inventorySetOnHand} title="Let’s Set Your BackBar On-Hand!" />

          {guideType === CHECKLIST_CODES.inventorySetOnHand && (
            <HelpStepCompleteDrawer
              guideType={CHECKLIST_CODES.inventorySetOnHand}
              title={infoOnProductsWithOnHand?.percentage === 100 ? "Way To Go! You’ve Completed Setting Your Backbar on-hand" : `Way To Go you've set up ${infoOnProductsWithOnHand?.percentage}% of your products`}
              subtitle={infoOnProductsWithOnHand?.percentage === 100 ? "You’re ready to move on to the next step!" : 'Set up all of your products to continue!'}
              actions={
                <Flex align="center" gridGap="12px">
                  {/*{stepCompleteDrawerState !== 'button' && (*/}
                  {/*  <Button*/}
                  {/*    variant="round-ghost-upper"*/}
                  {/*    colorScheme="brand.midnight"*/}
                  {/*    onClick={() => {*/}
                  {/*      dispatch(reduceSetStepCompleteDrawerState('button'))*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    Continue Editing*/}
                  {/*  </Button>*/}
                  {/*)}*/}
                  {infoOnProductsWithOnHand?.percentage === 100 ? (
                    <Link to={generatePath(ROUTES.sgInventoryStockAlertsIntro, { salonId })}>
                      <Button
                        variant="round"
                        colorScheme="brand.midnight"
                        onClick={() => {
                          dispatch(reduceSetStepCompleteDrawerState('hidden'))
                          dispatch(reduceSetCurrentSubStepIndex(0))
                          dispatch(
                            dispatchUpdateUserChecklistItem({
                              token: token,
                              checklistItemCode: CHECKLIST_CODES.inventorySetOnHand,
                            }),
                          )
                        }}
                      >
                        Next Step
                      </Button>
                    </Link>
                  ) : (
                    <>
                      <ConfirmPopover confirmText="Yes, I'm done!" title={'Are you sure?'} subtitle={`You've only set on-hand for ${infoOnProductsWithOnHand ? infoOnProductsWithOnHand?.numberCompleted : 'some'} of your products.`} onConfirm={() => {
                        dispatch(reduceSetStepCompleteDrawerState('hidden'))
                        dispatch(reduceSetCurrentSubStepIndex(0))
                        dispatch(
                            dispatchUpdateUserChecklistItem({
                              token: token,
                              checklistItemCode: CHECKLIST_CODES.inventorySetOnHand,
                            }),
                          )
                        history.push(nextStepLink)
                      }}>

                      <Button
                        variant="round-outline"
                        colorScheme="brand.midnight"
                      >
                        I'm Done {isMobile ? '' : 'Counting'}
                      </Button>
                      </ConfirmPopover>
                      <Text color={COLORS.midnight_500}>
                        {infoOnProductsWithOnHand?.numberCompleted}/{infoOnProductsWithOnHand?.totalProducts} Completed
                      </Text>
                    </>
                  )}
                </Flex>
              }
            />
          )}

          <Gap s="24px" />

          <ProductFilters showOnHandFilter={true}/>

          <Gap s="24px" />

          {/* products loaded state */}
          {productsLoaded && keys(productsByCategoryThenByLineSize).length > 0 && (
            <ProductsByCategoryAccordion
              productPropertyToUpdate={'on hand'}
              productsByCategoryThenByLineSize={productsByCategoryThenByLineSize}
            />
          )}

          {/* empty search state */}
          {productsLoaded &&
            keys(productsByCategoryThenByLineSize).length === 0 &&
            filters &&
            filters.searchText &&
            filters.searchText?.length > 0 && (
              <EmptySearch>
                <Text variant={VARIANTS.text.footnote}>No brands, lines, or products matched your search</Text>
              </EmptySearch>
            )}

          {/* empty state */}
          {productsLoaded &&
            keys(productsByCategoryThenByLineSize).length === 0 &&
            (!filters.searchText || filters.searchText?.length === 0) && (
              <EmptyPage
                title="NO PRODUCTS ADDED"
                content="Add your color lines to start using this feature in the SalonScale App!"
              />
            )}
          {/* loading state */}
          {!productsLoaded && <Loading />}
        </>
      }
    ></PageLayout>
  )
}
