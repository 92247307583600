import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { VideoData } from './constants'
import { UseViewSize } from '../../core/UseViewSize'

export const VideoModal = (props: { isOpen: boolean; onOpen: any; onClose: any; video: VideoData }) => {
  const { isOpen, onClose, video } = props
  const autoplayParam = video.id.includes('?') ? '&autoplay=1' : '?autoplay=1'
  const startTimeParam = video.startTime ? `?start=${video.startTime}` : ''
  const src = `https://www.youtube.com/embed/${video.id}${startTimeParam}${autoplayParam}`
  const { isMobile } = UseViewSize()
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{video.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <iframe
            title={src}
            style={{ width: '100%', minHeight: isMobile ? '350px' : '550px' }}
            src={src}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen={true}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
