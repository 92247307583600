import { Box, Flex } from '@chakra-ui/react'
import Lottie from 'react-lottie-player'
import pageNotFoundLottie from '../../assets/pageNotFoundLottie.json'
import { ColorForMode } from '../../theme'

export const EmptySearch = (props: { variant?: 'card' | 'clear'; children?: any }) => {
  const { children, variant = 'card' } = props
  return (
    <Box bg={variant === 'card' ? ColorForMode('card-bg') : ''}>
      <Flex justify="center" align="center" direction="column" p="24px" fontWeight="bold">
        {children}
        <Lottie loop animationData={pageNotFoundLottie} play style={{ maxWidth: '100%' }} />
      </Flex>
    </Box>
  )
}
