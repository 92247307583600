import axios from 'axios'
import { Dispatch } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import {
  reduceBulkMergeableClients,
  reduceCreateClient,
  reduceDeleteClient,
  reduceDeleteClients,
  reduceListClients,
  reduceListClientsPagination,
  reduceSelectedClientMergeableClients,
  reduceSetSelectedClientId,
  reduceUpdateClient,
  replaceListClients,
} from './slice'
import { APIClient, APIMergeablClientsBulkRequest, APIMergeableClientSet, Client } from './interfaces'
import {
  mapAPIClientsToClients,
  mapAPIClientToClient,
  mapAPIMergeableClientsToClients,
  mapClientsToAPIClients,
} from './mappers'
import { APIPagedResponse } from '../../core/pagination'
import { map } from 'lodash'
import { GetServerBaseUrl } from '../../env'
import { buildBulkMergeClientRequest } from './utils'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { CLIENTS_LOADING_CONSTANT, CREATING_CLIENT_CONSTANT } from './constants'
import {
  APIPagedLaraResponse,
  buildLaraPageParams,
  LaraPagination,
  mapAPILaraPaginationToLaraPagination,
} from '../../mini-lib/lara/lara-utils'
import { handleError } from "../../mini-lib/utils/errors";

// apis
//
//
export const apiListClient = (
  token: string,
  salon_id: number,
  pageNumber = 1,
  searchText?: string,
): Promise<{ pagination: LaraPagination; models: Client[] }> => {
  const searchTextParam = searchText ? `&filter[name]=${searchText}` : ''
  const { pageSizeParam, pageNumberParam } = buildLaraPageParams({ pageSize: 100, pageNumber })
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salon_id}/clients/?salon_id=${salon_id}&${pageSizeParam}&${pageNumberParam}${searchTextParam}`
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  return axios
    .get(url, config)
    .then((response: { data: APIPagedLaraResponse }) => {
      return {
        pagination: mapAPILaraPaginationToLaraPagination(response.data.meta),
        models: mapAPIClientsToClients(response.data.data),
      }
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
// list of clients with exact same first name and last name
export const apiListBulkMergeableClients = (params: {
  token: string
  salonId: number
}): Promise<APIClient[][]> => {
  const { token, salonId } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/clients-duplicate/?token=${token}`
  return axios
    .get(url)
    .then((response: { data: { data: APIClient[][] } }) => {
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

// bulk merge
export const apiBulkMergeClients = (params: {
  token: string
  salonId: number
  clientSets: APIMergeableClientSet[]
}): Promise<Client[]> => {
  const { token, salonId, clientSets } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/merge/?token=${token}`
  const body: APIMergeablClientsBulkRequest = buildBulkMergeClientRequest(clientSets)
  return axios
    .post(url, { ...body, salon_id: salonId, model: 'client' })
    .then((response: { data: APIPagedResponse }) => {
      return mapAPIClientsToClients(response.data.data)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const apiUpdateClient = (params: {
  token: string
  client_id: number
  salon_id: number
  model: Client
}): Promise<Client> => {
  const { token, client_id, salon_id, model } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salon_id}/clients/${client_id}?token=${token}`
  const body = {
    first_name: model.firstName,
    last_name: model.lastName,
    phone: model.phone,
  }
  return axios
    .patch(url, body)
    .then((response: { data: { data: APIClient } })  => {
      return mapAPIClientToClient(response.data.data)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const apiCreateClient = (params: { token: string; salonId: number; model: Client }): Promise<Client> => {
  const { token, salonId, model } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/clients?token=${token}`
  const body = {
    first_name: model.firstName,
    last_name: model.lastName,
  }
  if (model.phone) {
    body['phone'] = model.phone
  }
  return axios
    .post(url, body)
    .then((response: { data: { data: APIClient } }) => {
      return mapAPIClientToClient(response.data.data)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const apiCreateClients = (params: { token: string; salonId: number; models: Partial<Client>[] }): Promise<Client[]> => {
  const { token, salonId, models } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/bulk-clients?token=${token}`
  const body = {clients: mapClientsToAPIClients(models)}
  return axios
    .post(url, body)
    .then((response: { data: { data: APIClient[] } }) => {
      return mapAPIClientsToClients(response.data.data)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const apiDeleteClient = (params: { token: string; salonId: number; model: Client }): Promise<any> => {
  const { token, salonId, model } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/clients/${model.id}/?token=${token}`
  return axios
    .delete(url)
    .then((response: any) => {
      return { ...response.data, id: model.id }
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
// get duplicate clients for a particular client
// not laravel
export const apiGetClientMergeableClients = (params: {
  token: string
  salonId: number
  model: Client
}): Promise<Client[]> => {
  const { token, model, salonId } = params
  // note: there is a typo in this url
  const url = `${GetServerBaseUrl('v2')}/clients/mergable/?token=${token}&client_id=${model.id}&salon_id=${salonId}`
  return axios
    .get(url)
    .then((response: any) => {
      return mapAPIMergeableClientsToClients(response.data.data.clients)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

// merge
// not laravel
export const apiMergeClients = (params: {
  token: string
  salonId: number
  retainedClientId: number
  clientsToMerge: Client[]
}): Promise<number[]> => {
  const { token, salonId, retainedClientId, clientsToMerge } = params
  const body = { salon_id: salonId, retained_client: retainedClientId, token, clients: clientsToMerge }
  const url = `${GetServerBaseUrl('v2')}/clients/merge/`
  return axios
    .put(url, body)
    .then((response: any) => {
      const deletedClientIds = map(clientsToMerge, (client) => client.id)
      return deletedClientIds
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
// actions
//
//
export const dispatchListClients = (token: string, salon_id: number, pageNumber = 1, searchText?: string) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: CLIENTS_LOADING_CONSTANT, state: true }))
    return apiListClient(token, salon_id, pageNumber, searchText).then((resp) => {

      dispatch(reduceListClients(resp.models))
      dispatch(reduceListClientsPagination(resp.pagination))
      dispatch(reduceSetLoadingState({ name: CLIENTS_LOADING_CONSTANT, state: false }))
    })
  }
}
export const dispatchUpdateClient = (params: { token: string; client_id: number; salon_id: number; model: Client }) => {
  return (dispatch: Dispatch) => {
    return apiUpdateClient(params)
      .then((resp) => {
        dispatch(reduceUpdateClient(resp))
        toast.success('Updated Client')
      })
      .catch((error) => {
        handleError(error)
      })
  }
}
export const dispatchCreateClient = (params: { token: string; salonId: number; model: Client }) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: CREATING_CLIENT_CONSTANT, state: true }))
    return apiCreateClient(params)
      .then((resp) => {
        dispatch(reduceCreateClient(resp))
        dispatch(reduceSetLoadingState({ name: CREATING_CLIENT_CONSTANT, state: false }))
      })
      .catch((error) => {
        handleError(error)
      })
  }
}
export const dispatchCreateClients = (params: { token: string; salonId: number; models: Partial<Client>[] }) => {
  return (dispatch: Dispatch) => {
    return apiCreateClients(params)
      .then((resp) => {
        dispatch(reduceListClients(resp))
        toast.success('Added Clients')
      })
      .catch((error) => {
        handleError(error)
      })
  }
}
export const dispatchDeleteClient = (params: { token: string; salonId: number; model: Client }) => {
  return (dispatch: Dispatch) => {
    return apiDeleteClient(params)
      .then((resp) => {
        dispatch(reduceDeleteClient(resp))
        toast.success('Deleted Client')
      })
      .catch((error) => {
        handleError(error)
      })
  }
}
export const dispatchGetClientMergeableClients = (params: { token: string; salonId: number; model: Client }) => {
  return (dispatch: Dispatch) => {
    return apiGetClientMergeableClients(params).then((resp) => {
      dispatch(reduceSelectedClientMergeableClients(resp))
    })
  }
}
export const dispatchMergeClients = (params: {
  token: string
  salonId: number
  retainedClientId: number
  clientsToMerge: Client[]
}) => {
  return (dispatch: Dispatch) => {
    return apiMergeClients(params).then((resp) => {
      dispatch(reduceDeleteClients(resp))
      toast.success(`Merged ${params.clientsToMerge.length} Client${params.clientsToMerge.length === 1 ? '' : 's'}`)
    })
  }
}

export const dispatchSetSelectedClientId = (params: { selectedClientId: number | null }) => {
  const { selectedClientId } = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetSelectedClientId(selectedClientId))
  }
}

export const dispatchListBulkMergeableClients = (params: { token: string; salonId: number }) => {
  return (dispatch: Dispatch) => {
    return apiListBulkMergeableClients(params).then((resp) => {
      dispatch(reduceBulkMergeableClients(resp))
    })
  }
}

export const dispatchBulkMergeClients = (params: {
  token: string
  salonId: number
  clientSets: APIMergeableClientSet[]
}) => {
  return (dispatch: Dispatch) => {
    return apiBulkMergeClients(params).then((resp) => {
      return apiListBulkMergeableClients(params)
        .then((resp) => {
          dispatch(reduceBulkMergeableClients(resp))
        })
        .then(() => {
          apiListClient(params.token, params.salonId).then((resp) => {
            dispatch(replaceListClients({...resp, clients: resp.models}))
          })
        })
    })
  }
}
