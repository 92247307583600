import { filter, map } from 'lodash'
import {
  APIColorBrandProductDetail,
  APIColorBrandReport,
  ColorBrandProductDetail,
  ColorBrandReport,
  ColorBrandWasteProductDetail,
  APIColorStylistDetail,
  ColorStylistReport,
  ColorStylistWasteReport,
  APIColorStylistProductsDetail,
  ColorStylistProductsDetail,
  ColorStylistWasteProductsDetail
} from './interfaces'
import { getQuantityOnHand } from '../inventory/utils'

export const mapAPIColorBrandReportToColorBrandReport = (api: APIColorBrandReport): ColorBrandReport => {
  return {
    brand: api.brand.trim(),
    retail: api.retail,
    profit: api.profit,
    wholesale: api.wholesale,
  }
}
export const mapAPIColorBrandReportsToColorBrandReports = (apiModels: APIColorBrandReport[]): ColorBrandReport[] => {
  return map(apiModels, mapAPIColorBrandReportToColorBrandReport)
}

export const mapAPIColorBrandProductDetailToColorBrandProductDetail = (
  api: APIColorBrandProductDetail,
): ColorBrandProductDetail => {
  return {
    type: api.type,
    line: api.line,
    category: api.category,
    color: api.color,
    quantity: Number(getQuantityOnHand(parseFloat(Number(api.total_grams).toFixed(2)), api.size).toFixed(2)),
    sessionId: api.session_id,
    wholesale: parseFloat(api.wholesale.toFixed(2)),
    retail: api.retail,
    profit: api.profit,
    totalGrams: parseFloat(Number(api.total_grams).toFixed(2))
  }
}

export const mapAPIColorBrandProductDetailToColorBrandWasteProductDetail = (
  api: APIColorBrandProductDetail,
): ColorBrandWasteProductDetail => {
  return {
    type: api.type,
    line: api.line,
    category: api.category,
    wasteCost: parseFloat(api.waste_cost.toFixed(2)),
    wastePercentage: parseFloat(api.waste_percentage.toFixed(2)),
    wasteGrams: parseFloat(Number(api.waste_grams).toFixed(2)),
    totalGrams: parseFloat(Number(api.total_grams).toFixed(2)),
    wasteQuantity: Number(getQuantityOnHand(parseFloat(Number(api.waste_grams).toFixed(2)), api.size).toFixed(2))
  }
}

export const mapAPIColorBrandProductDetailsToColorBrandProductDetails = (
  type: 'used' | 'unused' | 'waste',
  apiProducts: APIColorBrandProductDetail[],
): ColorBrandProductDetail | ColorBrandWasteProductDetail | any => {
  if (type === 'used') {
    const used = filter(apiProducts, (apiProduct) => !!apiProduct.session_id &&  Number(getQuantityOnHand(parseFloat(Number(apiProduct.total_grams).toFixed(2)), apiProduct.size)) > 0)
    return map(used, mapAPIColorBrandProductDetailToColorBrandProductDetail)
  }
  if (type === 'unused') {
    return map(apiProducts, mapAPIColorBrandProductDetailToColorBrandProductDetail)
  }
  if (type === 'waste') {
    const waste = filter(apiProducts, (apiProduct) => !!apiProduct.session_id && Number(getQuantityOnHand(parseFloat(Number(apiProduct.waste_grams).toFixed(2)), apiProduct.size)) > 0 && apiProduct.waste_cost > 0)
    return map(waste, mapAPIColorBrandProductDetailToColorBrandWasteProductDetail)
  }
  return []
}

const mapAPIColorStylistReportToColorStylistReport = (api: APIColorStylistDetail): ColorStylistReport => {
  return {
    stylist: api.stylist_name.trim(),
    stylistId: api.stylist_id,
    retail: api.retail,
    profit: api.profit,
    wholesale: api.wholesale,
    wasteGrams: api.waste_grams,
    totalGrams: parseFloat(Number(api.total_grams).toFixed(2))
  }
}
export const mapAPIColorStylistReportsToColorStylistReports = (apiModels: APIColorStylistDetail[]): ColorStylistReport[] => {
  return map(apiModels, mapAPIColorStylistReportToColorStylistReport)
}


const mapAPIColorStylistReportToColorStylistWasteReport = (api: APIColorStylistDetail): ColorStylistWasteReport => {
  return {
    stylist: api.stylist_name.trim(),
    stylistId: api.stylist_id,
    wasteCost: parseFloat(api.waste_cost.toFixed(2)),
    wastePercentage: parseFloat(api.waste_percentage.toFixed(2)),
    wasteGrams: parseFloat(Number(api.waste_grams).toFixed(2)),
    wasteQuantity: Number(getQuantityOnHand(parseFloat(Number(api.waste_grams).toFixed(2)), api.size).toFixed(2))
  }
}
export const mapAPIColorStylistReportsToColorStylistWasteReports = (apiModels: APIColorStylistDetail[]): ColorStylistWasteReport[] => {
  return map(apiModels, mapAPIColorStylistReportToColorStylistWasteReport)
}

const mapAPIColorStylistProductsDetailToColorStylistProductsDetailReports = (
  api: APIColorStylistProductsDetail,
): ColorStylistProductsDetail => {
  return {
    type: api.type,
    line: api.line,
    category: api.category,
    color: api.color,
    retail: api.retail,
    quantity: Number(getQuantityOnHand(parseFloat(Number(api.total_grams).toFixed(2)), api.size).toFixed(2)),
    wholesale: api.wholesale,
    profit: api.profit,
    stylistName: api.stylist_name,
    totalGrams: parseFloat(Number(api.total_grams).toFixed(2))
  }
}

const mapAPIColorStylistProductsDetailToColorStylistWasteProductsDetailReports = (
  api: APIColorStylistProductsDetail,
): ColorStylistWasteProductsDetail => {
  return {
    type: api.type,
    wasteCost: parseFloat(api.waste_cost.toFixed(2)),
    wastePercentage: parseFloat(api.waste_percentage.toFixed(2)),
    wasteGrams: parseFloat(Number(api.waste_grams).toFixed(2)),
    stylistName: api.stylist_name,
    wasteQuantity: Number(getQuantityOnHand(parseFloat(Number(api.waste_grams).toFixed(2)), api.size).toFixed(2))
  }
}
///
export const mapAPIColorStylistProductDetailsToColorStylistProductDetails = (
  type: 'used' | 'waste',
  apiProducts: APIColorStylistProductsDetail[],
): ColorStylistProductsDetail | ColorStylistWasteProductsDetail | any => {
  if (type === 'used') {
    return map(apiProducts, mapAPIColorStylistProductsDetailToColorStylistProductsDetailReports)
  }
  if (type === 'waste') {
    return map(apiProducts.filter((e)=> e.waste_cost >0 ), mapAPIColorStylistProductsDetailToColorStylistWasteProductsDetailReports)
  }
  return []
}
