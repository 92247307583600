import { Box, Button, Flex, Image, Input, InputGroup } from '@chakra-ui/react'
import React, { useState } from 'react'
import { COLORS } from '../../mini-lib/theme/colors'
import logo from '../../assets/salonscale-logo-midnight-outline.svg'
import { UseViewSize } from '../../core/UseViewSize'
import { useDispatch } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { dispatchSendPasswordResetEmail } from '../../data/user/api'
import { ROUTES } from "../../appRoutes";

export const ForgotPasswordPage = () => {
  const { isMobile } = UseViewSize()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')

  const onPasswordReset = () => {
    if (email) {
      dispatch(dispatchSendPasswordResetEmail({ email }))
    }
  }

  return (
    <>
      <Flex align="center" minH="100vh" direction="column" padding={isMobile ? '12px' : '0'}>
        <Box h="72px" />
        <Image h="100px" src={logo} />
        <Box h="24px" />
        <Box
          w="100%"
          maxW="400px"
          border={`1px solid ${COLORS.shades_neutral_300}`}
          p="24px"
          borderRadius="15px"
          bg="white"
          zIndex="1000"
        >
          {/* email */}
          <InputGroup size="md" id="email">
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              borderRadius="100px"
              id="email"
              name="email"
              placeholder="Enter Email"
            />
          </InputGroup>

          <Box h="24px" />

          <Flex justify="flex-end" gridGap="12px">
            <Button variant="round" onClick={() => onPasswordReset()} disabled={!email}>
              Send Password Reset Email
            </Button>
          </Flex>
        </Box>
        <Flex w="100%" maxW="400px" zIndex="1000">
          <Link to={generatePath(ROUTES.login)}>
            <Button variant="round-ghost-upper">Return to Log In</Button>
          </Link>
        </Flex>

        {/* sticky images */}

        {!isMobile && (
          <Image
            left="0"
            bottom="0"
            position="absolute"
            src="https://storage.googleapis.com/salonscale-prod-webapp-assets/backgroup-left-illutration-signup.png"
          />
        )}

        {!isMobile && (
          <Image
            right="0"
            bottom="0"
            position="absolute"
            src="https://storage.googleapis.com/salonscale-prod-webapp-assets/backgroup-right-illutration-signup.png"
          />
        )}
      </Flex>
    </>
  )
}
