import { Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { GetServerBaseUrl } from '../../env'
import { REPORTS_LOADING_CONSTANT } from '../report/constants'
import { APILaborLaborReports, LaborLaborReports } from './interfaces'
import { mapAPILaborLaborReportsToLaborLaborReports } from './mappers'
import { reduceLaborLaborReports } from './slice'

export const apiGetAllLaborLaborReports = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
}): Promise<LaborLaborReports[]> => {
  const { token, salonId, dateStart, dateEnd } = props
  const dateRange = dateStart && dateEnd ? `&filter[starts_between]=${dateStart},${dateEnd}` : ''
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/reports/labor/total/?token=${token}${dateRange}`
  return axios
    .get(url)
    .then((response: { data: { data: APILaborLaborReports[] } }) => {
      return mapAPILaborLaborReportsToLaborLaborReports(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchGetAllLaborLaborReports = (props: {
  token: string
  salonId: number
  dateStart?: string | null
  dateEnd?: string | null
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: true }))
    return apiGetAllLaborLaborReports(props).then((resp) => {
      dispatch(reduceLaborLaborReports(resp))
      dispatch(reduceSetLoadingState({ name: REPORTS_LOADING_CONSTANT, state: false }))
    })
  }
}
