import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { selectLoadingState } from '../../../core/loading/slice'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { dispatchGetAllLaborLaborReports } from '../../../data/labor-report/api'
import {
  selectCustomEndDateForLaborReports,
  selectCustomStartDateForLaborReports,
  selectLaborLaborReports,
  selectReportsDateRangeTypeForLaborReports,
} from '../../../data/labor-report/slice'
import { REPORTS_LOADING_CONSTANT } from '../../../data/report/constants'
import { useAppSelector } from '../../../hooks'
import { convertDateRangeTypeToDates } from '../../../mini-lib/dates-and-times/utils'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { VARIANTS } from '../../../mini-lib/theme/variants'
import { LaborReportRow, LaborReportTitleRow, LaborReportTotalRow } from '../common/LaborReportRowComponents'
import { LABOR_PAGES } from '../common/LaborReportsConstants'

export const LaborReportsContainer = () => {
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const dateFilter = useAppSelector(selectReportsDateRangeTypeForLaborReports)
  const loadingReport = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDateForLaborReports)
  const customEndDate = useAppSelector(selectCustomEndDateForLaborReports)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const laborLaborReports = useAppSelector(selectLaborLaborReports) ?? []

  useEffect(() => {
    dispatch(dispatchGetAllLaborLaborReports({ token, salonId, dateStart: sDate, dateEnd: eDate }))
  }, [dispatch, token, salonId, sDate, eDate])

  return (
    <>
      <Box h="12px" />
      <Flex width="100%" align="center" p="20px" borderRadius="15px" bg="brand.skylight.50" maxH="50px">
        <Text variant={VARIANTS.text.caption1}>LABOR</Text>
      </Flex>
      <Box h="24px" />

      {!loadingReport && laborLaborReports && laborLaborReports.length > 0 && (
        <>
          <LaborReportTitleRow pageName={LABOR_PAGES.laborReport} />
          <LaborReportRow models={laborLaborReports} pageName={LABOR_PAGES.laborReport} />
          <LaborReportTotalRow pageName={LABOR_PAGES.laborReport} models={laborLaborReports} />
        </>
      )}
      {!loadingReport && laborLaborReports && laborLaborReports.length === 0 && <EmptyBox />}
      {loadingReport && <Loading />}
    </>
  )
}
