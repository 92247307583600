/////////////// api.ts
//
//

import axios, { AxiosResponse } from 'axios'
import { Dispatch } from '@reduxjs/toolkit'
import { reduceGetOwnerAnalytics } from './slice'
import { mapApiAnalyticsToAnalytics, mapAPITopClientToTopClient } from './mappers'
import { GetServerBaseUrl } from '../../env'
import { APITopClient, OwnerMetricOptions } from './interfaces'
import { DateRangeType } from '../../mini-lib/dates-and-times/constants'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { buildDateYYYYMMDD } from '../../core/dates'
import { buildLaraConfig } from '../../mini-lib/lara/lara-utils'
// apis
//
//
export const apiGetOwnerAnalytics = (props: {
  token: string
  salonId: number
  statName: string
  dateRangeType: DateRangeType
  dateStart?: string|null
  dateEnd?: string|null
  interval?: string
}): Promise<any> => {
  const { token, salonId, statName, dateRangeType, dateStart, dateEnd, interval } = props
  const dateRangeParam = dateStart && dateEnd ? `?filter[starts_between]=${dateStart},${dateEnd}` : ''
  const groupByParam =  statName === OwnerMetricOptions.totalRevenueProfit ? `&filter[group_by]=${interval}` : ''
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/statistics/${statName}${dateRangeParam}${groupByParam}`
  return axios
    .get(url, config)
    .then((response: { data: any }) => {
      const mapped = {
        dateRangeType: dateRangeType,
        statType: statName,
        analytics: mapApiAnalyticsToAnalytics(statName, {[statName]: response.data}),
      }
      return mapped
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetProductAnalytics = (props: {
  token: string
  salonId: number
  dateRangeType: DateRangeType
  dateStart?: string|null
  dateEnd?: string|null
  interval?: string
}): Promise<any> => {
  const { token, salonId, dateRangeType, interval } = props
  // const dateRangeParam = dateStart && dateEnd ? `?filter[starts_between]=${dateStart},${dateEnd}` : ''
  const kindParam = `?group_by=${interval}`
  // const kindParam = '&filter[kind]=color'
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/statistics/products/${kindParam}`
  const statName = OwnerMetricOptions.products
  return axios
    .get(url, config)
    .then((response: AxiosResponse<{ data: any }>) => {
      const data = response.data.data.map(d => {
        const date = d.date ? buildDateYYYYMMDD(d.date) : `${d.year}${d.month ? '-' + d.month : ''}`;
        return ({ 
          cost:  parseFloat(d.wholesale.toFixed(2)),
          revenue: parseFloat(d.retail.toFixed(2)),
          profit: parseFloat(d.profit.toFixed(2)),
          date
         })
      }) ?? []

      return {
        dateRangeType: dateRangeType,
        statType: OwnerMetricOptions.products,
        analytics: mapApiAnalyticsToAnalytics(statName, {[statName]: data}),
      }
    })
    .catch((error) => {
      throw error
    })
}
// getClientTotals(from: Date, to: Date): Observable<Total[]> {
//   const date_from = formatDate(from, 'yyyy-MM-dd', this.locale)
//   const date_to = formatDate(to, 'yyyy-MM-dd', this.locale)
//   const {
//     token,
//     current_salon_context: { salon_id }
//   } = this.userCache.cachedUserContext
//   const url = `${environment.v3}/reports/clients/?token=${token}&date_from=${date_from}&date_to=${date_to}&salon_id=${salon_id}`
//
//   return this.http.get<ClientTotalsResponse>(url).pipe(
//     map((resp: ClientTotalsResponse) => {
//       return resp.data.map(item => {
//         return {id: item.id.toString(), title: item.client_name, total: item.revenue, profit: item.profit}
//       })
//     })
//   )
// }
export const apiGetTopClientAnalytics = (props: {
  token: string
  salonId: number
  dateRangeType: DateRangeType
  dateStart?: string|null
  dateEnd?: string|null
  interval?: string
}): Promise<{ statType: string; dateRangeType: DateRangeType; analytics: any }> => {
  const { token, salonId, dateRangeType, dateStart, dateEnd } = props
  const dateRangeParam = dateStart && dateEnd ? `?filter[starts_between]=${dateStart},${dateEnd}` : ''
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/statistics/${OwnerMetricOptions.clients}${dateRangeParam}`
  const statName = OwnerMetricOptions.clients
  return axios
    .get(url, config)
    .then((response: AxiosResponse<{ data: APITopClient[] }>) => {
      return {
        dateRangeType: dateRangeType,
        statType: OwnerMetricOptions.clients,
        analytics: mapApiAnalyticsToAnalytics(statName, {[statName]: mapAPITopClientToTopClient(response.data.data)}),
      }
    })
    .catch((error) => {
      throw error
    })
}

// actions
//
//
export const dispatchGetOwnerAnalytics = (props: {
  token: string
  salonId: number
  statName: string
  dateRangeType: DateRangeType
  dateStart?: string|null
  dateEnd?: string|null
  interval?: string
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: props.statName, state: true }))
    return apiGetOwnerAnalytics(props).then((resp) => {
      dispatch(reduceGetOwnerAnalytics(resp))
      dispatch(reduceSetLoadingState({ name: props.statName, state: false }))
    })
  }
}

export const dispatchGetProductAnalytics = (props: {
  token: string
  salonId: number
  dateRangeType: DateRangeType
  dateStart?: string|null
  dateEnd?: string|null
  interval?: string
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: OwnerMetricOptions.products, state: true }))
    return apiGetProductAnalytics(props).then((resp) => {
      dispatch(reduceGetOwnerAnalytics(resp))
      dispatch(reduceSetLoadingState({ name: OwnerMetricOptions.products, state: false }))
    })
  }
}

export const dispatchGetTopClientAnalytics = (props: {
  token: string
  salonId: number
  dateRangeType: DateRangeType
  dateStart?: string|null
  dateEnd?: string|null
  interval?: string
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: OwnerMetricOptions.clients, state: true }))
    return apiGetTopClientAnalytics(props).then((resp) => {
      dispatch(reduceGetOwnerAnalytics(resp))
      dispatch(reduceSetLoadingState({ name: OwnerMetricOptions.clients, state: false }))
    })
  }
}
