import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'
import { selectChecklistItem, selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems, dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { MaterialIcon } from "../../mini-lib/icons/MaterialIcon";
import { COLORS } from "../../mini-lib/theme/colors";
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { sgInventoryStepCodes } from './SGIndex/SGInventoryIndex'
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGAcademyIntroPage = () => {
  const {
    user: {userId, token},
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])
  const stepCodes = [CHECKLIST_CODES.learnSessions, CHECKLIST_CODES.learnAcademy]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))

  const currentCode = CHECKLIST_CODES.learnAcademy
  const currentStep = useAppSelector((state) => selectChecklistItem(state, currentCode))
  const maybeUpdateChecklist = () => {
    if (!currentStep.completed) {
      dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: currentCode}))
    }
  }
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="learn"
          title="Successfully Implementing salonscale"
          description={descriptionText}
          descriptionActions={<AcademyLinks/>}
          video={VIDEO_MAP.addSession}
          actions={
            <SGActionButtons
              action={{
                label: 'Complete implementation',
                url: generatePath(ROUTES.sgIndex, {salonId}) + '?step=implementation&complete=implementation',
                onClick: maybeUpdateChecklist
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={sgInventoryStepCodes.length} stepsComplete={stepsCompleted || 0}/>}
          imgPath={require('../../assets/start-guide/implementation.png').default}
        />
      </SGLayout>
    </>
  )
}

export const AcademyLinks = () => {
  const {user} = UseBaseApiParams()
  const salonType = user?.currentSalonContext?.salonType || 'salon'
  const profitableGuideUrl = salonType === 'salon'
    ? 'https://uploads-ssl.webflow.com/66570799098af00ad5ed9eea/668c1840e080967c00b9002a_SalonScale%20Implementation%20guide%20for%20Salon%20Owners%20(19July2023)%20(1).pdf'
    : 'https://uploads-ssl.webflow.com/66570799098af00ad5ed9eea/668c186702753c78f41ad520_SalonScale%20Implementation%20Guide%20for%20Solos(04April2024).pdf'
  return (
    <>
      <Flex wrap="wrap" gridGap="12px">
        <a
          href={profitableGuideUrl}
          target="_blank"
          rel="noreferrer"
        >
          <Flex cursor="pointer" color="brand.lavender.500" gridGap="6px">
            <MaterialIcon name="video_library" colorhex={COLORS.peach_200}/>
            Guide to a Profitable Salon
          </Flex>
        </a>
        <a href="https://salonscale-technology-s-school.teachable.com" target="_blank" rel="noreferrer">
          <Flex cursor="pointer" color="brand.lavender.500" gridGap="6px">
            <MaterialIcon name="video_library" colorhex={COLORS.peach_200}/>
            SalonScale Courses
          </Flex>
        </a>
        <a href="https://helpcenter.salonscale.com/" target="_blank" rel="noreferrer">
          <Flex cursor="pointer" color="brand.lavender.500" gridGap="6px">
            <MaterialIcon name="video_library" colorhex={COLORS.peach_200}/>
            Question Library
          </Flex>
        </a>
      </Flex>
    </>
  )
}
const descriptionText = `Congratulations, you are ready to use SalonScale!

Remember: we are always here to help! From scripts to tips to downloads and more, we have resources to help you feel confident in your implementation within your salon.

Here are a few places we suggest checking out:

Guide to a Profitable Salon
  • Your business meets SalonScale. We dig into questions like how to find your breakeven point and tips on pricing, transparency and other financial tips!
  
SalonScale Courses 
  • Here you will find access to our courses to help you feel confident about SalonScale and your business.
    
Resource Center 
  • Here you will find answers to everything around the app and business center. You can also find resources for talking to clients and your team too`
