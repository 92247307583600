import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { COLORS } from "../theme/colors";

export const EmptyBox = (props: {
  icon?: any
  title?: string
  content?: string
  children?: any
  w?: string
  h?: string
  p?: string
  borderRadius?: string
  bg?: string
}) => {
  const {
    icon,
    title = 'There is nothing to display',
    content = 'Please add some data',
    children,
    w,
    h = '100%',
    borderRadius = '0px',
    p = '12px',
    bg = COLORS.shades_neutral_50
  } = props
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      h={h} w={w}
      bg={bg}
      p={p}
      borderRadius={borderRadius}
    >
      {icon}
      {icon && <Box h="24px" />}
      <Text variant="title3">{title}</Text>
      <Box h="16px" />
      <Text variant="footnote">{content}</Text>
      {children}
    </Flex>
  )
}
