import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { buildDateYYYYMMDD } from '../../core/dates'
import { dispatchGetAllReportsStylists, dispatchGetReportsStylistsById } from '../../data/report/api'
import {
  reduceSetCurrentStylist,
  selectCustomEndDate,
  selectCustomStartDate,
  selectReportsDateRangeType,
  selectStylistDetailReportList,
  selectStylistReportList,
} from '../../data/report/slice'
import { mapStylistDetailToCSV } from '../../data/report/mappers'
import { useAppSelector } from '../../hooks'
import { convertDateRangeTypeToDates } from '../../mini-lib/dates-and-times/utils'
import { pages } from './ReportsPage'
import { selectLoadingState } from '../../core/loading/slice'
import { REPORTS_LOADING_CONSTANT } from '../../data/report/constants'
import { Loading } from '../../mini-lib/loading/Loading'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { StylistDetailReportLara, StylistReport } from '../../data/report/interfaces'
import { VARIANTS } from '../../mini-lib/theme/variants'
import { TableContent, TableHead, TableTotal } from './Table'
import { UseViewSize } from '../../core/UseViewSize'

export const StylistReportPage = (props: { page: string; setPage: (page: string) => void }) => {
  const [stylistName, setstylistName] = useState('')
  const [stylistId, setstylistId] = useState(0)
  const { page, setPage } = props
  if (page === pages.stylistList) {
    return <ColorStylistReportTable setPage={setPage} setstylistName={setstylistName} setstylistId={setstylistId} />
  }
  if (page === pages.stylistDetail) {
    return <ColorStylistReportDetail stylistName={stylistName} stylistId={stylistId} setPage={setPage} />
  }

  return <></>
}

export const ColorStylistReportTable = (props: {
  setPage: (string) => void
  setstylistName: (string) => void
  setstylistId: (number) => void
}) => {
  const { setPage, setstylistName, setstylistId } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const dispatch = useDispatch()

  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const loadingReports = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const stylistsReportProducts = useAppSelector(selectStylistReportList)

  const sumProfit = stylistsReportProducts
    ? stylistsReportProducts.map((model) => model.profit).reduce((a, b) => a + b, 0)
    : null
  const sumRevenue = stylistsReportProducts
    ? stylistsReportProducts.map((model) => model.retail).reduce((a, b) => a + b, 0)
    : null
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0
  const [numRowsToShow, setNumRowsToShow] = useState(30)

  useEffect(() => {
    dispatch(dispatchGetAllReportsStylists({ token, salonId, dateStart: sDate, dateEnd: eDate, kind: 'color' }))
  }, [dispatch, token, salonId, sDate, eDate])

  const pagedStylistReportProducts = stylistsReportProducts ? stylistsReportProducts.slice(0, numRowsToShow) : null
  const loadingReport = loadingReports ? loadingReports : null
  return (
    <>
      <Flex width="100%" align="center" p="20px" borderRadius="15px" bg="brand.lavender.100">
        <Text variant={VARIANTS.text.caption1}>STYLISTS</Text>
      </Flex>
      <Box h="25px" />
      {!loadingReport &&
        stylistsReportProducts &&
        pagedStylistReportProducts &&
        pagedStylistReportProducts.length > 0 &&
        sumProfit !== null &&
        sumRevenue !== null &&
        sumWholesale !== null && (
          <ColorStylistReportTableContainer
            hasMore={numRowsToShow < stylistsReportProducts.length}
            setNumRowsToShow={setNumRowsToShow}
            pagedStylistReportProducts={pagedStylistReportProducts}
            sumProfit={sumProfit}
            sumRevenue={sumRevenue}
            sumWholesale={sumWholesale}
            setPage={setPage}
            setstylistName={setstylistName}
            setstylistId={setstylistId}
          />
        )}
      {!loadingReport && pagedStylistReportProducts && pagedStylistReportProducts.length === 0 && <EmptyBox />}
      {loadingReport && <Loading />}
    </>
  )
}

export const ColorStylistReportTableContainer = (props: {
  pagedStylistReportProducts: StylistReport[]
  hasMore: boolean
  setNumRowsToShow: (number) => void
  sumProfit: number
  sumRevenue: number
  sumWholesale: number
  setPage: (string) => void
  setstylistName: (string) => void
  setstylistId: (number) => void
}) => {
  const {
    hasMore,
    pagedStylistReportProducts,
    setNumRowsToShow,
    sumProfit,
    sumRevenue,
    sumWholesale,
    setPage,
    setstylistName,
    setstylistId,
  } = props
  const list = [
    { label: 'Stylist' },
    { label: 'Cost', value: sumWholesale, additionalProps: { isNumeric: true } },
    { label: 'Price', value: sumRevenue, additionalProps: { isNumeric: true } },
    { label: 'Mark up', value: sumProfit, additionalProps: { isNumeric: true } },
  ]
  return (
    <>
      <TableHead list={list} />
      {pagedStylistReportProducts.map((product, index) => (
        <Fragment key={index}>
          <ColorStylistReportTableRow
            product={product}
            setPage={setPage}
            setstylistName={setstylistName}
            setstylistId={setstylistId}
          />
        </Fragment>
      ))}
      {hasMore && (
        <Flex justify="center">
          <Button
            variant="round-ghost-lower"
            onClick={() => setNumRowsToShow(pagedStylistReportProducts.length + 30)}
          >
            View More
          </Button>
        </Flex>
      )}
      <TableTotal list={list} />
    </>
  )
}

export const ColorStylistReportTableRow = (props: {
  product: StylistReport
  setPage: (string) => void
  setstylistName: (string) => void
  setstylistId: (number) => void
}) => {
  const { product, setPage, setstylistName, setstylistId } = props
  const wholesale = product.retail - product.profit
  const list = [
    { label: 'Stylist', value: product.stylistName },
    { label: 'Cost', value: wholesale, additionalProps: { isNumeric: true } },
    { label: 'Price', value: product.retail, additionalProps: { isNumeric: true } },
    { label: 'Mark up', value: product.profit, additionalProps: { isNumeric: true } },
  ]

  return (
    <TableContent
      list={list}
      action={
        <Button
          minWidth={'70px'}
          height={'20px'}
          variant="round-ghost-lower"
          onClick={() => {
            setstylistName(product.stylistName)
            setstylistId(product.stylistId)
            setPage(pages.stylistDetail)
          }}
        >
          view all
        </Button>
      }
    />
  )
}

// ------------STYLIST REPORT DETAIL---------------------

export const ColorStylistReportDetailTableContainer = (props: {
  hasMore: boolean
  setNumRowsToShow: (number) => void
  stylistReportProducts: StylistDetailReportLara[]
  sumProfit: number | any
  sumRevenue: number | any
  sumWholesale: number
}) => {
  const { stylistReportProducts, hasMore, setNumRowsToShow, sumProfit, sumRevenue, sumWholesale } = props
  const { isMobile, isTablet } = UseViewSize()
  const minWidth = '110px'
  const list = [
    { label: 'Line' },
    { label: 'Color', additionalProps: { isNumeric: true, noTotal: true, width: (isMobile || isTablet) ? 105 : 200 } },
    { label: 'Type', additionalProps: { isNumeric: true, noTotal: true, width: (isMobile || isTablet) ? 105 : 200 } },
    { label: 'Qty', additionalProps: { isNumeric: true, noTotal: true, width: (isMobile || isTablet) ? 105 : 120 } },
    { label: 'Cost', value: sumWholesale, additionalProps: { isNumeric: true, width: (isMobile || isTablet) ? 105 : 120 } },
    { label: 'Price', value: sumRevenue, additionalProps: { isNumeric: true, width: (isMobile || isTablet) ? 105 : 120 } },
    { label: 'Mark up', value: sumProfit, additionalProps: { isNumeric: true, width: (isMobile || isTablet) ? 105 : 120 } },
  ]
  return (
    <>
      <TableHead list={list} minWidth={minWidth} noAction />
      {stylistReportProducts.map((product, index) => (
        <Fragment key={index}>
          <ColorStylistReportDetailTableRow product={product} />
        </Fragment>
      ))}
      {hasMore && (
        <Flex justify="center">
          <Button variant="round-ghost-lower" onClick={() => setNumRowsToShow(stylistReportProducts.length + 30)}>
            View More
          </Button>
        </Flex>
      )}
      <TableTotal list={list} minWidth={minWidth} noAction />
    </>
  )
}

export const ColorStylistReportDetailTableRow = (props: { product: StylistDetailReportLara }) => {
  const { product } = props
  const wholesale = product.retail - product.profit
  const { isMobile, isTablet } = UseViewSize()
  const minWidth = '110px'
  const list = [
    { label: 'Line', value: product.line },
    {
      label: 'Color',
      value: product.color,
      additionalProps: { noTotal: true, width: isMobile || isTablet ? 105 : 200 },
    },
    {
      label: 'Type',
      value: product.category,
      additionalProps: { noTotal: true, width: isMobile || isTablet ? 105 : 200 },
    },
    {
      label: 'Quantity',
      value: product.quantity,
      additionalProps: { noTotal: true, width: isMobile || isTablet ? 105 : 120 },
    },
    { label: 'Cost', value: wholesale, additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 } },
    {
      label: 'Price',
      value: product.retail,
      additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 },
    },
    {
      label: 'Mark up',
      value: product.profit,
      additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 },
    },
  ]
  return <TableContent list={list} minWidth={minWidth} />
}

export const ColorStylistReportDetail = (props: {
  stylistName: string
  stylistId: number
  setPage: (string) => void
}) => {
  const { stylistName, stylistId, setPage } = props //using this stylistId we will make a request to Stylist Detail Report

  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const dispatch = useDispatch()

  const stylistReportProducts = useAppSelector(selectStylistDetailReportList)
  const loadingReports = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const [numRowsToShow, setNumRowsToShow] = useState(30)
  const sumProfit = stylistReportProducts?.map((model) => model.profit).reduce((a, b) => a + b, 0)
  const sumRevenue = stylistReportProducts?.map((model) => model.retail).reduce((a, b) => a + b, 0)
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0

  useEffect(() => {
    dispatch(
      dispatchGetReportsStylistsById({ token, salonId, stylistId, dateStart: sDate, dateEnd: eDate, kind: 'color', csvMapper: mapStylistDetailToCSV, stylistName }),
    )
    dispatch(reduceSetCurrentStylist(stylistId))
  }, [dispatch, token, stylistId, salonId, sDate, eDate, stylistName])

  const pagedStylistReportProducts = stylistReportProducts ? stylistReportProducts.slice(0, numRowsToShow) : null

  return (
    <>
      <Flex width="100%" justify="space-between" align="center" p="20px" borderRadius="15px" bg="brand.lavender.100">
        <Text variant={VARIANTS.text.caption1}>{stylistName}</Text>
        <Box cursor="pointer" onClick={() => setPage(pages.stylistList)}>
          <Text variant={VARIANTS.text.caption1}>Back</Text>
        </Box>
      </Flex>
      <Box h="25px" />
      {!loadingReports &&
        pagedStylistReportProducts &&
        stylistReportProducts &&
        sumProfit !== null &&
        sumRevenue !== null &&
        sumWholesale !== null &&
        pagedStylistReportProducts.length > 0 && (
          <ColorStylistReportDetailTableContainer
            hasMore={numRowsToShow < stylistReportProducts.length}
            setNumRowsToShow={setNumRowsToShow}
            stylistReportProducts={pagedStylistReportProducts}
            sumProfit={sumProfit}
            sumRevenue={sumRevenue}
            sumWholesale={sumWholesale}
          />
        )}
      {!loadingReports && pagedStylistReportProducts && pagedStylistReportProducts.length === 0 && <EmptyBox />}
      {loadingReports && <Loading />}
    </>
  )
}
