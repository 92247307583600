import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, keyBy, omit, orderBy, values } from 'lodash'

import { RootState } from '../../store'
import { SalonUser } from './interfaces'
import { updateSalonUsers } from "./utils";

// state
//
interface SalonUserState {
  salonUsersById: {[ key: string ]: SalonUser} | null,
  selectedSalonUser: SalonUser | null,
}

const initialState: SalonUserState = {
  salonUsersById: null,
  selectedSalonUser: null,
}

// reducer
//
export const SalonUserSlice = createSlice({
  name: 'salonUsers',
  initialState,
  reducers: {
    reduceListSalonUsers: (state, action: PayloadAction<SalonUser[]>) => {
      state.salonUsersById = assign({}, state.salonUsersById, keyBy(action.payload, 'id'))
    },
    reduceUpdateSalonUsers: (state, action: PayloadAction<Partial<SalonUser>[]>) => {
      state.salonUsersById = updateSalonUsers({usersById: state.salonUsersById, userUpdates: action.payload})
    },
    reduceDeleteSalonUser: (state, action: PayloadAction<{userId: number }>) => {
      state.salonUsersById = omit(state.salonUsersById, action.payload.userId)
    },
    reduceSetSelectedSalonUser: (state, action: PayloadAction<SalonUser | null>) => {
      state.selectedSalonUser = action.payload
    },
  },
})

// actions
//
export const {
  reduceListSalonUsers,
  reduceSetSelectedSalonUser,
  reduceUpdateSalonUsers,
  reduceDeleteSalonUser,
} = SalonUserSlice.actions

// selectors
//
export const selectSalonUsersById = (state: RootState) => state.salonUsers.salonUsersById
export const selectSelectedSalonUser = (state: RootState) => state.salonUsers.selectedSalonUser
export const selectSalonUsers = (state: RootState): SalonUser[] | null => {
    const property1Sorter = (model) => model.firstName.toLowerCase()
  const property2Sorter = (model) => model.lastName.toLowerCase()
  return state.salonUsers.salonUsersById
    ? orderBy(values(state.salonUsers.salonUsersById), [property1Sorter, property2Sorter], ['asc', 'asc'])
    : null
}
// export
//
export default SalonUserSlice.reducer
