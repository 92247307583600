import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { sgInventoryStepCodes } from './SGIndex/SGInventoryIndex'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGInventoryTargetsIntroPage = () => {
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, sgInventoryStepCodes))
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="implementation"
          title="SET YOUR BACKBAR TARGETS"
          description={descriptionText}
          video={VIDEO_MAP.inventoryBackBarTargets}
          actions={
            <SGActionButtons
              action={{
                label: 'Set Backbar Targets',
                url: generatePath(ROUTES.sgInventoryTargets, { salonId }) + `?guide=${CHECKLIST_CODES.inventorySetTargets}`,
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={sgInventoryStepCodes.length} stepsComplete={stepsCompleted || 0} />}
          imgPath={require('../../assets/start-guide/backbar-targets.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `The first step is to set your inventory targets. Targets are your ideal quantity you want to have in on hand for each specific product at any given time.

Where do you find your target? This could be a number you have in mind for each product, or the the typical amount that you would order to have your stock. For example, if you order on a biweekly basis, you will want to ensure you have enough product on hand to sustain you for that period.
`
