import { Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
import { GetServerBaseUrl } from '../../env'
import { mapAPINotificationToNotification, mapAPINotificationsToNotifications } from '../notification-user/mappers'
import { reduceCreateNotificationForAdmin, reduceUpdateNotificationForAdmin, reduceDeleteNotificationForAdmin, reduceListNotificationsForAdmin } from '../notification-user/slice'
import { NOTIFICATIONS_CREATE_LOADING_CONSTANT } from '../notification-user/constants'
import { APINotification, Notification, NotificationCreate } from '../notification-user/interfaces'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { buildLaraConfig } from '../../mini-lib/lara/lara-utils'

// api
export const apiListNotificationForAdmin = (
  token: string
): Promise<Notification[]> => {
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/announcements`
  return axios
    .get(url, config)
    .then((response: { data: { data: APINotification[] } }) => {
      return mapAPINotificationsToNotifications(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateNotificationForAdmin = ({
  token,
  model
}: {
  token: string
  model: NotificationCreate
}): Promise<Notification> => {
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/announcements`
  const body = { ...model }
  return axios
    .post(url, body, config)
    .then((response: { data: { data: APINotification } }) => {
      toast.success('Notification created successfully')
      return mapAPINotificationToNotification(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateNotificationForAdmin = ({
  token,
  model
}: {
  token: string
  model: Partial<Notification>
}): Promise<Notification> => {
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/announcements/${model.id}`
  const { id, ...body } = model;
  return axios
    .patch(url, body, config)
    .then((response: { data: { data: APINotification } }) => {
      toast.success('Notification updated successfully')
      return mapAPINotificationToNotification(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteNotificationForAdmin = ({
  token,
  model
}: {
  token: string
  model: Notification
}): Promise<void> => {
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/announcements/${model.id}`
  return axios
    .delete(url, config)
    .then(() => {
      toast.success('Notification deleted successfully')
      return
    })
    .catch((error) => {
      throw error
    })
}

// dispatch
export const dispatchListNotificationForAdmin = (
  token: string
) => {
  return (dispatch: Dispatch) => {
    return apiListNotificationForAdmin(token).then((resp) => {
      dispatch(reduceListNotificationsForAdmin(resp))
    })
  }
}

export const dispatchCreateNotificationForAdmin = ({
  token,
  model
}: {
  token: string
  model: NotificationCreate
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: NOTIFICATIONS_CREATE_LOADING_CONSTANT, state: true }))
    return apiCreateNotificationForAdmin({ token, model }).then((resp) => {
      dispatch(reduceCreateNotificationForAdmin(resp))
    })
    .catch()
    .finally(()=>{
      dispatch(reduceSetLoadingState({ name: NOTIFICATIONS_CREATE_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchUpdateNotificationForAdmin = ({
  token,
  model
}: {
  token: string
  model: Partial<Notification>
}) => {
  return (dispatch: Dispatch) => {
    return apiUpdateNotificationForAdmin({ token, model }).then((resp) => {
      dispatch(reduceUpdateNotificationForAdmin(resp))
    })
  }
}

export const dispatchDeleteNotificationForAdmin = ({
  token,
  model
}: {
  token: string
  model: Notification
}) => {
  return (dispatch: Dispatch) => {
    return apiDeleteNotificationForAdmin({ token, model }).then(() => {
      dispatch(reduceDeleteNotificationForAdmin(model.id))
    })
  }
}
