import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { assign, keyBy, keys, without } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { buildDateYYYYMMDD } from '../../core/dates'
import { maybePluralize } from '../../core/text'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseViewSize } from '../../core/UseViewSize'
import { dispatchListOrderBrands } from '../../data/orders/api'
import { APIOrderCreateItem, OrderProduct } from '../../data/orders/interfaces'
import {
  reduceAddProductsToOrder,
  reduceSetSelectedOrderQuantitiesByProductId,
  selectOrderListByBrand,
} from '../../data/orders/slice'
import { getProductsForSelectedLinesforOrderProducts } from '../../data/orders/utils'
import { useAppSelector } from '../../hooks'
import { DATE_RANGE_OPTION_MAP_WITH_CUSTOM, DateRangeType } from '../../mini-lib/dates-and-times/constants'
import { DateInput } from '../../mini-lib/dates-and-times/DateInput'
import { DateRangeSelect } from '../../mini-lib/dates-and-times/DateRangeSelect'
import { convertDateRangeTypeToDates } from '../../mini-lib/dates-and-times/utils'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../mini-lib/theme/colors'

export const OrderAddProductsSideSheet = (props: { isOpen: boolean; setIsOpen: (isOpen: boolean) => void }) => {
  const { isOpen, setIsOpen } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()

  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>('month')
  const [customStartDate, setCustomStartDate] = useState<Date>(new Date())
  const [customEndDate, setCustomEndDate] = useState<Date>(new Date())
  const dates = convertDateRangeTypeToDates(selectedDateRange)
  const sDate = selectedDateRange === 'custom' ? buildDateYYYYMMDD(customStartDate) : buildDateYYYYMMDD(dates.start)
  const eDate = selectedDateRange === 'custom' ? buildDateYYYYMMDD(customEndDate) : buildDateYYYYMMDD(dates.end)

  const [selectedSensitivity, setSelectedSensitivity] = useState('0.5')
  const [selectedLineNames, setSelectedLineNames] = useState<string[]>([])

  const usedProductsByLineName = useAppSelector(selectOrderListByBrand)
  const { isMobile } = UseViewSize()
  const [showTooltip, setShowToolTip] = useState(false)
  const tooltip =
    'If you select sensitivity 1/2 all brands with products that you have used over 1/2 of a container will appear.'

  // Add/Remove checked item from list
  const handleSelect = (lineName: string) => {
    if (selectedLineNames.includes(lineName)) {
      const lineNames = selectedLineNames.filter((item) => item !== lineName)
      setSelectedLineNames(lineNames)
    } else {
      const lineNames = [...selectedLineNames, lineName]
      setSelectedLineNames(lineNames)
    }
  }

  useEffect(() => {
    dispatch(dispatchListOrderBrands(token, salonId, sDate, eDate, parseFloat(selectedSensitivity)))
  }, [dispatch, token, salonId, sDate, eDate, selectedSensitivity])
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={() => setIsOpen(false)} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <Box h="24px" />
        <Flex flexDirection="row" justify="space-between" align="center" p="24px">
          <Text variant="title2">Select Brands</Text>
          <Flex gridGap="12px">
            <Button onClick={() => setIsOpen(false)} variant="round-ghost-upper">
              Cancel
            </Button>
            <Button
              disabled={selectedLineNames.length === 0}
              variant="round"
              colorScheme="brand.midnight"
              onClick={() => {
                const allProducts: OrderProduct[] = getProductsForSelectedLinesforOrderProducts(
                  without(selectedLineNames, ''),
                  usedProductsByLineName,
                )
                dispatch(reduceAddProductsToOrder(allProducts))
                const orderQuantities: APIOrderCreateItem[] = allProducts.map((product) => {
                  return {
                    product_id: parseInt(product.id),
                    estimated_unit_cost: product.estWholesalePrice,
                    order_item_quantity: Math.ceil(product.estOrderQuantity),
                  }
                })
                const orderQuantitiesByProductId: { [key: string]: APIOrderCreateItem } = assign(
                  {},
                  keyBy(orderQuantities, 'product_id'),
                )
                dispatch(reduceSetSelectedOrderQuantitiesByProductId(orderQuantitiesByProductId))
                setIsOpen(false)
              }}
            >
              {selectedLineNames.length > 0
                ? `Add ${selectedLineNames.length} ${maybePluralize('brand', selectedLineNames.length)}`
                : 'Add brands'}
            </Button>
          </Flex>
        </Flex>

        <DrawerBody>
          <Flex width="100%" align="center" p="20px" borderRadius="15px" bg="brand.lavender.100">
            <Text fontSize="14px">For each brand selected we will add used products to the order</Text>
          </Flex>
          <Box h="20px" />
          <Flex margin="10px" justify="space-between" align="center" gridGap="24px" flexWrap="wrap">
            <Box w={isMobile ? '100%' : '50%'}>
              <Text>Date</Text>
              <DateRangeSelect
                options={DATE_RANGE_OPTION_MAP_WITH_CUSTOM}
                initialDateRangeType={selectedDateRange}
                onChange={(dateRange) => {
                  setSelectedDateRange(dateRange)
                  setSelectedLineNames([])
                }}
              />
              {selectedDateRange === 'custom' && (
                <Flex justify="space-between" gridGap="24px">
                  <Box>
                    <Box h="12px" />
                    <Text>Start</Text>
                    <DateInput
                      placeholder='Date'
                      value={customStartDate}
                      onChange={(date) => {
                        setCustomStartDate(date)
                      }}
                      isClearable={false}
                    />
                  </Box>
                  <Box>
                    <Box h="12px" />
                    <Text>End</Text>
                    <DateInput
                      placeholder='Date'
                      value={customEndDate}
                      onChange={(date) => {
                        setCustomEndDate(date)
                      }}
                      isClearable={false}
                    />
                  </Box>
                </Flex>
              )}
            </Box>
            <Box>
              <Flex dir="row">
                <Tooltip label={tooltip} isOpen={showTooltip}>
                  <Flex color="black" justifyContent="flex-start">
                    <Text w={'100%'}>Order Sensitivity</Text>
                    <MaterialIcon
                      ml="4px"
                      colorhex={COLORS.lavender_500}
                      cursor="pointer"
                      name={'info_outlined'}
                      onClick={() => setShowToolTip(!showTooltip)}
                    />
                  </Flex>
                </Tooltip>
              </Flex>

              <RadioGroup
                onChange={(sensitivity) => {
                  setSelectedSensitivity(sensitivity)
                  setSelectedLineNames([])
                }}
                value={selectedSensitivity}
                colorScheme="brand.midnight"
              >
                <Stack direction="row" mb="4">
                  <Radio value="0.25">1/4</Radio>
                  <Radio value="0.5">1/2</Radio>
                  <Radio value="0.75">3/4</Radio>
                  <Radio value="1">1</Radio>
                </Stack>
              </RadioGroup>
            </Box>
          </Flex>
          <Box h="20px" />

          <Flex>
            <Stack direction="column">
              {usedProductsByLineName &&
                keys(usedProductsByLineName).map((lineName) => {
                  return (
                    <Box p="4px" key={lineName}>
                      <Checkbox
                        colorScheme="brand.lavender"
                        isChecked={selectedLineNames.includes(lineName)}
                        onChange={(e) => {
                          handleSelect(lineName)
                        }}
                      >
                        {lineName}
                      </Checkbox>
                    </Box>
                  )
                })}
            </Stack>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
