/////////////// api.ts
//
//

import axios from 'axios'
import { Dispatch } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { GetServerBaseUrl } from '../../env'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { reduceListProductUpcs, reduceAcceptProductUpc, reduceDeclineProductUpc } from './slice'
import { buildLaraConfig } from '../../mini-lib/lara/lara-utils'
import { UPC_LOADING_CONSTANT } from './constants'
import { APIProductUpc, ProductUpc } from './interfaces'

// apis
//
//
export const apiListProductUpcs = (params: { token: string }): Promise<ProductUpc[]> => {
  const { token } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/admins/flagged-upcs`

  return axios
    .get(url, config)
    .then((response: { data: { data: APIProductUpc[] } }) => {
      return(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiAcceptProductUpc = (params: { token: string, upc: number }): Promise<void> => {
  const { token, upc } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/admins/flagged-upcs/${upc}/accept`

  return axios
    .post(url, {}, config)
    .then(() => {
      toast.success('Notification accepted successfully')
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeclineProductUpc = (params: { token: string, upc: number }): Promise<void> => {
  const { token, upc } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/admins/flagged-upcs/${upc}/decline`

  return axios
    .post(url, {}, config)
    .then(() => {
      toast.success('Notification deleted successfully')
      return
    })
    .catch((error) => {
      throw error
    })
}

// actions
//
//
export const dispatchListProductUpcs = (params: { token: string; }) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: UPC_LOADING_CONSTANT, state: true }))
    return apiListProductUpcs(params).then((resp) => {
      dispatch(reduceListProductUpcs(resp))
      dispatch(reduceSetLoadingState({ name: UPC_LOADING_CONSTANT, state: false }))
    })
  }
}

export const dispatchAcceptProductUpc = (params: { token: string, upc: number }) => {
  return (dispatch: Dispatch) => {
    return apiAcceptProductUpc(params).then(() => {
      dispatch(reduceAcceptProductUpc(params.upc))
    })
  }
}

export const dispatchDeclineProductUpc = (params: { token: string, upc: number }) => {
  return (dispatch: Dispatch) => {
    return apiAcceptProductUpc(params).then(() => {
      dispatch(reduceDeclineProductUpc(params.upc))
    })
  }
}
