import { Text, Table, TableContainer, Tbody, Tr, Td, Thead, Th, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { selectPendingNotificationsById, selectSentNotificationsById } from '../../data/notification-user/slice'
import { reduceSelectNotification } from '../../data/notification-user/slice'
import { useAppSelector } from '../../hooks'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../mini-lib/theme/colors'
import { Notification } from '../../data/notification-user/interfaces'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { dispatchDeleteNotificationForAdmin } from '../../data/notification-admin/api'
import { format, parseISO } from 'date-fns'
import { NotificationAvatar } from '../../mini-lib/notification'
import { orderBy, values } from 'lodash'

export const NotificationsList = () => {
  const pendingNotifications =   orderBy(values(useAppSelector(selectPendingNotificationsById)), ['visibleDate'], ['asc'])
  const sentNotifications =  orderBy(values(useAppSelector(selectSentNotificationsById)), ['visibleDate'], ['desc'])
  return (
    <>
      <Text mt='24px' fontSize='18px' fontWeight='600' variant='body'>Pending notifications</Text>
      <NotificationTable notifications={pendingNotifications} blankListMessage='There are no pending notifications.' showAction />
      <Text mt='33px' fontSize='18px' fontWeight='600' variant='body'>Sent notifications</Text>
      <NotificationTable notifications={sentNotifications} blankListMessage='No notifications have been sent yet.' />
    </>
  )
}

export const NotificationTable = ({ notifications, showAction = false, blankListMessage }: {
  notifications: Notification[]
  showAction?: boolean
  blankListMessage: string
}) => {
  if(notifications && notifications.length === 0){
    return(<Text color={COLORS.shades_neutral_500} marginTop='1rem' align='start' fontSize='18px'>{blankListMessage}</Text>)
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th><Text fontSize='14px' variant="small-subtitle">Content</Text></Th>
            <Th></Th>
            <Th><Text fontSize='14px' variant="small-subtitle">Scheduled for</Text></Th>
            <Th><Text fontSize='14px' variant="small-subtitle">Link</Text></Th>
            <Th><Text fontSize='14px' variant="small-subtitle">User</Text></Th>
            {showAction && <Th><Text fontSize='14px' variant="small-subtitle">Action</Text></Th>}
          </Tr>
        </Thead>
        <Tbody>
          {notifications && notifications.map((notification) => <NotificationRow
            key={notification.id}
            notification={notification}
            showAction={showAction}
          />)}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export const NotificationRow = ({ notification, showAction }: { notification: Notification, showAction: boolean }) => {
  const parsedDate = parseISO(String(notification.visibleDate));
  const formattedDate = format(parsedDate, "MMMM d, yyyy @ h:mm aa");
  const descriptionStyles: any = {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'break-spaces',
    color: COLORS.text_secondary
  }
  return (
    <Tr border="1px solid rgba(0, 0, 0, 0.1)">
      <Td paddingRight='unset'>
        <NotificationAvatar avatarUrl={notification.avatarUrl} />
      </Td>
      <Td paddingLeft='unset'>
        <Text fontWeight='600' w="400px" variant="callout" isTruncated>{notification.title}</Text>
        <Text w="400px" variant="body" css={{ ...descriptionStyles }}>{notification.description}</Text>
      </Td>
      <Td>
        <Text variant="callout">{formattedDate}</Text>
      </Td>
      <Td>
        <Text variant="callout" isTruncated>{notification.url ? 'Yes' : 'No'}</Text>
      </Td>
      <Td>
        <Text w="80px" variant="callout" isTruncated>{notification.visibleTo ?? 'All'}</Text>
      </Td>
      <Td w='100px'>{showAction && <ActionMenu notification={notification} />}</Td>
    </Tr>
  )
}


export const ActionMenu = ({ notification }: { notification: Notification }) => {
  const dispatch = useDispatch()
  const { user: { token } } = UseBaseApiParams()
  const handleEdit = () => dispatch(reduceSelectNotification(notification));;
  const handleDelete = () => dispatch(dispatchDeleteNotificationForAdmin({ token, model: notification }));

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<MaterialIcon color={COLORS.shades_background_400} name="more_vert" />}
        variant="ghost"
      />
      <MenuList>
        <MenuItem icon={<MaterialIcon name="edit" />} onClick={handleEdit}>Edit</MenuItem>
        <MenuItem icon={<MaterialIcon name="delete" />} onClick={handleDelete}>Delete Notification</MenuItem>
      </MenuList>
    </Menu>
  )
}
