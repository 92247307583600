import React from 'react'
import { generatePath, useParams } from 'react-router-dom'
import {
  Text,
  Box,
  Flex
} from '@chakra-ui/react'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { useAppSelector } from '../../hooks'
import { selectAllUserNotificationsById } from '../../data/notification-user/slice'
import { NotificationAvatar, LearnMore } from '../../mini-lib/notification'
import { COLORS } from '../../mini-lib/theme/colors'
import { timeAgo } from '../../mini-lib/utils/basic'
import { ROUTES } from "../../appRoutes";



export const NotificationPage = () => {
  const { salonId } = UseBaseApiParams()
  const params: any = useParams()
  const { notificationId } = params
  const allNotifications = useAppSelector(selectAllUserNotificationsById) ?? {}
  const notification = allNotifications[notificationId] ?? {}

  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Notification', url: generatePath(ROUTES.notifications, { salonId, notificationId }) },
  ]

  const notificationRecieved = timeAgo(notification.visibleDate ?? new Date())

  return (
    <>
      <PageLayout
        variant="full"
        header={
          <PageHeader
            title="View Notification"
            breadcrumbs={breadcrumbs}
          />
        }
        content={
          <>
          <Box h='16px' />
            {!notification.id ? <NoNotification /> : <>
              <Box margin='0px 12% 0px 12%' padding='0px 1rem !important'   borderRadius='8px' background='rgba(156, 158, 239, 0.16)' key={notification.id}>
                <Box h='12px' />
                <Flex justify='space-between' align='center' gridGap='24px'>
                  <NotificationAvatar avatarUrl={notification.avatarUrl} />
                  <Box width='100%'>
                    <Text variant='body' fontWeight='bold'>
                      {notification.title}
                    </Text>
                  </Box>
                  <Flex direction='column'>
                    <Text whiteSpace='nowrap' fontWeight='600' fontSize='12px' color={notification.read ? COLORS.text_secondary : COLORS.lavender_500} >{notificationRecieved}</Text>
                  </Flex>
                </Flex>
                <Box h='12px' />
                {/* <Divider /> */}
              </Box>
              <Box margin='1rem 12% 0px 12%'>
                <Text color={COLORS.text_secondary} variant='body'>
                  {notification.description}
                </Text>
                {notification.url && <LearnMore url={notification.url} read={Boolean(notification.read)} notificationId={notificationId} />}
              </Box>
            </>}
          </>
        }
      />
    </>
  )
}


const NoNotification = () => {
  return (<Text color={COLORS.text_secondary} variant='body'>No notification found</Text>)
}