import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { generatePath } from 'react-router-dom'

import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { InventoryTabs, LINES_TABS } from '../InventoryTabs'
import { useAppSelector } from '../../../hooks'
import {
  reduceSetProductFilters,
  selectProductFilters,
  selectProductListSortedByStockLevel,
} from '../../../data/products/slice'
import { dispatchListProducts } from '../../../data/products/api'
import { InventoryStockAlertsList } from './InventoryStockAlertsList'
import { ProductDetailsSheet } from '../../products/lines/ProductDetailsSheet'
import { ProductFilters } from '../../products/filters/ProductFilters'
import { filterForProductsWithStockIssues, filterProducts } from '../../../data/products/utils'
import { Box } from '@chakra-ui/react'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { EmptySearch } from '../../../mini-lib/empty/EmptySearch'
import { Loading } from '../../../mini-lib/loading/Loading'
import { EMPTY_PRODUCT_FILTERS } from '../../../data/products/constants'
import { ReleaseLaraColors, ReleasePageHelp } from "../../../mini-lib/flags/Release";
import { ROUTES } from "../../../appRoutes";
import { HelpDrawer } from "../../start-guide/common-components/HelpDrawer";
import { VIDEO_MAP } from "../../../mini-lib/video/constants";

export const InventoryStockAlertsPage = () => {
  const dispatch = useDispatch()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Inventory', url: generatePath(ROUTES.inventorySnapshot, { salonId }) },
    { label: 'Stock Alerts' },
  ]

  const products = useAppSelector(selectProductListSortedByStockLevel)
  const filters = useAppSelector(selectProductFilters)

  const releasePageHelp = ReleasePageHelp()
  const productsWithStockLevelIssue = products ? filterForProductsWithStockIssues(products) : null
  const filteredProducts = productsWithStockLevelIssue ? filterProducts(productsWithStockLevelIssue, filters) : null

  const releaseLaraColors = ReleaseLaraColors()
  useEffect(() => {
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, releaseLaraColors])

  // reset filters on page load
  useEffect(() => {
    dispatch(reduceSetProductFilters(EMPTY_PRODUCT_FILTERS))
  }, [dispatch])
  return (
    <>
      {releasePageHelp && (
        <HelpDrawer
          video={VIDEO_MAP.inventoryStockAlerts}
          title={`Let’s take a look inside Stock Alerts`}
          content={`Stock alerts give you up to date data on how much color you have in stock. Once you've set how much color you want to have in stock, the stock alerts will tell you which products are running low.`}
        />
      )}
      <PageLayout variant="full" header={<PageHeader showHelp={true} title="Inventory" breadcrumbs={breadcrumbs} actions={<></>} />}>
        <InventoryTabs selectedTab={LINES_TABS.stockAlerts} />

        <Box h="24px" />
        <ProductFilters showStockLevelFilter={true} showLineFilter={false} />
        <Box h="24px" />
        {filteredProducts && filteredProducts.length > 0 && <InventoryStockAlertsList products={filteredProducts} />}
        {products && products.length === 0 && (
          <EmptyBox h="200px" title="You dont have any products yet" content="please add some products" />
        )}
        {products && products.length > 0 && filteredProducts?.length === 0 && (
          <EmptySearch variant="clear">You have no stock alerts or no products matched your filters</EmptySearch>
        )}
        {!filteredProducts && <Loading />}
      </PageLayout>
      <ProductDetailsSheet />
    </>
  )
}
