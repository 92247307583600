// @flow
import React, { useState } from 'react'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react'
import { TextInput } from "../../mini-lib";

export const OrderNameSheet = (props: {
  show: boolean;
  setShow: (show: boolean) => void
  name: string;
  setName: (show: string) => void
}) => {
  const { name, setName, show, setShow } = props
  const [localName, setLocalName] = useState(name)
  const onSubmit = () => {
    setShow(false)
  }
  return (
    <Drawer placement="right" onClose={() => setShow(false)} isOpen={show}>
      <DrawerOverlay />
      <DrawerContent>
        <>
          <DrawerHeader>
            <Text variant="title2">Edit Order Name</Text>
          </DrawerHeader>
          <DrawerBody>
            <TextInput
              label='Order Name'
              variant='material'
              value={localName}
              onChange={(u) => setLocalName(u)}
              onBlur={(u) => setName(u)}
            />
          </DrawerBody>
          <DrawerFooter>
            <Button minW="100px" variant="round" colorScheme="brand.midnight" onClick={() => onSubmit()}>
              Done
            </Button>
          </DrawerFooter>
        </>
      </DrawerContent>
    </Drawer>
  )
}
