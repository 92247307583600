import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import { selectChecklistItem } from '../../../data/start-guide/slice'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { generatePath } from 'react-router-dom'
import { sumBy } from 'lodash'
import { Box } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { StepContent, StepHeader } from './SGIndexPage'
import { ROUTES } from "../../../appRoutes";

export const ImplementationSteps = (props: { isDefaultOpen: boolean }) => {
  const { isDefaultOpen } = props
  const { salonId } = UseBaseApiParams()
  const [expanded, setExpanded] = useState(isDefaultOpen)

  const sessions = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.learnSessions))
  const academy = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.learnAcademy))

  const steps = [
    {
      label: 'Learn how to create a session',
      complete: sessions.completed,
      link: generatePath(ROUTES.sgSessionsIntro, { salonId }),
    },
    {
      label: 'Successfully Implementing SalonScale',
      complete: academy.completed,
      link: generatePath(ROUTES.sgAcademyIntro, { salonId }),
    },
  ]

  const totalSteps = steps.length
  const stepsComplete = sumBy(steps, 'complete')

  return (
    <Box border={`1px solid ${COLORS.shades_neutral_300}`} borderRadius="15px">
      <StepHeader
        iconName="learn"
        label="Implementation"
        setExpanded={setExpanded}
        expanded={expanded}
        stepsComplete={stepsComplete}
        totalSteps={totalSteps}
      />
      {expanded && <StepContent steps={steps} />}
    </Box>
  )
}

export const sgImplementationStepCodes = [CHECKLIST_CODES.learnSessions, CHECKLIST_CODES.learnAcademy]
