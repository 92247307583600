import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberInput,
  NumberInputField,
  Text,
} from '@chakra-ui/react'

import { Extension } from '../../data/extensions/interfaces'
import { keys } from 'lodash'
import { UseViewSize } from '../../core/UseViewSize'
import { reduceExtensionsSheetVisibility, reduceSetSelectedFilters } from '../../data/extensions/slice'
import { APIUpdateProduct, ProductsByCategoryThenByLineSize, ProductsByLineSize } from '../../data/products/interfaces'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { dispatchDeleteExtensions, dispatchUpdateExtensions } from '../../data/extensions/api'
import { HelpPopover } from '../start-guide/common-components/HelpPopover'
import {
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectStepCompleteDrawerState
} from '../../data/start-guide/slice'
import { buildLoadingName } from '../../core/loading/utils'
import { useAppSelector } from '../../hooks'
import { selectLoadingState } from '../../core/loading/slice'
import { Loading } from '../../mini-lib/loading/Loading'
import { calculateMarkupNumber, getBulkUpdateInputValue, shouldShowBulkUpdateInput } from '../../data/products/utils'
import { HELP_DRAWER_STATES } from '../start-guide/common-components/HelpStepCompleteDrawer'
import { addDollarSign, flattenMapOfListsToList, removeDollarSign, UseQueryParams } from '../../mini-lib/utils/basic'
import { COLORS } from '../../mini-lib/theme/colors'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { getHelpStepIndex } from '../../data/start-guide/utils'
import { dispatchUpdateUserChecklistItem } from "../../data/start-guide/api";
import { getColumnWidth } from "../../mini-lib/table/utils";
import { Icon } from "@chakra-ui/icons";
import { ConfirmPopover } from "../../mini-lib/confirm-popover/ConfirmPopover";
import { SalonScaleProductIcon } from "../../mini-lib/icons/SalonScaleProductIcon";
import { ReleaseLaraColors } from "../../mini-lib/flags/Release";

interface Props {
  sortedFilteredLineCategoryNames: string[]
  extensionsByCategoryThenByLineSize: ProductsByCategoryThenByLineSize
}

export const ExtensionAccordion = ( props: Props ) => {
  const { sortedFilteredLineCategoryNames, extensionsByCategoryThenByLineSize } = props
  return (
    <>
      <Accordion allowToggle defaultIndex={[0]}>
        {sortedFilteredLineCategoryNames.map(( lineCategoryKey, index ) => {
          return (
            <ExtensionAccordionPanel
              lineCategoryName={lineCategoryKey}
              key={lineCategoryKey}
              extensionsByLineSize={extensionsByCategoryThenByLineSize[lineCategoryKey]}
            />
          )
        })}
      </Accordion>
    </>
  )
}

export const ExtensionAccordionPanel = ( {
                                           extensionsByLineSize,
                                         }: {
  lineCategoryName: string
  extensionsByLineSize: ProductsByLineSize
} ) => {
  const extensions: Extension[] = flattenMapOfListsToList(extensionsByLineSize)
  const { isMobile } = UseViewSize()
  return (
    <AccordionItem border="none">
      <AccordionButton
        color="black"
        bg="brand.peach.50"
        borderRadius="8px"
        w="100%"
        p="0 12px"
        mb="8px"
        border="none"
        _focus={{ border: 'none' }}
        _hover={{ bg: 'brand.peach.100' }}
      >
        <Box p="12px" w="100%">
          <Flex align="center" w="100%" gridGap='12px'>
            <SalonScaleProductIcon
              size='32px'
              variant='nobackground'
              theme='peach'
              name='extensions'
              colorhex={COLORS.peach_500}
            />
            <Box textAlign="left">
              <Text variant="title3" w={isMobile ? '225px' : '100%'} fontWeight="normal" isTruncated={true}>
                {extensions[0].category}
              </Text>
            </Box>
          </Flex>
        </Box>
        <AccordionIcon/>
      </AccordionButton>

      <AccordionPanel pb={4}>
        <ExtensionTitleRow/>
        {keys(extensionsByLineSize)
          .sort()
          .map(( key, index ) => {
            const extensions = extensionsByLineSize[key]
            return <Row key={index} index={index} extensions={extensions}/>
          })}
      </AccordionPanel>
    </AccordionItem>
  )
}
export const ExtensionTitleRow = () => {
  const { isMobile, isTablet, isDesktop } = UseViewSize()
  return (
    <Flex gridGap="12px" justify="space-between" pb="24px">
      <Box w={getColumnWidth({ size: 'big', isMobile, isTablet, isDesktop })} color="text.secondary">
        length
      </Box>
      {!isMobile && (
        <Flex justify='center' w={getColumnWidth({ size: 'small', isMobile, isTablet, isDesktop })}
              color="text.secondary">
          subitems
        </Flex>
      )}
      <Flex color="text.secondary" w='120px'>
        cost/unit
      </Flex>
      <Box w={isMobile ? '24px' : '200px'}/>
    </Flex>
  )
}

export const Row = ( props: { index: number; extensions: Extension[] } ) => {
  const { extensions, index } = props
  const { isMobile, isTablet, isDesktop } = UseViewSize()
  const baseExtension = extensions[0]

  return (
    <>
      {baseExtension && (
        <Flex align="center" cursor="pointer" justify="space-between" mb='24px'>
          <TitleColumn
            extension={baseExtension}
            width={getColumnWidth({ size: 'big', isMobile, isTablet, isDesktop })}
          />
          {!isMobile && (
            <NumSubItemsColumn
              numSubItems={extensions.length}
              width={getColumnWidth({ size: 'small', isMobile, isTablet, isDesktop })}
            />
          )}
          <BulkExtensionInputColumn
            rowIndex={index}
            extensions={extensions}
            width='120px'
          />
          <ExtensionRowActions
            rowIndex={index}
            width={isMobile ? '24px' : '200px'}
            extensions={extensions}
          />
        </Flex>
      )}
    </>
  )
}

const TitleColumn = ( { width, extension } ) => {
  return (
    <Flex align="center" gridGap="12px" w={width} paddingRight="12px">
      <SalonScaleProductIcon
        theme='peach'
        name='extensions'
        backgroundhex={COLORS.peach_50}
        colorhex={COLORS.peach_500}
      />
      <Box w={width}>
        <Text>{extension.size}"</Text>
        <Text color={COLORS.text_secondary}>{extension.vendor.name}</Text>
      </Box>
    </Flex>
  )
}

const NumSubItemsColumn = ( { width, numSubItems } ) => {
  return (
    <Flex w={width} justify='center'>
      {numSubItems}
    </Flex>
  )
}

const BulkExtensionInputColumn = ( { width, extensions, rowIndex } ) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const baseExtension = extensions[0]
  const loadingName = buildLoadingName(baseExtension.id)
  const isLoading = useAppSelector(( state ) => selectLoadingState(state, loadingName))
  const showBulkUpdateInput = shouldShowBulkUpdateInput(extensions)
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const initialValue = baseExtension?.inventory?.cost ? baseExtension.inventory.cost.toFixed(2) : '0'
  const bulkInputValue = getBulkUpdateInputValue(extensions)
  const [value, setValue] = useState<any>(initialValue)
  const queryParams: any = UseQueryParams()
  const isGuide = !!queryParams.get('guide')
  const addExtensionsStep = useAppSelector(state => selectChecklistItem(state, CHECKLIST_CODES.addExtensions))
  const releaseLaraColors = ReleaseLaraColors()

  useEffect(() => {
    setValue(bulkInputValue)
  }, [bulkInputValue])

  const onUpdateCost = ( size: any, updatedCost: string ) => {
    const defaultMarkup = user.currentSalonContext?.defaultExtensionMarkupPercentage || 0
    const filteredExtensions = extensions.filter(( extension ) => extension.size === size)
    const currentMarkup = calculateMarkupNumber(extensions[0])
    const updatedExtensions: APIUpdateProduct[] = filteredExtensions.map(( extension ) => {
      return {
        product_id: extension.id,
        wholesale_price: updatedCost ? parseFloat(updatedCost) : 0,
        mark_up: currentMarkup ? currentMarkup : defaultMarkup,
      }
    })
    const params = {
      token: user.token,
      user_id: user.userId,
      salon_id: salonId,
      models: updatedExtensions,
      loadingName: buildLoadingName(updatedExtensions[0].product_id),
      releaseLaraColors
    }
    dispatch(dispatchUpdateExtensions(params))
    if (isGuide && addExtensionsStep && !addExtensionsStep.completed) {
      dispatch(
        dispatchUpdateUserChecklistItem({
          token: user.token,
          checklistItemCode: CHECKLIST_CODES.addExtensions,
        }),
      )
    }
    if (stepCompleteDrawerState !== HELP_DRAWER_STATES.button) {
      dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
    }
  }
  return (
    <Box w={width}>

      {showBulkUpdateInput
        ? <HelpPopover
          guideTypes={[CHECKLIST_CODES.addExtensions]}
          description="Quickly add a cost for all subitems in an extension line here."
          stepIndex={getHelpStepIndex(rowIndex, 0)} // we only want to show for the first item in the list
          placement="bottom"
        >
          <NumberInput
            w="100px"
            minW="100px"
            min={0}
            value={addDollarSign(value)}
            onChange={( updatedPrice ) => setValue(removeDollarSign(updatedPrice))}
            onBlur={( evt: any ) => {
              const cost = removeDollarSign(evt.target.value)
              onUpdateCost(baseExtension.size, cost)
            }}
          >
            {isLoading ? <Loading/> : <NumberInputField p="0 16px" borderRadius="50px"/>}
          </NumberInput>
        </HelpPopover>
        : (
          <Box pl="12px" w="100px" fontStyle="italic">
            multiple
          </Box>
        )}
    </Box>
  )
}

const ExtensionRowActions = ( { width, rowIndex, extensions } ) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const baseExtension = extensions[0]
  const { isMobile } = UseViewSize()
  const releaseLaraColors = ReleaseLaraColors()
  
  const onDelete = () => {
    dispatch(reduceExtensionsSheetVisibility(true))
    dispatch(
      dispatchDeleteExtensions({
        token: user.token,
        salonId,
        models: extensions,
        releaseLaraColors
      }),
    )
  }
  const onViewAll = () => {
    dispatch(reduceExtensionsSheetVisibility(true))
    dispatch(
      reduceSetSelectedFilters({
        size: baseExtension.size,
        lineName: baseExtension.line.name,
        categoryName: baseExtension.category,
      }),
    )
  }
  return (
    <Box w={width}>
      {isMobile
        ? (
          <Menu>
            <MenuButton w="24px">
              <Icon as={() => <i className="material-icons-round">more_vert</i>}/>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={onViewAll}>
                View All
              </MenuItem>
              <ConfirmPopover
                title={`This Action is Permanent`}
                subtitle={`This will remove ${extensions.length} extensions from your salon`}
                onConfirm={onDelete}
              >
                <Text p="12px" w="100px" color="danger" variant="ghost" colorScheme="brand.lavender">
                  Delete
                </Text>
              </ConfirmPopover>
            </MenuList>
          </Menu>
        )
        : (
          <Flex>
            <HelpPopover
              guideTypes={[CHECKLIST_CODES.addExtensions]}
              description="To edit individual extensions, select 'view all'"
              stepIndex={getHelpStepIndex(rowIndex, 1)}
              placement="bottom"
            >
              <Button
                onClick={onViewAll}
                variant="round-ghost"
                colorScheme='brand.lavender'
              >
                View All
              </Button>

            </HelpPopover>
            <ConfirmPopover
              title={`This Action is Permanent`}
              subtitle={`This will remove ${extensions.length} extensions from your salon`}
              onConfirm={onDelete}
            >
              <Button w="100px" colorScheme='brand.lavender' variant="round-ghost">
                Delete
              </Button>
            </ConfirmPopover>
          </Flex>
        )}
    </Box>
  )
}
