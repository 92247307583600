import { Box, Button, Container, Flex, Image } from '@chakra-ui/react'
import * as Yup from 'yup'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { useDispatch } from 'react-redux'
import { APICreateUser } from '../../data/signup/interfaces'
import { Formik } from 'formik'
import { InputControl } from 'formik-chakra-ui'
import React from 'react'
import { Policies } from '../signup/v1/CheckoutPage'
import { FormikPasswordControl } from '../../mini-lib/formik-utils/FormikPasswordControl'
import logo from '../../assets/salonscale-logo-midnight-outline.svg'
import { dispatchStylistInviteSignup } from '../../data/signup/api'
import { useAppSelector } from '../../hooks'
import { selectLoggedInUser } from '../../data/user/slice'
import { Redirect } from 'react-router-dom'

import { ROUTES } from "../../appRoutes";

export const StylistInviteSignupPage = () => {
  const dispatch = useDispatch()
  const queryParams = UseQueryParams()
  const inviteToken = queryParams.get('invite-token') || ''
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    bookingSoftware: '',
  }
  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required').min(2, 'You need at least 2 characters'),
    lastName: Yup.string().required('Last Name is required').min(2, 'You need at least 2 characters'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    password: Yup.string().required('Password is required').min(8, 'You need at least 8 characters'),
  })
  const onSignup = (values: { firstName: string; lastName: string; email: string; password: string }) => {
    const request: APICreateUser = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      invitation_token: inviteToken,
    }
    dispatch(dispatchStylistInviteSignup({ request }))
  }

  const loggedInUser = useAppSelector(selectLoggedInUser)
  if (loggedInUser) {
    return <Redirect to={ROUTES.base} />
  }
  return (
    <Container centerContent={true}>
      <Box h="24px" />
      <Image h="100px" src={logo} />
      <Box h="24px" />
      <Flex
        direction="column"
        align="center"
        justify="center"
        maxW="450px"
        borderRadius="15px"
        borderWidth="1px"
        borderColor="shades.neutral.200"
        p="12px"
      >
        <Formik onSubmit={onSignup} initialValues={initialValues} validationSchema={validationSchema}>
          {({ handleSubmit, values, resetForm, errors, setFieldValue }) => (
            <>
              <Flex gridGap="12px" direction="column" onSubmit={handleSubmit as any} w="100%">
                <InputControl
                  w="100%"
                  name="firstName"
                  label="First Name"
                  inputProps={{ autoFocus: true, borderRadius: '100px' }}
                />
                <InputControl inputProps={{ borderRadius: '100px' }} w="100%" name="lastName" label="Last Name" />
                <InputControl inputProps={{ borderRadius: '100px' }} w="100%" name="email" label="Email" />
                <FormikPasswordControl isDisabled={false} w="100%" name="password" type="password" label="Password" />
                <Box h="12px" />
              </Flex>
              <Box h="24px" />
              <Policies />
              <Button colorScheme="brand.lavender" w="100%" onClick={() => handleSubmit()}>
                Sign Up
              </Button>
            </>
          )}
        </Formik>
      </Flex>
    </Container>
  )
}
