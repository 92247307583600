import React, { useEffect, useState } from 'react'
import { dispatchListSalonMembers } from '../../data/user/api'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { selectMemberList } from '../../data/user/slice'
import { filterSalonMembers } from '../../data/user/utils'
import { SalonMember, SalonMemberLara } from '../../data/user/interfaces'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Loading } from '../../mini-lib/loading/Loading'
import { Box } from '@chakra-ui/react'
import type { AppDispatch, RootState } from '../../store'
import { SelectInput } from "../../mini-lib";

/**
 * @deprecated This component is deprecated and will be removed in future releases. Use `UserAutocomplete` instead.
 */
export const StylistAutocompleteDeprecated = (props: {
  initialStylist?: SalonMember | SalonMemberLara | null
  onSelect: (SalonMember) => void
  width?: string
  isClearable?: boolean
  disabled?: boolean
  // TODO - remove salonMemberSelector, dispatchForSalonMembers, filterForSalonMembers once users api completely replaced
  salonMemberSelector?: (state: RootState) => SalonMember[] | SalonMemberLara[] | null
  dispatchForSalonMembers?: () => (dispatch: AppDispatch) => Promise<void>
  filterForSalonMembers?: any
  reloadOnEveryMount?: boolean
  label?: string
}) => {
  const dispatch = useDispatch()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const {
    initialStylist,
    onSelect,
    width = '350px',
    isClearable = true,
    disabled = false,
    salonMemberSelector = selectMemberList,
    dispatchForSalonMembers = () => dispatchListSalonMembers(token, salonId),
    filterForSalonMembers = filterSalonMembers,
    reloadOnEveryMount = false,
    label = 'Stylist'
  } = props

  const models = useAppSelector(salonMemberSelector)
  const [searchText, setSearchText] = useState('')

  const [selectedStylist, setSelectedStylist] = useState<SalonMember | SalonMemberLara | null>( initialStylist || null)
  const filteredModels = models ? filterForSalonMembers(models, searchText) : []
  const modelsLoaded = !!models

  useEffect(() => {
    if (!modelsLoaded || reloadOnEveryMount) {
      dispatch(dispatchForSalonMembers())
    }
    // looks like eslint is unable to recognize and ignore a dispatch method as a dep here
    // todo: remove this eslint disable when we remove the old dispatchListSalonMembers
    // eslint-disable-next-line
  }, [dispatch, modelsLoaded, token, salonId, reloadOnEveryMount])

  const buildLabel = (member: SalonMemberLara | SalonMember) => member.firstName + ' ' + member.lastName
  const dropdownOptions: { value: SalonMemberLara | SalonMember; label: string }[] | null = models
    ? filteredModels.map((model) => {
      return { value: model, label: buildLabel(model) }
    })
    : null

  // const defaultSelected = initialStylist
  //   ? dropdownOptions?.find((option) => option.value.userId === initialStylist.userId)
  //   : null

  return (
    <Box w={width} maxW="100%">
      {disabled && (
        <Box w="100%" isTruncated={true}>
          {initialStylist ? `${initialStylist.firstName} ${initialStylist.lastName}` : ''}
        </Box>
      )}
      {!disabled && dropdownOptions && (
        <SelectInput
          w='100%'
          variant='material'
          border='rounded'
          value={selectedStylist}
          placeholder="Search Clients"
          label={label}
          options={dropdownOptions}
          onInputChange={(searchText) => setSearchText(searchText)}
          onChange={(option: SalonMember) => {
            if (option) {
              setSelectedStylist(option)
              onSelect(option)
            } else {
              setSelectedStylist(null)
              onSelect(null)
            }
          }}
          allowFocus={true}
          isClearable={isClearable}
        />
      )}
      {!dropdownOptions && <Loading />}
    </Box>
  )
}
