import { Flex } from '@chakra-ui/react'
import React from 'react'

export type IconName =
  | 'orders'
  | 'average'
  | 'home'
  | 'profile'
  | 'sessions'
  | 'stylists'
  | 'clients'
  | 'labor'
  | 'products'
  | 'reports'
  | 'settings'
  | 'tutorials'
  | 'startGuide'
  | 'implementation'
  | 'getToKnowSalonScale'
  | 'learn'
  | 'solo'
  | 'team'
  | 'multiSalon'
  | 'stylist'

export type IconState = 'Selected' | 'NotSelected'

export const SalonScaleIcon = (
  props: { name: IconName; state: IconState; theme?: 'Light' | 'Dark'; size?: string; colorhex?: string } | any,
) => {
  const { name, state = 'Selected', theme = 'Light', size = '24px', colorhex = '' } = props

  const iconImgKey = `${name}${theme}${state}`
  const defaultIcon = iconNameToImgPathMap['averageDarkNotSelected']
  const imgImport = iconImgKey && iconImgKey in iconNameToImgPathMap ? iconNameToImgPathMap[iconImgKey] : defaultIcon
  return (
    <Flex h={size} {...props} align="center">
      <img
        style={{
          height: size,
          width: size,
          fontSize: size,
          color: colorhex,
        }}
        src={imgImport}
        alt="iconimg"
      />
    </Flex>
  )
}

const iconNameToImgPathMap = {
  averageDarkNotSelected: require('../../assets/icons/Average-Dark-NotSelected.png').default,
  homeDarkNotSelected: require('../../assets/icons/Home-Dark-NotSelected.png').default,
  ordersDarkNotSelected: require('../../assets/icons/Orders-Dark-NotSelected.png').default,
  profileDarkNotSelected: require('../../assets/icons/Profile-Dark-NotSelected.png').default,
  sessionsDarkNotSelected: require('../../assets/icons/Sessions-Dark-NotSelected.png').default,
  stylistsDarkNotSelected: require('../../assets/icons/Stylists-Dark-NotSelected.png').default,
  averageDarkSelected: require('../../assets/icons/Average-Dark-Selected.png').default,
  homeDarkSelected: require('../../assets/icons/Home-Dark-Selected.png').default,
  ordersDarkSelected: require('../../assets/icons/Orders-Dark-Selected.png').default,
  profileDarkSelected: require('../../assets/icons/Profile-Dark-Selected.png').default,
  sessionsDarkSelected: require('../../assets/icons/Sessions-Dark-Selected.png').default,
  stylistsDarkSelected: require('../../assets/icons/Stylists-Dark-Selected.png').default,
  averageLightNotSelected: require('../../assets/icons/Average-Light-NotSelected.png').default,
  homeLightNotSelected: require('../../assets/icons/Home-Light-NotSelected.png').default,
  ordersLightNotSelected: require('../../assets/icons/Orders-Light-NotSelected.png').default,
  profileLightNotSelected: require('../../assets/icons/Profile-Light-NotSelected.png').default,
  sessionsLightNotSelected: require('../../assets/icons/Sessions-Light-NotSelected.png').default,
  stylistsLightNotSelected: require('../../assets/icons/Stylists-Light-NotSelected.png').default,
  averageLightSelected: require('../../assets/icons/Average-Light-Selected.png').default,
  homeLightSelected: require('../../assets/icons/Home-Light-Selected.png').default,
  ordersLightSelected: require('../../assets/icons/Orders-Light-Selected.png').default,
  profileLightSelected: require('../../assets/icons/Profile-Light-Selected.png').default,
  sessionsLightSelected: require('../../assets/icons/Sessions-Light-Selected.png').default,
  stylistsLightSelected: require('../../assets/icons/Stylists-Light-Selected.png').default,
  clientsDarkNotSelected: require('../../assets/icons/Clients-Dark-NotSelected.png').default,
  laborDarkNotSelected: require('../../assets/icons/Labor-Dark-NotSelected.png').default,
  productsDarkNotSelected: require('../../assets/icons/Products-Dark-NotSelected.png').default,
  reportsDarkNotSelected: require('../../assets/icons/Reports-Dark-NotSelected.png').default,
  settingsDarkNotSelected: require('../../assets/icons/Settings-Dark-NotSelected.png').default,
  tutorialsDarkNotSelected: require('../../assets/icons/Tutorials-Dark-NotSelected.png').default,
  clientsDarkSelected: require('../../assets/icons/Clients-Dark-Selected.png').default,
  laborDarkSelected: require('../../assets/icons/Labor-Dark-Selected.png').default,
  productsDarkSelected: require('../../assets/icons/Products-Dark-Selected.png').default,
  reportsDarkSelected: require('../../assets/icons/Reports-Dark-Selected.png').default,
  settingsDarkSelected: require('../../assets/icons/Settings-Dark-Selected.png').default,
  tutorialsDarkSelected: require('../../assets/icons/Tutorials-Dark-Selected.png').default,
  clientsLightNotSelected: require('../../assets/icons/Clients-Light-NotSelected.png').default,
  laborLightNotSelected: require('../../assets/icons/Labor-Light-NotSelected.png').default,
  productsLightNotSelected: require('../../assets/icons/Products-Light-NotSelected.png').default,
  reportsLightNotSelected: require('../../assets/icons/Reports-Light-NotSelected.png').default,
  settingsLightNotSelected: require('../../assets/icons/Settings-Light-NotSelected.png').default,
  tutorialsLightNotSelected: require('../../assets/icons/Tutorials-Light-NotSelected.png').default,
  clientsLightSelected: require('../../assets/icons/Clients-Light-Selected.png').default,
  laborLightSelected: require('../../assets/icons/Labor-Light-Selected.png').default,
  productsLightSelected: require('../../assets/icons/Products-Light-Selected.png').default,
  reportsLightSelected: require('../../assets/icons/Reports-Light-Selected.png').default,
  settingsLightSelected: require('../../assets/icons/Settings-Light-Selected.png').default,
  tutorialsLightSelected: require('../../assets/icons/Tutorials-Light-Selected.png').default,

  startGuideLightSelected: require('../../assets/icons/StartGuide-Light-Selected.png').default,
  startGuideLightNotSelected: require('../../assets/icons/StartGuide-Light-NotSelected.png').default,
  startGuideDarkSelected: require('../../assets/icons/StartGuide-Dark-Selected.png').default,
  startGuideDarkNotSelected: require('../../assets/icons/StartGuide-Dark-NotSelected.png').default,

  getToKnowSalonScaleLightSelected: require('../../assets/icons/GetToKnowSalonScale-Light-Selected.png').default,
  getToKnowSalonScaleLightNotSelected: require('../../assets/icons/GetToKnowSalonScale-Light-NotSelected.png').default,
  getToKnowSalonScaleDarkSelected: require('../../assets/icons/GetToKnowSalonScale-Dark-Selected.png').default,
  getToKnowSalonScaleDarkNotSelected: require('../../assets/icons/GetToKnowSalonScale-Dark-NotSelected.png').default,

  implementationLightSelected: require('../../assets/icons/Implementation-Light-Selected.png').default,
  implementationLightNotSelected: require('../../assets/icons/Implementation-Light-NotSelected.png').default,
  implementationDarkSelected: require('../../assets/icons/Implementation-Dark-Selected.png').default,
  implementationDarkNotSelected: require('../../assets/icons/Implementation-Dark-NotSelected.png').default,

  learnLightSelected: require('../../assets/icons/Learn-Light-Selected.png').default,
  learnLightNotSelected: require('../../assets/icons/Learn-Light-NotSelected.png').default,
  learnDarkSelected: require('../../assets/icons/Learn-Dark-Selected.png').default,
  learnDarkNotSelected: require('../../assets/icons/Learn-Dark-NotSelected.png').default,

  soloLightSelected: require('../../assets/icons/solo-salon.png').default,
  teamLightSelected: require('../../assets/icons/team-salon.png').default,
  multiSalonLightSelected: require('../../assets/icons/multi-salon.png').default,
  stylistLightSelected: require('../../assets/icons/salon-stylist.png').default,
}

