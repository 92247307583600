// ref - https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/foundations
import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { COLORS } from './mini-lib/theme/colors'
import { FONTS } from "./mini-lib/theme/fonts";

export type ThemeType = 'lavender' | 'peach' | 'linen' | 'midnight' | 'skylight' | 'basic'

// this is not a chakra component but all the theme variables should be in this file
export const autoCompleteTheme = {
  control: ( styles ) => {
    return {
      ...styles,
      padding: '0 8px',
      boxShadow: 'none',
      borderRadius: '100px',
      '&:hover': { borderColor: COLORS.shades_neutral_400 }, // border style on hover
      border: `1px solid ${COLORS.shades_neutral_400}`, // default border color
    }
  },
  option: ( styles, { data, isDisabled, isFocused, isSelected } ) => {
    return {
      ...styles,
    }
  },
}

// this is not a chakra component but all the theme variables should be in this file
export const selectTheme = ( params: {
  variant?: 'material',
  theme?: 'lavender' | 'peach' | 'linen' | 'midnight' | 'skylight' | string,
  hasValue?: boolean,
  hasError?: boolean
} ) => {
  const { variant = '', theme = '', hasValue = false, hasError = false } = params
  const themeVeryDarkhex = theme ? COLORS[`${theme}_700`] : COLORS.shades_neutral_200
  const themeDarkhex = theme ? COLORS[`${theme}_500`] : 'black'
  const themeMedhex = theme ? COLORS[`${theme}_100`] : 'white'
  const themeLighthex = theme ? COLORS[`${theme}_50`] : COLORS.shades_neutral_50
  const themeBorderHex = theme && hasValue ? COLORS[`${theme}_500`] : COLORS.shades_neutral_200
  return {
    control: ( defaultStyles, { hasValue, ...rest } ) => {
      return {
        ...defaultStyles,
        cursor: 'pointer',
        padding: '0 8px',
        boxShadow: 'none',
        borderRadius: '100px',
        '&:hover': { borderColor: themeVeryDarkhex }, // border style on hover
        '&:focus': { borderColor: themeVeryDarkhex }, // border style on hover
        background: hasValue ? variant === 'material' ? '' : themeMedhex : '',
        color: hasValue ? variant === 'material' ? 'black' : themeDarkhex : '',
        border: hasError
          ? `2px solid ${COLORS.danger}`
          : variant === 'material' ? `1px solid ${COLORS.shades_neutral_300}` : `1px solid ${themeBorderHex}`,
      }
    },
    option: ( defaultStyles, { isSelected, isFocused, ...rest } ) => {
      return {
        ...defaultStyles,
        color: 'black',
        background: isSelected ? themeMedhex : isFocused ? themeLighthex : '',
        '&:hover': { background: themeLighthex },
      }
    },
    menu: ( base ) => ( {
      ...base,
    } ),
    singleValue: ( defaultStyles ) => ( {
      ...defaultStyles,
      color: variant === 'material' ? 'black' : themeDarkhex,
    } ),
    input: ( defaultStyles, { hasValue } ) => ( {
      ...defaultStyles,
      color: themeDarkhex,
    } ),
    valueContainer: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        color: hasValue ? 'white' : '',
        textOverflow: 'ellipsis',

        /* Required for text-overflow to do anything */
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&:hover': { color: hasValue ? themeVeryDarkhex : '' },
      }
    },
    placeholder: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        color: hasValue ? 'white' : COLORS.placeholder,
        '&:hover': { color: hasValue ? themeVeryDarkhex : '' },
      }
    },
    dropdownIndicator: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        padding: '0',
        color: hasValue ? 'white' : 'black',
        '&:hover': { color: themeVeryDarkhex },
      }
    },
    indicatorSeparator: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        fontSize: '12px',
        padding: '0',
        color: hasValue ? 'white' : themeDarkhex,
        '&:hover': { color: hasValue ? themeVeryDarkhex : '' },
      }
    },
    clearIndicator: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        padding: '0',
        color: hasValue ? variant === 'material' ? COLORS.text_secondary : themeDarkhex : '',
        '&:hover': { color: hasValue ? themeVeryDarkhex : '' },
      }
    },
  }
}

export const buttonStyles = ( params: { colorHex?: string } ) => {
  const { colorHex = COLORS.lavender_500 } = params
  return {
    padding: '6px 12px',
    minWidth: '100px',
    borderRadius: '50px',
    fontSize: '16px',
    background: COLORS.shades_neutral_100,
    color: colorHex,
    cursor: 'pointer',
    justifyContent: 'center',
    fontWeigh: 'bold',
    gridGap: '6px',
  }
}

// this is not a chakra component but all the theme variables should be in this file
export const dropdownGhostVariantStyles = (
  theme?: 'lavender' | 'peach' | 'linen' | 'midnight' | 'skylight' | string,
) => {
  const themeDark = 'black'
  const extendsTheme = selectTheme({ theme })
  return {
    ...extendsTheme,
    control: ( defaultStyles, { hasValue, ...rest } ) => {
      return {
        ...defaultStyles,
        cursor: 'pointer',
        fontSize: '16px',
        padding: '0 8px 0 4px',
        boxShadow: 'none',
        minHeight: '20px',
        border: 'unset',
        backgroundColor: 'unset'
      }
    },
    singleValue: ( defaultStyles ) => ( {
      ...defaultStyles,
      gridArea: 'unset',
      marginLeft: 'unset',
      marginRight: 'unset',
      maxWidth: 'unset',
      overflow: 'unset',
      marginTop: '2px',
      color: themeDark,
    } ),
    input: ( defaultStyles, { hasValue } ) => ( {
      ...defaultStyles,
      margin: 'unset',
      paddingBottom: 'unset',
      paddingTop: 'unset',
      display: 'unset',
      gridArea: 'unset',
      gridTemplateColumns: 'unset',
      marginTop: '-17px',
      color: themeDark,
    } ),
    valueContainer: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        color: hasValue ? 'white' : '',
        textOverflow: 'ellipsis',
        padding: 'unset',
        height: '20px',
        /* Required for text-overflow to do anything */
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }
    },
    menuPortal: ( provided ) => ( { ...provided, zIndex: 9999 } ),
    menu: ( provided ) => ( { ...provided, zIndex: 9999 } ),
  }
}

export const dropdownGhostVariant2Styles = (
  theme?: 'lavender' | 'peach' | 'linen' | 'midnight' | 'skylight' | string,
) => {
  const themeVeryDarkhex = COLORS.shades_neutral_200
  const themeDarkhex = COLORS.text_primary

  const extendsTheme = selectTheme({ theme })

  return {
    ...extendsTheme,
    control: ( defaultStyles, { hasValue, ...rest } ) => {
      return {
        ...defaultStyles,
        cursor: 'pointer',
        fontSize: '16px',
        padding: '0px',
        boxShadow: 'none',
        '&:hover': { borderColor: themeVeryDarkhex }, // border style on hover
        background: 'unset',
        color: hasValue ? themeDarkhex : '',
        border: 'unset',
        height: '20px',
        minHeight: '20px'
      }
    },
    singleValue: ( defaultStyles ) => ( {
      ...defaultStyles,
      color: themeDarkhex,
    } ),
    input: ( defaultStyles, { hasValue } ) => ( {
      ...defaultStyles,
      color: themeDarkhex,
    } ),
    valueContainer: ( defaultStyles: any, { hasValue } ) => {
      return {
        ...defaultStyles,
        color: hasValue ? 'white' : '',
        textOverflow: 'ellipsis',
        /* Required for text-overflow to do anything */
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&:hover': { color: hasValue ? themeVeryDarkhex : '' },
        padding: 'unset'
      }
    },
  }
}

export const ColorForMode = (
  type:
    | 'base-bg'
    | 'base-text'
    | 'secondary-text'
    | 'header-bg'
    | 'card-bg'
    | 'secondary-bg'
    | 'brand-light'
    | 'primary',
): string => {
  if (type === 'base-bg') {
    return 'brand.lavender.100'
  }
  if (type === 'base-text') {
    return 'black'
  } else if (type === 'secondary-text') {
    return 'rgba(0, 0, 0, 0.54)'
  } else if (type === 'secondary-bg') {
    return '#fcfcfc'
  } else if (type === 'primary') {
    return COLORS.lavender_500
  } else if (type === 'header-bg') {
    return '#f2f2f2'
  } else if (type === 'card-bg') {
    return 'white'
  } else if (type === 'brand-light') {
    return 'brand.lavender.100'
  }

  return ''
}

const config = {
  initialColorMode: 'light', // 'dark' | 'light'
  useSystemColorMode: false,
}

// ref - https://chakra-ui.com/docs/theming/customize-theme#customizing-single-components
export const buttonTheme = {
  baseStyle: {
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '500',
  },
  defaultProps: {
    colorScheme: 'brand.lavender',
  },
  variants: {
    'salonscale-special': {
      bg: 'red.400',
      boxShadow: '0 0 2px 2px #efdfde',
    },
    round: ( props ) => {
      const { colorScheme } = props
      return {
        minWidth: '100px',
        textTransform: 'uppercase',
        borderRadius: '50px',
        bg: colorScheme === 'brand.midnight' ? `${colorScheme}.900` : `${colorScheme}.500`,
        color: 'white',
        fontSize: '16px',
        fontFamily: 'Oswald, sans-serif',
      }
    },

    'round-large': ( props ) => {
      const { colorScheme } = props
      return {
        minWidth: '100px',
        textTransform: 'uppercase',
        borderRadius: '50px',
        bg: colorScheme === 'brand.midnight' ? `${colorScheme}.900` : `${colorScheme}.500`,
        h: '64px',
        color: 'white',
        fontSize: '26px',
        fontFamily: 'Oswald, sans-serif',
      }
    },
    'round-outline': ( props ) => {
      const { colorScheme } = props

      //  special case midnight as normally we'd want 500 not 900, this will be default styling
      if (colorScheme === 'brand.midnight') {
        return {
          minWidth: '100px',
          textTransform: 'uppercase',
          borderRadius: '50px',
          border: '1px solid',
          borderColor: `${COLORS.shades_neutral_300}`,
          color: `${colorScheme}.900`,
          fontSize: '16px',
          fontFamily: 'Oswald, sans-serif',
        }
      } else {
        return {
          minWidth: '100px',
          textTransform: 'uppercase',
          borderRadius: '50px',
          border: '2px solid',
          borderColor: `${COLORS.shades_neutral_300}`,
          color: `${colorScheme}.500`,
          fontSize: '16px',
          fontFamily: 'Oswald, sans-serif',
        }
      }
    },
    'round-border': ( props ) => {
      const { colorScheme } = props

      //  special case midnight as normally we'd want 500 not 900, this will be default styling
      if (colorScheme === 'brand.midnight') {
        return {
          minWidth: '100px',
          textTransform: 'uppercase',
          borderRadius: '50px',
          border: '1px solid',
          borderColor: `${colorScheme}.500`,
          color: `${colorScheme}.900`,
          fontSize: '16px',
          fontFamily: 'Oswald, sans-serif',
        }
      } else {
        return {
          minWidth: '100px',
          textTransform: 'uppercase',
          borderRadius: '50px',
          border: '2px solid',
          borderColor: `${colorScheme}.500`,
          color: `${colorScheme}.500`,
          fontSize: '16px',
          fontFamily: 'Oswald, sans-serif',
        }
      }
    },
    'round-outline-lower': ( props ) => {
      const { colorScheme } = props
      return {
        borderRadius: '50px',
        border: '1px solid',
        borderColor: `${colorScheme}.500`,
        color: `${colorScheme}.500`,
        fontSize: '16px',
      }
    },
    'round-ghost': ( props ) => {
      const { colorScheme } = props
      return {
        _hover: {
          bg: `${colorScheme}.50`,
        },
        minWidth: '100px',
        borderRadius: '50px',
        color: `${colorScheme}.500`,
        fontSize: '16px',
      }
    },
    'round-ghost-upper': ( props ) => {
      const { colorScheme } = props
      if (colorScheme === 'brand.midnight') {
        return {
          _hover: {
            bg: `${colorScheme}.50`,
          },
          minWidth: '100px',
          textTransform: 'uppercase',
          borderRadius: '50px',
          color: `${colorScheme}.900`,
          fontSize: '16px',
          fontFamily: 'Oswald, sans-serif',
        }
      }
      return {
        _hover: {
          bg: `${colorScheme}.50`,
        },
        minWidth: '100px',
        textTransform: 'uppercase',
        borderRadius: '50px',
        color: `${colorScheme}.500`,
        fontSize: '16px',
        fontFamily: 'Oswald, sans-serif',
      }
    },
    'round-ghost-lower': ( props ) => {
      const { colorScheme } = props
      return {
        _hover: {
          bg: `${colorScheme}.50`,
        },
        minWidth: '100px',
        textTransform: 'lowercase',
        borderRadius: '50px',
        color: `${colorScheme}.500`,
        fontSize: '16px',
      }
    },
  },
  shadows: {
    outline: `0 0 0 3px ${COLORS.lavender_200}`,
  },
}

export const accordionButtonTheme = {
  _focus: {
    boxShadow: 'none',
  },
}
export const switchTheme = {
  // style object for base or default style
  baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for `size` and `variant`
  defaultProps: {
    size: '',
  },
}
export const tabTheme = {
  variants: {
    line: {
      tab: {
        _focus: {
          borderColor: 'none',
          boxShadow: 'none',
        },
      },
    },
  },
}

export const textTheme = {
  sizes: {
    l: {},
  },
  variants: {
    gigantictext: {
      fontSize: '64px',
      fontWeight: 'bold',
      fontFamily: 'Oswald,  sans-serif',
      textTransform: 'uppercase',
    },
    giganticsubtitle: {
      fontSize: '48px',
      fontFamily: 'Oswald,  sans-serif',
      textTransform: 'uppercase',
      lineHeight: '106%',
    },
    largetitle: { fontSize: '28px', fontWeight: 'bold', fontFamily: 'Oswald,  sans-serif', lineHeight: '118%' },
    largetitlemobile: { fontSize: '20px', fontWeight: 'bold', fontFamily: 'Oswald,  sans-serif', lineHeight: '118%' },
    title1: {
      fontSize: '28px',
      fontFamily: 'Oswald,  sans-serif',
      textTransform: 'uppercase',
      lineHeight: '126%',
    },
    title1mobile: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'Oswald,  sans-serif',
      textTransform: 'uppercase',
      lineHeight: '126%',
    },
    title2: {
      fontSize: '22px',
      fontWeight: 'bold',
      fontFamily: 'Oswald,  sans-serif',
      textTransform: 'uppercase',
      lineHeight: '126%',
    },
    title2mobile: {
      fontSize: '16px',
      fontWeight: 'bold',
      fontFamily: 'Oswald,  sans-serif',
      textTransform: 'uppercase',
      lineHeight: '126%',
    },
    'title2-desktop-bold': { fontSize: '20px', fontWeight: 'bold' },
    title3: { fontSize: '20px', fontWeight: 'bold', fontFamily: 'Oswald,  sans-serif', lineHeight: '126%' },
    'default/regular/title3': { fontSize: '20px', fontWeight: '600', fontFamily: FONTS.openSans, lineHeight: '24px' },
    title3opensans: { fontSize: '15px', lineHeight: '20px', fontFamily: 'Open Sans, sans-serif' },
    title3mobile: { fontSize: '16px', fontWeight: 'bold', fontFamily: 'Oswald,  sans-serif', lineHeight: '126%' },
    footnote: { fontSize: '13px', lineHeight: '126%' },
    headline: { fontSize: '14px', fontStyle: 'italic', color: 'shades.neutral.500' },
    caption1: { fontSize: '12px', letterSpacing: '2px', color: 'shades.neutral.500', textTransform: 'uppercase' },
    caption2: { fontSize: '11px', lineHeight: '13px', fontWeight: 'bold' },
    callout: { fontSize: '16px', lineHeight: '21px' },
    'callout-desktop-regular': { fontSize: '14px', color: COLORS.text_secondary, fontWeight: 400 },
    body: { fontSize: '15px', lineHeight: '20px', fontFamily: 'Open Sans, sans-serif' },
    button: { fontSize: '16px', fontWeight: '500', fontFamily: 'Oswald,  sans-serif', textTransform: 'uppercase' },
    'small-subtitle': { fontSize: '12px', fontWeight: '400', color: 'shades.neutral.500' },
    round: ( props ) => {
      const { colorScheme } = props
      return {
        textAlign: 'center',
        padding: '6px',
        minWidth: '100px',
        textTransform: 'uppercase',
        borderRadius: '50px',
        border: '2px solid',
        bg: colorScheme === 'brand.midnight' ? `${colorScheme}.900` : `${colorScheme}.500`,
        color: 'white',
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Oswald, sans-serif',
      }
    },
    'round-outline': ( props ) => {
      const { colorScheme } = props
      return {
        textAlign: 'center',
        padding: '6px',
        minWidth: '100px',
        textTransform: 'uppercase',
        borderRadius: '50px',
        border: '2px solid',
        borderColor: `${COLORS.shades_neutral_300}`,
        color: `${colorScheme}.500`,
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Oswald, sans-serif',
      }
    },
    'round-ghost': ( props ) => {
      const { colorScheme } = props
      return {
        textAlign: 'center',
        padding: '6px',
        minWidth: '100px',
        textTransform: 'uppercase',
        borderRadius: '50px',
        color: `${colorScheme}.500`,
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Oswald, sans-serif',
      }
    },
  },
}

const baseInputVariant = () => ( {
  field: {
    _focus: {
      borderColor: COLORS.lavender_200,
      boxShadow: `0 0 0 3px ${COLORS.lavender_200}`,
    },
  },
} )

const roundInputVariant = () => ( {
  field: {
    _focus: {
      borderColor: COLORS.lavender_200,
      boxShadow: `0 0 0 3px ${COLORS.lavender_200}`,
    },
    border: `1px solid ${COLORS.shades_neutral_300}`,
    borderRadius: '100px',
  },
} )

export const inputTheme = {
  border: '#ffffff',
  opacity: '.12',
  shadows: {
    outline: `0 0 0 3px ${COLORS.lavender_200}`,
  },
  _focus: {
    borderColor: COLORS.lavender_200,
    boxShadow: `0 0 0 3px ${COLORS.lavender_200}`,
  },
  defaultProps: {
    focusBorderColor: 'brand.lavender.200',
  },
  variants: {
    outline: baseInputVariant,
    filled: baseInputVariant,
    flushed: baseInputVariant,
    round: roundInputVariant,
  },
}

export const chakraSelectTheme = {
  shadows: {
    outline: `0 0 0 3px ${COLORS.lavender_200}`,
  },
  _focus: {
    borderColor: COLORS.lavender_200,
    boxShadow: `0 0 0 3px ${COLORS.lavender_200}`,
  },
  defaultProps: {
    focusBorderColor: 'brand.lavender.200',
  },
  variants: {
    round: ( props ) => {
      const { colorScheme } = props
      return {
        borderRadius: '50px',
        border: '1px solid',
        borderColor: `${colorScheme}.500`,
        color: `${colorScheme}.500`,
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Oswald, sans-serif',
      }
    },
  },
}
export const drawerTheme = {
  variants: {
    // this allows a drawer to stay open while you do other things on a page
    // uses: help/info drawer open explaining how to do things
    alwaysOpen: {
      dialog: {
        pointerEvents: 'auto',
      },
      dialogContainer: {
        pointerEvents: 'none',
      },
    },
  },
}
export const BRAND_THEMES = {
  lavender: 'lavender',
  skylight: 'skylight',
  midnight: 'midnight',
  linen: 'linen',
}

// example theme stuff - https://codesandbox.io/s/chakra-ui-theme-extension-w5u2n?file=/src/theme/Button/index.js:322-351
// valid 2022-04-06
export const chakraTheme: ThemeConfig = extendTheme({
  config,
  // this sets the active state for all buttons
  shadows: {
    outline: `0 0 0 3px ${COLORS.lavender_200}`,
  },

  colors: {
    // base styles
    primary: COLORS.lavender_500,
    secondary: COLORS.lavender_400,
    success: COLORS.success_500,
    highlight: COLORS.highlight,
    warning: COLORS.warning,
    danger: COLORS.danger,

    // text styles
    text: {
      secondary: COLORS.text_secondary,
    },

    // brand styles
    brand: {
      lavender: {
        50: COLORS.lavender_50,
        100: COLORS.lavender_100,
        200: COLORS.lavender_200,
        300: COLORS.lavender_300,
        400: COLORS.lavender_400,
        500: COLORS.lavender_500,
        600: COLORS.lavender_600,
        700: COLORS.lavender_700,
        800: COLORS.lavender_800,
        900: COLORS.lavender_900,
      },
      peach: {
        50: COLORS.peach_50,
        100: COLORS.peach_100,
        200: COLORS.peach_200,
        300: COLORS.peach_300,
        400: COLORS.peach_400,
        500: COLORS.peach_500,
        600: COLORS.peach_600,
      },
      linen: {
        50: COLORS.linen_50,
        100: COLORS.linen_100,
        200: COLORS.linen_200,
        300: COLORS.linen_300,
        400: COLORS.linen_400,
        500: COLORS.linen_500,
        600: COLORS.linen_600,
      },
      skylight: {
        50: COLORS.skylight_50,
        100: COLORS.skylight_100,
        200: COLORS.skylight_200,
        500: COLORS.skylight_500,
        600: COLORS.skylight_600,
        700: COLORS.skylight_700,
        800: COLORS.skylight_800,
        900: COLORS.skylight_900,
      },
      midnight: {
        50: COLORS.midnight_50,
        500: COLORS.midnight_500,
        700: COLORS.midnight_700,
        900: COLORS.midnight_900,
      },
      black: {
        50: COLORS.shades_neutral_50,
        500: 'black',
        700: 'black',
        900: 'black',
      },
    },
    shades: {
      background: {
        100: COLORS.shades_background_100,
        200: COLORS.shades_background_200,
        400: COLORS.shades_background_400,
        600: COLORS.shades_background_600,
        700: COLORS.shades_background_700,
      },
      // styles taken from salonscale uikit in figma
      neutral: {
        50: COLORS.shades_neutral_50,
        200: COLORS.shades_neutral_200,
        300: COLORS.shades_neutral_300,
        400: COLORS.shades_neutral_400,
        500: COLORS.shades_neutral_500,
      },
    },
  },
  fonts: {
    body: `'Open Sans', sans-serif`,
  },
  components: {
    Button: buttonTheme,
    AccordionButton: accordionButtonTheme,
    Text: textTheme,
    Switch: switchTheme,
    Tabs: tabTheme,
    NumberInput: inputTheme,
    Select: chakraSelectTheme,
    Input: inputTheme,
    Drawer: drawerTheme,
  },
})
