import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { generatePath, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'

import { Box, Table, TableContainer } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { ReportTableContent, ReportTableFooters, ReportTableHeaders } from '../ReportTable'
import {
  reduceSelectedBrand,
  selectColorBrandUsedProducts,
  selectCustomEndDate,
  selectCustomStartDate,
  selectReportsDateRangeType,
  selectSelectedBrand,
} from '../../../data/reports-v2/slice'
import { dispatchGetColorBrandDetailsReport } from '../../../data/reports-v2/api'
import { ReportSectionHeader } from '../ReportSectionHeader'
import { COLORS } from '../../../mini-lib/theme/colors'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { REPORTS_LOADING_CONSTANT } from '../../../data/reports-v2/constants'
import { selectLoadingState } from '../../../core/loading/slice'
import { convertDateRangeTypeToDates } from '../../../mini-lib/dates-and-times/utils'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { buildCsv } from '../../../mini-lib/csv/buildCsv'
import { ColorBrandReport } from '../../../data/reports-v2/interfaces'
import { COLOR_BRAND_DETAILS_REPORTS_TABS, ColorLineDetailsReportsTabs } from './ColorLineDetailsReportsTabs'
import { UseViewSize } from '../../../core/UseViewSize'
import { formatDollars } from "../../../mini-lib/units/money";
import { ROUTES } from "../../../appRoutes";

export const ColorLineUsedProductsReportPage = () => {
  const params: any = useParams()
  const { brand } = params
  const { isMobile, isTablet } = UseViewSize()

  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = [
    { label: 'Reports', url: generatePath(ROUTES.colorBrandReports, { salonId }) },
    { label: isMobile ? 'Lines' : 'Color Line Reports', url: generatePath(ROUTES.colorBrandReports, { salonId }) },
    { label: isMobile ? 'Line' : 'Color Line Report' },
  ]
  const dispatch = useDispatch()
  const models = useAppSelector(selectColorBrandUsedProducts)
  const currentlyLoading = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))

  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const currentBrand = useAppSelector(selectSelectedBrand)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null

  const sumProfit = models ? models.map((model) => model.profit).reduce((a, b) => a + b, 0) : null
  const sumRevenue = models ? models.map((model) => model.retail).reduce((a, b) => a + b, 0) : null
  const sumWholesale = models ? models.map((model) => model.wholesale).reduce((a, b) => a + b, 0) : null

  useEffect(() => {
    if (brand !== currentBrand) {
      dispatch(reduceSelectedBrand(brand))
    }
  }, [dispatch, brand, currentBrand])

  useEffect(() => {
    dispatch(dispatchGetColorBrandDetailsReport({ token, salonId, brand, dateStart: sDate, dateEnd: eDate }))
  }, [dispatch, token, brand, salonId, sDate, eDate, currentBrand])

  const tableHeaders = [
    { label: 'Line' },
    { label: 'Color' },
    { label: 'Type' },
    { label: 'Qty', tooltip: 'number of containers' },
    { label: 'Cost' },
    { label: 'Price' },
    { label: 'Mark up' },
  ]
  const tableProperties = [
    { name: 'line', variant: 'text', size: isMobile || isTablet ? 'sm' : 'm' },
    { name: 'type', variant: 'text', size: isMobile || isTablet ? 'xs' : 'm', mainText: true },
    { name: 'category', variant: 'text', size: 'sm' },
    { name: 'quantity', variant: 'text', size: isMobile || isTablet ? 'xs' : 'sm' },
    { name: 'wholesale', variant: 'money' },
    { name: 'retail', variant: 'money' },
    { name: 'profit', variant: 'money' },
  ]
  const tableFooters = [
    'Total',
    '',
    '',
    '',
    '$' + formatDollars(sumWholesale),
    '$' + formatDollars(sumRevenue),
    '$' + formatDollars(sumProfit),
  ]
  const onCsvExport = () => {
    const totals: ColorBrandReport | any = {
      line: 'total',
      color: '',
      type: '',
      quantity: '',
      wholesale: '$' + formatDollars(sumWholesale),
      retail: '$' + formatDollars(sumRevenue),
      profit: '$' + formatDollars(sumProfit),
    }

    const formattedModels: ColorBrandReport[] | any[] = models
      ? models.map((model) => ({
          line: model.line,
          color: model.type,
          type: model.category,
          quantity: model.quantity,
          wholesale: '$' + formatDollars(model.wholesale),
          retail: '$' + formatDollars(model.retail),
          profit: '$' + formatDollars(model.profit),
        }))
      : []
    const rows: any = formattedModels ? [...formattedModels, totals] : []
    const headers = tableHeaders.map((header) => header.label)
    buildCsv('color brand used products report', headers, rows)
  }

  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Color Reports" breadcrumbs={breadcrumbs} />}
      content={
        <>
          {!isMobile && <Box h="24px" />}
          <ColorLineDetailsReportsTabs
            selectedTab={COLOR_BRAND_DETAILS_REPORTS_TABS.usedColors}
            onCsvExport={onCsvExport}
          />
          <Box h="24px"/>
          <ReportSectionHeader
            label={brand}
            bgHex={COLORS.lavender_100}
            backButtonUrl={generatePath(ROUTES.colorBrandReports, { salonId })}
          />
          {!currentlyLoading && !!models && models.length > 0 && (
            <TableContainer>
              <Table>
                <ReportTableHeaders headers={tableHeaders} />
                <ReportTableContent properties={tableProperties} models={models} />
                <ReportTableFooters footers={tableFooters} />
              </Table>
            </TableContainer>
          )}
          {!currentlyLoading && !!models && models.length === 0 && (
            <EmptyBox content="No colors were used in this time period" />
          )}
          <Box h="24px" />
          {(!models || currentlyLoading) && <Loading />}
        </>
      }
    />
  )
}
