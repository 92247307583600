import { debounce } from 'lodash'
import { useState, useEffect } from 'react'
import { DESKTOP_BREAKPOINT_PIXELS, SHOW_INFONAV_AS_SHEET, TABLET_BREAKPOINT_PIXELS } from '../data/constants'

export const UseViewSize = (): {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  showSideNavAsSheet: boolean
} => {
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  })
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    })
  useEffect(() => {
    window.addEventListener('resize', debounce(updateSize, 100))
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  const isMobile = size.x < TABLET_BREAKPOINT_PIXELS
  const showSideNavAsSheet = size.x <= SHOW_INFONAV_AS_SHEET
  const isTablet = size.x >= TABLET_BREAKPOINT_PIXELS && size.x < DESKTOP_BREAKPOINT_PIXELS
  const isDesktop = size.x >= DESKTOP_BREAKPOINT_PIXELS
  return { isMobile, isTablet, isDesktop, showSideNavAsSheet }
}
