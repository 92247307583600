import {
  Box,
  Circle,
  Divider,
  Flex,
  Img,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text, Tooltip,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { MaterialIcon } from '../icons/MaterialIcon'
import { COLORS } from '../theme/colors'
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseSignOut } from '../../core/hooks/UseSignOut'
import { ConfirmModal } from '../confirm-modal/ConfirmModal'
import { LoggedInUser } from '../../data/user/interfaces'
import { capitalize } from 'lodash'
import { UseViewSize } from '../../core/UseViewSize'
import { useDispatch } from 'react-redux'
import { setNavVisible } from '../sidenav/slice'
import { useAppSelector } from '../../hooks'
import { selectLoggedInUserSalonPermissions } from '../../data/user/slice'
import { NotificationPopover } from '../notification'
import { ROUTES } from '../../appRoutes'
import { SearchAnything } from '../search-anything/SearchAnything'
import { ReleaseSearchAnything } from "../flags/Release";
import { SalonScaleIcon } from "../icons/SalonScaleIcon";
import { hasNoQuickTrialAndNoSubscription } from "../../data/salon/utils";
import { selectActiveSalonContext } from "../../data/salon/slice";

export const UserNav = () => {
  const { user, salonId } = UseBaseApiParams()
  const { showSideNavAsSheet } = UseViewSize()
  const history = useHistory()
  const dispatch = useDispatch()
  const releaseSearchAnything = ReleaseSearchAnything()
  const userPermissions = useAppSelector(selectLoggedInUserSalonPermissions)
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const salon = useAppSelector(selectActiveSalonContext)

  // stop user from being able to leave the start guide flow until they've added products
  const hasProducts = !!salon?.productsCount && salon.productsCount > 0

  const location = useLocation()
  const isTrial = location.pathname.includes('trial')
  const hasSubOrTrial = user && user.currentSalonContext ? !hasNoQuickTrialAndNoSubscription(user.currentSalonContext) : true

  const onAccountClick = () => {
    if (hasSubOrTrial) {
      history.push(generatePath(ROUTES.userSettings, { salonId }))
    } else {
      history.push(generatePath(ROUTES.userSettingsNoNav, { salonId }))
    }
  }

  return (
    <>
      <ConfirmModal
        showModal={showLogoutModal}
        title="Sign out"
        subtitle="Are you sure you want to sign out?"
        confirmText="Yes, Sign Out"
        onConfirm={() => {
          setShowLogoutModal(false)
          UseSignOut()
        }}
        onCancel={() => setShowLogoutModal(false)}
      />

      <Flex w="100%" align="center" justify="space-between">
        <Flex align="center">
          {showSideNavAsSheet && (
            <>
              <Circle p="8px" cursor="pointer " onClick={() => dispatch(setNavVisible(true))}>
                <MaterialIcon size="24px" name="menu" />
              </Circle>
            </>
          )}
          {releaseSearchAnything && userPermissions && hasSubOrTrial && <SearchAnything userPermissions={userPermissions}/>}
        </Flex>
        <Flex align="center" justify="flex-end">
          {user && !!salonId && hasProducts && hasSubOrTrial && userPermissions && userPermissions.role === 'owner' && (
            <Link to={isTrial ? generatePath(ROUTES.trialSalonSettings, { salonId }) : generatePath(ROUTES.salonSettings, { salonId })}>
              <Tooltip label='go to salon settings'>
                <Flex
                  cursor='pointer'
                  h="40px"
                  w="40px"
                  justify="center"
                  align="center"
                  borderRadius="50%"
                  _hover={{ border: `3px solid ${COLORS.lavender_200}` }}
                >
                  <SalonScaleIcon name="settings" colorhex={COLORS.text_secondary}/>
                </Flex>
              </Tooltip>
              </Link>
          )}
          {user && !!salonId && hasSubOrTrial && !isTrial && <NotificationPopover />}
          <Flex>
            <a href="https://helpcenter.salonscale.com/en/" target="_blank" rel="noreferrer">
              <Tooltip label='go to help center - this link will open in a new tab'>
                <Flex
                  h="40px"
                  w="40px"
                  justify="center"
                  align="center"
                  borderRadius="50%"
                  _hover={{ border: `3px solid ${COLORS.lavender_200}` }}
                >
                  <MaterialIcon name="help_outline" colorhex={COLORS.text_secondary} />
                </Flex>
              </Tooltip>
            </a>
          </Flex>
          <Menu>
            <MenuButton
              transition="all 0.2s"
              p="4px 12px"
              borderRadius="50px"
              _hover={{ bg: 'gray.100' }}
              _expanded={{ bg: 'white' }}
              _focus={{ boxShadow: 'outline' }}
            >
              <UserDetails user={user} />
            </MenuButton>
            <MenuList>
              { hasSubOrTrial && hasProducts && (
                <>
                  <MenuItem onClick={onAccountClick}>
                    <Flex align="center" gridGap="12px">
                      <MaterialIcon name="account_circle" /> Account
                    </Flex>
                  </MenuItem>
                  <MenuDivider />
                </>
              )}
              <MenuItem onClick={() => setShowLogoutModal(true)}>
                <Flex align="center" gridGap="12px">
                  <MaterialIcon name="logout" /> Sign out
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Box h="8px" />
      {showSideNavAsSheet && <Divider />}
    </>
  )
}

const UserDetails = (props: { user: LoggedInUser }) => {
  const { user } = props
  const userPermissions = useAppSelector(selectLoggedInUserSalonPermissions)
  const isMobile = UseViewSize()
  return (
    <Flex gridGap="12px" w="100%" align="center" justify="flex-end">
      {user && user.photoUrl ? (
        <Img h="44px" w="44px" src={user?.photoUrl} borderRadius="50%" bg={COLORS.lavender_50} />
      ) : (
        <Circle size="44px" border="2px solid" borderColor="brand.lavender.500">
          <MaterialIcon name="account_circle" size="44px" />
        </Circle>
      )}
      {!isMobile && (
        <Flex direction="column" align="center" justify="center">
          <Flex align="center" gridGap="12px">
            <Text fontWeight="bold" isTruncated={true}>
              {user?.firstName} {user?.lastName}
            </Text>
            <MaterialIcon name="expand_more" size="24px" />
          </Flex>
          <Flex justify="start" w="100%">
            <Text fontSize='12px'>{capitalize(userPermissions?.role)}</Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
