import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'

import {
  reduceSetProductFilters,
  selectProductFilters,
  selectProductList,
  selectProductsById,
  selectVendorNames,
  selectLineNamesFilteredByVendorNames,
  selectAllCategories
} from '../../../data/products/slice'
import { dispatchListProducts } from '../../../data/products/api'
import { Product, ProductFilters } from '../../../data/products/interfaces'
import { ColorForMode } from '../../../theme'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { Loading } from '../../../mini-lib/loading/Loading'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { assign, keys, omit } from 'lodash'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { filterProducts, getLevelNames } from '../../../data/products/utils'
import { StickySearch } from '../../../mini-lib/search/StickySearch'
import { UseViewSize } from '../../../core/UseViewSize'
import { ReleaseLaraColors } from "../../../mini-lib/flags/Release";
import { DropdownFilter } from '../../../mini-lib/filters/DropdownFilter'

export const ProductSelectSheet = (props: {
  show: boolean
  onHide: () => void
  selectedProductIds: number[]
  onProductsSelected: (products: Product[]) => void
  subtitleText?: string
  confirmButtonText?: string
}) => {
  const dispatch = useDispatch()
  const productFilters = useAppSelector(selectProductFilters)
  const [showOnlyWithPricing, setShowOnlyWithPricing] = useState(true)

  // call on initial render to set initial pricing filter
  useEffect(() => {
    const initialFilters = assign({}, productFilters, { pricingFilter: 'hasPricing' })
    dispatch(reduceSetProductFilters(initialFilters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    show,
    onHide,
    onProductsSelected,
    selectedProductIds,
    subtitleText = 'Select products to add to bowl',
    confirmButtonText,
  } = props
  return (
    <Drawer isOpen={show} placement="right" size="md" onClose={onHide}>
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent p="0">
        <DrawerBody p="0">
          <Box p="24px">
            <Flex justify="space-between">
              <Text variant="title1" fontWeight="bold">
                products
              </Text>
              <Button
                className="cy-product-sheet-cancel"
                colorScheme="brand.midnight"
                variant="round-outline"
                minW="100px"
                onClick={onHide}
              >
                Cancel
              </Button>
            </Flex>
           
            <Flex justify="space-between" align="center" gridGap="12px" mt="12px">
            <Text color="text.secondary">{subtitleText}</Text>
            <Box>
            <Text mr={1} color="text.secondary" display={'inline'}> show only with pricing</Text>
           
              <Switch
                sx={{ baseStyle: { bg: 'brand.lavender.500' } }}
                isChecked={showOnlyWithPricing}
                size="md"
                colorScheme="brand.lavender"
                onChange={() => {
                  const updatedFilters = showOnlyWithPricing
                    ? assign({}, productFilters, { pricingFilter: null })
                    : assign({}, productFilters, { pricingFilter: 'hasPricing' })
                  dispatch(reduceSetProductFilters(updatedFilters))
                  setShowOnlyWithPricing(!showOnlyWithPricing)
                }}
              />
            </Box>
            
             
            </Flex>
            <Box h="24px" />
            <Flex mb={5} gridGap="12px" justify="space-between">
              <Box w="100%">
                <StickySearch
                  isSticky={false}
                  variant="clear"
                  placeholder={'Search Products'}
                  searchText={productFilters.lineOrType || ''}
                  setSearchText={(t: string) => {
                    const updatedProductFilters: ProductFilters = assign({}, productFilters, { lineOrType: t })
                    dispatch(reduceSetProductFilters(updatedProductFilters))
                  }}
                />
              </Box>

            </Flex>
            <Flex gridGap="12px" justify="space-between">
              <CategorySelector />
              <VendorSelector />
              <LineSelector />
            </Flex>
          </Box>
          <ProductSheetList
            selectedProductIds={selectedProductIds}
            onProductsSelected={onProductsSelected}
            confirmButtonText={confirmButtonText}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const ProductSheetList = (props: {
  selectedProductIds: number[]
  onProductsSelected: (products: Product[]) => void
  confirmButtonText?: string
}) => {
  const dispatch = useDispatch()
  const products = useAppSelector(selectProductList)
  const productsById = useAppSelector(selectProductsById)
  const productFilters = useAppSelector(selectProductFilters)
  const { user, salonId } = UseBaseApiParams()
  const productsLoaded = !!products
  const { selectedProductIds, onProductsSelected, confirmButtonText = 'Start Measuring' } = props
  const [numProductsToShow, setNumProductsToShow] = useState(50)

  const releaseLaraColors = ReleaseLaraColors()
  useEffect(() => {
    dispatch(dispatchListProducts({ token: user.token, salonId, releaseLaraColors }))
  }, [dispatch, user.token, salonId, releaseLaraColors])

  const initiallySelectedProductMap = {}
  selectedProductIds.forEach((id) => {
    initiallySelectedProductMap[id] = true
  })

  const [selectedProductMap, setSelectedProductMap] = useState(initiallySelectedProductMap)
  const toggleProductSelected = (productId: number) => {
    if (productId in selectedProductMap) {
      const updatedMap = omit(selectedProductMap, [productId])
      setSelectedProductMap(updatedMap)
    } else {
      const updatedMap = assign({}, selectedProductMap, { [productId]: true })
      setSelectedProductMap(updatedMap)
    }
  }
  const filteredProducts: Product[] = products ? filterProducts(products, productFilters) : []
  const selectedProducts = productsById ? keys(selectedProductMap).map((productId) => productsById[productId]) : []
  // clean out null products if they have deleted the product since creating the session
  const cleanedSelectedProducts = selectedProducts.filter((product) => product)
  return (
    <>
      {!productsLoaded && <Loading variant="clear" />}
      {productsLoaded && products.length > 0 && (
        <>
          {filteredProducts && filteredProducts.length > 0 ? (
            filteredProducts.slice(0, numProductsToShow).map((product, index) => {
              return (
                <ProductRow
                  toggleProductSelected={toggleProductSelected}
                  index={index}
                  isSelected={selectedProductMap[product.id] || false}
                  product={product}
                  key={product.id}
                />
              )
            })
          ) : (
            <EmptyBox content="No products match that filter" />
          )}
          {numProductsToShow < filteredProducts.length && (
            <Flex justify="center" m="12px 0">
              <Button variant="ghost" onClick={() => setNumProductsToShow(numProductsToShow + 50)}>
                View More Products
              </Button>
            </Flex>
          )}
          <Box h="60px" />
          <Box position="absolute" p="" m="0">
            <Flex position="fixed" bottom="0" justify="center" p="0" w="100%" h="60px">
              <Button
                variant="round"
                maxW="100%"
                w="350px"
                p="24px"
                onClick={() => onProductsSelected(cleanedSelectedProducts)}
              >
                <Box w="100%">
                  <Box w="100%">{confirmButtonText}</Box>
                  <Box w="100%">
                    <SelectedFormula products={cleanedSelectedProducts} />
                  </Box>
                </Box>
              </Button>
            </Flex>
          </Box>
        </>
      )}
      {productsLoaded && products.length === 0 && (
        <EmptyBox title="You don't have any products yet!" content="Please add some products and come back" />
      )}
    </>
  )
}

export const ProductRow = ({
  index,
  product,
  isSelected,
  toggleProductSelected,
}: {
  index: number
  product: Product
  isSelected: boolean
  toggleProductSelected: (productId: number) => void
}) => {
  return (
    <Box
      className="cy-product-row"
      p="12px 24px"
      bg={(index + 1) % 2 === 0 ? ColorForMode('secondary-bg') : ''}
      maxW="100%"
      onClick={() => toggleProductSelected(product.id)}
    >
      <Flex align="center" gridGap="12px" cursor="pointer">
        {isSelected ? (
          <MaterialIcon colorhex={COLORS.lavender_500} name="check_circle" />
        ) : (
          <MaterialIcon colorhex={COLORS.lavender_500} name="radio_button_unchecked" />
        )}
        <Flex align="center" gridGap="12px" justify="space-between">
          <Box isTruncated={true}>
            <Flex>
              <Text>{product.type}</Text>
              {product.pricing.pricePerG === 0 && (
                <Text color={COLORS.text_secondary} pl="4px">
                  (no pricing)
                </Text>
              )}
            </Flex>
            <Text>{product.line.name}</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export const SelectedFormula = (props: { products: Product[] }) => {
  const { products } = props
  // many of our types have a + on the end of them, that is confusing here
  const cleanType = (type: string): string => (type.trim().slice(-1) === '+' ? type.slice(0, type.length - 1) : type)
  return (
    <Flex align="center" justify="center" isTruncated={true}>
      {products.map((product, index) => {
        return (
          <Flex key={product.id} align="center">
            <Text fontSize="10px">{cleanType(product.type)}</Text>
            {index + 1 !== products.length && <Box m="0 8px">+</Box>}
          </Flex>
        )
      })}
    </Flex>
  )
}

export const CategorySelector = () => {
  const dispatch = useDispatch()
  const productFilters = useAppSelector(selectProductFilters)
  const allCategories = useAppSelector(selectAllCategories)
  const { isMobile } = UseViewSize()
  return (
    <DropdownFilter
      theme='lavender'
      placeholder={isMobile ? 'Categories' : 'All Categories'}
      value={productFilters.categoryName}
      options={allCategories.map((name) => ({ label: name, value: name }))}
      onChange={(option) =>
        dispatch(reduceSetProductFilters({ ...productFilters, ...{ categoryName: option.value } }))
      }
    />
  )
}

export const VendorSelector = () => {
  const dispatch = useDispatch()
  const productFilters = useAppSelector(selectProductFilters)
  const vendorNames = useAppSelector(selectVendorNames)
  const { isMobile } = UseViewSize()
  return (
    <DropdownFilter
      theme='lavender'
      placeholder={isMobile ? 'brands' : 'All Brands'}
      value={productFilters.vendorName}
      options={vendorNames.map((name) => ({ label: name, value: name }))}
      onChange={(option) =>
        dispatch(reduceSetProductFilters({ ...productFilters, ...{ vendorName: option.value } }))
      }
    />
  )
}

export const LineSelector = () => {
  const dispatch = useDispatch()
  const productFilters = useAppSelector(selectProductFilters)
  const lineNames = useAppSelector(selectLineNamesFilteredByVendorNames)
  const { isMobile } = UseViewSize()
  return (
    <DropdownFilter
      theme='lavender'
      placeholder={isMobile ? 'Lines' : 'All Lines'}
      value={productFilters.lineName}
      options={lineNames.map((name) => ({ label: name, value: name }))}
      onChange={(option) => dispatch(reduceSetProductFilters({ ...productFilters, ...{ lineName: option.value } }))}
    />
  )
}

export const LevelSelect = () => {
  const dispatch = useDispatch()
  const productFilters = useAppSelector(selectProductFilters)
  const products = useAppSelector(selectProductList)
  const levelNames = products ? getLevelNames(products) : []
  return (
    <Flex justify="flex-end">
      <Select
        value={productFilters.categoryName || ''}
        borderRadius="100px"
        placeholder="All Levels"
        onChange={(e: any) => {
          const updatedProductFilters: ProductFilters = assign({}, productFilters, { categoryName: e.target.value })
          dispatch(reduceSetProductFilters(updatedProductFilters))
        }}
      >
        {levelNames.map((name: any) => {
          return (
            <option key={name} value={name}>
              {name}
            </option>
          )
        })}
      </Select>
    </Flex>
  )
}
