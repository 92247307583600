import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'

import { selectSuppliesById, selectSupplyList } from '../../../data/supplies/slice'
import { dispatchListSupplies } from '../../../data/supplies/api'
import { Supply } from '../../../data/supplies/interfaces'
import { ColorForMode } from '../../../theme'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { Loading } from '../../../mini-lib/loading/Loading'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { assign, keys, omit } from 'lodash'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { StickySearch } from '../../../mini-lib/search/StickySearch'
import { ReleaseLaraSupplies } from "../../../mini-lib/flags/Release";

export const SupplySelectSheet = (props: {
  show: boolean
  onHide: () => void
  selectedSupplyIds: number[]
  onSuppliesSelected: (supplies: Supply[]) => void
}) => {
  const [searchText, setSearchText] = useState('')
  const { show, onHide, onSuppliesSelected, selectedSupplyIds } = props
  return (
    <Drawer isOpen={show} placement="right" size="md" onClose={onHide}>
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent p="0">
        <DrawerBody p="0">
          <Box p="24px">
            <Flex justify="space-between">
              <Text variant="title1" fontWeight="bold">
                supplies
              </Text>
              <Button
                className="cy-supply-sheet-cancel"
                colorScheme="brand.linen"
                variant="round-outline"
                minW="100px"
                onClick={onHide}
              >
                Cancel
              </Button>
            </Flex>
            <Text>Select supplies to add to bowl</Text>
            <Box h="24px" />
            <StickySearch
              isSticky={false}
              iconColor="brand.linen.500"
              variant="clear"
              placeholder={'Search Supplies'}
              searchText={searchText || ''}
              setSearchText={(t: string) => setSearchText(t)}
            />
          </Box>
          <SupplySheetList
            searchText={searchText}
            selectedSupplyIds={selectedSupplyIds}
            onSuppliesSelected={onSuppliesSelected}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const SupplySheetList = (props: {
  searchText: string
  selectedSupplyIds: number[]
  onSuppliesSelected: (supplies: Supply[]) => void
}) => {
  const dispatch = useDispatch()
  const supplies = useAppSelector(selectSupplyList)
  const suppliesById = useAppSelector(selectSuppliesById)
  const { user, salonId } = UseBaseApiParams()
  const suppliesLoaded = !!supplies
  const { searchText, selectedSupplyIds, onSuppliesSelected } = props
  const [numSuppliesToShow, setNumSuppliesToShow] = useState(50)
  
  const releaseLaraSupplies = ReleaseLaraSupplies()

  useEffect(() => {
    dispatch(dispatchListSupplies({token: user.token, salonId, releaseLaraSupplies}))
  }, [dispatch, user.token, salonId, suppliesLoaded, releaseLaraSupplies])

  const initiallySelectedSupplyMap = {}
  selectedSupplyIds.forEach((id) => {
    initiallySelectedSupplyMap[id] = true
  })

  const [selectedSupplyMap, setSelectedSupplyMap] = useState(initiallySelectedSupplyMap)
  const toggleSupplySelected = (supplyId: number) => {
    if (supplyId in selectedSupplyMap) {
      const updatedMap = omit(selectedSupplyMap, [supplyId])
      setSelectedSupplyMap(updatedMap)
    } else {
      const updatedMap = assign({}, selectedSupplyMap, { [supplyId]: true })
      setSelectedSupplyMap(updatedMap)
    }
  }
  const filteredSupplies: Supply[] = supplies ? supplies.filter((supply) => supply.name.includes(searchText)) : []
  const selectedSupplies = suppliesById
    ? keys(selectedSupplyMap).map((supplyId) => {
        return suppliesById[supplyId.toString()]
      })
    : []
  return (
    <>
      {!suppliesLoaded && <Loading variant="clear" />}
      {suppliesLoaded && supplies.length > 0 && (
        <>
          {filteredSupplies && filteredSupplies.length > 0 ? (
            filteredSupplies.slice(0, numSuppliesToShow).map((supply, index) => {
              return (
                <SupplyRow
                  toggleSupplySelected={toggleSupplySelected}
                  index={index}
                  isSelected={selectedSupplyMap[supply.id] || false}
                  supply={supply}
                  key={supply.id}
                />
              )
            })
          ) : (
            <EmptyBox content="No supplies match that filter" />
          )}
          {numSuppliesToShow < supplies.length && (
            <Flex justify="center" m="12px 0">
              <Button
                colorScheme="brand.linen"
                variant="ghost"
                onClick={() => setNumSuppliesToShow(numSuppliesToShow + 50)}
              >
                View More Supplies
              </Button>
            </Flex>
          )}
          <Box h="60px" />
          <Box position="absolute" p="" m="0">
            <Flex position="fixed" bottom="0" justify="center" p="0" w="100%" h="60px">
              <Button
                colorScheme="brand.linen"
                variant="round"
                maxW="100%"
                w="350px"
                p="24px"
                onClick={() => onSuppliesSelected(selectedSupplies)}
              >
                <Box w="100%">
                  <Box w="100%">Add Supplies</Box>
                  <Box w="100%">
                    <SelectedFormula supplies={selectedSupplies} />
                  </Box>
                </Box>
              </Button>
            </Flex>
          </Box>
        </>
      )}
      {suppliesLoaded && supplies.length === 0 && (
        <EmptyBox title="You don't have any supplies yet!" content="Please add some supplies and come back" />
      )}
    </>
  )
}

export const SupplyRow = ({
  index,
  supply,
  isSelected,
  toggleSupplySelected,
}: {
  index: number
  supply: Supply
  isSelected: boolean
  toggleSupplySelected: (supplyId: number) => void
}) => {
  return (
    <Box
      p="12px 24px"
      bg={(index + 1) % 2 === 0 ? ColorForMode('secondary-bg') : ''}
      maxW="100%"
      onClick={() => toggleSupplySelected(supply.id)}
    >
      <Flex align="center" gridGap="12px" cursor="pointer">
        {isSelected ? (
          <MaterialIcon colorhex={COLORS.linen_500} name="check_circle" />
        ) : (
          <MaterialIcon colorhex={COLORS.linen_500} name="radio_button_unchecked" />
        )}
        <Flex align="center" gridGap="12px">
          <Box isTruncated={true}>
            <Text>{supply.name}</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export const SelectedFormula = (props: { supplies: Supply[] }) => {
  const { supplies } = props
  // many of our types have a + on the end of them, that is confusing here
  const cleanType = (type: string): string => (type.trim().slice(-1) === '+' ? type.slice(0, type.length - 1) : type)
  return (
    <Flex align="center" justify="center" isTruncated={true}>
      {supplies.map((supply, index) => {
        return (
          <Flex key={index} align="center">
            <Text fontSize="10px">{supply?.name ? cleanType(supply.name) : ''}</Text>
            {index + 1 !== supplies.length && <Box m="0 8px">+</Box>}
          </Flex>
        )
      })}
    </Flex>
  )
}
