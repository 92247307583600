import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { DisableAutofocus } from '../../mini-lib/disable-autofocus/DisableAutofocus'
import {
  reduceExtensionsBulkUpdateSheetVisibility,
  reduceExtensionsSheetVisibility,
  reduceSelectedExtensionsById,
  reduceSelectedExtensionsByIdBulkUpdate,
  selectExtensionsBulkUpdateSheetVisibility,
  selectExtensionsForBulkUpdate
} from "../../data/extensions/slice";
import { ExtensionAutoSaveFormTitleRow, ExtensionsAutoSaveForm } from "./ExtensionsAutoSaveForm";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { SalonScaleProductIcon } from "../../mini-lib/icons/SalonScaleProductIcon";
import { COLORS } from "../../mini-lib/theme/colors";
import { maybePluralize } from "../../core/text";
import { dispatchDeleteExtensions } from "../../data/extensions/api";
import { ConfirmPopover } from "../../mini-lib/confirm-popover/ConfirmPopover";
import { ReleaseLaraColors } from "../../mini-lib/flags/Release";

export const ExtensionsBulkUpdateSheet = () => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const extensions = useAppSelector(selectExtensionsForBulkUpdate)
  const extension = extensions ? extensions[0] : null
  const sheetVisible = useAppSelector(selectExtensionsBulkUpdateSheetVisibility)
  const releaseLaraColors = ReleaseLaraColors()
  const onDrawerClose = () => {
    dispatch(reduceSelectedExtensionsByIdBulkUpdate({}))
    dispatch(reduceSelectedExtensionsById({}))
    dispatch(reduceExtensionsBulkUpdateSheetVisibility(false))
    dispatch(reduceExtensionsSheetVisibility(true))
  }
  return (
    <>
      {sheetVisible && (
        <Drawer
          size='lg'
          isOpen={sheetVisible}
          placement="right"
          onClose={onDrawerClose}
        >
          <DrawerOverlay/>
          <DrawerCloseButton/>
          <DrawerContent>
            <DrawerHeader>
              <DisableAutofocus/>
              {extension && extensions?.length === 1 && (
                <>
                  <Flex justify="center">
                    <Box />
                    <Text variant="largetitle" textTransform="uppercase">
                      Edit Product
                    </Text>
                    <Button
                      position='absolute'
                      top='0'
                      right='0'
                      variant="round-ghost"
                      colorScheme="brand.midnight"
                      onClick={onDrawerClose}
                    >
                      Close
                    </Button>
                  </Flex>
                  <Flex justify="center" align="center" w="100%" direction="column">
                    <Box h="24px"/>
                    <SalonScaleProductIcon
                      theme='peach'
                      name='extensions'
                      backgroundhex={COLORS.peach_50}
                      colorhex={COLORS.peach_500}
                      size="120px"
                    />
                    <Box h="24px"/>
                    <Text variant="title1">
                      {extension.type} • {extension.line.name}
                    </Text>
                    <Box h="12px"/>
                    <Text variant="body" fontWeight="normal" color={COLORS.text_secondary}>
                      {extension.category} • {extension.size}
                    </Text>
                  </Flex>
                </>
              )}
              {extensions && extensions.length > 1 && (
                <Flex justify="center">
                  <Box>
                    <Text variant="largetitle" textTransform="uppercase">
                      Edit ({extensions.length}) Products
                    </Text>
                    <Text variant="headline" fontWeight="normal">
                      Editing Fields for {extensions.length} Products
                    </Text>
                  </Box>
                  <Button
                    position='absolute'
                    top='12px'
                    right='12px'
                    variant="round-ghost"
                    colorScheme="brand.midnight"
                    onClick={onDrawerClose}
                  >
                    Close
                  </Button>
                </Flex>
              )}

            </DrawerHeader>

            <DrawerBody>
              <Box h="24px"/>
              {extensions && (
                <>
                  <ExtensionAutoSaveFormTitleRow showDelete={false}/>
                  <ExtensionsAutoSaveForm extensions={extensions} showDelete={false}/>
                </>
              )}
            </DrawerBody>
            <DrawerFooter>
              {extensions && (
                <Flex gridGap="12px" justify="flex-end" mt="24px" w="100%">

                  <ConfirmPopover
                    title={`This Action is Permanent`}
                    subtitle={`Deleted extensions will no longer appear in your salon.`}
                    onConfirm={() => {

                      dispatch(dispatchDeleteExtensions({
                        token: user.token,
                        salonId,
                        models: extensions ? extensions : [],
                        loadingName: 'bulk-delete-extensions',
                        releaseLaraColors
                      }))
                      // close and clear both drawers
                      dispatch(reduceSelectedExtensionsByIdBulkUpdate({}))
                      dispatch(reduceSelectedExtensionsById({}))
                      dispatch(reduceExtensionsBulkUpdateSheetVisibility(false))
                      dispatch(reduceExtensionsSheetVisibility(false))

                    }}
                  >
                    <Button
                      variant="round-outline"
                      colorScheme="brand.midnight"
                    >
                      Delete {extensions.length > 1 ? `(${extensions.length})` : ''} {maybePluralize('Extension', extensions.length)}
                    </Button>

                  </ConfirmPopover>

                  <Button
                    variant="round"
                    colorScheme="brand.midnight"
                    onClick={onDrawerClose}
                  >
                    done
                  </Button>
                </Flex>
              )}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}

