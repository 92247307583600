// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, keyBy, orderBy, uniq, values } from 'lodash'

import { RootState } from '../../store'
import { MasterProduct, MasterProductTrialPrice } from './interfaces'
import { INTERNAL_SALONS } from "../constants";
import { filterMasterProducts } from "./utils";
import { VendorLara } from "../products/interfaces";

// state
//
interface MasterProductState {
  masterProductsById: { [key: string]: MasterProduct } | null
  trialPricingByMasterProductId: { [key: string]: MasterProductTrialPrice } | null
  searchText: string
  vendorsById: { [key: string]: VendorLara } | null
}

const initialState: MasterProductState = {
  masterProductsById: null, // undefined is an accident, null is a choice, this lets us know when something is loading
  trialPricingByMasterProductId: null,
  searchText: '',
  vendorsById: null
}

// reducer
//
export const MasterProductSlice = createSlice({
  name: 'masterProducts',
  initialState,
  reducers: {
    reduceListMasterProducts: (state, action: PayloadAction<MasterProduct[]>) => {
      if (action.payload && action.payload.length > 0) {
        state.masterProductsById = assign({}, state.masterProductsById, keyBy(action.payload, 'id'))
      }
    },
    reduceListTrialPricing: (state, action: PayloadAction<MasterProductTrialPrice[]>) => {
      state.trialPricingByMasterProductId = assign(
        {},
        state.trialPricingByMasterProductId,
        keyBy(action.payload, 'masterProductId'),
      )
    },
    reduceSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    reduceListVendors: (state, action: PayloadAction<VendorLara[]>) => {
      if (action.payload && action.payload.length > 0) {
        state.vendorsById = assign({}, state.vendorsById, keyBy(action.payload, 'id'))
      }
    },
  },
})

// actions
//
export const {
  reduceListMasterProducts,
  reduceListTrialPricing,
  reduceSearchText,
  reduceListVendors
} = MasterProductSlice.actions

// selectors
//

export const selectMasterProductsById = (state: RootState): { [key: string]: MasterProduct } | null => {
  return state.masterProducts.masterProductsById
}

export const selectVendorsById = (state: RootState): { [key: string]: VendorLara } | null => {
  return state.masterProducts.vendorsById
}

export const selectTrialPricingByMasterProductId = (state: RootState): { [key: string]: MasterProductTrialPrice } | null => {
  return state.masterProducts.trialPricingByMasterProductId
}
export const selectSearchText = (state: RootState): string => {
  return state.masterProducts.searchText
}

export const selectMasterProducts = (state: RootState): MasterProduct[] | null => {
  const property1Sorter = (product) => product.category.toLowerCase()
  const property2Sorter = (product) => product.type.toLowerCase()
  const property3Sorter = (product) => product.parentBrandName.toLowerCase()
  return state.masterProducts.masterProductsById
    ? orderBy(
      values(state.masterProducts.masterProductsById),
      [property1Sorter, property2Sorter, property3Sorter],
      ['asc', 'asc', 'asc'],
    )
    : null
}

export const selectVendors = (state: RootState): VendorLara[] | null => {
  const property1Sorter = (product) => product.name.toLowerCase()
  return state.masterProducts.vendorsById
    ? orderBy(
      values(state.masterProducts.vendorsById),
      [property1Sorter],
      ['asc'],
    )
    : null
}

export const selectMasterProductsLineNamesAndVendorNamesFilteredBySearchText = (state: RootState):
  { filteredMasterProducts: MasterProduct[] | null, filteredLineNames: string[] | null, filteredVendorNames: string[] | null } => {
  const property1Sorter = (product) => product.category.toLowerCase()
  const property2Sorter = (product) => product.type.toLowerCase()
  const property3Sorter = (product) => product.parentBrandName.toLowerCase()
  const masterProducts = state.masterProducts.masterProductsById
    ? orderBy(
      values(state.masterProducts.masterProductsById),
      [property1Sorter, property2Sorter, property3Sorter],
      ['asc', 'asc', 'asc'],
    )
    : null
  if (!masterProducts) {
    return {filteredMasterProducts: null, filteredLineNames: null, filteredVendorNames: null}
  }
  const searchText = state.masterProducts.searchText
  // unless it's an internal salon ensure we don't show demo data
  // note: the demo data should just not exist on this environment but that's out of our hands currently
  const currentSalonContext = state.users?.loggedInUser?.currentSalonContext || null
  const isInternalSalon = currentSalonContext && values(INTERNAL_SALONS).includes(currentSalonContext.salonId)
  const filteredMasterProducts = filterMasterProducts({masterProducts, searchText, isInternalSalon})

  const filteredVendorNames: string[] = []
  const filteredLineNames: string[] = []

  filteredMasterProducts.forEach(mp => {
    filteredVendorNames.push(mp.parentBrandName)
    filteredLineNames.push(mp.brandName)
  })
  return {filteredMasterProducts, filteredLineNames: uniq(filteredLineNames), filteredVendorNames: uniq(filteredVendorNames)}
}

export const selectMasterProductsForLine = (state: RootState, lineName: string, vendorName: string): MasterProduct[] | null => {
  const property1Sorter = (product) => product.category.toLowerCase()
  const property2Sorter = (product) => product.type.toLowerCase()
  const property3Sorter = (product) => product.parentBrandName.toLowerCase()
  const mpsInLine = values(state.masterProducts.masterProductsById).filter((mp) => mp.brandName === lineName && mp.parentBrandName === vendorName)
  return mpsInLine
    ? orderBy(values(mpsInLine), [property1Sorter, property2Sorter, property3Sorter], ['asc', 'asc', 'asc'])
    : null
}

// export
//
export default MasterProductSlice.reducer
