/////////////// slice.ts
//
//

// references
//
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, keyBy, omit } from 'lodash'

import { RootState } from '../../store'
import { ProductUpc } from './interfaces';

// state
//
interface ProductUpcState {
  productUpcsById: { [key: number]: ProductUpc } | null
}

const initialState: ProductUpcState = {
  productUpcsById: null
}

// reducer
//
export const ProductUpcsSlice = createSlice({
  name: 'productUpc',
  initialState,
  reducers: {
    reduceListProductUpcs: (state, action: PayloadAction<ProductUpc[] | null>) => {
      state.productUpcsById = assign({}, state.productUpcsById, keyBy(action.payload, 'upc'))
    },
    reduceAcceptProductUpc: (state, action: PayloadAction<number>) => {
      state.productUpcsById = omit(state.productUpcsById, action.payload)
    },
    reduceDeclineProductUpc: (state, action: PayloadAction<number>) => {
      state.productUpcsById = omit(state.productUpcsById, action.payload)
    },

  },
})

// actions
//
export const {
  reduceListProductUpcs,
  reduceAcceptProductUpc,
  reduceDeclineProductUpc
} = ProductUpcsSlice.actions

// selectors
//
export const selectProductUpcs = (state: RootState) => state.productUpc.productUpcsById

// export
//
export default ProductUpcsSlice.reducer
