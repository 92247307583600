import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { APIUpdateSalon } from '../../data/salon/interfaces'
import { dispatchUpdateSalon } from '../../data/salon/api'
import { useDispatch } from 'react-redux'
import { APIUpdateProduct } from '../../data/products/interfaces'
import { useAppSelector } from '../../hooks'
import { selectLoadingState } from '../../core/loading/slice'
import { Loading } from '../../mini-lib/loading/Loading'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { HelpPopover } from '../start-guide/common-components/HelpPopover'
import { selectExtensions } from '../../data/extensions/slice'
import { dispatchUpdateExtensions } from '../../data/extensions/api'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { ReleaseLaraColors } from "../../mini-lib/flags/Release";

export const GLOBAL_MARKUP = 'global-markup'

export const GlobalExtensionsMarkupModal = (props: {}) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const initialDefaultMarkup = user.currentSalonContext?.defaultExtensionMarkupPercentage || 0
  const extensions = useAppSelector(selectExtensions)
  const [showMarkupModal, setShowMarkupModal] = useState(false)
  const [markup, setMarkup] = useState(initialDefaultMarkup)
  const releaseLaraColors = ReleaseLaraColors()
  
  const addPercent = (val) => (val ? `${val}%` : 0)
  const removePercent = (val) => val.replace(/^%/, '')
  const isUpdatingMarkup = useAppSelector((state) => selectLoadingState(state, GLOBAL_MARKUP))
  const onSubmit = () => {
    if (user.currentSalonContext && extensions) {
      const request: APIUpdateSalon = {
        name: user.currentSalonContext.salonName,
        default_extension_markup_percentage: markup,
      }
      dispatch(
        dispatchUpdateSalon({
          token: user.token,
          userId: user.userId,
          salonId,
          currentSalonContext: user.currentSalonContext,
          request,
        }),
      )
      const updatedProducts: APIUpdateProduct[] = extensions.map((product) => {
        return {
          product_id: product.id,
          wholesale_price: product.inventory.cost,
          mark_up: markup,
        }
      })

      const params = {
        token: user.token,
        user_id: user.userId,
        salon_id: salonId,
        models: updatedProducts,
        loadingName: GLOBAL_MARKUP,
        releaseLaraColors
      }
      dispatch(dispatchUpdateExtensions(params))
      setShowMarkupModal(false)
    }
  }
  return (
    <>
      <Flex justify="center">
        <HelpPopover
          guideTypes={[CHECKLIST_CODES.addExtensions]}
          children={
            <Button
              w="120px"
              colorScheme="brand.midnight"
              onClick={() => setShowMarkupModal(true)}
              variant="round-outline"
            >
              {isUpdatingMarkup ? <Loading /> : 'Edit Markup'}
            </Button>
          }
          description="Add a markup to your wholesale cost to keep a small extension stock on hand and to help cover additional costs. We recommend 25%"
          stepIndex={2}
          placement="left"
        />
      </Flex>

      <Modal isOpen={showMarkupModal} onClose={() => setShowMarkupModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box h="36px" />
            <Text variant="title2">SET A MARKUP</Text>
            <Box h="12px" />
            <Text variant="">This will update the markup for all of your extensions that currently have prices.</Text>
            <Box h="36px" />

            <NumberInput
              w="100%"
              value={addPercent(markup)}
              onChange={(updatedMarkup) => setMarkup(parseInt(removePercent(updatedMarkup)))}
              defaultValue={initialDefaultMarkup}
              colorScheme="brand.lavender"
            >
              <NumberInputField
                justifyContent="center"
                textAlign="center"
                alignContent="center"
                w="100%"
                borderRadius="50px"
                fontSize="26px"
                fontFamily="Oswald, sans-serif"
              />
            </NumberInput>

            <Box h="24px" />
            <Box>
              <span style={{ textDecoration: 'underline' }}>WARNING:</span> This will overwrite all your mark ups. Any
              specific extensions you have the mark up different will have to be readjusted.
            </Box>
          </ModalBody>

          <ModalFooter>
            <Flex gridGap="12px">
              <Button variant="round-outline" onClick={() => setShowMarkupModal(false)}>
                Close
              </Button>
              <Button variant="round" onClick={onSubmit}>
                Apply
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
