import { Dispatch } from "@reduxjs/toolkit";
import { SalonUser } from "./interfaces";
import { buildLaraConfig } from "../../mini-lib/lara/lara-utils";
import { GetServerBaseUrl } from "../../env";
import axios from "axios";
import { mapApiSalonUsersToSalonUsers } from "./mappers";
import { reduceListSalonUsers } from "./slice";



export const apiListSalonUsers = (params: {
  token: string,
  salonId: number,
}): Promise<SalonUser[]> => {
  const {token, salonId} = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/salon-users`
  return axios
    .get(url, config)
    .then((response: any) => {
      return mapApiSalonUsersToSalonUsers(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const dispatchListSalonUsers = (params: {token: string, salonId: number}) => {
  return (dispatch: Dispatch) => {
    return apiListSalonUsers(params).then((resp) => {
      dispatch(reduceListSalonUsers(resp))
    })
  }
}
