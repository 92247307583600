import React, { useState } from 'react'
import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { BRAND_THEMES, ColorForMode } from '../../theme'
import { UseViewSize } from '../../core/UseViewSize'
import { find, includes, map, uniq, xor } from 'lodash'
import { generatePath, useHistory } from 'react-router-dom'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { capitalCase, UseQueryParams } from '../../mini-lib/utils/basic'
import { DEFAULT_SUPPLY_NAME } from '../../data/supplies/constants'
import { APISupply, APISupplyCreate } from '../../data/supplies/interfaces'
import { EditableButton } from '../../mini-lib/button/EditableButton'
import { HelpModal } from '../start-guide/common-components/HelpModal'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { ReleaseLaraSupplies, ReleaseStartGuideV2 } from '../../mini-lib/flags/Release'
import { dispatchCreateOrUpdateSuppliesLara, dispatchCreateSupplies } from "../../data/supplies/api";
import { ROUTES } from "../../appRoutes";

export const SuppliesOnboard = ( props: {} ) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    user: { token, userId },
    salonId,
  } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  const st = ColorForMode('secondary-text')

  interface Option {
    id: number
    label: string
  }

  const initialOptions: Option[] = [
    { id: 1, label: 'shampoo/conditioner' },
    { id: 2, label: 'treatment' },
    { id: 3, label: 'foil' },
    { id: 4, label: 'gloves' },
    { id: 5, label: 'bobby pins' },
    { id: 6, label: 'cotton' },
  ]
  const [options, setOptions] = useState<Option[]>(initialOptions)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = useState('')
  const queryParams: any = UseQueryParams()
  const isGuide = !!queryParams.get('guide')
  const releaseStartGuideV2 = ReleaseStartGuideV2()
  const releaseLaraSupplies = ReleaseLaraSupplies()
  const createOption = () => {
    const newOption = { id: options.length + 1, label: DEFAULT_SUPPLY_NAME }
    setOptions([...options, newOption])
    addOrRemoveOption('add', newOption)
  }
  const addOrRemoveOption = ( action: 'add' | 'remove', option: Option ) => {
    if (action === 'remove') {
      const updatedList = xor(selectedIds, [option.id])
      setSelectedIds(updatedList)
    } else {
      setSelectedIds([...selectedIds, option.id])
    }
  }
  const updateOption = ( id: number, updatedLabel: string ) => {
    const updated = { id: id, label: updatedLabel }
    const updatedOptions = map(options, ( o ) => ( o.id === id ? updated : o ))
    setOptions(updatedOptions)
  }
  const isSelected = ( option: Option ) => {
    return includes(selectedIds, option.id)
  }
  const onNext = () => {
    if (selectedIds.length === 0) {
      setError('* Please select at least one addon')
      return
    }
    const supplies: APISupplyCreate[] | APISupply[] = uniq(selectedIds).map(( id ) => {
      const option: Option | any = find(options, ( o ) => o.id === id)
      return { name: capitalCase(option.label), price: 0 }
    })
    if (releaseLaraSupplies) {
      dispatch(
        dispatchCreateOrUpdateSuppliesLara({
          token,
          userId,
          salonId,
          models: supplies,
          loadingId: 'creating-supplies',
        }),
      )
    } else {
      dispatch(
        dispatchCreateSupplies({
          token: token,
          user_id: userId,
          salon_id: salonId,
          models: supplies,
        }),
      )
    }
    setSelectedIds([])
    const guideQueryParam = isGuide ? `?guide=${CHECKLIST_CODES.addAddons}` : ''
    const url = isGuide
      ? releaseStartGuideV2
        ? generatePath(ROUTES.sgAddons, { salonId }) + guideQueryParam
        : generatePath(ROUTES.supplies, { salonId }) + guideQueryParam
      : generatePath(ROUTES.supplies, { salonId })
    history.push(url)
  }

  return (
    <Flex direction="column" justify="center" align="center">
      <HelpModal title="Let's set up your addons" guideType={CHECKLIST_CODES.addAddons}/>
      <Box h={isMobile ? '24px' : '72px'}/>
      <Text variant="title1" fontWeight="bold">
        SELECT THE ADD-ONS YOU WISH TO CHARGE FOR
      </Text>
      <Box h={'24px'}/>
      <Text color={st} maxW="100%" w="640px">
        Select all the add-ons you would like to be able to charge your client for, or create your own items by clicking
        the ‘+’ and adding a new label.
      </Text>
      <Box h="72px"/>
      <Grid
        templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'}
        width="700px"
        maxWidth="100%"
        gridGap="12px"
      >
        {options.map(( option ) => {
          const selected = isSelected(option)
          const onRemove = ( e ) => {
            e.stopPropagation()
            addOrRemoveOption('remove', option)
          }
          return (
            <GridItem key={option.id}>
              <EditableButton
                theme={BRAND_THEMES.linen}
                isSelected={selected}
                defaultValue={option.label}
                onClick={() => addOrRemoveOption('add', option)}
                onUpdate={( updatedLabel ) => updateOption(option.id, updatedLabel)}
                onRemove={onRemove}
              />
            </GridItem>
          )
        })}
      </Grid>
      <Flex justify="flex-end" maxW="100%" w="700px" mt="12px">
        <Button colorScheme="brand.linen" variant="ghost" w="150px" onClick={createOption}>
          <MaterialIcon name="add_circle" style={{ marginRight: '4px' }}/> add addon
        </Button>
      </Flex>
      <Box h="24px"/>
      <Flex h="24px" justify="flex-end" maxW="100%" w="700px" color="danger">
        {error}
      </Flex>
      <Box h="12px"/>
      <Flex justify="flex-end" maxW="100%" w="700px">
        <Button colorScheme="brand.midnight" variant="round" w="150px" onClick={onNext}>
          NEXT
        </Button>
      </Flex>
    </Flex>
  )
}
