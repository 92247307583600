export const LABOR_TABS = {
  laborReports: 0,
  stylistReports: 1,
  clientReports: 2,
}

export const LABOR_PAGES = {
  laborReport: 'LaborReport',
  laborStylistReport: 'LaborStylistReport',
  laborStylistDetailReport: 'LaborStylistDetailReport',
  laborClientReport: 'LaborClientReport',
  laborClientDetailReport: 'LaborClientDetailReport',
}
