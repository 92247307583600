import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { Gap } from '../../mini-lib/gap/Gap'
import { COLORS } from '../../mini-lib/theme/colors'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { SquareConnectButton } from '../settings/IntegrationSettingsPage'
import { SALONSCALE_LOGO_URL } from '../../data/constants'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../appRoutes'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'

export const SquareConnectAuthorization = () => {
  const { salonId } = UseBaseApiParams()
  const breadcrumbs = [
    { label: 'Salon Settings', url: generatePath(ROUTES.settings, { salonId }) },
    { label: 'Integrations', url: generatePath(ROUTES.integrationSettings, { salonId }) },
    { label: 'Authorize'},
  ]
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Authorize Square" breadcrumbs={breadcrumbs} />}
      content={
        <Flex align="center" justify="center">
          <Flex w="100%" maxW="650px" borderRadius="14px" border={`1px solid ${COLORS.border}`} direction="column">
            <Gap />
            <Logos />
            <Gap />
            <Title />
            <Gap />
            <Content />
            <Gap />
            <Divider />
            <Gap />
            <Permissions />
            <Gap />
            <Divider />
            <Actions />
          </Flex>
        </Flex>
      }
    />
  )
}

const Logos = () => {
  const logoUrl = require('../../assets/square-logo.png').default
  return (
    <Flex align="center" justify="center">
      <Flex justify="center" align="center" w="50px" h="50px" p="4px" borderRadius="6px" bg="brand.lavender.500">
        <img alt="logo" style={{ height: '100%', width: '100%', padding: '4px' }} src={SALONSCALE_LOGO_URL} />
      </Flex>
      <Gap s="12px" />
      <MaterialIcon name="sync_alt" colorhex={COLORS.shades_neutral_600} />
      <Gap s="12px" />
      <img src={logoUrl} alt="square logo" style={{ height: '50px', width: '50px' }} />
    </Flex>
  )
}

const Title = () => (
  <Text variant="largetitle" textTransform="uppercase" align="center">
    Connect SalonScale with Square
  </Text>
)

const Content = () => (
  <Flex justify="center">
    <Text variant="callout" maxW="420px" align="center" color={COLORS.shades_neutral_600}>
      Clients, stylists, and appointments from Square integrate into SalonScale. SalonScale sends the session prices
      back to Square when complete and are added to the clients bill.
    </Text>
  </Flex>
)

const Permissions = () => (
  <Box p="0 24px">
    <Text variant="default/regular/title3">Square would like to:</Text>
    <Gap s="12px" />
    <Flex gridGap="12px">
      <MaterialIcon name="check_circle" colorhex={COLORS.lavender_500} /> Lorem ipsum dolor sit amet, consectetur{' '}
    </Flex>
    <Gap s="12px" />
    <Flex gridGap="12px">
      <MaterialIcon name="check_circle" colorhex={COLORS.lavender_500} /> Lorem ipsum dolor sit amet, consectetur{' '}
    </Flex>
    <Gap s="12px" />
    <Flex gridGap="12px">
      <MaterialIcon name="check_circle" colorhex={COLORS.lavender_500} /> Lorem ipsum dolor sit amet, consectetur{' '}
    </Flex>
    <Gap s="12px" />
    <Flex gridGap="12px">
      <MaterialIcon name="check_circle" colorhex={COLORS.lavender_500} /> Lorem ipsum dolor sit amet, consectetur{' '}
    </Flex>
    <Gap s="12px" />
    <Flex gridGap="12px">
      <MaterialIcon name="check_circle" colorhex={COLORS.lavender_500} /> Lorem ipsum dolor sit amet, consectetur{' '}
    </Flex>
    <Gap s="12px" />
    <Flex gridGap="12px">
      <MaterialIcon name="check_circle" colorhex={COLORS.lavender_500} /> Lorem ipsum dolor sit amet, consectetur{' '}
    </Flex>
  </Box>
)

const Actions = () => {
  const { salonId } = UseBaseApiParams()
  return (
    <Flex p="24px" justify="space-between" w="100%">
      {/*todo: figure out what this learn more button does*/}
      <Button variant="round-ghost-upper" colorScheme="brand.midnight">
        Learn More
      </Button>
      <Flex gridGap="12px">
        <Link to={generatePath(ROUTES.integrationSettings, { salonId })}>
          <Button variant="round-outline" colorScheme="brand.midnight">
            Cancel
          </Button>
        </Link>
        <SquareConnectButton />
      </Flex>
    </Flex>
  )
}
