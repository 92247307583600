// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, keyBy, keys, map, omit, orderBy, uniq, values } from 'lodash'

import { RootState } from '../../store'
import { Extension, ExtensionFilters } from './interfaces'
import {
  LineNamesByVendor,
  LineOption,
  LinesById,
  LinesByVendor,
  ProductsByCategoryThenByLineSize,
} from '../products/interfaces'
import {
  mapLinesByIdToLinesByVendor,
  mapProductsToLineNamesByVendor,
  mapProductsToProductsByLine,
  mapProductsToProductsByVendor,
} from '../products/mappers'
import { EMPTY_EXTENSION_FILTERS } from './constants'
import { mapExtensionsByCategoryThenByLineSize } from './mappers'
import { MasterProduct } from "../master-products/interfaces";

export interface SelectedFilters {
  lineName: string
  categoryName: string
  size: number | null
}

// state
//
interface ExtensionState {
  extensionsById: { [key: string]: Extension } | null
  selectedExtensionsById: { [key: string]: boolean }
  selectedExtensionsByIdBulkUpdate: { [key: string]: boolean }
  allLinesById: LinesById | null
  allLines: LineOption[] | null
  selectedExtension: Extension | null
  selectedFilters: SelectedFilters | null
  addLinesSheetVisibility: boolean
  extensionFilters: ExtensionFilters
  extensionsSheetVisible: boolean,
  extensionsBulkUpdateSheetVisible: boolean,
}

const initialState: ExtensionState = {
  extensionsById: null, // undefined is an accident, null is a choice, this lets us know when something is loading
  selectedExtensionsById: { },
  selectedExtensionsByIdBulkUpdate: { },
  allLines: null,
  allLinesById: null,
  selectedExtension: null,
  selectedFilters: null,
  addLinesSheetVisibility: false,
  extensionFilters: EMPTY_EXTENSION_FILTERS,
  extensionsSheetVisible: false,
  extensionsBulkUpdateSheetVisible: false,
}

// reducer
//
export const ExtensionSlice = createSlice({
  name: 'extensions',
  initialState,
  reducers: {
    reduceSetSelectedExtension: (state, action: PayloadAction<Extension | null>) => {
      state.selectedExtension = action.payload
    },
    reduceSetSelectedFilters: (state, action: PayloadAction<SelectedFilters | null>) => {
      state.selectedFilters = action.payload
    },
    reduceSetAddLinesSheetVisibility: (state, action: PayloadAction<boolean>) => {
      state.addLinesSheetVisibility = action.payload
    },
    reduceListExtension: (state, action: PayloadAction<Extension[]>) => {
      state.extensionsById = assign({}, state.extensionsById, keyBy(action.payload, 'id'))
    },
    reduceCreateExtension: (state, action: PayloadAction<Extension>) => {
      if (state.extensionsById) {
        state.extensionsById[action.payload.id] = action.payload
      }
    },
    reduceUpdateExtensions: (state, action: PayloadAction<Extension[]>) => {
      state.extensionsById = assign({}, state.extensionsById, keyBy(action.payload, 'id'))
    },
    reduceDeleteExtension: (state, action: PayloadAction<Extension>) => {
      state.extensionsById = omit(state.extensionsById, action.payload.id)
    },
    reduceDeleteExtensions: (state, action: PayloadAction<Extension[]>) => {
      const extensionIdsToRemove = action?.payload?.map((p) => p.id)
      state.extensionsById = omit(state.extensionsById, extensionIdsToRemove)
    },
    reduceListAllExtensionLines: (state, action: PayloadAction<LineOption[]>) => {
      if (action.payload && action.payload.length > 0) {
        state.allLinesById = assign({}, state.allLinesById, keyBy(action.payload, 'lineId'))
        state.allLines = action.payload
      }
    },
    reduceExtensionFilters: (state, action: PayloadAction<ExtensionFilters>) => {
      state.extensionFilters = action.payload
    },
    reduceSelectedExtensionsById: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.selectedExtensionsById = action.payload
    },
    reduceSelectedExtensionsByIdBulkUpdate: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.selectedExtensionsByIdBulkUpdate = action.payload
    },
    reduceExtensionsBulkUpdateSheetVisibility: (state, action: PayloadAction<boolean>) => {
      state.extensionsBulkUpdateSheetVisible = action.payload
    },
    reduceExtensionsSheetVisibility: (state, action: PayloadAction<boolean>) => {
      state.extensionsSheetVisible = action.payload
    },
  },
})

// actions
//
export const {
  reduceSetSelectedExtension,
  reduceListExtension,
  reduceCreateExtension,
  reduceUpdateExtensions,
  reduceDeleteExtension,
  reduceDeleteExtensions,
  reduceSetSelectedFilters,
  reduceSetAddLinesSheetVisibility,
  reduceListAllExtensionLines,
  reduceExtensionFilters,
  reduceSelectedExtensionsById,
  reduceSelectedExtensionsByIdBulkUpdate,
  reduceExtensionsSheetVisibility,
  reduceExtensionsBulkUpdateSheetVisibility,
} = ExtensionSlice.actions

// selectors
//
// vendors, lines, and extensions the salon uses
export const selectExtensionsBulkUpdateSheetVisibility = (state: RootState) => state.extensions.extensionsBulkUpdateSheetVisible
export const selectExtensionsSheetVisibility = (state: RootState) => state.extensions.extensionsSheetVisible
export const selectSelectedExtensionsById = (state: RootState) => state.extensions.selectedExtensionsById
export const selectExtensionsForBulkUpdate = (state: RootState): Extension[] | null => {
  const { extensionsById, selectedExtensionsByIdBulkUpdate } = state.extensions;

  if (!extensionsById || !selectedExtensionsByIdBulkUpdate) {
    return null;
  }

  const selectedExtensionIds = keys(selectedExtensionsByIdBulkUpdate);
  const validExtensionIds = selectedExtensionIds.filter(id => extensionsById[id]);
  const selectedExtensions = validExtensionIds.map(id => extensionsById[id]);
  return selectedExtensions;
}

export const selectSelectedExtension = (state: RootState) => state.extensions.selectedExtension
export const selectSelectedFilters = (state: RootState) => state.extensions.selectedFilters
export const selectAddLinesSheetVisibility = (state: RootState) => state.extensions.addLinesSheetVisibility

export const selectExtensionsForSelectedFilters = (state: RootState): Extension[] => {
  const allExtensions = values(state.extensions.extensionsById)
  const selectedFilters = state.extensions.selectedFilters
  if (selectedFilters) {
    return allExtensions.filter(extension => {
      if (selectedFilters && selectedFilters.categoryName && extension.category !== selectedFilters.categoryName) {
        return false
      }
      if (selectedFilters && selectedFilters.lineName && extension.line.name !== selectedFilters.lineName) {
        return false
      }
      if (selectedFilters && selectedFilters.size && extension.size !== selectedFilters.size) {
        return false
      }
      return true
    })
  } else {
    return []
  }

}

export const selectExtensionsById = (state: RootState) => state.extensions.extensionsById
export const selectExtensionLineNamesByVendor = (state: RootState): LineNamesByVendor => {
  return mapProductsToLineNamesByVendor(values(state.extensions.extensionsById))
}

export const selectExtensionsByVendor = (state: RootState): { [key: string]: Extension[] } | null => {
  return state.extensions.extensionsById ? mapProductsToProductsByVendor(values(state.extensions.extensionsById)) : null
}

export const selectExtensionsByLine = (state: RootState): { [key: string]: Extension[] } | null => {
  return state.extensions.extensionsById ? mapProductsToProductsByLine(values(state.extensions.extensionsById)) : null
}

export const selectExtensionsByLineCategory = (state: RootState): ProductsByCategoryThenByLineSize | null => {
  const filters = state.extensions.extensionFilters
  return state.extensions.extensionsById
    ? mapExtensionsByCategoryThenByLineSize(values(state.extensions.extensionsById), filters)
    : null
}

export const selectAllExtensionLines = (state: RootState): LineOption[] | null => {
  return state.extensions.allLines
}

export const selectAllExtensionCategories = (state: RootState): string[] | null => {
  return state.extensions.extensionsById
    ? uniq(values(state.extensions.extensionsById).map((extension) => extension.category)).sort()
    : null
}
export const selectAllExtensionSizes = (state: RootState): number[] | null => {
  return state.extensions.extensionsById
    ? uniq(values(state.extensions.extensionsById).map((extension) => extension.size)).sort()
    : null
}
export const selectAllExtensionVendorNames = (state: RootState): string[] | null => {
  const extensions = values(state.extensions.extensionsById)
  const allNames = map(extensions, (p) => p.vendor.name)
  return uniq(allNames)
}
export const selectAllExtensionLinesByVendor = (state: RootState): LinesByVendor | null => {
  return state.extensions.allLinesById ? mapLinesByIdToLinesByVendor(state.extensions.allLinesById) : null
}
export const selectExtensions = (state: RootState): Extension[] | null => {
  const property1Sorter = (extension) => extension.type.toLowerCase()
  const property2Sorter = (extension) => extension.line.name.toLowerCase()
  const property3Sorter = (extension) => extension.vendor.name.toLowerCase()
  return state.extensions.extensionsById
    ? orderBy(
        values(state.extensions.extensionsById),
        [property1Sorter, property2Sorter, property3Sorter],
        ['asc', 'asc', 'asc'],
      )
    : null
}

export const selectInfoOnExtensionsWithPricing = ( state: RootState): { percentage: number, totalExtensions: number, numberCompleted: number } | null => {
  if (!state.extensions?.extensionsById) return null
  const extensionsById = state.extensions.extensionsById
  const extensions = values(extensionsById)
  const extensionsWithPricing = extensions.filter((p) => p.pricing && p.pricing.price > 0).length
  const percentage = Math.round((extensionsWithPricing / extensions.length) * 100)
  return { percentage, totalExtensions: extensions.length, numberCompleted: extensionsWithPricing }
}

export const selectExtensionFilters = (state: RootState): ExtensionFilters => {
  return state.extensions.extensionFilters
}


export const selectMasterProductsExtensionLineNamesAndExtensionVendorNamesFilteredBySearchText = (state: RootState):
  { filteredMasterProducts: MasterProduct[] | null, filteredLineNames: string[] | null, filteredVendorNames: string[] | null } => {
  const property1Sorter = (product) => product.category.toLowerCase()
  const property2Sorter = (product) => product.type.toLowerCase()
  const property3Sorter = (product) => product.parentBrandName.toLowerCase()
  const masterProducts = state.masterProducts.masterProductsById
    ? orderBy(
      values(state.masterProducts.masterProductsById),
      [property1Sorter, property2Sorter, property3Sorter],
      ['asc', 'asc', 'asc'],
    )
    : null
  if (!masterProducts) {
    return {filteredMasterProducts: null, filteredLineNames: null, filteredVendorNames: null}
  }

  const filteredMasterProducts = masterProducts.filter((mp) => {
      const normalizedSearchText = state.masterProducts.searchText.toLowerCase()
      const matchesLine = mp.brandName.toLowerCase().includes(normalizedSearchText)
      const matchesType = mp.type.toLowerCase().includes(normalizedSearchText)
      const matchesVendor = mp.parentBrandName.toLowerCase().includes(normalizedSearchText)
      return matchesLine || matchesType || matchesVendor
    }
  )
  const filteredVendorNames: string[] = []
  const filteredLineNames: string[] = []
  filteredMasterProducts.forEach(mp => {
    filteredVendorNames.push(mp.parentBrandName)
    filteredLineNames.push(mp.brandName)
  })
  return {filteredMasterProducts, filteredLineNames, filteredVendorNames}
}

export const selectExtensionListForLine = (state: RootState, lineName: string): Extension[] | null => {
  if (!state.extensions.extensionsById) {
    return null
  }
  const property1Sorter = (extension) => extension.type.toLowerCase()
  const property2Sorter = (extension) => extension.line.name.toLowerCase()
  const property3Sorter = (extension) => extension.vendor.name.toLowerCase()
  const extensionsForLine = values(state.extensions.extensionsById).filter((extension) => extension.line.name === lineName)
  return extensionsForLine
    ? orderBy(extensionsForLine, [property1Sorter, property2Sorter, property3Sorter], ['asc', 'asc', 'asc'])
    : null
}
// export
//
export default ExtensionSlice.reducer
