/////////////// mappers.ts
//
//

// todos: copy paste Supply and supply

import { map } from 'lodash'
import { APISupply, Supply } from './interfaces'
import { centsToDollars } from '../../core/money/utils'

export const mapAPISupplyToSupply = (api: APISupply): Supply => {
  return {
    id: api.id || -1,
    name: api.name,
    priceDollars: centsToDollars(api.price),
  }
}
export const mapAPISuppliesToSupplies = (apiModels: APISupply[]): Supply[] => {
  return map(apiModels, mapAPISupplyToSupply)
}
