import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useAppSelector } from '../../../hooks'
import { selectSessionsLara } from '../../../data/sessions/slice'
import { selectDateRangeData } from '../../../data/analytics/slice'
import React, { useEffect } from 'react'
import { dispatchListSessionsLara } from '../../../data/sessions/api'
import { BlurBottom, BlurScrollContainer } from '../../../mini-lib/blur/Blur'
import { Box, Text } from '@chakra-ui/react'
import { SessionRow } from '../../sessions/SessionList'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { filterSessionsLara } from "../../../data/sessions/utils";

export const HomeRecentSessions = () => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const sessions = useAppSelector(selectSessionsLara)
  const { start, startString, endString, end } = useAppSelector(selectDateRangeData) // let start = useMemo(() => new Date(), [])
  const filteredSessions = sessions ? filterSessionsLara({models: sessions, startDate: start, endDate: end}) : null

  useEffect(() => {
    if (startString && endString) {
      dispatch(
        dispatchListSessionsLara({
          token: user.token,
          salonId,
          startDateTime: start,
          endDateTime: end,
        }),
      )
    }
    // object deps break things, this is using a string to tell if the date has changed
    // eslint-disable-next-line
  }, [dispatch, startString, endString, user.token, salonId])

  return (
    <>
      <BlurScrollContainer>
        <Text variant="title1">Recent Sessions</Text>
        <Box h="250px" overflowY="scroll">
          {filteredSessions &&
            filteredSessions.length > 0 &&
            filteredSessions.map((session, index) => {
              return <SessionRow key={session.id} session={session} index={0} />
            })}
          {sessions && sessions.length === 0 && (
            <EmptyBox title="You don't have any sessions yet!" content="When you make sessions they will appear here" />
          )}
          {!sessions && <Loading mt="40px" />}
        </Box>
        <BlurBottom />
      </BlurScrollContainer>
    </>
  )
}
