import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { Loading } from '../../mini-lib/loading/Loading'
import { UseSignOut } from '../../core/hooks/UseSignOut'

export const LogoutPage = () => {
  UseSignOut()
  return (
    <Flex h="100vh" justify="center" align="center" direction="column">
      <Loading />
      <Box h="24px" />
      Logging Out
    </Flex>
  )
}
