import { SessionMetaLara } from '../../../data/sessions/interfaces'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import {
  reduceIsExtensionSheetVisible,
  selectActiveSessionExtensions,
  selectIsExtensionSheetVisible
} from '../../../data/sessions/slice'
import { SessionExtensionsSheet } from './SessionExtensionsSheet'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { SESSION_STATUS_OPTIONS } from '../../../data/sessions/constants'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'

import { formatCentsToDollars } from "../../../mini-lib/units/money";
import { getSessionExtensionsLaraTotalCost } from "../../../data/sessions/utils";
import { Loading } from "../../../mini-lib/loading/Loading";

export const SessionExtensions = (props: {
  sessionMeta: SessionMetaLara
}) => {
  const dispatch = useDispatch()
  const {  sessionMeta } = props
  const [show, setShow] = useState(true)
  const isExtensionSheetVisible = useAppSelector(selectIsExtensionSheetVisible)

  // lara session apis
  //
  //
  const sessionExtensions = useAppSelector(selectActiveSessionExtensions)

  return (
    <>
      {isExtensionSheetVisible && sessionExtensions && <SessionExtensionsSheet sessionMeta={sessionMeta} sessionExtensions={sessionExtensions} />}
      <Flex
        minH="60px"
        borderRadius="8px"
        bg="brand.peach.50"
        align="center"
        justify="space-between"
        p="24px"
        onClick={() => setShow(!show)}
        cursor="pointer"
      >
        <Text variant="title3">Extensions</Text>
        <Flex align="center" gridGap="24px">
          <Text>${sessionExtensions ? formatCentsToDollars(getSessionExtensionsLaraTotalCost(sessionExtensions)) : 0}</Text>
          <MaterialIcon pr="4px" name={show ? 'arrow_drop_down' : 'arrow_drop_up'} />
        </Flex>
      </Flex>
      {show && (
        <>
          {sessionExtensions?.map((extension) => {
            return (
              <Box
                key={extension.id}
                onClick={() => {
                  if (sessionMeta.status === SESSION_STATUS_OPTIONS.open) {
                    dispatch(reduceIsExtensionSheetVisible(true))
                  }
                }}
                cursor={sessionMeta.status === SESSION_STATUS_OPTIONS.open ? 'pointer' : ''}
                className="cy-session-extension"
              >
                <Flex p="24px 12px" justify="space-between" pr="24px">
                  <Text color="text.secondary">
                    {extension.quantity} • {extension?.product?.size}
                    {'"'} • {extension?.product?.category} • {extension?.product?.type}
                  </Text>
                  <Text>${formatCentsToDollars(extension.quantity * extension.product.clientPurchasePriceCents)}</Text>
                </Flex>
                <Divider />
              </Box>
            )
          })}
          {sessionExtensions?.length === 0 && <EmptyBox h="200px" />}
          {!sessionExtensions && <Loading />}
          {sessionMeta.status === SESSION_STATUS_OPTIONS.open && (
            <Flex
              align="center"
              p="12px"
              onClick={() => {
                dispatch(reduceIsExtensionSheetVisible(true))
              }}
              cursor="pointer"
            >
              <MaterialIcon colorhex={COLORS.peach_200} name="add_circle" style={{ marginRight: '4px' }} />{' '}
              <Text color="brand.peach.200">add extensions</Text>
            </Flex>
          )}
        </>
      )}
    </>
  )
}
