import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import {
  reduceInventoryAddStockModalVisibility,
  reduceInventoryAuditModalVisibility,
  selectInventoryAuditModalVisibility,
} from '../../../data/inventory/slice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { COLORS } from '../../../mini-lib/theme/colors'
import React, { useState } from 'react'
import { StylistAutocompleteDeprecated } from '../../stylists/StylistAutocompleteDeprecated'
import { SalonMember } from '../../../data/user/interfaces'
import { dispatchCreateInventoryCount } from '../../../data/inventory/api'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { APIInventoryCountCreate } from '../../../data/inventory/interfaces'
import { selectLoadingState } from '../../../core/loading/slice'
import { INVENTORY_CREATE_LOADING_CONSTANT } from '../../../data/inventory/constants'
import { Loading } from '../../../mini-lib/loading/Loading'
import { selectMemberListLara } from '../../../data/user/slice'
import { filterSalonMembersLara } from '../../../data/user/utils'
import { dispatchListSalonMembersLara } from '../../../data/user/api'
import { USER_PERMISSIONS } from '../../../data/user/constants'

export const InventoryAuditModal = () => {
  const dispatch = useDispatch()

  const inventoryAddStockModalVisible = useAppSelector(selectInventoryAuditModalVisibility)
  const creatingInventoryAudit = useAppSelector((state) => selectLoadingState(state, INVENTORY_CREATE_LOADING_CONSTANT))
  const [title, setTitle] = useState('')
  const [salonMember, setSelectedSalonMember] = useState<SalonMember | null>(null)
  const { user, salonId } = UseBaseApiParams()

  const createAudit = () => {
    if (salonMember && title) {
      const model: APIInventoryCountCreate = {
        assigned_user_id: salonMember?.userId,
        count_type: 'inventory',
        title,
      }
      dispatch(dispatchCreateInventoryCount({ token: user.token, userId: user.userId, salonId, model }))
      dispatch(reduceInventoryAddStockModalVisibility(false))
    }
  }
  return (
    <Modal
      onClose={() => dispatch(reduceInventoryAddStockModalVisibility(false))}
      isOpen={inventoryAddStockModalVisible}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent m="12px">
        <ModalBody>
          <Text variant="largetitle" textTransform="uppercase">
            New Inventory Count
          </Text>

          <Box h="24px" />
          <Text variant="caption2" color={COLORS.text_secondary}>
            Title
          </Text>
          <Box h="12px" />
          <InputGroup
            border="1px solid"
            borderColor="shades.neutral.200"
            borderRadius="100px"
            width="100%"
            alignItems="center"
          >
            <Input
              disabled={creatingInventoryAudit}
              borderRadius="100px"
              value={title}
              placeholder={'Title'}
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </InputGroup>
          <Box h="24px" />
          <Text variant="caption2" color={COLORS.text_secondary}>
            Add Contributor
          </Text>
          <Box h="12px" />
          <StylistAutocompleteDeprecated
            disabled={creatingInventoryAudit}
            width="100%"
            isClearable={false}
            initialStylist={salonMember}
            onSelect={(salonMember) => setSelectedSalonMember(salonMember)}
            // TODO - remove salonMemberSelector, dispatchForSalonMembers, filterForSalonMembers once users api completely replaced
            salonMemberSelector={selectMemberListLara}
            dispatchForSalonMembers={() => dispatchListSalonMembersLara(user.token, salonId, USER_PERMISSIONS.inventoryAudit)}
            filterForSalonMembers ={filterSalonMembersLara}
            reloadOnEveryMount={true}
          />
          {creatingInventoryAudit ? (
            <CreatingInventory />
          ) : (
            <Flex justify="flex-end" gridGap="12px" p="36px 0 24px 0" h="100px">
              <Button
                onClick={() => dispatch(reduceInventoryAuditModalVisibility(false))}
                colorScheme="brand.midnight"
                variant="round-outline"
              >
                Cancel
              </Button>
              <Button onClick={createAudit} colorScheme="brand.midnight" variant="round">
                Create
              </Button>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const CreatingInventory = () => {
  return (
    <Flex justify="center" w="100%" align="center" direction="column" p="36px 0 24px 0" h="100px">
      <Text color={COLORS.lavender_500}>Creating inventory count, this may take a moment</Text>
      <Loading color={COLORS.lavender_500} />
    </Flex>
  )
}
