import { Flex } from '@chakra-ui/react'
import { COLORS } from '../theme/colors'
import { MaterialIcon } from '../icons/MaterialIcon'
import { ThemeType } from '../../theme'
import { ReactNode } from "react";

export const Banner = (props: { content: string | ReactNode; theme?: ThemeType, iconName?: string }) => {
  const { content, theme = 'lavender', iconName } = props

  const color = theme === 'midnight' ? COLORS.midnight_900 : COLORS[`${theme}_500`]
  const bg = COLORS[`${theme}_100`]
  return (
    <Flex
      justify="center"
      align="center"
      color={color}
      bg={bg}
      textTransform="none"
      fontStyle="italic"
      borderRadius="16px"
      p='12px'
    >
      {iconName && <MaterialIcon mr="4px" colorhex={color} name={iconName} />}
      {content}
    </Flex>
  )
}
