import { GetServerBaseUrl } from '../../env'
import axios from 'axios'
import { buildLaraConfig } from '../../mini-lib/lara/lara-utils'
import { Dispatch } from '@reduxjs/toolkit'
import { reduceFileUpload } from './slice'

export const apiUploadFile = (params: { token: string; file: File }): Promise<string> => {
  const { token, file } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/files`
  const body = new FormData()
  body.append('file', file)

  return axios
    .post(url, body, config)
    .then((response: { data: { data: { url: string } } }) => {
      return response.data.data.url
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchUploadFile = (params: { token: string; uploadName: string; file: File }) => {
  const { uploadName } = params
  return (dispatch: Dispatch) => {
    return apiUploadFile(params)
      .then((fileUrl: string) => {
        dispatch(reduceFileUpload({ uploadName, fileUrl }))
      })
      .catch((error) => {
        throw error
      })
  }
}
