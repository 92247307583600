import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Accordion, Button, Flex, Spinner } from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { generatePath, Link } from 'react-router-dom'
import {
  reduceSetCurrentStepCode,
  reduceSetCurrentSubStepIndex,
  selectChecklistItemsByCode,
  selectCurrentStepCode,
} from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { ChecklistGroup1 } from './steps/ChecklistGroup1'
import { ChecklistGroup3 } from './steps/ChecklistGroup3'
import { ChecklistGroup2 } from './steps/ChecklistGroup2'
import { ChecklistGroup4 } from './steps/ChecklistGroup4'
import { getAccordionIndexFromStepCode, getNextStepCodeForUser } from '../../data/start-guide/utils'
import { HelpModal } from './common-components/HelpModal'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { values } from 'lodash'
import { dispatchLaraGetSalon } from "../../data/salon/api";
import { ROUTES } from "../../appRoutes";

export const StartGuidePage = () => {
  const { user, salonId } = UseBaseApiParams()
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Start Guide' }]
  const dispatch = useDispatch()
  const checklistMap = useAppSelector(selectChecklistItemsByCode)
  const modelsLoaded = !!checklistMap
  const currentStepCode = useAppSelector(selectCurrentStepCode)
  const currentStepIndex = getAccordionIndexFromStepCode(currentStepCode)

  useEffect(() => {
    dispatch(dispatchLaraGetSalon({ token: user.token, userId: user.userId, salonId }))
  }, [dispatch, user?.token, user?.userId, salonId])

  useEffect(() => {
    dispatch(reduceSetCurrentSubStepIndex(0))
  }, [dispatch])

  useEffect(() => {
    dispatch(dispatchListUserChecklistItems(user.token, user.userId))
  }, [dispatch, user.token, user.userId])

  const query = UseQueryParams()
  const currentStep: any = query.get('step')
  const nextStepCode = getNextStepCodeForUser(values(checklistMap))

  useEffect(() => {
    if (currentStep) {
      dispatch(reduceSetCurrentStepCode(currentStep))
    } else {
      dispatch(reduceSetCurrentStepCode(nextStepCode))
    }
  }, [dispatch, currentStep, nextStepCode])

  return (
    <>
      <PageLayout
        variant="full"
        header={<PageHeader title="Start Guide" breadcrumbs={breadcrumbs} />}
        content={
          <>
            {checklistMap && (
              <Accordion allowToggle allowMultiple p="0" index={currentStepIndex}>
                <ChecklistGroup1 />
                <ChecklistGroup2 />
                <ChecklistGroup3 />
                <ChecklistGroup4 />
              </Accordion>
            )}
            {!modelsLoaded && (
              <Flex h="500px" align="center" justify="center">
                <Spinner />
              </Flex>
            )}
          </>
        }
      />
      <HelpModal
        iconName="tutorials"
        title="Congratulations!!"
        subtitle="You have completed your account set-up, but the learning doesn’t have to stop here. Head to the        Academy tab in the left-hand menu to access more free resources so you get the most out of your SalonScale Subscription!  "
        guideType={CHECKLIST_CODES.guideComplete}
        closeButtons={
          <Flex gridGap="12px">
            <Link to={generatePath(ROUTES.tutorials, { salonId })}>
              <Button variant="round-outline" colorScheme="brand.midnight">
                Academy
              </Button>
            </Link>
            <Link to={generatePath(ROUTES.tutorials, { salonId })}>
              <Button variant="round" colorScheme="brand.midnight">
                Home
              </Button>
            </Link>
          </Flex>
        }
      />
    </>
  )
}
