import { map } from 'lodash'
import { APINotification, Notification, NotificationForUser, APINotificationForUser } from './interfaces'

export const mapAPINotificationToNotification = (api: APINotification): Notification => {
  return {
    id: api.id,
    title: api.title,
    description: api.description,
    visibleTo: api.visible_to,
    visibleDate: api.visible_date,
    url: api.url,
    avatarUrl: api.avatar_url
  }
}

export const mapAPINotificationsToUserNotification = (api: APINotificationForUser): NotificationForUser => {
  return {
    id: api.id,
    title: api.title,
    description: api.description,
    url: api.url,
    avatarUrl: api.avatar_url,
    visibleDate: api.visible_date,
    visibleTo: api.visible_to,
    read: api.read,
    viewed: api.viewed
  }
}

export const mapAPINotificationsToNotifications = (api: APINotification[]): Notification[] => {
  return map(api, mapAPINotificationToNotification)
}


export const mapAPINotificationsToUserNotifications = (api: APINotificationForUser[]): NotificationForUser[] => {
  return map(api, mapAPINotificationsToUserNotification)
}
