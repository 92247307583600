import { Box, Button, Flex, Text, } from '@chakra-ui/react'
import React, { useState } from 'react'
import { COLORS } from '../../mini-lib/theme/colors'
import { UseViewSize } from '../../core/UseViewSize'
import { useDispatch } from 'react-redux'
import { dispatchSetLaraPassword } from '../../data/user/api'

import { Gap } from "../../mini-lib/gap/Gap";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { useAppSelector } from "../../hooks";
import { selectSetPasswordSuccessfully } from "../../data/user/slice";
import { Redirect } from "react-router-dom";
import { PasswordInput } from "../../mini-lib";
import { UseQueryParams } from "../../mini-lib/utils/basic";

export const SetInitialPasswordPage = () => {
  const { isMobile } = UseViewSize()
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [errorText, setErrorText] = useState('')
  const {user} = UseBaseApiParams()
  const setPasswordSuccessfully = useAppSelector(selectSetPasswordSuccessfully)
  const queryParams = UseQueryParams()
  const source = queryParams.get('source')
  const onPasswordSet = () => {
    if (!password || password.length < 6) {
      setErrorText('You need at least 6 characters')
      return
    }
    if (password && source === 'email') {
      dispatch(dispatchSetLaraPassword({ token: user.token, userId: user.userId, password }))
    }
  }

  if (setPasswordSuccessfully) {
    // go to base url and let it figure out where to send the various user types
    return <Redirect to={'/'}/>
  }

  return (

    <>
      <Flex align="center" minH="100vh" direction="column" padding={isMobile ? '12px' : '0'} bg="white">
        <Gap s='72px'/>
        <Text variant="largetitle" textTransform="uppercase">Before we continue let's get your password set up!</Text>
        <Gap s='48px'/>

        <Box
          w="100%"
          maxW="400px"
          border={`1px solid ${COLORS.shades_neutral_300}`}
          p="24px"
          borderRadius="15px"
          zIndex="1000"
          bg="white"
        >
          <PasswordInput value={password} onBlur={() => {}} onChange={(val) => setPassword(val)} errorText={errorText}/>
          <Gap />

          <Flex justify="flex-end" gridGap="12px">
            <Button variant="round" onClick={() => onPasswordSet()} disabled={!password}>
              Set Password
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}
