import { useHistory } from 'react-router-dom'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { setLocalStorageItem } from '../localStorage'

export const SetLocalStorageFromUrl = (): void => {
  // note: make sure the url param is user-id not user_id

  const history = useHistory()
  const queryParams = UseQueryParams()

  if (queryParams) {
    const token = queryParams.get('token')
    const authToken = queryParams.get('auth-token')
    const env = queryParams.get('env')
    const userId = queryParams.get('user-id')
    const theme = queryParams.get('theme')

    if (theme) {
      setLocalStorageItem('theme', theme)
      queryParams.delete('theme')
      history.replace({
        search: queryParams.toString(),
      })
    }
    if (env) {
      setLocalStorageItem('env', env)
      queryParams.delete('env')
      history.replace({
        search: queryParams.toString(),
      })
    }
    if (token && userId) {
      setLocalStorageItem('auth-token', token)
      setLocalStorageItem('user-id', userId)
      queryParams.delete('token')
      queryParams.delete('user-id')
      history.replace({
        search: queryParams.toString(),
      })
    }
    if (authToken && userId) {
      setLocalStorageItem('auth-token', authToken)
      setLocalStorageItem('user-id', userId)
      queryParams.delete('auth-token')
      queryParams.delete('user-id')
      history.replace({
        search: queryParams.toString(),
      })
    }
  }
}
