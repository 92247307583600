import { has, map } from 'lodash'
import {
  APIOrderItemLara,
  APIOrderLara,
  APIUsedBrand,
  OrderItemLara,
  OrderLara,
  OrderProduct
} from './interfaces'
import { Product } from '../products/interfaces'
import { getProductQuantityNeededToReachProductTarget } from './utils'
import { centsToDollars } from "../../core/money/utils";


export const mapAPIOrderBrandsToUsedBrands = ( data: APIUsedBrand ): OrderProduct => {
  return {
    id: data.id,
    vendorName: data.parent_brand,
    brand: data.brand,
    category: data.category,
    type: data.type,
    totalContainersUsed: data.total_containers_used,
    revenue: data.revenue,
    estOrderQuantity: data.est_order_quantity,
    estWholesalePrice: data.est_wholesale_price,
    onHand: 0,
    target: 0
  }
}

export const mapAPIOrderBrandToUsedBrand = ( data: APIUsedBrand[] ): OrderProduct[] => {
  return map(data, mapAPIOrderBrandsToUsedBrands)
}

export const mapUsedBrandToUsedProducts = ( brands: OrderProduct[] ): { [key: string]: OrderProduct[] } => {
  const usedProducts: { [key: string]: OrderProduct[] } = {}
  brands.forEach(( brand ) => {
    has(usedProducts, brand.vendorName)
      ? ( usedProducts[brand.vendorName] = [...usedProducts[brand.vendorName], brand] )
      : ( usedProducts[brand.vendorName] = [brand] )
  })
  return usedProducts
}

export const mapProductToUsedProduct = ( product: Product ): OrderProduct => {
  return {
    id: product.id + '',
    vendorName: product.vendor.name,
    brand: product.line.name,
    category: product.category,
    type: product.type,
    totalContainersUsed: getProductQuantityNeededToReachProductTarget(product),
    revenue: 0,
    estOrderQuantity: 0,
    estWholesalePrice: product.inventory.cost,
    onHand: product.inventory.quantityOnHand,
    target: product.inventory.maxStockLevel ?? 0
  }
}
export const mapProductToUsedProductForManualOrder = ( product: Product ): OrderProduct => {
  return {
    ...mapProductToUsedProduct(product),
    totalContainersUsed: 0
  }
}
export const mapProductsToOrderProducts = ( products: Product[], type: 'manual' | 'automatic' ): OrderProduct[] => {
  return map(products, type === 'manual' ? mapProductToUsedProductForManualOrder : mapProductToUsedProduct)
}


export const mapAPIOrderItemLaraToOrderItemLara = ( api: APIOrderItemLara ): OrderItemLara => {
  return {
    id: api.id,
    productId: api.product_id,
    unitCostDollars: centsToDollars(api.cost),
    quantity: api.quantity,
  }
}

export const mapAPIOrderLaraToOrderLara = ( api: APIOrderLara ): OrderLara => {
  return {
    id: api.id,
    name: api.name,
    orderDate: new Date(api.order_date),
    totalOrderedCostDollars: centsToDollars(api.total_ordered_cost),
    orderNumber: api.order_number,
    status: api.status,
    notes: api.notes,
    orderItems: map(api.order_items, mapAPIOrderItemLaraToOrderItemLara),
    shippingAddress: api.shipping_address
  }
}

export const mapAPIOrdersLaraToOrdersLara = ( api: APIOrderLara[] ): OrderLara[] => {
  return map(api, mapAPIOrderLaraToOrderLara)
}
