import { Extension, ExtensionFilters } from './interfaces'
import { getMatchesPricingFilter } from '../products/utils'

// follows lodash standards - returns true if the extension should stay, returns false if the extension should be omitted
// if no search or search is empty do not filter out the extension
// if there is a search allow the extension to stay if its type, line, or vendor match the search
export const filterExtension = ( extension: Extension, filters: ExtensionFilters ): boolean => {
  if (!filters) {
    return true
  }
  const { searchText, extensionName, extensionSize, pricingFilter, lineName, categoryName, vendorName } = filters

  // normalize search and see if it exists
  const normalizedSearch = searchText ? searchText.toLowerCase() : ''
  const hasCategorySearch = normalizedSearch ? extension.category.toLowerCase().includes(normalizedSearch) : true
  const hasTypeSearch = normalizedSearch ? extension.type.toLowerCase().includes(normalizedSearch) : true
  const hasLineSearch = normalizedSearch ? extension.line.name.toLowerCase().includes(normalizedSearch) : true
  const hasVendorSearch = normalizedSearch ? extension.vendor.name.toLowerCase().includes(normalizedSearch) : true
  const hasSizeSearch = normalizedSearch ? extension.size.toString().includes(normalizedSearch) : true
  const matchesSearchFilter = hasTypeSearch || hasLineSearch || hasVendorSearch || hasSizeSearch || hasCategorySearch

  const normalizedType = extensionName ? extensionName.toLowerCase() : ''
  const hasType = normalizedType ? extension.type.toLowerCase().includes(normalizedType) : true

  const normalizedCategory = categoryName ? categoryName.toLowerCase() : ''
  const hasCategory = normalizedCategory ? extension.category.toLowerCase().includes(normalizedCategory) : true

  const normalizedVendorName = vendorName ? vendorName.toLowerCase() : ''
  const hasVendor = normalizedVendorName ? extension.vendor.name.toLowerCase() === normalizedVendorName : true

  const normalizedLineName = lineName ? lineName.toLowerCase() : ''
  const hasLine = normalizedLineName ? extension.line.name.toLowerCase() === normalizedLineName : true

  const hasSize = extensionSize ? extension.size === extensionSize : true

  // get extensions with the right type of pricing
  const matchesPricingFilter = getMatchesPricingFilter(extension, pricingFilter)
  // we want all filters to match for the extension to be added
  return matchesSearchFilter && hasType && hasCategory && hasSize && hasVendor && hasLine && matchesPricingFilter
}

export const filterExtensions = ( extensions: Extension[], filters: ExtensionFilters ): Extension[] => {
  return extensions.filter(( extension ) => filterExtension(extension, filters))
}

export const filterActive = ( filters: ExtensionFilters ): boolean => {
  return !!filters.searchText ||
    !!filters.extensionName ||
    !!filters.lineName ||
    !!filters.vendorName ||
    !!filters.categoryName ||
    !!filters.extensionSize ||
    !!filters.pricingFilter
}
