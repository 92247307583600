import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useAppSelector } from '../../../hooks'
import { selectAnalyticsForStatType, selectDateRangeData } from '../../../data/analytics/slice'
import { OwnerMetricOptions } from '../../../data/analytics/interfaces'
import React, { useEffect } from 'react'
import { dispatchGetTopClientAnalytics } from '../../../data/analytics/api'
import { Box, Circle, Divider, Flex, Text } from '@chakra-ui/react'
import { BlurBottom, BlurScrollContainer } from '../../../mini-lib/blur/Blur'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { generatePath, Link } from 'react-router-dom'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { combineNames } from '../../../data/clients/utils'
import { ROUTES } from "../../../appRoutes";

export const HomeTopClients = (props: {}) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const { dateRangeType, start, end, dataInterval } = useAppSelector(selectDateRangeData)
  const topClientsAnalytics = useAppSelector((state) =>
    selectAnalyticsForStatType(state, dateRangeType, OwnerMetricOptions.clients),
  )

  const sDate = buildDateYYYYMMDD(start)
  const eDate = buildDateYYYYMMDD(end)
  useEffect(() => {
    if (dataInterval && sDate && eDate) {
      dispatch(
        dispatchGetTopClientAnalytics({
          token: user.token,
          salonId,
          dateRangeType,
          dateStart: sDate,
          dateEnd: eDate,
          interval: dataInterval
        }),
      )
    }
  }, [dispatch, dataInterval, dateRangeType, user.token, salonId, eDate, sDate])

  const sortedClients = topClientsAnalytics?.clients ?? null

  return (
    <Box>
      <Text variant="title1">top clients</Text>

      <BlurScrollContainer>
        <Box h="250px" overflowY="scroll">
          {sortedClients &&
            sortedClients.length > 0 &&
            sortedClients.map((client) => {
              const clientName = combineNames(client.firstName, client.middleName, client.lastName)
              const clientInitials = `${client.firstName[0]}${client.lastName[0]}`
              const clientUrl = generatePath(ROUTES.clients, { salonId }) + `?selectedClientId=${client.id}`
              return (
                <Box key={client.id}>
                  <Link to={clientUrl}>
                    <Box h="12px" />
                    <Flex align="center" gridGap="24px">
                      <Circle size="40px" bg="brand.lavender.200">
                        <Text fontWeight="bold" color="brand.lavender.500" textTransform="uppercase">
                          {clientInitials}
                        </Text>
                      </Circle>
                      <Box>
                        <Text variant="body" fontWeight="normal">
                          {clientName}
                        </Text>
                        <Text variant="title3">${client.totalCost}</Text>
                      </Box>
                    </Flex>
                    <Box h="12px" />
                    <Divider />
                  </Link>
                </Box>
              )
            })}
          {sortedClients && sortedClients.length === 0 && (
            <EmptyBox
              title="You don't have sessions for this time period!"
              content="When you make sessions with clients they will appear here"
            />
          )}
          {!sortedClients && <Loading mt="40px" />}
        </Box>
        <BlurBottom />
      </BlurScrollContainer>
    </Box>
  )
}
