/////////////// slice.ts
//
//

// references
//
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, keyBy, omit, values } from 'lodash'

import { RootState } from '../../store'
import { EmptyBowl } from './interfaces'

// state
//
interface EmptyBowlCountState {
  emptyBowlsById: { [key: string]: EmptyBowl } | null
}

const initialState: EmptyBowlCountState = {
  emptyBowlsById: null,
}

// reducer
//
export const EmptyBowlSlice = createSlice({
  name: 'emptybowls',
  initialState,
  reducers: {
    reduceListEmpyBowls: (state, action: PayloadAction<EmptyBowl[]>) => {
      state.emptyBowlsById = assign({}, state.emptyBowlsById, keyBy(action.payload, 'id'))
    },
    reduceCreateEmptyBowl: (state, action: PayloadAction<EmptyBowl>) => {
      state.emptyBowlsById = assign({}, state.emptyBowlsById, { [action.payload.id]: action.payload })
    },
    reduceUpdateEmptyBowl: (state, action: PayloadAction<EmptyBowl>) => {
      state.emptyBowlsById = assign({}, state.emptyBowlsById, { [action.payload.id]: action.payload })
    },
    reduceDeleteEmptyBowl: (state, action: PayloadAction<number>) => {
      state.emptyBowlsById = omit(state.emptyBowlsById, action.payload)
    },
  },
})

// actions
//
export const {
  reduceListEmpyBowls,
  reduceCreateEmptyBowl,
  reduceUpdateEmptyBowl,
  reduceDeleteEmptyBowl
} = EmptyBowlSlice.actions

// selectors
//

export const selectEmptyBowls = (state: RootState): EmptyBowl[] | null => {
  return state.emptybowls.emptyBowlsById ? values(state.emptybowls.emptyBowlsById) : null
}

// export
//
export default EmptyBowlSlice.reducer
