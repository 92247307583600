import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { generatePath } from 'react-router-dom'
import React, { useEffect } from 'react'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { ColorReportsTabs } from './ColorReportsTabs'

import { Box, Table, TableContainer } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { ReportTableContent, ReportTableFooters, ReportTableHeaders } from '../reports-v2/ReportTable'
import {
  selectColorStylistReports,
  selectCustomEndDate,
  selectCustomStartDate,
  selectReportsDateRangeType,
} from '../../data/reports-v2/slice'
import { dispatchGetColorStylistReports } from '../../data/reports-v2/api'
import { ReportSectionHeader } from '../reports-v2/ReportSectionHeader'
import { COLORS } from '../../mini-lib/theme/colors'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { Loading } from '../../mini-lib/loading/Loading'
import { REPORTS_LOADING_CONSTANT } from '../../data/reports-v2/constants'
import { selectLoadingState } from '../../core/loading/slice'
import { convertDateRangeTypeToDates } from '../../mini-lib/dates-and-times/utils'
import { buildDateYYYYMMDD } from '../../core/dates'
import { buildCsv } from '../../mini-lib/csv/buildCsv'
import { ColorStylistReport } from '../../data/reports-v2/interfaces'
import { UseViewSize } from '../../core/UseViewSize'
import { COLOR_STYLIST_PRODUCTS_DETAILS_REPORTS_TABS, ColorStylistReportsTabs } from '../reports-v2/colors/ColorStylistReportsTabs'
import { formatDollars } from "../../mini-lib/units/money";
import { ROUTES } from "../../appRoutes";

export const ColorStylistReportsPage = (props: { selectedTab: number }) => {
  const { selectedTab } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = [
    {
      label: 'Reports',
      url: generatePath(ROUTES.colorStylistReports, { salonId }),
    },
    { label: 'Stylist Reports' },
  ]
  const dispatch = useDispatch()
  const { isMobile, isTablet } = UseViewSize()
  const models = useAppSelector(selectColorStylistReports)
  const currentlyLoading = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))

  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null

  const sumProfit = models ? models.map((model) => model.profit).reduce((a, b) => a + b, 0) : null
  const sumRevenue = models ? models.map((model) => model.retail).reduce((a, b) => a + b, 0) : null
  const sumWholesale = models ? models.map((model) => model.wholesale).reduce((a, b) => a + b, 0) : null

  useEffect(() => {
    dispatch(dispatchGetColorStylistReports({ token, salonId, dateStart: sDate, dateEnd: eDate, kind: 'color' }))
  }, [dispatch, token, salonId, sDate, eDate])

  const tableHeaders = [{ label: 'Stylist' }, { label: 'Cost' }, { label: 'Price' }, { label: 'Mark up' }]
  const tableProperties = [
    { name: 'stylist', variant: 'text', mainText: true, size: isMobile || isTablet ? 'l' : 'xl' },
    { name: 'wholesale', variant: 'money' },
    { name: 'retail', variant: 'money' },
    { name: 'profit', variant: 'money' },
  ]
  const tableActions = [
    {
      label: 'view all',
      buildUrl: (stylistId: string): string => generatePath(ROUTES.colorStylistProductsReports, { salonId, stylistId }),
      variant: 'button',
    },
  ]
  const tableFooters = [
    'Total',
    '$' + formatDollars(sumWholesale),
    '$' + formatDollars(sumRevenue),
    '$' + formatDollars(sumProfit),
  ]
  const onCsvExport = () => {
    const totals: ColorStylistReport | any = {
      stylist: 'total',
      wholesale: '$' + formatDollars(sumWholesale),
      retail: '$' + formatDollars(sumRevenue),
      profit: '$' + formatDollars(sumProfit),
    }
    const formattedModels: ColorStylistReport[] | any[] = models
      ? models.map((model) => ({
          stylist: model.stylist,
          wholesale: '$' + formatDollars(model.wholesale),
          retail: '$' + formatDollars(model.retail),
          profit: '$' + formatDollars(model.profit),
        }))
      : []
    const rows: any = formattedModels ? [...formattedModels, totals] : []
    const headers = tableHeaders.map((header) => header.label)
    buildCsv('color stylist list report', headers, rows)
  }

  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Color Reports" breadcrumbs={breadcrumbs} />}
      content={
        <>
          <ColorReportsTabs selectedTab={selectedTab} onCsvExport={onCsvExport} />
          <ColorStylistReportsTabs selectedTab={COLOR_STYLIST_PRODUCTS_DETAILS_REPORTS_TABS.products} />
          <Box h="24px" />
          <ReportSectionHeader label="COLORS" bgHex={COLORS.lavender_100} />
          <Box h="24px" />
          {!currentlyLoading && !!models && models.length > 0 && (
            <TableContainer>
              <Table>
                <ReportTableHeaders headers={tableHeaders} actions={tableActions} />
                <ReportTableContent
                  properties={tableProperties}
                  models={models}
                  actions={tableActions}
                  urlProperty={'stylistId'}
                />
                <ReportTableFooters footers={tableFooters} actions={tableActions} />
              </Table>
            </TableContainer>
          )}
          {!currentlyLoading && !!models && models.length === 0 && (
            <EmptyBox content="No colors were used in this time period" />
          )}
          <Box h="24px" />
          {(!models || currentlyLoading) && <Loading />}
        </>
      }
    />
  )
}
