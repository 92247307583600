import {
  APIClient,
  APIClientToMerge,
  APIMergeablClientsBulkRequest,
  APIMergeableClientSet,
  Client,
  CSVClient,
} from './interfaces'
import { filter } from 'lodash'

export const filterClients = (models: Client[], searchText: string): Client[] => {
  const normalizedText = searchText.toLowerCase()
  const filteredText = normalizedText.replace(/\s/g, '') //removing white space between search text

  return filter(models, (model) => {
    if ((model.firstName.toLowerCase() + model.lastName.toLowerCase()).includes(filteredText)) {
      return true
    }
    return false
  })
}

export const buildBulkMergeClientRequest = (clientSets: APIMergeableClientSet[]): APIMergeablClientsBulkRequest => {
  const clientsToMerge: APIClientToMerge[] = clientSets.map((clientSet: APIMergeableClientSet) => {
    const idsToMerge: number[] = clientSet.clients.map((client: APIClient) => {
      return client.id || -1
    })
    return {
      model_to_retain: clientSet.clients[0].id || -1,
      models_to_merge: idsToMerge.slice(1),
    }
  })
  return { models_to_merge: clientsToMerge }
}

export const extractClientCSVData = (rows: any): CSVClient[] => {
  return rows
    .filter((row) => row['first_name']?.length > 0 && row['last_name']?.length > 0)
    .map((row, index) => {
      return {
        id: index,
        firstName: row['first_name'],
        lastName: row['last_name'],
        phone: row['phone'],
        added: false,
      }
    })
}


export const combineNames = (firstName: string, middleName: string = '', lastName: string): string => {
  if (!(middleName === null || middleName === '')) {
    return `${firstName} ${middleName} ${lastName}`;
  } else {
    return `${firstName} ${lastName}`;
  }
}