import { Box, Button, Flex, Img } from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'
import { selectLoggedInUser } from '../../data/user/slice'
import { getLocalStorageItem } from '../../core/localStorage'
import { useEffect } from 'react'
import { dispatchGetLoggedInUser } from '../../data/user/api'
import { useDispatch } from 'react-redux'
import { reduceSetIsSignupCartOpen } from '../../data/signup/slice'
import { CartSideSheet } from '../../pages/signup/v1/SignupCart'
import { SalonScaleIcon } from '../icons/SalonScaleIcon'
import { generatePath, Link } from 'react-router-dom'
import { UseSignOut } from '../../core/hooks/UseSignOut'
import { ROUTES } from "../../appRoutes";

export const TopNav = (props: {}) => {
  const dispatch = useDispatch()
  const loggedInUser = useAppSelector(selectLoggedInUser)
  const localAuthToken = getLocalStorageItem('auth-token')
  const localUserId = getLocalStorageItem('user-id')
  const haveUser = !!loggedInUser
  useEffect(() => {
    if (localAuthToken && !haveUser) {
      // todo, replace with lara api
      dispatch(dispatchGetLoggedInUser({ token: localAuthToken, userId: localUserId }))
    }
  }, [dispatch, localAuthToken, localUserId, haveUser])
  return (
    <>
      <CartSideSheet />
      <Box height="60px" />
      <Flex
        zIndex={10}
        height="60px"
        position="fixed"
        top="0"
        left="0"
        align="center"
        width="100%"
        padding="12px 24px"
        borderBottomWidth="2px"
        borderColor="shades.neutral.200"
        bg="white"
        justify="space-between"
      >
        <Flex align="center">
          <Img
            h="40px"
            w="40px"
            src="https://storage.googleapis.com/salonscale-prod-webapp-assets/SalonScale-AbstractMark-Midnight.png"
          />
          <Box w="12px" />
          {loggedInUser ? (
            <Button variant="round-outline" colorScheme="brand.midnight" onClick={UseSignOut} p="0 24px">
              Sign Out
            </Button>
          ) : (
            <Link to={generatePath(ROUTES.login)}>
              <Button variant="round-outline" colorScheme="brand.midnight" p="0 24px">
                Log In
              </Button>
            </Link>
          )}
        </Flex>
        {/* todo: switch this out for SalonScaleIcon with shopping-cart.png*/}
        <SalonScaleIcon
          name="orders"
          size="32px"
          cursor="pointer"
          onClick={() => dispatch(reduceSetIsSignupCartOpen(true))}
        />
      </Flex>
    </>
  )
}
