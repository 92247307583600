import { Box, Button, Flex, Image, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import React, { useState } from 'react'
import { COLORS } from '../../mini-lib/theme/colors'
import logo from '../../assets/salonscale-logo-midnight-outline.svg'
import { UseViewSize } from '../../core/UseViewSize'
import { useDispatch } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { dispatchResetPassword } from '../../data/user/api'
import { UseQueryParams } from '../../mini-lib/utils/basic'

import { FormikError } from "../../mini-lib/formik-utils/FormikError";
import { ROUTES } from "../../appRoutes";

export const ResetPasswordPage = () => {
  const { isMobile } = UseViewSize()
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [password, setPassword] = useState('')
  const [errorText, setErrorText] = useState('')
  const queryParams = UseQueryParams()
  const resetToken = queryParams.get('token')
  const userId = queryParams.get('user_id')
  const onPasswordReset = () => {
    if (!password || password.length < 6) {
      setErrorText('You need at least 6 characters')
      return
    }
    if (password && resetToken && userId) {
      dispatch(dispatchResetPassword({ userId, password, resetToken }))
    }
  }

  return (
    <>
      <Flex align="center" minH="100vh" direction="column" padding={isMobile ? '12px' : '0'} bg="white">
        <Box h="72px" />
        <Image h="100px" src={logo} />
        <Box h="24px" />
        <Box
          w="100%"
          maxW="400px"
          border={`1px solid ${COLORS.shades_neutral_300}`}
          p="24px"
          borderRadius="15px"
          zIndex="1000"
          bg="white"
        >
          {/* email */}
          <InputGroup size="md">
            {/*
            note: manually turning auto capitalize off because safari things this should be autocapitalized
            ref: https://stackoverflow.com/questions/11102729/how-to-prevent-auto-capitalization-in-ios-safari-input-tags
            */}
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              borderRadius="100px"
              type={show ? 'text' : 'password'}
              name="password"
              placeholder="Reset Password"
              autoCapitalize="off"
            />
            <InputRightElement w="50px" mr="12px">
              <Button size="sm" h="24px" onClick={() => setShow(!show)}>
                {show ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormikError showError={!!errorText} errorText={errorText} />
          <Box h="24px" />

          <Flex justify="flex-end" gridGap="12px">
            <Button variant="round" onClick={() => onPasswordReset()} disabled={!password}>
              Reset Password
            </Button>
          </Flex>
        </Box>
        <Flex w="100%" maxW="400px" zIndex="1000">
          <Link to={generatePath(ROUTES.login)}>
            <Button variant="round-ghost-upper">Return to Log In</Button>
          </Link>
        </Flex>

        {/* sticky images */}

        {!isMobile && (
          <Image
            left="0"
            bottom="0"
            position="absolute"
            src="https://storage.googleapis.com/salonscale-prod-webapp-assets/backgroup-left-illutration-signup.png"
          />
        )}

        {!isMobile && (
          <Image
            right="0"
            bottom="0"
            position="absolute"
            src="https://storage.googleapis.com/salonscale-prod-webapp-assets/backgroup-right-illutration-signup.png"
          />
        )}
      </Flex>
    </>
  )
}
