import { Box, Button, Flex, TabPanel, TabPanels, Tabs, Text, } from '@chakra-ui/react'
import { sortBy } from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { buildDateYYYYMMDD } from '../../core/dates'
import { selectLoadingState } from '../../core/loading/slice'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { dispatchGetAllReportsByKind, dispatchGetProductReportsDetailsForExtensions } from '../../data/report/api'
import { REPORTS_LOADING_CONSTANT } from '../../data/report/constants'
import { ExtensionOrProductReportDetailsLara, ProductReport } from '../../data/report/interfaces'
import {
  reduceUsedOrUnusedCSV,
  selectCustomEndDate,
  selectCustomStartDate,
  selectProductExtensionDetailReportList,
  selectProductReportList,
  selectReportsDateRangeType,
} from '../../data/report/slice'
import { useAppSelector } from '../../hooks'
import { convertDateRangeTypeToDates } from '../../mini-lib/dates-and-times/utils'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { Loading } from '../../mini-lib/loading/Loading'
import { VARIANTS } from '../../mini-lib/theme/variants'
import { pages } from './ExtensionReportsPage'
import { UseViewSize } from '../../core/UseViewSize'
import { TableContent, TableHead, TableTotal } from './Table'

export const ExtensionLinesReportPage = ( props: { page: string; setPage: ( page: string) => void }) => {
  const [brandName, setbrandName] = useState('')
  const { page, setPage } = props

  return (
    <>
      {page === pages.productList && <ExtensionReportsTable setPage={setPage} setbrandName={setbrandName} />}
      {page === pages.productDetail && <ExtensionReportDetail brand={brandName} setPage={setPage} />}
    </>
  )
}

export const ExtensionReportsTable = (props: { setPage: (string) => void; setbrandName: (string) => void }) => {
  const { setPage, setbrandName } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const loadingReport = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const models = useAppSelector(selectProductReportList)
  const sumProfit = models ? models.map((model) => model.profit).reduce((a, b) => a + b, 0) : null
  const sumRevenue = models ? models.map((model) => model.retail).reduce((a, b) => a + b, 0) : null
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0

  // future todo: add paging to extensions table
  // const [numRowsToShow, setNumRowsToShow] = useState(30)
  // const modelsToShow = models ? models.slice(0, numRowsToShow) : null

  useEffect(() => {
    dispatch(dispatchGetAllReportsByKind({ token, salonId, dateStart: sDate, dateEnd: eDate, kind: 'extension' }))
  }, [dispatch, token, salonId, sDate, eDate])

  return (
    <Flex flexDirection="column" width="100%">
      <Flex width="100%" align="center" p="20px" borderRadius="15px" bg="brand.peach.50" maxH="50px">
        <Text variant={VARIANTS.text.caption1}>EXTENSIONS</Text>
      </Flex>
      <Box h={'25px'} />
      {!loadingReport && models && models.length > 0 && (
        <ExtensionReportTableContainer
          modelsToShow={models}
          sumProfit={sumProfit}
          sumRevenue={sumRevenue}
          sumWholesale={sumWholesale}
          setPage={setPage}
          setbrandName={setbrandName}
        />
      )}
      {!loadingReport && models && models.length === 0 && <EmptyBox />}
      {loadingReport && <Loading />}
    </Flex>
  )
}

export const ExtensionReportTableContainer = (props: {
  modelsToShow: ProductReport[]
  sumProfit: number | null
  sumRevenue: number | null
  sumWholesale: number | null
  setPage: (string) => void
  setbrandName: (number) => void
}) => {
  const { modelsToShow, sumProfit, sumRevenue, sumWholesale, setPage, setbrandName } = props
  const minWidth = '150px'
  const list = [
    { label: 'Title' },
    { label: 'Cost', value: sumWholesale, additionalProps: { isNumeric: true } },
    { label: 'Price', value: sumRevenue, additionalProps: { isNumeric: true } },
    { label: 'Mark up', value: sumProfit, additionalProps: { isNumeric: true } }
  ]
  return (
    <>
      <TableHead list={list} minWidth={minWidth} />
      {sortBy(modelsToShow, 'brand').map((product, index) => (
        <Fragment key={index}>
          <ExtensionReportTableRow product={product} setPage={setPage} setbrandName={setbrandName} />
        </Fragment>
      ))}
      <TableTotal list={list} minWidth={minWidth} />
    </>
  )
}

export const ExtensionReportTableRow = (props: {
  product: ProductReport
  setPage: (string) => void
  setbrandName: (string) => void
}) => {
  const { product, setPage, setbrandName } = props
  const dispatch = useDispatch()
  const wholesale = product.retail - product.profit
  const list = [
    { label: 'Title', value: product.brand },
    { label: 'Cost', value: wholesale, additionalProps: { isNumeric: true } },
    { label: 'Price', value: product.retail, additionalProps: { isNumeric: true } },
    { label: 'Mark up', value: product.profit, additionalProps: { isNumeric: true } }
  ]
  return (
    <TableContent
      list={list}
      action={
        <Button
          width={'70px'}
          height={'20px'}
          variant="round-ghost-lower"
          onClick={() => {
            setbrandName(product.brand)
            setPage(pages.productDetail)
            dispatch(reduceUsedOrUnusedCSV('used'))
          }}
        >
          view all
        </Button>
      }
    />
  )
}

// ---------Extension Report Detail--------------

export const ExtensionReportDetail = (props: { brand: string; setPage: (string) => void }) => {
  const { brand, setPage } = props //using this brand we will make a request to Product Detail Report
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const dispatch = useDispatch()
  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const data = useAppSelector(selectProductExtensionDetailReportList)
  const loadingReport = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const sumProfit = data ? data.map((model) => model.profit).reduce((a, b) => a + b, 0) : null
  const sumRevenue = data ? data.map((model) => model.retail).reduce((a, b) => a + b, 0) : null
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0

  // todo: add client side paging to this in a future iteration
  // const [numRowsToShow, setNumRowsToShow] = useState(50)
  // const usedProducts = brandUsedProducts ? brandUsedProducts.slice(0, numRowsToShow) : null

  useEffect(() => {
    dispatch(
      dispatchGetProductReportsDetailsForExtensions({
        token,
        salonId,
        brand,
        dateStart: sDate,
        dateEnd: eDate,
        kind: 'extension',
      }),
    )
  }, [dispatch, token, brand, salonId, sDate, eDate])

  return (
    <>
      <Tabs colorScheme="brand.lavender">
        <TabPanels>
          <TabPanel maxW="1200px" p={0}>
            <Flex width="100%" justify="space-between" align="center" p="20px" borderRadius="15px" bg="brand.peach.50">
              <Text variant={VARIANTS.text.caption1}>{brand}</Text>
              <Box cursor="pointer" onClick={() => setPage(pages.productList)}>
                <Text variant={VARIANTS.text.caption1}>Back</Text>
              </Box>
            </Flex>
            <Box h={'25px'} />
            {!loadingReport && data && data.length > 0 && (
              <ExtensionReportDetailTableContainer
                data={data}
                sumProfit={sumProfit}
                sumRevenue={sumRevenue}
                sumWholesale={sumWholesale}
              />
            )}
            {!loadingReport && data && data.length === 0 && <EmptyBox />}
            {loadingReport && <Loading />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

// UsedProduct

export const ExtensionReportDetailTableContainer = (props: {
  data: ExtensionOrProductReportDetailsLara[]
  sumProfit: number | null
  sumRevenue: number | null
  sumWholesale: number | null
}) => {
  const { data, sumProfit, sumRevenue, sumWholesale } = props
  const { isMobile, isTablet } = UseViewSize()
  const minWidth = '110px'
  const list = [
    { label: 'Type' },
    { label: 'Shades', additionalProps: { noTotal: true, width: (isMobile || isTablet) ? 105 : 200 } },
    { label: 'Length', additionalProps: { noTotal: true, width: 105 } },
    { label: 'Qty', additionalProps: { noTotal: true, width: 105 } },
    { label: 'Cost', value: sumWholesale, additionalProps: { isNumeric: true, width: 105 } },
    { label: 'Price', value: sumRevenue, additionalProps: { isNumeric: true, width: 105 } },
    { label: 'Mark up', value: sumProfit, additionalProps: { isNumeric: true, width: 105 } }
  ]
  return (
    <>
      <TableHead list={list} minWidth={minWidth} noAction />
      {data.map((product, index) => (
        <Fragment key={index}>
          <ExtensionReportDetailTableRow product={product} id={index} />
        </Fragment>
      ))}
      <TableTotal list={list} minWidth={minWidth} noAction />
    </>
  )
}

export const ExtensionReportDetailTableRow = (props: { product: ExtensionOrProductReportDetailsLara, id: number }) => {
  const { product, id } = props
  const wholesale = product.retail - product.profit
  const { isMobile, isTablet } = UseViewSize()
  const minWidth = '110px'
  // note: these labels are all messed up on purpose, for some reason the business wants type to be called category
  const list = [
    { label: 'Type', value: product.category },
    { label: 'Shades', value: product.color, additionalProps: { noTotal: true, width: (isMobile || isTablet) ? 105 : 200 } },
    { label: 'Length', value: product.length, additionalProps: { noTotal: true, width: 105 } },
    { label: 'Qty', value: product.quantity, additionalProps: { noTotal: true, width: 105 } },
    { label: 'Cost', value: wholesale, additionalProps: { isNumeric: true, width: 105 } },
    { label: 'Price', value: product.retail, additionalProps: { isNumeric: true, width: 105 } },
    { label: 'Mark up', value: product.profit, additionalProps: { isNumeric: true, width: 105 } }
  ]
  return (
    <TableContent minWidth={minWidth} list={list} id={id} />
  )
}
