import { Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'
import { GetServerBaseUrl } from '../../env'
import {
  APIOrderCreateLara,
  APIOrderLara,
  APIUsedBrand,
  OrderLara,
  OrderProduct
} from './interfaces'
import {
  mapAPIOrderBrandToUsedBrand,
  mapAPIOrderLaraToOrderLara,
  mapAPIOrdersLaraToOrdersLara,
} from './mappers'
import {
  reduceListOrderBrands,
  reduceListOrdersLara,
  reduceListOrdersPagination,
  reduceOrderLara
} from './slice'
import {
  APIPagedLaraResponse,
  buildLaraConfig,
  buildLaraPageParams,
  LaraPagination,
  mapAPILaraPaginationToLaraPagination
} from "../../mini-lib/lara/lara-utils";
import { dispatchSetLoadingType } from "../../core/loading/api";
import { ORDER_PDF } from "./utils";


export const apiListOrderBrands = (
  token: string,
  salon_id: number,
  dateStart?: string | null,
  dateEnd?: string | null,
  threshold?: number | null,
): Promise<OrderProduct[]> => {
  const dateStartParam = dateStart ? `&date_from=${dateStart}` : ''
  const dateEndParam = dateEnd ? `&date_to=${dateEnd}` : ''
  const thresholdParam = threshold ? `&threshold=${threshold}` : ''
  const url = `${GetServerBaseUrl()}/orders/used-brands/?token=${token}&salon_id=${salon_id}${dateStartParam}${dateEndParam}${thresholdParam}`
  return axios
    .get(url)
    .then((response: { data: { data: APIUsedBrand[] } }) => {
      return mapAPIOrderBrandToUsedBrand(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchListOrderBrands = (
  token: string,
  salon_id: number,
  dateStart?: string | null,
  dateEnd?: string | null,
  threshold?: number | null,
) => {
  return (dispatch: Dispatch) => {
    return apiListOrderBrands(token, salon_id, dateStart, dateEnd, threshold).then((resp) => {
      dispatch(reduceListOrderBrands(resp))
    })
  }
}


export const apiListOrdersLara = (params: {
  token: string
  salonId: number
  pageSize?: number
  pageNumber?: number
}): Promise<{ models: OrderLara[]; pagination: LaraPagination }> => {
  const { token, salonId,  pageNumber = 1, pageSize = 10000 } = params
  const config = buildLaraConfig({ token })
  const { pageSizeParam, pageNumberParam } = buildLaraPageParams({ pageSize, pageNumber })
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/orders/?${pageSizeParam}&${pageNumberParam}`

  return axios
    .get(url, config)
    .then((response: { data: APIPagedLaraResponse }) => {
      return {
        pagination: mapAPILaraPaginationToLaraPagination(response.data.meta),
        models: mapAPIOrdersLaraToOrdersLara(response.data.data),
      }
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateOrderLara = (params: { token: string; salonId: number; body: APIOrderCreateLara }): Promise<OrderLara> => {
  const { token, salonId, body } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/orders/`
  return axios
    .post(url, body, config)
    .then((response: { data: {data: APIOrderLara} }) => {
      return mapAPIOrderLaraToOrderLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetOrderLara = (params: { token: string; salonId: number; orderId: number; }): Promise<OrderLara> => {
  const { token, salonId, orderId} = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/orders/${orderId}`
  return axios
    .get(url, config)
    .then((response: { data: {data: APIOrderLara} }) => {
      return mapAPIOrderLaraToOrderLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteOrderLara = (params: { token: string; salonId: number; orderId: number; }): Promise<void> => {
  const { token, salonId, orderId} = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/orders/${orderId}`
  return axios
    .delete(url, config)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiOrderGeneratePdfLara = (params: { token: string; salonId: number; orderId: number; }): Promise<void> => {
  const { token, salonId, orderId} = params
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/orders/${orderId}/generate-pdf`
  const config: any =  {
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  }

  return axios
    .get(url, config)
    .then((resp) => {
      const url = window.URL.createObjectURL(new Blob([resp.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'OrderDetail.pdf')
      document.body.appendChild(link)
      link.click()
      return
    })
    .catch((error) => {
      throw error
    })
}


// lara dispatches
//
//
export const dispatchListOrdersLara = (params: {
  token: string,
  salonId: number,
  searchText?: string,
  pageSize?: number,
} ) => {
  return (dispatch: Dispatch) => {
    return apiListOrdersLara(params).then((resp) => {
      dispatch(reduceListOrdersLara(resp.models))
      dispatch(reduceListOrdersPagination(resp.pagination))
    })
  }
}

export const dispatchCreateOrderLara = (params: { token: string; salonId: number; body: APIOrderCreateLara } ) => {
  return (dispatch: Dispatch) => {
    return apiCreateOrderLara(params).then((resp) => {
      dispatch(reduceOrderLara(resp))
    })
  }
}

export const dispatchGetOrderLara = (params: { token: string; salonId: number; orderId: number } ) => {
  return (dispatch: Dispatch) => {
    return apiGetOrderLara(params).then((resp) => {
      dispatch(reduceOrderLara(resp))
    })
  }
}

export const dispatchDeleteOrderLara = (params: { token: string; salonId: number; orderId: number } ) => {
  return (dispatch: Dispatch) => {
    return apiDeleteOrderLara(params).then(() => {})
  }
}

export const dispatchOrderGeneratePdfLara = (params: { token: string; salonId: number; orderId: number } ) => {
  return (dispatch: any) => {
    dispatch(dispatchSetLoadingType({ name: ORDER_PDF, state: true }))
    return apiOrderGeneratePdfLara(params).then(() => {
      dispatch(dispatchSetLoadingType({ name: ORDER_PDF, state: false }))
    })
  }
}
