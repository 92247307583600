import { PageLayout } from '../../mini-lib/layouts/PageLayout';
import { PageHeader } from '../../mini-lib/page-header/PageHeader';
import { Box, Button, Flex, Switch, Text } from '@chakra-ui/react';
import React, {ReactNode} from 'react';
import { generatePath, Link } from 'react-router-dom';
import { UseViewSize } from '../../core/UseViewSize';
import {
  UseBuildSignupQueryParams,
  UseCanSeeScales,
  UseInitializeSignupState
} from '../signup/v1/signupHooks';
import { CBSalonType, CBSubscriptionType } from '../../integrations/chargebee/constants';
import { useDispatch } from 'react-redux';
import { reduceSetSalonType, reduceSetSubscriptionType, selectSalonType } from '../../data/signup/slice';
import { COLORS } from '../../mini-lib/theme/colors';
import { Gap } from '../../mini-lib/gap/Gap';
import { useAppSelector } from "../../hooks";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { MaterialIcon } from "../../mini-lib/icons/MaterialIcon";
import { ROUTES } from "../../appRoutes";
import {ReleaseSpecialPricing} from "../../mini-lib/flags/Release";
import {ThemeType} from "../../theme";

export const CheckoutSubscriptionPage = () => {

  UseInitializeSignupState()
  const salonType = useAppSelector(selectSalonType)

  return (
    <>
      <PageLayout
        variant='full'
        header={<PageHeader title='Choose Your Subscription' breadcrumbs={[]}/>}
        content={
          <>
            <ToggleSalonType/>
            {salonType === 'solo' && <SoloSubscriptions/>}
            {salonType === 'team' && <TeamSubscriptions/>}
          </>
        }
      />
    </>
  )
}

export const ToggleSalonType = () => {
  const dispatch = useDispatch()
  const salonType = useAppSelector(selectSalonType)
  const { isMobile } = UseViewSize()
  const toggleSalonType = () => {
    if (salonType === 'team') {
      dispatch(reduceSetSalonType('solo'))
    } else {
      dispatch(reduceSetSalonType('team'))
    }
  }
  return (
    <Flex justify="center" align="center" textTransform="uppercase" gridGap="24px" m="24px 0">
      <Text variant={isMobile ? 'title1' : 'largetitle'} fontWeight="bold">
        for solos
      </Text>
      <Switch
        isChecked={salonType === 'team'}
        size="lg"
        colorScheme='brand.midnight'
        onChange={toggleSalonType}
      />
      <Text variant={isMobile ? 'title1' : 'largetitle'} fontWeight="bold">
        for teams
      </Text>
    </Flex>
  )
}
export const TeamSubscriptions = () => {
  const { isMobile } = UseViewSize()
  return (
    <>
      <Flex
        justify='center'
        align='center'
        gridGap={'24px'}
        m={isMobile ? '32px 12px' : '32px 0'}
        wrap={isMobile ? 'wrap' : 'nowrap'}
      >
        <TeamYearlySubscriptionCard/>
        <TeamMonthlySubscriptionCard/>
      </Flex>
    </>
  )
}


export const TeamMonthlySubscriptionCard = (props: {minH?: string, showButton?: boolean}) => {
  const {minH = '400px', showButton = true} = props
  const canSeeScales = UseCanSeeScales()
  return (
    <CheckoutSubscriptionCard
      minH={minH}
      topTitle=''
      title='TEAM MONTHLY'
      subtitle='Tech savvy teams that prefer self-guided software setup and implementation.'
      price={<>$149<Text as='span' fontSize='20px' fontWeight='normal'>/MONTH</Text></>}
      priceSubtitle='Billed monthly in USD'
      bulletPoints={[
        <Bullet text='Features Included:' iconName={null} bold={true}/>,
        <Bullet text='Do it yourself onboarding' bold={true}/>,
        <Bullet text='Unlimited sessions per month'/>,
        <Bullet text='Weigh, Charge, Track and Order features'/>,
        canSeeScales && <Bullet text='Bluetooth scale included ($60 value) '/>,
      ]}
      theme='midnight'
      isSelected={false}
      subscriptionType='monthly'
      salonType='team'
      showButton={showButton}
    />
  )
}

export const TeamYearlySubscriptionCard = (props: {minH?: string, showButton?: boolean}) => {
  const {minH = '400px', showButton = true} = props
  const canSeeScales = UseCanSeeScales()
  const regularPricing = {
    price: <>$125<Text as='span' fontSize='20px' fontWeight='normal'>/MONTH</Text></>,
    savings: 648,
  }
  const specialPricing = {
    price: <><Text as='span' textDecoration='line-through' fontSize='20px' fontWeight='normal'>$1499</Text> $999<Text as='span' fontSize='20px' fontWeight='normal'>/YEAR</Text></>,
    savings: 1148,
  }

  const isSpecialPricing = ReleaseSpecialPricing()
  const pricing = isSpecialPricing ? specialPricing : regularPricing
  return (
    <CheckoutSubscriptionCard
      minH={minH}
      topTitle={isSpecialPricing ? 'BLACK FRIDAY SALE': 'Most Popular'}
      title='TEAM ANNUAL'
      subtitle='Salons that are time-strapped and need one-on-one support for a smooth and efficient setup and implementation.'
      bottomTitle='Total Value of $2,147'
      bottomSubtitle={`Save $${pricing.savings} compared to our monthly plan!`}
      price={pricing.price}
      priceSubtitle='Billed Annually in USD'
      bulletPoints={[
        <Bullet text='Features Included:' iconName={null} bold={true}/>,
        <Bullet text='Do it with us onboarding ($299 value)' bold={true}/>,
        <Bullet text='Unlimited sessions per month'/>,
        <Bullet text='Weigh, Charge, Track and Order features'/>,
        canSeeScales && <Bullet text='Bluetooth scale included ($60 value)'/>,
      ]}
      theme={isSpecialPricing ? 'midnight' : 'lavender'}
      isSelected={true}
      subscriptionType='yearly'
      salonType='team'
      showButton={showButton}
    />
  )
}

export const SoloSubscriptions = () => {
  const { isMobile } = UseViewSize()
  return (
    <>
      <Flex
        justify='center'
        align='center'
        gridGap={'24px'}
        m={isMobile ? '32px 12px' : '32px 0'}
        wrap={isMobile ? 'wrap' : 'nowrap'}
      >
        <SoloYearlySubscriptionCard/>
        <SoloMonthlySubscriptionCard/>
      </Flex>
    </>
  )
}

export const SoloMonthlySubscriptionCard = (props: {minH?: string, showButton?: boolean}) => {
  const {minH = '400px', showButton = true} = props
  const canSeeScales = UseCanSeeScales()
  return (
    <CheckoutSubscriptionCard
      minH={minH}
      topTitle=''
      title='SOLO MONTHLY'
      subtitle='Booth renters or suite owners  looking to manage their color costs independently, with self guided setup.'
      price={<>$55<Text as='span' fontSize='20px' fontWeight='normal'>/MONTH</Text></>}
      priceSubtitle='Billed monthly in USD'
      bulletPoints={[
        <Bullet text='Features Included:' iconName={null} bold={true}/>,
        <Bullet text='Do it yourself onboarding' bold={true}/>,
        <Bullet text='Unlimited sessions per month'/>,
        <Bullet text='Weigh, Charge, Track and Order features'/>,
        canSeeScales && <Bullet text='Bluetooth scale included ($60 value) '/>,
      ]}
      theme='lavender'
      isSelected={false}
      subscriptionType='monthly'
      salonType='solo'
      showButton={showButton}
    />
  )
}

export const SoloYearlySubscriptionCard = (props: {minH?: string, showButton?: boolean}) => {
  const {minH = '400px', showButton = true} = props
  const canSeeScales = UseCanSeeScales()
  const regularPricing = {
    price: <>$47<Text as='span' fontSize='20px' fontWeight='normal'>/MONTH</Text></>,
    savings: 165,
  }
  const specialPricing = {
    price: <><Text as='span' textDecoration='line-through' fontSize='20px' fontWeight='normal'>$555</Text> $405<Text as='span' fontSize='20px' fontWeight='normal'>/YEAR</Text></>,
    savings: 315,
  }

  const isSpecialPricing = ReleaseSpecialPricing()
  const pricing = isSpecialPricing ? specialPricing : regularPricing
  return (
    <CheckoutSubscriptionCard
      minH={minH}
      topTitle={isSpecialPricing ? 'BLACK FRIDAY SALE': 'Most Popular'}
      title='SOLO ANNUAL'
      subtitle='The independent artist who is committed to taking control of the backbar and color costs.'
      price={pricing.price}
      priceSubtitle={'Billed annually in USD'}
      bottomTitle='Total Value of $720'
      bottomSubtitle={`Save $${pricing.savings} compared to our monthly plan!`}

      bulletPoints={[
        <Bullet text='Features Included:' iconName={null} bold={true}/>,
        <Bullet text='Do it yourself onboarding' bold={true}/>,
        <Bullet text='Unlimited sessions per month'/>,
        <Bullet text='Weigh, Charge, Track and Order features'/>,
        canSeeScales && <Bullet text='Bluetooth scale included ($60 value) '/>,
      ]}
      theme={isSpecialPricing ? 'midnight' : 'lavender'}
      isSelected={true}
      subscriptionType='yearly'
      salonType='solo'
      showButton={showButton}
    />
  )
}

export const CheckoutSubscriptionCard = ( props: {
  topTitle: string
  title: string
  subtitle: string
  bottomTitle?: string
  bottomSubtitle?: string
  price: ReactNode | string
  priceSubtitle: string | any
  bulletPoints: any[]
  theme: ThemeType | string
  isSelected: boolean
  subscriptionType: CBSubscriptionType
  salonType: CBSalonType
  minH: string
  showButton: boolean
} ) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const {
    minH = 'auto',
    topTitle,
    title,
    subtitle,
    bottomTitle,
    bottomSubtitle,
    price,
    priceSubtitle,
    bulletPoints,
    theme,
    isSelected,
    subscriptionType,
    salonType,
    showButton = true
  } = props

  const { salonId } = UseBaseApiParams()
  const canSeeScales = UseCanSeeScales()
  const setSubscriptionType = () => {
    dispatch(reduceSetSubscriptionType(subscriptionType))
  }
  // const specialPricingBowUrl = require('../../../assets/signup/dec19-card-bow.png').default
  // const bowUrl = `url(${specialPricingBowUrl})`
  const themeColor = theme === 'midnight' ? COLORS.midnight_900 : COLORS.lavender_500
  return (
    <Box w={isMobile ? '100%' : '350px'} position='relative'>

      {topTitle !== '' && <TopText text={topTitle} theme={theme}/>}

      <Flex
        bg={COLORS.shades_neutral_0}
        w='100%'
        justify='space-between'
        align='center'
        direction='column'
        borderRadius={'15px'}
        borderWidth={'1px'}
        color={COLORS.midnight_900}
        borderColor={isSelected ? themeColor : `shades.neutral.500`}
        minH={minH}
      >
        <Flex
          p={isMobile ? '24px' : '24px'}
          w='100%'
          justify='space-between'
          align='center'
          direction='column'
        >
          <Gap s='12px'/>
          <Text variant={isMobile ? 'title2' : 'title1'} fontWeight='bold'>
            {title}
          </Text>
          <Gap s='12px'/>

          <Text color={COLORS.text_secondary} fontSize='11px' textAlign='center' maxW='220px'>
            <Text as='span' fontWeight='bold'>Best For:</Text> {subtitle}
          </Text>

          <Gap/>

          <Text variant='largetitle'>
            {price}
          </Text>

          <Text h='24px' color={COLORS.text_secondary} fontSize='11px' textAlign='center'>
            {priceSubtitle}
          </Text>

          <Gap s='12px'/>

          <Flex color={themeColor} align='center' justify='center' gridGap='12px' fontWeight='600'>
            {salonType === 'team' && <><MaterialIcon size='40px' name='groups' /> Unlimited Stylists</>}
            {salonType === 'solo' && <><MaterialIcon size='40px' name='person' /> Single Login Access</>}
          </Flex>


          {showButton && (
            <>
              <Gap s='12px'/>
              <Link
                to={{
                  pathname: canSeeScales
                    ? generatePath(ROUTES.checkoutFreeScale, { salonId })
                    : generatePath(ROUTES.checkoutCart, { salonId }),
                  search: UseBuildSignupQueryParams({ subscriptionType, salonType }),
                }}
              >
                <Button
                  className='cy-next'
                  variant='round'
                  colorScheme='brand.midnight'
                  onClick={setSubscriptionType}
                >
                  choose this plan
                </Button>
              </Link>
            </>
          )}
          <Gap/>

          <Box variant={isMobile ? 'footnote' : ''} w='90%'>
            {bulletPoints.map(( point: any, index ) => {
              return (
                <Box key={index}>{point}</Box>
              )
            })}
          </Box>
        </Flex>
        {bottomTitle && (
          <Box bg={COLORS.lavender_50} w='100%' borderRadius='0 0 15px 15px' textAlign='center' p='24px'>
            <Text fontWeight='bold' >{bottomTitle}</Text>
            <Text fontStyle='italic' fontSize='11px'>{bottomSubtitle}</Text>
          </Box>
        )}
      </Flex>
    </Box>
  )
}


export const Bullet = (props: { text: string; bold?: boolean; iconName?: string | null, textColor?: string }) => {
  const { text, bold, iconName = 'check', textColor = 'black' } = props
  return (
    <Flex
      gridGap="8px"
      align="center"
      p="2px 0"
      fontSize="11px"
      fontWeight={bold ? 'bold' : ''}
      color={textColor}
    >
      {iconName && <MaterialIcon size='16px' name={iconName}/>}
      {text}
    </Flex>
  )
}
export const TopText = (props: { text: string, theme: ThemeType | string }) => {
  const {theme, text} = props
  const themeColor = theme === 'midnight' ? COLORS.midnight_900 : COLORS.lavender_500
  return (
    <Flex position="absolute" top="-20px" w="100%" justify="center">
      <Flex
        h="40px"
        w="200px"
        bg={themeColor}
        color={'white'}
        justify="center"
        align="center"
        borderRadius="8px"
        fontWeight="bold"
        fontSize="15px"
      >
        {text}
      </Flex>
    </Flex>
  )
}

