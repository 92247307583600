/////////////// slice.ts
//
//

// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../store'

// state
//
interface MultiSalonState {
  isMultiSalonSideSheetVisible: boolean
}

const initialState: MultiSalonState = {
  isMultiSalonSideSheetVisible: false,
}

// reducer
//
export const MultiSalonSlice = createSlice({
  name: 'multisalon',
  initialState,
  reducers: {
    reduceIsMultiSalonSideSheetVisible: (state, action: PayloadAction<boolean>) => {
      state.isMultiSalonSideSheetVisible = action.payload
    },
  },
})

// actions
//
export const { reduceIsMultiSalonSideSheetVisible } = MultiSalonSlice.actions

// selectors
//
export const selectIsMultiSalonSideSheetVisible = (state: RootState) => state.multisalon.isMultiSalonSideSheetVisible

// export
//
export default MultiSalonSlice.reducer
