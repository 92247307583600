import { UseViewSize } from "../../core/UseViewSize";
import { Flex } from "@chakra-ui/react";
import { COLORS } from "../theme/colors";
import { ReactNode } from "react";

export const TextBadge = (props: {label: string | ReactNode, cHex?: string, bgHex?: string}) => {
  const {isDesktop} = UseViewSize()
  const {cHex = COLORS.lavender_500, bgHex = COLORS.lavender_100, label} = props
  return <Flex
      fontSize={isDesktop ? '13px' : '12px'}
      color={cHex}
      bg={bgHex}
      flex="1 auto"
      padding="6px 12px"
      borderRadius="15px"
      align="center"
      justify="center"
  >
    {label}
  </Flex>
}