export const iosAppUrl = 'itms-apps://apps.apple.com/app/id1499547297';
const androidAppUrl = 'https://play.google.com/store/apps/details?id=com.salonscale.salonscale&hl=en_CA&pli=1';

export const redirectToScalonScaleApp = (fallbackURL = '/') => {
  const userAgent = window.navigator.userAgent;

  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isAndroid = /android/i.test(userAgent);

  if (isIOS) {
    try {
      window.location.href = iosAppUrl;
    } catch (error) {
      window.location.href = fallbackURL;
    }
  } else if (isAndroid) {
    window.location.href = androidAppUrl;
  } else {
    window.location.href = fallbackURL;
  }
};

export const isUserAgentIOS = () => /iPad|iPhone|iPod/.test(window.navigator.userAgent)