import { Box } from '@chakra-ui/react'

// example use
//
//
//
// return (
//   <Box>
//     <BlurScrollContainer>
//       <Box h='350px' overflowY='scroll'>your list of things here</Box>
//       <BlurBottom/> {/* make sure the blur is outside of the scrollable element */}
//     </BlurScrollContainer>
//   </Box>
// )

export const BlurScrollContainer = (props: { children: any }) => {
  return <Box position="relative">{props.children}</Box>
}

export const BlurBottom = (props: { height?: string; width?: string }) => {
  const { height = '100px', width = '100%' } = props
  // const darkThemeBlur = 'linear-gradient(to bottom,rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.3) 100%)'
  return (
    <Box
      pointerEvents='none'
      position="absolute"
      bottom="0"
      display="block"
      backgroundImage="linear-gradient(to bottom,rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.9) 100%)"
      h={height}
      w={width}
    />
  )
}
