import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateRangeType } from '../../mini-lib/dates-and-times/constants'
import { RootState } from '../../store'
import { LaborLaborReports, LaborLaborReportsCSV } from './interfaces'
import { mapLaborLaborReportsToLaborLaborReportsCSV } from './mappers'

interface LaborReportsState {
  laborLaborReports: LaborLaborReports[] | null
  selectedDateRangeType: DateRangeType
  customStartDate: string
  customEndDate: string
  laborCSV: LaborLaborReportsCSV[] | null
  laborCSVTitle: string
}

const initialState: LaborReportsState = {
  laborLaborReports: null,
  selectedDateRangeType: 'week',
  customStartDate: '',
  customEndDate: '',
  laborCSV: null,
  laborCSVTitle: 'Labor.csv',
}

export const LaborReportsSlice = createSlice({
  name: 'laborReports',
  initialState,
  reducers: {
    reduceLaborLaborReports: (state, action: PayloadAction<LaborLaborReports[]>) => {
      state.laborLaborReports = action.payload
      state.laborCSV = mapLaborLaborReportsToLaborLaborReportsCSV(action.payload)
      state.laborCSVTitle = 'Labor-Total-Reports.csv'
    },

    reduceSelectedDateRangeForLaborReports: (state, action: PayloadAction<DateRangeType>) => {
      state.selectedDateRangeType = action.payload
    },

    reduceCustomStartDateForLaborReports: (state, action: PayloadAction<string>) => {
      state.customStartDate = action.payload
    },

    reduceCustomEndDateForLaborReports: (state, action: PayloadAction<string>) => {
      state.customEndDate = action.payload
    },
  },
})

export const {
  reduceSelectedDateRangeForLaborReports,
  reduceLaborLaborReports,
  reduceCustomStartDateForLaborReports,
  reduceCustomEndDateForLaborReports,
} = LaborReportsSlice.actions

// selectors
export const selectLaborLaborReports = (state: RootState): LaborLaborReports[] | null =>
  state.laborReports.laborLaborReports ? state.laborReports.laborLaborReports : null

export const selectReportsDateRangeTypeForLaborReports = (state: RootState): DateRangeType =>
  state.laborReports.selectedDateRangeType

export const selectCustomStartDateForLaborReports = (state: RootState): string => state.laborReports.customStartDate
export const selectCustomEndDateForLaborReports = (state: RootState): string => state.laborReports.customEndDate

export const selectLaborCSV = (state: RootState): LaborLaborReportsCSV[] | null => state.laborReports.laborCSV

export const selectLaborCSVTitle = (state: RootState): string => state.laborReports.laborCSVTitle

export default LaborReportsSlice.reducer
