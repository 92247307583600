export type DateRangeType = 'all' | 'year' | 'month' | 'week' | 'day' | 'twoweeks' | 'fourweeks' | 'custom'
export type DateInterval = 'year' | 'month' | 'date' | 'custom'

export interface DateSelection {
  type: DateRangeType | null
  startDate: Date | null
  endDate: Date | null
}

export const DATE_RANGE_OPTION_MAP: { [key: string]: { label: string; value: string } } = {
  day: { label: 'Today', value: 'day' },
  week: { label: 'This Week', value: 'week' },
  month: { label: 'Month to Date', value: 'month' },
  year: { label: 'Year to Date', value: 'year' },
  all: { label: 'All Time', value: 'all' },
}

export const DATE_RANGE_OPTION_MAP_WITH_CUSTOM: { [key: string]: { label: string; value: string } } = {
  ...DATE_RANGE_OPTION_MAP,
  custom: { label: 'Custom', value: 'custom' },
}

// this is the most ridiculous constant that should not have to exist
// `new Date(2022, 11, 23)` should be `November 23 2022` right? WRONG
// `new Date(2022, 11, 23)` is `December 23 2022` in javascript world because arrays start at 0, it views months as an array
// note the 23rd stays the 23rd, this only happens to the months not the date

// for your sanity just use `new Date(2022, MONTHS.november, 23)`
export const MONTHS = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
}
