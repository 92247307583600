import React, { ReactNode } from "react";
import { Text } from "@chakra-ui/react";

export const FormikError = ( props: { showError: boolean; children?: ReactNode; errorText?: string } ) => {
  const { showError, errorText, children } = props
  return (
    <>
      {showError && (
        <Text color="red.500" fontSize="14px" mt="8px">
          {errorText}
          {children}
        </Text>
      )}
    </>
  )
}
