import { Divider, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { UseViewSize } from '../../../core/UseViewSize'
import { LaborLaborReports } from '../../../data/labor-report/interfaces'
import { COLORS } from '../../../mini-lib/theme/colors'
import { formatDollars } from "../../../mini-lib/units/money";

export const LaborReportTitleRow = (props: { pageName: string }) => {
  const { pageName } = props
  const { isDesktop, isTablet } = UseViewSize()

  return (
    <>
      {/* LaborReport title row */}
      {(pageName === 'LaborReport' ||
        pageName === 'LaborStylistDetailReport' ||
        pageName === 'LaborClientDetailReport') && (
        <>
          <Flex p="24px 0" gridGap="12px">
            <Flex w={isDesktop || isTablet ? '300px' : '66%'}>
              <Text ml="30px" variant="callout" color={COLORS.text_tertiary}>
                type
              </Text>
            </Flex>
            <Flex w="150px">
              <Text variant="callout" color={COLORS.text_tertiary}>
                system
              </Text>
            </Flex>
            <Flex w="150px">
              <Text variant="callout" color={COLORS.text_tertiary}>
                time
              </Text>
            </Flex>
            <Flex w="150px">
              <Text variant="callout" color={COLORS.text_tertiary}>
                qty
              </Text>
            </Flex>
            <Flex w="150px">
              <Text variant="callout" color={COLORS.text_tertiary}>
                retail
              </Text>
            </Flex>
          </Flex>
          <Divider />
        </>
      )}
      {/* {pageName === 'LaborStylistReport' && (
        <>
          <Flex p="24px 0" gridGap="12px">
            <Flex w={isDesktop || isTablet ? '300px' : '66%'}>
              <Text ml="30px" variant="callout" color={COLORS.text_tertiary}>
                stylist
              </Text>
            </Flex>
            <Flex w="150px">
              <Text variant="callout" color={COLORS.text_tertiary}>
                retail
              </Text>
            </Flex>
          </Flex>
          <Divider />
        </>
      )}
      {pageName === 'LaborClientReport' && (
        <>
          <Flex p="24px 0" gridGap="12px">
            <Flex w={isDesktop || isTablet ? '300px' : '66%'}>
              <Text ml="30px" variant="callout" color={COLORS.text_tertiary}>
                customer
              </Text>
            </Flex>
            {isDesktop && (
              <Flex w="150px">
                <Text variant="callout" color={COLORS.text_tertiary}>
                  date
                </Text>
              </Flex>
            )}
            <Flex w="150px">
              <Text variant="callout" color={COLORS.text_tertiary}>
                retail
              </Text>
            </Flex>
          </Flex>
          <Divider />
        </>
      )} */}
    </>
  )
}

export const LaborReportTotalRow = (props: { pageName: string; models: LaborLaborReports[] }) => {
  //TODO: Change models type any to interface
  const { pageName, models } = props
  const { isDesktop, isTablet } = UseViewSize()
  const sumRetail = models ? models.map((model) => model.retail).reduce((a, b) => a + b, 0) : null

  return (
    <>
      {(pageName === 'LaborReport' ||
        pageName === 'LaborStylistDetailReport' ||
        pageName === 'LaborClientDetailReport') && (
        <>
          <Flex p="24px 0" gridGap="12px">
            <Flex w={isDesktop || isTablet ? '300px' : '66%'}>
              <Text ml="30px" variant="callout" fontWeight="bold">
                total
              </Text>
            </Flex>
            <Flex w="150px"></Flex>
            <Flex w="150px"></Flex>
            <Flex w="150px"></Flex>
            <Flex w="150px" maxW="150px" wordBreak="break-word">
              <Text variant="callout" fontWeight="bold">
                ${formatDollars(sumRetail)}
              </Text>
            </Flex>
          </Flex>
        </>
      )}
    </>
  )
}

export const LaborReportRow = (props: { pageName: string; models: LaborLaborReports[] }) => {
  //TODO: Change models type any to interface
  const { pageName, models } = props
  const { isDesktop, isTablet } = UseViewSize()
  return (
    <>
      {(pageName === 'LaborReport' ||
        pageName === 'LaborStylistDetailReport' ||
        pageName === 'LaborClientDetailReport') &&
        models && (
          <>
            {models.map((report, key) => (
              <Flex p="24px 0" gridGap="12px" key={key} borderBottom="0.5px solid rgba(0, 17, 57, 0.6)">
                <Flex
                  w={isDesktop || isTablet ? '300px' : '66%'}
                  maxW={isDesktop || isTablet ? '300px' : '66%'}
                  wordBreak="break-word"
                >
                  <Text ml="30px" variant="callout">
                    {report.type}
                  </Text>
                </Flex>
                <Flex w="150px" maxW="150px" wordBreak="break-word">
                  <Text variant="callout">{report.system}</Text>
                </Flex>
                <Flex w="150px" maxW="150px" wordBreak="break-word">
                  <Text variant="callout">{report.time}</Text>
                </Flex>
                <Flex w="150px" maxW="150px" wordBreak="break-word">
                  <Text variant="callout">{report.quantity}</Text>
                </Flex>
                <Flex w="150px" maxW="150px" wordBreak="break-word">
                  <Text variant="callout">${formatDollars(report.retail)}</Text>
                </Flex>
              </Flex>
            ))}
          </>
        )}
    </>
  )
}
