export interface PossibleErrorType {
  code?: number
  data?: {
    error?: {
      code?: number
      details?: string
      message?: string
    }
  }
}

export const extractErrorFromResponse = (response: PossibleErrorType, defaultResponse: string): string => {
  const errorCode = response?.data?.error?.code
  const errorDetails = response?.data?.error?.details

  if (errorDetails) {
    return errorDetails
  }

  if (errorCode === 401) {
    // usually happens when the user updates their password and then tries to use a different device that's already logged in
    return 'Session Expired. Please login again. (Error: 401)'
  }
  if (errorCode === 402) {
    return 'Your subscription has expired. Please renew or contact your salon owner or support. (Error: 402)'
  }

  if (errorCode === 403) {
    // usually happens if they are a stylist
    return 'Access Denied. Contact your owner. (Error: 403)'
  }

  if (errorCode === 404) {
    return `Couldn't find what you're looking for. (Error: 404)`
  }
  return defaultResponse
}
