import React from 'react'
import Select from 'react-select'
import { dropdownGhostVariantStyles, selectTheme, ThemeType } from '../../theme'
import { Box } from '@chakra-ui/react'
import { UseViewSize } from '../../core/UseViewSize'
import { find } from 'lodash'
import { TriangleDownIcon } from '@chakra-ui/icons'

export interface DropdownOption {
  label: string
  value: string
}

// this wraps the react select lib in a way that we commonly use
export const DropdownFilter = ( props: {
  variant?: 'ghost' | 'round'
  version?: 1
  theme?: ThemeType | ''
  options: DropdownOption[]
  placeholder: string
  value: string | null // this will be the option.value
  onChange: ( option: DropdownOption ) => void
  w?: string
  isClearable?: boolean
  isDisabled?: boolean
  hasError?: boolean
} ) => {
  const { isMobile } = UseViewSize()
  const {
    variant = 'round',
    version = 1,
    theme = '',
    placeholder,
    value,
    options,
    onChange,
    w = isMobile ? '31%' : '150px',
    isClearable = true,
    isDisabled = false,
    hasError = false,
    // allowMultiple = false
  } = props
  const styles = dropdownGhostVariantStyles('lavender')
  const selectedOption = value ? find(options, ( option ) => option.value === value) : null

  const componentOverrides = value
    ? {
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }
    : {
      IndicatorSeparator: () => null,
      DropdownIndicator: () => <TriangleDownIcon height={'10px'} width={'10px'}/>
    }
  if (![1].includes(version)) {
    console.warn('the Dropdown component version you have selected does not exist')
  }
  return (
    <>
      {variant === 'round' && (
        <Box w={w} maxW="100%">
          {/*isMulti={allowMultiple}*/}
          <Select
            components={componentOverrides}
            isDisabled={isDisabled}
            value={selectedOption}
            styles={selectTheme({theme, hasError, hasValue: !!value})}
            placeholder={placeholder}
            options={options}
            onChange={( option: any ) => {
              if (option) {
                onChange(option)
              } else {
                onChange({ label: '', value: '' })
              }
            }}
            isClearable={isClearable}
          />
        </Box>
      )}
      {variant === 'ghost' && (
        <Box w={w} maxW="100%">
          <Select
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            components={{ DropdownIndicator: () => <TriangleDownIcon height={'0.7rem'} width={'0.7rem'}/> }}
            isDisabled={false}
            value={selectedOption}
            styles={styles}
            placeholder={placeholder}
            options={options}
            onChange={( option ) => {
              if (option) {
                onChange(option)
              } else {
                onChange({ label: '', value: '' })
              }
            }}
            isClearable={false}
          />
        </Box>
      )}
    </>
  )
}
