import { Checkbox, Flex, Spinner, Text, Tooltip } from '@chakra-ui/react'
import { isEqual, startCase } from 'lodash'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { dispatchUpdatePermissionSetting } from '../../data/settings/api'
import { OWNER_TITLE } from '../../data/settings/constants'
import { selectRolePermissionState } from '../../data/settings/slice'
import { useAppSelector } from '../../hooks'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { selectLoadingState } from '../../core/loading/slice'
import {
  buildPermissionTooltip,
  buildRoleLabel,
  buildRolePermissionKey,
  buildRoleTooltip,
  getPermissionDisplayName,
} from '../../data/settings/utils'
import { COLORS } from '../../mini-lib/theme/colors'
import { useState } from 'react'

export const PermissionTitleCell = (props: { permissionName: string; theme: string }) => {
  const { permissionName, theme } = props
  const tooltip = buildPermissionTooltip(permissionName)
  const [showTooltip, setShowToolTip] = useState(false)
  const displayName = getPermissionDisplayName(permissionName)
  return (
    <Tooltip label={tooltip} isTruncated={true} isOpen={showTooltip}>
      <Flex
        justify="space-between"
        align="center"
        h="100%"
        w="100%"
        bg={`brand.${theme}.50`}
        color="black"
        borderLeftColor={`brand.${theme}.500`}
        borderLeftWidth="9px"
        p="0 12px"
      >
        <Text w={'100%'}>{startCase(displayName)}</Text>
        <MaterialIcon
          cursor="pointer"
          colorhex={COLORS.shades_neutral_500}
          name={'info_outlined'}
          onClick={() => setShowToolTip(!showTooltip)}
        />
      </Flex>
    </Tooltip>
  )
}

export const RoleTitleCell = (props: { roleName: string }) => {
  const { roleName } = props
  const label = buildRoleLabel(roleName)
  const tooltip = buildRoleTooltip(roleName)
  const [showTooltip, setShowToolTip] = useState(false)
  return (
    <Tooltip label={tooltip} isTruncated={true} isOpen={showTooltip}>
      <Flex
        p="0 12px"
        justify="space-between"
        align="center"
        h="100%"
        bg="shades.neutral.200"
        color="black"
        width="100%"
      >
        <Text isTruncated={true}>{label}</Text>
        <MaterialIcon
          cursor="pointer"
          colorhex={COLORS.shades_neutral_500}
          name={'info_outlined'}
          onClick={() => setShowToolTip(!showTooltip)}
        />
      </Flex>
    </Tooltip>
  )
}

export const PermissionCheckBoxCell = (props: { role: string; permission: string; id: number }) => {
  const { role, permission, id } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  const dispatch = useDispatch()

  const loadingKey = buildRolePermissionKey({ id, roleName: role, permissionName: permission })
  const updatePermission = (token: string, role_id: number, permissionName: string, hasAccess: boolean) => {
    dispatch(
      dispatchUpdatePermissionSetting({
        token,
        salon_id: salonId,
        role_id,
        permissionName,
        hasAccess,
        loadingKey,
      }),
    )
  }
  const permissionState: boolean = useAppSelector((state) => selectRolePermissionState(state, id, role, permission))
  const isUpdating = useAppSelector((state) => selectLoadingState(state, loadingKey))
  return (
    <Flex justifyContent={'center'} align="center" h="100%">
      {isUpdating ? (
        <Spinner h="16px" w="16px" />
      ) : (
        <Checkbox
          isChecked={permissionState || isEqual(role.toLowerCase(), OWNER_TITLE)}
          // isDisabled={isEqual(role.toLowerCase(), OWNER_TITLE)}
          isDisabled={true}
          colorScheme={'brand.lavender'}
          size={'md'}
          onChange={() => {
            updatePermission(token, id, permission.toLowerCase(), !permissionState)
          }}
        />
      )}
    </Flex>
  )
}
