import React from 'react'
import { SGLayout } from './SGLayout'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { SalonScaleProductIcon } from '../../mini-lib/icons/SalonScaleProductIcon'
import { UseViewSize } from '../../core/UseViewSize'
import { generatePath, Link } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { ROUTES } from "../../appRoutes";

export const SGIntroPage = () => {
  const { isMobile } = UseViewSize()
  const { salonId, user } = UseBaseApiParams()
  return (
    <>
      <SGLayout>
        <Flex justify="center">
          <Flex justify="center" align="center" direction="column" maxW="500px" gridGap="24px" textAlign="center">
            <Box h={isMobile ? '48px' : '92px'} />
            <SalonScaleProductIcon name="wavinghand" variant="nobackground" />
            <Text variant="largetitle" textTransform="uppercase">
              Hi, {user.firstName} {user.lastName}! Welcome to The SalonScale Startguide.
            </Text>
            <Text>
              Before you get started in the app, we need to set up your color lines and determine your color lines’
              pricing. When you’re ready, click the button below.
            </Text>
            <Link to={generatePath(ROUTES.sgAddLinesIntro, { salonId })}>
              <Button variant="round" minWidth="200px">
                Get Started
              </Button>
            </Link>
          </Flex>
        </Flex>
      </SGLayout>
    </>
  )
}
