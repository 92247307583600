import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { useAppSelector } from '../../hooks'
import { selectChecklistItemsByCode, selectStepsCompleted } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { ReleaseExtensionsV2 } from "../../mini-lib/flags/Release";
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";

export const SGExtensionsIntroPage = () => {
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems
  useEffect(() => {
    if (!checklistItemsLoaded && userId) {
      dispatch(dispatchListUserChecklistItems(token, userId))
    }
  }, [dispatch, token, userId, checklistItemsLoaded])

  const releaseExtensionsV2 = ReleaseExtensionsV2()
  const stepCodes = [CHECKLIST_CODES.addAddons, CHECKLIST_CODES.addExtensions]
  const stepsCompleted = useAppSelector((state) => selectStepsCompleted(state, stepCodes))
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          iconName="products"
          title="Add Extensions to your Backbar"
          description={descriptionText}
          video={VIDEO_MAP.addExtensions}
          actions={
            <SGActionButtons
              action={{
                label: 'Add Extensions',
                url: generatePath(releaseExtensionsV2 ? ROUTES.sgAddExtensionsV2 : ROUTES.sgAddExtensions, { salonId }) + `?guide=${CHECKLIST_CODES.addExtensions}`,
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={stepCodes.length} stepsComplete={stepsCompleted || 0} />}
          imgPath={require('../../assets/start-guide/extensions.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `Just like adding color lines, the Extensions feature helps to track and charge for hair extensions. If you don’t currently use extensions, you can skip this step or revisit later if things in your business change!

Pricing tip:
If you sell a full pack of extensions to a client every time, put in the pack cost and select how many packs you used on the client in app.

If you break your packs down and sell partial packs, divide the pack cost by the quantity of extensions per pack and enter that. When using the app select how many extensions from the pack you used on the client and SalonScale will tally up the total cost of all the extensions used.`
