import { Box, Button, Editable, EditableInput, EditablePreview, Flex } from '@chakra-ui/react'
import { MaterialIcon } from '../icons/MaterialIcon'
import { UseViewSize } from '../../core/UseViewSize'
import { BRAND_THEMES } from '../../theme'

export const EditableButton = (props: {
  onClick: () => void
  onRemove: (any) => void
  onUpdate: (string) => void
  isSelected: boolean
  defaultValue?: string
  width?: string
  theme?: string
}) => {
  const { isMobile } = UseViewSize()
  const {
    width,
    onClick,
    onUpdate,
    onRemove,
    isSelected = false,
    defaultValue = '',
    theme = BRAND_THEMES.midnight,
  } = props

  const w = width ? width : isMobile ? '100%' : '220px'
  return (
    <Button
      width={w}
      maxWidth="100%"
      colorScheme={`brand.${theme}`}
      variant={isSelected ? 'round' : 'round-outline'}
      onClick={onClick}
    >
      <Flex w="100%" h="100%" align="center" justify="space-between">
        <Box w="24px" />
        <Editable defaultValue={defaultValue} onSubmit={onUpdate}>
          <EditablePreview cursor="pointer" textTransform="uppercase" />
          <EditableInput textTransform="uppercase" />
        </Editable>
        <Box w="24px">{isSelected && <MaterialIcon name="close" onClick={onRemove} />}</Box>
      </Flex>
    </Button>
  )
}
