import React, { useState } from 'react'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { Alert, AlertIcon, Box, Button, Flex, Text } from '@chakra-ui/react'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { useDispatch } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import {
  reduceHomeCustomEndDate,
  reduceHomeCustomStartDate,
  reduceSetDateRangeType,
  selectDateRangeData,
} from '../../data/analytics/slice'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { values } from 'lodash'
import { UseViewSize } from '../../core/UseViewSize'
import { HomeTotals } from './components/HomeTotals'
import { HomeRevenueGraph } from './components/HomeRevenueGraph'
import { HomeProductBarGraph } from './components/HomeProductBarGraph'
import { HomeRecentSessions } from './components/HomeRecentSessions'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { HomeTopClients } from './components/HomeTopClients'
import { ReleaseStartGuideV2 } from '../../mini-lib/flags/Release'
import { COLORS } from '../../mini-lib/theme/colors'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { ROUTES } from "../../appRoutes";
import { CustomDateRangeModal } from "../../mini-lib/dates-and-times/CustomDateRangeModal";
import { SelectInput } from "../../mini-lib";

export function HomePage() {
  const breadcrumbs = [{ label: 'Home' }]
  const { isTablet, isMobile } = UseViewSize()
  return (
    <PageLayout
      variant="full"
      top={<AutoAddPricingBanner />}
      header={<PageHeader title="Business Center" breadcrumbs={breadcrumbs} />}
      content={
        <Flex justify={isTablet ? 'center' : 'space-between'} flexWrap="wrap">
          {/* left column */}
          <Flex direction="column" w={isTablet || isMobile ? '100%' : '66%'}>
            <Box h="48px" />
            <HomeTotals />
            <Box h="48px" />
            <HomeQuickLinks />
            <Box h="48px" />
            <HomeRevenueGraph />
            <Box h="48px" />
            <HomeProductBarGraph />
          </Flex>
          {/* right column */}
          <Flex direction={isTablet ? 'column-reverse' : 'column'} w={isTablet || isMobile ? '100%' : '34%'}>
            <Box h="48px" />
            <HomeDateRangeSelect />
            <Box h="48px" />
            <HomeScaleBanner />
            <Box h="48px" />
            <HomeTopClients />
            <Box h="48px" />
            <HomeRecentSessions />
          </Flex>
        </Flex>
      }
    />
  )
}

export const AutoAddPricingBanner = () => {
  const { salonId } = UseBaseApiParams()
  const releaseStartGuideV2 = ReleaseStartGuideV2()
  // todo: maybe get api call that tells us if they have auto pricing for products or something
  // this seems like a weird place to call products and do a lot of work just to show a banner
  const showBanner = false
  return (
    <>
      {showBanner && releaseStartGuideV2 && (
        <Alert status="info" bg={COLORS.skylight_50} borderRadius="6px" border={`1px solid ${COLORS.skylight_500}`}>
          <Flex align="center" justify="space-between" w="100%">
            <Flex align="center" gridGap="8px">
              <AlertIcon color={COLORS.skylight_500} />
              Looks like you are still using automatic product costs. Customize your prices to see the best profit in
              your salon.
            </Flex>
            <Box color={COLORS.lavender_500}>
              <Link to={generatePath(ROUTES.sgAddPricing, { salonId }) + `?guide=${CHECKLIST_CODES.addPricing}`}>
                Customize Prices
              </Link>
            </Box>
          </Flex>
        </Alert>
      )}
    </>
  )
}

export const HomeQuickLinks = () => {
  const { isMobile, isTablet } = UseViewSize()
  const { salonId } = UseBaseApiParams()
  // todo-reports: update this to v2 when it goes live
  return (
    <Flex gridGap={isMobile ? '4px' : '24px'} justify={isMobile || isTablet ? 'space-between' : ''}>
      <Link to={generatePath(ROUTES.lines, { salonId })}>
        <Button w={isMobile ? '100%' : '150px'} variant="round" colorScheme="brand.midnight">
          Products <MaterialIcon name="chevron_right" />
        </Button>
      </Link>
      <Link to={generatePath(ROUTES.orders, { salonId })}>
        <Button w={isMobile ? '100%' : '150px'} variant="round" colorScheme="brand.midnight">
          Orders <MaterialIcon name="chevron_right" />
        </Button>
      </Link>
      <Link to={generatePath(ROUTES.colorBrandReports, { salonId })}>
        <Button w={isMobile ? '100%' : '150px'} variant="round" colorScheme="brand.midnight">
          Reports <MaterialIcon name="chevron_right" />
        </Button>
      </Link>
    </Flex>
  )
}

export const DATE_RANGE_OPTION_MAP = {
  day: { label: 'Today', value: 'day' },
  week: { label: 'This Week', value: 'week' },
  month: { label: 'Month to Date', value: 'month' },
  year: { label: 'Year to Date', value: 'year' },
  all: { label: 'All Time', value: 'all' },
  custom: { label: 'Custom', value: 'custom' },
}

export const HomeDateRangeSelect = () => {
  const dispatch = useDispatch()
  const { isMobile, isTablet } = UseViewSize()
  const dateRangeData = useAppSelector(selectDateRangeData)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
    <Flex justify="flex-end">
      {isOpen && (
        <CustomDateRangeModal 
          setIsOpen={setIsOpen} 
          isOpen={isOpen}
          startDateValue={dateRangeData.start}
          endDateValue={dateRangeData.end}
          onSelect={({ startDate, endDate}) => {
            dispatch(reduceHomeCustomStartDate(startDate))
            dispatch(reduceHomeCustomEndDate(endDate))
          }}
        />
      )}
      <Box zIndex={2} w={isMobile || isTablet ? '100%' : '450px'}>
        <SelectInput
          placeholder='Select Date Range'
          value={dateRangeData.dateRangeType}
          options={values(DATE_RANGE_OPTION_MAP)}
          onChange={(value) => {
            dispatch(reduceSetDateRangeType(value))
            if (value === 'custom') {
              setIsOpen(true)
            } else {
              setIsOpen(false)
            }
          }}
        />
      </Box>
    </Flex>
      {dateRangeData.dateRangeType === 'custom' && (
        <Flex p='8px 20px' align='center' justify='space-between'>
          range: {dateRangeData.start.toLocaleDateString('en-US')} to {dateRangeData.end.toLocaleDateString('en-US')}
          <Button variant={'round-ghost'} onClick={() => setIsOpen(true)}>change</Button>
        </Flex>
      )}
  </>
  )
}

export const HomeScaleBanner = () => {
  const lightImgUrl = 'https://storage.googleapis.com/salonscale-prod-webapp-assets/images/color-your-way.png'
  const height = '125px'
  return (
    <a target="_blank" href="https://salonscale.com/bluetooth-scale/" rel="noreferrer">
      <Flex w="100%" h={height} justify="flex-end" borderRadius="15px" position="relative" bg="shades.neutral.50">
        <Box
          position="absolute"
          opacity="0.7"
          top={0}
          left={0}
          backgroundImage={`url(${lightImgUrl})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          h={height}
          w="150px"
          borderRadius="15px"
          zIndex={0}
        />
        <Flex direction="column" align="center" justify="center" mr="24px" zIndex={1}>
          <Text variant="body" fontWeight="normal">
            SHOP OUR LINE OF
          </Text>
          <Text variant="largetitle" fontWeight="normal">
            BLUETOOTH SCALES
          </Text>
        </Flex>
      </Flex>
    </a>
  )
}
