import { Box, Checkbox, Flex, Input, Select, Text } from '@chakra-ui/react'
import { UseViewSize } from '../../../core/UseViewSize'
import { generatePath, Link } from 'react-router-dom'
import { SignupSelectedItems, SignupTotal } from './SignupCart'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import './CheckoutPage.scss'
import {
  reduceAllowSmsContact,
  reduceSetHeardAboutUsExtraDetails,
  reduceSetHeardAboutUsOptionValue,
  selectAllowSmsContact,
  selectHeardAboutUsOptionValue,
} from '../../../data/signup/slice'
import { TopNav } from '../../../mini-lib/top-nav/TopNav'
import { HEARD_ABOUT_OPTIONS } from '../../../data/signup/constants'
import { UseBuildSignupQueryParams, UseCanSeeScales, UseInitializeSignupState } from './signupHooks'
import { SignupForm } from './SignupForm'
import { Gap } from "../../../mini-lib/gap/Gap";
import { useAppSelector } from "../../../hooks";
import { COLORS } from "../../../mini-lib/theme/colors";
import { ROUTES } from "../../../appRoutes";

export const CheckoutPage = ( props: {} ) => {
  const { isDesktop, isTablet, isMobile } = UseViewSize()
  UseInitializeSignupState()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const canSeeScales = UseCanSeeScales()
  return (
    <Box>
      <TopNav/>
      {isMobile && (
        <Box p="12px">
          {canSeeScales
            ? <Link
              to={{ pathname: generatePath(ROUTES.signupAccessories), search: UseBuildSignupQueryParams() }}
              className="cy-back"
            >
              <Text color="shades.neutral.500">{'<'} Back to Accessories</Text>
            </Link>

            : <Link
              to={{ pathname: generatePath(ROUTES.signupPlans), search: UseBuildSignupQueryParams() }}
              className="cy-back"
            >
              <Text color="shades.neutral.500">{'<'} Back to Plans</Text>
            </Link>
          }
        </Box>
      )}
      <Flex flexWrap="wrap-reverse">
        {/* left column */}
        <Flex w={isDesktop ? '50%' : '100%'} direction="column" align="center">
          <Box maxW={isDesktop ? '500px' : '100%'}>
            {!isMobile && (
              <Box w='100%'>
                <Box h="48px"/>
                <Link
                  to={{ pathname: canSeeScales ? generatePath(ROUTES.signupAccessories) : generatePath(ROUTES.signupPlans), search: UseBuildSignupQueryParams() }}
                  className="cy-back"
                >
                  <Text color="shades.neutral.500">
                    {'<'} Back
                  </Text>
                </Link>
                <Box h="24px"/>
              </Box>
            )}
            {isMobile && <Box h="24px"/>}
            <Box p={isMobile ? '24px' : ''}>
              <Text
                w="100%"
                variant={isMobile ? 'largetitle' : 'largetitle'}
                textTransform="uppercase"
              >
                YOUR ORDER
              </Text>
              <Box h="24px"/>
              <Text variant={isMobile ? 'largetitle' : 'default/regular/title3'}>Account Create</Text>
              <Gap/>
              <SignupForm/>
            </Box>
          </Box>
        </Flex>
        {/* right column */}
        <Flex
          w={isDesktop ? '50%' : '100%'}
          direction="column"
          align="center"
          minH={isMobile || isTablet ? '' : '100vh'}
        >
          <Box width="100%" maxW="500px" p={isMobile ? '0 12px' : ''} >
            {/* this offsets the back to accessories link */}
            <Box h={isMobile ? '' : '24px'}/>
            <Box h={isMobile ? '12px' : '72px'}/>

            <Box bg="brand.lavender.50" p={isMobile ? '12px' : '24px'} borderRadius='20px'>
              <Text variant={isMobile ? 'largetitle' : 'default/regular/title3'}>
                Your Order Summary
              </Text>
              <Box h="24px"/>
              <SignupSelectedItems
                includeAccessories={true}
                includeSectionTitles={false}
                includeDescription={true}
                appearDisabled={false}
              />

              <Box h="24px"/>
              <SignupTotal
                showPolicies={true}
              />
            </Box>

            <Box h="48px"/>
            <a href="https://salonscale.com/contact-sales/" target="_blank" rel="noreferrer">
              <Flex justify="center">
                Questions?{' '}
                <Box ml="4px" fontWeight="bold" textDecoration="underline">
                  {' '}
                  Contact Customer Service
                </Box>
              </Flex>
            </a>
            <Box h="12px"/>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export const Policies = () => {
  return (
    <Box fontSize='14px'>
      <Flex flexWrap="wrap">
        By signing up you agree to the
        <a href="https://app.salonscale.com/legal/terms" target="_blank" rel="noreferrer">
          <Text p="0 4px" color="brand.lavender.500">
            terms of use
          </Text>
        </a>
        and our
        <a href="https://app.salonscale.com/legal/privacy" target="_blank" rel="noreferrer">
          <Text p="0 4px" color="brand.lavender.500">
            privacy policy.
          </Text>
        </a>
      </Flex>
      <Gap s='4px'/>
      <CancellationAndRefundPolicies/>
    </Box>
  )
}

export const CancellationAndRefundPolicies = () => {
  return (
    <Flex flexWrap="wrap">
      Please read our
      <a href="https://bc.salonscale.com/cancellation-policy" target="_blank" rel="noreferrer">
        <Text p="0 4px" color="brand.lavender.500">
          cancellation
        </Text>
      </a>
      and
      <a href="https://bc.salonscale.com/refund-policy" target="_blank" rel="noreferrer">
        <Text p="0 4px" color="brand.lavender.500">
          refund
        </Text>
      </a>
      policies.
    </Flex>
  )
}

export const TextMessagePolicy = () => {
  const dispatch = useDispatch()
  const allowSmsContact = useAppSelector(selectAllowSmsContact)
  return (
    <Box>
      <Checkbox
        colorScheme="brand.lavender"
        isChecked={allowSmsContact}
        onChange={() => {
          dispatch(reduceAllowSmsContact(!allowSmsContact))
        }}
      >
        <Text fontSize='14px'>Yes, please allow SalonScale to send me SMS text messages.</Text>
      </Checkbox>
      <Gap s='6px'/>
      <Text fontSize='9px' color={COLORS.text_secondary}>
        Message and data rates may apply. Reply HELP for help. Reply STOP to unsubscribe from SMS messages. Message
        frequency varies.
      </Text>
    </Box>
  )
}

export const AlreadyHaveAccount = () => {
  return (
    <>
      <Flex justify='center'>
        Already have an account?
        <Link to={generatePath(ROUTES.login)}>
          <Text p="0 4px" color="brand.lavender.500">
            Sign In
          </Text>
        </Link>
      </Flex>
    </>
  )
}
export const HeardAboutUs = ( props: { isDisabled: boolean } ) => {
  const { isDisabled } = props
  const dispatch = useDispatch()
  const heardAboutUsOptionValue = useSelector(selectHeardAboutUsOptionValue)
  return (
    <>
      <Text color={isDisabled ? COLORS.shades_neutral_400 : ''}>How did you hear about SalonScale?</Text>
      <Flex gridGap="12px" w='100%'>

        <Select
          borderRadius="100px"
          isDisabled={isDisabled}
          w={heardAboutUsOptionValue ? '50%' : '100%'}
          placeholder="How did you find us?"
          onChange={( e: any ) => {
            dispatch(reduceSetHeardAboutUsOptionValue(e.target.value))
          }}
        >
          {HEARD_ABOUT_OPTIONS.map(( option: any ) => {
            return (
              <option key={option.id} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </Select>
        {heardAboutUsOptionValue && (
          <Input
            borderRadius="100px"
            isDisabled={isDisabled}
            maxW="450px"
            w={'50%'}
            placeholder="Extra details"
            onBlur={( evt ) => dispatch(reduceSetHeardAboutUsExtraDetails(evt.target.value))}
          />
        )}
      </Flex>
    </>
  )
}
