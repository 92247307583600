import React, { useEffect, useMemo, useState } from 'react'
import { dispatchListClients } from '../../data/clients/api'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { selectClientList } from '../../data/clients/slice'
import { filterClients } from '../../data/clients/utils'
import { Client } from '../../data/clients/interfaces'
import { Box } from '@chakra-ui/react'
import { Loading } from '../../mini-lib/loading/Loading'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { SelectInput } from "../../mini-lib/form/SelectInput";
import { debounce } from "lodash";

export const ClientAutocomplete = (props: {
  onSelect: (Client) => void
  initialClient?: Client
  width?: string
  isClearable?: boolean
  disabled?: boolean
  label?: string
}) => {
  const dispatch = useDispatch()
  const { onSelect, initialClient, width = '350px', isClearable = false, disabled = false, label = 'Search Clients' } = props
  const { salonId, user } = UseBaseApiParams()
  const models = useAppSelector(selectClientList)
  const [searchText, setSearchText] = useState('')
  const [selectedClient, setSelectedClient] = useState<Client | null>(initialClient ? initialClient : null)
  const filteredModels = models ? filterClients(models, searchText) : null
  const loaded = !!models

  useEffect(() => {
    if (user.token && !loaded) {
      dispatch(dispatchListClients(user.token, salonId))
    }
  }, [dispatch, user.token, loaded, salonId])

  const defaultSelected = initialClient
    ? { value: initialClient, label: initialClient.firstName + ' ' + initialClient.lastName }
    : null

  const dropdownOptions: { value: Client; label: string }[] | null = filteredModels
    ? filteredModels.map((model) => {
        return { value: model, label: model.firstName + ' ' + model.lastName }
      })
    : null


  // debounce user input so we don't call the api multiple times
  // j-ref - debounce dispatch
  const debouncedDispatch = useMemo(() =>
    debounce((token, salonId, searchText) => {
      dispatch(dispatchListClients(token, salonId, 1, searchText))
    }, 300),
    [dispatch]
  )

  const handleInputChange = (searchText) => {
    setSearchText(searchText)
    if (searchText !== '') {
      debouncedDispatch(user.token, salonId, searchText)
    }
  }
  defaultSelected && dropdownOptions && dropdownOptions.push(defaultSelected)
  return (
    <Box w={width} maxW="100%">
      {disabled && (
        <Box w="100%" isTruncated={true}>
          {initialClient ? `${initialClient.firstName} ${initialClient.lastName}` : ''}
        </Box>
      )}
      {!disabled && dropdownOptions && (
        <SelectInput
          variant='material'
          border='rounded'
          value={selectedClient}
          placeholder="Search Clients"
          label={label}
          options={dropdownOptions}
          onInputChange={handleInputChange}
          onChange={(option: Client) => {
            if (option) {
              setSelectedClient(option)
              onSelect(option)
            } else {
              setSelectedClient(null)
              onSelect(null)
            }
          }}
          allowFocus={true}
          isClearable={isClearable}
        />
      )}
      {!dropdownOptions && <Loading />}
    </Box>
  )
}
